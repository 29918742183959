(function() {
    "use strict";

    angular
        .module('slate.approvals')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
            .state('myApprovals', {
                url: '/my-approvals/',
                template: "<div class='content-pane list-page'><slate-my-approvals-list></slate-my-approvals-list></div>",
                current_app: 'approvals'
            })
            .state('myApprovalsMasq', {
                url: '/my-approvals/:userid/',
                template: "<div class='content-pane list-page'><slate-my-approvals-list></slate-my-approvals-list></div>",
                current_app: 'approvals'
            })
        ;
    }
})();
