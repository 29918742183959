(function () {
    "use strict";

    angular
        .module('slate.salesbudgets')
        .directive('slateNewBusinessForm', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/salesbudgets/newbusiness.edit.dir.html',
            scope: {
                sales_mgr_id: '=salesmgr',
                newbusiness: '=',
                start_date: '=?start',
                complete: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function ListController(
        $scope,
        $http,
        getLinkSrv,
        SlateAuth,
        SlateSalesNewBusinesses
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;
        
        
        vm.newbusiness = new SlateSalesNewBusinesses();
        
        vm.requestNewBudget = requestNewBudget;
        
        $scope.$watch('vm.sales_mgr_id', function (old_val, new_val) {
            if (vm.sales_mgr_id) {
                loadBudgets();
            }
        });
        
        function loadBudgets() {
            var running_year, running_month, start_date;

            if(!vm.start_date) {
                vm.start_date = new Date();
                vm.start_date = new Date(vm.start_date.getFullYear(), 0, 1);
            }
            
            running_year = vm.start_date.getFullYear();
            running_month = vm.start_date.getMonth() + 1;
            start_date = running_year + '-' + running_month.toString().padStart(2, '0') + '-01';
            
            vm.newbusiness.getList({sales_mgr: vm.sales_mgr_id, active: true, start_date: start_date}).then(function() {
                
            });
        }

        function requestNewBudget() {
            var apiUrl = API_URL + 'salesbudgets/request-new/';
            
            vm.saving = true;

            $http.post(apiUrl, {company: vm.company_id, start:vm.start_date.getFullYear()+'-'+ (vm.start_date.getMonth() + 1)}).then(function() {
                vm.saving = false;
                loadBudgets();
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        $scope.$watch(function() { return vm.newbusiness.list; }, function(newCollection, oldCollection) {
            if(!vm.auth.perms.salesbudgets_can_update_all) { return false; }
            console.log('budgets list changed');
            console.log(newCollection);
            console.log(oldCollection);
            if(!oldCollection.length) { return false; }
            newCollection.forEach(function(item, idx) {
                console.log(oldCollection);
                if(item.value != oldCollection[idx].value && (!item.notes || !item.notes.includes('Changed')) ) {
                    var now = new Date().toJSON().slice(0,10);
                    var notes = '';
                    if(item.notes) { notes = item.notes; }
                    item.notes = notes + ' Changed by ' + vm.auth.user.contact_name + ' on ' + now;
                    item.notes = item.notes.trim();
                }
            });
        }, true);

    }

})();
