/* eslint-disable func-names */
(function () {
    'use strict';

    angular
        .module('slate.library.companies')
        .factory('SlateCompanyBrands', ModelList)
        .factory('SlateCompanyBrand', ModelDetail);

    const apiUrl = `${API_URL}companies/company-brands/`;

    function ModelList($http, $q, SlateCompanyBrand) {
        // Set to url relative to api root.

        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === 'object') {
                self.filters = angular.copy(filters);
            }

            const promise = $http.get(apiUrl, {
                params: self.filters,
                timeout: self.canceller.promise
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateCompanyBrand();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.totalItems = response.data.totalItems;
                self.permissions = response.data.permissions;
                self.canceller = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q, SlateLocations, SlateContactMethods, SlateShippingAccounts) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        const fields = [
            { name: 'id', def: null, readonly: true },

            { name: 'brand', def: null },

            { name: 'contact' },
            { name: 'contact_info', readonly: true },

            { name: 'company' },
            { name: 'company_info', readonly: true }
        ];

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = _.cloneDeep(field.def);
            });

            self.locations = new SlateLocations();
            self.contactmethods = new SlateContactMethods();
            self.shipping_accounts = new SlateShippingAccounts();

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                // eslint-disable-next-line no-console
                console.log('payload must be an object');
                // eslint-disable-next-line no-console
                console.log('not object:', payload);
                return self;
            }

            angular.forEach(fields, (field) => {
                self[field.name] = payload[field.name];

                if (field.type === 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.log('could not parse json');
                    }
                }
            });


            if (!self.locations) {
                self.locations = new SlateLocations();
            }
            if (!self.contactmethods) {
                self.contactmethods = new SlateContactMethods();
            }
            if (!self.shipping_accounts) {
                self.shipping_accounts = new SlateShippingAccounts();
            }

            // if(!self.name_zh && self.name) { self.name_zh = self.name; }

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            if (id) { modelUrl = `${apiUrl + id}/`; }

            if (!self.id && !id) {
                // eslint-disable-next-line no-console
                console.log('Tried to load from server without id');
                return $q.reject(self);
            }

            const promise = $http.get(modelUrl);

            promise.then((response) => {
                const promises = [];

                return self.loadFromPayload(response.data).then(() => {
                    self.permissions = response.data.permissions;

                    return $q.when(promises).then(() => {
                        deferred.resolve(self);
                    });
                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) { modelUrl = `${apiUrl + self.id}/`; }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (field.type === 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });


            let has_primary_url = false;
            let has_primary_phone = false;


            angular.forEach(self.contactmethods.list, (cm) => {
                if (cm.method === 'url' && cm.is_primary === true) {
                    if (!has_primary_url) {
                        has_primary_url = true;
                    } else {
                        cm.is_primary = false;
                    }
                }
                if (cm.method === 'phone' && cm.is_primary === true) {
                    if (!has_primary_phone) {
                        has_primary_phone = true;
                    } else {
                        cm.is_primary = false;
                    }
                }
            });
            angular.forEach(self.contactmethods.list, (cm) => {
                if (cm.method === 'url' && !has_primary_url) {
                    cm.is_primary = true;
                    has_primary_url = true;
                }
                if (cm.method === 'phone' && !has_primary_phone) {
                    cm.is_primary = true;
                    has_primary_phone = true;
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then((response) => {
                const promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                if (self.locations) {
                    promises.push(self.locations.saveAll({ parent_type: 'company', parent_id: self.id }));
                }

                if (self.contactmethods) {
                    promises.push(self.contactmethods.saveAll({ parent_type: 'company', parent_fk: self.id }));
                }

                if (self.shipping_accounts) {
                    promises.push(self.shipping_accounts.saveAll({ parent_type: 'company', parent_fk: self.id }));
                }

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            const promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }
})();
