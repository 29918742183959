(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetNotes', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.notes.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $interval, SlateAuth, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.froalaOptions = {
            toolbarButtons: ["bold", "italic", "underline", 'fontSize', "|",
                "align", "formatOL", "formatUL", "|",
                "quote", "insertTable", "|", 'insertLink'
            ],
        };
    }


})();
