(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .controller('SlatePurchaseOrderEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlatePurchaseOrder) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.purchaseorder = new SlatePurchaseOrder();

        vm.purchaseorder.loadFromServer($stateParams.id).then(function () {
            if (vm.purchaseorder.name) {
                SlateTitle.set('Edit ' + vm.purchaseorder.name + ' - Purchase Orders');
            } else {
                SlateTitle.set('Edit Untitled order - Purchase Orders');
            }
            vm.loading = false;

        }).catch(angular.noop);

        $scope.$watch('vm.purchaseorder.name', function () {
            if (vm.purchaseorder.name) {
                SlateTitle.set('Edit ' + vm.purchaseorder.name + ' - Purchase Orders');
            } else {
                SlateTitle.set('Edit Untitled order - Purchase Orders');
            }
        });

    }

})();


