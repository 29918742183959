(function() {
    "use strict";

    angular
        .module('slate.library.inspections')
        .factory('SlateInspections', ModelList)
        .factory('SlateInspection', ModelDetail);

    var apiUrl = API_URL + 'inspections/inspection/';

    function ModelList($http, $q, SlateInspection) {
        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if(self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters,
                timeout: self.canceller.promise
            });

            promise.then(function (response) {
                var newlist = [];
                //set this for correct model

                self.count = response.data.count;

                angular.forEach(response.data.payload, function (item) {
                    var itemModel = new SlateInspection();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.pagination = {
                    total: parseInt(response.data.pagination.total),
                    page: parseInt(response.data.pagination.page),
                    count: parseInt(response.data.pagination.count),
                };
                self.canceller = null;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

    }

    function ModelDetail($http, $q, SlateInspectionLineItem, SlateContactMethod) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', readonly: true },

            {name: 'name'},
            {name: 'status', def: 'New'},

            {name: 'ship_date', type:'dateOnly'},
            {name: 'qc_date', type:'dateOnly', readonly: true},
            {name: 'inspector'},

            {name: 'request_type'},
            {name: 'inspection_type'},
            {name: 'problem_areas'},

            {name: 'purchase_order'},
            {name: 'purchase_order_info'},

            {name: 'order', readonly: true},
            {name: 'order_info', readonly: true},
            
            {name: 'contact_methods', type:'child_list', model: SlateContactMethod, readonly: true},

            {name: 'contact'},
            {name: 'contact_info'},

            {name: 'factory'},
            {name: 'factory_info'},

            {name: 'supplier_info'},
            
            {name: 'line_items', type:'child_list', model: SlateInspectionLineItem},

            {name: 'created_by'},
            {name: 'created_by_info'},
            {name: 'created', readonly: true},
            {name: 'updated', readonly: true},
        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.getModelData = getModelData;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.getPDFInfo = getPDFInfo;
        model.prototype.getLineItems = getLineItems;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = _.cloneDeep(field.def);
                if(field.type=="date" && !self[field.name]) {
                    self[field.name] = new Date();
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load inspection from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function (response) {
                var promises = [];

                return self.loadFromPayload(response.data.payload).then(function () {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                if(!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if (field.type == 'dateOnly' && payload[field.name]) {
                    if(typeof payload[field.name] == 'string') {
                        self[field.name] = dateFns.parse(payload[field.name]);
                    }
                }
                if(field.type == 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach(function(item, idx, array) {
                            var obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch(e) {
                        console.log('error loading: ', e);
                    }
                }

            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            var self = this;
            var data = {};

            angular.forEach(fields, function(field) {
               if ((!field.readonly || field.name == 'id') && typeof(self[field.name]) != 'undefined') {
                    data[field.name] = self[field.name];
                    if(field.type == "dateOnly" && self[field.name]) {
                        data[field.name] = dateFns.format(self[field.name], 'YYYY-MM-DD');
                    }
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name], function(key, value) {
                            if (key == "$$hashKey") {
                                return undefined;
                            }
                            return value;
                        });
                    }
                    if(field.type == "child_list" && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach(function(item) {
                            if(item.toDelete) { return false; }
                            data[field.name].push(item.getModelData());
                        });
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;
            var promise;

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }
            
            var data = self.getModelData();
            
            promise = $http.post(modelUrl, data);
            promise.then(function(response) {
                self.loadFromPayload(response.data.payload);
                deferred.resolve(self);
            }, function() {
                deferred.reject(self);
            });

            return deferred.promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;
            
            var promise;
            var data = {};
            
            if(self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }
            
            promise = $http.delete(modelUrl);
            promise.then(function (response) {
                self.id = null;
                deferred.resolve(self);
            });
            
            return deferred.promise;
            
        }
        
        function getPDFInfo() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            
            if(!self.id) {
                deferred.reject(self);
                return deferred.promise;
            }

            var modelUrl = apiUrl + self.id + '/qima-pdf-info/';

            var promise = $http.get(modelUrl);
            promise.then(function(response) {
                self.pdfInfo = response.data.payload;
                deferred.resolve(self);
            }, function() {
                deferred.reject(self);
            });

            return deferred.promise;
        }

        function getLineItems() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (!self.id) {
                deferred.reject(self);
                return deferred.promise;
            }

            var modelUrl = API_URL + 'inspections/lineitems/';

            $http.get(modelUrl, {params: {inspection: self.id} }).then(function(response) {
                self.line_items = response.data.payload;
                deferred.resolve(self);
            }, function() {
                deferred.reject(self);
            });

            return deferred.promise;
        }


    }

})();
