(function() {
    "use strict";

    angular
        .module('slate.library.projects')
        .factory('SlateApprovalSteps', ModelList)
        .factory('SlateApprovalStep', ModelDetail);

    // Set to url relative to api root.
    var apiUrl = API_URL + 'approvals/';

    function ModelList($http, $q, SlateApprovalStep) {

        // instantiate our initial object
        var list = function construct() {
            var self = this;
            self.tagOptions = ['QC', 'Internal', 'Client'];

            self.clear();

            return self;
        };


        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.getMyList = getMyList;
        list.prototype.saveAll = saveAll;
        list.prototype.updateCurrent = updateCurrent;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];
            self.current = null;

            return self;
        }

        function getList(parent_type, parent_fk, filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            var listUrl;

            if (typeof filters === "object") {
                self.filters = filters;
            }

            if (parent_type) {
                self.parent_type = parent_type;
            }
            if (parent_fk) {
                self.parent_fk = parent_fk;
            }
            listUrl = apiUrl + self.parent_type + '/' + self.parent_fk + '/steps/';

            promise = $http.get(listUrl, {
                params: self.filters
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlateApprovalStep();
                    itemModel.loadFromPayload(item);
                    itemModel.parent_type = self.parent_type;
                    itemModel.parent_fk = self.parent_fk;

                    newlist.push(itemModel);
                });

                newlist.sort(function compare(a, b) {
                    if (a.order < b.order) {
                        return -1;
                    }
                    if (a.order > b.order) {
                        return 1;
                    }
                    return 0;
                });
                self.list = newlist;
                self.permissions = response.data.permissions;
                self.updateCurrent();
                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function getMyList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            var listUrl;

            filters = angular.extend({}, filters, self.filters);

            listUrl = apiUrl + 'myapprovals/';

            promise = $http.get(listUrl, {
                params: filters
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlateApprovalStep();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;
                self.updateCurrent();
                deferred.resolve(self);
            });

            return deferred.promise;
        }


        function updateCurrent() {
            /* jshint validthis: true */
            var self = this;
            var current;
            var status = "";

            angular.forEach(self.list, function(item) {
                if (!current && !item.approved_date && status != 'rejected') {
                    current = item;
                }
                if (item.approved_date) {
                    if (item.status === true) {
                        status = 'approved';
                    } else {
                        status = 'rejected';
                    }
                }
            });
            self.current = current;
            if(!self.current) { self.status = status; } else {self.status = ""; }
        }

        function saveAll(defaults) {
            /* jshint validthis: true */
            var self = this;
            var promises = [];
            var toDelete = [];
            var idx = 0;

            angular.forEach(self.list, function(item, index) {
                if (typeof defaults == 'object') {
                    item.loadFromPayload(defaults);
                }
                item.order = index;

                if (!item.toDelete) {
                    promises.push(item.saveModel());
                } else {
                    promises.push(item.deleteModel());

                }

            });

            return $q.all(promises).then(function() {
                return self.getList();
            });
        }

    }

    function ModelDetail($http, $q, SlateAuth, SlateLocations, SlateContactMethods) {

        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },

            {name: 'approval_cycle', def: null, readonly: true },
            {name: 'parent', def: 'unkown', readonly: true },

            {name: 'url', def: null, readonly: true },

            {name: 'comments', def: null },
            {name: 'comments_html', def: null, readonly: true },
            {name: 'system_notes', def: null },

            {name: 'approved_date', type: 'date' },
            {name: 'status'},
            {name: 'canceled', def: false},
            
            {name: 'tag', def:"Internal"},
            {name: 'approvers', def: []},
            {name: 'approver'},
            {name: 'approver_name', def: "", readonly: true },
            {name: 'approver_slug', def: "", readonly: true },

            {name: 'order', def: 0},

            {name: 'created', def: null, readonly: true },
            {name: 'updated', def: null, readonly: true },

            {name: 'created_by', def: null, readonly: true },
            {name: 'created_by_name', def: null, readonly: true },
            {name: 'created_by_slug', def: null, readonly: true },

            {name: 'parent_fk', def: null, readonly: true },
            {name: 'parent_type', def: null, readonly: true },

            {name: 'root', def: null, readonly: true },
            {name: 'root_type', def: null },
            {name: 'root_fk', def: null },
            {name: 'root_slug', def: null, readonly: true },

            {name: 'project', def: null, readonly: true },

        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.approve = approve;
        model.prototype.reject = reject;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }



        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                if (payload.hasOwnProperty(field.name)) {
                    self[field.name] = payload[field.name];
                }

            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id, parent_type, parent_fk) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            if (parent_type) {
                self.parent_type = parent_type;
            }
            if (parent_fk) {
                self.parent_fk = parent_fk;
            }

            var modelUrl = apiUrl + self.parent_type + '/' + self.parent_fk + '/steps/';

            var promise;

            if (id) {
                modelUrl = modelUrl + id + '/';
            }

            if (!id && self.id) {
                modelUrl = modelUrl + self.id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];

                return self.loadFromPayload(response.data.payload).then(function() {
                    self.permissions = response.data.permissions;
                    if (parent_type) {
                        self.parent_type = parent_type;
                    }
                    if (parent_fk) {
                        self.parent_fk = parent_fk;
                    }

                    return $q.when(promises).then(function() {

                        deferred.resolve(self);
                    });

                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl + self.parent_type + '/' + self.parent_fk + '/steps/';

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = modelUrl + self.id + '/';
            }

            angular.forEach(fields, function(field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];

                }

            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl + self.parent_type + '/' + self.parent_fk + '/steps/';

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = modelUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

        function approve() {
            /* jshint validthis: true */
            var self = this;
            var now = new Date();
            if (self.approver == SlateAuth.user.contact_id) {

                self.approved_date = now.toISOString();
                self.status = true;
                return self.saveModel();
            }
        }

        function reject() {
            /* jshint validthis: true */
            var self = this;
            var now = new Date();

            if (self.approver == SlateAuth.user.contact_id) {
                self.approved_date = now.toISOString();
                self.status = false;
                return self.saveModel();
            }

        }

    }

})();
