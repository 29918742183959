(function() {
    "use strict";

    angular
        .module('slate.rfqs')
        .controller('SlateRfqNewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateRFQ) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = false;
        vm.rfq = new SlateRFQ();
        if($location.search().client) { vm.rfq.company = $location.search().client; }
        if($location.search().company) { vm.rfq.company = $location.search().ccompany; }
        if($location.search().project) { vm.rfq.project = $location.search().project; }
    }

})();
