(function () {
    'use strict';

    angular
        .module('slate.library.inspections')
        .factory('SlateInspectionResults', ModelList)
        .factory('SlateInspectionResult', ModelDetail);

    const apiUrl = `${API_URL}inspections/lineitems/results/`;

    function ModelList($http, $q, SlateBillingLineItem) {
        // Set to url relative to api root.

        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let promise;
            const promises = [];

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateBillingLineItem();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', readonly: true },
            { name: 'line_item' },

            { name: 'status', def: 'New' },

            { name: 'inspection_date', type: 'dateOnly' },
            { name: 'qc_date', type: 'dateOnly' },
            { name: 'time_spent' },
            { name: 'travel_spent' },
            { name: 'travel_mode' },
            { name: 'notes' },
            { name: 'status_notes' },
            { name: 'factory_confirmed' },

            { name: 'created', readonly: true },
            { name: 'updated', readonly: true }
        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.getModelData = getModelData;
        model.prototype.saveModel = saveModel;
        // model.prototype.deleteModel = deleteModel;
        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;
                if (field.type == 'date' && !self[field.name]) {
                    self[field.name] = new Date();
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                if (!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if (field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type == 'dateOnly' && payload[field.name]) {
                    if (typeof payload[field.name] === 'string') {
                        const parts = self[field.name].split('-');
                        self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
                    }
                }

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }
                if (field.type == 'int') {
                    self[field.name] = parseInt(self[field.name]);
                }


                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            let promise;

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then((response) => {
                const promises = [];

                return self.loadFromPayload(response.data.payload).then(() => {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            const self = this;
            const data = {};

            angular.forEach(fields, (field) => {
                if ((!field.readonly || field.name == 'id') && typeof (self[field.name]) !== 'undefined') {
                    data[field.name] = self[field.name];
                    if (field.type == 'date') {
                        data[field.name] = `${self[field.name].getUTCFullYear()}-${self[field.name].getUTCMonth() + 1}-${self[field.name].getUTCDate()}`;
                    }
                    if (field.type == 'dateOnly' && self[field.name]) {
                        data[field.name] = dateFns.format(self[field.name], 'YYYY-MM-DD');
                    }
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;
            let data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            }

            data = getModelData();

            promise = $http.post(modelUrl, data);
            promise = promise.then((response) => {
                const promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }
})();
