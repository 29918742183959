(function() {
    "use strict";

    angular
        .module('slate.estimates')
        .directive('slateVendorEstimateEditResponsesList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/estimates/vendorestimates.responses.list.dir.html',
            scope: {
                estimate: '=',
                responses: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateVendorEstimateResponses, SlateVendorEstimateResponse) {
        /* jshint validthis: true */
        var vm = this;

        vm.loading = false;

        vm.addNewLineItem = addNewLineItem;
        vm.deleteLineItem = deleteLineItem;

        vm.response = new SlateVendorEstimateResponse();

        vm.hasNoPreferred = hasNoPreferred;
        vm.hasNoQualified = hasNoQualified;

        function addNewLineItem() {
            if(!vm.response.vendor) { return false;}
            if(!vm.responses.list.some(function(resp) {
                return resp.vendor == vm.response.vendor;
            })) {
                vm.responses.list.push(vm.response);
            }
            vm.response = new SlateVendorEstimateResponse();
        }
        $scope.$watch('vm.response.vendor', function (newVal, oldVal) {
            if(newVal != oldVal && vm.response.vendor) {
                addNewLineItem();
            }
        });

        function deleteLineItem(lineitem, $idx) {
            lineitem.toDelete = !lineitem.toDelete;
        }

        function hasNoQualified() {
            if(!vm.responses.list.length) { return false; }
            if(vm.responses.list.some(function(response) {
                return response.vendor_info.vendor_qualified;
            })) { return false; }

            return true;
        }

        function hasNoPreferred() {
            if(!vm.responses.list.length) { return false; }
            if(vm.responses.list.some(function(response) {
                return response.vendor_info.vendor_preferred;
            })) { return false; }

            return true;
        }
    }

})();
