(function () {
    'use strict';

    angular
        .module('slate.productprofiles')
        .service('SlateProductProfileEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateProductProfile) {
        const templateUrl = '/partials/productprofiles/productprofile.edit.dialog.html';

        const srv = {
            edit: editObj,
        };


        return srv;


        function editObj(obj) {
            const modalInstance = $uibModal.open({
                templateUrl,
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj() {
                        const profile = new SlateProductProfile();
                        if (!obj) { return profile; }

                        return profile.loadFromServer(obj.id);
                    },
                },
            });

            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $http, $q, $uibModalInstance, obj, SlateAuth) {
        /* jshint validthis: true */
        const vm = this;

        vm.auth = SlateAuth;
        vm.obj = obj;

        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.isComplete = isComplete;

        function saveObject() {
            const deferred = $q.defer();

            if (!vm.isComplete()) { return false; }

            vm.saving = true;

            if (!vm.obj.sales_mgr) { vm.obj.sales_mgr = vm.obj.order_info.sales_mgr; }
            if (!vm.obj.proj_mgr) { vm.obj.proj_mgr = vm.obj.order_info.proj_mgr; }

            vm.obj.saveModel().then(() => {
                deferred.resolve(vm.obj);
            }, () => {
                vm.saving = false;
                vm.errorSaving = true;
            });

            deferred.promise.then(() => {
                vm.saving = false;
                $uibModalInstance.close(vm.obj);
            });

            return deferred.promise;
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function isComplete() {
            if (!vm.obj.company) { return false; }
            if (!vm.obj.order) { return false; }
            if (!vm.obj.name) { return false; }

            return true;
        }

        $scope.$watch('vm.obj.order', () => {
            if (vm.obj.order && !vm.saving) {
                if (!vm.obj.name) { vm.obj.name = vm.obj.order_info.name; }
                if (!vm.obj.sales_mgr) { vm.obj.sales_mgr = vm.obj.order_info.sales_mgr; }
                if (!vm.obj.proj_mgr) { vm.obj.proj_mgr = vm.obj.order_info.proj_mgr; }
            }
        });
    }
})();
