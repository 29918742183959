(function () {
    "use strict";

    angular
        .module('slate.estimates')
        .controller('SlateVendorEstimateResponseViewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateVendorEstimate, SlateVendorEstimateResponse, SlateOrder) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.response = new SlateVendorEstimateResponse();
        vm.estimate = new SlateVendorEstimate();

        vm.response.loadFromServer($stateParams.id).then(function () {
            vm.estimate.loadFromServer(vm.response.estimate).then(function () {
                vm.loading = false;
            });
        }).catch(angular.noop);

        $scope.$watch('vm.response.name', function () {
            if (vm.response.name) {
                SlateTitle.set('View ' + vm.response.name + ' - Vendor Estimate Responses');
            } else {
                SlateTitle.set('View Untitled Estimate - Vendor Estimate Responses');
            }
        });

    }

})();
