(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetProjNotifications', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.proj_notifications.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $interval, SlateAuth, SlateNotifications, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var interval = null;
        var speed = vm.settings.speed || 600000;

        vm.loading = true;
        vm.notifications = new SlateNotifications();
        vm.getLink = getLinkSrv;
        vm.toggle_read = toggle_read;

        function update_data() {
            vm.notifications.getList({'type':'PROJECT.NEW_ASSIGNMENT', 'new_only': true, 'get_company': true}).then(function() {
                vm.loading = false;
            });

        }

        function toggle_read(entry) {
            entry.read = !entry.read;
            entry.saveModel();

        }


        update_data();

        interval = $interval(function() {
            update_data();
        }, speed);

        $scope.$watch('vm.settings', function(items) {
            update_data();
        }, true);

        $scope.$on('$destroy', function() {
            if (angular.isDefined(interval)) {
                $interval.cancel(interval);
                interval = undefined;
            }
        });

    }


})();
