(function() {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .directive('slatePolineitemSearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/purchaseorders/po.lineitem.searchbox.html',
            scope: {
                polineitemId: '=',
                purchaseorderId: '=',
                componentId: '=?',
                readonly: '<?',
                placeholder: '=?'
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function directiveController($scope, $state, $location, $translate, $q, getLinkSrv, SlatePOLineItems, SlatePOLineItem) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;
        var selectedItem = "";

        vm.lineitems = new SlatePOLineItems();
        vm.dropdownItems = new SlatePOLineItems();

        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.clear = clear;
        vm.selectItem = selectItem;

        vm.dropdownItems.getList({purchase_order: vm.purchaseorderId});

        if(vm.polineitemId) {
            var lineitem = new SlatePOLineItem();
            lineitem.loadFromServer(vm.polineitemId).then(function() {
                vm.lineitemObj = lineitem;
                vm.lineitemName = lineitem.name;
                selectedItem = lineitem.id;
            });
        }
        $scope.$watch('vm.polineitemId', function() {
            if(selectedItem != vm.polineitemId && vm.polineitemId) {
                var lineitem = new SlatePOLineItem();
                lineitem.loadFromServer(vm.polineitemId).then(function() {
                    vm.lineitemObj = lineitem;
                    vm.lineitemName = lineitem.name;
                    selectedItem = lineitem.id;
                });
            }
        });

        function getSearch(q) {
            var filters = {
                q: q,
                purchase_order: vm.purchaseorderId
            };
            if (q && q.length < 1) { return $q.reject([]);}
            return vm.lineitems.getList(filters).then(function (response) {
                return vm.lineitems.list;
            });
        }

        function selectSearch($item, $model, $label, $event) {
            vm.searching = false;
            vm.polineitemId = $item.id;
            selectedItem = $item.id;
            vm.lineitemName = $item.name;
        }

        function clear() {
            if(vm.readonly) { return false; }
            vm.polineitemId = null;
            vm.lineitemObj = null;
        }

        function selectItem(vendor) {
            vm.searching = false;
            vm.polineitemId = vendor.id;
            vm.lineitemObj = vendor;
            vm.lineitemName = vendor.name;
        }


    }

})();
