(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .service('SlateDashboardEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal) {
        var templateUrl = '/partials/dashboard/dashboard.edit.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(dashboard_item) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                //size: 'lg',
                resolve: {
                    obj: function() {
                        return angular.copy(dashboard_item);
                    }
                }
            });

            return modalInstance.result.then(function(results) {
                return results;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $uibModalInstance, SlateAuth, obj, SlateRolesSrv) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;
        vm.roles = SlateRolesSrv;
        vm.auth = SlateAuth;


        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.checkType = checkType;

        vm.widgetTypes = [
            {name: "Notes", width: 3, height: 2},
            {name: "Logo", width: 2, height: 2},
            {name: "Incomplete Tasks", width: 3, height: 2},
            {name: "Watched Projects", width: 3, height: 2},
            {name: "Requests", width: 3, height: 2},
            {name: "QC Requests", width: 6, height: 3},
            //{name: "RFQ Logs", width: 6, height: 3},
            {name: "Request for Quote", width: 3, height: 2},
            //{name: "PO Requests", width: 3, height: 2},
            {name: "Purchase Orders", width: 3, height: 2},
            {name: "PO Received Items", width: 3, height: 2},
            {name: "Sales Orders", width: 3, height: 2},
            {name: "Sales Quotes", width: 3, height: 2},
            {name: "Shipments", width: 3, height: 2},
            {name: "Invoice Requests", width: 3, height: 2},
            {name: "Time Spent So Far", width: 2, height: 1},
            {name: "Project Notifications", width: 3, height: 2},
            {name: "Open Orders", width: 3, height: 2},
        ];

        if (SlateAuth.user.permissions.auth_can_view_active) {
            vm.widgetTypes.push({name: "Active Users", width: 3, height: 2});
        }

        vm.speeds = [
            {ms: 60000, name: 'One Minute'},
            {ms: 360000, name: 'Five Minutes'},
            {ms: 1080000, name: 'Fifteen Minutes'},
            {ms: 3600000, name: 'One Hour'},
        ];

        function saveObject() {
            if (vm.saving === true) {
                return false;
            }
            vm.saving = true;
            $uibModalInstance.close(vm.obj);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function checkType(type) {
            if (!vm.obj.name) {
                vm.obj.name = vm.obj.widgetType;
                angular.forEach(vm.widgetTypes, function(type) {
                    if (type.name == vm.obj.name) {
                        if(!vm.obj.sizeX || vm.obj.sizeX < type.width) { vm.obj.sizeX = type.width; }
                        if(!vm.obj.sizeY || vm.obj.sizeY < type.height) { vm.obj.sizeY = type.height; }
                    }
                });
            }
        }
    }



})();
