(function () {
    'use strict';

    angular
        .module('slate.projects')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('projectsList', {
                url: '/projects/',
                // templateUrl: '/partials/projects/list.html',
                // controller: 'projectListCtrl',
                template: "<div class='content-pane list-page'><slate-projects-list filters=\"{'root_only': true}\"></slate-projects-list></div>",
                current_app: 'projects',
                slateTitle: 'Projects'
            })
            .state('projectDetails', {
                url: '/projects/:slug/',
                templateUrl: '/partials/projects/project.details.page.html',
                controller: 'SlateProjectDetailsPageController',
                controllerAs: 'vm',
                current_app: 'projects',
                reloadOnSearch: false,
                resolve: {
                    project($stateParams, SlateProject) {
                        const project = new SlateProject();
                        const promise = project.loadFromServer($stateParams.slug);
                        promise.then(() => {

                        });
                        return promise;
                    }
                }
            })

            .state('projectDetails.brief', {
                url: 'brief/',
                templateUrl: '/partials/projects/project.details.brief.html',
                current_app: 'projects',
                tabName: 'Brief',
                tabConditional: 'project'
            })

            .state('projectDetails.component', {
                url: 'component/',
                template: "<slate-projects-component-list filters='{project: vm.project.id}' subview='true'></slate-projects-component-list>",
                current_app: 'projects',
                tabName: 'Components',
                tabConditional: 'project'
            })

            .state('projectDetails.variation', {
                url: 'variation/',
                template: "<slate-projects-variation-list filters='{project: vm.project.id}'></slate-projects-variation-list>",
                current_app: 'projects',
                tabName: 'Variations',
                tabConditional: 'component',
                tabExclude: 'finished_good'
            })

            .state('projectsComponentList', {
                url: '/components/',
                template: "<div class='content-pane list-page'><slate-projects-component-list filters=\"{is_component: true}\"></slate-projects-component-list></div>",
                current_app: 'projects',
                slateTitle: 'Components'
            })

            .state('projectsVariationList', {
                url: '/variations/',
                template: "<div class='content-pane list-page'><slate-projects-variation-list filters=\"{is_variation: true}\"></slate-projects-variation-list></div>",
                current_app: 'projects',
                slateTitle: 'Variations'
            })

            .state('projectDetails.assemblyfiles', {
                url: 'assemblies/*path?',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'assemblies',
                template: "<slate-file-browser parent-type='\"project\"' parent-fk='vm.project.id'></slate-file-browser>",
                current_app: 'projects',
                tabName: 'Assembly Files',
                tabConditional: 'project',
                defaults: { path: '' }
            })

            .state('projectDetails.orders', {
                url: 'orders/',
                template: "<slate-order-list filters='{project:vm.project.id, client:vm.project.get_client()}' show-new='true'></slate-order-list>",
                current_app: 'projects',
                tabName: 'Orders',
                tabConditional: 'project',
                tabPerms: 'orders_can_access'
                // tabPerms: 'rfq_can_access',
            })
            .state('projectDetails.requests', {
                url: 'requests/',
                template: "<slate-request-list parent-type=\"'project'\" parent-fk='vm.project.id' client-on-hold='vm.project.parent_info.on_hold'></slate-request-list>",
                current_app: 'projects',
                tabConditional: 'project',
                tabName: 'Design Requests',
                tabPerms: 'requests_can_view',
                nested: 'Requests'
            })
            .state('projectDetails.rfqlogs', {
                url: 'rfqlogs/',
                template: "<slate-rfqs-list subview='true' filters='{project:vm.project.id, client:vm.project.get_client()}'></slate-rfqs-list>",
                current_app: 'projects',
                tabName: 'RFQS',
                tabPerms: 'rfq_can_access',
                tabConditional: 'project',
                nested: 'Quotes'
            })
            .state('projectDetails.vendorestimates', {
                url: 'vendor-estimates/',
                template: "<slate-vendor-estimates-list show-new='true' filters='{project:vm.project.id}'></slate-vendor-estimates-list>",
                current_app: 'projects',
                tabName: 'Vendor Estimates',
                tabPerms: 'vendor_estimates_can_access',
                tabConditional: 'project',
                nested: 'Quotes'
            })
            .state('projectDetails.salesestimates', {
                url: 'sales-estimates/',
                template: "<slate-sales-estimates-list show-new='true' filters='{project:vm.project.id}'></slate-sales-estimates-list>",
                current_app: 'projects',
                tabName: 'Sales Estimates',
                tabPerms: 'sales_estimates_can_access',
                tabConditional: 'project',
                nested: 'Quotes'
            })
            .state('projectDetails.salesquotes', {
                url: 'salesquotes/',
                template: "<slate-sales-quotes-list filters='{project: vm.project.id}' show-totals='true'></slate-sales-quotes-list>",
                current_app: 'projects',
                tabName: 'Sales Quotes',
                tabConditional: 'project',
                nested: 'Quotes'
            })
            .state('projectDetails.inspections', {
                url: 'inspections/',
                template: "<slate-inspection-list filters='{project: vm.project.id}'></slate-inspection-list>",
                current_app: 'projects',
                tabName: 'Inspections',
                tabPerms: 'qcrequest_can_access',
                tabConditional: 'project',
                nested: 'Requests'
            })
            .state('projectDetails.qcrequests', {
                url: 'qcrequests/',
                template: "<slate-qcrequest-list filters='{project: vm.project.id}'></slate-qcrequest-list>",
                current_app: 'projects',
                tabName: 'QC Requests',
                tabPerms: 'qcrequest_can_access',
                tabConditional: 'project',
                nested: 'Requests'
            })
            .state('projectDetails.qclogs', {
                url: 'qclogs/',
                template: "<slate-qclog-list filters='{project: vm.project.id}'></slate-qclog-list>",
                current_app: 'projects',
                tabName: 'QC Logs',
                tabPerms: 'qclog_can_access',
                tabConditional: 'project',
                nested: 'Requests'
            })
            .state('projectDetails.productprofiles', {
                url: 'productprofiles/',
                template: "<slate-product-profiles-list filters='{project: vm.project.id}' show-new='false' hide-filters='true'></slate-product-profiles-list>",
                current_app: 'projects',
                tabName: 'Product Profiles',
                tabPerms: 'productprofiles_can_access',
                tabConditional: 'project',
                nested: 'Requests'
            })
            .state('projectDetails.testreports', {
                url: 'testreports/*path?',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'test-reports',
                template: "<slate-file-browser parent-type='\"project\"' parent-fk='vm.project.id'></slate-file-browser>",
                current_app: 'projects',
                tabName: 'Test Reports',
                tabConditional: 'project',
                defaults: { path: '' },
                nested: 'Files'
            })
            .state('projectDetails.shipments', {
                url: 'shipments/',
                template: "<slate-shipments-list filters='{project:vm.project.id, client:vm.project.get_client()}'></slate-shipments-list>",
                current_app: 'projects',
                tabName: 'Shipments',
                tabConditional: 'project',
                // tabPerms: 'rfq_can_access',
                nested: 'Tracking'
            })
            .state('projectDetails.samplesList', {
                url: 'samples/',
                template: "<slate-samples-list filters='{project: vm.project.id}'></slate-samples-list>",
                current_app: 'projects',
                tabName: 'Samples',
                tabPerms: 'samples_can_access',
                tabConditional: 'project',
                nested: 'Tracking'
            })

            .state('projectDetails.clientfiles', {
                url: 'clientfiles/*path?',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'client-files',
                template: "<slate-file-browser parent-type='\"project\"' parent-fk='vm.project.id'></slate-file-browser>",
                current_app: 'projects',
                tabName: 'Client Files',
                tabConditional: 'project',
                defaults: { path: '' },
                nested: 'Files'
            })

            .state('projectDetails.quotefiles', {
                url: 'quote_and_budget_files/*path?',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'quotes-and-budgets',
                template: "<slate-file-browser parent-type='\"project\"' parent-fk='vm.project.id'></slate-file-browser>",
                current_app: 'projects',
                tabName: 'Quotes & Budget Files',
                tabConditional: 'project',
                tabPerms: 'files_can_view_non_client_files',
                defaults: { path: '' },
                nested: 'Files'

            })
            .state('projectDetails.prodSched', {
                url: 'production_schedule/*path?',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'production-schedule',
                template: "<slate-file-browser parent-type='\"project\"' parent-fk='vm.project.id'></slate-file-browser>",
                current_app: 'projects',
                tabName: 'Production Schedule',
                tabConditional: 'project',
                tabPerms: 'files_can_view_non_client_files',
                defaults: { path: '' },
                nested: 'Tracking'
            })
            .state('projectDetails.techfiles', {
                url: 'techfiles/*path?',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'design-files',
                template: "<slate-file-browser parent-type='\"project\"' parent-fk='vm.project.id'></slate-file-browser>",
                current_app: 'projects',
                tabName: 'Technical Files',
                tabConditional: 'component',
                defaults: { path: '' }
            })
            .state('projectDetails.artfiles', {
                url: 'artfiles/*path?',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'design-files',
                template: "<slate-file-browser parent-type='\"project\"' parent-fk='vm.project.id'></slate-file-browser>",
                current_app: 'projects',
                tabName: 'Artwork Files',
                tabConditional: 'variation',
                defaults: { path: '' }
            })
            .state('projectDetails.projectfiles', {
                url: 'productionfiles/*path?',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'production-files',
                template: "<slate-file-browser parent-type='\"project\"' parent-fk='vm.project.id' read-only='true'></slate-file-browser>",
                current_app: 'projects',
                tabName: 'Production Files',
                tabExclude: ['project', 'finished_good'],
                tabPerms: 'files_can_view_non_client_files',
                defaults: { path: '' }
            })
            .state('projectDetails.BOMList', {
                url: 'bom/',
                controllerAs: 'vm',
                bindToController: true,
                template: "<slate-bom-list filters='{finished_good:vm.project.id}'></slate-bom-list>",
                current_app: 'projects',
                tabName: 'BOM',
                tabConditional: 'finished_good',
                defaults: { path: '' }
            })
            .state('projectDetails.costedbomcalculator', {
                url: 'costedbomcalculator/',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'costedbomcalculator',
                template: "<slate-costed-bom-calculator filters='{finished_good:vm.project.id}' project-nomenclature='vm.project.project_nomenclature'></slate-costed-bom-calculator>",
                current_app: 'projects',
                tabName: 'Costed BOM Calculator',
                tabConditional: 'finished_good',
                defaults: { path: '' }
            })
            .state('projectDetails.pricing', {
                url: 'pricing/*path?',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'pricing',
                template: "<slate-pricing-list filters='{component:vm.project.id}' project-nomenclature='vm.project.project_nomenclature'></slate-pricing-list>",
                current_app: 'projects',
                tabName: 'Pricing',
                tabExclude: ['project', 'component'],
                defaults: { path: '' }
            })
            .state('projectDetails.componentShipmentListPage', {
                url: 'component_shipments/',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'shipments',
                template: "<slate-shipments-list filters='{component: vm.project.id}'></slate-shipments-list>",
                current_app: 'projects',
                tabName: 'Component Shipments',
                tabExclude: ['project', 'component']
            })
            .state('projectDetails.linkedBOMList', {
                url: 'linkedboms/*path?',
                controllerAs: 'vm',
                bindToController: true,
                template: "<slate-linked-bom-list filters='{component:vm.project.id}'></slate-linked-bom-list>",
                current_app: 'projects',
                tabName: 'Linked BOMs',
                tabConditional: 'variation',
                defaults: { path: '' }
            })
            .state('projectDetails.stockLevels', {
                url: 'stock/',
                controllerAs: 'vm',
                bindToController: true,
                template: "<slate-instock-list filters='{component:vm.project.id}'></slate-instock-list>",
                current_app: 'projects',
                tabName: 'Stock Levels',
                tabConditional: 'variation'
            })
            .state('projectDetails.allfiles', {
                url: 'allfiles/*path?',
                controllerAs: 'vm',
                bindToController: true,
                template: "<slate-file-browser parent-type='\"project\"' parent-fk='vm.project.id'></slate-file-browser>",
                current_app: 'projects',
                tabName: 'All Files',
                tabPerms: 'files_can_view_all_files',
                tabConditional: 'project',
                defaults: { path: '' },
                nested: 'Files'

            });
    }
})();
