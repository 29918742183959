function debounce(func, wait, immediate) {
	var timeout;
	return function() {

		var context = this, args = arguments;
		var later = function() {
        	timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}


function getIPs(callback) {
    return false;
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

getObjClassName = function (clazzy) {
    "use strict";
    var obj = Object.getPrototypeOf(clazzy);
    if (typeof obj != "object" || obj === null) return false;
    return /(\w+)\(/.exec(obj.constructor.toString())[1].toLowerCase();
};


function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
        return '0 Bytes';
    }
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}
