(function () {
    "use strict";

    angular
        .module('slate.inspections')
        .directive('slateInspectionLineItemList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/inspections/inspection.lineitem.list.dir.html',
            scope: {
                inspection: '=',
                hidenew: '=?',
                showTotals: '=?',
                fileSrv: '=?',
                fields: '=?',
                projectId: '=?',
                dirty: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, InspectionFileEditSrv, SlateInspectionLineItems, SlateInspectionResultNewSrv, SlateInspectionLineItemEditSrv) {
        /* jshint validthis: true */
        var vm = this;

        if (!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'jansy_pn',
                'status',
                'name',
                'qc_date',
                'factory_confirmed',
                'category',
                'material',
                'qty',
            ];
            /* beautify preserve:end */
        }

        vm.editLineItem = editLineItem;
        vm.newResult = newResult;
        vm.selectFiles = selectFiles;

        function getLineItems() {
            vm.loading = true;
            var lineItems = new SlateInspectionLineItems();
            lineItems.getList({ inspection: vm.inspection.id }).then(function () {
                vm.inspection.line_items = lineItems.list;
                vm.loading = false;
            }, function () {
                vm.loading = false;
            });
        }
        getLineItems();

        function editLineItem(item, index) {
            SlateInspectionLineItemEditSrv.edit(item, { inspection: vm.inspection }).then(function (obj) {
                vm.inspection.line_items[index] = obj;
            }, function () {

            });
        }

        function newResult(lineItem, index) {
            SlateInspectionResultNewSrv.edit(null, { lineItem: lineItem }).then(function (item) {
                item.line_item = lineItem.id;
                lineItem.inspection_results.push(item);
            }, function () {

            });
        }

        function selectFiles(lineItem) {
            InspectionFileEditSrv.edit(lineItem).then(function (newLineItem) {
                lineItem.loadFromPayload(newLineItem);
            }, function () {

            });
        }



    }


})();
