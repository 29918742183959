(function () {
    "use strict";

    angular
        .module('slate.logistics')
        .directive('slateShipmentsList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/logistics/list/shipments.list.dir.html',
            scope: {
                modelFilters: '=?filters',
                fields: '=?',
                hideFilters: '=?',
                hideDetails: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $window, SlateAuth, SlateShipments, getLinkSrv, SlateDeleteObjSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.shipments = new SlateShipments();

        // Configuration type stuff you can change when copy/pasting
        vm.default_fields = [
            'shipment_number',
            'name',
            'client',
            'freight_handler',
            'status',
            'origin_warehouse',
            'total_cbms',
            'forwarder',
            'etd',
            'eta'
        ];


        vm.url_filter_fields = [  // these are valid url search parameters
            'q',
            'startDate',
            'endDate',
            'exworksStartDate',
            'exworksEndDate',
            'client',
            'vendor',
            'company',
            'sales_mgr',
            'proj_mgr',
            'delivery_to'
        ];

        vm.date_fields = ['startDate', 'endDate', 'exworksStartDate', 'exworksEndDate', 'etdStartDate', 'etdEndDate'];
        vm.multiSearchFields = ['factory_incoterms', 'status', 'shipment_type', 'status_changed'];
        //end stuff that sets configuration

        // toggles for filters
        vm.showFilters = false;

        // defile scope functions
        vm.hasFilterData = hasFilterData;
        vm.updateFromFilter = updateFromFilter;
        vm.deleteShipment = deleteShipment;
        vm.get_url = get_url;
        vm.toggle_all_closed = toggle_all_closed;

        // handle grabing filters on load and making sure they're valid, strings to date objects, etc.
        if (!vm.fields) { vm.fields = vm.default_fields; }
        if (!vm.modelFilters) { vm.modelFilters = {}; }
        vm.default_filters = {};
        if (!vm.hideFilters) {
            vm.default_filters.page = 1;
            vm.default_filters.count = 25;
        }

        var searchParams = $location.search();
        vm.url_filters = {};
        vm.url_filter_fields.forEach(function (item) {
            if (searchParams.hasOwnProperty(item)) {
                vm.url_filters[item] = searchParams[item];
            }
        });
        vm.filters = Object.assign({}, vm.default_filters, vm.modelFilters, vm.url_filters);
        if (vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if (vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }

        vm.date_fields.forEach(function (dateField) {
            if (vm.filters[dateField]) {
                let parts = vm.filters[dateField].split('-');
                vm[dateField] = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
            }
            $scope.$watch('vm.' + dateField, function () {
                if (vm[dateField]) {
                    vm.filters[dateField] = vm[dateField].getFullYear() + '-' + (vm[dateField].getMonth() + 1) + '-' + vm[dateField].getDate();
                } else {
                    vm.filters[dateField] = null;
                }
            });
        });

        vm.multiSearchFields.forEach(function (field) {
            if (vm.filters[field] && typeof (vm.filters[field]) === 'string') {
                vm.filters[field] = [vm.filters[field]];
            }

        });

        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            updateFromFilter();
        });

        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function (value, key) {
                if (key == 'page' || key == 'count' || key == 'q') { return false; }
                if (value === undefined || value === null || value === '') { return false; }
                if (vm.filters[key] == vm.modelFilters[key]) { return false; }
                if (typeof vm.filters[key] == 'string' && vm.filters[key].length === 0) { return false; }
                hasData = true;
                vm.showFilters = true;
                return true;

            });
            return hasData;
        }

        function updateFromFilter() {
            var modelPromise;
            var send_data = {};

            vm.loading = true;

            vm.multiSearchFields.forEach(function (fieldname) {
                if (typeof vm.filters[fieldname] === 'string') {
                    vm.filters[fieldname] = [vm.filters[fieldname]];
                }
            });

            angular.forEach(vm.filters, function (value, item) {
                if (value === '') { vm.filters[item] = null; }
                if (vm.filters[item] && !vm.modelFilters[item]) {
                    if (vm.filters[item] instanceof Date) {
                        $location.replace().search(item, vm.filters[item].toISOString());
                    } else {
                        $location.replace().search(item, vm.filters[item]);
                    }
                } else {
                    $location.replace().search(item, null);
                }
            });
            angular.extend(
                send_data,
                { 'fields': vm.fields },
                vm.filters
            );
            vm.url = $window.location.href;
            return vm.shipments.getList(send_data).then(function () {
                vm.loading = false;
            });
        }

        function deleteShipment(shipment) {
            SlateDeleteObjSrv.delete('shipment', shipment.id).then(function () {
                updateFromFilter();
            });
        }

        function get_url() {
            var url = API_URL + 'logistics/shipment/';
            if ($window.location.search.length) {
                return url + $window.location.search + '&download=true';
            } else {
                return url + '?download=true';
            }
        }

        function toggle_all_closed() {
            vm.shipments.list.forEach(function (shipment) {
                shipment.toggled = false;
            });
        }

    }


})();
