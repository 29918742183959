(function () {
    'use strict';

    angular
        .module('slate.billings')
        .directive('slateBillingLineItemList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/billings/billing.lineitem.list.dir.html',
            scope: {
                billing: '=',
                salesOrder: '=?',
                hidenew: '=?',
                showTotals: '=?',
                showBoms: '=?',
                selectedBomItems: '=?',
                fields: '=?',
                projectId: '=?',
                dirty: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateSalesOrder, SlateSOLineItems, SlateBillingLineItem, SlateBillingLineItemEditSrv, getLinkSrv, SlateAuth) {
        /* jshint validthis: true */
        const vm = this;
        vm.auth = SlateAuth;

        if (!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'finished_good',
                'qty',
                'unit_name',
                'sellprice',
                'sellprice_total'
            ];
            /* beautify preserve:end */
        }

        vm.getLink = getLinkSrv;
        vm.loading = false;

        vm.editLineItem = editLineItem;
        vm.addNewLineItem = addNewLineItem;
        vm.deleteLineItem = deleteLineItem;

        vm.getSellTotal = getSellTotal;

        vm.getStyle = getStyle;

        function getSellTotal() {
            let total = Big(0.0);
            if (!Array.isArray(vm.billing.line_items)) { return 0.00000; }
            vm.billing.line_items.forEach((item) => {
                total = total.add(item.getSellTotal());
            });
            return total.toFixed(5);
        }

        function addNewLineItem() {
            const new_lineitem = new SlateBillingLineItem();
            SlateBillingLineItemEditSrv.edit(new_lineitem, { billing: vm.billing, salesOrder: vm.salesOrder }).then((lineitem) => {
                vm.billing.line_items.push(lineitem);
            }, () => {
                // do nothing if canceled?
            });
        }

        function editLineItem(lineitem, $idx) {
            SlateBillingLineItemEditSrv.edit(lineitem, { billing: vm.billing, salesOrder: vm.salesOrder }).then((new_lineitem) => {
                vm.billing.line_items[$idx] = new_lineitem;
            }, () => { });
        }

        function deleteLineItem(lineitem, $idx) {
            lineitem.toDelete = !lineitem.toDelete;
        }

        function getStyle(field) {
            if (field === 'name') {
                return { width: '100%' };
            }

            return {
                overflow: 'hidden',
                'white-space': 'nowrap',
                'text-overflow': 'ellipsis'
            };
        }
    }
})();
