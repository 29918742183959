(function() {
    "use strict";

    angular
        .module('slate.schedule')
        .service('SlateScheduleGridSrv', slateScheduleGridSrv);


    function slateScheduleGridSrv($rootScope, $q, SlateUnscheduleTasks, SlateScheduleEvents) {
        var unschedTasks = new SlateUnscheduleTasks();
        var schedTasks = new SlateScheduleEvents();

        var srv = {
            roles: [],
            users: [],
            unsch: [],
            schd: [],

            filters: {},
            update: updateFromFilters,

        };

        return srv;

        function updateFromFilters() {

            unschedTasks.getList();
            schedTasks.getList(srv.filters);
        }
    }
})();
