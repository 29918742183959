(function () {
    "use strict";

    angular
        .module('slate.bom')
        .service('SlateBomItemPriceTierEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlatePOLineItem) {
        var templateUrl= '/partials/bom/bomitem.pricetier.edit.sidebar.html';

        var srv = {
            edit: editObj
        };
        return srv;

        function editObj(bomitem, itemPriceTiers) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    bomitem: function() { return bomitem; },
                    itemPriceTiers: function() { return itemPriceTiers; }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $http, $q, $uibModalInstance, SlateAuth, bomitem, itemPriceTiers, SlatePriceTiers) {
        /* jshint validthis: true */
        var vm = this;

        vm.close = close;
        vm.bomItemPriceTiers = _.cloneDeep(itemPriceTiers);
        vm.item = bomitem;
        vm.loading = true;

        vm.saveObject = saveObject;
        vm.isComplete = isComplete;

        vm.priceTiers = new SlatePriceTiers();

        function getPricing() {
            vm.priceTiers.getList({component: vm.item.component, count: 25}).then(function() {
                vm.loading = false;
            }, function() {
                vm.loading = false;
            });
        }
        getPricing();

        function saveObject() {
            $uibModalInstance.close({bom_item_price_tiers: vm.bomItemPriceTiers, price_tier: vm.priceTier});
        }

        function close() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }

        function isComplete() {
            if(!vm.bomItemPriceTiers[vm.item.id]) { return false; }
            return true;
        }

    }



})();
