(function () {
    'use strict';

    angular
        .module('slate.companies')
        .controller('SlateProductProfilesDetailsPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, SlateSFLinkage, getLinkSrv, SlateProductProfileEdit, SlateProductProfileGenCompsSrv, productprofile, SlateAuth) {
        /* jshint validthis: true */
        const vm = this;
        vm.auth = SlateAuth;

        vm.productprofile = productprofile;
        vm.editProfile = editProfile;

        vm.editing = false;

        SlateTitle.set(vm.productprofile.name);

        function editProfile() {
            if (vm.editing) { return false; }
            vm.editing = true;

            const promise = SlateProductProfileEdit.edit(vm.productprofile);
            promise.then((newProfile) => {
                vm.editing = false;
                if (newProfile.slug !== productprofile.slug) {
                    // $location.path(getLinkSrv.getUrl('company', newCompany.id, newCompany.slug)).search({});
                    $state.transitionTo('productProfileDetails', { id: newProfile.id }, { location: 'replace' });
                } else {
                    $state.reload();
                }
            }, () => {
                vm.editing = false;
            });
            return promise;
        }
    }
})();
