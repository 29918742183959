(function () {
    "use strict";

    angular
        .module('slate.salesquotes')
        .directive('slateSalesQuoteEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/salesquotes/salesquote.edit.dir.html',
            scope: {
                salesQuote: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $window,
        $state,
        $http,
        getLinkSrv,
        SlateAuth,
        SlateCompany,
        SlateLocations,
        SlateSalesOrder,
        SlateSOLineItems,
        SlateSOLineItem,
        SlatePurchaseOrder,
        SlatePOLineItem,
        SlatePOLineItems,
        SlateAssignedRoles,
        SlateRolesSrv,
        SlateProject
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.generatePDF = generatePDF;

        vm.saveObject = saveObject;
        vm.duplicateObject = duplicateObject;
        vm.genSalesOrder = genSalesOrder;
        vm.isComplete = isComplete;

        vm.setAddress = setAddress;
        vm.hasAddress = hasAddress;

        vm.froalaOptions = {
            toolbarButtons: ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            zIndex: 10,
        };

        vm.countries = Countries;

        vm.lineItemsDirty = false;

        vm.company = new SlateCompany();
        vm.co_addresses = new SlateLocations();

        vm.minDate = new Date(new Date().getTime()+(1*24*60*60*1000));
        vm.maxDate = new Date(new Date().getTime()+(30*24*60*60*1000));

        vm.baseStatus = vm.salesQuote.status;

        vm.SQStatusAvailable = [vm.salesQuote.status];
        checkAvailStatus();

        function checkAvailStatus() {
            vm.SQStatusAvailable = [vm.baseStatus];
            if(vm.baseStatus == 'draft') { vm.SQStatusAvailable.push('ready'); }
            if(vm.baseStatus == 'ready') { vm.SQStatusAvailable.push('draft', 'approval'); }
            if(vm.baseStatus == 'approval') { vm.SQStatusAvailable.push('draft', 'ready'); }
            if(vm.baseStatus == 'approval' && vm.auth.perms.salesquotes_can_approve) { vm.SQStatusAvailable.push('quoted'); }
            if(vm.baseStatus == 'quoted') { vm.SQStatusAvailable.push('accepted', 'rejected'); }
        }

        $scope.$watch('vm.salesQuote.tariffs', function() {
            vm.salesQuote.line_items.forEach(function(item) {
                item.updateCosts(vm.salesQuote.tariffs);
            });  
        });

        $scope.$watch('vm.salesQuote.landed_cost', function() {
            if (vm.salesQuote.landed_cost) {
                vm.salesQuote.freight_type = 'estimate';
            }  
        });

        $scope.$watch('vm.salesQuote.id', function (old_val, new_val) {
            if (vm.salesQuote.id && !vm.saving) {
                updateDependants();
            }
        });

        $scope.$watch('vm.salesQuote.company', function (old_val, new_val) {
            if (vm.salesQuote.company) {
                vm.company.loadFromServer(vm.salesQuote.company).then(function() {
                    if(!vm.salesQuote.destination_port && vm.company.destination_port) {
                        vm.salesQuote.destination_port = vm.company.destination_port;
                    }
                    if(!vm.salesQuote.final_destination && vm.company.final_destination) {
                        vm.salesQuote.final_destination = vm.company.final_destination;
                    }

                    if(!vm.salesQuote.id && vm.company.show_landed_costs) {
                        vm.salesQuote.landed_cost = vm.company.show_landed_costs;
                    }
                });
                vm.co_addresses.getList({company: vm.salesQuote.company});
            }
        });

        function setAddress(address) {
            var selected_addr;
            function select_addr(item) {
                if(item.id == vm.selected_client_address) { selected_addr = item; }
            }
            vm.co_addresses.list.forEach(select_addr);
            if(!selected_addr) { return false;}

            vm.salesQuote.ship_street = selected_addr.street1;
            if(selected_addr.street2) {
                vm.salesQuote.ship_street = vm.salesQuote.ship_street + ", " + selected_addr.street2;
            }
            if(selected_addr.street3) {
                vm.salesQuote.ship_street = vm.salesQuote.ship_street + ", " + selected_addr.street3;
            }
            vm.salesQuote.ship_city = selected_addr.city;
            vm.salesQuote.ship_state = selected_addr.state;
            vm.salesQuote.ship_postal = selected_addr.postal;
            vm.salesQuote.ship_country = selected_addr.country;

            vm.selected_client_address = null;
        }

        function updateDependants() {
            // bom vendor pricing updates go here
        }

        function hasAddress() {
            if(
                !vm.salesQuote.ship_phone ||
                !vm.salesQuote.ship_street ||
                !vm.salesQuote.ship_city ||
                !vm.salesQuote.ship_state ||
                !vm.salesQuote.ship_postal
            ) { return false; }
            return true;
        }

        function isComplete() {
            if(!vm.salesQuote.order) { return false; }
            if(!vm.salesQuote.name) { return false; }

            if(vm.salesQuote.status == 'draft') { return true; }

            if(!vm.salesQuote.quote_expires) { return false; }
            if(!vm.salesQuote.line_items.length) { return false; }

            if(!hasAddress()) { return false; }
            if(!vm.salesQuote.shipvia) { return false; }
            if(!vm.salesQuote.ship_terms) { return false; }

            return true;
        }

        function saveObject() {
            var deferred = $q.defer();

            if (vm.saving === true) {
                return false;
            }

            vm.saving = true;
            vm.error = false;

            // apply sort order to lineitems so they don't rearrange them selves
            vm.salesQuote.line_items.forEach(function(item, idx) {
                item.sort_order = idx + 1;
            });

            vm.salesQuote.saveModel().then(function () {
                var promises = [];

                if (vm.fileSrv) {
                    promises.push(vm.fileSrv.uploadFiles('salesquote', vm.salesQuote.id));
                }

                return $q.all(promises).then(function () {
                    $timeout(function () {
                        vm.saving = false;
                        vm.salesQuoteFrom.$setPristine();
                        vm.fileSrv.files = [];
                        vm.lineItemsDirty = false;
                        vm.baseStatus = vm.salesQuote.status;
                        checkAvailStatus();
                        if ($state.current.name != 'salesQuoteEditPage') {
                            $state.transitionTo(
                                'salesQuoteEditPage',
                                {id: vm.salesQuote.id},
                                {location: 'replace'}
                            );
                        }
                    }, 300);

                }, function () {
                    vm.saving = false;
                    vm.error = true;
                });

            }, function () {
                vm.saving = false;
                vm.error = true;
            });

            return deferred.promise;
        }

        function duplicateObject() {
            if(vm.saving) { return false; }
            if(!window.confirm('Are you sure you want to void and duplicate the quote?')) { return false; }
            vm.saving = true;
            vm.salesQuote.duplicateModel().then(function() {
                $timeout(function () {
                    $state.transitionTo(
                        'salesQuoteEditPage',
                        {id: vm.salesQuote.id}
                    );
                }, 300);
            }, function() {
                vm.saving = false;
                vm.error = true;
            });
        }

        function generatePDF() {
            var url = API_URL + 'salesquotes/quotes/' + vm.salesQuote.id + '/generatepdf/';

            if(['draft'].includes(vm.salesQuote.status)) { return false;}
            if (vm.saving) { return false; }
            if (!vm.salesQuote.id) { return false; }

            vm.saving = true;
            vm.error = false;

            return $http.get(url).then(function(response) {
                var download_url = API_URL + 'files/download/' + response.data.node_id + '/';
                var window = $window.open(download_url, '_blank');
                vm.saving = false;
                $scope.$broadcast('updateFiles', 'salesquote', vm.salesQuote.id);
            }, function() {
                vm.saving = false;
                vm.error = true;
            });
        }

        function projectSync() {
            var assroles = new SlateAssignedRoles();
            var project = new SlateProject();
            var role_dict = SlateRolesSrv.dict;
            if(!vm.salesQuote.proj_mgr || !vm.salesQuote.sales_mgr) {
                assroles.getList({
                    parent_type: 'project',
                    parent_fk: vm.salesQuote.project
                }).then(function () {

                    angular.forEach(assroles.list, function (item) {
                        if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Project Manager' && !vm.salesQuote.proj_mgr) {
                            vm.salesQuote.proj_mgr = item.member_fk;
                        }
                        if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Sales Manager' && !vm.salesQuote.sales_mgr) {
                            vm.salesQuote.sales_mgr = item.member_fk;
                        }
                    });

                });
            }
            if (!vm.salesQuote.company) {
                project.loadFromServer(vm.salesQuote.project).then(function () {
                    if (project.parent_type == 'company') {
                        vm.salesQuote.company = project.parent_fk;
                    }
                });
            }
        }
        $scope.$watch('vm.salesQuote.project', function (newVal, oldVal) {
            if(newVal != oldVal && vm.salesQuote.project) {
                projectSync();
            }
        });

        function genSalesOrder() {
            if(vm.saving) { return false; }
            if(!window.confirm('Are you sure you want to generate a sales order?')) { return false; }
            vm.saving = true;
            vm.error = false;

            var salesOrder = new SlateSalesOrder();

            [
                'name',
                'company',
                'project',
                'order',
                'proj_mgr',
                'sales_mgr',
                'reorder',
                'split_shipment',
                'shipvia',
                'ship_terms',
                'ship_to',
                'ship_street',
                'ship_state',
                'ship_postal',
                'ship_phone',
                'ship_country',
                'ship_city',
                'terms',
                'taxable',
                'notes',
                'desired_date'
            ].forEach(function(field) {
                salesOrder[field] = _.cloneDeep(vm.salesQuote[field]);
            });
            vm.salesQuote.line_items.forEach(function(sq_lineitem) {
                var so_lineitem = new SlateSOLineItem();

                [
                    'name',
                    'code',
                    'finished_good',
                    'bom',
                    'cateogry',
                    'material',
                    'specs',
                    'notes',
                    'qty',
                    'unit_name',
                    'unitprice',
                    'sellprice',
                    'ready_date',
                    'required_date',
                    'vendor_pn',
                    'sort_order'
                 ].forEach(function(field) {
                    so_lineitem[field] = _.cloneDeep(sq_lineitem[field]);
                });
                so_lineitem.jansy_pn = _.cloneDeep(sq_lineitem.finished_good_info.composite_num);
                salesOrder.line_items.push(so_lineitem);
            });
            salesOrder.saveModel().then(function() {
                vm.saving = false;
                $state.transitionTo(
                    'salesOrderEditPage',
                    {id: salesOrder.id}
                );

            }, function() {
                vm.saving = false;
                vm.error = true;
            });

        }
    }

})();
