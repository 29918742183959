(function () {
    'use strict';

    angular
        .module('slate.invoices')
        .directive('slateSynergyEmailEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/synergy/synergy.emails.edit.dir.html',
            scope: {
                email: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController(
        $scope,
        $timeout,
        getLinkSrv,
        SlateAuth
    ) {
        /* jshint validthis: true */
        const vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.baseEnteredStatus = vm.email.entered;

        vm.requestTypeOptions = ['external_only', 'both', 'sourcing_only'];

        vm.isComplete = isComplete;
        vm.saveObject = saveObject;

        vm.addProj = function () {
            if (!Array.isArray(vm.email.projects)) {
                vm.email.projects = [];
            }
            vm.email.projects.push('');
        };

        function saveObject() {
            if (vm.saving) { return false; }
            if (!vm.auth.perms.synergy_can_edit) { return false; }

            vm.saving = true;
            vm.saved = false;
            vm.error = false;

            if (Array.isArray(vm.email.projects)) {
                vm.email.projects = vm.email.projects.filter((item) => item);
            }

            vm.email.saveModel().then(() => {
                vm.saved = true;
                $timeout(() => {
                    vm.saving = false;
                }, 300);
            }, (e) => {
                vm.saving = false;
                vm.error = true;
            });

            return true;
        }

        function isComplete() {
            if (vm.email.is_completed && !vm.email.request_type) { return false; }
            return true;
        }
    }
})();
