(function () {
    "use strict";

    angular
        .module('slate.pos')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('porequestListPage', {
                url: '/porequests/',
                template: "<div class=\"content-pane list-page\"><slate-po-request-list></slate-po-request-list></div>",
                current_app: 'porequests',
                slateTitle: 'PO Requests',
            })

            ;
    }
})();