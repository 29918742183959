(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .directive('slateRfqResponseView', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/rfqs/rfq.response.dir.html',
            scope: {
                response: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $state,
        getLinkSrv,
        SlateAuth,
        SlateRFQ
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

        vm.incoterms = ['exw', 'fca', 'fob', 'cif', 'cpt', 'cip', 'dat', 'dap', 'ddp', 'ddu', 'other'];

        vm.baseStatus = vm.response.status;
        vm.canApprove = canApprove;

        function canApprove() {
            if(vm.baseStatus == 'accepted' || vm.baseStatus == 'rejected') { return false; }
            if(vm.auth.user.contact_id == vm.response.created_by) { return true; }
            if(vm.auth.user.contact_id == vm.response.sales_mgr) { return true; }
            if(vm.auth.user.contact_id == vm.response.proj_mgr) { return true; }
            return false;
        }

    }

})();
