(function() {
    "use strict";

    angular
        .module('slate.requests')
        .directive('slateRequestGlobalListX', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/requests/requests.global.list.dir.html',
            scope: {

            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $timeout, $filter, $state, $location, $q, getLinkSrv, SlateRequests, SlateRequest, SlateRequestEdit) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.requests = new SlateRequests();

        var oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

        vm.init = init;
        vm.filters = {
            q: undefined,
            incomplete: '',
            startDate: oneWeekAgo
        };

        vm.startDateIsOpen = false;
        vm.startDateOpen = startDateOpen;

        vm.endDateIsOpen = false;
        vm.endDateOpen = endDateOpen;

        vm.getLink = getLinkSrv;

        vm.saveCSV = saveCSV;

        function startDateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.startDateIsOpen = true;
        }

        function endDateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.endDateIsOpen = true;
        }

        init();

        function init() {

            var modelPromise;
            var listFilters = vm.filters;
            vm.loading = true;

            if (vm.filters.startDate) {
                vm.filters.startDate.setHours(0,0,1);
            }
            if (vm.filters.endDate) {
                vm.filters.endDate.setHours(23,59,59);
            }


            modelPromise = vm.requests.getList(listFilters);
            return modelPromise.then(function() {
                vm.loading = false;
            });
        }

        $scope.$watch('vm.filters', function(items) {
            init();
        }, true);

        function saveCSV() {
            var data = [[
                'Company',
                'Project',
                'Request',
                'Created By',
                'Created On',
                'Desired By',
                'Hours Budgeted'
            ]];
            var csvContent = "data:text/csv;charset=utf-8,";

            angular.forEach(vm.requests.list, function(request) {
                var comments = "";
                var created = new Date(request.created);
                var desired_by =  new Date(request.desired_by);

                if ($filter('date')(request.created,'mediumDate') == $filter('date')(request.desired_by, 'mediumDate')) {
                    comments = "Same day!";
                }

                if (!comments && created > desired_by) {
                    comments = "Request was in the past";
                }
                

                data.push([
                    request.company_info.name,
                    request.project_composite_num + ' - ' + request.project_name,
                    request.name,
                    request.createdby_name,
                    $filter('date')(request.created,'medium'),
                    $filter('date')(request.desired_by, 'mediumDate'),
                    request.hours_est,
                    comments
                ]);
            });

            return exportToCsv('requests.csv', data);
        }

        function exportToCsv(filename, rows) {
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                }
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }

        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    }


})();
