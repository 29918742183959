(function() {
    "use strict";

    angular
        .module('slate.library.projects')
        .factory('SlateWatchList', ModelList);

    var apiUrl = API_URL + 'projects/watch/';

    function ModelList($http, $q) {
        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.isWatched = isWatched;
        list.prototype.watch = watch;
        list.prototype.unWatch = unWatch;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters, short) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if(typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {params: self.filters});

            promise.then(function (response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function (item) {
                    var itemModel = new SlateWatch();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function isWatched(project) {
            var modelUrl = apiUrl + project + '/';
            return $http.get(modelUrl).then(function(response) {
                return response.data.watched;
            }, function(response) { return $q.reject(response); });
        }

        function watch(project) {
            return $http.post(apiUrl, {project: project});
        }

        function unWatch(project) {
            var modelUrl = apiUrl + project + '/';
            return $http.delete(modelUrl);
        }

    }

})();
