(function () {
    "use strict";

    angular
        .module('slate.invoices')
        .service('SlateInvoiceLineitemEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateInvoiceLineItem) {
        var templateUrl = '/partials/invoices/invoices.lineitem.sidebar.edit.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(item, init_data, parentProj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj: function () {
                        var newitem = new SlateInvoiceLineItem(init_data);
                        if(!item) {
                            return item;
                        }
                        if(typeof item == 'string') {
                            return newitem.loadFromServer(item);
                        }
                        return newitem.loadFromPayload(item);
                    },
                    parentProj: function() { return parentProj; }
                }
            });

            return modalInstance.result.then(function (results) {
                
                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance,  obj, parentProj, SlateSettings, SlateProject) {
        /* jshint validthis: true */
        var vm = this;

        vm.item = obj;
        vm.parentProj = parentProj;
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.detailsIsComplete = detailsIsComplete;

        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            //zIndex: 2501,
        };
        

        function saveObject(skip) {
            $uibModalInstance.close(vm.item);        
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }


        function detailsIsComplete() {
            return true;
        }



        function projectSync() {
            var project = new SlateProject();
            project.loadFromServer(vm.item.jansy_component).then(function () {
                if (!vm.item.jansy_pn) { 
                    if(project.internal_item_number) {
                        vm.item.jansy_pn = project.internal_item_number;     
                    } else {
                        vm.item.jansy_pn = project.composite_num; 
                    }
                }
                
                if (!vm.item.name) { 
                    if(project.variation_name) {
                        vm.item.name = project.variation_name;
                    } else {
                        vm.item.name = project.name; 
                    }
                }
                if (!vm.item.client_po) { vm.item.client_po = project.client_po; }
                if (!vm.item.jansy_po) { vm.item.jansy_po = project.jansy_po; }
                if (!vm.item.client_pn) { vm.item.client_pn = project.client_item_number; }
                if (!vm.item.notes) { vm.item.notes = project.brief; }
                if (!vm.item.category) { vm.item.category = project.category; }
                if (!vm.item.material) { vm.item.material = project.material; }
                if (!vm.item.unit_name) { vm.item.unit_name = project.unit_name; }
                if (!vm.item.unitprice) { vm.item.unitprice = project.buy_price; }
                if (!vm.item.sellprice) { vm.item.sellprice = project.sell_price; }
            });

        }
        $scope.$watch('vm.item.jansy_component', function () {
            if (vm.item.jansy_component) {
                projectSync();
            }

        });        


        SlateSettings.getConfig('material_categories').then(function(config) {
            vm.categories = config;
        });
        SlateSettings.getConfig('materials').then(function(config) {
            vm.material_list = config;
            getMatList();
        });
        vm.materials = [];

        $scope.$watch('vm.item.category', function() {
            getMatList();
        });

        function getMatList() {
            var newlist = [];
            if(!vm.material_list || ! vm.material_list.value) { return []; }
            angular.forEach(vm.material_list.value, function(item) {
                if(item.category == vm.item.category) {
                    newlist.push(item);
                }
            });
            vm.materials = newlist;
            return newlist;
        }

        
    }



})();
