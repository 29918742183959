(function () {
    "use strict";

    angular
        .module('slate.rates')
        .controller('SlateRateSheetEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateRateSheet) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.rateSheet = new SlateRateSheet();

        vm.rateSheet.loadFromServer($stateParams.id).then(function () {
            SlateTitle.set('Edit ' + vm.rateSheet.company_info.name + ' Rate Sheet - Rates');
            vm.loading = false;
        }).catch(angular.noop);
    }

})();


