(function () {
    'use strict';

    angular
        .module('slate.qima')
        .directive('slateBookingList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/qima/booking.list.dir.html',
            scope: {
                hideFilters: '=?',
                modelFilters: '=?filters',
                showTotals: '=?',
                fields: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlateDeleteObjSrv, SlateBookings, SlateBookingEditSrv) {
        /* jshint validthis: true */
        const vm = this;

        vm.loading = true;
        vm.auth = SlateAuth;
        vm.bookings = new SlateBookings();
        vm.deleteInspection = deleteInspection;
        vm.createBooking = createBooking;
        const multiSearchFields = ['status', 'request_type', 'inspection_type'];

        if (!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'description',
                'qima_booking_number',
                'status',
                'vendor',
                'service_date',
                'ship_date',
                'created',
                'created_by'
            ];
            /* beautify preserve:end */
        }

        vm.statusList = [
            'Draft',
            'Sent',
            'Waiting for Inspection',
            'Waiting for Allocation',
            'Waiting for report validation'
        ];

        vm.filters = angular.extend({ page: 1, count: 25 }, vm.modelFilters, $location.search());
        if (vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if (vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }

        $scope.$watchCollection(() => vm.filters, (value) => {
            updateFromFilter();
        });

        $scope.$watch('vm.qcDateStart', () => {
            if (vm.qcDateStart) {
                vm.filters.qcDateStart = dateFns.format(vm.qcDateStart, 'YYYY-MM-DD');
            } else {
                vm.filters.qcDateStart = null;
            }
        });
        $scope.$watch('vm.qcDateEnd', () => {
            if (vm.qcDateEnd) {
                vm.filters.qcDateEnd = dateFns.format(vm.qcDateEnd, 'YYYY-MM-DD');
            } else {
                vm.filters.qcDateEnd = null;
            }
        });

        function updateFromFilter() {
            vm.loading = true;

            multiSearchFields.forEach((field) => {
                if (vm.filters[field] && typeof vm.filters[field] === 'string') {
                    vm.filters[field] = [vm.filters[field]];
                }
            });

            angular.forEach(vm.filters, (value, item) => {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            vm.bookings.getList(vm.filters).then(() => {
                vm.loading = false;
            }, () => {

            });
        }

        function deleteInspection(inspection) {
            SlateDeleteObjSrv.delete('booking', inspection.id).then(() => {
                updateFromFilter();
            });
        }

        function createBooking() {
            SlateBookingEditSrv.edit().then(() => {
                updateFromFilter();
            }, () => {

            });
        }
    }
})();
