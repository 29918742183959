(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetWatched', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.watched.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $q, $timeout, $interval, SlateAuth, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var interval = null;
        var speed = vm.settings.speed || 360000;

        vm.data = [];
        vm.getLink = getLinkSrv;

        vm.deleteWatch = deleteWatch;
        vm.deleteNotification = deleteNotification;

        function getData() {
            if(saving) { return false; }
            $http.get(API_URL + 'projects/watch/').then(function(res) {
                vm.data = res.data.payload;
            });
        }

        function deleteWatch(watch) {
            var url = API_URL + 'projects/watch/' + watch.project + '/';
            $http({
                url: url,
                method: 'delete'
            }).then(function(res) {
                getData();
            });
        }

        function deleteNotification(watch) {
            var url = API_URL + 'projects/notification/' + watch.id + '/';
            $http({
                url: url,
                method: 'delete'
            }).then(function(res) {
                getData();
            });
        }

        getData();

        interval = $interval(function() {
            getData();
        }, 360000);

        $scope.$on('$destroy', function() {
            if (angular.isDefined(interval)) {
                $interval.cancel(interval);
                interval = undefined;
            }
        });

        vm.dragOptions = {
            handle: ".handle",
            placeholder: "beingDragged",
            stop: sortingStop,
            sort: function(event, ui) {
                if(ui.position.top < 0) {
                    ui.helper.css({'top' : ui.position.top + $(window).scrollTop() + 'px'});
                }

            }
        };

        var sortingDebounce;
        function sortingStop(e, ui) {
            if(saving) { return false; }
            if ( sortingDebounce ) {
                $timeout.cancel(sortingDebounce);
            }
            sortingDebounce = $timeout(function() {
                saveAllWatches().then(function() {
                    sortingDebounce = null;
                });
            }, 500);

        }
        var saving = false;
        function saveAllWatches() {
            saving = true;
            var promises = [];
            angular.forEach(vm.data, function(item, idx) {
                var promise;
                var url = API_URL + 'projects/watch-detail/' + item.id + '/';
                item.order = idx + 1;
                promise = $http.post(url, item);
                promises.push(promise);

            });

            return $q.all(promises).then(function() {
                saving = false;
            });

        }
    }


})();
