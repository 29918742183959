(function () {
    "use strict";

    angular
        .module('slate.app')
        .directive('slateWizard', slateWizard);

    function slateWizard() {
        return {
            restrict: 'E',
            templateUrl: '/partials/slate/slate.wizard.dir.html',
            transclude: true,
            controller: wizardController,
            controllerAs: 'wizard',
            scope: {
                control: "="
            }
        };
    }

    function wizardController($scope) {
        /* jshint validthis: true */
        var wizard = this;
        wizard.steps = [];
        wizard.step = 0;

        wizard.addStep = addStep;
        wizard.removeStep = removeStep;
        wizard.setStep = setStep;
        wizard.isComplete = isComplete;
        wizard.isFirstStep = isFirstStep;
        wizard.isLastStep = isLastStep;
        wizard.handleNext = handleNext;
        wizard.handlePrevious = handlePrevious;

        $scope.control.wizard = wizard;

        function addStep(step) {
            wizard.steps.push(step);
        }

        function removeStep(step) {
            var items = [];
            angular.forEach(wizard.steps, function (item, idx) {
                if (item.name == step.name) {
                    items.unshift(idx);
                }
            });
            angular.forEach(items, function (idx) {
                wizard.steps.splice(idx, 1);
            });
        }

        function setStep(index) {
            wizard.step = index;
        }

        function isComplete() {
            var complete = true;
            angular.forEach(wizard.steps, function (step) {
                if (complete && !step.optional && typeof step.complete == "function" && !step.complete()) {
                    complete = false;
                }
            });
            return complete;
        }

        function isFirstStep() {
            return wizard.step === 0;
        }

        function isLastStep() {
            return wizard.step === (wizard.steps.length - 1);
        }

        function handlePrevious() {
            wizard.step -= (wizard.isFirstStep()) ? 0 : 1;
        }

        function handleNext() {
            if (!wizard.isLastStep()) {
                wizard.step += 1;
            }
        }



    }

})();
