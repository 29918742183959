(function () {
    'use strict';

    angular
        .module('slate.reports')
        .directive('slateReportUnbilledShipments', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.unbilled_shipments.dir.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function ReportController($scope, $q, $http, $location, $httpParamSerializer, SlateAuth, SlateBilling) {
        /* jshint validthis: true */
        const vm = this;
        const apiUrl = `${API_URL}reports/unbilled_shipments/`;

        vm.auth = SlateAuth;

        vm.getDownloadUrl = getDownloadUrl;
        vm.updateFromFilter = updateFromFilter;
        vm.getBillingTotal = getBillingTotal;
        vm.getMonth = getMonth;
        vm.getMonthTotals = getMonthTotals;

        let modelPromise;
        let canceller = null;

        vm.data = [];
        vm.filters = $location.search();
        vm.loading = false;
        vm.sortOrder = 'date_of_movement';
        vm.reverse = true;


        vm.start_date = new Date();
        vm.start_date.setMonth(vm.start_date.getMonth() - 3);
        vm.end_date = new Date();
        vm.end_date.setDate(vm.end_date.getDate() + 1);

        vm.date_fields = ['start_date', 'end_date'];

        vm.date_fields.forEach((dateField) => {
            if (vm.filters[dateField]) {
                const parts = vm.filters[dateField].split('-');
                vm[dateField] = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
            }
            $scope.$watch(`vm.${dateField}`, () => {
                if (vm[dateField]) {
                    vm.filters[dateField] = `${vm[dateField].getFullYear()}-${vm[dateField].getMonth() + 1}-${vm[dateField].getDate()}`;
                } else {
                    vm.filters[dateField] = null;
                }
            });
        });

        $scope.$watchCollection(() => vm.filters, (value) => {
            updateFromFilter();
        });

        function updateFromFilter() {
            vm.loading = true;

            if (canceller) { canceller.resolve(); }
            canceller = $q.defer();

            angular.forEach(vm.filters, (value, item) => {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
                timeout: canceller.promise
            });

            modelPromise.then((resp) => {
                vm.data = resp.data.payload;
                getPMTotals();
                vm.loading = false;
                canceller = null;
            });
        }

        function getDownloadUrl() {
            return `${apiUrl}?download=true&${$httpParamSerializer(vm.filters)}`;
        }

        function getBillingTotal(item) {
            const billing = new SlateBilling();
            billing.loadFromPayload(item);
            item.totalSell = billing.getTotal();
            return parseFloat(item.totalSell);
        }

        function getShipmentMonth(shipment) {
            let date_value = shipment.eta;
            if (!date_value) {
                date_value = shipment.etd;
            }
            const parts = date_value.split('-');
            const date = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
            return date;
        }

        function getShipmentTotals(shipments, months) {
            let total = 0;
            shipments.forEach((shipment) => {
                let shipment_total = 0;

                // get month
                const raw_date = getShipmentMonth(shipment);
                const month = `${raw_date.getFullYear()}-${raw_date.getMonth() + 1}`;
                const formatted_month = `${dateFns.format(raw_date, 'MMM')}, ${raw_date.getFullYear()}`;
                if (!months[formatted_month]) {
                    months[formatted_month] = {
                        unformatted: month,
                        total: 0.0
                    };
                }

                shipment.billings.forEach((billing) => {
                    const bill_total = getBillingTotal(billing);
                    total += bill_total;
                    shipment_total += bill_total;
                    months[formatted_month].total += bill_total;
                });

                shipment.totalSell = shipment_total;
            });
            return total;
        }

        vm.PMTotals = {};
        vm.overallTotal = 0;
        function getPMTotals() {
            Object.keys(vm.data).forEach((key) => {
                if (!vm.data[key].months) {
                    vm.data[key].months = {};
                }
                const total = getShipmentTotals(vm.data[key].shipments, vm.data[key].months);
                vm.PMTotals[key] = total;
                vm.overallTotal += total;
            });
            return vm.overallTotal;
        }

        function getMonth(shipment) {
            if (!shipment) { return ''; }

            const date = getShipmentMonth(shipment);
            return `${dateFns.format(date, 'MMM')}, ${date.getFullYear()}`;
        }

        vm.getMonthTotals = [];
        function getMonthTotals() {
            const months = {};
            Object.keys(vm.data).forEach((pm_id) => {
                vm.data[pm_id].forEach((shipment) => {
                    let date_value = shipment.eta;
                    if (!date_value) {
                        date_value = shipment.etd;
                    }
                    const parts = date_value.split('-');
                    const date = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
                    const formated_date = `${dateFns.format(date, 'MMM')}, ${date.getFullYear()}`;
                    shipment.billings.forEach((billing) => {

                    });
                });
            });
        }
    }
})();
