(function () {
    "use strict";

    angular
        .module('slate.logistics')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('shipmentListPage', {
                url: '/logistics/shipment/',
                template: "<div class='content-pane list-page'><slate-shipments-list></slate-shipments-list></div>",
                current_app: 'logistics',
                slateTitle: 'Shipments',
            })

            .state('shipmentNewPage', {
                url: '/logistics/shipment/new/',
                templateUrl: '/partials/logistics/shipment.edit.page.html',
                controller: 'SlateShipmentNewPageController',
                controllerAs: 'vm',
                current_app: 'logistics',
                slateTitle: 'Create New Shipment - Logistics',
            })
            .state('shipmentEditPage', {
                url: '/logistics/shipment/:id/edit/',
                templateUrl: '/partials/logistics/shipment.edit.page.html',
                controller: 'SlateShipmentEditPageController',
                controllerAs: 'vm',
                current_app: 'logistics',
                slateTitle: 'Edit Shipment - Logistics',
            })
            .state('shipmentFollowupPage', {
                url: '/logistics/shipment/:id/ship/',
                templateUrl: '/partials/logistics/followup/ship.new.page.html',
                controller: 'SlateFollowupShipmentNewPageController',
                controllerAs: 'vm',
                current_app: 'logistics',
                slateTitle: 'Ship Followup - Logistics',
            })

            ;
    }
})();