(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('rfqListPage', {
                url: '/rfqs/',
                template: "<div class='content-pane list-page'><slate-rfqs-list></slate-rfqs-list></div>",
                current_app: 'rfqs',
                slateTitle: 'RFQs',
            })
            .state('rfqNewPage', {
                url: '/rfqs/new/',
                templateUrl: '/partials/rfqs/rfqs.new.page.html',
                controller: 'SlateRfqNewPageController',
                controllerAs: 'vm',
                current_app: 'rfqs',
                slateTitle: 'Create New RFQ - RFQs',
            })
            .state('rfqEditPage', {
                url: '/rfqs/:id/edit/',
                templateUrl: '/partials/rfqs/rfq.edit.page.html',
                controller: 'SlateRfqEditPageController',
                controllerAs: 'vm',
                current_app: 'rfqs',
                slateTitle: 'Edit RFQ - RFQs',

            })
            .state('rfqLegacyEditPage', {
                url: '/rfqs/:id/legacy_edit/',
                templateUrl: '/partials/rfqs/rfqs.legacy_edit.page.html',
                controller: 'SlateRfqLegacyEditPageController',
                controllerAs: 'vm',
                current_app: 'rfqs',
                slateTitle: 'Edit RFQ - RFQs',
            })
            .state('rfqResponsePage', {
                url: '/rfq-response/:id/',
                templateUrl: '/partials/rfqs/rfq.response.page.html',
                controller: 'SlateRfqResponsePageController',
                controllerAs: 'vm',
                current_app: 'rfqs',
                slateTitle: 'Response - RFQs',
            })
            .state('rfqResponseSummaryPage', {
                url: '/rfqs/:id/summary/',
                templateUrl: '/partials/rfqs/rfq.response.summary.page.html',
                controller: 'SlateRfqResponseSummaryPageController',
                controllerAs: 'vm',
                current_app: 'rfqs',
                slateTitle: 'Response Summary - RFQs',
            })
            .state('rfqResponseComparisonPage', {
                url: '/rfqs/:id/comparison/',
                templateUrl: '/partials/rfqs/rfq.response.comparison.page.html',
                controller: 'SlateRfqResponseComparisonPageController',
                controllerAs: 'vm',
                current_app: 'rfqs',
                slateTitle: 'Response Comparison - RFQs',
            })

            ;
    }
})();