// import { request } from "http";

(function () {
    'use strict';

    angular
        .module('slate.library.billings')
        .factory('SlateBillings', ModelList)
        .factory('SlateBilling', ModelDetail);

    const apiUrl = `${API_URL}billings/billing/`;

    function ModelList($http, $q, SlateBilling) {
        const list = function () {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];
            self.pagination = {
                total: 0,
                page: 1,
                count: 25
            };

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            const promise = $http.get(apiUrl, {
                params: self.filters,
                timeout: self.canceller.promise
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateBilling();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.pagination = {
                    total: parseInt(response.data.pagination.total, 10),
                    page: parseInt(response.data.pagination.page, 10),
                    count: parseInt(response.data.pagination.count, 10)
                };
                self.permissions = response.data.permissions;
                self.canceller = null;


                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q, SlateBillingLineItem) {
        // instantiate our initial object
        const model = function () {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', def: null, readonly: true },
            { name: 'name' },
            { name: 'status', def: 'Draft' },
            { name: 'billing_type' },
            { name: 'posting_status', readonly: true },

            { name: 'shipment' },
            { name: 'shipment_info', readonly: true },

            { name: 'invoice_num', readonly: true },

            { name: 'salesorder' },

            { name: 'company' },
            { name: 'company_info', readonly: true },

            { name: 'proj_mgr' },
            { name: 'proj_mgr_info', readonly: true },

            { name: 'sales_mgr' },
            { name: 'sales_mgr_info', readonly: true },

            { name: 'order' },
            { name: 'order_info', readonly: true },

            { name: 'is_tooling', def: false, readonly: true },

            { name: 'date', type: 'dateOnly' },

            { name: 'notes' },

            { name: 'delivery_street' },
            { name: 'delivery_city' },
            { name: 'delivery_state' },
            { name: 'delivery_postal' },
            { name: 'delivery_country' },

            { name: 'line_items', type: 'child_list', model: SlateBillingLineItem },

            { name: 'sf_id', readonly: true },

            { name: 'created', readonly: true },
            { name: 'created_by', readonly: true },
            { name: 'updated', readonly: true },
            { name: 'deleted', readonly: true, default: false },

            { name: 'approved_by', readonly: true },
            { name: 'approval_time', readonly: true },
            { name: 'confirmed_totals', def: false },
            { name: 'confirmed_responsible', def: false }
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.getTotal = getTotal;
        model.prototype.getIsNonInventory = getIsNonInventory;

        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;

                if (field.type === 'child_list') {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                // eslint-disable-next-line no-console
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                if (!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if (field.type === 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type === 'dateOnly' && payload[field.name]) {
                    if (typeof payload[field.name] === 'string') {
                        const parts = self[field.name].split('-');
                        self[field.name] = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
                    }
                }

                if (field.type === 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if (field.type === 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                        // catch this.
                    }
                }

                if (field.type === 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach((item, idx, array) => {
                            const obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.log('error loading: ', e);
                    }
                }
                return null;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                // eslint-disable-next-line no-console
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            const promise = $http.get(modelUrl);

            promise.then((response) => self.loadFromPayload(response.data.payload).then(() => {
                self.permissions = response.data.permissions;
                deferred.resolve(self);
            }));

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (data[field.name] && field.type === 'date') {
                        data[field.name] = `${self[field.name].getUTCFullYear()}-${self[field.name].getUTCMonth() + 1}-${self[field.name].getUTCDate()}`;
                    }
                    if (field.type === 'dateOnly' && self[field.name]) {
                        data[field.name] = `${self[field.name].getFullYear()}-${self[field.name].getMonth() + 1}-${self[field.name].getDate()}`;
                    }

                    if (field.type === 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if (field.type === 'child_list' && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach((item) => {
                            if (!item.toDelete) {
                                data[field.name].push(item.getModelData());
                            }
                        });
                    }
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            const promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function getTotal() {
            /* jshint validthis: true */
            const self = this;
            let total = new Big(0);
            self.line_items.forEach((item) => {
                let lineitem = item;
                if (!item.hasOwnProperty('getSellTotal')) {
                    lineitem = new SlateBillingLineItem();
                    lineitem.loadFromPayload(item);
                }
                total = total.plus(lineitem.getSellTotal());
            });
            return total.toFixed(2);
        }

        function getIsNonInventory() {
            /* jshint validthis: true */
            const self = this;
            return self.line_items.every((item) => {
                if (!item.finished_good_info) { return false; }
                return item.finished_good_info.non_inventory;
            });
        }
    }
})();
