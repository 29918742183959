(function() {
    "use strict";

    angular
        .module('slate.invoices')
        .controller('SlateInvoiceEmailEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateInvoiceEmail) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.email = new SlateInvoiceEmail();

        vm.email.loadFromServer($stateParams.id).then(function () {
            vm.loading = false;
            
        }).catch(angular.noop);

    }

})();


