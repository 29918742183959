(function() {
    "use strict";

    angular
        .module('slate.library.qc')
        .factory('SlateQCLogs', ModelList)
        .factory('SlateQCLog', ModelDetail);

    var apiUrl = API_URL + 'qc/qclog/';

    function ModelList($http, $q, SlateQCLog) {
        var list = function construct() {
            var self = this;
            self.clear();
            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlateQCLog();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.totalItems = response.data.totalItems;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },

            {name: 'name'},
            {name: 'name_en', readonly: true},
            {name: 'date', type: 'date'},
            {name: 'status'},

            {name: 'client'},
            {name: 'client_info', readonly: true},
            {name: 'sales_mgr'},
            {name: 'sales_mgr_info', readonly: true},
            {name: 'proj_mgr'},
            {name: 'proj_mgr_info', readonly: true},

            {name: 'project'},
            {name: 'project_info', readonly: true},

            {name: 'component'},
            {name: 'component_info', readonly: true},

            {name: 'order'},
            {name: 'order_info', readonly: true},

            {name: 'purchase_order'},
            {name: 'purchase_order_info', readonly: true},


            {name: 'factory'},
            {name: 'factory_location'},
            {name: 'factory_location_en', readonly: true},
            {name: 'time_spent', type: 'float'},
            {name: 'customer'},
            {name: 'po'},
            {name: 'travel_spent', type: 'float'},
            {name: 'travel_mode'},
            {name: 'location'},
            {name: 'location_name', readonly: true},
            {name: 'inspection_type'},
            
            {name: 'updated', readonly: true, type: 'date'},
            {name: 'created', readonly: true, type: 'date'},
            {name: 'created_by', readonly: true},
            {name: 'created_by_info', readonly: true},

        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }



        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                self[field.name] = payload[field.name];

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if (field.type == 'date' && payload[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) { console.log('didnt parse json', e); }
                }

            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];
                
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;
                
                deferred.resolve(self);
                
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            angular.forEach(fields, function(field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if(field.type == "date" && self[field.name]) {
                        //data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth()+1) + '-' + self[field.name].getUTCDate();
                        data[field.name] = self[field.name].toISOString();
                    }
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
