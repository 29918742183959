(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .service('SlatePOLineItemEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlatePOLineItem) {
        var templateUrl = '/partials/purchaseorders/po.lineitem.edit.sidebar.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(item, options) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj: function () {
                        var newitem = new SlatePOLineItem();
                        if(!item) {
                            return newitem;
                        }
                        if(typeof item == 'string') {
                            return newitem.loadFromServer(item);
                        }
                        return newitem.loadFromPayload(item);
                    },
                    purchaseOrder: function() {
                        if(typeof options === 'object' && options.purchaseOrder) {
                            return options.purchaseOrder;
                        }
                        return null;
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateAuth,  obj, purchaseOrder, SlateSettings, SlateCompany, SlateProject, SlateLocations, SlatePriceTier) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

        vm.item = obj;
        vm.purchaseOrder = purchaseOrder;
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.isComplete = isComplete;

        vm.setShipto = setShipto;
        vm.locations = new SlateLocations();

        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            //zIndex: 2501,
        };

        vm.units = [
            'ea',
            'box',
            'bottle',
            'g',
            'kg',
            'oz',
            'liter'
        ];


        function saveObject(skip) {
            $uibModalInstance.close(vm.item);
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }


        function isComplete() {
            if(!vm.item.finished_good) { return false; }
            if(!vm.item.component) { return false; }
            if(!vm.item.qty) { return false; }
            return true;
        }



        function projectSync() {
            var project = new SlateProject();
            project.loadFromServer(vm.item.component).then(function () {
                if (!vm.item.jansy_pn) {
                    if(project.internal_item_number) {
                        vm.item.jansy_pn = project.internal_item_number;
                    } else {
                        //vm.item.jansy_pn = project.composite_num;
                    }
                }

                vm.priceTier = null;
                if (!vm.item.id && project.price_tiers.length) {
                    vm.priceTier = project.price_tiers[0];
                    vm.item.price_tier = vm.priceTier.id;
                } else if (vm.item.price_tier) {
                    vm.priceTier = new SlatePriceTier();
                    vm.priceTier.loadFromServer(vm.item.price_tier);
                }

                if (!vm.item.name) {
                    if(project.variation_name) {
                        vm.item.name = project.variation_name;
                    } else {
                        vm.item.name = project.name;
                    }
                    vm.item.name = vm.item.name.substring(0,74);
                }
                if (!vm.item.client_pn) { vm.item.client_pn = project.client_item_number; }
                if (!vm.item.specs) { vm.item.specs = project.brief; }
                if (!vm.item.category) { vm.item.category = project.category; }
                if (!vm.item.material) { vm.item.material = project.material; }
                if (!vm.item.unit_name) { vm.item.unit_name = project.unit_name; }
                if (!vm.item.unitprice) { vm.item.unitprice = project.buy_price; }
                if (!vm.item.sellprice) { vm.item.sellprice = project.sell_price; }

            });

        }
        $scope.$watch('vm.item.component', function () {
            if (vm.item.component) {
                projectSync();
            }

        });

        $scope.$watch('vm.item.qty', function() {
            if (vm.priceTier) {
                vm.item.unitprice = vm.priceTier.getPrice(vm.item.qty);
            }
        });

        SlateSettings.getConfig('material_categories').then(function(config) {
            vm.categories = config;
        });
        SlateSettings.getConfig('materials').then(function(config) {
            vm.material_list = config;
            getMatList();
        });
        vm.materials = [];

        $scope.$watch('vm.item.category', function() {
            getMatList();
        });

        function getMatList() {
            var newlist = [];
            if(!vm.material_list || ! vm.material_list.value) { return []; }
            angular.forEach(vm.material_list.value, function(item) {
                if(item.category == vm.item.category) {
                    newlist.push(item);
                }
            });
            vm.materials = newlist;
            return newlist;
        }

        $scope.$watch('vm.shipto', function() {
            if(vm.shipto) {
                setShipto(vm.shipto);
            }
        });

        function setShipto(address) {
            console.log(address);
            var data = "";
            if(vm.company.name) { data += vm.company.name + "\n"; }
            if (address.street1) { data += address.street1 + "\n"; }
            if (address.street2) { data += address.street2 + "\n"; }
            if (address.street3) { data += address.street3 + "\n"; }
            if (address.city) { data += address.city + ","; }
            data += address.state + " " + address.postal;
            if (address.country) { data += "\n" + address.country; }

            vm.item.shipto = data;
        }


    }



})();
