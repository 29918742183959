(function() {
    "use strict";

    angular
        .module('slate.filebrowser')
        .service('SlateFileNodeUpload', editDialog);


    function editDialog($rootScope, $q, $uibModal) {
        var templateUrl = '/partials/filebrowser/filebrowser.upload.dialog.html';

        var srv = {
            edit: editObj
        };
        return srv;


        function editObj(obj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                size: 'lg',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',

                resolve: {
                    obj: function() {
                            return obj;
                    },
                }
            });

            return modalInstance.result.then(function(results) {
                return results;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateFileNode, obj) {
        /* jshint validthis: true */
        var vm = this;

        if(!obj) {
            vm.obj= {};
        } else {
            vm.obj = obj;
        }

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.removeFile = removeFile;

        vm.files = [];
        vm.dirs = {node:null, children: {}};

        function saveObject() {
            var promises = [];
            var filepromise = $q.resolve();
            vm.saving = true;
            vm.savedFiles = [];
            angular.forEach(vm.files, function(file) {

                var newFile = new SlateFileNode();
                var pathParts = [];

                vm.obj.filename = file.name;
                newFile.loadFromPayload(vm.obj);


                if(file.path) {
                    pathParts = file.path.split('/');
                    pathParts.pop();
                    newFile.path = pathParts.join('/');
                }

                filepromise = filepromise.then(function() {
                    return newFile.saveModel(file).then(function() {
                        vm.savedFiles.push(newFile);
                    });
                });
            });
            promises.push(filepromise);

            $q.all(promises).then(function() {
                vm.saving = false;
                $uibModalInstance.close(vm.savedFiles);
            });

        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function removeFile(index) {
            vm.files.splice(index, 1);
        }

        $scope.$watch('vm.filesAdd', function() {
            angular.forEach(vm.filesAdd, function(file) {
                vm.files.push(file);
            });
        });


    }



})();
