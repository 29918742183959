(function () {
    'use strict';

    angular
        .module('slate.companies')
        .service('SlateCompanyEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateCompany) {
        const templateUrl = '/partials/companies/company.edit.dialog.html';

        const srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj) {
            let modalInstance;

            // eslint-disable-next-line prefer-const
            modalInstance = $uibModal.open({
                templateUrl,
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    obj() {
                        if (!obj.id) {
                            return obj;
                        }
                        const co = new SlateCompany();
                        return co.loadFromServer(obj.id);
                    }
                }
            });

            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($http, $q, $uibModalInstance, obj, SlateAuth, SlateSettings, SlateCompanies, SlateCompanyBrands) {
        /* jshint validthis: true */
        const vm = this;

        vm.auth = SlateAuth;
        vm.obj = obj;
        vm.baseHoldStatus = vm.obj.on_hold;
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.wizardControl = {};
        vm.countries = Countries;

        vm.brands = new SlateCompanyBrands();
        if (vm.obj.id) {
            vm.brands.getList({ company: vm.obj.id });
        }

        vm.canFullEdit = canFullEdit;
        vm.combineCompany = combineCompany;
        vm.addLocation = addLocation;
        vm.addContactMethod = addContactMethod;
        vm.cap_prefix = cap_prefix;
        vm.prefixCheck = prefixCheck;
        vm.nameCheck = nameCheck;
        vm.nameCheckZH = nameCheckZH;

        vm.detailsIsComplete = detailsIsComplete;
        vm.hasAddresses = hasAddresses;
        vm.salesComplete = salesComplete;
        vm.qcComplete = qcComplete;
        vm.shippingAccountsComplete = shippingAccountsComplete;
        vm.brandComplete = brandComplete;

        vm.requestBudgetCompany = requestBudgetCompany;

        vm.getCaps = getCaps;
        vm.getMats = getMats;

        vm.budget_start_date = new Date();
        vm.budget_start_date = new Date(vm.budget_start_date.getFullYear() + 1, 0, 1);


        /* beautify preserve:start */

        vm.sources = [
            'Salesperson',
            'Cold Calling',
            'Website',
            'LinkedIn',
            'Social Media',
            'Legacy'
        ];

        vm.caps = [
            'Aerosol Can',
            'Barrier Bag',
            'Blow Molding',
            'Bottles',
            'Closure Caps (Disc cap, Flip top cap)',
            'Cosmetic Compact',
            'Cosmetics Components',
            'Cut & Sew',
            'Cylinder Tube',
            'Deodorant Stick',
            'Dispensar (Pump, Sprayer)',
            'Display Shipper',
            'Double Flute Corrugate',
            'Eye Shadow Pot',
            'Filling',
            'Flow Pack Wrapper',
            'Foam',
            'Folding Carton',
            'Folding Cartons',
            'Foundation Stick',
            'Fulfilment',
            'Glass',
            'Hair Spray',
            'Injection Molding',
            'Jars',
            'Lip Pot',
            'Lip Stick Tube',
            'Make up brush',
            'Mascara Tube',
            'Molded Fiber',
            'POP Display',
            'Paper Bag',
            'Paper Board',
            'Paperfoam',
            'Point of Sale',
            'Pressed Powder Compacts',
            'Resealable Bag',
            'Rigid & Set up Box',
            'Single Flute Corrugate',
            'Spray Can',
            'Thermoform',
            'Tin Can',
            'Tins',
            'Tubes',
            'Warehousing',
            'Wood'
        ];
        /* beautify preserve:end */
        vm.categories = [];
        SlateSettings.getConfig('material_categories').then((config) => {
            vm.categories = config.value;
        });
        vm.materials = [];
        SlateSettings.getConfig('materials').then((config) => {
            vm.materials = config.value.map((item) => item.name);
        });

        vm.existingCoList = new SlateCompanies();
        vm.existingCoNameList = new SlateCompanies();
        vm.existingCoNameZHList = new SlateCompanies();

        function getCaps(search) {
            let newcaps = [];
            newcaps = newcaps.concat(vm.caps.filter((item) => item.indexOf(search) > -1));
            if (vm.categories.length) {
                newcaps = newcaps.concat(vm.categories.filter((item) => item.indexOf(search) > -1));
            }
            if (vm.materials.length) {
                newcaps = newcaps.concat(vm.materials.filter((item) => item.indexOf(search) > -1));
            }
            if (search && newcaps.indexOf(search) === -1) {
                newcaps.unshift(search);
            }
            return newcaps.filter((value, index, self) => self.indexOf(value) === index);
        }

        function getMats(search) {
            let newcaps = [];
            if (vm.categories.length) {
                newcaps = newcaps.concat(vm.categories.filter((item) => item.toLowerCase().indexOf(search.toLowerCase()) > -1));
            }
            if (vm.materials.length) {
                newcaps = newcaps.concat(vm.materials.filter((item) => item.toLowerCase().indexOf(search.toLowerCase()) > -1));
            }
            newcaps = newcaps.filter((value, index, self) => self.indexOf(value) === index);
            return newcaps.filter((value, index, self) => vm.obj.material_categories.indexOf(value) === -1);
        }

        function canFullEdit() {
            return vm.auth.perms.can_delete_any_object;
        }

        function combineCompany() {
            if (!vm.obj.companyToCombine) { return false; }
            if (!vm.auth.user.is_admin) { return false; }

            vm.saving = true;
            vm.obj.saveModel().then(() => {
                vm.saving = false;
                $uibModalInstance.close(vm.obj);
            }, () => {
                vm.saving = false;
                vm.errorSaving = true;
            });
            return true;
        }

        function saveObject() {
            const deferred = $q.defer();

            vm.saving = true;
            if (!vm.obj.country) {
                vm.obj.country = vm.obj.locations.list[0].country;
            }
            vm.obj.saveModel().then(() => {
                const promises = [];
                if (vm.budgets && vm.budgets.list) {
                    vm.budgets.list.forEach((budget) => {
                        if (budget.sales_mgr !== vm.auth.user.contact_id || budget.approved) { return false; }
                        promises.push(budget.saveModel());
                        return true;
                    });
                }
                if (vm.brands && vm.brands.list) {
                    vm.brands.list.forEach((brand) => {
                        brand.company = vm.obj.id;
                        promises.push(brand.saveModel());
                    });
                }
                $q.all(promises).then(() => { deferred.resolve(vm.obj); });
            }, () => {
                vm.saving = false;
                vm.errorSaving = true;
            });

            deferred.promise.then(() => {
                vm.saving = false;
                $uibModalInstance.close(vm.obj);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function addLocation() {
            vm.obj.locations.addBlank();
        }

        function addContactMethod() {
            vm.obj.contactmethods.addBlank();
        }

        function detailsIsComplete() {
            if (!vm.obj.name) {
                return false;
            }
            return true;
        }

        function salesComplete() {
            if (!vm.obj.sales_mgr) { return false; }

            return true;
        }

        function qcComplete() {
            if (vm.obj.vendor && !vm.obj.inspector) { return false; }
            if (vm.obj.vendor && !vm.obj.qc_contact) { return false; }
            return true;
        }

        function accountDetailsComplete() {
            return vm.obj.shipping_accounts.list.every((account) => account.account_type && account.value);
        }

        function shippingAccountsComplete() {
            if (vm.obj.shipping_accounts.list.length === 0) { return true; }
            return vm.obj.shipping_accounts.list.length > 0 && accountDetailsComplete();
        }

        function hasAddresses() {
            if (vm.obj.locations && vm.obj.locations.list && vm.obj.locations.list.length < 1) {
                return false;
            }

            if (!vm.obj.locations.list.every((location, idx, array) => {
                if (!location.qualifier || location.qualifier.length < 1) { return false; }
                if (location.is_warehouse && !location.warehouse_name) { return false; }
                return true;
            })) { return false; }

            return true;
        }

        function brandComplete() {
            if (vm.brands.list && vm.brands.list.length === 0) { return true; }
            if (!vm.brands.list.every((brand) => {
                if (!brand.contact) { return false; }
                if (!brand.brand) { return false; }
                return true;
            })) { return false; }
            return true;
        }

        function cap_prefix() {
            vm.obj.prefix = vm.obj.prefix.toUpperCase();
        }

        function prefixCheck() {
            cap_prefix();
            vm.existingCoList.getList({ prefix: vm.obj.prefix });
        }

        function nameCheck() {
            if (vm.obj.name.length > 4) {
                vm.existingCoNameList.getList({ q: vm.obj.name });
            } else {
                vm.existingCoNameList.list = [];
            }
        }

        function nameCheckZH() {
            if (vm.obj.name_zh.length > 0) {
                vm.existingCoNameZHList.getList({ q: vm.obj.name_zh });
            } else {
                vm.existingCoNameZHList.list = [];
            }
        }

        function requestBudgetCompany() {
            const apiUrl = `${API_URL}salesbudgets/request-new/`;
            vm.saving = true;

            $http.post(apiUrl, { company: vm.obj.id, start: `${vm.budget_start_date.getFullYear()}-${vm.budget_start_date.getMonth() + 1}` }).then(() => {
                vm.saving = false;
                vm.budgets.getList();
            }, () => {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }
    }
})();
