(function () {
    'use strict';

    angular
        .module('slate.productprofiles')
        .service('SlateProductProfileLineItemEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateProductProfileItem) {
        const templateUrl = '/partials/productprofiles/productprofile.item.sidebar.srv.html';

        const srv = {
            edit: editObj
        };


        return srv;


        function editObj(item) {
            const modalInstance = $uibModal.open({
                templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    obj() {
                        const newitem = new SlateProductProfileItem();
                        if (!item) {
                            return newitem;
                        }
                        if (typeof item === 'string') {
                            return newitem.loadFromServer(item);
                        }
                        return newitem.loadFromPayload(item);
                    }
                }
            });

            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateAuth, SlateLocations, SlateCompanyBrands, obj) {
        /* jshint validthis: true */
        const vm = this;

        vm.item = obj;
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.auth = SlateAuth;

        vm.isComplete = isComplete;
        vm.setAddress = setAddress;

        vm.countries = Countries;

        vm.locations = new SlateLocations();
        vm.locations.getList({ mine: true });

        vm.brands = new SlateCompanyBrands();
        $scope.$watch('vm.item.brand', () => {
            if (vm.item.brand) { vm.brands.getList({ company: vm.item.brand }); }
            if (!vm.item.brand) {
                vm.brands.list = [];
                vm.item.label = '';
            }
        });
        $scope.$watch('vm.item.label', () => {
            if (!vm.item.contact && vm.item.label) {
                const label = vm.brands.list.find((item) => item.id === vm.item.label);
                if (label === undefined) { return false; }
                vm.item.contact = label.contact;
            }
            return false;
        });

        vm.checkOtherFragrance = checkOtherFragrance;

        if (!vm.item.id) { vm.show_only_required = true; }

        vm.example_product_list = [
            'Sour Apple Hair Gel',
            'Pineapple Pizza Body Lotion',
            'Perri-air',
            'Zit popping face wash',
            'Eau de cotton candy parfum',
            'Wiggle cream',
            'Rootbeer fiz scrub',
            'Bleu cheese eau de toilette'
        ];
        const random_idx = [];
        while (random_idx.length < 2) {
            const r = Math.floor(Math.random() * (vm.example_product_list.length - 1));
            if (random_idx.indexOf(r) === -1) random_idx.push(r);
        }
        vm.product_name_placeholder = `${vm.example_product_list[random_idx[0]]}, ${vm.example_product_list[random_idx[1]]}, etc`;

        vm.froalaOptions = {
            toolbarButtons: ['bold',
                'italic',
                'underline',
                '|',
                'align',
                'formatOL',
                'formatUL']
            // zIndex: 2501,
        };


        vm.units_imperial = [
            'oz.',
            'fl. oz.',
            'ea.',
            'ct.',
            'pair'
        ];
        vm.units_metric = [
            'g',
            'mL',
            'pair',
            'ea.',
            'ct.'
        ];

        vm.status_options = ['draft', 'ready', 'sent', 'confirmed'];

        function saveObject() {
            vm.saving = true;
            vm.error = false;
            if (vm.item.label === '') { vm.item.label = null; }
            return vm.item.saveModel().then(() => vm.fileSrv.uploadFiles('productprofileitem', vm.item.id).then(() => {
                $uibModalInstance.close(vm.item);
            }), () => {
                vm.saving = false;
                vm.error = true;
            });
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }


        function isComplete() {
            // if (!vm.item.client_pn) { return false; }
            if (!vm.item.brand) { return false; }
            if (!vm.item.contact) { return false; }
            if (!vm.item.name) { return false; }
            if (!vm.item.status) { return false; }
            if (!vm.item.due_date) { return false; }
            return true;
        }

        function setAddress(address) {
            let addy = address.street1;
            if (address.street2) { addy += `\n${address.street2}`; }
            if (address.street3) { addy += `\n${address.street3}`; }
            addy += `\n${address.city}, ${address.state} ${address.postal}`;
            addy += `\nAttn: ${vm.item.profile_info.name}`;

            vm.item.samples_destination = addy;
        }

        function checkOtherFragrance() {
            if (['neither', 'edt', 'edp'].includes(vm.item.fragrance_type)) {
                vm.item.fragrance_type = '';
                setTimeout(() => {
                    document.getElementById('fragrance_type_input').focus();
                }, 100);
            }
        }
    }
})();
