(function() {
    "use strict";

    angular
        .module('slate.customercomplaints')
        .directive('slateCustomerComplaintLineItemList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/customercomplaints/customercomplaint.lineitem.list.dir.html',
            scope: {
                complaint: '=',
                fileSrv: '=?',
                fields: '=?',
                dirty: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlateCustomerComplaintLineItemEditSrv) {
        /* jshint validthis: true */
        var vm = this;

        if(!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'jansy_pn',
                'name',
                'category',
                'material',
                'defect_rate',
                'qty',
            ];
            /* beautify preserve:end */
        }

        vm.editLineItem = editLineItem;

        function editLineItem(item, index) {
            SlateCustomerComplaintLineItemEditSrv.edit(item, {complaint: vm.complaint}).then(function(obj) {
                vm.complaint.line_items[index] = obj;
            }, function() {

            });
        }



    }


})();
