(function () {
    "use strict";

    angular
        .module('slate.orders')
        .directive('slateOpStepLines', modelList);

    function modelList() {
        return {
            restrict: 'A',
            //replace: 'true',
            templateUrl: '/partials/orderplan/order.step.line.dir.html',
            scope: {
                steps: '<'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $element, $timeout, $interval) {
        /* jshint validthis: true */
        var vm = this;
        vm.lines = [];
        vm.getPath = getPath;
        vm.width = 0;
        var frame;

        frame = requestAnimationFrame(getWidth);

        function getWidth() {
            var new_width = $element[0].parentElement.scrollWidth;
            if(new_width != vm.width) {
                $element[0].style.width = $element[0].parentElement.scrollWidth;
                //recalc();
            }
            //frame = requestAnimationFrame(getWidth);
        }

        /*
        $scope.$watchCollection('vm.steps.list', function (old_val, new_val) {
            console.log('saw new steps');
            $element[0].style.width = $element[0].parentElement.scrollWidth;
            $timeout(function() { recalc(); }, 200);
        });
*/
        $scope.$on('orderplan-step:ready', function() {
            recalc();
        });

        var display = document.getElementById('op-display');

        function getLine(startStep, endStep) {
            var start, end;
            var dispRect = display.getBoundingClientRect();
            var startX = 0;
            var startY = 0;
            var endX = 0;
            var endY = 0;
            if(!startStep || !endStep) { return false; }

            start = document.getElementById('step-' + startStep.id);
            end = document.getElementById('step-' + endStep.id);
            if(!start || !end) { return false; }
            startX = start.getBoundingClientRect().right - dispRect.left - (start.scrollWidth / 2);
            startY = start.getBoundingClientRect().bottom - dispRect.top;
            endX = end.getBoundingClientRect().right - dispRect.left - (end.scrollWidth / 2);
            endY = end.getBoundingClientRect().top - dispRect.top;
            return {
                startId: startStep.id,
                endId: endStep.id,
                startX: startX,
                startY: startY,
                endX: endX,
                endY: endY,
                width: start.scrollWidth,
                startColor: startStep.stepColor,
                endColor: endStep.stepColor
            };
        }

        var recalc = debounce(function () {
            var lines = [];
            $element[0].style.width = $element[0].parentElement.scrollWidth;
            vm.steps.list.forEach(function(step) {

                step.children.forEach(function(child) {
                    var fullChild = vm.steps.list.find(function(item) { return item.id == child.id; });
                    var line = getLine(step, fullChild);
                    if(line) {
                        lines.push(line);
                    }
                });
            });
            vm.lines = lines;
        }, 50);

        function getPath(line) {
            var path = "";
            var height = line.endY - line.startY;
            var descBase = 72;
            var descX = descBase; // 7.2rems div by 2
            var descY = descBase / 4; // 7.2rems div by 2
            var startX = line.startX;
            var startY = line.startY;
            var endX = line.endX;
            var endY = line.endY;



            //move to bottom center of element
            path = path + "M " + startX + " " + startY + " ";

            //descent
            path = path + "C " + startX + " " + endY + ", "; // go down
            path = path + endX + " " + startY + ", "; // both bars meet
            path = path + endX + " " + endY + ", "; // go right

            return path;
        }

    }

})();