(function () {
    'use strict';

    angular
        .module('slate.library.salesorders')
        .factory('SlateSOLineItems', ModelList)
        .factory('SlateSOLineItem', ModelDetail);

    const apiUrl = `${API_URL}salesorders/lineitem/`;

    function ModelList($http, $q, SlateSOLineItem) {
        // Set to url relative to api root.

        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let promise;
            const promises = [];

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateSOLineItem();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q, SlateBOMItems, SlateSORelease) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', readonly: true },
            { name: 'sales_order' },
            { name: 'name' },
            { name: 'code' },

            { name: 'finished_good' },
            { name: 'finished_good_info', readonly: true },
            { name: 'po_created', def: false, readonly: true },
            { name: 'bom' },
            { name: 'price_tier' },
            { name: 'price_tier_info', readonly: true },

            { name: 'category' },
            { name: 'material' },

            { name: 'specs' },
            { name: 'notes' },


            { name: 'qty', type: 'float', def: 0 },
            { name: 'unit_name', def: 'ea' },
            { name: 'unitprice', type: 'float', def: 0.00 },
            { name: 'sellprice', type: 'float', def: 0.00 },
            {
                name: 'bom_price', type: 'float', def: 0.00, readonly: true
            },
            { name: 'total_units_billed', type: 'float', def: 0 },
            { name: 'unfiltered_units_billed', type: 'float', def: 0 },
            { name: 'items_shipped', type: 'float', def: 0 },
            { name: 'has_billings', def: false },

            { name: 'is_non_inventory', readonly: true, def: false },

            { name: 'ready_date', type: 'dateOnly' },
            { name: 'required_date', type: 'dateOnly' },


            { name: 'jansy_pn' },
            { name: 'client_pn' },
            { name: 'vendor_pn' },

            {
                name: 'revised', def: [{}], type: 'json', readonly: true
            },
            { name: 'was_revised', def: false },
            { name: 'sort_order' },

            { name: 'ignore_on_pdf', def: false },

            { name: 'releases', type: 'child_list', model: SlateSORelease },

            { name: 'created', readonly: true },
            { name: 'created_by', readonly: true },
            { name: 'updated', readonly: true },
            { name: 'deleted', readonly: true, default: false }

        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.getModelData = getModelData;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.setFromProj = setFromProj;
        model.prototype.getCostTotal = getCostTotal;
        model.prototype.getBOMCostTotal = getBOMCostTotal;
        model.prototype.getBOMPrice = getBOMPrice;
        model.prototype.getSellTotal = getSellTotal;
        model.prototype.getBoms = getBoms;
        model.prototype.getUnreleased = getUnreleased;
        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;
                if (field.type == 'date' && !self[field.name]) {
                    self[field.name] = new Date();
                }
                if (field.type == 'child_list') {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                if (!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if (field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type == 'dateOnly' && payload[field.name]) {
                    if (typeof payload[field.name] === 'string') {
                        const parts = self[field.name].split('-');
                        self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
                    }
                }

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }
                if (field.type == 'int') {
                    self[field.name] = parseInt(self[field.name]);
                }


                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                    }
                }

                if (field.type == 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach((item, idx, array) => {
                            const obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch (e) {
                        console.log('error loading: ', e);
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            let promise;

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then((response) => {
                const promises = [];

                return self.loadFromPayload(response.data.payload).then(() => {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            const self = this;
            const data = {};

            angular.forEach(fields, (field) => {
                if ((!field.readonly || field.name == 'id') && typeof (self[field.name]) !== 'undefined') {
                    data[field.name] = self[field.name];
                    if (field.type == 'date') {
                        data[field.name] = `${self[field.name].getUTCFullYear()}-${self[field.name].getUTCMonth() + 1}-${self[field.name].getUTCDate()}`;
                    }
                    if (field.type == 'dateOnly' && self[field.name]) {
                        data[field.name] = `${self[field.name].getFullYear()}-${self[field.name].getMonth() + 1}-${self[field.name].getDate()}`;
                    }
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if (field.type == 'child_list' && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach((item) => {
                            if (item.toDelete) { return false; }
                            data[field.name].push(item.getModelData());
                        });
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;
            let data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            }

            data = getModelData();

            promise = $http.post(modelUrl, data);
            promise = promise.then((response) => {
                const promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function setFromProj(proj) {
            /* jshint validthis: true */
            const self = this;
            // self.jansy_pn = proj.composite_num;
            // bself.name = proj.name;
        }

        function getCostTotal() {
            /* jshint validthis: true */
            const self = this;
            let total = 0;
            let qty;
            let cost;
            let freight;

            try { cost = new Big(self.unitprice); } catch (e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch (e) { qty = new Big(0); }

            total = qty.times(cost);

            return total.toFixed(5);
        }

        function getBOMCostTotal() {
            /* jshint validthis: true */
            const self = this;
            let total = 0;
            let qty;
            let cost;
            let freight;

            try { cost = new Big(self.bomTotal); } catch (e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch (e) { qty = new Big(0); }

            total = qty.times(cost);

            return total.toFixed(5);
        }


        function getSellTotal() {
            /* jshint validthis: true */
            const self = this;
            let total = 0;
            let qty;
            let cost;
            let freight;

            try { cost = new Big(self.sellprice); } catch (e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch (e) { qty = new Big(0); }

            total = qty.times(cost);

            return total.toFixed(5);
        }

        function getBOMPrice(bomItemUnitCosts) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            const bomItems = new SlateBOMItems();
            let total = new Big(0.0);
            return bomItems.getList({ bom: self.bom }).then(() => {
                bomItems.list.forEach((item) => {
                    let qty_req = Big(item.qty_required);
                    const scrap = Big(item.scrap_factor).div(100);
                    const scrap_count = scrap.times(qty_req);

                    if (item.supplier == 'client') { return false; }

                    qty_req = qty_req.add(scrap_count);
                    total = total.add(Big(parseFloat(bomItemUnitCosts[item.id])).times(qty_req));
                });
                self.bomTotal = total.toFixed(5);
            });
        }

        function getBoms() {
            /* jshint validthis: true */
            const self = this;

            if (!self.bom_items) {
                self.bom_items = new SlateBOMItems();
            }
            return self.bom_items.getList({ bom: self.bom });
        }

        function getUnreleased() {
            /* jshint validthis: true */
            const self = this;
            let total = Big(0.0);
            let unreleased = Big(self.qty);
            self.releases.forEach((release) => {
                total = total.add(release.qty);
            });
            unreleased = unreleased.minus(total);
            return parseFloat(unreleased.toString());
        }
    }
})();
