(function () {
    'use strict';

    angular
        .module('slate.library.roles')
        .service('SlateRolesSrv', RolesService);

    function RolesService(SlateRolesList) {
        const loaded = false;
        const srv = {
            getList,

            listModel: new SlateRolesList(),
            list: [],
            dict: {},
            byName: {},
            assignable: [],
            requestable: []
        };

        return srv;

        function getList() {
            srv.promise = srv.listModel.getList();
            const assignableroles = [];
            const requestableroles = [];
            const rolesdict = {};
            const byname = {};
            return srv.promise.then(() => {
                // populate assignable roles
                srv.list = srv.listModel.list;
                angular.forEach(srv.list, (item, key) => {
                    rolesdict[item.id] = item;
                    byname[item.name] = item;
                    if (item.can_be_direct_assigned) {
                        assignableroles.push(item);
                    }
                    if (item.can_be_requested) {
                        requestableroles.push(item);
                    }
                });
                srv.assignable = assignableroles;
                srv.requestable = requestableroles;
                srv.dict = rolesdict;
                srv.byName = byname;
            });
        }
    }
})();
