(function () {
    'use strict';

    angular
        .module('slate.library.qima')
        .factory('SlateBookings', ModelList)
        .factory('SlateBooking', ModelDetail);

    const apiUrl = `${API_URL}qima/booking-drafts/`;

    function ModelList($http, $q, SlateBooking) {
        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            const promise = $http.get(apiUrl, {
                params: self.filters,
                timeout: self.canceller.promise
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model

                self.count = response.data.count;

                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateBooking();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.pagination = {
                    total: parseInt(response.data.pagination.total, 10),
                    page: parseInt(response.data.pagination.page, 10),
                    count: parseInt(response.data.pagination.count, 10)
                };
                self.canceller = null;

                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q, QIMAProduct) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', readonly: true },

            { name: 'user_id' },
            { name: 'order_id' },
            { name: 'qima_booking_number', readonly: true },
            { name: 'status' },

            { name: 'supplier_code' },
            { name: 'vendor' },
            { name: 'vendor_info', readonly: true },
            { name: 'service_type' },
            { name: 'service_date', type: 'dateOnly' },
            { name: 'ship_date', type: 'dateOnly' },

            { name: 'products', type: 'child_list', model: QIMAProduct },

            { name: 'created_by', readonly: true },
            { name: 'created', readonly: true },
            { name: 'updated', readonly: true }
        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.getModelData = getModelData;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = _.cloneDeep(field.def);
                if (field.type === 'date' && !self[field.name]) {
                    self[field.name] = new Date();
                }
                if (field.type === 'child_list') {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                console.log('Tried to load booking from server without id');
                return $q.reject(self);
            }

            const promise = $http.get(modelUrl);

            promise.then((response) => self.loadFromPayload(response.data.payload).then(() => {
                self.permissions = response.data.permissions;
                deferred.resolve(self);
            }));

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                if (!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if (field.type === 'dateOnly' && payload[field.name]) {
                    if (typeof payload[field.name] === 'string') {
                        self[field.name] = dateFns.parse(payload[field.name]);
                    }
                }
                if (field.type === 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach((item, idx, array) => {
                            const obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch (e) {
                        console.log('error loading: ', e);
                    }
                }

                return null;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            const self = this;
            const data = {};

            angular.forEach(fields, (field) => {
                if ((!field.readonly || field.name === 'id') && typeof (self[field.name]) !== 'undefined') {
                    data[field.name] = self[field.name];
                    if (field.type === 'dateOnly' && self[field.name]) {
                        data[field.name] = dateFns.format(self[field.name], 'YYYY-MM-DD');
                    }
                    if (field.type === 'json') {
                        data[field.name] = JSON.stringify(self[field.name], (key, value) => {
                            if (key === '$$hashKey') {
                                return undefined;
                            }
                            return value;
                        });
                    }
                    if (field.type === 'child_list' && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach((item) => {
                            if (item.toDelete) { return false; }
                            data[field.name].push(item.getModelData());
                            return null;
                        });
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;
            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            }

            const data = self.getModelData();

            const promise = $http.post(modelUrl, data);
            promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                deferred.resolve(self);
            }, () => {
                deferred.reject(self);
            });

            return deferred.promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            const promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }
})();
