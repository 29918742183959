(function () {
    "use strict";

    angular
        .module('slate.salesquotes')
        .directive('slateSalesEstimateEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/estimates/salesestimates.edit.dir.html',
            scope: {
                estimate: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $window,
        $state,
        $http,
        getLinkSrv,
        SlateAuth,
        SlateCompany,
        SlateLocations,
        SlateContactMethods,
        SlateRolesSrv,
        SlateAssignedRoles,
        SlateProject
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.saveObject = saveObject;
        vm.uploadFiles = uploadFiles;
        vm.isComplete = isComplete;

        vm.setAddress = setAddress;
        vm.hasAddress = hasAddress;

        vm.selectMethod = selectMethod;
        vm.contactMethods = contactMethods;

        vm.generatePDF = generatePDF;
        vm.duplicate = duplicate;

        vm.froalaOptions = {
            toolbarButtons: ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL", "|", "insertTable", ],
            zIndex: 10,
        };

        vm.co_addresses = new SlateLocations();
        vm.co_methods =  new SlateContactMethods();

        vm.countries = Countries;

        vm.minDate = new Date(new Date().getTime()+(1*24*60*60*1000));
        vm.maxDate = new Date(new Date().getTime()+(30*24*60*60*1000));

        vm.availStatus = ['draft', 'ready', 'sent', 'cancelled',];
        vm.baseStatus = vm.estimate.status;

        vm.StatusAvailable = [vm.estimate.status];
        checkAvailStatus();

        function checkAvailStatus() {
            vm.StatusAvailable = [vm.baseStatus];
            if(vm.baseStatus == 'draft') { vm.StatusAvailable.push('ready', 'cancelled'); }
            if(vm.baseStatus == 'ready') { vm.StatusAvailable.push('draft', 'sent', 'cancelled'); }
        }

        $scope.$watch('vm.estimate.id', function (old_val, new_val) {
            if (vm.estimate.id && !vm.saving) { updateDependants(); }
        });

        $scope.$watch('vm.estimate.company', function (old_val, new_val) {
            if (vm.estimate.company) {
                vm.co_addresses.getList({company: vm.estimate.company});
                vm.co_methods.getList({company: vm.estimate.company});
            }
        });

        function projectSync() {
            var assroles = new SlateAssignedRoles();
            var project = new SlateProject();
            var role_dict = SlateRolesSrv.dict;
            assroles.getList({
                parent_type: 'project',
                parent_fk: vm.estimate.project
            }).then(function () {

                angular.forEach(assroles.list, function (item) {
                    if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Sales Manager' && !vm.estimate.sales_mgr) {
                        vm.estimate.sales_mgr = item.member_fk;
                    }
                    if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Project Manager' && !vm.estimate.proj_mgr) {
                        vm.estimate.proj_mgr = item.member_fk;
                    }
                });

            });
            if (!vm.estimate.company) {
                project.loadFromServer(vm.estimate.project).then(function () {
                    if (project.parent_type == 'company') {
                        vm.estimate.company = project.parent_fk;
                    }
                });
            }

        }
        $scope.$watch('vm.estimate.project', function () {
            if (vm.estimate.project) {
                projectSync();
            }
        });

        function selectMethod(method) {
            vm.estimate.ship_phone = method.value;
        }

        function contactMethods() {
            if(!vm.co_methods.list) { return []; }
            return vm.co_methods.list.filter(function(item) {
                return ['phone', 'cell'].includes(item.method);
            });
        }

        function setAddress(address) {
            var selected_addr;
            function select_addr(item) {
                if(item.id == vm.selected_client_address) { selected_addr = item; }
            }
            vm.co_addresses.list.forEach(select_addr);
            if(!selected_addr) { return false;}

            vm.estimate.ship_street = selected_addr.street1;
            if(selected_addr.street2) {
                vm.estimate.ship_street = vm.estimate.ship_street + ", " + selected_addr.street2;
            }
            if(selected_addr.street3) {
                vm.estimate.ship_street = vm.estimate.ship_street + ", " + selected_addr.street3;
            }
            vm.estimate.ship_city = selected_addr.city;
            vm.estimate.ship_state = selected_addr.state;
            vm.estimate.ship_postal = selected_addr.postal;
            vm.estimate.ship_country = selected_addr.country;

            vm.selected_client_address = null;
        }

        function hasAddress() {
            if(
                !vm.estimate.ship_phone ||
                !vm.estimate.ship_street ||
                !vm.estimate.ship_city ||
                !vm.estimate.ship_state ||
                !vm.estimate.ship_postal
            ) { return false; }
            return true;
        }

        function updateDependants() {
            if(vm.estimate.id) {

            }
        }

        function isComplete() {
            if(!vm.estimate.name) { return false; }
            if(vm.estimate.status == 'draft') { return true; }

            if(!vm.estimate.project) { return false; }
            if(!vm.estimate.order) { return false; }
            if(!vm.estimate.company) { return false; }
            if(!vm.estimate.sales_mgr) { return false; }
            if(!vm.estimate.proj_mgr) { return false; }
            if(!vm.estimate.details.length) { return false; }

            if(['sent', 'cancelled'].includes(vm.baseStatus)) { return false; }

            return true;
        }

        function saveObject() {
            var deferred = $q.defer();

            if (vm.saving === true) {
                return false;
            }

            vm.saving = true;
            vm.error = false;

            // apply sort order to lineitems so they don't rearrange them selves
            vm.estimate.saveModel().then(function () {
                var promises = [];

                if (vm.fileSrv) {
                    promises.push(vm.fileSrv.uploadFiles('salesestimate', vm.estimate.id));
                }

                return $q.all(promises).then(function () {
                    $timeout(function () {
                        vm.saving = false;
                        vm.estimateFrom.$setPristine();
                        vm.fileSrv.files = [];
                        vm.baseStatus = vm.estimate.status;
                        checkAvailStatus();
                        if ($state.current.name != 'salesEstimateEditPage') {
                            $state.transitionTo(
                                'salesEstimateEditPage',
                                {id: vm.estimate.id},
                                {location: 'replace'}
                            );
                        }
                    }, 300);

                }, function () {
                    vm.saving = false;
                    vm.error = true;
                });

            }, function () {
                vm.saving = false;
                vm.error = true;
            });

            return deferred.promise;
        }

        function uploadFiles() {
            if (vm.saving === true) {
                return false;
            }

            vm.saving = true;
            vm.error = false;

            var promises = [];
            if (vm.fileSrv) {
                promises.push(vm.fileSrv.uploadFiles('salesestimate', vm.estimate.id));
            }

            return $q.all(promises).then(function () {
                    vm.saving = false;
                    vm.fileSrv.files = [];

            }, function () {
                vm.saving = false;
                vm.error = true;
            });
        }

        function generatePDF() {
            var url = API_URL + 'estimates/sales-estimate/' + vm.estimate.id + '/generatepdf/';

            if (vm.saving) { return false; }
            if (!vm.estimate.id) { return false; }

            vm.saving = true;
            vm.error = false;

            return $http.get(url).then(function(response) {
                var download_url = API_URL + 'files/download/' + response.data.node_id + '/';
                var window = $window.open(download_url, '_blank');
                vm.saving = false;
                $scope.$broadcast('updateFiles', 'salesestimate', vm.estimate.id);
            }, function() {
                vm.saving = false;
                vm.error = true;
            });
        }

        function duplicate() {
            var url = API_URL + 'estimates/sales-estimate/' + vm.estimate.id + '/duplicate/';

            if (vm.saving) { return false; }
            if (!vm.estimate.id) { return false; }

            vm.saving = true;
            vm.error = false;

            return $http.post(url).then(function(response) {
                $state.transitionTo(
                    'salesEstimateEditPage',
                    {id: response.data.payload}
                );
                vm.saving = false;

            }, function() {
                vm.saving = false;
                vm.error = true;
            });
        }

    }

})();
