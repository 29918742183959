(function() {
    'use strict';

    angular
        .module('slate.app')
        .directive('elementReady', elementReady);

    function elementReady($timeout, $rootScope) {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          $timeout(function() {
            element.ready(function() {
              scope.$apply(function() {
                $rootScope.$broadcast(attrs.elementReady + ':ready');
              });
            });
          });
        }
      };
    }

})();