(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .directive('slatePurchaseOrderReceive', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/purchaseorders/receive.edit.dir.html',
            scope: {
                purchaseOrder: '=',
                receive: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $q,
        $timeout,
        $state,
        $location,
        getLinkSrv,
        SlateAuth,
        SlateCompany,
        SlateLocations,
        SlatePOLineItems,
        SlatePOLineItem,
        SlatePOReceiveLineItem
    ) {
        /* jshint validthis: true */
        var vm = this;

        vm.auth = SlateAuth;

        vm.saveObject = saveObject;
        vm.isComplete = isComplete;

        vm.getVariance = getVariance;
        vm.has_variance = has_variance;
        vm.has_unders = has_unders;

        vm.companies_for_warehouses = [vm.purchaseOrder.vendor];
        vm.get_companies_for_warehouses = get_companies_for_warehouses;

        function get_companies_for_warehouses() {
            var companies = [vm.purchaseOrder.vendor];
            if(vm.auth.perms.purchaseorders_can_receive_at_client) {
                companies.push(vm.purchaseOrder.company);
            }
            vm.companies_for_warehouses = companies;
        }


        vm.lineItems = new SlatePOLineItems();

        vm.co_addresses = new SlateLocations();
        vm.vendor_addresses = new SlateLocations();

        $scope.$watch('vm.purchaseOrder.id', function (old_val, new_val) {
            get_companies_for_warehouses();
            if (vm.purchaseOrder.id && !vm.saving) {
                vm.lineItems.getList({purchase_order: vm.purchaseOrder.id});
            }
        });

        function saveObject() {
            var deferred = $q.defer();

            if(!vm.auth.perms.purchaseorders_can_receive) {
                return false;
            }

            if (vm.saving === true) {
                return false;
            }

            vm.saving = true;
            vm.error = false;

            vm.lineItems.list.forEach(function(item) {
                if(isNaN(item.qty_to_receive) || !item.qty_to_receive) { return false; }

                var receive_item = new SlatePOReceiveLineItem();
                receive_item.po_lineitem = item.id;
                receive_item.qty = item.qty_to_receive;
                receive_item.comments = item.receive_comments;
                vm.receive.line_items.push(receive_item);

            });

            vm.receive.saveModel().then(function() {
                var promises = [];

                if (vm.fileSrv) {
                    promises.push(vm.fileSrv.uploadFiles('purchaseorder', vm.purchaseOrder.id));
                }

                return $q.all(promises).then(function() {
                        $timeout(function() {
                            vm.saving = false;
                            deferred.resolve();
                            $state.transitionTo(
                                'purchaseOrderEditPage',
                                {id: vm.purchaseOrder.id},
                                {location: 'replace'}
                            );
                        }, 300);
                }, function() {
                    vm.saving = false;
                    vm.error = true;
                });
            }, function() {
                vm.saving = false;
                vm.error = true;
            });

            return deferred.promise;
        }

        function getVariance(po_lineitem) {
            var difference, qty_expected;
            if(!po_lineitem.qty_to_receive) { return 0; }

            qty_expected = po_lineitem.qty - po_lineitem.qty_received;
            difference = 100 * Math.abs( (qty_expected - po_lineitem.qty_to_receive) / ( (qty_expected+po_lineitem.qty_to_receive)/2 ) );
            if (po_lineitem.qty_to_receive < qty_expected) { difference = difference * -1; }
            return Math.round(difference);
        }

        function isComplete() {
            if(!vm.auth.perms.purchaseorders_can_receive) { return false; }
            if(!vm.receive.date) { return false; }
            if(!vm.receive.warehouse) { return false; }

            if(!vm.lineItems.list.some(function(item) {
                if(isNaN(item.qty_to_receive)) { return false; }
                if(!item.qty_to_receive) { return false; }

                return true;
            })) { return false; }

            if(has_variance() && !vm.receive.variance_ack) { return false; }

            return true;
        }

        function has_variance() {
            if(vm.lineItems.list.some(function(item) {
                if(getVariance(item) > 5) { return true; }
                return false;
            })) { return true; }
            return false;
        }

        function has_unders() {
            if(vm.lineItems.list.some(function(item) {
                if(getVariance(item) < -5) { return true; }
                return false;
            })) { return true; }
            return false;
        }

        function update_var_ack() {
            if(vm.variance_int && vm.variance_accpt) {
                vm.receive.variance_ack = true;
            } else {
                vm.receive.variance_ack = false;
            }
        }
        $scope.$watch('vm.variance_int', function (old_val, new_val) { update_var_ack(); });
        $scope.$watch('vm.variance_accpt', function (old_val, new_val) { update_var_ack(); });


    }

})();
