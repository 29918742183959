(function() {
    "use strict";

    angular
        .module('slate.orderplan')
        .directive('slateOrderplanLotsList', slateListDir);

    function slateListDir() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/orderplan/orderplan.goods.dir.html',
            scope: {
                stepId: '='
            },
            bindToController: true,
            controller: locationEditController,
            controllerAs: 'vm',
        };
    }

    function locationEditController($scope, SlateOrderPlanLots) {
        /* jshint validthis: true */
        var vm = this;
        vm.lots = new SlateOrderPlanLots();
        $scope.$watch('vm.stepId', function (old_val, new_val) {
            if (vm.stepId) {
                vm.lots.getList({step: vm.stepId});
            }
        });

    }

})();
