(function () {
    "use strict";

    angular
        .module('slate.logistics')
        .directive('slateContactEdit', modelList)
        .directive('slateContactEmploymentLine', goodsModelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/contacts/contact.edit.form.dir.html',
            scope: {
                contact: '=',
                company: '=?',
                currentTab: '=?',
                hideControls: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $timeout, $state, $q, SlateAuth, SlateTimeZonesSrv, SlateEmployee, SlateEmployees, $http, $translate ) {
        /* jshint validthis: true */
        var vm = this;

        vm.save = save;
        vm.auth = SlateAuth;

        vm.isComplete = isComplete;
        vm.detailsComplete = detailsComplete;
        vm.methodsComplete = methodsComplete;
        vm.addressesComplete = addressesComplete;

        vm.addLocation = addLocation;
        vm.addContactMethod = addContactMethod;

        vm.timezones = SlateTimeZonesSrv;
        vm.contact.employment = new SlateEmployee();

        vm.employmentList = new SlateEmployees();
        if(vm.contact.id) {
            vm.employmentList.getList({'contact': vm.contact.id}).then(function() {
                if(vm.employmentList.list.length) {
                    vm.contact.employment = vm.employmentList.list[0];
                    vm.contact.employment.companyModel.loadFromServer().then(function() {
                        if(!vm.company) { vm.company = vm.employment.companyModel; }
                    });
                }
            });    
        } else {
            vm.contact.employment.company = vm.company.id;
        }

        function save() {
            vm.saving = true;
            vm.contact.saveModel().then(function () {
                var promises = [];

                angular.forEach(vm.employment.list, function(employment) {
                    if(employment.toDelete && employment.id) {
                        promises.push(employment.deleteModel());
                    }
                    if(!employment.toDelete) {
                        employment.contact = vm.contact.id;
                        promises.push(employment.saveModel());
                    }
                });
                return $q.all(promises).then(function() {
                    $timeout(function () {
                        vm.saving = false;
                        $state.go('contactDetails', {slug: vm.contact.slug});
                    }, 300);    
                });
            }, function (e) {
                vm.saving = false;
                vm.error = true;
            });
        }


        function isComplete() {

            
            return true;
        }

        function detailsComplete() {
            if(!vm.contact.full_name) { return false; }


            return true;
        }

        function methodsComplete() {
            return vm.contact.contactmethods.hasAll(['phone','email']);
        }

        function addressesComplete() {
            
            if(vm.company && vm.company.locations) {
                if(vm.company.locations.list.some(function(location) {
                    if(location.addToUser) { return true; }
                    return false;
                })) { return true; }
            }

            if (vm.contact.locations && vm.contact.locations.list && vm.contact.locations.list.length < 1) {
                return false;
            }

            if(!vm.contact.locations.list.every(function(location, idx, array) {
                if(!location.qualifier || location.qualifier.length < 1) { return false; }
                if(location.is_warehouse && !location.warehouse_name) { return false; }
                return true;
            })) { return false; }

            return true;
        }

        function addLocation() {
            var newLoc = vm.contact.locations.addBlank();
        }

        function addContactMethod() {
            var newMethod = vm.contact.contactmethods.addBlank();
        }

    }

    function goodsModelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/logistics/contact.edit.form.employmentline.html',
            scope: {
                good: '=',
            },
            controller: GoodsListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function GoodsListController($scope, SlateProjects) {
        /* jshint validthis: true */
        var vm = this;

    }

})();
