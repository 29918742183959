(function() {
    "use strict";

    angular
        .module('slate.requests')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('requestsList', {
                url: '/requests/',
                template: "<div class='content-pane list-page'><slate-request-global-list></slate-request-global-list></div>",
                current_app: 'requests',
                slateTitle: 'Requests',
            })



        ;
    }
})();
