(function() {
    "use strict";

    angular
        .module('slate.library.projects')
        .factory('SlateRequests', ModelList)
        .factory('SlateRequest', ModelDetail);

    var apiUrl = API_URL + 'projects/requests/';

    function ModelList($http, $q, SlateRequest) {
        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.saveAll = saveAll;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters, short) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if(self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters,
                timeout: self.canceller.promise
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlateRequest();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveAll(defaults) {
            /* jshint validthis: true */
            var self = this;
            var promises = [];
            var i = 0;
            angular.forEach(self.list, function(model) {
                angular.forEach(defaults, function(value, field) {
                    model[field] = value;
                });
                model.order = i;
                promises.push(model.saveModel());
                i = i + 1;
            });

            return $q.all(promises);
        }

    }

    function ModelDetail($http, $q, SlateRolesSrv) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();
            return self;
        };

        var today = new Date();
        var nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 3, 11, 59);
        if (nextweek === 0) {
            nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 4, 11, 59);
        }
        if (nextweek === 6) {
            nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 5, 11, 59);
        }

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },

            {name: 'project'},
            {name: 'project_slug', readonly:true},
            {name: 'project_num', readonly:true},
            {name: 'project_name', readonly:true},
            {name: 'project_composite_num', readonly:true},
            {name: 'company_info', readonly:true},

            {name: 'order'},
            {name: 'task_count', def: 0, readonly: true},
            {name: 'task_complete', def: 0, readonly: true},

            {name: 'name'},
            {name: 'slug', readonly: true },

            {name: 'low_priority', def: false},
            {name: 'hours_est', def: 0, type: 'float'},
            {name: 'desired_by', def: nextweek, type:'date'},

            {name: 'assigned_to', def:""},
            {name: 'assigned_to_name', def:"", readonly: true},

            {name: 'reviewed', def: 'New'},
            {name: 'reviewed_by', readonly: true},
            {name: 'reviewed_by_info', readonly: true},

            {name: 'notes'},

            {name: 'complete'},
            {name: 'completed_on', readonly:true},
            {name: 'completedby', readonly:true},

            {name: 'updated', readonly: true },
            {name: 'created', readonly: true },
            {name: 'createdby', readonly: true },
            {name: 'createdby_name', readonly:true},
            {name: 'createdby_slug', readonly:true},

        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = _.cloneDeep(field.def);
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object', payload);
                return $q.reject(self);
            }

            angular.forEach(fields, function(field) {
                if(typeof(payload[field.name]) == 'undefined') { return; }

                self[field.name] = angular.copy(payload[field.name]);

                if (field.type == 'float' && self[field.name]) {
                    self[field.name] = parseFloat(self[field.name]);
                }
                if (field.type == 'date' && payload[field.name]) {
                    self[field.name] = new Date(payload[field.name]);
                    self[field.name] = new Date( self[field.name].getTime() + ( self[field.name].getTimezoneOffset() * 60000 ) );
                }

                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {}
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];
                var loadPromise;

                loadPromise = self.loadFromPayload(response.data.payload);

                return loadPromise.then(function() {
                    self.permissions = response.data.permissions;

                    return $q.when(promises).then(function() {

                        deferred.resolve(self);
                    });

                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            angular.forEach(fields, function(field) {
                if (!field.readonly && typeof(self[field.name]) != 'undefined' && self[field.name] !== null) {
                    data[field.name] = self[field.name];
                    if(field.type == "date") {
                        data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth()+1) + '-' + self[field.name].getUTCDate();
                        //data[field.name] = self[field.name].toISOString();
                    }
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
