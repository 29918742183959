(function() {
    "use strict";

    angular
        .module('slate.roles')
        .directive('slateRoleSearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/roles/roles.searchbox.dir.html',
            scope: {
                role: '=slateRole'
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function directiveController($scope, $state, $location, $q, getLinkSrv, SlateRolesSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.roles = SlateRolesSrv.dict;
        
        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.selectRole = selectRole;
        vm.clear = clear;

        if(vm.role) {
            vm.roleObj = vm.roles[vm.role];
        }

        function getSearch(q) {
            var deferred = $q.defer();
            var filteredList = [];
        
            if (q && q.length < 1) { deferred.reject([]); }
            angular.forEach(vm.roles, function(role) {
                if(role.name.search(new RegExp(q, "i")) > -1) {
                    filteredList.push(role);
                }
            });
            deferred.resolve(filteredList);
            return deferred.promise;
        }

        function selectSearch($item, $model, $label, $event) {
            vm.searching = false;
            vm.role = $item.id;
            vm.roleObj = $item;
        }

        function clear() {
            vm.role = null;
            vm.roleObj = null;
        }

        function selectRole(role) {
            vm.searching = false;
            vm.roleObj = role;
            vm.role = role.id;
        }

        $scope.$watch('vm.role', function() {
            
        });

    }

})();
