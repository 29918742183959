(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('purchaseOrderListPage', {
                url: '/purchaseorders/',
                template: "<div class=\"content-pane list-page\"><slate-purchase-order-list show-totals='true'></slate-purchase-order-list></div>",
                current_app: 'purchaseorders',
                slateTitle: 'Purchase Orders',
            })
            .state('purchaseOrderNewPage', {
                url: '/purchaseorders/po/new/',
                templateUrl: '/partials/purchaseorders/purchaseorder.new.page.html',
                controller: 'SlatePurchaseOrderNewPageController',
                controllerAs: 'vm',
                current_app: 'purchaseorders',
                slateTitle: 'Create New Purchase Order - Purchase Orders',
            })
            .state('purchaseOrderEditPage', {
                url: '/purchaseorders/po/:id/edit/',
                templateUrl: '/partials/purchaseorders/purchaseorder.edit.page.html',
                controller: 'SlatePurchaseOrderEditPageController',
                controllerAs: 'vm',
                current_app: 'purchaseorders',
                slateTitle: 'Edit Purchase Order - Purchase Orders',
            })
            .state('purchaseOrderReceivePage', {
                url: '/purchaseorders/po/:id/receive/',
                templateUrl: '/partials/purchaseorders/receive.new.page.html',
                controller: 'SlatePOReceiveNewPageController',
                controllerAs: 'vm',
                current_app: 'purchaseorders',
                slateTitle: 'Receive - Purchase Orders',
            })
            .state('purchaseOrderShipPage', {
                url: '/purchaseorders/po/:id/ship/',
                templateUrl: '/partials/purchaseorders/ship.new.page.html',
                controller: 'SlatePOShipNewPageController',
                controllerAs: 'vm',
                current_app: 'purchaseorders',
                slateTitle: 'Ship - Purchase Orders',
            })
            .state('purchaseOrderForceBuildPage', {
                url: '/purchaseorders/po/:id/forcebuild/',
                templateUrl: '/partials/purchaseorders/forcebuild.page.html',
                controller: 'SlatePOForceBuildPageController',
                controllerAs: 'vm',
                current_app: 'purchaseorders',
                slateTitle: 'Force Build - Purchase Orders',
            })

            ;
    }
})();