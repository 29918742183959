(function () {
    'use strict';

    angular
        .module('slate.customercomplaints')
        .directive('slateCustomerComplaintEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/customercomplaints/customercomplaint.edit.dir.html',
            scope: {
                complaint: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function ListController($scope, $http, getLinkSrv, SlateCustomerComplaint, SlateAuth, SlateContactMethods) {
        /* jshint validthis: true */
        const vm = this;

        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;
        vm.saveObject = saveObject;
        vm.isComplete = isComplete;

        vm.statuses = [
            'New', 'Under Investigation', 'Waiting for Details', 'Investigation Complete'
        ];

        vm.finalDispositions = [
            'Jansy',
            'Vendor',
            'Customer',
            'Filler',
            'Warehouse',
            'Freight Forwarder',
            'No issue found'
        ];

        if (vm.complaint.purchase_order_info.vendor_info.qc_contact) {
            const contactMethods = vm.complaint.purchase_order_info.vendor_info.qc_contact_info.contact_methods;
            const phone = contactMethods.find((item) => item.is_primary && item.method == 'phone');
            if (phone) { vm.phone_number = phone.value; }
            const email = contactMethods.find((item) => item.is_primary && item.method == 'email');
            if (email) { vm.email = email.value; }
        }

        function isComplete() {
            if (vm.complaint.status == 'Investigation Complete' && (!vm.complaint.final_disposition || !vm.complaint.conclusion)) { return false; }
            return true;
        }

        function saveObject() {
            if (vm.saving) { return false; }

            vm.saving = true;
            vm.complaint.saveModel().then(() => {
                vm.fileSrv.uploadFiles('customercomplaint', vm.complaint.id).then(() => {
                    vm.saving = false;
                });
            }, () => {
                vm.saving = false;
            });
        }
    }
})();
