(function() {
    "use strict";

    angular
        .module('slate.app')
        .service('SlateVersionWatch', SlateVersionWatch);

    function SlateVersionWatch($http, $interval, $timeout, ngToast) {
        var srv = {
            current: "0.0",
            new: "0.0"
        };

        getServerVer().then(function(ver) {
            srv.current = ver;
        });

        if(DEBUG != "True") {
            $interval(function() {
                if (srv.current == "0.0") {
                    return false;
                }
                getServerVer().then(function(ver) {
                    if(srv.new == ver) { return false; }
                    srv.new = ver;
                    if(srv.new == srv.current) { return false; }

                    ngToast.create({
                        className: 'warning',
                        content: 'A new version of slate is available. Please reload to use the latest version. A reload will be forced in 1 hour.',
                        timeout: (45 * 60000),
                    });
                    $timeout(function() {
                        ngToast.create({
                            className: 'danger',
                            content: 'A new version of slate is available. Update will be forced in 15 minutes. Please reload to avoid losing work.',
                            timeout: (10 * 60000),
                        });
                    }, (45 * 60000));
                    $timeout(function() {
                        ngToast.create({
                            className: 'danger',
                            content: 'A new version of slate is available. Update will be forced in 5 minutes. Please reload to avoid losing work.',
                            dismissOnTimeout: false,
                        });
                    }, (55 * 60000));

                    $timeout(function() {
                        location.reload();
                    }, (60 * 60000));


                });
            }, 60000);
        }


        return srv;

        function getServerVer() {
            var promise;

            promise = $http.get('/ver.info.json').then(function(results) {
                return results.data.version;
            });

            return promise;
        }

    }

})();
