(function() {
    "use strict";

    angular
        .module('slate.requests')
        .service('SlateRequestEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateRequest) {
        var templateUrl = '/partials/requests/requests.edit.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj: function() {
                        var newObj = new SlateRequest();
                        newObj.loadFromPayload(obj);
                        return newObj;
                    }
                }
            });

            return modalInstance.result.then(function(results) {
                return results;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateAuth, SlateRolesSrv, SlateFileNode, obj) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;

        vm.auth = SlateAuth;

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.review = reviewRequest;

        vm.isComplete = isComplete;

        vm.desiredByIsOpen = false;
        vm.desiredByOpen = desiredByOpen;
        vm.weekendsDisabled = weekendsDisabled;
        vm.now = new Date();

        vm.roles = SlateRolesSrv.requestable;

        vm.files = [];
        vm.removeFile = removeFile;

        vm.dragOptions = {
            handle: ".handle",
            //placeholder: "beingDragged",
        };

        vm.froalaOptions = {
            toolbarButtons: ["bold", "italic", "underline", 'fontSize', "|",
                "align", "formatOL", "formatUL", "|",
                "quote", "insertTable", "|", 'insertLink', 'insertImage', 'insertVideo'
            ],
            imageUploadParams: {
                parent_type: 'contact',
                parent_fk: SlateAuth.user.contact_id,
                path: "uploads/project_briefs/",
                filename: 'unknown'
            },
            imageUploadURL: API_URL + 'files/nodes/',
            imageUploadMethod: "POST",
            imageEditButtons: ['imageDisplay', 'imageAlign'],
            events: {
                'froalaEditor.click': function(e, editor) {
                    $('.fr-popup').css('z-index', 1300);
                }
            }
        };


        function isComplete() {
            if(!vm.obj.name) { return false; }
            if(!vm.obj.assigned_to) { return false; }

            return true;
        }

        function reviewRequest(status) {
            if (!SlateAuth.user.permissions.requests_can_assign) { return false; }
            vm.obj.reviewed = status;
            vm.saveObject();
        }

        function saveObject() {
            if (vm.saving === true) {
                return false;
            }
            vm.saving = true;

            vm.obj.saveModel().then(function() {
                var filepromise = $q.resolve();
                vm.savedFiles = [];
                angular.forEach(vm.files, function(file) {
                    var newFile = new SlateFileNode();


                    newFile.loadFromPayload({
                        parent_type: 'project',
                        parent_fk: vm.obj.project,
                        path: 'requests/'+vm.obj.name,
                        filename: file.name,
                        description: file.description,
                    });

                    filepromise = filepromise.then(function() {
                        return newFile.saveModel(file).then(function() {
                            vm.savedFiles.push(newFile);
                            var linkedNode = new SlateFileNode();
                            linkedNode.loadFromPayload({
                                mimetype: newFile.mimetype,
                                parent_type: 'request',
                                parent_fk: vm.obj.id,
                                path: '',
                                filename: file.name,
                                core_id: newFile.core_id,
                                description: file.description,
                            });
                            linkedNode.saveModel();
                        });
                    });
                });

                filepromise.then(function() {
                    vm.saving = false;
                    $uibModalInstance.close(vm.obj);
                });

            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function desiredByOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.desiredByIsOpen = true;
        }

        function weekendsDisabled(data) {
            var date = data.date,
                mode = data.mode;
            return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
        }


        function removeFile(index) {
            vm.files.splice(index, 1);
        }

        $scope.$watch('vm.filesAdd', function() {

            angular.forEach(vm.filesAdd, function(file) {
                vm.files.push(file);
            });
        });

    }

})();
