(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .directive('slateRfqSearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/rfqs/rfq.searchbox.dir.html',
            scope: {
                rfqId: '=',
                readonly: '<?',
                projectId: "=?",
                showDropdown: "<?"
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function directiveController($scope, $q, SlateRFQ, SlateRFQs) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;

        vm.rfqs = new SlateRFQs();
        vm.dropdownRFQs = new SlateRFQs();
        var selectedRFQ;

        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.clear = clear;

        vm.selectDropdown = selectDropdown;

        function getDropDown() {
            var filters = {};
            var promise;
            
            if (!vm.showDropdown) { return false; }

            if (!vm.projectId) { return false; }
            if (vm.projectId) { filters.project = vm.projectId;}
            promise = vm.dropdownRFQs.getList(filters).then(function () {
                return vm.dropdownRFQs.list;
            });
            return promise;
        }

        function selectDropdown($item) {
            vm.searching = false;
            vm.rfqId = $item.id;
            if(!vm.projectId) { vm.projectId = $item.project; }
            selectedRFQ = $item.id;
            vm.rfqObj = $item;
            vm.rfqName = $item.name;
        }

        if (vm.rfqId) {
            var rfq = new SlateRFQ();
            rfq.loadFromServer(vm.rfqId).then(function () {
                vm.rfqObj = rfq;
                vm.rfqName = rfq.comp_name;
                selectedRFQ = rfq.id;
            });
        }

        function getSearch(q) {
            var promise;
            var filters = { 
                q: q,
                page: 1,
                count: 50
            };
            vm.searching = true;
            if (q && q.length < 3) { return $q.reject([]); }
            if (vm.projectId) { filters.project = vm.projectId;}
            promise = vm.rfqs.getList(filters).then(function () {
                return vm.rfqs.list;
            });
            return promise;
        }

        function selectSearch($item, $model, $label, $event) {
            vm.searching = false;
            vm.rfqId = $item.id;
            if(!vm.projectId) { vm.projectId = $item.project; }
            selectedRFQ = $item.id;
            vm.rfqObj = $item;
            vm.rfqName = $item.name;
        }

        function clear() {
            if (vm.readonly) { return true; }
            vm.rfqId = null;
            selectedRFQ = null;
            vm.rfqObj = null;
            vm.rfqName = null;
        }

        $scope.$watch('vm.rfqId', function () {
            if (selectedRFQ != vm.rfqId && vm.rfqId) {
                var rfq = new SlateRFQ();
                rfq.loadFromServer(vm.rfqId).then(function () {
                    vm.rfqObj = rfq;
                    vm.rfqName = rfq.comp_name;
                    selectedRFQ = rfq.id;
                });
            }
            
        });
        $scope.$watch('vm.projectId', function () { getDropDown(); });

        $scope.$watch('vm.typeaheadOpen', function() {
            if(!vm.typeaheadOpen && !vm.rfqObj && vm.rfqId) {
                var rfq = new SlateRFQ();
                rfq.loadFromServer(vm.rfqId).then(function () {
                    vm.rfqObj = rfq;
                    vm.rfqName = rfq.comp_name;
                    selectedRFQ = rfq.id;
                });
            }
        });
    }

})();
