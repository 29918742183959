(function () {
    "use strict";

    angular
        .module('slate.app')
        .directive('slateWizardStep', slateWizardStep);

    function slateWizardStep() {
        return {
            restrict: 'E',
            templateUrl: '/partials/slate/slate.wizardstep.dir.html',
            transclude: true,
            require: ['^slateWizard'],
            controller: wizardStepController,
            controllerAs: 'wizardStep',
            scope: {
                name: "@stepName",
                complete: "&",
                optional: "=?",
            },
            bindToController: true,
            link: wizardStepLinker
        };

        function wizardStepController() {
            /* jshint validthis: true */
            //var wizardStep = this;

        }

        function wizardStepLinker(scope, elem, attr, controllers) {
            var step = {
                name: attr.stepName
            };
            if (angular.isDefined(attr.complete)) {
                step.complete = scope.wizardStep.complete;
                step.optional = scope.wizardStep.optional;
            }
            scope.wizard = controllers[0];
            controllers[0].addStep(step);

            scope.$on(
                "$destroy",
                function handleDestroyEvent() {
                    controllers[0].removeStep(step);
                }
            );
        }
    }


})();
