(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
            .state('dashboard', {
                url: '/',
                templateUrl: '/partials/dashboard/dashboard.page.html',
                controller: 'SlateDashboardController',
                current_app: 'dashboard',
                slateTitle: 'Dashboard',
                bindToController: true,
                controllerAs: 'vm',
                resolve: {
                    contact: function($stateParams, SlateAuth, SlateContact) {
                        var contact = new SlateContact();
                        var promise = contact.loadFromServer(SlateAuth.user.contact_id);
                        return promise.then(function() {
                            contact.dashboard_config.sort(function compare(a, b) {
                                if (a.row < b.row) {
                                    return -1;
                                }
                                if (a.row > b.row) {
                                    return 1;
                                }
                                // a must be equal to b
                                if (a.col < b.col) {
                                    return -1;
                                }
                                if (a.col > b.col) {
                                    return 1;
                                }
                                return 0;
                            });
                            return contact;
                        });
                    }
                }
            })


        ;
    }
})();
