(function () {
    'use strict';

    angular
        .module('slate.productprofiles')
        .directive('slateProductProfileEmailsList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/productprofiles/emails/productprofiles.email.list.html',
            scope: {
                modelFilters: '=?filters',
                showNew: '=?',
                hideFilters: '=?',
                fields: '=?',
                showTotals: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, getLinkSrv, SlateDeleteObjSrv, SlateProductProfileEmails, SlateProductProfileEmailViewSrv) {
        /* jshint validthis: true */
        const vm = this;

        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;
        vm.viewEmail = viewEmail;

        vm.emails = new SlateProductProfileEmails();

        if (!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'profileitem',
                'to_email',
                'subject',
                'created',
                'created_by'
            ];
            /* beautify preserve:end */
        }

        vm.status_list = [];

        vm.filters = {};
        if (!vm.hideFilters) {
            vm.filters = {
                page: 1,
                count: 25,
                status: null
            };
        }

        if (vm.showNew === undefined) { vm.showNew = true; }

        if (!vm.modelFilters) { vm.modelFilters = {}; }
        angular.extend(vm.filters, {
            q: $location.search().q
        }, vm.modelFilters, $location.search(), vm.filters);
        if (typeof vm.filters.page === 'string') { vm.filters.page = parseInt(vm.filters.page, 10); }
        if (typeof vm.filters.count === 'string') { vm.filters.count = parseInt(vm.filters.count, 10); }


        $scope.$watchCollection(() => vm.filters, () => {
            updateFromFilter();
        });

        function hasFilterData() {
            let hasData = false;
            angular.forEach(vm.filters, (value, key) => {
                if (key === 'page' || key === 'count' || key === 'q') {
                    return false;
                }
                if (typeof vm.filters[key] === 'undefined' || vm.filters[key] === undefined || vm.filters[key] === null) {
                    return false;
                }
                if (vm.filters[key] === vm.modelFilters[key]) {
                    return false;
                }
                if (typeof vm.filters[key] === 'string' && vm.filters[key].length === 0) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;
            });
            return hasData;
        }

        function updateFromFilter() {
            vm.loading = true;
            if (typeof vm.filters.status === 'string') {
                vm.filters.status = [vm.filters.status];
            }

            angular.forEach(vm.filters, (value, item) => {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            const updatePromise = vm.emails.getList(vm.filters);
            updatePromise.then(() => {
                vm.loading = false;
            });
        }

        function viewEmail(email) {
            SlateProductProfileEmailViewSrv.view(email);
        }
    }
})();
