(function () {
    "use strict";

    angular
        .module('slate.navbar')
        .directive('slateNavbar', dirObject);

    function dirObject() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/navbar/navbar.dir.html',
            scope: {

            },
            controller: navbarController,
            controllerAs: 'vm',


        };
    }

    function navbarController($q, $interval, $translate, $location, $http, $state, getLinkSrv, SlateAuth, SlateTitle, SidebarSrv, SlateAddTicket) {
        /* jshint validthis: true */
        var vm = this;
        vm.state = $state;
        vm.auth = SlateAuth;
        vm.title = SlateTitle;
        vm.toggleSearch = toggleSearch;
        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.searching = false;
        vm.translate = changeTranslation;
        vm.TranslateSrv = $translate;
        vm.sidebarsrv = SidebarSrv;
        vm.createTicket = createTicket;

        vm.addSheep = addSheep;
        vm.sheep = [];

        if (DEBUG) {
            vm.watchersTotal = 1;
            vm.watchersTotal = getWatchers().length;
            $interval(function () {
                vm.watchersTotal = getWatchers().length;
            }, 2000);
        }

        function toggleSearch() {
            vm.searching = !vm.searching;
            if (vm.searching) {
                angular.element('#nav-search').focus();
            }
        }

        function getSearch(q) {
            if (q.length < 2) { return $q.reject([]); }
            return $http.get(API_URL + 'dashboard/search/', { params: { q: q } }).then(function (response) {
                return response.data.payload;
            });

        }

        function selectSearch($item, $model, $label, $event) {
            var targetLocation = getLinkSrv.getUrl($item.item_type, $item.item_fk, $item.item_slug);
            var url;
            vm.searchText = "";
            vm.searching = false;
            if(!targetLocation.startsWith('http')) {
                $location.path(targetLocation).search({});
            } else {
                url = new URL(targetLocation);
                $location.path(url.pathname).search({});
            }
        }


        function changeTranslation(lang) {
            $translate.use(lang);
        }

        function addSheep() {
            var sheep = new eSheep({allowPopup:"no"});
            sheep.Start();
            vm.sheep.push(sheep);
        }

        function createTicket() {
            SlateAddTicket.add().then(function() {
    
            });
        }
    }


    function getWatchers(root) {
        root = angular.element(root || document.documentElement);
        var watcherCount = 0;

        function getElemWatchers(element) {
            var isolateWatchers = getWatchersFromScope(element.data().$isolateScope);
            var scopeWatchers = getWatchersFromScope(element.data().$scope);
            var watchers = scopeWatchers.concat(isolateWatchers);
            angular.forEach(element.children(), function (childElement) {
                watchers = watchers.concat(getElemWatchers(angular.element(childElement)));
            });
            return watchers;
        }

        function getWatchersFromScope(scope) {
            if (scope) {
                return scope.$$watchers || [];
            } else {
                return [];
            }
        }

        return getElemWatchers(root);
    }


})();
