(function () {
    'use strict';

    angular
        .module('slate.orders')
        .directive('slateOrderEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/orders/order.edit.dir.html',
            scope: {
                order: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $http,
        $state,
        $location,
        getLinkSrv,
        SlateAuth,
        SlateRolesSrv,
        SlateAssignedRoles,
        SlateProject,
        SlateOrderSpec,
        SlateOrderSpecs,
        SlateOrderSpecEditSrv,
        SlateShipments,
        SlateInvoices,
        SlateInvoice,
        SlateInvoiceLineItems,
        SlateInvoiceLineItem,
        SlateInvoiceEdit,
        SlatePOs,
        SlatePO,
        SlatePOSpecs,
        SlatePOSpec,
        SlatePORequestEdit,
        SlatePurchaseOrder,
        SlatePOLineItem,
        SlatePOLineItems,
        SlateBOMs,
        SlateBOMItems,
        SlateSFLinkage
    ) {
        /* jshint validthis: true */
        const vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;
        vm.sf_linkage = SlateSFLinkage;

        vm.save = save;
        vm.saving = false;

        vm.baseHoldStatus = vm.order.on_hold || vm.order.on_system_hold;
        vm.basePOReceivedDate = vm.order.po_received_date || '';
        vm.isComplete = isComplete;
        vm.checkNotes = checkNotes;
        vm.canFullEdit = canFullEdit;
        vm.SpecsDirty = false;

        vm.get_order_number = get_order_number;

        vm.specs = new SlateOrderSpecs();

        vm.boms = new SlateBOMs();
        vm.bomitems = new SlateBOMItems();

        vm.pos = new SlatePOs();

        vm.invoices = new SlateInvoices();

        vm.shipments = new SlateShipments();

        vm.hasSelectedBomItem = hasSelectedBomItem;
        vm.allItemsSelected = allItemsSelected;
        vm.toggleAllItems = toggleAllItems;
        vm.isNumber = isNumber;

        vm.isDisabled = isDisabled;
        vm.canCreateSO = canCreateSO;

        vm.createPO = createPO;
        vm.editPO = editPO;

        vm.createSpec = createSpec;
        vm.editSpec = editSpec;

        vm.editInvoice = editInvoice;
        vm.createInvoice = createInvoice;

        vm.froalaOptions = {
            toolbarButtons: ['bold',
                'italic',
                'underline',
                '|',
                'align',
                'formatOL',
                'formatUL'],
            zIndex: 10
        };

        const required_fields = [
            'name',
            'project',
            'sales_mgr',
            'proj_mgr',
            'projected_amount',
            'projected_margin',
            'invoice_date',
            'opp_type'
        ];

        vm.statusList = [
            'new',
            'tooling',
            'production',
            'qc',
            'shipping',
            'delivered',
            'cancelled'
        ];

        $scope.$watch('vm.order.id', (old_val, new_val) => {
            if (vm.order.id && !vm.saving) {
                vm.sf_linkage.parent_type = 'order';
                vm.sf_linkage.parent_fk = vm.order.id;

                vm.pos.getList({
                    order: vm.order.id
                }).then(() => {
                    const po_to_open = $location.search().openPO;
                    if (!po_to_open) {
                        return true;
                    }
                    vm.pos.list.forEach((item) => {
                        if (item.id == po_to_open) {
                            vm.editPO(item);
                        }
                    });
                });

                vm.shipments.getList({
                    order: vm.order.id
                });
                vm.invoices.getList({
                    order: vm.order.id
                });
                vm.specs.getList({
                    order: vm.order.id
                }).then(() => {
                    vm.specs.list.forEach((spec) => {
                        spec.bomitems = new SlateBOMItems();
                        spec.bomitems.getList({
                            bom: spec.bom
                        });
                    });
                });
            }
        });

        function get_order_number() {
            if (!vm.order.order_number) {
                return '';
            }
            return `OR${vm.order.order_number}`;
        }

        function save(redirect) {
            const promises = [];
            vm.saving = true;

            if (vm.specs.list.some((spec) => spec.toDelete)) {
                if (!confirm('There are some finished goods marked for deletion. Are you sure you want to delete them?')) {
                    vm.saving = false;
                    return false;
                }
            }

            return vm.order.saveModel().then(() => $timeout(() => {
                const promises = [];

                promises.push(vm.fileSrv.uploadFiles('order', vm.order.id));
                const todelete = [];
                vm.specs.list.forEach((spec, idx) => {
                    if (spec.toDelete) {
                        todelete.unshift(idx);
                        if (spec.id) {
                            promises.push(spec.deleteModel());
                        }
                    } else {
                        spec.order = vm.order.id;
                        promises.push(spec.saveModel());
                    }
                });
                todelete.forEach((idx) => {
                    vm.specs.list.splice(idx, 1);
                });
                return $q.all(promises).then(() => {
                    $timeout(() => {
                        vm.saving = false;
                        vm.OrderEditFrom.$setPristine();
                        vm.fileSrv.files = [];
                        vm.SpecsDirty = false;
                        vm.basePOReceivedDate = vm.order.po_received_date;
                        // $state.go('ordersListPage');
                        if (redirect) {
                            window.history.back();
                        } else if ($state.current.name !== 'orderEditPage') {
                            $state.transitionTo('orderEditPage', {
                                id: vm.order.id
                            }, {
                                location: 'replace'
                            });
                        }
                    }, 300);
                }, () => {
                    vm.saving = false;
                    vm.error = true;
                });
            }, 500), (e) => {
                vm.saving = false;
                vm.error = true;
            });
        }


        function isComplete() {
            if (!required_fields.every((field) => {
                if (typeof vm.order[field] === 'undefined' || vm.order[field] === null) {
                    return false;
                }
                return true;
            })) { return false; }
            if (!vm.order.notes || vm.order.notes.length < 29) { return false; }
            if (!vm.order.opp_types.includes(vm.order.opp_type)) { return false; }
            if (vm.order.client_po && !vm.order.po_received_date && ['Shipped', 'Closed Won', 'Closed Billed', 'Closed Complete'].includes(vm.order.sf_status)) { return false; }
            if (vm.order.is_intercompany) {
                if (!vm.order.intercompany_rep) { return false; }
                if (!vm.order.alt_client) { return false; }
                if (!vm.order.intercompany_value) { return false; }
                if (!vm.order.intercompany_op_num) { return false; }
            }
            if (!['Stock', 'Custom'].includes(vm.order.stock_or_custom)) { return false; }
            return true;
        }

        vm.splitSalesorders = function splitSalesorders() {
            if (!vm.order.id) { return false; }

            vm.saving = true;
            vm.splitError = false;

            vm.order.splitSalesorders().then(() => {
                vm.saving = false;
                $state.go('orderEditPage', { id: vm.order.id }, { location: 'replace', reload: true });
            }, () => {
                vm.saving = false;
                vm.splitError = true;
            });
        };

        vm.cleanupOrderplan = function cleanupOrderplan() {
            if (!vm.order.id) { return false; }

            vm.saving = true;
            vm.splitError = false;

            vm.order.cleanupOrderplan().then(() => {
                vm.saving = false;
                $state.go('orderEditPage', { id: vm.order.id }, { location: 'replace', reload: true });
            }, () => {
                vm.saving = false;
                vm.splitError = true;
            });
        };

        function stripHtml(dirtyString) {
            let doc;
            // eslint-disable-next-line no-undef
            if (!DOMParser) { return dirtyString; }
            // eslint-disable-next-line no-undef
            doc = new DOMParser().parseFromString(dirtyString, 'text/html');
            return doc.body.textContent || '';
        }

        function checkNotes() {
            if (!vm.order.notes) { return false; }
            if (stripHtml(vm.order.notes).length < 30) { return false; }

            return true;
        }

        function canFullEdit() {
            return true;
        }

        function allItemsSelected(spec) {
            return spec.bomitems.list.every((item) => item.selected);
        }

        function toggleAllItems(spec) {
            if (allItemsSelected(spec)) {
                spec.bomitems.list.forEach((item) => {
                    item.selected = false;
                });
            } else {
                spec.bomitems.list.forEach((item) => {
                    item.selected = true;
                });
            }
        }

        function createSpec() {
            const spec = new SlateOrderSpec();
            const promise = SlateOrderSpecEditSrv.edit(spec, {}, vm.order.project);
            promise.then((newspec) => {
                vm.specs.list.push(newspec);
                vm.SpecsDirty = true;
            }, () => {

            });
            return promise;
        }

        function editSpec(spec, idx) {
            SlateOrderSpecEditSrv.edit(spec).then((newspec) => {
                vm.specs.list[idx] = newspec;
                vm.SpecsDirty = true;
            });
        }

        function isDisabled(e) {
            if (!vm.canCreateSO()) {
                e.preventDefault();
            }
        }

        function canCreateSO() {
            if (!vm.order.client_po) { return false; }
            if (!vm.soList || (vm.soList.list && vm.soList.list.length)) { return false; }
            if ((vm.order.on_hold || vm.order.client_info.on_hold || vm.order.on_system_hold) && !vm.auth.user.permissions.companies_can_override_holds) { return false; }

            return true;
        }

        function projectSync() {
            const assroles = new SlateAssignedRoles();
            const project = new SlateProject();
            const role_dict = SlateRolesSrv.dict;
            assroles.getList({
                parent_type: 'project',
                parent_fk: vm.order.project
            }).then(() => {
                angular.forEach(assroles.list, (item) => {
                    if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Project Manager' && !vm.order.proj_mgr) {
                        vm.order.proj_mgr = item.member_fk;
                    }
                    if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Sales Manager' && !vm.order.sales_mgr) {
                        vm.order.sales_mgr = item.member_fk;
                    }
                });
            });
            if (!vm.order.client) {
                project.loadFromServer(vm.order.project).then(() => {
                    if (project.parent_type == 'company') {
                        vm.order.client = project.parent_fk;
                    }
                });
            }
        }
        $scope.$watch('vm.order.project', () => {
            if (vm.order.project) {
                projectSync();
                if (!vm.order.projects.includes(vm.order.project)) {
                    vm.order.projects.push(vm.order.project);
                }
            }
        });
        $scope.$watch('vm.order.finished_good', () => {
            if (!vm.order.finished_good) {
                vm.boms.list = [];
                return vm.boms;
            }
            vm.boms.getList({
                finished_good: vm.order.finished_good
            }).then(() => {
                if (!vm.order.bom && vm.boms.list.length > 0) {
                    vm.order.bom = vm.boms.list[0].id;
                }
            });
        });

        $scope.$watch('vm.order.bom', () => {
            if (!vm.order.bom) {
                vm.bomitems.list = [];
                return vm.bomitems;
            }
            vm.bomitems.getList({
                bom: vm.order.bom
            }).then(() => {

            });
        });

        function hasSelectedBomItem() {
            return vm.specs.list.some((spec) => spec.bomitems.list.some((item) => item.selected));
        }

        function editPO(po) {
            const promise = SlatePORequestEdit.edit(po);
            promise.then(() => {
                vm.pos.getList({
                    order: vm.order.id
                });
                $scope.$broadcast('updateFiles', 'order', vm.order.id);
            }, () => {

            });
            return promise;
        }

        function deletePO(po) {
            const promise = po.deleteModel();
            promise.then(() => {
                vm.pos.getList({
                    order: vm.order.id
                });
            }, () => {

            });
            return promise;
        }

        function createPO() {
            const newPO = new SlatePurchaseOrder();
            let promise;
            const inital_data = {
                name: vm.order.name,
                company: vm.order.client,
                project: vm.order.project,
                order: vm.order.id,
                notes: vm.order.notes,
                reorder: vm.order.reorder,
                client_po: vm.order.client_po,
                proj_mgr: vm.order.proj_mgr,
                sales_mgr: vm.order.sales_mgr
            };

            if (!vm.isComplete() || vm.OrderEditFrom.$dirty || vm.fileSrv.files.length) {
                return false;
            }

            if (!vm.hasSelectedBomItem() && !confirm('You have not selected any bom items. Are you sure you want to continue?')) {
                return false;
            }

            vm.savingPO = true;

            newPO.loadFromPayload(inital_data);
            const cachedSpecs = {};
            const specs = new SlatePOLineItems();

            vm.specs.list.forEach((finished_good) => {
                finished_good.bomitems.list.forEach((item) => {
                    let spec;
                    let scrap = 0;
                    let order_qty = finished_good.qty;
                    if (isNaN(order_qty)) {
                        order_qty = 0;
                    }
                    order_qty = new Big(order_qty);

                    if (item.selected) {
                        spec = new SlatePOLineItem();
                        spec.qty = new Big(item.qty_required).times(order_qty);
                        if (item.scrap_factor) {
                            scrap = new Big(item.scrap_factor);
                            scrap = scrap.div(100).times(spec.qty);
                            spec.qty = spec.qty.plus(scrap);
                        }
                        spec.qty = spec.qty.toFixed(5);
                        spec.jansy_pn = item.internal_item_number;
                        spec.client_pn = item.client_item_number;
                        spec.category = item.category;
                        spec.material = item.material;
                        spec.name = item.item_description.substring(0, 74);
                        spec.component = item.component;
                        spec.component_info = item.component_info;
                        spec.finished_good = finished_good.id;
                        spec.unitprice = item.component_info.buy_price;
                        spec.sellprice = item.component_info.sell_price;

                        if (!cachedSpecs[item.component]) {
                            cachedSpecs[item.component] = spec;
                        } else {
                            cachedSpecs[item.component].qty = cachedSpecs[item.component].qty.plus(spec.qty).toFixed(5);
                        }
                    }
                });
            });
            Object.keys(cachedSpecs).forEach((key) => {
                cachedSpecs[key].qty = cachedSpecs[key].qty.toString();
                specs.list.push(cachedSpecs[key]);
            });


            promise = newPO.saveModel().then(() => {
                const promises = [];

                specs.list.forEach((lineItem) => {
                    let promise;
                    lineItem.purchase_order = newPO.id;
                    promise = lineItem.saveModel();
                    promises.push(promise);
                    return promise;
                });

                return $q.all(promises).then(() => {
                    if ($state.current.name != 'purchaseOrderEditPage') {
                        $state.transitionTo(
                            'purchaseOrderEditPage',
                            { id: newPO.id }
                        );
                    }
                });
            });
            return promise;
        }


        function editInvoice(invoice) {
            const promise = SlateInvoiceEdit.edit(invoice);
            promise.then(() => {
                vm.invoices.getList({
                    order: vm.order.id
                });
                $scope.$broadcast('updateFiles', 'order', vm.order.id);
            }, () => {

            });
            return promise;
        }

        function createInvoice() {
            const newInv = new SlateInvoice();
            let promise;
            const inital_data = {
                description: vm.order.name,
                company: vm.order.client,
                project: vm.order.project,
                order: vm.order.id,
                client_po: vm.order.client_po,
                proj_mgr: vm.order.proj_mgr,
                salesperson: vm.order.sales_mgr,
                notes: vm.order.notes
            };

            if (!vm.isComplete() || vm.OrderEditFrom.$dirty || vm.fileSrv.files.length) {
                return false;
            }

            vm.creatingLegacyInvoice = true;

            newInv.loadFromPayload(inital_data);
            const specs = new SlateInvoiceLineItems();
            const cachedSpecs = {};

            promise = SlateInvoiceEdit.edit(newInv, specs);
            promise.then((inv) => {
                vm.invoices.getList({
                    order: vm.order.id
                });
                $scope.$broadcast('updateFiles', 'order', vm.order.id);
                vm.creatingLegacyInvoice = false;
            }, () => {
                vm.creatingLegacyInvoice = false;
            });
            return promise;
        }

        function isNumber(num, isIntiger) {
            if (Number.isNaN(num)) { return false; }
            if (isIntiger && !Number.isInteger(parseFloat(num))) { return false; }

            return true;
        }

        vm.putOnHold = function putOnHold() {
            const apiUrl = `${API_URL}orders/orders/`;
            const modelUrl = `${apiUrl + vm.order.id}/saleshold/`;

            vm.saving = true;

            $http.post(modelUrl).then(() => {
                $state.go('orderEditPage', { id: vm.order.id }, { location: 'replace', reload: true });
            }, () => {
                vm.saving = false;
                vm.errorSaving = true;
            });
        };
    }
})();
