(function () {
    "use strict";

    angular
        .module('slate.qc')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('qclogListPage', {
                url: '/qclogs/',
                template: "<div class='content-pane list-page'><slate-qclog-list></slate-qclog-list></div>",
                current_app: 'qclogs',
                slateTitle: 'QC Logs',
            })
            .state('qclogNewPage', {
                url: '/qclogs/new/',
                templateUrl: '/partials/qc/qclog.new.page.html',
                controller: 'SlateQCLogNewPageController',
                controllerAs: 'vm',
                current_app: 'qclogs',
                slateTitle: 'Create New QC Log - QC Logs',
            })
            .state('qclogViewPage', {
                url: '/qclogs/:id/',
                templateUrl: '/partials/qc/qclog.view.html',
                controller: 'SlateQCLogViewPageController',
                controllerAs: 'vm',
                current_app: 'qclogs',
                slateTitle: 'View QC Log - QC Logs',
                
            })
            .state('qclogEditPage', {
                url: '/qclogs/:id/edit/',
                templateUrl: '/partials/qc/qclog.edit.page.html',
                controller: 'SlateQCLogEditPageController',
                controllerAs: 'vm',
                current_app: 'qclogs',
                slateTitle: 'Edit QC Log - QC Logs',
                
            })

            .state('qcrequestListPage', {
                url: '/qcrequests/',
                template: "<div class='content-pane list-page'><slate-qcrequest-list></slate-qcrequest-list></div>",
                current_app: 'qclogs',
                slateTitle: 'QC Requests',
            })
            .state('qcrequestNewPage', {
                url: '/qcrequests/new/',
                templateUrl: '/partials/qc/qcrequest.new.page.html',
                controller: 'SlateQCRequestNewPageController',
                controllerAs: 'vm',
                current_app: 'qclogs',
                slateTitle: 'Create New QC Request - QC Requests',
            })
            .state('qcrequestViewPage', {
                url: '/qcrequests/:id/',
                templateUrl: '/partials/qc/qclog.view.html',
                controller: 'SlateQCLogViewPageController',
                controllerAs: 'vm',
                current_app: 'qclogs',
                slateTitle: 'View QC Log - QC Logs',
                
            })
            .state('qcrequestEditPage', {
                url: '/qcrequests/:id/edit/',
                templateUrl: '/partials/qc/qcrequest.edit.page.html',
                controller: 'SlateQCRequestEditPageController',
                controllerAs: 'vm',
                current_app: 'qclogs',
                slateTitle: 'Edit QC Request - QC Requests',
                
            })


            ;
    }
})();