(function () {
    'use strict';

    angular
        .module('slate.productprofiles')
        .service('SlateProductProfileLineItemContactsSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateProductProfileItemContacts) {
        const templateUrl = '/partials/productprofiles/productprofile.item.contact.sidebar.html';

        const srv = {
            edit: editObj
        };


        return srv;


        function editObj(productprofile_item_id) {
            const modalInstance = $uibModal.open({
                templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    contacts() {
                        const newitem = new SlateProductProfileItemContacts();
                        return newitem.getList({ profileitem: productprofile_item_id });
                    }
                }
            });

            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateAuth, contacts) {
        /* jshint validthis: true */
        const vm = this;

        vm.contacts = contacts;
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveList = saveList;
        vm.cancel = cancel;
        vm.auth = SlateAuth;

        function saveList() {
            vm.saving = true;
            vm.error = false;

            const promises = [];

            vm.contacts.list.forEach((contact) => {
                promises.push(contact.saveModel());
            });

            return $q.all(promises).then(() => {
                $uibModalInstance.close(vm.item);
            }, () => {
                vm.saving = false;
                vm.error = true;
            });
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
