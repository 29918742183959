(function() {
    "use strict";

    angular
        .module('slate.rfqs')
        .directive('slateRfqEditResponsesRecommendationsList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/rfqs/rfq.edit.response.list.recommendations.html',
            scope: {
                response: '=',
                rfq: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, SlateCompanies) {
        /* jshint validthis: true */
        var vm = this;

        vm.loading = false;

        vm.companies = new SlateCompanies();

        function getMats() {
            var mats = [];
            vm.rfq.line_items.forEach(function(item) {
                if(typeof item.category == 'string' && item.category.length) { mats.push(item.category); }
                if(typeof item.material == 'string' && item.material.length) { mats.push(item.material); }
            });
            return mats;
        }

        vm.addVendor = function addVendor(vendor) {
            vm.response.vendor_info = vendor;
            vm.response.vendor = vendor;
        };

        vm.getRecommendations = function getRecommendations() {
            vm.companies.getList({
                vendor: true,
                preferred: true,
                mats: getMats()
            });
        };

    }


})();
