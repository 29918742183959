(function () {
    "use strict";

    angular
        .module('slate.rates')
        .service('SlateFreightLocationsSrv', LocationsService)
        .directive('slateFreightLocationSearchbox', slateDirectiveList);


    function LocationsService($http, $timeout) {

        var srv = {
            getFreightLocations: getFreightLocations
        };

        var promise;
        var ttl;

        return srv;

        function getFreightLocations() {
            var apiUrl = API_URL + 'rates/ports/';
            if(promise) { return promise; }

            promise = $http.get(apiUrl).then(function(response) {
                return response.data.payload;
            });

            ttl = $timeout( function(){
                ttl = null;
                promise = null;
            }, 300000 ); // 5 minute ttl on cache

            return promise;
        }
    }


    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/rates/freight.location.searchbox.dir.html',
            scope: {
                location: '=',
                locationType: '=', // origin_port, destination_port, final_destination
                originPort: '=?',
                destinationPort: '=?',
                useSonic: '=?',
                showDropdown: "<?"
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function directiveController($scope, $q, $http) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'rates/ports/';

        vm.loading = false;

        vm.id = 'locations-search-box-' +  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        vm.ports = [location];

        $scope.$watch('vm.locationType', function (old_val, new_val) { getPorts(); });
        $scope.$watch('vm.originPort', function (old_val, new_val) { getPorts(); });
        $scope.$watch('vm.destinationPort', function (old_val, new_val) { getPorts(); });
        $scope.$watch('vm.useSonic', function (old_val, new_val) { getPorts(); });

        function getPorts() {
            if(!vm.locationType) { return false; }

            var params = {};

            if(vm.canceller) { vm.canceller.resolve(); }
            vm.canceller = $q.defer();

            if(vm.originPort) { params.origin_port = vm.originPort; }
            if(vm.destinationPort) { params.destination_port = vm.destinationPort; }
            if(vm.useSonic) { params.use_sonic = vm.useSonic; }

            return $http.get(apiUrl, {
                params: params,
                timeout: vm.canceller.promise
            }).then(function(response) {
                vm.canceller = null;
                vm.freightLocations = response.data.payload;
                vm.ports = vm.freightLocations[vm.locationType + 's'].filter(function(item) { return item && item.length > 0; });
            });
        }
    }

})();
