(function() {
    "use strict";

    angular
        .module('slate.orders')
        .controller('SlateOrderNewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $location, SlateTitle, SlateOrder) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = false;
        vm.order = new SlateOrder();
        if($location.search().client) { vm.order.client = $location.search().client; }
        if($location.search().company) { vm.order.client = $location.search().ccompany; }
        if($location.search().project) { vm.order.project = $location.search().project; }
        if($location.search().reorder) { vm.order.reorder = true; }
        

        $scope.$watch('vm.order.name', function () {
            if (vm.order.name) {
                SlateTitle.set('Edit ' + vm.order.name + ' - Orders');
            } else {
                SlateTitle.set('Edit Untitled order - Orders');
            }
        });

    }

})();


