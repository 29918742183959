(function () {
    "use strict";

    angular
        .module('slate.orders')
        .directive('slateOrdersSearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/orders/orders.searchbox.dir.html',
            scope: {
                orderId: '=',
                orderName: '=?',
                client: '<?',
                readonly: '<?',
                project: "=?",
                showDropdown: "<?",
                orderObj: "=?",
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function directiveController($scope, $state, $location, $q, getLinkSrv, SlateOrder, SlateOrders) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;

        vm.orders = new SlateOrders();
        vm.dropdownOrders = new SlateOrders();
        var selectedOrder;

        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.getWidth = getWidth;
        vm.clear = clear;
        if(typeof vm.orderName != "string") { vm.orderName = ""; }

        vm.orderObj = {
            name: vm.orderName,
            composite_name: vm.orderName,
            id: vm.orderId
        };

        vm.id = 'orders-search-box-' +  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        vm.selectDropdown = selectDropdown;

        function getWidth() {
            var elem = document.getElementById(vm.id);
            return elem.offsetWidth + 'px';
        }

        function getDropDown() {
            var filters = {};
            var promise;
            if(!vm.showDropdown) { return false; }
            
            if(!vm.project && !vm.client) { return false; }
            if (vm.client) { filters.client = vm.client; }
            if (vm.project) { filters.project = vm.project; }
            
            promise = vm.dropdownOrders.getList(filters).then(function () {
                return vm.dropdownOrders.list;
            });
            return promise;
        }

        function selectDropdown($item) {
            vm.searching = false;
            vm.orderId = $item.id;
            selectedOrder = $item.id;
            vm.orderObj = $item;
            vm.orderName = $item.name;
        }

        getDropDown();

        if (vm.orderId) {
            var order = new SlateOrder();
            order.loadFromServer(vm.orderId).then(function () {
                vm.orderObj = order;
                vm.orderName = order.name;
                selectedOrder = order.id;
            });
        }

        function getSearch(q) {
            var promise;
            var filters = { q: q };
            if (q && q.length < 1) { return $q.reject([]); }
            if (vm.client) { filters.client = vm.client; }
            
            if (vm.project) { filters.project = vm.project; }
            promise = vm.orders.getList(filters).then(function () {
                return vm.orders.list;
            });
            return promise;

        }

        function selectSearch($item, $model, $label, $event) {
            vm.searching = false;
            vm.orderId = $item.id;
            selectedOrder = $item.id;
            vm.orderObj = $item;
            vm.orderName = $item.name;
        }

        function clear() {
            if (vm.readonly) { return true; }
            vm.orderId = null;
            vm.orderObj = null;
            vm.orderName = null;
        }

        $scope.$watch('vm.orderId', function () {
            
            if (selectedOrder != vm.orderId && vm.orderId) {
                
                var order = new SlateOrder();
                order.loadFromServer(vm.orderId).then(function () {
                    vm.orderObj = order;
                    vm.orderName = order.name;
                    selectedOrder = order.id;
                });
            }
            
        });
        $scope.$watch('vm.client', function () { getDropDown(); });
        $scope.$watch('vm.project', function () { getDropDown(); });

        $scope.$watch('vm.typeaheadOpen', function() {
            if(!vm.typeaheadOpen && !vm.orderObj && vm.orderId) {
                var order = new SlateOrder();
                order.loadFromServer(vm.orderId).then(function () {
                    vm.orderObj = order;
                    vm.orderName = order.name;
                    selectedOrder = order.id;
                });
            }
        });
    }

})();
