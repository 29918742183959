(function() {
    "use strict";

    angular
        .module('slate.filebrowser')
        .service('SlateFileNodeClientProps', editDialog);


    function editDialog($rootScope, $q, $uibModal) {
        var templateUrl = '/partials/filebrowser/filebrowser.editclientprops.dialog.html';

        var srv = {
            edit: editObj
        };
        return srv;


        function editObj(obj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                //size: 'lg',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',

                resolve: {
                    obj: function() {
                            return obj;
                    },
                }
            });

            return modalInstance.result.then(function(results) {
                return results;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateFileNode, obj) {
        /* jshint validthis: true */
        var vm = this;

        if(!obj) {
            vm.obj= {};
        } else {
            vm.obj = obj;
        }

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.saveAndApplyObject = saveAndApplyObject;

        function saveAndApplyObject() {
            vm.obj.apply_to_children = true;
            return saveObject();
        }

        function saveObject() {
            var promises = [];
            var filepromise = $q.resolve();
            vm.saving = true;

            vm.obj.saveModel().then(function() {
                vm.saving = false;
                $uibModalInstance.close(vm.obj);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function removeFile(index) {
            vm.files.splice(index, 1);
        }

        $scope.$watch('vm.filesAdd', function() {
            angular.forEach(vm.filesAdd, function(file) {
                vm.files.push(file);
            });
        });


    }



})();
