(function() {
    "use strict";

    angular
        .module('slate.library.approvals')
        .factory('SlateApproval', ModelDetail);

    var apiUrl = API_URL + 'approvals/';

    function ModelDetail ($http, $location, $q, SlateApprovalSteps) {

        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },

            {name: 'created', def: null, readonly: true },
            {name: 'updated', def: null, readonly: true },

            {name: 'created_by', def: null, readonly: true },
            {name: 'created_by_name', def: null, readonly: true },
            {name: 'created_by_slug', def: null, readonly: true },

            {name: 'url', def: null },

            {name: 'parent', def: null, readonly: true },
            {name: 'parent_type', def: null, readonly: true },
            {name: 'parent_fk', def: null, readonly: true },
            {name: 'parent_slug', def: null, readonly: true },

        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;


        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if(typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                self[field.name] = payload[field.name];
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(parent_type, parent_fk, root_type, root_fk) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl + parent_type + '/' + parent_fk + '/';

            var promise;

            if(!parent_type || ! parent_fk) {
                return $q.reject();
            }

            promise = $http.get(modelUrl);

            promise.then(function (response) {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;
                self.steps = new SlateApprovalSteps();

                self.steps.getList(parent_type, parent_fk).then(function() {
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function saveModel(root_type, root_fk) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl + self.parent_type + '/' + self.parent_fk + '/';

            var promise;
            var data = {};

            angular.forEach(fields, function(field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                }
            });
            data.root_fk = root_fk;
            data.root_type = root_type;

            data.url = $location.absUrl();

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];

                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return self.steps.saveAll({parent_fk: self.parent_fk, parent_type: self.parent_type, root_fk: root_fk, root_type: root_type});
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if(self.id) { modelUrl = apiUrl + self.id + '/'; }

            angular.forEach(fields, function(field) {
                if(!field.readonly) {
                    data[field.name] = self[field.name];
                }
            });

            promise = $http.delete(modelUrl);
            promise.then(function (response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
