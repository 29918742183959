(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetShipments', modelList)
        .directive('slateWidgetShipmentsLine', modelLine);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.shipment.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $interval, SlateAuth, SlateRolesSrv, SlateShipments, SlateShipment, SlateInvoiceEdit, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var interval = null;
        var speed = vm.settings.speed || 60000;
        vm.getLink = getLinkSrv;
        vm.shipments = new SlateShipments();
        vm.loading = true;
        
        
        vm.filters = {
            page: 1,
            count: 10,
        };

        function getData() {
            vm.loading = true;
            var filters = { thisWeek: vm.settings.thisWeek };
            angular.extend(filters, vm.filters, vm.settings);
            if (vm.settings.mine) { filters.contact = SlateAuth.user.id; }
            if (vm.settings.status) { filters.status = vm.settings.status; }

            vm.shipments.getList(filters).then(function() {
                vm.loading = false;
            });
        }

        //getData();

        $scope.$watch('vm.settings', function(items) {
            getData();
        }, true);
        $scope.$watch('vm.filters', function(items) {
            getData();
        }, true);

        interval = $interval(function() {
            getData();
        }, speed);

        $scope.$on('$destroy', function() {
            if (angular.isDefined(interval)) {
                $interval.cancel(interval);
                interval = undefined;
            }
        });

    }


    function modelLine() {
        return {
            restrict: 'E',
            
            templateUrl: '/partials/dashboard/dashboard.widget.shipmentline.html',
            scope: {
                entry: "="
            },
            controller: LineController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function LineController($scope, SlateAuth, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var unwatch;

        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;
    
    }


})();
