(function () {
    "use strict";

    angular
        .module('slate.library.timetracking')
        .factory('SlateTimeTracks', ModelList)
        .factory('SlateTimeTrack', ModelDetail);

    var apiUrl = API_URL + 'timetracking/time/';

    function ModelList($http, $q, SlateTimeTrack) {
        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters, short) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, { params: self.filters });

            promise.then(function (response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function (item) {
                    var itemModel = new SlateTimeTrack();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveAll(defaults) {
            /* jshint validthis: true */
            var self = this;
            var promises = [];
            angular.forEach(self.list, function (model) {
                angular.forEach(defaults, function (value, field) {
                    model[field] = value;
                });
                promises.push(model.saveModel());
            });

            return $q.all(promises);
        }

    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();
            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            { name: 'id', def: null, readonly: true },

            { name: 'parent', readonly: true },
            { name: 'parent_fk' },
            { name: 'parent_slug', readonly: true },
            { name: 'parent_type' },

            { name: 'start', def: new Date(Date.now()), type: 'date' },
            { name: 'hours_spent', def: 0, type: 'float' },
            { name: 'user' },
            { name: 'user_name', readonly: true },
            { name: 'user_slug', readonly: true },

            { name: 'company', readonly: true },
            { name: 'project', readonly: true },
            { name: 'project_info', readonly: true },

        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function (field) {
                self[field.name] = field.def;
            });

            self.start = new Date(Date.now());

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object', payload);
                return $q.reject(self);
            }

            angular.forEach(fields, function (field) {
                self[field.name] = payload[field.name];
                if (field.type == 'float' && self[field.name]) {
                    self[field.name] = parseFloat(self[field.name]);
                }
                if (field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                        console.log('invalid json: ', payload[field.name]);
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl;

            var promise;


            promise = $http.get(modelUrl);

            promise.then(function (response) {
                var promises = [];
                var loadPromise;

                loadPromise = self.loadFromPayload(response.data.payload);

                return loadPromise.then(function () {
                    self.permissions = response.data.permissions;

                    return $q.when(promises).then(function () {

                        deferred.resolve(self);
                    });

                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) { modelUrl = apiUrl + self.id + '/'; }

            angular.forEach(fields, function (field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function (response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function (response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
