(function() {
    "use strict";

    angular
        .module('slate.approvals')
        .directive('slateApprovalStepsList', ApprovalStatusDir);

    function ApprovalStatusDir() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/approvals/approvals.steps.list.dir.html',
            scope: {
                parentType: '=',
                parentFk: '=',
                rootType: '=?',
                rootFk: '=?',
                companyFk: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ListController($scope, SlateAuth, SlateApproval, SlateApprovalEdit, $timeout, $interval) {
        /* jshint validthis: true */
        var vm = this;
        vm.getDateString = getDateString;
        vm.getSystemNotes = getSystemNotes;
        var timer;
        vm.approval = new SlateApproval();
        $timeout(function() {
            vm.approval.loadFromServer(vm.parentType, vm.parentFk, vm.rootType, vm.rootFk);
            timer = $interval(function() {
                vm.approval.loadFromServer(vm.parentType, vm.parentFk, vm.rootType, vm.rootFk);
            }, 30000);
        }, 300);
        $scope.$on('approvalUpdated', function(e, opt) {
            console.log('got approval update message', e, opt);
            var parentType = opt.parentType;
            var parentFk = opt.parentFk;
            if(vm.parentType == parentType && vm.parentFk == parentFk) {
                vm.approval.loadFromServer(vm.parentType, vm.parentFk, vm.rootType, vm.rootFk);
            }
        });
        $scope.$on("$destroy", function() {
            if (timer) {
                $interval.cancel(timer);
            }
        });

        function getDateString(date){
            date = new Date(date);
            return date.toLocaleString();
        }

        function getSystemNotes(notes){
            return notes.split('_');
        }


    }


})();
