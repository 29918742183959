(function () {
    'use strict';

    angular
        .module('slate.salesorders')
        .service('SlateSOLineItemEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateSOLineItem) {
        const templateUrl = '/partials/salesorders/so.lineitem.edit.sidebar.html';

        const srv = {
            edit: editObj
        };

        return srv;

        function editObj(item, options) {
            const modalInstance = $uibModal.open({
                templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj() {
                        const newitem = new SlateSOLineItem();
                        if (!item) {
                            return newitem;
                        }
                        if (typeof item === 'string') {
                            return newitem.loadFromServer(item);
                        }
                        return newitem.loadFromPayload(item);
                    },
                    salesOrder() {
                        if (typeof options === 'object' && options.salesOrder) {
                            return options.salesOrder;
                        }
                        return null;
                    }
                }
            });

            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, obj, salesOrder, SlateSettings, SlateCompany, SlateProject, SlateLocations, SlateBOMs, SlateBOM, SlateBOMItems, SlatePriceTiers, SlatePriceTier, SlateAuth) {
        /* jshint validthis: true */
        const vm = this;

        vm.item = obj;
        vm.salesOrder = salesOrder;
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.auth = SlateAuth;

        vm.isComplete = isComplete;

        vm.setShipto = setShipto;
        vm.locations = new SlateLocations();

        vm.boms = new SlateBOMs();


        vm.froalaOptions = {
            toolbarButtons: ['bold',
                'italic',
                'underline',
                '|',
                'align',
                'formatOL',
                'formatUL']
            // zIndex: 2501,
        };

        vm.units = [
            'ea',
            'box',
            'bottle',
            'g',
            'kg',
            'oz',
            'liter'
        ];

        function saveObject(skip) {
            vm.saving = true;

            const bomItems = new SlateBOMItems();

            bomItems.getList({ bom: vm.item.bom }).then(() => {
                const promises = [];
                bomItems.list.forEach((bomItem) => {
                    if (!vm.salesOrder.bom_item_price_tiers[bomItem.id]) {
                        const priceTiers = new SlatePriceTiers();
                        const promise = priceTiers.getList({ component: bomItem.component }).then(() => {
                            if (priceTiers.list.length) {
                                vm.salesOrder.bom_item_price_tiers[bomItem.id] = priceTiers.list[0].id;
                            }
                        });
                        promises.push(promise);
                    }
                });
                $q.all(promises).then(() => {
                    vm.saving = false;
                    $uibModalInstance.close(vm.item);
                });
            });
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }


        function isComplete() {
            if (!vm.item.client_pn) { return false; }
            if (!vm.item.finished_good) { return false; }
            if (!vm.item.bom) { return false; }
            if (!vm.item.qty) { return false; }

            return true;
        }

        function projectSync() {
            const project = new SlateProject();
            project.loadFromServer(vm.item.finished_good).then(() => {
                if (!vm.item.jansy_pn) {
                    if (project.internal_item_number) {
                        vm.item.jansy_pn = project.internal_item_number;
                    } else {
                        // vm.item.jansy_pn = project.composite_num;
                    }
                }

                vm.priceTier = null;
                if (!vm.item.id && project.price_tiers.length) {
                    vm.priceTier = project.price_tiers[0];
                    vm.item.price_tier = vm.priceTier.id;
                } else if (vm.item.price_tier) {
                    vm.priceTier = new SlatePriceTier();
                    vm.priceTier.loadFromPayload(vm.item.price_tier_info);
                }

                if (!vm.item.name) {
                    if (project.variation_name) {
                        vm.item.name = project.variation_name.substring(0, 74);
                    } else {
                        vm.item.name = project.name.substring(0, 74);
                    }
                }
                if (!vm.item.client_pn) { vm.item.client_pn = project.client_item_number; }
                if (!vm.item.specs) { vm.item.specs = project.brief; }
                if (!vm.item.category) { vm.item.category = project.category; }
                if (!vm.item.material) { vm.item.material = project.material; }
                if (!vm.item.unit_name) { vm.item.unit_name = project.unit_name; }
                if (!vm.item.unitprice) { vm.item.unitprice = project.buy_price; }
                if (!vm.item.sellprice) { vm.item.sellprice = project.sell_price; }
            });
        }
        $scope.$watch('vm.item.finished_good', () => {
            if (vm.item.finished_good) {
                projectSync();
            }

            if (!vm.item.finished_good) {
                vm.boms.list = [];
            } else {
                vm.boms.getList({ finished_good: vm.item.finished_good }).then(() => {
                    if (vm.boms.list.length > 0 && !vm.boms.list.some((bom) => bom.id === vm.item.bom)) {
                        vm.item.bom = vm.boms.list[0].id;
                    }

                    if (!vm.item.bom && vm.boms.list.length > 0) {
                        vm.item.bom = vm.boms.list[0].id;
                    }
                });
            }
        });

        SlateSettings.getConfig('material_categories').then((config) => {
            vm.categories = config;
        });
        SlateSettings.getConfig('materials').then((config) => {
            vm.material_list = config;
            getMatList();
        });
        vm.materials = [];

        $scope.$watch('vm.item.category', () => {
            getMatList();
        });

        $scope.$watch('vm.item.qty', () => {
            if (vm.priceTier) {
                vm.item.sellprice = vm.priceTier.getPrice(vm.item.qty);
            }
        });

        function getMatList() {
            const newlist = [];
            if (!vm.material_list || !vm.material_list.value) { return []; }
            angular.forEach(vm.material_list.value, (item) => {
                if (item.category === vm.item.category) {
                    newlist.push(item);
                }
            });
            vm.materials = newlist;
            return newlist;
        }

        $scope.$watch('vm.shipto', () => {
            if (vm.shipto) {
                setShipto(vm.shipto);
            }
        });

        function setShipto(address) {
            let data = '';
            if (vm.company.name) { data += `${vm.company.name}\n`; }
            if (address.street1) { data += `${address.street1}\n`; }
            if (address.street2) { data += `${address.street2}\n`; }
            if (address.street3) { data += `${address.street3}\n`; }
            if (address.city) { data += `${address.city},`; }
            data += `${address.state} ${address.postal}`;
            if (address.country) { data += `\n${address.country}`; }

            vm.item.shipto = data;
        }

        vm.canEditBOM = () => {
            // if item has not been saved yet item remains editable.
            if (!vm.item.id) { return true; }

            // If SO is in draft, it's ok
            if (['Draft', 'draft', 'New', 'new'].includes(vm.salesOrder.status)) { return true; }

            // if item has shipped, NO ONE can edit.
            if (vm.item.items_shipped > 0) { return false; }

            // if user has perm, they can edit, if passed above checks.
            if (vm.auth.perms.salesorders_can_change_li_bom) { return true; }

            // default to false;
            return false;
        };
    }
})();
