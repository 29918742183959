(function () {
    'use strict';

    angular
        .module('slate.contacts')
        .service('SlateContactEditDialog', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateContact, SlateCompany) {
        const templateUrl = '/partials/contacts/contact.edit.dialog.html';

        const srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj, company_id) {
            const modalInstance = $uibModal.open({
                templateUrl,
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj() {
                        if (!obj.id) {
                            return obj;
                        }
                        const co = new SlateContact();
                        return co.loadFromServer(obj.id);
                    },
                    co() {
                        const co = new SlateCompany();
                        if (!company_id) { return { id: null }; }

                        return co.loadFromServer(company_id);
                    }
                }
            });

            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($q, $uibModalInstance, SlateAuth, SlateTimeZonesSrv, SlateEmployee, SlateEmployees, obj, co) {
        /* jshint validthis: true */
        const vm = this;

        vm.obj = obj;
        vm.company = co;
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.isComplete = isComplete;

        vm.addLocation = addLocation;
        vm.addContactMethod = addContactMethod;
        vm.addEmployment = addEmployment;

        vm.detailsIsComplete = detailsIsComplete;
        vm.methodsComplete = methodsComplete;
        vm.hasAddresses = hasAddresses;
        vm.timezones = SlateTimeZonesSrv;
        vm.auth = SlateAuth;

        vm.employmentList = new SlateEmployees();
        // Load employment list if editing an existing employee. Otherwise, add new employment info.
        if (vm.obj.id) {
            vm.employmentList.getList({ contact: vm.obj.id });
        } else {
            const employment = new SlateEmployee();
            employment.company = vm.company.id;
            vm.employmentList.list.push(employment);
        }

        function addEmployment() {
            const employment = new SlateEmployee();
            employment.title = 'Employee';
            vm.employmentList.list.push(employment);
        }

        function saveObject() {
            if (vm.saving) { return false; }
            vm.saving = true;
            vm.obj.email = '';
            if (!vm.obj.email) {
                angular.forEach(vm.obj.contactmethods.list, (cm) => {
                    if (!vm.obj.email && cm.method === 'email') {
                        vm.obj.email = cm.value;
                    }
                });
            }

            if (vm.company && vm.company.locations) {
                angular.forEach(vm.company.locations.list, (location) => {
                    let newLoc;
                    if (location.addToUser) {
                        newLoc = vm.obj.locations.addBlank();
                        newLoc.loadFromPayload(location);
                        newLoc.is_warehouse = false;
                        newLoc.warehouse_name = undefined;
                        newLoc.warehouse_type = [];
                        newLoc.id = null;
                    }
                });
            }

            obj.saveModel().then(() => {
                const promises = [];

                vm.employmentList.list.forEach((employment) => {
                    employment.contact = vm.obj.id;
                    if (employment.company) {
                        if (!employment.title) { employment.title = 'Employee'; }
                        promises.push(employment.saveModel());
                    }
                });

                $q.all(promises).then(() => {
                    vm.saving = false;
                    $uibModalInstance.close(vm.obj);
                }, () => {
                    vm.saving = false;
                    vm.errorSaving = true;
                });
            }, () => {
                vm.saving = false;
                vm.errorSaving = true;
            });
            return obj;
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function addLocation() {
            return vm.obj.locations.addBlank();
        }

        function addContactMethod() {
            return vm.obj.contactmethods.addBlank();
        }

        function detailsIsComplete() {
            if (!vm.obj.full_name || !vm.obj.timezone) {
                return false;
            }
            return true;
        }

        function methodsComplete() {
            return vm.obj.contactmethods.hasAll(['phone', 'email']);
        }

        function isComplete() {
            return detailsIsComplete() && hasAddresses() && methodsComplete();
        }

        function hasAddresses() {
            let addrComplete = true;
            let willCopy = false;
            if (vm.company && vm.company.locations) {
                angular.forEach(vm.company.locations.list, (location) => {
                    if (location.addToUser) { willCopy = true; }
                });
                if (willCopy) { return true; }
            }

            if (vm.obj.locations && vm.obj.locations.list && vm.obj.locations.list.length < 1) {
                return false;
            }

            angular.forEach(vm.obj.locations.list, (location) => {
                if (!location.qualifier || location.qualifier.length < 1) {
                    addrComplete = false;
                }
            });

            return addrComplete;
        }
    }
})();
