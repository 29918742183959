(function () {
    "use strict";

    angular
        .module('slate.orders')
        .service('SlateOrderSpecEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateOrderSpec) {
        var templateUrl = '/partials/orders/orderspec.sidebar.edit.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(item, init_data, parent_proj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj: function () {
                        var newitem = new SlateOrderSpec(init_data);
                        if(!item) {
                            return newitem;
                        }
                        if(typeof item == 'string') {
                            return newitem.loadFromServer(item);
                        }
                        return newitem.loadFromPayload(item);
                    },
                    parentProj: function() { return parent_proj; }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance,  obj, parentProj, SlateSettings, SlateProject, SlateBOMItems, SlateBOMs, SlateBOM) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;
        vm.parentProj = parentProj;
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.detailsIsComplete = detailsIsComplete;

        vm.boms = new SlateBOMs();
        vm.obj.bomitems = new SlateBOMItems();

        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            //zIndex: 2501,
        };


        function saveObject(skip) {
            if(!vm.obj.bom || !vm.obj.finished_good) { return false; }
            if (isNaN(vm.obj.qty)) { vm.obj.qty = 0; }

            $uibModalInstance.close(vm.obj);
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }


        function detailsIsComplete() {
            return true;
        }


        $scope.$watch('vm.obj.finished_good', function (newGood, oldGood) {
            if (!vm.obj.finished_good) {
                vm.boms.list = [];
                return vm.boms;
            }

            vm.boms.getList({ finished_good: vm.obj.finished_good }).then(function () {
                if(vm.boms.list.length > 0 && !vm.boms.list.some(function(bom) { return bom.id == vm.obj.bom; })) {
                    vm.obj.bom = vm.boms.list[0].id;
                }

                if(!vm.obj.bom && vm.boms.list.length > 0) {
                    vm.obj.bom = vm.boms.list[0].id;
                }
            });
        });

        $scope.$watch('vm.obj.bom', function () {
            vm.obj.bom_info = new SlateBOM();
            if (!vm.obj.bom) {
                vm.obj.bomitems.list = [];
                return vm.obj.bomitems;
            }
            vm.obj.bom_info.loadFromServer(vm.obj.bom);
            vm.obj.bomitems.getList({ bom: vm.obj.bom }).then(function () {

            });
        });


    }



})();
