(function () {
    'use strict';

    angular
        .module('slate.app')
        .directive('slateDatepicker', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/slate/slate.datepicker.dir.html',
            scope: {
                date: '=',
                allowweekends: '=?',
                placeholder: '=?',
                required: '=?',
                disabled: '=?',
                readonly: '=?',
                minDate: '=?',
                maxDate: '=?',
                openaccounting: '=?',
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function directiveController(SlateAccountPrdSrv) {
        /* jshint validthis: true */
        const vm = this;
        vm.baseDate = vm.date;
        vm.dateIsOpen = false;
        vm.dateOpen = dateOpen;
        vm.disabledDates = disabledDates;
        vm.getOptions = getOptions;

        vm.accountingPeriods = SlateAccountPrdSrv;

        vm.datepickerOptions = {
            showWeeks: false, dateDisabled: vm.disabledDates, minDate: vm.minDate, maxDate: vm.maxDate,
        };

        function dateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();
            if (vm.disabled || vm.readonly) {
                vm.dateIsOpen = false;
                return false;
            }
            vm.dateIsOpen = !vm.dateIsOpen;
            return true;
        }

        function disabledDates(data) {
            const { date } = data;
            const { mode } = data;

            if (date && vm.baseDate) {
                if (date.getUTCFullYear() === vm.baseDate.getUTCFullYear()) {
                    if (date.getUTCMonth() === vm.baseDate.getUTCMonth()) {
                        if (date.getUTCDate() === vm.baseDate.getUTCDate()) {
                            return false;
                        }
                    }
                }
            }

            if (!vm.allowweekends) {
                if (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6)) {
                    return true;
                }
            }

            if (vm.openaccounting && vm.accountingPeriods.earliest) {
                if (date < vm.accountingPeriods.earliest) {
                    return true;
                }
            }

            return false;
        }

        function getOptions() {
            const options = {
                showWeeks: false,
            };
            if (!vm.allowweekends) { options.dateDisabled = vm.weekendsDisabled; }
            if (vm.minDate && !vm.baseDate) { options.minDate = vm.minDate; }
            if (vm.maxDate && !vm.baseDate) { options.maxDate = vm.maxDate; }
            return options;
        }
    }
})();
