(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .directive('slatePoLineItemList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/purchaseorders/po.lineitem.list.dir.html',
            scope: {
                purchaseOrder: '=',
                hidenew: '=?',
                showTotals: '=?',
                fields: '=?',
                projectId: '=?',
                dirty: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlatePOLineItems, SlatePOLineItem, SlatePOLineItemEditSrv, SlatePOLineItemPriceTierEditSrv, SlateInspection, SlateInspectionEditSrv, SlateCustomerComplaintEditSrv, SlateCustomerComplaint, getLinkSrv, SlateDeleteObjSrv) {
        /* jshint validthis: true */
        var vm = this;

        if (!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'photo',
                'jansy_pn',
                'finished_good',
                'name',
                'category',
                'material',
                'required_date',
                'ready_date',
                'qty',
                'qty_received',
                'qty_shipped',
                // 'qty_inspected',
                'unitprice',
                'unitprice_total'
            ];
            /* beautify preserve:end */
        }

        vm.getLink = getLinkSrv;
        vm.loading = false;

        vm.auth = SlateAuth;

        vm.getStyle = getStyle;

        vm.editLineItem = editLineItem;
        vm.selectPriceTier = selectPriceTier;
        vm.addNewLineItem = addNewLineItem;
        vm.deleteLineItem = deleteLineItem;
        vm.displayErrorEdit = displayErrorEdit;
        vm.displayErrorTrash = displayErrorTrash;
        vm.confirmReceive = confirmReceive;
        vm.newInspection = newInspection;
        vm.newCustomerComplaint = newCustomerComplaint;
        vm.isInspected = isInspected;

        vm.isDisabled = isDisabled;
        vm.canShipItems = canShipItems;
        vm.hasNonInventory = hasNonInventory;

        vm.getPriceTotal = getPriceTotal;
        vm.getQtyTotal = getQtyTotal;
        vm.getQtyRecTotal = getQtyRecTotal;

        function getPriceTotal() {
            var total = Big(0.0);
            vm.purchaseOrder.line_items.forEach(function (item) {
                total = total.add(item.getCostTotal());
            });
            return total.toFixed(5);
        }

        function getSellTotal() {
            var total = Big(0.0);
            vm.purchaseOrder.line_items.forEach(function (item) {
                total = total.add(item.selltotal);
            });
            return total.toFixed(5);
        }

        var qty_total;
        var qty_rec_total;

        function getQtyTotal() {
            var total = Big(0.0);
            vm.purchaseOrder.line_items.forEach(function (item) {
                total = total.add(item.qty);
            });
            qty_total = total;
            return total.toFixed();
        }
        function getQtyRecTotal() {
            var total = Big(0.0);
            vm.purchaseOrder.line_items.forEach(function (item) {
                total = total.add(item.qty_received);
            });
            qty_rec_total = total;
            return total.toFixed();
        }


        function getMarginTotal() {
            var sell = getSellTotal();
            var cost = getPriceTotal();
            var total = Big(0.0);
            if (sell == 0.00000) { return 0.0; }
            total = total.add(sell).minus(cost);
            total = total.div(sell);
            total = total.times(100.0);
            return total.toFixed(2);
        }

        function addNewLineItem() {
            var new_lineitem = new SlatePOLineItem();
            console.log(vm.purchaseOrder);
            SlatePOLineItemEditSrv.edit(new_lineitem, { purchaseOrder: vm.purchaseOrder }).then(function (lineitem) {
                if (vm.purchaseOrder.id) { lineitem.was_revised = true; }
                vm.dirty = true;
                vm.purchaseOrder.line_items.push(lineitem);
            }, function () {
                //do nothing if canceled?
            });
        }

        function editLineItem(lineitem, $idx) {
            SlatePOLineItemEditSrv.edit(lineitem, { purchaseOrder: vm.purchaseOrder }).then(function (new_lineitem) {
                if (lineitem.id || vm.purchaseOrder) { new_lineitem.was_revised = true; }
                vm.dirty = true;
                vm.purchaseOrder.line_items[$idx] = new_lineitem;
            }, function () { });
        }

        function selectPriceTier(lineItem, $idx) {
            SlatePOLineItemPriceTierEditSrv.edit(lineItem).then(function (new_lineitem) {
                if (vm.purchaseOrder.id) { lineItem.was_revised = true; }
                vm.dirty = true;
                vm.purchaseOrder.line_items[$idx] = new_lineitem;
            }, function () {

            });
        }

        function deleteLineItem(lineitem, $idx) {
            lineitem.toDelete = !lineitem.toDelete;

        }

        function hasNonInventory() {
            return vm.purchaseOrder.line_items.some(function (item) { return item.component_info.non_inventory; });
        }


        function displayErrorEdit(lineitem, $idx) {
            alert("This item has already been received. The PO line item can no longer be edited.");
        }

        function displayErrorTrash(lineitem, $idx) {
            alert("This item has already been received. The PO line item can no longer be deleted.");
        }

        function getStyle(field) {
            if (field == 'name') {
                return {
                    'width': '100%',
                    'overflow': 'hidden',
                    'white-space': 'nowrap',
                    'text-overflow': 'ellipsis'
                };
            }

            return {
                'overflow': 'hidden',
                'white-space': 'nowrap',
                'text-overflow': 'ellipsis',
            };
        }

        function confirmReceive(e) {
            if (!vm.purchaseOrder.vendor || vm.purchaseOrder.status == 'Draft') {
                e.preventDefault();
                return false;
            }

            if (!window.confirm('Receiving items directly is to a workaround for creating shipments. Are you sure you want to receive items directly?')) {
                e.preventDefault();
            }
        }

        function isDisabled(e) {
            if (!vm.canShipItems()) {
                e.preventDefault();
            }
        }

        function canShipItems() {
            if (!vm.purchaseOrder.vendor || vm.purchaseOrder.status == 'Draft') { return false; }

            if ((vm.purchaseOrder.order_info.on_hold ||
                vm.purchaseOrder.company_info.on_hold ||
                vm.purchaseOrder.order_info.on_system_hold) &&
                !vm.auth.user.permissions.companies_can_override_holds) { return false; }

            return true;
        }

        vm.get_vendor_split = function get_vendor_split(option, qty) {
            var price = 0;
            var split = 0;

            if (option.final_split) { return option.final_split; }

            if (typeof option.prices == 'string') {
                option.prices = JSON.parse(option.prices);
            }
            if (!option.prices) { return 'n/a'; }

            //default to the smallest split
            Object.keys(option.prices).forEach(function (key) {
                var new_split = parseFloat(key);
                if ((split == 0 || new_split <= split) && option.prices[key]) {
                    split = new_split;
                    option.final_split = key;
                }
            });

            // find the largest split that fits
            Object.keys(option.prices).forEach(function (key) {
                var new_split = parseFloat(key);
                if (new_split <= qty && new_split > split && option.prices[key]) {
                    split = new_split;
                    option.final_split = key;
                }
            });
            if (Number.isNaN(option.final_split)) { return 'n/a'; }
            option.final_price = parseFloat(option.prices[option.final_split]);
            return option.final_split;
        };

        vm.get_vendor_price = function get_vendor_price(option, qty) {
            var price = 0;
            var split = 0;

            if (option.final_price) { return option.final_price; }
            if (!option.final_split) { return 'n/a'; }

            if (typeof option.prices == 'string') {
                option.prices = JSON.parse(option.prices);
            }
            if (!option.prices) { return 'n/a'; }

            option.final_price = parseFloat(option.prices[option.final_split]);
            if (Number.isNaN(option.final_price)) { return 'n/a'; }
            return option.final_price;
        };

        vm.get_vendor_total = function get_vendor_total(option, qty) {
            if (!option || !option.final_price || !qty) { return 'n/a'; }
            return Big(option.final_price).times(Big(qty)).toFixed(5);
        };

        vm.get_fixed_price = function get_fixed_price(option) {
            return Big(option.price).toFixed(5);
        };

        function newInspection() {
            var inspection = new SlateInspection();
            SlateInspectionEditSrv.edit(inspection, vm.purchaseOrder.id).then(function (obj) {

            }, function () {

            });
        }

        function isInspected() {
            return vm.purchaseOrder.line_items.every(function (item) {
                return item.qty_inspected >= item.qty;
            });
        }

        function newCustomerComplaint() {
            var complaint = new SlateCustomerComplaint();
            SlateCustomerComplaintEditSrv.edit(complaint, vm.purchaseOrder.id).then(function () {

            }, function () {

            });
        }

    }


})();
