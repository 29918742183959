(function () {
    "use strict";

    angular
        .module('slate.presentations')
        .service('SlatePresPhotoEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal) {
        var templateUrl = '/partials/presentations/presphotos.edit.srv.html';

        var srv = {
            edit: editObj
        };
        return srv;


        function editObj(objs) {
            var modalInstance;
            if (objs.constructor != Array) {
                objs = [objs];
            }

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                size: 'lg',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',

                resolve: {
                    objs: function () {
                        return objs;
                    },
                }
            });

            return modalInstance.result.then(function (results) {
                return results;
            }, function () {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlatePresPhoto, SlateTags, SlatePresCategories, objs) {
        /* jshint validthis: true */
        var vm = this;

        if (!objs) {
            vm.objs = [];
        } else {
            vm.objs = objs;
        }
        vm.obj = new SlatePresPhoto();
        if (vm.objs.length === 1) {
            vm.obj.loadFromPayload(vm.objs[0]);
        }
        vm.activeSlide = 0;

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.wizardControl = {};

        vm.tags = SlateTags;
        vm.cats = SlatePresCategories;

        vm.image_types = ["3D Rendering", "Illustration", "Photograph", 'Sketch'];
        vm.files = [];

        vm.downloadAll = downloadAll;
        vm.deleteAll = deleteAll;

        function saveObject() {
            var promises = [];
            var fieldsToCopy = ['company', 'project', 'confidential', 'tags', 'cats'];

            vm.saving = true;

            angular.forEach(vm.objs, function (photo) {
                angular.forEach(fieldsToCopy, function (field) {
                    photo[field] = vm.obj[field];
                });
                promises.push(photo.saveModel());
            });

            $q.all(promises).then(function () {
                vm.saving = false;
                $uibModalInstance.close(vm.objs);
            });

        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.$watch('vm.filesAdd', function () {
            angular.forEach(vm.filesAdd, function (file) {
                vm.files.push(file);
            });
        });

        function downloadAll() {

            var link = document.createElement('a');
            var urls = [];

            angular.forEach(vm.objs, function (obj) {
                urls.push(obj.full);
            });

            link.setAttribute('download', null);
            link.style.display = 'none';

            document.body.appendChild(link);

            for (var i = 0; i < urls.length; i++) {
                link.setAttribute('href', urls[i]);
                link.click();
            }

            document.body.removeChild(link);
        }

        function deleteAll() {
            var promises = [];

            var message = "Are you sure you want to delete these files?";
            var confmessage = "Are you REALLY sure? you can't ge them back.";
            if (window.confirm(message) && window.confirm(confmessage)) {
                vm.saving = true;
                angular.forEach(vm.objs, function (obj) {
                    promises.push(obj.deleteModel());
                });
                $q.all(promises).then(function () {
                    vm.saving = false;
                    $uibModalInstance.dismiss('cancel');
                });

            }

        }


    }




})();
