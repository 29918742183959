(function () {
    "use strict";

    angular
        .module('slate.locations')
        .directive('slateLocationEditBox', slateLocationEditBox);

    function slateLocationEditBox() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/locations/locations.edit.dir.html',
            scope: {
                location: '=',
                company: '<?',
            },
            bindToController: true,
            controller: locationEditController,
            controllerAs: 'vm',
            link: locationEditLinker,
        };
    }

    function locationEditController($scope, SlateZipFinder, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;
        vm.fillFromPostal = fillFromPostal;
        vm.deleteLocation = deleteLocation;
        vm.zipfinder = SlateZipFinder;
        vm.toggleWarehouse = toggleWarehouse;
        vm.auth = SlateAuth;

        $scope.$watch('vm.location.is_warehouse', function (old_val, new_val) {
            if (vm.location.is_warehouse && !vm.location.warehouse_name) {
                vm.location.warehouse_name = vm.location.qualifier + ' warehouse';
            }
        });

        function deleteLocation() {
            /* jshint validthis: true */
            var vm = this;

            vm.location.toDelete = !vm.location.toDelete;
        }

        function fillFromPostal() {
            /* jshint validthis: true */
            var vm = this;
            var countrycode;


            if (vm.location.city || vm.location.county) {
                return false;
            }

            if (vm.location.country == "United States" && vm.location.postal.length < 5) {
                return false;
            }

            if (vm.location.country == "China" && vm.location.postal.length < 6) {
                return false;
            }

            if (vm.location.postal.length < 3) {
                return false;
            }
            angular.forEach(Countries, function (country) {
                if (country.name === vm.location.country) {
                    countrycode = country.code;
                }
            });

            if (!countrycode) {
                return false;
            }

            SlateZipFinder.findzip(vm.location.postal, countrycode).then(function (data) {
                vm.location.city = data.places[0]["place name"];
                vm.location.state = data.places[0]["state abbreviation"];
            });

        }

        function toggleWarehouse() {
            if (vm.auth.user.permissions.contacts_can_edit_warehouses) {
                vm.location.is_warehouse = !vm.location.is_warehouse;
            }
        }
    }


    function locationEditLinker(scope) {
        scope.countries = Countries;
    }

})();
