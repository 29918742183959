(function() {
    "use strict";

    angular
        .module('slate.app')
        .directive('slateStateTabsNested', slateStateTabs);

    function slateStateTabs() {
        return {
            restrict: 'E',
            templateUrl: '/partials/slate/slate.statetabs.nested.dir.html',
            transclude: true,
            controller: slateTabController,
            controllerAs: 'vm',
            scope: {
                title: "@",
                baseState: "@",
                conditional: "@"
            },
            bindToController: true
        };

        function slateTabController($scope, $attrs, $state, $stateParams,
            SlateTitle, SlateAuth) {
            /* jshint validthis: true */
            var vm = this;
            var allstates = $state.get();
            vm.state = $state;
            vm.states = [];
            vm.goto = goToState;
            vm.addedstates = {};
            vm.currentNestedTab = "";


            angular.forEach(allstates, function(item) {

                if (!item.name.startsWith(vm.baseState + '.')) {
                    return false;
                }
                if (item.tabConditional && vm.conditional && vm.conditional != item.tabConditional) {
                    return false;
                }

                if(!Array.isArray(item.tabExclude)) {
                    if (item.tabExclude && vm.conditional && vm.conditional == item.tabExclude) {
                        return false;
                    }    
                }
                if(Array.isArray(item.tabExclude) && vm.conditional) {
                    if(item.tabExclude.includes(vm.conditional)) {
                        return false;
                    }
                }

                if (item.tabPerms && !SlateAuth.user.permissions[item.tabPerms]) {
                    return false;
                }

                if(vm.addedstates[item.tabName]) { return false; }

                if (item.needs_permission && !SlateAuth.user.permissions[
                        item.needs_permission]) {
                    return false;
                }
                
                vm.states.push(item);
                vm.addedstates[item.tabName] = true;
            });

            checkstate();
            vm.tabs = {};
            vm.states.forEach(function(item){
                // check if a nested tab
                if(!item.nested){
                    // not a nested tab so treat as normal
                    vm.tabs[item.tabName] = item;
                } else {
                    if(!vm.tabs[item.nested]){
                        // a nested tab so check if nest alraedy exists
                        vm.tabs[item.nested] = [];
                    }
                    // nest now or already did exist so add to it
                    vm.tabs[item.nested].push(item);
                }
            });

            function checkstate() {
                var can_load = false;
                if ($state.is(vm.baseState) && vm.states.length > 0) {
                    //$state.transitionTo(vm.states[0], $stateParams, {location:false} );
                    var results;
                    results = $state.go(vm.states[0], $stateParams, {
                        location: "replace"
                    });
                    
                    return results;
                }
                angular.forEach(vm.states, function(state) {
                    if(state.name == $state.current.name) {
                        can_load = true;
                        if (state.tabPerms && !SlateAuth.user.permissions[state.tabPerms]) {
                            can_load = false;
                        }
                        if (state.tabConditional && vm.conditional && vm.conditional != state.tabConditional) {
                            can_load = false;
                        }

                        if (state.tabExclude && vm.conditional && vm.conditional == state.tabExclude) {
                            can_load = false;
                        }

                    }
                });
                if(!can_load) {
                    return $state.go(vm.states[0], $stateParams, {
                        location: "replace"
                    });
                }

            }

            function goToState(newState, extParams) {
                var params = $stateParams;
                var can_see = false;
                params = angular.extend(params, newState.defaults, extParams);
                angular.forEach(vm.states, function(state) {
                    if(state.name == newState) {

                        params = angular.extend({}, state.defaults, params);
                        if(state.tabName) {
                            SlateTitle.subset(state.tabName);
                        }
                    }
                });

                $state.transitionTo(newState, params);

            }


        }
    }
})();
