(function() {
    "use strict";

    angular
        .module('slate.presentations')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
            .state('presList', {
                url: '/presentations/',
                templateUrl: "/partials/presentations/presentations.page.html",
                current_app: 'presentations',
                slateTitle: 'Presentations',
            })

        ;
    }
})();
