(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportClientPos', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.clientpos.dir.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $q, $http, $location, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'reports/client_po/';
        vm.getLink = getLinkSrv;

        var modelPromise;

        vm.updateFromFilter = updateFromFilter;

        vm.get_so_total_qty = get_so_total_qty;
        vm.get_so_total_sell = get_so_total_sell;
        vm.get_so_total_cost = get_so_total_cost;
        vm.get_so_total_paid = get_so_total_paid;
        vm.get_so_total_billed = get_so_total_billed;
        vm.get_so_release_dates = get_so_release_dates;
        vm.get_total_sell = get_total_sell;

        vm.get_po_total_cost = get_po_total_cost;
        vm.get_po_total_qty = get_po_total_qty;
        vm.get_po_total_qty_shipped = get_po_total_qty_shipped;
        vm.get_po_total_qty_missing = get_po_total_qty_missing;
        vm.get_po_total_qty_unordered = get_po_total_qty_unordered;
        vm.get_po_qty_required = get_po_qty_required;
        vm.get_po_total_qty_received = get_po_total_qty_received;

        vm.count = get_key_count;

        vm.data = [];
        vm.filters = {};
        vm.loading = true;
        vm.canceller = null;

        vm.filters = angular.extend(vm.filters, {
            q: $location.search().q,
            company: $location.search().company
        });

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function() {
            updateFromFilter();
        });

        function updateFromFilter() {
            vm.loading = true;

            if(vm.canceller) { vm.canceller.resolve(); }
            vm.canceller = $q.defer();

            if(!vm.filters.q) {
                vm.data = [];
                vm.loading = false;
                return false;
            }

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
                timeout: vm.canceller.promise
            });

            modelPromise.then(function(resp) {
                vm.data = resp.data.payload;
                vm.loading = false;
                vm.canceller = null;
            });
        }

        function get_key_count(item) {
            if(!item) { return 0; }
            return Object.keys(item).length;
        }

        function get_so_release_dates(lineitem) {
            return lineitem.releases.join(' ,  ');
        }

        function get_so_total_qty(lineitem) {
            var total_qty = 0.0;
            lineitem.prices.forEach(function(price) {
                total_qty += parseFloat(price.qty);
            });
            return total_qty;
        }

        function get_so_total_billed(lineitem) {
            var total_qty = 0.0;
            lineitem.prices.forEach(function(price) {
                total_qty += parseFloat(price.qty_billed);
            });
            return total_qty;
        }

        function get_so_total_paid(lineitem) {
            var total_qty = 0.0;
            lineitem.prices.forEach(function(price) {
                total_qty += parseFloat(price.qty_paid);
            });
            return total_qty;
        }

        function get_so_total_sell(lineitem) {
            var total_sell = Big(0.0);
            lineitem.prices.forEach(function(price) {
                total_sell = total_sell.add(Big(price.qty).times(price.sellprice));
            });
            return total_sell.toFixed(2);
        }

        function get_po_total_qty(lineitem) {
            var total_qty = 0.0;
            lineitem.details.forEach(function(price) {
                total_qty += parseFloat(price.qty);
            });
            return total_qty;
        }

        function get_po_total_qty_missing(lineitem) {
            var total_qty = 0.0;
            if(lineitem.supplier == 'client')  { return 0.0; }
            lineitem.details.forEach(function(price) {
                total_qty += parseFloat(price.qty);
            });
            return parseFloat(lineitem.qty_needed) - total_qty;
        }

        function get_po_total_qty_received(lineitem) {
            var total_qty = 0.0;
            lineitem.details.forEach(function(price) {
                total_qty += parseFloat(price.qty_received);
            });
            return total_qty;
        }

        function get_po_total_qty_shipped(lineitem) {
            var total_qty = 0.0;
            lineitem.details.forEach(function(price) {
                total_qty += parseFloat(price.qty_shipped);
            });
            return total_qty;
        }

        function get_po_total_qty_unordered(lineitem) {
            var qty_ordered = get_po_total_qty(lineitem);
            var qty_received = get_po_total_qty_received(lineitem);
            return qty_ordered - qty_received;
        }


        function get_po_qty_required(lineitem) {
            var total_qty = Big(0.0);
            lineitem.details.forEach(function(price) {
                total_qty = total_qty.add(line.qty_needed);
            });
            return total_qty.toFixed(2);
        }

        function get_po_total_cost(lineitem) {
            var total_sell = Big(0.0);
            lineitem.details.forEach(function(price) {
                total_sell = total_sell.add(Big(price.qty).times(price.unitprice));
            });
            return total_sell.toFixed(2);
        }

        function get_so_total_cost(lineitem) {
            var total_sell = Big(0.0);
            Object.keys(lineitem.components).forEach(function(key) {
                total_sell = total_sell.add(get_po_total_cost(lineitem.components[key]));
            });
            return total_sell.toFixed(2);
        }

        function get_total_sell() {
            var total_sell = Big(0.0);
            Object.keys(vm.data).forEach(function(key) {
                total_sell = total_sell.add(get_so_total_sell(vm.data[key]));
            });
            return total_sell.toFixed(2);
        }
    }
})();