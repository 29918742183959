(function () {
    'use strict';

    angular
        .module('slate.productprofiles')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('productProfilesListPage', {
                url: '/productprofiles/',
                template: '<div class="content-pane list-page"><slate-product-profiles-list></slate-product-profiles-list></div>',
                current_app: 'productprofiles',
                slateTitle: 'Product Profiles'
            })
            .state('productProfileDetails', {
                url: '/productprofiles/profile/:id/',
                templateUrl: '/partials/productprofiles/productprofile.details.page.html',
                controller: 'SlateProductProfilesDetailsPageController',
                controllerAs: 'vm',
                current_app: 'productprofiles',

                resolve: {
                    productprofile($stateParams, SlateProductProfile) {
                        const profile = new SlateProductProfile();
                        return profile.loadFromServer($stateParams.id);
                    }
                }
            })
            .state('productProfileDetails.items', {
                url: 'items/',
                template: "<slate-productprofile-item-list filters='{profile: vm.productprofile.id}' hide-filters='true'></slate-productprofile-item-list>",
                current_app: 'productprofiles',
                tabName: 'Products'
            })
            .state('productProfileDetails.emails', {
                url: 'emails/',
                template: "<slate-product-profile-emails-list filters='{profile: vm.productprofile.id}' hide-filters='true'></slate-product-profile-emails-list>",
                current_app: 'productprofiles',
                tabName: 'Email Log'
            });
    }
})();
