(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportSalesYear', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.sales_year.dir.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ReportController($scope, $q, $http, $location, $httpParamSerializer, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'reports/sales_report_year/';
        
        var modelPromise;
        var canceller = null;

        vm.auth = SlateAuth;

        vm.getDownloadUrl = getDownloadUrl;
        vm.updateFromFilter = updateFromFilter;
        vm.sortBy = sortBy;
        
        vm.data = [];
        vm.filters = {'sales_mgrs_only': true};
        vm.loading = false;
        vm.sortOrder = 'date_of_movement';
        vm.reverse = true;

        vm.start_date = new Date();
        vm.start_date = new Date(vm.start_date.getFullYear(), 0, 1);
        vm.end_date = new Date(vm.start_date.getFullYear() + 1, 0, 1);
        
        vm.date_fields = ['start_date', 'end_date'];
        
        vm.date_fields.forEach(function(dateField) {
            if(vm.filters[dateField]) {
                parts = vm.filters[dateField].split('-');
                vm[dateField] = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
            }
            $scope.$watch('vm.'+ dateField, function () {
                if (vm[dateField]) {
                    vm.filters[dateField] = vm[dateField].getFullYear() + '-' + (vm[dateField].getMonth()+1) + '-' + vm[dateField].getDate();
                } else {
                    vm.filters[dateField] = null;
                }
            });
        });

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            // updateFromFilter();
        });

        function updateFromFilter() {
            vm.loading = true;

            if (canceller) { canceller.resolve(); }
            canceller = $q.defer();

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
                timeout: canceller.promise
            });

            modelPromise.then(function(resp) {
                vm.data = resp.data.payload;
                vm.loading = false;
                canceller = null;
            });
        }

        function sortBy(field) {
            vm.reverse = vm.sortOrder == field ? !vm.reverse : true;
            vm.sortOrder = field;
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }
    }
})();