(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportCompanyHolds', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.company_hold.dir.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($http, $httpParamSerializer) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'reports/company_holds/';
        vm.downloadUrl = apiUrl + '?download=true';

        vm.updateFromFilter = updateFromFilter;
        
        vm.loading = true;
        vm.data = [];
        
        function updateFromFilter() {
            var modelPromise;
            vm.loading = true;

            modelPromise = $http.get(apiUrl);

            modelPromise.then(function(resp) {
                vm.data = resp.data.payload;
                vm.loading = false;
            });
        }

        updateFromFilter();
    }
})();