(function () {
    "use strict";

    angular
        .module('slate.conditions')
        .directive('slateConditionsSearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/conditions/conditions.searchbox.dir.html',
            scope: {
                conditionsId: '=',
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function directiveController($scope, SlateConditionsList) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;

        vm.conditions = new SlateConditionsList();

        function getDropDown() {
            return vm.conditions.getList();
        }

        getDropDown();

    }

})();
