(function() {
    "use strict";

    angular
        .module('slate.library.workflows')
        .factory('SlateWorkflows', ModelList)
        .factory('SlateWorkflow', ModelDetail);

    var apiUrl = API_URL + 'projects/milestones/';

    function ModelList($http, $q, SlateWorkflow, SlateAssignedRoles) {
        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.saveAll = saveAll;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters, short) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlateWorkflow();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveAll(defaults) {
            /* jshint validthis: true */
            var self = this;
            var promises = [];
            var i = 0;
            angular.forEach(self.list, function(model) {
                angular.forEach(defaults, function(value, field) {
                    model[field] = value;
                });
                model.order = i;
                promises.push(model.saveModel());
                i = i + 1;
            });

            return $q.all(promises);
        }

    }

    function ModelDetail($http, $q, SlateAssignedRoles) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();
            self.stages = [
                'Unstarted',
                'Enquiry',
                'Development',
                'Production',
                'Delivery',
                'Invoicing',
            ];
            return self;
        };

        var today = new Date();
        var nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },
            {name: 'slug', def: null, readonly: true },
            {name: 'name', def: ""},
            {name: 'template', def: false, readonly: true},
            {name: 'request'},
            {name: 'request_info', readonly: true},
            {name: 'project'},
            {name: 'project_slug'},
            {name: 'project_num'},
            {name: 'project_name'},
            {name: 'project_parent_type'},
            {name: 'project_parent_id'},
            {name: 'project_parent_name'},
            {name: 'project_parent_slug'},
            {name: 'vendor', def: false},
            {name: 'complete', def: false},
            {name: 'completed_on', readonly:true},
            {name: 'hours_spent', def: 0, readonly: true, type: 'float'},
            {name: 'hours_est', def: 0, type: 'float'},
            {name: 'stage', def: "Unstarted"},
            {name: 'tag', def: "workflow"},
            {name: 'low_priority', def: false},

            {name: 'form', def: [], type: 'json'},
            {name: 'formdata', def: {}, type: 'json'},
            {name: 'checkboxes', def: [], type: 'json'},

            {name: 'desired_by', def: nextweek, type: 'date'},

            {name: 'order'},

            {name: 'updated', def: null, readonly: true },
            {name: 'created', def: null, readonly: true },
            {name: 'created_by', readonly: true},
            {name: 'created_by_info', readonly: true},

        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object', payload);
                return $q.reject(self);
            }

            angular.forEach(fields, function(field) {
                if(typeof(payload[field.name]) == 'undefined') { return; }

                self[field.name] = angular.copy(payload[field.name]);

                if (field.type == 'float' && self[field.name]) {
                    self[field.name] = parseFloat(self[field.name]);
                }
                if (field.type == 'date' && payload[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {}
                }
            });

            if (payload.assigned_roles) {
                self.assroles = new SlateAssignedRoles();
                self.assroles.loadListFromPayload(payload.assigned_roles);
            }

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];
                var loadPromise;

                loadPromise = self.loadFromPayload(response.data.payload);

                return loadPromise.then(function() {
                    self.permissions = response.data.permissions;

                    return $q.when(promises).then(function() {

                        deferred.resolve(self);
                    });

                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            angular.forEach(fields, function(field) {
                if (!field.readonly && typeof(self[field.name]) != 'undefined' && self[field.name] !== null) {
                    data[field.name] = self[field.name];
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
