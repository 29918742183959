(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .directive('slatePoCondLineItemList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/purchaseorders/po.lineitem.cond_list.dir.html',
            scope: {
                purchaseOrder: '=',
                hidenew: '=?',
                showTotals: '=?',
                fields: '=?',
                projectId: '=?',
                dirty: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlatePOLineItems, SlatePOLineItem, SlatePOLineItemEditSrv, SlatePOLineItemPriceTierEditSrv, getLinkSrv, SlateDeleteObjSrv) {
        /* jshint validthis: true */
        var vm = this;

        if (!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'photo',
                'jansy_pn',
                'finished_good',
                'name',
                'category',
                'material',
                'required_date',
                'ready_date',
                'qty',
                'qty_received',
                'qty_shipped',
                'unitprice',
                'unitprice_total'
            ];
            /* beautify preserve:end */
        }

        vm.getLink = getLinkSrv;
        vm.loading = false;

        vm.auth = SlateAuth;

        vm.getStyle = getStyle;
        vm.getLineItems = getLineItems;

        vm.getPriceTotal = getPriceTotal;
        vm.getQtyTotal = getQtyTotal;
        vm.getQtyRecTotal = getQtyRecTotal;

        vm.combinedList = [];

        function getPriceTotal() {
            var total = Big(0.0);
            vm.purchaseOrder.line_items.forEach(function (item) {
                total = total.add(item.getCostTotal());
            });
            return total.toFixed(5);
        }

        function getQtyTotal() {
            var total = Big(0.0);
            vm.purchaseOrder.line_items.forEach(function (item) {
                total = total.add(item.qty);
            });
            return total.toFixed();
        }
        function getQtyRecTotal() {
            var total = Big(0.0);
            vm.purchaseOrder.line_items.forEach(function (item) {
                total = total.add(item.qty_received);
            });
            return total.toFixed();
        }

        function getLineItems() {
            var list = [];
            vm.purchaseOrder.line_items.forEach(function (line_item) {
                var list_item = list.find(function (list_item) {
                    return list_item.component == line_item.component && list_item.unit_price == line_item.unit_price;
                });
                if (list_item) {
                    list_item.qty += line_item.qty;
                    list_item.qty_received += line_item.qty_received;
                    list_item.qty_shipped += line_item.qty_shipped;
                    line_item.unit_price_total += line_item.getCostTotal();
                } else {
                    line_item.unit_price_total = line_item.getCostTotal();
                    list.push(_.cloneDeep(line_item));
                }
            });
            vm.combinedList = list;
        }

        $scope.$watchCollection(vm.purchaseOrder.line_items, function () {
            getLineItems();
        });

        function getStyle(field) {
            if (field == 'name') {
                return {
                    'width': '100%',
                    'overflow': 'hidden',
                    'white-space': 'nowrap',
                    'text-overflow': 'ellipsis'
                };
            }

            return {
                'overflow': 'hidden',
                'white-space': 'nowrap',
                'text-overflow': 'ellipsis',
            };
        }

        vm.get_vendor_split = function get_vendor_split(option, qty) {
            var price = 0;
            var split = 0;

            if (option.final_split) { return option.final_split; }

            if (typeof option.prices == 'string') {
                option.prices = JSON.parse(option.prices);
            }
            if (!option.prices) { return 'n/a'; }

            //default to the smallest split
            Object.keys(option.prices).forEach(function (key) {
                var new_split = parseFloat(key);
                if ((split == 0 || new_split <= split) && option.prices[key]) {
                    split = new_split;
                    option.final_split = key;
                }
            });

            // find the largest split that fits
            Object.keys(option.prices).forEach(function (key) {
                var new_split = parseFloat(key);
                if (new_split <= qty && new_split > split && option.prices[key]) {
                    split = new_split;
                    option.final_split = key;
                }
            });
            if (Number.isNaN(option.final_split)) { return 'n/a'; }
            option.final_price = parseFloat(option.prices[option.final_split]);
            return option.final_split;
        };

        vm.get_vendor_price = function get_vendor_price(option, qty) {
            var price = 0;
            var split = 0;

            if (option.final_price) { return option.final_price; }
            if (!option.final_split) { return 'n/a'; }

            if (typeof option.prices == 'string') {
                option.prices = JSON.parse(option.prices);
            }
            if (!option.prices) { return 'n/a'; }

            option.final_price = parseFloat(option.prices[option.final_split]);
            if (Number.isNaN(option.final_price)) { return 'n/a'; }
            return option.final_price;
        };

        vm.get_vendor_total = function get_vendor_total(option, qty) {
            if (!option || !option.final_price || !qty) { return 'n/a'; }
            return Big(option.final_price).times(Big(qty)).toFixed(5);
        };

        vm.get_fixed_price = function get_fixed_price(option) {
            return Big(option.price).toFixed(5);
        };

    }


})();
