(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .directive('slateRfqEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/rfqs/rfq.edit.dir.html',
            scope: {
                rfq: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $state,
        getLinkSrv,
        SlateAuth,
        SlateRolesSrv,
        SlateAssignedRoles,
        SlateProject,
        SlateRFQ
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.saveObject = saveObject;
        vm.voidObject = voidObject;

        vm.isComplete = isComplete;
        vm.isEditable = isEditable;
        vm.isVoidable = isVoidable;

        vm.baseStatus = vm.rfq.status;

        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            heightMax: 200
        };

        vm.cleaveConfig = {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            numeralPositiveOnly: true,
            rawValueTrimPrefix: true
        };
        vm.minDate = new Date(new Date().getTime()+(7*24*60*60*1000));

        vm.statusOptions = ['Draft', 'Ready', 'Quoting', 'Quoted', 'Completed', 'Cancelled', 'Exception Hold', 'Void'];
        vm.statusAvailable = [vm.rfq.status, 'Cancelled', 'Exception Hold'];

        if(vm.rfq.status == 'Draft') { vm.statusAvailable.push('Ready'); }
        if(vm.rfq.status == 'Ready') { vm.statusAvailable.push('Draft'); }
        if(vm.rfq.status == 'Exception Hold') { vm.statusAvailable.push('Ready'); vm.statusAvailable.push('Draft'); }

        if(vm.auth.perms.rfq_can_override_status) { vm.statusAvailable = ['Draft', 'Ready', 'Quoting', 'Quoted', 'Completed', 'Cancelled', 'Exception Hold']; }


        function isComplete() {
            if(!vm.rfq.name) { return false; }
            if(!vm.rfq.company) { return false; }
            if(!vm.rfq.project) { return false; }
            if(!vm.rfq.sales_mgr) { return false; }
            if(!vm.rfq.proj_mgr) { return false; }
            if(!vm.rfq.quote_due) { return false; }
            if(!vm.rfq.delivery_date) { return false; }
            if(!vm.rfq.domestic && !vm.rfq.international) { return false; }
            if(vm.rfq.line_items.some(function(item) { return !item.qty; })) { return false; }
            if(!vm.rfq.responses.length) { return false; }

            return true;
        }

        function isEditable() {
            if(vm.baseStatus == 'Void') { return false; }
            if(!(vm.baseStatus == 'Draft' || vm.baseStatus == 'Ready') && !vm.auth.perms.rfq_can_override_status) { return false; }

            return true;
        }

        function isVoidable() {
            if(vm.baseStatus == 'Void') { return false; }
            if(vm.baseStatus == 'Draft') { return false; }
            if(vm.auth.perms.rfq_can_void_all) { return true; }
            if(vm.rfq.proj_mgr != vm.auth.user.contact_id) { return false; }

            return true;
        }


        function voidObject() {
            if (vm.saving === true) {
                return false;
            }

            if(!window.confirm('Are you sure you want to VOID and duplicate this RFQ?')) { return false; }

            vm.voided = false;
            vm.voiding = true;
            vm.saving = true;
            vm.void_error = false;

            vm.rfq.voidModel().then(function () {
                vm.voided = true;
                vm.voiding = false;
                vm.saving = false;
                vm.void_error = false;
                $state.transitionTo(
                    'rfqEditPage',
                    {id: vm.rfq.id}
                );
            }, function() {
                vm.voided = false;
                vm.voiding = false;
                vm.saving = false;
                vm.void_error = true;
            });
        }

        function saveObject() {
            var deferred = $q.defer();

            if (vm.saving === true) {
                return false;
            }

            if(!isEditable()) {
                vm.saving = false;
                return false;
            }
            vm.saved = false;
            vm.saving = true;
            vm.error = false;

            vm.rfq.line_items.forEach(function(item, idx) {
                item.sort_order = idx;
            });

            vm.rfq.saveModel().then(function () {
                var promises = [];

                if (vm.fileSrv) {
                    promises.push(vm.fileSrv.uploadFiles('rfq', vm.rfq.id));
                }

                return $q.all(promises).then(function () {
                    $timeout(function () {
                        vm.saving = false;
                        vm.saved = true;
                        vm.rfqFrom.$setPristine();
                        vm.baseStatus = vm.rfq.status;
                        if ($state.current.name != 'rfqEditPage') {
                            $state.transitionTo(
                                'rfqEditPage',
                                {id: vm.rfq.id},
                                {location: 'replace'}
                            );
                        }
                    }, 300);
                }, function () {
                    vm.saving = false;
                    vm.error = true;
                });
            }, function () {
                vm.saving = false;
                vm.error = true;
            });

            return deferred.promise;
        }

        function projectSync() {
            var assroles = new SlateAssignedRoles();
            var project = new SlateProject();
            var role_dict = SlateRolesSrv.dict;
            if(!vm.rfq.proj_mgr || !vm.rfq.sales_mgr) {
                assroles.getList({
                    parent_type: 'project',
                    parent_fk: vm.rfq.project
                }).then(function () {

                    angular.forEach(assroles.list, function (item) {
                        if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Project Manager' && !vm.rfq.proj_mgr) {
                            vm.rfq.proj_mgr = item.member_fk;
                        }
                        if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Sales Manager' && !vm.rfq.sales_mgr) {
                            vm.rfq.sales_mgr = item.member_fk;
                        }
                    });

                });
            }
            if (!vm.rfq.company) {
                project.loadFromServer(vm.rfq.project).then(function () {
                    if (project.parent_type == 'company') {
                        vm.rfq.company = project.parent_fk;
                    }
                });
            }
        }
        $scope.$watch('vm.rfq.project', function (newVal, oldVal) {
            if(newVal != oldVal && vm.rfq.project) {
                projectSync();
            }
        });
        projectSync();

    }

})();
