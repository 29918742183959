(function () {
    "use strict";

    angular
        .module('slate.logistics')
        .directive('slateGoodsApprovalButtons', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/logistics/goods/goods.approval.dir.html',
            scope: {
                purchaseorderId: '=',
                shipment: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

        vm.getType = getType;
        vm.getStatus = getStatus;
        vm.isApprover = isApprover;
        vm.approve = approve;

        function getType() {
            if(vm.shipment.status == 'schedule_up') {
                return 'schedule';
            }
            if(vm.shipment.status == 'quoted') {
                return 'quote';
            }
            return '';
        }

        function getStatus(type) {
            var po_id = vm.purchaseorderId;
            var goods = vm.shipment.goods.filter(function(good) { return good.purchaseorder == po_id; });
            // var type = getType();
            var isApproved = goods.every(function(item) { return item[type+'_approved'] == 'approved'; });
            var isRejected = goods.some(function(item) { return item[type+'_approved'] == 'rejected'; });
            if(isRejected) { return 'rejected'; }
            if(isApproved) { return 'approved'; }
            return '';
        }


        function isApprover() {
            var po_id = vm.purchaseorderId;
            var user_id = vm.auth.user.contact_id;
            var goods = vm.shipment.goods.filter(function(good) { return good.purchaseorder == po_id; });
            var isApprover = goods.every(function(item) {
                if(item[getType() +'_approved']) { return false; }
                if([item.proj_mgr, item.sales_mgr].includes(user_id)) { return true; }
                return false;
            });
            var isSaved = goods.every(function(item) { return item.id; });
            return isApprover && isSaved;
        }

        function approve(status) {
            var apiUrl = API_URL + 'logistics/shipment/' + vm.shipment.id + '/approve/';
            var data = {
                purchaseorder: vm.purchaseorderId,
                approval_status: status,
                approval_type: vm.getType()
            };

            return $http.post(apiUrl, data).then(function(response) {
                vm.shipment.loadFromServer();
            });
        }
    }


})();
