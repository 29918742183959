(function () {
    'use strict';

    angular
        .module('slate.companies')
        .directive('slateCompanyBrandsTab', slateCompaniesList);

    function slateCompaniesList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/companies/brands.dir.html',
            scope: {
                brands: '=',
                company: '='
            },
            controller: companiesListController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function companiesListController($scope, $timeout, SlateCompanyBrand) {
        /* jshint validthis: true */
        const vm = this;

        // add brand
        vm.addBrand = () => {
            vm.brands.list.push(new SlateCompanyBrand());
            $timeout(() => {
                // wait for elements to finish adding
                const elements = document.getElementsByClassName('brandTab_brandEntryName');
                const idx = elements.length - 1;
                const el = elements.item(idx);
                el.focus();
            });
        };
        // remove brand
        vm.removeBrand = (brand) => {
            if (!brand.id) {
                brand.toDelete = true;
                vm.brands.list = vm.brands.list.filter((item) => !item.toDelete);
            } else {
                brand.toDelete = true;
                brand.deleteModel().then(() => {
                    vm.brands.list = vm.brands.list.filter((item) => !item.toDelete);
                });
            }
        };
    }
})();
