(function () {
    'use strict';

    angular
        .module('slate.app')
        .directive('numberInput', ($filter) => ({
            restrict: 'A',
            require: 'ngModel',

            scope: {
                numberInput: '&',
                onInit: '&?',
                onValueChange: '&?',
            },

            compile() {
                return {
                    pre($scope, $element, attrs, ngModelCtrl) {
                        const defaultOptions = {
                            numeral: true,
                            numeralDecimalScale: 5,
                            numeralThousandsGroupStyle: 'thousand',
                            rawValueTrimPrefix: true,
                        };

                        function getOptions() {
                            const options = $scope.numberInput();
                            if (typeof options !== 'object') { return defaultOptions; }
                            console.log(Object.assign({}, defaultOptions, options));
                            return Object.assign({}, defaultOptions, options);
                        }

                        $scope.instance = new Cleave($element[0], getOptions());

                        if ($scope.onInit) {
                            $scope.onInit()($scope.instance);
                        }

                        ngModelCtrl.$formatters.push((val) => {
                            $scope.instance.setRawValue(val);

                            return $scope.instance.getFormattedValue();
                        });

                        ngModelCtrl.$parsers.push((newFormattedValue) => {
                            if ($scope.onValueChange) {
                                $scope.onValueChange()(newFormattedValue);
                            }

                            return $scope.instance.getRawValue();
                        });

                        // Recreate cleave instance if any cleave options change
                        $scope.$watch(
                            () => $scope.numberInput(),
                            (newOptions, oldOptions) => {
                                $scope.instance.destroy();
                                $scope.instance = new Cleave($element[0], getOptions());
                            },
                            // eslint-disable-next-line comma-dangle
                            true
                        );

                        $scope.$on('$destroy', () => {
                            $scope.instance.destroy();
                            $scope.instance = null;
                        });
                    },
                };
            },

        }));
})();
