(function () {
    "use strict";

    angular
        .module('slate.orders')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('ordersListPage', {
                url: '/orders/',
                template: "<div class='content-pane list-page'><slate-order-list></slate-order-list></div>",
                current_app: 'orders',
                slateTitle: 'Orders',
            })

            .state('orderNewPage', {
                url: '/orders/orders/new/',
                templateUrl: '/partials/orders/order.edit.page.html',
                controller: 'SlateOrderNewPageController',
                controllerAs: 'vm',
                current_app: 'orders',
                slateTitle: 'Create New Order - Orders',
            })
            .state('orderEditPage', {
                url: '/orders/orders/:id/edit/',
                templateUrl: '/partials/orders/order.edit.page.html',
                controller: 'SlateOrderEditPageController',
                controllerAs: 'vm',
                current_app: 'orders',
                slateTitle: 'Edit Order - Orders',
            })


            ;
    }
})();