(function() {
    "use strict";

    angular
        .module('slate.library.salesforce')
        .service('SlateSFLinkage', SFService);


    function SFService($rootScope, $http, SlateAuth) {
        var apiUrl = API_URL + 'salesforce/sf_links/';

        var srv = {
            parent_fk: '',
            parent_type: '',
            links: [],
            errors: 0,

            update: update,
            delete: deleteItem
        };

        var auth = SlateAuth;

        $rootScope.$on('$stateChangeStart', function(){
            srv.parent_type = '';
            srv.parent_fk = '';
            srv.links = [];
            srv.errors = 0;
        });

        function update() {
            if(!auth.perms.salesforce_links_can_access) { return false;}
            if(!srv.parent_fk || !srv.parent_type) { return false; }

            return $http.get(apiUrl, {
                params: {
                    parent_type: srv.parent_type,
                    parent_fk: srv.parent_fk
                }
            }).then(function(response) {
                srv.links = response.data.payload.links;
                srv.errors = response.data.payload.error_count;
            });
        }

        function deleteItem(id) {
            return $http.delete(apiUrl + id + '/').then(function(response) {
                update();
            });
        }

        $rootScope.$watchCollection(function() {
            return [srv.parent_fk, srv.parent_type];
        }, function () {
            return update();
        });

        return srv;
    }

})();
