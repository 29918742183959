(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .controller('SlateSOReleaseNewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($q, $scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateSalesOrder, SlateShipment) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.salesorder = new SlateSalesOrder();

        vm.salesorder.loadFromServer($stateParams.id).then(function () {

            if (vm.salesorder.name) {
                SlateTitle.set('Add release for ' + vm.salesorder.name + ' - Sales Orders');
            } else {
                SlateTitle.set('Add release - Sales Orders');
            }

            vm.loading = false;

        });
    }

})();
