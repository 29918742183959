(function () {
    "use strict";

    angular
        .module('slate.requests')
        .directive('slateReportRequests', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/reports/reports.report.requests.dir.html',
            scope: {

            },
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $state, $location, $http, $q, $stateParams, $httpParamSerializer, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.linksrv = getLinkSrv;
        vm.getLink = getLinkSrv;

        var now = new Date();
        var oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

        var modelPromise;
        var rawData = {};
        let canceller = null;

        vm.data = {};
        vm.getDownloadUrl = getDownloadUrl;
        vm.getDateDiff = getDateDiff;

        var apiUrl = API_URL + 'reports/requests/';

        vm.loading = true;
        vm.pagerOptions = {};
        if (!vm.filters) {
            vm.filters = {
                startDate: oneWeekAgo,
                endDate: now
            };
        }

        vm.filters = angular.extend(vm.filters, {
            //vendor: $location.search().vendor || false,
            q: $location.search().q
        });


        // Date picker stuff

        vm.startDateIsOpen = false;
        vm.startDateOpen = startDateOpen;

        vm.endDateIsOpen = false;
        vm.endDateOpen = endDateOpen;

        function startDateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.startDateIsOpen = true;
        }

        function endDateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.endDateIsOpen = true;
        }

        updateFromFilter();

        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            updateFromFilter();
        });

        function updateFromFilter() {
            var newData = [];
            vm.loading = true;

            if (vm.filters.q) {
                $location.replace().search('q', vm.filters.q);
            } else {
                $location.replace().search('q', null);
            }

            if (canceller) { canceller.resolve(); }
            canceller = $q.defer();

            const filters = Object.assign({
                "fields": [
                    "id",
                    "slug",
                    "order",
                    "project",
                    "project_slug",
                    "project_num",
                    "project_name",
                    "project_composite_num",
                    "company_info",
                    "task_count",
                    "task_complete",
                    "name",
                    "low_priority",
                    "hours_est",
                    "desired_by",
                    "assigned_to",
                    "assigned_to_name",
                    "notes",
                    "complete",
                    "completed_on",
                    "completedby",
                    "created",
                    "createdby",
                    "createdby_name",
                    "tasks_assignedto",
                ],
            }, vm.filters);

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
                timeout: canceller.promise
            });

            modelPromise.then(function (resp) {
                var tempData = resp.data.payload;
                angular.forEach(tempData, function (item) {
                    item.desired_by = new Date(item.desired_by);
                    item.desired_by = new Date(item.desired_by.getTime() + (item.desired_by.getTimezoneOffset() * 60000));

                    item.created = new Date(item.created);
                    item.created = new Date(item.created.getTime() + (item.created.getTimezoneOffset() * 60000));

                });
                vm.data = tempData;
                vm.loading = false;
            });
        }

        function getDateDiff(orgCreated, orgDesired) {
            var created = orgCreated;
            var desired = orgDesired;
            var diff = 0;

            created = created.setHours(0, 0, 0, 0);
            diff = desired - created; // millisconds
            diff = Math.round((diff) / 1000); // in seconds
            diff = Math.round((diff) / 60); // in minutes
            diff = Math.round((diff) / 60); // in hours
            diff = Math.round((diff) / 24); // in days

            return diff;
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }


    }


})();
