(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .directive('slateRfqResponseComparison', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/rfqs/rfq.response.comparison.dir.html',
            scope: {
                responses: '=',
                rfq: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $state,
        getLinkSrv,
        SlateAuth,
        SlateRFQ
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

    }

})();
