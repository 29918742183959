(function () {
    "use strict";

    angular
        .module('slate.bom')
        .directive('slateBomEdit', modelList);
        
    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/bom/bom.edit.dir.html',
            scope: {
                bom: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $timeout, $q) {
        /* jshint validthis: true */
        var vm = this;

        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            zIndex: 10,
        };



    }

})();
