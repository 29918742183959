(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .directive('slateSalesorderSearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/salesorders/salesorder.search.dir.html',
            scope: {
                salesorderId: '=',
                orderId: '=',
                soNumMax: '<?',
                readonly: '<?',
                showDropdown: "<?"
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function directiveController($scope, $q, SlateSalesOrders, SlateSalesOrder) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;

        vm.specs = new SlateSalesOrders();
        vm.dropdownSpecs = new SlateSalesOrders();
        vm.dropdownList = [];
        var selectedSpec;

        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.clear = clear;

        vm.selectDropdown = selectDropdown;

        getDropDown();

        function getDropDown() {
            var promise;
            var filters = {};

            if (!vm.showDropdown) { return false; }
            if (vm.readonly) { return false; }
            if (!vm.orderId) {  return false; }
            filters.order = vm.orderId;
            if(vm.soNumMax) {
                filters.so_num_max = vm.soNumMax;
            }

            promise = vm.dropdownSpecs.getList(filters);

            return promise;
        }

        function selectDropdown($item) {
            vm.searching = false;
            vm.salesorderId = $item.id;
            selectedSpec = $item.id;
            vm.specObj = $item;
            vm.specName = '' + $item.jansy_so + ' - ' + $item.name;
        }


        function getSearch(q) {
            var promise;
            var filters = {
                q: q,
                page: 1,
                count: 50
            };
            vm.searching = true;
            if (q && q.length < 3) { return $q.reject([]); }
            //if (vm.companyId) { filters.company = vm.companyId;}
            filters.order = vm.orderId;
            if(vm.soNumMax) { filters.so_num_max = vm.soNumMax; }

            promise = vm.specs.getList(filters).then(function () {
                return vm.specs.list;
            });
            return promise;
        }

        function selectSearch($item, $model, $label, $event) {
            vm.searching = false;
            vm.salesorderId = $item.id;
            selectedSpec = $item.id;
            vm.specObj = $item;
            vm.specName = '' + $item.jansy_so + ' - ' + $item.name;
        }

        function clear() {
            if (vm.readonly) { return true; }
            vm.salesorderId = null;
            selectedSpec = null;
            vm.specObj = null;
            vm.specName = null;
        }

        $scope.$watch('vm.salesorderId', function () {
            if (selectedSpec != vm.salesorderId && vm.salesorderId) {
                var spec = new SlateSalesOrder();
                spec.loadFromServer(vm.salesorderId).then(function () {
                    vm.specObj = spec;
                    vm.specName = '' + spec.jansy_so + ' - ' + spec.name;
                    selectedSpec = spec.id;
                });
            }

        });

        $scope.$watch('vm.orderId', function () {
            getDropDown();
        });

        $scope.$watch('vm.typeaheadOpen', function() {
            if(!vm.typeaheadOpen && !vm.specObj && vm.salesorderId) {
                var spec = new SlateSalesOrder();
                spec.loadFromServer(vm.salesorderId).then(function () {
                    vm.specObj = spec;
                    vm.specName = '' + spec.jansy_so + ' - ' + spec.name;
                    selectedSpec = spec.id;
                });
            }
        });
    }

})();
