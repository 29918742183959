(function () {
    'use strict';

    angular
        .module('slate.salesorders')
        .directive('slateSalesOrderEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/salesorders/salesorder.edit.dir.html',
            scope: {
                salesOrder: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $state,
        $http,
        getLinkSrv,
        SlateAuth,
        SlateCompany,
        SlateLocations,
        SlateContactMethods,
        SlateSOLineItems,
        SlateSOLineItem,
        SlatePurchaseOrder,
        SlatePOLineItem,
        SlatePOLineItems,
        SlateDateChangeSrv,
        SlateDateLogList,
        SlateSFLinkage
    ) {
        /* jshint validthis: true */
        const vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.so_number = vm.salesOrder.jansy_so;

        vm.saveObject = saveObject;
        vm.isComplete = isComplete;

        vm.cleanUp = cleanUp;
        vm.createBilling = createBilling;
        vm.generatePDF = generatePDF;
        vm.hasAddress = hasAddress;
        vm.setAddress = setAddress;

        vm.froalaOptions = {
            toolbarButtons: ['bold',
                'italic',
                'underline',
                '|',
                'align',
                'formatOL',
                'formatUL'],
            zIndex: 10
        };

        vm.bomItemUnitCosts = {};

        vm.baseStatus = vm.salesOrder.status;

        vm.SOStatusOptions = ['Draft',
            'SO',
            'SO Revision',
            'Approved',
            'Emailed',
            'Signed',
            'Sent',
            'Partially Billed',
            'Closed',
            'Closed - Short',
            'Cancelled',
            'Exception Hold'];
        vm.SOStatusAvailable = [vm.salesOrder.status];
        checkAvailStatus();

        function checkAvailStatus() {
            vm.SOStatusAvailable = [vm.baseStatus];
            if (vm.baseStatus === 'Draft') { vm.SOStatusAvailable.push('SO', 'Exception Hold', 'Cancelled'); }
            if (vm.baseStatus === 'SO') { vm.SOStatusAvailable.push('Exception Hold', 'Cancelled'); }
            if (vm.baseStatus === 'SO Revision') { vm.SOStatusAvailable.push('Exception Hold', 'Cancelled'); }
            if (vm.baseStatus === 'Approved') { vm.SOStatusAvailable.push('Emailed', 'Exception Hold', 'Cancelled'); }
            if (vm.baseStatus === 'Emailed') { vm.SOStatusAvailable.push('Exception Hold', 'Cancelled'); }
            if (vm.baseStatus === 'Sent') { vm.SOStatusAvailable.push('SO Revision', 'Closed', 'Closed - Short', 'Exception Hold', 'Cancelled', 'Received', 'Partially Billed'); }
            if (vm.baseStatus === 'Signed') { vm.SOStatusAvailable.push('SO Revision', 'Closed', 'Closed - Short', 'Exception Hold', 'Cancelled', 'Received', 'Partially Billed'); }
            if (vm.baseStatus === 'Partially Billed') { vm.SOStatusAvailable.push('SO Revision', 'Closed', 'Closed - Short', 'Exception Hold', 'Received'); }
            if (vm.baseStatus === 'Received') { vm.SOStatusAvailable.push('Closed', 'Closed - Short', 'Exception Hold'); }
            if (vm.baseStatus === 'Closed') { vm.SOStatusAvailable.push('Exception Hold', 'Cancelled'); }
            if (vm.baseStatus === 'Exception Hold') { vm.SOStatusAvailable.push('SO', 'SO Revision', 'Closed', 'Closed - Short', 'Cancelled', 'Received', 'Partially Billed'); }

            if (vm.auth.perms.salesorders_can_mark_approved && ['SO', 'SO Revision', 'Exception Hold'].includes(vm.baseStatus)) { vm.SOStatusAvailable.push('Approved'); }
            if (vm.auth.perms.salesorders_can_mark_signed && ['Emailed', 'Sent'].includes(vm.baseStatus)) { vm.SOStatusAvailable.push('Signed'); }
        }

        vm.countries = Countries;

        vm.lineItemsDirty = false;

        vm.company = new SlateCompany();

        vm.co_addresses = new SlateLocations();
        vm.co_phones = new SlateContactMethods();

        vm.ship_types = ['oceanlcl',
            'oceanfcl',
            'oceanexp',
            'air',
            'truck',
            'ups',
            'fedex',
            'dhl',
            'other'];
        vm.incoterms = ['exw',
            'fca',
            'fob',
            'cif',
            'cpt',
            'cip',
            'dat',
            'dap',
            'ddp',
            'ddu',
            'other'];

        vm.createPO = createPO;
        vm.hasSelectedBomItem = hasSelectedBomItem;

        vm.sf_linkage = SlateSFLinkage;
        vm.dateLog = SlateDateLogList;
        vm.dateChangeSrv = SlateDateChangeSrv;

        vm.dateChangeSrv.watch(vm.salesOrder, ['desired_date']);

        $scope.$watch('vm.salesOrder.id', (old_val, new_val) => {
            if (vm.salesOrder.id && !vm.saving) {
                vm.sf_linkage.parent_type = 'salesorder';
                vm.sf_linkage.parent_fk = vm.salesOrder.id;
                vm.dateLog.parent_type = 'salesorder';
                vm.dateLog.parent_fk = vm.salesOrder.id;
                updateDependants();
            }
        });

        $scope.$watch('vm.salesOrder.company', (old_val, new_val) => {
            if (vm.salesOrder.company) {
                vm.company.loadFromServer(vm.salesOrder.company);
                vm.co_addresses.getList({ company: vm.salesOrder.company });
                vm.co_phones.getList({ company: vm.salesOrder.company });
            }
        });

        $scope.$watch('vm.salesOrder.status', (new_val, old_val) => {
            vm.show_remove_exception_warning = false;
            vm.show_exception_warning = false;
            if (new_val === vm.baseStatus) { return false; }
            if (new_val === 'Exception Hold') {
                vm.show_exception_warning = true;
                return true;
            }
            if (vm.baseStatus === 'Exception Hold') {
                vm.show_remove_exception_warning = true;
                return false;
            }
            return new_val;
        });

        function setAddress(address) {
            let selected_addr;
            function select_addr(item) {
                if (item.id === vm.selected_client_address) { selected_addr = item; }
            }
            vm.co_addresses.list.forEach(select_addr);
            if (!selected_addr) { return false; }

            vm.salesOrder.ship_street = selected_addr.street1;
            if (selected_addr.street2) {
                vm.salesOrder.ship_street = `${vm.salesOrder.ship_street}, ${selected_addr.street2}`;
            }
            if (selected_addr.street3) {
                vm.salesOrder.ship_street = `${vm.salesOrder.ship_street}, ${selected_addr.street3}`;
            }
            vm.salesOrder.ship_city = selected_addr.city;
            vm.salesOrder.ship_state = selected_addr.state;
            vm.salesOrder.ship_postal = selected_addr.postal;
            vm.salesOrder.ship_country = selected_addr.country;

            vm.selected_client_address = null;

            return null;
        }

        function updateDependants() {

        }

        $scope.$watch('vm.lineItemsDirty', (old_val, new_val) => {
            if (vm.lineItemsDirty) {
                vm.salesOrder.revision = true;
            }
        });

        function hasAddress() {
            // These are now seperate to avoid the weirdnews with large amounts of || and formatting
            if (!vm.salesOrder.ship_phone) { return false; }
            if (!vm.salesOrder.ship_street) { return false; }
            if (!vm.salesOrder.ship_city) { return false; }
            if (!vm.salesOrder.ship_state) { return false; }
            if (!vm.salesOrder.ship_postal) { return false; }
            return true;
        }
        function isComplete() {
            if (!hasAddress()) { return false; }
            if (!vm.salesOrder.shipvia) { return false; }
            if (!vm.salesOrder.ship_terms) { return false; }
            if (!vm.salesOrder.order) { return false; }

            if (vm.salesOrder.status !== 'Draft' && !vm.salesOrder.client_po && !vm.auth.user.permissions.companies_can_override_holds) { return false; }
            if (vm.salesOrder.status !== 'Draft' && !vm.salesOrder.date) { return false; }
            if (vm.salesOrder.status !== 'Draft' && !vm.salesOrder.desired_date) { return false; }

            if (vm.salesOrder.status !== 'Draft' && !vm.salesOrder.line_items.length) { return false; }

            return true;
        }

        function saveObject() {
            const deferred = $q.defer();

            if (vm.saving === true) {
                return false;
            }

            vm.saving = true;
            vm.error = false;

            vm.salesOrder.line_items.forEach((item, idx) => {
                item.sort_order = idx + 1;
            });

            vm.salesOrder.saveModel().then(() => {
                const promises = [];
                vm.so_number = vm.salesOrder.jansy_so;

                if (vm.fileSrv) {
                    promises.push(vm.fileSrv.uploadFiles('salesorder', vm.salesOrder.id));
                }

                vm.salesOrder.line_items.forEach((item) => {
                    promises.push(item.getBOMPrice(vm.bomItemUnitCosts));
                });

                return $q.all(promises).then(() => {
                    $timeout(() => {
                        vm.saving = false;
                        vm.salesOrderFrom.$setPristine();
                        vm.fileSrv.files = [];
                        vm.lineItemsDirty = false;
                        vm.baseStatus = vm.salesOrder.status;
                        checkAvailStatus();

                        // $state.go('ordersListPage');
                        if ($state.current.name !== 'salesOrderEditPage') {
                            $state.transitionTo(
                                'salesOrderEditPage',
                                { id: vm.salesOrder.id },
                                { location: 'replace' }
                            );
                        }
                    }, 300);
                }, () => {
                    vm.saving = false;
                    vm.error = true;
                });
            }, () => {
                vm.saving = false;
                vm.error = true;
            });

            return deferred.promise;
        }

        function hasSelectedBomItem() {
            return vm.salesOrder.line_items.some((item) => {
                if (!item.selectedBomItems) { return false; }

                if (Object.keys(item.selectedBomItems).length > 0) { return true; }

                return false;
            });
        }

        $scope.$on('createPO', () => { createPO(); });
        function createPO() {
            const newPO = new SlatePurchaseOrder();

            const inital_data = {
                salesorder: vm.salesOrder.id,
                name: vm.salesOrder.name,
                company: vm.salesOrder.company,
                project: vm.salesOrder.project,
                order: vm.salesOrder.order,
                notes: vm.salesOrder.notes,
                reorder: vm.salesOrder.reorder,
                client_po: vm.salesOrder.client_po,
                proj_mgr: vm.salesOrder.proj_mgr,
                sales_mgr: vm.salesOrder.sales_mgr,
                shipvia: vm.salesOrder.shipvia,
                ship_terms: vm.salesOrder.ship_terms
            };

            if (vm.savingPO) { return false; }

            if (!vm.isComplete() || vm.salesOrderFrom.$dirty || vm.fileSrv.files.length) {
                return false;
            }

            // eslint-disable-next-line no-restricted-globals
            if (!vm.hasSelectedBomItem() && !confirm('You have not selected any bom items. Are you sure you want to continue?')) {
                return false;
            }

            vm.savingPO = true;

            newPO.loadFromPayload(inital_data);
            newPO.line_items = [];

            vm.salesOrder.line_items.forEach((finished_good) => {
                if (!finished_good.selectedBomItems) { return false; }
                Object.keys(finished_good.selectedBomItems).forEach((key) => {
                    let order_qty = finished_good.qty;
                    const item = finished_good.selectedBomItems[key];
                    if (Number.isNaN(order_qty)) {
                        order_qty = 0;
                    }
                    order_qty = new Big(order_qty);

                    const spec = new SlatePOLineItem();
                    spec.qty = Big(item.total_req).toFixed(6);
                    spec.jansy_pn = item.internal_item_number;
                    spec.client_pn = item.client_item_number;
                    spec.category = item.component_info.category;
                    spec.material = item.component_info.material;
                    spec.name = item.item_description.substring(0, 74);
                    spec.component = item.component;
                    spec.component_info = item.component_info;
                    spec.finished_good = finished_good.id;
                    spec.unitprice = vm.bomItemUnitCosts[item.id];
                    spec.sellprice = item.component_info.sell_price;
                    spec.specs = item.component_info.brief;
                    if (vm.salesOrder.bom_item_price_tiers[item.id]) {
                        spec.price_tier = vm.salesOrder.bom_item_price_tiers[item.id];
                    }

                    newPO.line_items.push(spec);

                    return spec;
                });
                return null;
            });

            const promise = newPO.saveModel().then(() => {
                const promises = [];

                return $q.all(promises).then(() => {
                    $timeout(() => { vm.savingPO = false; }, 300);
                    if ($state.current.name !== 'purchaseOrderEditPage') {
                        $state.transitionTo(
                            'purchaseOrderEditPage',
                            { id: newPO.id }
                        );
                    }
                });
            });
            return promise;
        }

        function cleanUp() {
            if (!vm.salesOrder.id) { return false; }

            vm.saving = true;
            vm.error = false;

            vm.salesOrder.cleanupModel().then(() => {
                vm.saving = false;
            }, () => {
                vm.saving = false;
                vm.error = true;
            });

            return null;
        }

        function createBilling(btype_param) {
            const btype = btype_param || 'Final';
            let allItemsBilled = true;
            vm.salesOrder.line_items.forEach((line_item) => {
                if (line_item.unfiltered_units_billed < line_item.qty) {
                    allItemsBilled = false;
                }
            });
            if (allItemsBilled && !vm.auth.user.permissions.salesorders_can_always_create_billing) {
                alert('Total billed units already meets or exceeds units ordered. Please see your supervisor for assistance.');
                return false;
            }

            if (!vm.salesOrder.id) { return false; }

            vm.saving = true;
            vm.error = false;

            vm.salesOrder.createBilling(btype).then((billing_id) => {
                $state.transitionTo(
                    'billingEditPage',
                    { id: billing_id }
                );
                vm.saving = false;
            }, () => {
                vm.saving = false;
                vm.error = true;
            });

            return true;
        }

        function generatePDF() {
            const url = `${API_URL}salesorders/so/${vm.salesOrder.id}/generatepdf/`;

            if (vm.saving) { return false; }
            if (!vm.salesOrder.id) { return false; }

            vm.saving = true;
            vm.error = false;

            return $http.get(url).then((response) => {
                vm.saving = false;
                $scope.$broadcast('updateFiles', 'salesorder', vm.salesOrder.id);
                const node_id = response.data.payload.node_id;
                window.open(`/api/v1/files/download/${node_id}/`, '_blank');
            }, () => {
                vm.saving = false;
                vm.error = true;
            });
        }
    }
})();
