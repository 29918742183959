(function () {
    "use strict";

    angular
        .module('slate.salesforecast')
        .directive('slateSalesForecastCotab', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/salesforecast/salesforecast.edit.cotab.html',
            scope: {
                company: '=',
                forecast: '=',
                complete: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function ListController(
        $scope,
        $http,
        getLinkSrv,
        SlateAuth,
        SlateCoSalesForecasts
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.forecasts = new SlateCoSalesForecasts();
        
        vm.requestNewForecast = requestNewForecast;

        vm.start_date = new Date();
        vm.start_year = vm.start_date.getFullYear();
        vm.start_month = vm.start_date.getMonth() + 1;

        $scope.$watch('vm.company.id', function (old_val, new_val) {
            if (vm.company.id) {
                loadForecast();
            }
        });

        function loadForecast() {
            vm.forecasts.getList({company: vm.company.id, sales_mgr: vm.company.sales_mgr}).then(function() {
                vm.forecast = vm.forecasts.list[0];
            });
        }

        function requestNewForecast() {
            var apiUrl = API_URL + 'salesforecast/request-new/';
            
            vm.saving = true;

            $http.post(apiUrl, {company: vm.company.id, start: vm.start_year+'-'+vm.start_month}).then(function() {
                vm.saving = false;
                loadForecast();
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

    }

})();
