(function() {
    "use strict";

    angular
        .module('slate.discussion')
        .directive('slateDiscussionMini', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/discussion/discussion.mini.dir.html',
            scope: {
                parentType: '=',
                parentFk: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $interval, $state, $location, $q, getLinkSrv, SlateDiscussion, SlateDiscussionMsgs, SlateDiscussionMsg) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;

        vm.getLinkSrv = getLinkSrv;

        vm.loading = true;
        vm.message = "";

        vm.froalaOptions = {
            toolbarButtons: ["bold", "italic", "underline", 'fontSize', "|",
                "align", "formatOL", "formatUL", "|",
                "quote", "insertTable", "|", 'insertLink'
            ],
        };

        vm.discussion = new SlateDiscussion();
        modelPromise = vm.discussion.loadFromServer({parent_fk: vm.parentFk, parent_type: vm.parentType});

        vm.msgs = new SlateDiscussionMsgs();

        modelPromise.then(function() {
            vm.msgs.getList({discussion: vm.discussion.id}).then(function() {
                vm.loading = false;
            });
        });

    }


})();
