(function() {
    "use strict";

    angular
        .module('slate.library.logistics')
        .factory('SlateGoods', ModelList)
        .factory('SlateGood', ModelDetail);

    var apiUrl = API_URL + 'logistics/goods/';

    function ModelList($http, $q, SlateGood) {
        // Set to url relative to api root.

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.download = download;
        list.prototype.loadFromPayload = loadFromPayload;


        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then(function(response) {
                self.list = self.loadFromPayload(response.data.payload);
                self.totalItems = response.data.totalItems;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            var newlist = [];
            //set this for correct model
            angular.forEach(payload, function(item) {
                var itemModel = new SlateGood();

                itemModel.loadFromPayload(item);
                newlist.push(itemModel);
            });
            self.list = newlist;
            return newlist;
        }

        function download(filters) {

        }

    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };


        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },

            {name: 'name'},
            {name: 'slug', readonly: true},

            {name: 'shipment'},
            {name: 'polineitem'},
            {name: 'solineitem'},
            {name: 'bom', readonly: true},

            {name: 'client'},
            {name: 'client_info', readonly: true},
            {name: 'vendor'},
            {name: 'vendor_info', readonly: true},
            {name: 'sales_mgr'},
            {name: 'sales_mgr_info', readonly: true},
            {name: 'proj_mgr'},
            {name: 'proj_mgr_info', readonly: true},
            {name: 'project'},
            {name: 'project_info', readonly: true},
            {name: 'order'},
            {name: 'order_info', readonly: true},
            {name: 'purchaseorder'},
            {name: 'purchaseorder_info', readonly: true},
            {name: 'salesorder'},

            {name: 'ready_date', type:'dateOnly'},
            {name: 'origin_from'},
            {name: 'origin_from_info', readonly: true},
            {name: 'origin_warehouse'},
            {name: 'origin_warehouse_info', readonly: true},

            {name: 'po_name'},
            {name: 'item_num'},
            {name: 'component'},
            {name: 'component_info', readonly: true},
            {name: 'lot'},
            {name: 'production_date', type: 'dateOnly'},

            {name: 'unit'},
            {name: 'qty', def:0},
            {name: 'qty_per_carton', def:0},
            {name: 'weight_per_unit', type: 'float', def:0},
            {name: 'cbm'},

            {name: 'length', type: 'float', def:0},
            {name: 'width', type: 'float', def:0},
            {name: 'height', type: 'float', def:0},

            {name: 'estimated_duty', type: 'float'},
            {name: 'actual_duty', type: 'float'},
            {name: 'hts_code'},
            {name: 'bill_to_client'},
            {name: 'tariff', type: 'float'},

            {name: 'quote_approved', readonly:true},
            {name: 'quote_approved_by', readonly:true},
            {name: 'quote_approved_by_info', readonly:true},
            {name: 'quote_approved_on', type: 'date', readonly:true},

            {name: 'schedule_approved', readonly:true},
            {name: 'schedule_approved_by', readonly:true},
            {name: 'schedule_approved_by_info', readonly:true},
            {name: 'schedule_approved_on', type: 'date', readonly:true},

            {name: 'notes'},

            {name: 'total_followup_shipped', readonly: true },
            {name: 'parent_good'},
            {name: 'parent_good_info', readonly: true },

            {name: 'updated', readonly: true, type: 'date'},
            {name: 'created', readonly: true, type: 'date'},
            {name: 'created_by', readonly: true},
            {name: 'created_by_info', readonly: true},

        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.getModelData = getModelData;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.copyFrom = copyFrom;
        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function copyFrom(payload) {
            /* jshint validthis: true */
            var self = this;

            angular.forEach(fields, function(field) {
                if(field.name == 'id') { return false; }
                if(field.name == 'shipment') { return false; }
                self[field.name] = _.cloneDeep(payload[field.name]);
            });

            return self;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                self[field.name] = _.cloneDeep(payload[field.name]);

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if (field.type == 'date' && payload[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if (field.type == 'dateOnly' && self[field.name] && typeof self[field.name] == 'string') {
                    var parts = self[field.name].split('-');
                    self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
                }

                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) { console.log('didnt parse json', e); }
                }

            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];

                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                deferred.resolve(self);

            });

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            var self = this;
            var data = {};

            angular.forEach(fields, function(field) {
                if ((!field.readonly || field.name == 'id') && typeof(self[field.name]) != 'undefined') {
                    data[field.name] = self[field.name];
                    if(field.type == "date") {
                        data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth()+1) + '-' + self[field.name].getUTCDate();
                    }
                    if(field.type == "dateOnly" && self[field.name]) {
                        data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth()+1) + '-' + self[field.name].getDate();
                    }
                    if(field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if(field.type == 'float') {
                        data[field.name] = parseFloat(self[field.name]);
                        if(isNaN(data[field.name])) { data[field.name] = null; }
                    }
                    if(field.trim && data[field.name]) {
                        data[field.name] = data[field.name].substr(0,field.trim);
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            data = getModelData();

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                //return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
