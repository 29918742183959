(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportTurnkey', modelList)
        .service('slateReportTurnkeyLedger', editDialog);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.turnkey.dir.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $q, $http, $location, $httpParamSerializer, slateReportTurnkeyLedger) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'reports/turnkey/';

        var modelPromise;
        var canceller = null;

        vm.getDownloadUrl = getDownloadUrl;
        vm.updateFromFilter = updateFromFilter;
        vm.sortBy = sortBy;
        vm.viewLedger = viewLedger;

        vm.data = [];
        vm.ledgers = {};
        vm.filters = {
            company_id: $location.search().company_id,
            q: $location.search().q,
            min_qty: $location.search().min_qty || 2,
        };
        vm.loading = true;
        vm.sortOrder = 'date';
        vm.reverse = true;

        vm.date_fields = ['orderDate'];
        vm.date_fields.forEach(function(dateField) {
            var parts;
            if(vm.filters[dateField]) {
                parts = vm.filters[dateField].split('-');
                vm[dateField] = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
            }
            $scope.$watch('vm.'+ dateField, function () {
                if (vm[dateField]) {
                    vm.filters[dateField] = vm[dateField].getFullYear() + '-' + (vm[dateField].getMonth()+1) + '-' + vm[dateField].getDate();
                } else {
                    vm.filters[dateField] = null;
                }
            });
        });

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        function updateFromFilter() {
            vm.loading = true;

            if (canceller) { canceller.resolve(); }
            canceller = $q.defer();

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            if(!vm.filters.company_id) {
                vm.data = [];
                vm.ledgers = {};
                canceller = null;
                vm.loading = false;
                return false;
            }

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
                timeout: canceller.promise

            });

            modelPromise.then(function(resp) {
                vm.data = resp.data.payload;
                vm.ledgers = resp.data.ledgers;
                vm.loading = false;
                canceller = null;
            });
        }

        function sortBy(field) {
            vm.reverse = vm.sortOrder == field ? !vm.reverse : true;
            vm.sortOrder = field;
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }

        function viewLedger(date, component_id) {
            if(!vm.ledgers[component_id]) { return false; }

            slateReportTurnkeyLedger.edit(date, vm.ledgers[component_id]);
        }
    }

    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateSOLineItem) {
        var templateUrl= '/partials/reports/reports.report.turnkey.sidebar.html';

        var srv = {
            edit: editObj
        };
        return srv;

        function editObj(date, ledger) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    date: function () {
                        return date;
                    },
                    ledger: function () {
                        return ledger;
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($uibModalInstance, date, ledger) {
        /* jshint validthis: true */
        var vm = this;

        vm.close = close;
        vm.ledger = ledger;
        vm.date = date;

        function close() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }


    }


})();