(function () {
    "use strict";

    angular
        .module('slate.salesforecast')
        .directive('slateSalesForecastBulk', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/salesforecast/salesforecast.bulk.page.html',
            scope: {
                
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function ListController(
        $scope,
        $http,
        getLinkSrv,
        SlateAuth,
        SlateContacts,
        SlateCompanies,
        SlateCoSalesForecasts
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.sales_staff = new SlateContacts();
        vm.companies = new SlateCompanies();
        vm.forecasts = new SlateCoSalesForecasts();

        vm.save = save;
        vm.approve = approve;
        
        vm.requestNewSalesMgrForecast = requestNewSalesMgrForecast;
        vm.requestNewCOForecast = requestNewCOForecast;
        vm.requestNewAllForecast = requestNewAllForecast;

        vm.company = '';
        vm.sales_mgr = '';

        vm.start_date = new Date();
        vm.start_year = vm.start_date.getFullYear();
        vm.start_month = vm.start_date.getMonth() + 1;
        vm.months = [];

        loadForecast();

        $scope.$watch('vm.start_month', function() { vm.months = getDates(); });
        $scope.$watch('vm.start_year', function() { vm.months = getDates(); });
        
        $scope.$watch('vm.company', function (old_val, new_val) {
            if (vm.company) {loadForecast();}
        });

        $scope.$watch('vm.sales_mgr', function (new_val, old_val) {
            loadForecast();
            vm.company = '';
            loadCompanies();
        });

        function loadCompanies() {
            if(!vm.sales_mgr) { 
                vm.companies.list = [];
                return false; 
            }
            vm.companies.getList({sales_mgr: vm.sales_mgr});
        }

        function loadForecast() {
            // if(!vm.sales_mgr || !vm.company) { return false; }
            var filters = {latest: true};
            if(vm.company) { filters.company = vm.company; }
            if(vm.sales_mgr) { filters.sales_mgr = vm.sales_mgr; }
            vm.forecasts.getList(filters).then(function() {
                vm.forecasts.sortList();
            });
        }

        function save() {
            vm.saving = true;

            vm.forecast.saveModel().then(function() {
                vm.saving = false;
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });

        }

        function approve() {
            vm.saving = true;
            vm.forecast.approved = true;
            vm.forecast.saveModel().then(function() {
                vm.saving = false;
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });

        }

        function requestNewCOForecast() {
            var apiUrl = API_URL + 'salesforecast/request-new/';
            vm.saving = true;

            $http.post(apiUrl, {company: vm.company, start: vm.start_year+'-'+vm.start_month}).then(function() {
                vm.saving = false;
                loadForecast();
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function requestNewSalesMgrForecast() {
            var apiUrl = API_URL + 'salesforecast/request-new/';
            vm.saving = true;

            $http.post(apiUrl, {sales_mgr: vm.sales_mgr, start: vm.start_year+'-'+vm.start_month}).then(function() {
                vm.saving = false;
                loadForecast();
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function requestNewAllForecast() {
            var apiUrl = API_URL + 'salesforecast/request-new/';
            
            if(!window.confirm('Are you sure you want to setup new budgets for all sales staff for all their accounts?')) { return false; }
            
            vm.saving = true;

            $http.post(apiUrl, {all: true, start: vm.start_year+'-'+vm.start_month}).then(function() {
                vm.saving = false;
                loadForecast();
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function getDates() {
            var running_year = vm.start_year;
            var running_month = vm.start_month;
            var months = [];

            while(months.length < 12) {
                months.push(running_year + '-' + running_month.toString().padStart(2, '0'));
                running_month += 1;
                if(running_month > 12) { running_month = 1; running_year += 1;}
            }

            return months;
        }

        function editForecast(forecast) {
            
        }

    }

})();
