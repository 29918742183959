(function () {
    'use strict';

    angular
        .module('slate.auth')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
            .state('login', {
                url: '/login/',
                templateUrl: '/partials/auth/login.page.html',
                current_app: 'login',
                noAuth: true
            })
            .state('loginOkta', {
                url: '/login/okta/',
                templateUrl: '/partials/auth/login.okta.page.html',
                current_app: 'login',
                noAuth: true
            })
            .state('changePassword', {
                url: '/change-password/',
                template: "<div class='auth-page changepassword-page'><slate-change-password-box class='auth-box'></slate-change-password-box></div>",
                current_app: 'login'
            });
    }
})();
