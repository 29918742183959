(function() {
    "use strict";

    angular
        .module('slate.library.presentations')
        .factory('SlateSlides', ModelList)
        .factory('SlateSlide', ModelDetail);

    var apiUrl = API_URL + 'presentations/slides/';

    function ModelList($http, $q, SlateSlide) {
        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.saveAll = saveAll;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if(typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {params: self.filters});

            promise.then(function (response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function (item) {
                    var itemModel = new SlateSlide();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveAll(defaults) {
            /* jshint validthis: true */
            var self = this;
            var promises = [];
            var i = 0;
            angular.forEach(self.list, function(model) {
                angular.forEach(defaults, function(value, field) {
                    model[field] = value;
                });
                model.order = i;
                promises.push(model.saveModel());
                i = i + 1;
            });

            return $q.all(promises);
        }

    }

    function ModelDetail ($http, $q, SlatePresPhoto) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        var fields = [
            {name: 'id', def: null, readonly: true },
            {name: 'presentation', def: null },
            {name: 'order', def: 100 },
            {name: 'photo', def: null },

            {name: 'updated', def: null, readonly: true },
            {name: 'created', def: null, readonly: true },
            {name: 'createdby', def: null, readonly: true },
        ];

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });
            self.actPhoto = null;

            deferred.resolve(self);

            return deferred.promise;
        }



        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if(typeof payload !== "object") {
                console.log('payload must be an object', payload);
                return self;
            }

            angular.forEach(fields, function(field) {
                self[field.name] = payload[field.name];
            });

            if(payload.photopayload) {

                self.actPhoto = new SlatePresPhoto();
                self.actPhoto.loadFromPayload(payload.photopayload);
            }

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if(id) { modelUrl = apiUrl + id + '/'; }

            if(!self.id && !id) {
                console.log('Tried to load from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function (response) {
                var promises = [];

                return self.loadFromPayload(response.data).then(function() {
                    self.permissions = response.data.permissions;

                    return $q.when(promises).then(function() {

                        deferred.resolve(self);
                    });

                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if(self.id) { modelUrl = apiUrl + self.id + '/'; }
            if(!self.photo && self.actPhoto) {
                self.photo = self.actPhoto.id;
            }

            angular.forEach(fields, function(field) {
                if(!field.readonly) {
                    data[field.name] = self[field.name];
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function (response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if(self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function (response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
