(function () {
    "use strict";

    angular
        .module('slate.helpdesk')
        .directive('slateInstockList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/projects/instock/instock.list.dir.html',
            scope: {
                modelFilters: '=?filters',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $q, SlateAuth, SlateProject) {
        /* jshint validthis: true */
        var vm = this;

        var apiUrl = API_URL + 'projects/project/';
        vm.auth = SlateAuth;

        vm.getStock = getStock;

        vm.filters = {
            date: new Date(),
        };
        vm.component = new SlateProject();
        vm.stock = [];

        $scope.$watch('vm.modelFilters.component', function () {
            if (!vm.modelFilters.component) { return false; }
            // vm.component.loadFromServer(vm.modelFilters.component);
            getStock();
        });
        $scope.$watch('vm.filters.date', function () {
            if (!vm.modelFilters.component) { return false; }
            getStock();
        });

        function getStock() {
            var modelUrl = apiUrl + vm.modelFilters.component + '/stock/';
            var datestring;
            var params = {};
            vm.loading = true;

            if (vm.canceller) { vm.canceller.resolve(); }
            vm.canceller = $q.defer();

            if (!vm.modelFilters.component) {
                vm.loading = false;
                vm.stock = [];
                return false;
            }

            if(vm.filters.date) {
                datestring = vm.filters.date.getFullYear() + '-' + (vm.filters.date.getMonth() + 1) + '-' + vm.filters.date.getDate();
                params.date = datestring;
            }
            return $http.get(modelUrl, {
                timeout: vm.canceller.promise,
                params: params
            }).then(function (response) {
                vm.canceller = null;
                vm.loading = false;
                console.log('got stock', response.data.payload);
                vm.stock = response.data.payload;
            }, function () {
                vm.canceller = null;
                vm.loading = false;
                vm.stock = [];
            });
        }


    }


})();
