(function() {
    "use strict";

    angular
        .module('slate.controlpanel')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
            .state('controlPanels', {
                url: '/controlpanels/',
                //templateUrl: "/partials/ro/presentations.page.html",
                template: "<div><slate-control-panels-menu></slate-control-panels-menu></div><div ui-view></div>",
                current_app: 'controlpanels'
            })
            .state('controlPanels.materials', {
                url: 'materials/',
                template: "<slate-control-panel-materials></slate-control-panel-materials>",
                current_app: 'controlpanels',
                controller: function() {
                    /* jshint validthis: true */
                    var vm = this;
                },
                controllerAs: 'vm',
            })
        ;
    }
})();
