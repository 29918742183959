(function() {
    "use strict";

    angular
        .module('slate.salesforecast')
        .service('SlateCoForecastEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateCompany) {
        var templateUrl = '/partials/salesforecast/salesforecast.edit.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj: function() {
                        var co;
                        if (!obj.id) {
                            return obj;
                        } else {
                            co = new SlateCompany();
                            return co.loadFromServer(obj.id);
                        }
                    },
                }
            });

            return modalInstance.result.then(function(results) {

                return results;
            }, function() {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($q, $uibModalInstance, obj, SlateAuth, SlateSettings, SlateCompanies) {
        /* jshint validthis: true */
        var vm = this;

        vm.auth = SlateAuth;
        vm.obj = obj;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        
        function saveObject() {
            var promise;
            var deferred = $q.defer();
            
            vm.saving = true;
            if(!vm.obj.country){
                vm.obj.country = vm.obj.locations.list[0].country;
            }
            promise = vm.obj.saveModel().then(function() {
                if(vm.forecast && vm.forecast.sales_mgr == vm.auth.user.contact_id && !vm.forecast.approved) {
                    vm.forecast.saveModel().then(function() {
                        deferred.resolve(vm.obj);
                    });
                } else {
                    deferred.resolve(vm.obj);
                }
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
            
            deferred.promise.then(function() {
                vm.saving = false;
                $uibModalInstance.close(vm.obj);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }


    }



})();
