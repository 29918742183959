(function () {
    "use strict";

    angular
        .module('slate.bom')
        .service('SlateBOMItemEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateBOM) {
        var templateUrl = '/partials/bom/bomitem.edit.sidebar.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(bom, init_data) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj: function () {
                        var newbom = new SlateBOM(init_data);
                        if(!bom) {
                            return newbom;
                        }
                        if(typeof bom == 'string') {
                            return newbom.loadFromServer(bom);
                        }
                        return bom;
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance,  obj, SlateSettings, SlateBOMs, SlateProject) {
        /* jshint validthis: true */
        var vm = this;

        vm.bomitem = obj;
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.isComplete = isComplete;

        vm.boms = new SlateBOMs();


        vm.detailsIsComplete = detailsIsComplete;

        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            //zIndex: 2501,
        };

        vm.compVendors = [];

        vm.units = [
            'ea',
            'box',
            'bottle',
            'g',
            'kg',
            'oz',
            'ml',
            'liter',

        ];

        SlateSettings.getConfig('material_categories').then(function(config) {
            vm.categories = config;
        });
        SlateSettings.getConfig('materials').then(function(config) {
            vm.material_list = config;
            getMatList();
        });
        vm.materials = [];

        $scope.$watch('vm.bomitem.category', function() {
            getMatList();
        });

        function getMatList() {
            var newlist = [];
            if(!vm.material_list || ! vm.material_list.value) { return []; }
            angular.forEach(vm.material_list.value, function(item) {
                if(item.category == vm.bomitem.category) {
                    newlist.push(item);
                }
            });
            vm.materials = newlist;
            return newlist;
        }


        function saveObject(skip) {
            if (vm.saving === true) {
                return false;
            }
            vm.saving = true;

            vm.bomitem.saveModel().then(function () {
                vm.saving = false;
                $uibModalInstance.close(vm.bomitem);
            }, function () {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }


        function detailsIsComplete() {
            return true;
        }

        function isComplete() {
            if(!vm.bomitem.component) { return false; }
            if(vm.bomitem.qty_required <= 0) { return false; }
            if(!vm.bomitem.supplier) { return false; }
            return true;
        }

        function projectSync() {
            var project = new SlateProject();
            project.loadFromServer(vm.bomitem.component).then(function () {

                if (!vm.bomitem.internal_item_number) {
                    if(project.internal_item_number) {
                        vm.bomitem.internal_item_number = project.internal_item_number;
                    } else {
                        vm.bomitem.internal_item_number = project.composite_num;
                    }
                }
                if (!vm.bomitem.vendor_item_number) { vm.bomitem.vendor_item_number = project.vendor_item_number; }
                if (!vm.bomitem.client_item_number) { vm.bomitem.client_item_number = project.client_item_number; }
                if (!vm.bomitem.notes) { vm.bomitem.notes = project.brief; }
                if (!vm.bomitem.item_description) { vm.bomitem.item_description = project.name; }
                if (!vm.bomitem.supplier) { vm.bomitem.supplier = project.supplier; }
                if (!vm.bomitem.vendor) { vm.bomitem.vendor = project.vendor; }
                if (!vm.bomitem.unit_name) { vm.bomitem.unit_name = project.unit_name; }
                if (!vm.bomitem.scrap_factor) { vm.bomitem.scrap_factor = project.scrap_factor; }


            });

        }
        $scope.$watch('vm.bomitem.component', function () {
            if (vm.bomitem.component) {
                projectSync();
            }

            if(vm.bomitem.component_info) {
                if (!vm.bomitem.component_info.finished_good) {
                    vm.boms.list = [];
                } else {
                    vm.boms.getList({ finished_good: vm.bomitem.component }).then(function () {
                        // this forces a valid bom
                        if(vm.boms.list.length > 0 && !vm.boms.list.some(function(bom) { return bom.id == vm.bomitem.component_bom; })) {
                            vm.bomitem.component_bom = vm.boms.list[0].id;
                        }

                        if(!vm.bomitem.component_bom && vm.boms.list.length > 0) {
                            vm.bomitem.component_bom = vm.boms.list[0].id;
                        }
                    });
                }
            }

        });
    }

})();
