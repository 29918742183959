(function () {
    'use strict';

    angular
        .module('slate.billings')
        .service('SlateBillingLineItemEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateAuth, SlateSettings, SlateBillingLineItem) {
        const templateUrl = '/partials/billings/billing.lineitem.edit.sidebar.html';

        const srv = {
            edit: editObj
        };


        return srv;


        function editObj(item, options) {
            const modalInstance = $uibModal.open({
                templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj() {
                        const newitem = new SlateBillingLineItem();
                        if (!item) {
                            return newitem;
                        }
                        if (typeof item === 'string') {
                            return newitem.loadFromServer(item);
                        }
                        return newitem.loadFromPayload(item);
                    },
                    billing() {
                        if (typeof options === 'object' && options.billing) {
                            return options.billing;
                        }
                        return null;
                    },
                    salesOrder() {
                        if (typeof options === 'object' && options.salesOrder) {
                            return options.salesOrder;
                        }
                        return null;
                    }
                }
            });

            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, obj, billing, SlateAuth, salesOrder, SlateSettings, SlateCompany, SlateProject, SlateLocations, SlateBOMs, SlateBOM) {
        /* jshint validthis: true */
        const vm = this;

        vm.auth = SlateAuth;

        vm.item = obj;
        vm.billing = billing;
        vm.salesOrder = salesOrder;
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.isComplete = isComplete;
        vm.get_non_inv_items = get_non_inv_items;

        vm.froalaOptions = {
            toolbarButtons: ['bold',
                'italic',
                'underline',
                '|',
                'align',
                'formatOL',
                'formatUL']
            // zIndex: 2501,
        };

        vm.units = [
            'ea',
            'box',
            'bottle',
            'g',
            'kg',
            'oz',
            'liter'
        ];


        function saveObject(skip) {
            $uibModalInstance.close(vm.item);
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }


        function isComplete() {
            if (!vm.item.so_lineitem) { return false; }
            if (!vm.item.qty) { return false; }

            return true;
        }

        function get_non_inv_items() {
            if (vm.billing.billing_type !== 'Final') { return vm.salesOrder.line_items; }
            if (vm.auth.perms.billings_can_override) { return vm.salesOrder.line_items; }
            return vm.salesOrder.line_items.filter((item) => {
                if (item.is_non_inventory) { return true; }
                if (item.id === vm.item.so_lineitem) { return true; }
                return false;
            });
        }

        $scope.$watch('vm.item.so_lineitem', (new_val, old_val) => {
            if (old_val === new_val) { return false; }
            const new_so_item = vm.salesOrder.line_items.find((item) => item.id === new_val);
            vm.item.finished_good = new_so_item.finished_good;
            vm.item.finished_good_info = new_so_item.finished_good_info;
            vm.item.bom = new_so_item.bom;
            vm.item.qty = new_so_item.qty;
            vm.item.unit_name = new_so_item.unit_name;
            vm.item.sellprice = new_so_item.sellprice;
            if (!vm.item.sellprice) { vm.item.sellprice = 0; }

            return null;
        });
    }
})();
