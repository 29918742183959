(function() {
    "use strict";

    angular
        .module('slate.rfqs')
        .directive('slateRfqsVendorsList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/rfqs/rfqs.vendorslist.dir.html',
            scope: {
                vendors: '=',
                caps: '=',
                readonly: '<?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $q, SlateRFQs, SlateRFQ, SlateCompany, SlateCompanyEdit) {
        /* jshint validthis: true */
        var vm = this;
        if (!vm.vendors) { vm.vendors = []; }
        vm.addingVendor = false;
        vm.vendorToAdd = "";
        vm.excludes = [];

        vm.addVendor = addVendor;
        vm.addNewCo = addNewCo;

        $scope.$watchCollection('vm.vendors', function() {
            var splices = [];
            angular.forEach(vm.vendors, function(vendor, idx) {
                if(!vendor || vendor.length < 1) { splices.push(idx); }
            });
            for (var i = splices.length - 1; i >= 0; --i) {
                vm.vendors.splice(splices[i], 1);
            }
            vm.excludes = angular.copy(vm.vendors);
        });

        $scope.$watch('vm.vendorToAdd', function() {
            if(vm.vendorToAdd) {
                vm.addingVendor = false;
                vm.vendors.push(vm.vendorToAdd);
                vm.vendorToAdd = "";
                vm.excludes = angular.copy(vm.vendors);
            } 
        });

        function addVendor() {
            vm.vendorToAdd = "";
            vm.addingVendor = true;
        }

        function addNewCo($event) {
            var newCompany = new SlateCompany();
            var promise;
            newCompany.contactmethods.addBlank({method: 'phone', is_primary: true});
            newCompany.contactmethods.addBlank({method: 'url', is_primary: true});
            promise = SlateCompanyEdit.edit(newCompany);
            promise.then(function() {
                vm.vendors.push(newCompany.id);
            }, function() {

            });
            return promise;
        }

    }


})();
