(function() {
    "use strict";

    angular
        .module('slate.schedule')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            // old schduling page
            .state('schedule', {
                url: '/schedule/',
                template: '<slate-schedule></slate-schedule>',
                current_app: 'schedule',
                slateTitle: 'Scheduling',
            })


            .state('scheduleGrid', {
                url: '/scheduling/',
                template: "<div class='content-pane list-page'><slate-schedule-grid></slate-schedule-grid></div>",
                current_app: 'schedule',
                slateTitle: 'Scheduing Grid',
            })

            .state('weeklySchedule', {
                url: '/weekly-schedule/',
                template: "<div class='content-pane list-page'><slate-weekly-schedule></slate-weekly-schedule></div>",
                current_app: 'schedule',
                slateTitle: 'Weekly Schedule',
            })


        ;
    }
})();
