(function () {
    "use strict";

    angular
        .module('slate.auditlog')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('auditLogListPage', {
                url: '/auditlog/:parent_model/:id/',
                template: "<div class=\"content-pane\"><slate-audit-log></slate-audit-log></div>",
                current_app: 'auditlog',
                slateTitle: 'Audit Log',
            });
    }
})();