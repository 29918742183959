(function () {
    'use strict';

    angular
        .module('slate.filebrowser')
        .service('SlateFileVerAnnotation', editDialog);


    function editDialog($rootScope, $q, $uibModal) {
        const templateUrl = '/partials/filebrowser/filebrowser.annote.dialog.html';

        const srv = {
            edit: editObj
        };
        return srv;


        function editObj(node, ver) {
            const modalInstance = $uibModal.open({
                templateUrl,
                size: 'lg',
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',

                resolve: {
                    node() {
                        return node;
                    },
                    ver() {
                        return ver;
                    }
                }
            });

            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, node, ver, SlateFileNode, SlateFileVerList) {
        /* jshint validthis: true */
        const vm = this;

        vm.node = node;
        vm.ver = ver;

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        if (!ver) {
            vm.verList = new SlateFileVerList();
            vm.verList.getList(node.id).then(() => {
                angular.forEach(vm.verList.list, (item) => {
                    if (item.version === vm.node.version) { vm.ver = item; }
                });
            });
        }

        const direct_view_mimetypes = [
            'application/pdf'
            // 'application/vnd.ms-excel',
            // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];
        function getDownloadUrl(filenode, filever) {
            let url = `${API_URL}files/download/${filenode.id}/`;
            if (filever) {
                url = `${url}ver/${filever}/`;
            }
            if ((filenode.has_preview || (vm.ver && vm.ver.has_preview)) && !direct_view_mimetypes.includes(vm.node.mimetype)) {
                url += '?size=preview';
            }
            return url;
        }

        $uibModalInstance.rendered.then(() => {
            // const baseUrl = `${window.location.protocol}//${window.location.host}/pdfviewer/`;
            const baseUrl = '/webviewer/';

            let url = getDownloadUrl(vm.node);
            if (vm.ver) {
                url = getDownloadUrl(vm.node, vm.ver.version);
            }
            // console.log('Using license key: ', PDF_LICENSE_KEY);
            WebViewer.WebComponent({
                path: baseUrl, // path to the Apryse 'lib' folder on your server
                licenseKey: 'Jansy Packaging LLC ( jansypkg.com):PWS:Jansy Packaging LLC::B+2:1133D332DD61815C33A681734AA1DD04DD5EB8AFF5EFC054A69670181CA1E89388A6765726BD'
                // initialDoc: url,
                // initialDoc: '/path/to/my/file.pdf', // You can also use documents on your server
            }, document.getElementById('documentViewer'))
                .then((instance) => {
                    const { Core } = instance;
                    const { documentViewer, annotationManager } = Core;
                    vm.annotationManager = annotationManager;
                    // call methods from instance, documentViewer and annotationManager as needed

                    // you can also access major namespaces from the instance as follows:
                    // const Tools = instance.Core.Tools;
                    // const Annotations = instance.Core.Annotations;


                    instance.UI.setHeaderItems((header) => {
                        const items = header.getItems().slice(2);
                        items.splice(8, 1, { type: 'spacer' });
                        items.splice(9, 0, {
                            type: 'actionButton',
                            img: 'icon-tool-pen-line',
                            dataElement: 'annotationsButton',
                            onClick: () => {
                                if (instance.UI.isElementDisabled('toolsHeader')) {
                                    instance.UI.enableElements(['toolsHeader']);
                                } else {
                                    instance.UI.disableElements(['toolsHeader']);
                                }
                            }
                        });
                        header.update(items);
                    });

                    // hide the ribbons and second header
                    // instance.UI.disableElements(['ribbons']);
                    instance.UI.disableElements(['toolsHeader']);
                    let filename = vm.node.filename;
                    if (url.endsWith('preview')) {
                        filename = `${filename}.pdf`;
                    }
                    instance.UI.loadDocument(url, { filename });

                    documentViewer.addEventListener('documentLoaded', () => {
                        annotationManager.importAnnotations(vm.ver.annotations);
                    });
                });
        });

        function saveObject() {
            vm.saving = true;

            if (vm.annotationManager) {
                vm.annotationManager.exportAnnotations({ links: false, widgets: false }).then((annotations) => {
                    vm.ver.annotations = annotations;
                    return vm.ver.saveModel().then(() => {
                        vm.saving = false;
                        $uibModalInstance.close(vm.savedFiles);
                    });
                });
            } else {
                return vm.ver.saveModel().then(() => {
                    vm.saving = false;
                    $uibModalInstance.close(vm.savedFiles);
                });
            }
            return true;
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
