(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .service('SlateSQLineItemOptionEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateSQLineItem) {
        var templateUrl = '/partials/salesquotes/sq.lineitems/sq.lineitem.option.sidebar.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(item, options) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj: function () {
                        return item;
                    },
                    lineItem: function() {
                        if(typeof options === 'object' && options.lineItem) {
                            return options.lineItem;
                        }
                        return null;
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance,  obj, lineItem, SlateSettings, SlateCompany, SlateProject, SlateLocations, SlateBOMs, SlateBOM, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;

        vm.option = obj;
        vm.item = lineItem;
        vm.saveObject = saveObject;
        vm.updateMargin = updateMargin;
        vm.updatePrice = updatePrice;
        vm.cancel = cancel;
        vm.auth = SlateAuth;

        vm.isComplete = isComplete;

        vm.orig_name = vm.option.name;

        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            //zIndex: 2501,
        };


        function saveObject(skip) {
            lineItem.options[vm.option.name] = vm.option.data;
            if(vm.orig_name && vm.orig_name != vm.option.name) {
                delete(lineItem.options[vm.orig_name]);
            }
            $uibModalInstance.close(vm.item);
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }

        function isComplete() {
            if(!vm.option.name) { return false; }

            return true;
        }

        $scope.$watch('vm.option.data.amortize', function() {
            if (vm.option.data.amortize) {
                angular.forEach(vm.option.data, function(value, key) {
                    if (key == 'amortize') { return false; }
                    vm.option.data[key].margin = 0;
                    vm.option.data[key].price = 0;
                    vm.option.data[key].lastUpdate = '';
                });
            }
        });

        function updatePrice(qtyBreak) {
            var cost, margin;
            var option = vm.option.data[qtyBreak];
            
            if(typeof option.cost == 'undefined') { option.cost = 0; }
            if(option.cost == '') { option.cost = 0; }
            if(!option.margin) { option.margin = 0; }
            
            cost = Big(option.cost);
            margin = Big(option.margin).div(100);
            option.lastUpdate = 'margin';
            option.price = cost.div(Big(1).minus(margin)).toFixed(5);
            
        }
        
        function updateMargin(qtyBreak) {
            var cost, sell;
            var option = vm.option.data[qtyBreak];
            if(option.cost == '') { option.cost = 0; }

            cost = Big(option.cost);
            sell = Big(option.price);
            option.lastUpdate = 'price';
            option.margin = sell.minus(cost).div(sell).times(100).toFixed(2);
        }

    }
})();
