(function () {
    "use strict";

    angular
        .module('slate.qima')
        .service('SlateProductRefEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateBooking) {
        var templateUrl = '/partials/qima/booking.productrefs.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj: function () {
                        if (!obj.id) {
                            return new SlateBooking();
                        }
                        var booking = new SlateBooking();
                        return booking.loadFromServer(obj.id);
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $http, $q, $uibModalInstance, SlateAuth, SlateInspections, QIMAProduct, QIMAProductReference, obj) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;
        vm.auth = SlateAuth;

        vm.saving = false;
        vm.errorSaving = false;
        vm.toggleItem = toggleItem;
        vm.getLineItems = getLineItems;
        vm.isComplete = isComplete;
        vm.saveObject = saveObject;
        vm.cancel = cancel;


        vm.inspectionFields = [
            'jansy_po',
            'description',
            'status',
            'qc_date',
        ];

        vm.inspections = new SlateInspections();

        function getLineItems(inspection) {
            // if (inspection.line_items) { return; }

            // var lineItems = new SlateInspectionLineItems();
            // inspection.loadingItems = true;
            inspection.line_items = inspection.line_items.map(function (item) {
                var product = new QIMAProduct();
                product.name = item.po_lineitem_info.component_info.composite_num + '/' + item.po_lineitem_info.component_info.name;
                product.name = product.name.slice(0, 74);
                product.inspection_lineitem = item.id;
                product.inspection_lineitem_info = item;

                var ref = new QIMAProductReference();
                ref.po_number = inspection.purchase_order_info.jansy_po;
                ref.sku_code = item.po_lineitem_info.component_info.composite_num;
                ref.name = item.po_lineitem_info.component_info.composite_num + '/' + item.po_lineitem_info.component_info.name;
                ref.qty = item.qty;

                product.refs = [ref];
                return product;
            });
            // inspection.line_items = productRefs;
            // inspection.loadingItems = false;
            // lineItems.getList({ inspection: inspection.id }).then(function () {
            // }, function () {

            // });
        }

        function getInspections() {
            vm.loadingInspections = true;
            vm.inspections.getList({ order_by: 'qc_date', unbooked: true, vendor: vm.obj.vendor }).then(function () {
                vm.loadingInspections = false;
            }, function () {
                vm.loadingInspections = false;
            });
        }
        getInspections();


        function isComplete() {
        }

        function saveObject() {
            $uibModalInstance.close(vm.obj.products);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function toggleItem(item) {
            var products = vm.obj.products;
            if (item.selected) {
                products.push(item);
            } else {
                var index = products.indexOf(item);
                products.splice(index, 1);
            }
        }

    }

})();