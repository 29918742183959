(function () {
    "use strict";

    angular
        .module('slate.bom')
        .directive('slateBomSubList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/bom/bom.sublist.dir.html',
            scope: {
                bomId: '=?',
                selectedBomItems: '=?',
                qty: '=?',
                salesOrder: '=?',
                bomItemUnitCosts: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ListController($scope, $state, $location, $window, $q, SlateAuth, SlateDeleteObjSrv, getLinkSrv, SlateProject, SlateBOM, SlateBOMItems, SlateBOMEditSrv, SlatePriceTier, SlateBomItemPriceTierEditSrv, SlateItemStockDialog) {
        /* jshint validthis: true */
        var vm = this;

        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.bom = new SlateBOM();
        vm.bomitems = new SlateBOMItems();

        vm.getItemTotal = getItemTotal;

        vm.toggleItem = toggleItem;
        vm.selectItem = selectItem;
        vm.getUnitCostTotal = getUnitCostTotal;
        vm.selectPriceTier = selectPriceTier;
        vm.stock = stockDialog;

        if(!vm.qty) { vm.qty=0; }
        if(typeof vm.selectedBomItems == 'undefined'){ vm.selectedBomItems = {}; }

        $scope.$watch('vm.bom', function (old_val, new_val) {
            if (vm.bom) {
                vm.bom.loadFromServer(vm.bomId);
                vm.bomitems.getList({bom: vm.bomId}).then(function() {
                    updateTotals();
                    vm.loading = false;
                });
            }
        });

        $scope.$watch('vm.qty', function (old_val, new_val) {
            if(vm.bomitems.length) {
                updateTotals();
            }
        });

        function updateTotals() {
            vm.bomitems.list.forEach(function(item) {
                item.total_req = getItemTotal(item.qty_required, item.scrap_factor, item.unit_name);
            });
        }

        function toggleItem(item) {
            item.showBom = !item.showBom;
            if(item.showBom) { item.selected = false; }
        }


        function selectItem(item) {
            if(item.supplier == 'client' || item.supplier == 'vendor') { return; }

            item.selected = !item.selected;

            if(!item.selected && item.id in vm.selectedBomItems) {
                delete vm.selectedBomItems[item.id];
            }

            if(item.selected && !(item.id in vm.selectedBomItems)) {
                vm.selectedBomItems[item.id] = item;
            }
        }

        function getItemTotal(qty_required, scrap_factor, unit_name) {
            var qty = new Big(vm.qty);
            var req;
            var scrap;

            if(!qty_required) { qty_required = 0; }
            if(!scrap_factor) { scrap_factor = 0; }

            req = new Big(qty_required);
            scrap = new Big(scrap_factor);
            scrap = scrap.div(100);

            qty = qty.times(req);
            qty = qty.plus(qty.times(scrap));

            if (unit_name == 'ea') {
                qty = qty.round(0,3);
            }
            return qty.toString();
        }

        function getUnitCostTotal() {
            var total = Big(0.0);
            if(!Array.isArray(vm.bomitems.list)) { return 0.0; }
            vm.bomitems.list.forEach(function(item) {
                var qty_req = Big(item.qty_required);
                var scrap = Big(item.scrap_factor).div(100);
                var scrap_count = scrap.times(qty_req);
                qty_req = qty_req.add(scrap_count);

                total = total.add(Big(vm.bomItemUnitCosts[item.id]).times(qty_req));
            });
            return total.toFixed(5);
        }

        function selectPriceTier(bomitem) {
            SlateBomItemPriceTierEditSrv.edit(bomitem, vm.salesOrder.bom_item_price_tiers).then(function(result) {
                if (result.price_tier) {
                    vm.salesOrder.bom_item_price_tiers = result.bom_item_price_tiers;
                    vm.bomItemUnitCosts[bomitem.id] = result.price_tier.getPrice(bomitem.getItemTotal(vm.qty));
                    var lineItem = vm.salesOrder.line_items.find(function(item) { return item.bom == bomitem.bom; });
                    if (lineItem) { lineItem.getBOMPrice(vm.bomItemUnitCosts); }
                }
            }, function() {

            });
        }

        function stockDialog(componentId) {
            SlateItemStockDialog.showStock(componentId);
        }
    }


})();
