(function() {
    "use strict";

    angular
        .module('slate.requests')
        .directive('slateRequestRow', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/requests/requests.row.dir.html',
            scope: {
                request: '=',
                onEdit: '&',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $window, $location, $timeout, $anchorScroll, SlateAuth, SlateRolesSrv, $q, getLinkSrv, SlateRequest, SlateRequestEdit) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.roles = SlateRolesSrv;

        vm.permissions = SlateAuth.user.permissions;

        vm.getLinkSrv = getLinkSrv;

        vm.editWorkflow = editWorkflow;
        vm.deleteRequest = deleteRequest;
        vm.toggleCompletion = toggleCompletion;
        vm.toggle = toggle;
        init();

        if ($location.search().open_request == "request-" + vm.request.id) {
            vm.toggled = true;
            
            $timeout(function() {
                var element = document.getElementById("request-" + vm.request.id);
                element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            }, 200);
        }


        function init() {
            var modelPromise;
            var listFilters;
            vm.loading = true;

            vm.loading = false;
        }

        function editWorkflow() {
            SlateRequestEdit.edit(vm.request).then(function(workflow) {
                $state.reload();
            });
        }


        function deleteRequest() {
            vm.request.deleteModel().then(function(workflow) {
                $state.reload();
            });
        }

        function toggleCompletion() {
            vm.request.complete = !vm.request.complete;
            vm.request.saveModel();
        }

        function toggle() {
            vm.toggled = !vm.toggled;
            if (vm.toggled) {
                $location.replace().search('open_request', 'request-' + vm.request.id);
            } else {
                if($location.search().open_request == 'request-' + vm.request.id) {
                    $location.replace().search('open_request', null);
                }
            }
        }
    }


})();
