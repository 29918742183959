(function () {
    'use strict';

    angular
        .module('slate.purchaseorders')
        .directive('slatePurchaseOrderShip', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/purchaseorders/ship.edit.dir.html',
            scope: {
                purchaseOrder: '=',
                shipment: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController(
        $scope,
        $q,
        $timeout,
        $state,
        $translate,
        $http,
        SlateCompany,
        SlateLocation,
        SlateLocations,
        SlatePOLineItems,
        SlatePOLineItem,
        SlateGood,
        SlateShipment,
        getLinkSrv
    ) {
        /* jshint validthis: true */
        const vm = this;
        vm.getLink = getLinkSrv;

        vm.new_shipment = new SlateShipment();

        vm.saveObject = saveObject;
        vm.isComplete = isComplete;
        vm.isPartialLabor = isPartialLabor;
        vm.isPartialNoninv = isPartialNoninv;
        vm.showShipmentLocation = showShipmentLocation;
        vm.showDeliveryLocation = showDeliveryLocation;
        vm.showOriginLocation = showOriginLocation;
        vm.checkNumSign = checkNumSign;
        vm.calcTotalCBMs = calcTotalCBMs;

        vm.getLocation = getLocation;
        vm.geolocate = geolocate;
        vm.countries = Countries;

        vm.ship_types = ['oceanlcl',
            'oceanfcl',
            'oceanexp',
            'air',
            'truck',
            'ups',
            'fedex',
            'dhl',
            'other'];

        vm.incoterms = {
            jansy: ['exw',
                'fca',
                'fob',
                'cif',
                'cpt',
                'cip',
                'dat',
                'dap',
                'ddp',
                'ddu',
                'other'],
            customer: ['exw', 'fob', 'fca'],
            vendor: ['exw',
                'fca',
                'fob',
                'cif',
                'cpt',
                'cip',
                'dat',
                'dap',
                'ddp',
                'ddu',
                'other']
        };

        vm.pickup_info = {
        };

        vm.lineItems = new SlatePOLineItems();

        vm.loadingLocations = {};
        vm.autoselectedLocation = {};

        $scope.$watch('vm.purchaseOrder.id', (old_val, new_val) => {
            if (!vm.pickup_info.ready_date) { vm.pickup_info.ready_date = vm.purchaseOrder.desired_date; }
            if (!vm.pickup_info.eta) { vm.pickup_info.eta = vm.purchaseOrder.desired_date; }
            if (!vm.pickup_info.origin_from) { vm.pickup_info.origin_from = vm.purchaseOrder.vendor; }

            if (vm.purchaseOrder.id && !vm.saving) {
                vm.lineItems.getList({ purchase_order: vm.purchaseOrder.id });
            }
        });

        vm.jansy_china = new SlateCompany();
        vm.jansy_warehouses = new SlateLocations();

        vm.jansy_china.loadFromServer('jansy-china').then(() => {
            vm.jansy_warehouses.getList({ company: vm.jansy_china.id, warehouse_only: true, warehouse_type: 'invlocation' });
        });

        function isPartialLabor() {
            return vm.lineItems.list.some((item) => item.category === 'Labor') && !vm.lineItems.list.every((item) => item.category === 'Labor');
        }

        function isPartialNoninv() {
            return vm.lineItems.list.some((item) => item.component_info.non_inventory);
        }

        function combineObjects() {
            const deferred = $q.defer();

            if (vm.saving === true) { return false; }
            if (vm.shipment_existing !== 'Existing Shipment') { return false; }

            vm.saving = true;
            vm.error = false;

            vm.shipment.loadFromServer(vm.combine_into_shipment[0].id).then(() => {
                vm.lineItems.list.forEach((item) => {
                    const ship_item = new SlateGood();

                    if (Number.isNaN(item.qty_to_ship) || !item.qty_to_ship) { return false; }
                    const existing_item = vm.shipment.goods.find((good) => good.polineitem === item.id);

                    if (existing_item) {
                        existing_item.qty = Big(existing_item.qty).add(item.qty_to_ship);
                    } else {
                        if (item.category !== 'Labor') {
                            ship_item.name = item.name.slice(0, 72);
                            ship_item.item_num = item.component_info.composite_num;
                            ship_item.unit = item.unit_name;
                            ship_item.component = item.component;
                        } else {
                            ship_item.name = item.finished_good_info.finished_good_info.name.slice(0, 72);
                            ship_item.item_num = item.finished_good_info.finished_good_info.composite_num;
                            ship_item.unit = item.finished_good_info.finished_good_info.unit_name;
                            ship_item.component = item.finished_good_info.finished_good;
                        }

                        ship_item.polineitem = item.id;
                        ship_item.purchaseorder = vm.purchaseOrder.id;
                        ship_item.client = vm.purchaseOrder.company;
                        ship_item.vendor = vm.purchaseOrder.vendor;
                        ship_item.po_name = vm.purchaseOrder.jansy_po;

                        ship_item.project = vm.purchaseOrder.project;
                        ship_item.order = vm.purchaseOrder.order;
                        ship_item.sales_mgr = vm.purchaseOrder.sales_mgr;
                        ship_item.proj_mgr = vm.purchaseOrder.proj_mgr;
                        ship_item.ready_date = vm.pickup_info.ready_date;
                        ship_item.origin_from = vm.pickup_info.origin_from;
                        ship_item.origin_warehouse = vm.pickup_info.origin_warehouse;

                        ship_item.qty = item.qty_to_ship;
                        ship_item.qty_per_carton = item.qty_per_carton;
                        ship_item.length = item.length;
                        ship_item.width = item.width;
                        ship_item.height = item.height;
                        ship_item.weight_per_unit = item.weight_per_unit;
                        ship_item.cbm = item.cbm_to_ship;
                        ship_item.hts_code = item.hts_to_ship;
                        ship_item.notes = item.ship_comments;

                        item.ship_item = ship_item;
                        vm.shipment.goods.push(ship_item);
                    }

                    return null; // make eslint happy
                });

                vm.shipment.saveModel().then(() => {
                    const promises = [];

                    if (vm.fileSrv) {
                        promises.push(vm.fileSrv.uploadFiles('shipment', vm.shipment.id));
                    }

                    return $q.all(promises).then(() => {
                        $timeout(() => {
                            vm.saving = false;
                            deferred.resolve();
                            if ($state.current.name !== 'shipmentEditPage') {
                                $state.transitionTo(
                                    'shipmentEditPage',
                                    { id: vm.shipment.id },
                                    { location: 'replace' }
                                );
                            }
                        }, 300);
                    }, () => {
                        vm.saving = false;
                        vm.error = true;
                    });
                }, () => {
                    vm.saving = false;
                    vm.error = true;
                });

                return deferred.promise;
            });

            return null; // make eslint happy
        }

        function saveObject() {
            const deferred = $q.defer();

            if (vm.saving === true) {
                return false;
            }

            if (vm.shipment_existing === 'Existing Shipment') {
                return combineObjects();
            }

            if (vm.shipment_existing === 'Vendor to Vendor') {
                vm.shipment.forwarder = vm.jansy_china.id;
                vm.shipment.shipment_type = 'other';
                vm.shipment.warehouse = vm.jansy_warehouses.list[0].id;

                vm.shipment.freight_handler = 'jansy';
                vm.shipment.delivery_type = '3rdparty';
                vm.shipment.factory_incoterms = 'other';

                vm.shipment.house_bol = 'Slate-VendorToVendor';
                vm.shipment.etd = vm.pickup_info.ready_date;
                vm.shipment.eta = vm.pickup_info.eta;
                vm.shipment.ready_date = vm.pickup_info.ready_date;

                vm.shipment.name = `PO# ${vm.purchaseOrder.jansy_po} ${vm.from_company.name} to ${vm.to_company.name}`;
                vm.shipment.name = vm.shipment.name.substring(0, 72);
            }

            vm.saving = true;
            vm.error = false;
            vm.shipment.goods = [];
            vm.lineItems.list.forEach((item) => {
                if (Number.isNaN(item.qty_to_ship) || !item.qty_to_ship) { return false; }

                const ship_item = new SlateGood();
                if (item.category !== 'Labor') {
                    ship_item.name = item.name.slice(0, 72);
                    ship_item.item_num = item.component_info.composite_num;
                    ship_item.unit = item.unit_name;
                    ship_item.component = item.component;
                } else {
                    ship_item.name = item.finished_good_info.finished_good_info.name.slice(0, 72);
                    ship_item.item_num = item.finished_good_info.finished_good_info.composite_num;
                    ship_item.unit = item.finished_good_info.finished_good_info.unit_name;
                    ship_item.component = item.finished_good_info.finished_good;
                }

                ship_item.polineitem = item.id;
                ship_item.purchaseorder = vm.purchaseOrder.id;
                ship_item.client = vm.purchaseOrder.company;
                ship_item.vendor = vm.purchaseOrder.vendor;
                ship_item.po_name = vm.purchaseOrder.jansy_po;

                ship_item.project = vm.purchaseOrder.project;
                ship_item.order = vm.purchaseOrder.order;
                ship_item.sales_mgr = vm.purchaseOrder.sales_mgr;
                ship_item.proj_mgr = vm.purchaseOrder.proj_mgr;
                ship_item.ready_date = vm.pickup_info.ready_date;
                ship_item.origin_from = vm.pickup_info.origin_from;
                ship_item.origin_warehouse = vm.pickup_info.origin_warehouse;

                ship_item.qty = item.qty_to_ship;
                ship_item.qty_per_carton = item.qty_per_carton;
                ship_item.length = item.length;
                ship_item.width = item.width;
                ship_item.height = item.height;
                ship_item.weight_per_unit = item.weight_per_unit;
                ship_item.cbm = item.component_info.cbm;
                ship_item.hts_code = item.hts_to_ship;
                ship_item.notes = item.ship_comments;

                item.ship_item = ship_item;

                vm.shipment.goods.push(ship_item);

                return null; // make eslint happy
            });

            if (vm.shipment.freight_handler === 'customer' && !vm.shipment.delivery_type) {
                vm.shipment.delivery_type = 'customer';
            }

            vm.shipment.saveModel().then(() => {
                const promises = [];

                if (vm.fileSrv) {
                    promises.push(vm.fileSrv.uploadFiles('shipment', vm.shipment.id));
                }

                return $q.all(promises).then(() => {
                    if (vm.shipment_existing === 'New Shipment') {
                        $timeout(() => {
                            vm.saving = false;
                            deferred.resolve();
                            $state.transitionTo(
                                'shipmentEditPage',
                                { id: vm.shipment.id },
                                { location: 'replace' }
                            );
                        }, 300);
                    } else if (vm.shipment_existing === 'Vendor to Vendor') {
                        vm.shipment.status = 'intransit';
                        vm.shipment.saveModel().then(() => {
                            $timeout(() => {
                                vm.shipment.status = 'delivered';
                                vm.shipment.saveModel().then(() => {
                                    $state.transitionTo(
                                        'purchaseOrderEditPage',
                                        { id: vm.purchaseOrder.id },
                                        { location: 'replace' }
                                    );
                                }, () => {
                                    vm.error = true;
                                    $timeout(() => {
                                        $state.transitionTo(
                                            'shipmentEditPage',
                                            { id: vm.shipment.id },
                                            { location: 'replace' }
                                        );
                                    }, 300);
                                });
                            }, 5000);
                        }, () => {
                            vm.error = true;
                            $timeout(() => {
                                $state.transitionTo(
                                    'shipmentEditPage',
                                    { id: vm.shipment.id },
                                    { location: 'replace' }
                                );
                            }, 1000);
                        });
                    }
                }, () => {
                    vm.saving = false;
                    vm.error = true;
                });
            }, () => {
                vm.saving = false;
                vm.error = true;
            });

            return deferred.promise;
        }

        function isComplete() {
            if (vm.shipment_existing === 'Existing Shipment') {
                if (vm.combine_into_shipment && vm.combine_into_shipment.length !== 1) { return false; }
                if (!vm.pickup_info.ready_date) { return false; }
                if (!vm.pickup_info.origin_from) { return false; }
                if (!vm.pickup_info.origin_warehouse) { return false; }
            } else if (vm.shipment_existing === 'Vendor to Vendor') {
                if (!vm.pickup_info.ready_date) { return false; }
                if (!vm.pickup_info.origin_from) { return false; }
                if (!vm.pickup_info.origin_warehouse) { return false; }
                if (!vm.shipment.delivery_to) { return false; }
                if (!vm.shipment.delivery_warehouse) { return false; }
                if (!vm.shipment.final_destination_state) { return false; }
            } else {
                if (!vm.shipment.freight_handler) { console.log('Must enter freight handler'); return false; }
                if (!vm.pickup_info.ready_date) { console.log('Pickup info missing ready date'); return false; }
                if (!vm.shipment.factory_incoterms) { console.log('Must enter factory incoterms'); return false; }
                if (vm.shipment.freight_handler !== 'customer') {
                    if (vm.shipment.freight_handler !== 'vendor') {
                        if (!vm.shipment.warehouse) { console.log('Non customer handled customer delivried require warehouse'); return false; }
                        if (!vm.shipment.forwarder) { console.log('Must enter forwarder'); return false; }
                    }
                    if (!vm.shipment.shipment_type) { console.log('Must enter shipment type'); return false; }
                    if (!vm.shipment.origin_state) { console.log('Must enter origin state'); return false; }
                    if (!vm.shipment.delivery_to) { console.log('Must enter delivery_to field'); return false; }
                    if (vm.shipment.delivery_type !== 'customer' && !vm.shipment.delivery_warehouse) { console.log('Must enter delivery warehouse for 3rd party delivery'); return false; }
                    if (!vm.shipment.final_destination_state) { console.log('must enter final destination state'); return false; }
                }
            }
            if (!vm.lineItems.list.some((item) => {
                if (Number.isNaN(item.qty_to_ship)) { return false; }
                if (!item.qty_to_ship || item.qty_to_ship === '0') { return false; }
                return true;
            })) { console.log('At least one line item must have a qty to ship'); return false; }

            return true;
        }

        $scope.$watch('vm.shipment.freight_handler', (old_val, new_val) => {
            if (vm.shipment.freight_handler === 'customer' && !vm.shipment.delivery_to) {
                vm.shipment.delivery_to = vm.purchaseOrder.company;
            }

            if (vm.shipment.freight_handler === 'vendor') {
                vm.shipment.delivery_type = 'customer';
            }

            if (!vm.pickup_info.origin_from) {
                vm.pickup_info.origin_from = vm.purchaseOrder.vendor;
                if (vm.purchaseOrder) {
                    if (vm.purchaseOrder.ship_terms && !vm.shipment.factory_incoterms && vm.incoterms.jansy.includes(vm.purchaseOrder.ship_terms)) {
                        vm.shipment.factory_incoterms = vm.purchaseOrder.ship_terms;
                    }

                    if (vm.purchaseOrder.shipvia && !vm.shipment.shipment_type && vm.ship_types.includes(vm.purchaseOrder.shipvia)) {
                        vm.shipment.shipment_type = vm.purchaseOrder.shipvia;
                    }
                }
            }
        });

        $scope.$watch('vm.shipment.freight_handler', (old_val, new_val) => {
            if (vm.shipment.freight_handler === 'customer' && !vm.shipment.delivery_type) {
                vm.shipment.delivery_type = 'customer';
            }
        });


        $scope.$watch('vm.shipment.delivery_type', (old_val, new_val) => {
            if (vm.shipment.delivery_type === 'customer' && !vm.shipment.delivery_to) {
                vm.shipment.delivery_to = vm.purchaseOrder.company;
            }
        });


        function showDeliveryLocation() {
            if (vm.shipment.freight_handler === 'customer') { return false; }
            if (!vm.shipment.delivery_to) { return false; }
            if (vm.loadingLocations.delivery_warehouse) { return false; }
            if (vm.autoselectedLocation.delivery_warehouse) { return false; }
            if (vm.shipment.delivery_type === 'customer') { return false; }
            return true;
        }

        function showOriginLocation() {
            if (!vm.pickup_info.origin_from) { return false; }
            if (vm.loadingLocations.origin_warehouse) { return false; }
            if (vm.autoselectedLocation.origin_warehouse) { return false; }

            return true;
        }

        function showShipmentLocation() {
            if (!vm.shipment.forwarder) { return false; }
            if (!vm.shipment.shipment_type) { return false; }
            if (vm.loadingLocations.warehouse) { return false; }
            if (vm.autoselectedLocation.warehouse) { return false; }

            return true;
        }

        $scope.$watch('vm.shipment.delivery_to', (old_val, new_val) => {
            if (vm.shipment.delivery_to) { checkLocation('delivery_to', 'customer', 'delivery_warehouse', 'final_destination'); }
        });

        $scope.$watch('vm.pickup_info.origin_from', (old_val, new_val) => {
            if (vm.pickup_info.origin_from) { checkLocation('origin_from', 'factory', 'origin_warehouse', 'origin'); }
        });

        $scope.$watch('vm.shipment.delivery_warehouse', (old_val, new_val) => {
            if (vm.shipment.delivery_warehouse) { updateAddress(vm.shipment.delivery_warehouse, 'final_destination'); }
        });

        $scope.$watch('vm.pickup_info.origin_warehouse', (old_val, new_val) => {
            if (vm.pickup_info.origin_warehouse) { updateAddress(vm.pickup_info.origin_warehouse, 'origin'); }
        });

        $scope.$watch('vm.shipment.forwarder', (old_val, new_val) => {
            if (vm.shipment.forwarder) { checkLocation('forwarder', vm.shipment.shipment_type, 'warehouse'); }
        });

        $scope.$watch('vm.shipment.shipment_type', (old_val, new_val) => {
            if (vm.shipment.forwarder) { checkLocation('forwarder', vm.shipment.shipment_type, 'warehouse'); }
        });

        function updateAddress(warehouse_id, address_field) {
            const location = new SlateLocation();
            location.loadFromServer(warehouse_id).then(() => {
                if (address_field) {
                    vm.shipment[`${address_field}_street`] = [location.street1, location.street2].join(' ');
                    vm.shipment[`${address_field}_city`] = location.city;
                    vm.shipment[`${address_field}_state`] = location.state;
                    vm.shipment[`${address_field}_postal`] = location.postal;
                    vm.shipment[`${address_field}_country`] = location.country;
                }
            });
        }

        function checkLocation(company_field, type_field, warehouse_field, address_field) {
            const locations = new SlateLocations();
            const filters = { company: vm.shipment[company_field] };

            if (!vm.shipment[company_field]) { return false; }
            if (type_field && type_field.length) {
                filters.warehouse_type = type_field;
            }

            vm.loadingLocations[warehouse_field] = true;

            locations.getList(filters).then(() => {
                if (locations.list.length !== 1) {
                    vm.loadingLocations[warehouse_field] = false;
                    vm.autoselectedLocation[warehouse_field] = false;
                    return false;
                }
                vm.shipment[warehouse_field] = locations.list[0].id;
                vm.loadingLocations[warehouse_field] = false;
                vm.autoselectedLocation[warehouse_field] = true;

                return null; // make eslint happy
            });

            return null; // make eslint happy
        }

        function checkNumSign(lineItem, field) {
            if (lineItem[field] < 0) {
                lineItem[field] = Math.abs(lineItem[field]);
            }
        }

        function calcCBMs(lineItem) {
            if ([
                lineItem.length, lineItem.width, lineItem.height
            ].some((item) => item === 0.0 || typeof item === 'undefined')) { return new Big(0); }

            const l = new Big(lineItem.length);
            const w = new Big(lineItem.width);
            const h = new Big(lineItem.height);

            const cbmPerCarton = l.times(w).times(h).div(1000000);

            return cbmPerCarton;
        }

        function calcTotalCBMs(lineItem) {
            if ([
                lineItem.qty_to_ship, lineItem.qty_per_carton
            ].some((item) => item === 0.0 || typeof item === 'undefined')) { return new Big(0); }

            const cbmPerCarton = calcCBMs(lineItem);

            const qtyToShip = new Big(lineItem.qty_to_ship);
            const qtyPerCarton = new Big(lineItem.qty_per_carton);
            const cartons = qtyToShip.div(qtyPerCarton);

            const totalCBMs = cartons.round(0, 3).times(cbmPerCarton);

            lineItem.cbm_to_ship = totalCBMs;

            return null; // make eslint happy
        }

        /** ***** Mapping stuff  ********** */
        vm.sessionToken = uuidv4();

        function getLocation(q) {
            const deferred = $q.defer();

            // var url = "/geolocate/";
            const url = `${API_URL}utils/geocomplete/`;
            const params = {
                sessiontoken: vm.sessionToken,
                lang: $translate.use(),
                address: q
            };

            if (q.length < 5) {
                deferred.reject();
                return deferred.promise;
            }

            $http.get(url, {
                params
            }).then((response) => {
                const locations = [];

                angular.forEach(response.data.predictions, (item) => {
                    const name = item.description;

                    locations.push({ name, details: item });
                });
                deferred.resolve(locations);
            });

            return deferred.promise;
        }

        function geolocate(address, variable, addy_prefix) {
            const url = `${API_URL}utils/geolocate/`;
            const params = {
                lang: $translate.use(),
                address
            };

            if (address.length < 5) {
                vm.shipment[variable] = { lat: 0, lng: 0 };
                return false;
            }

            const promise = $http.get(url, {
                params
            }).then((response) => {
                let results = [];
                vm.shipment[variable] = { lat: 0, lng: 0 };

                results = response.data.results;
                if (results && results.length) {
                    vm.shipment[variable].lat = results[0].geometry.location.lat;
                    vm.shipment[variable].lng = results[0].geometry.location.lng;
                }

                const comps = results[0].address_components;
                let street_num = comps.find((item) => item.types.includes('street_number'));
                street_num = street_num ? street_num.short_name : '';
                let street_name = comps.find((item) => item.types.includes('route'));
                street_name = street_name ? street_name.short_name : 'Unkown St.';
                const city = comps.find((item) => item.types.includes('locality'));
                const state = comps.find((item) => item.types.includes('administrative_area_level_1'));
                const postal = comps.find((item) => item.types.includes('postal_code'));
                const country = comps.find((item) => item.types.includes('country'));

                vm.shipment[`${addy_prefix}_street`] = [street_num, street_name].join(' ');
                vm.shipment[`${addy_prefix}_city`] = city ? city.short_name : 'Unkown City.';
                vm.shipment[`${addy_prefix}_state`] = state ? state.short_name : '';
                vm.shipment[`${addy_prefix}_postal`] = postal ? postal.long_name : '';
                vm.shipment[`${addy_prefix}_country`] = country ? country.long_name : 'United States';
            });

            return promise;
        }
    }
})();
