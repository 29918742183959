(function() {
    "use strict";

    angular
        .module('slate.notifications')
        .directive('slateNotificationPrefsList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/notifications/notifications.prefs.dir.html',
            scope: {
                user: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;

        vm.prefs = [];
        vm.loading = true;
        vm.saving = false;

        vm.auth = SlateAuth;
        if(vm.user) {
            vm.test_user_id = vm.user;
        } else {
            vm.test_user_id = SlateAuth.user.contact_id;
        }



        vm.getData = getData;
        vm.save = save;
        vm.test = test;

        getData();

        function getData() {
            var filters = {};
            var apiUrl = API_URL + 'notifications/notification_prefs/';
            vm.loading = true;

            if(vm.user) { filters.user = vm.user; }
            $http.get(apiUrl, {params: filters}).then(function(response) {
                vm.prefs = response.data.payload.sort(function(a, b) { return (a.cat > b.cat) ? 1 : -1; });
                vm.loading = false;
            });
        }


        function save() {
            var data = {
                prefs: vm.prefs
            };
            var apiUrl = API_URL + 'notifications/notification_prefs/';
            vm.saving = true;
            vm.saved = false;
            vm.error = false;

            if(vm.user) { data.user = vm.user; }
            $http.post(apiUrl, data).then(function(response) {
                vm.saving = false;
                vm.saved = true;
            },
            function(response){
                vm.saving = false;
                vm.error = true;
           });
        }

        function test(contact_id) {
            var apiUrl = API_URL + 'notifications/notification/test/';
            var data = {};
            if(!contact_id) { return false; }
            if(vm.saving) { return false; }

            vm.testing = true;


            if(vm.user) { data.user = vm.user; }
            $http.post(apiUrl, data).then(function(response) {
                vm.testing = false;

            },
            function(response){
                vm.testing = false;
           });

        }
    }


})();
