(function() {
    "use strict";

    angular
        .module('slate.requests')
        .directive('slateRequestList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/requests/requests.dir.html',
            scope: {
                parentType: '=',
                parentFk: '=',
                templates: '=?',
                clientOnHold: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $timeout, $state, $location, $q, getLinkSrv,SlateRequests, SlateRequest, SlateRequestEdit) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.requests = new SlateRequests();

        vm.addNew = addNew;

        vm.dragOptions = {
            handle: ".handle",
            placeholder: "beingDragged",
            stop: sortingStop,
            sort: function(event, ui) {
                if(ui.position.top < 0) {
                    ui.helper.css({'top' : ui.position.top + $(window).scrollTop() + 'px'});
                }

            }
        };

        vm.init = init;
        vm.filters = {q: undefined, hide_completed: false};

        $scope.$watch('vm.parentFk', function() { init(); });
        $scope.$watchCollection('vm.filters', function() { init(); });

        function init() {

            var modelPromise;
            var listFilters = {};
            vm.loading = true;
            if (!vm.templates) {
                listFilters[vm.parentType] = vm.parentFk;
            } else {
                listFilters.templates = true;
            }

            modelPromise = vm.requests.getList(Object.assign({}, listFilters, vm.filters));
            return modelPromise.then(function() {
                vm.loading = false;
            });

        }

        function addNew() {
            var newWorkflow = new SlateRequest();
            var order = 100;
            if(vm.requests.list.length) {
                order = vm.requests.list[vm.requests.list.length -1].order + 10;
            }

            newWorkflow[vm.parentType] = vm.parentFk;
            newWorkflow.tag = 'request';
            newWorkflow.order = order;

            SlateRequestEdit.edit(newWorkflow).then(function(workflow) {
                init().then(function() {
                    $timeout(function() {
                        var id = "request-"+workflow.id.toString();
                        var element = document.getElementById(id);
                        element.scrollIntoView({block: "end", behavior: "smooth"});
                    }, 50);

                });
            });
        }

        function sortingStop(e, ui) {
            vm.requests.saveAll();
        }

    }


})();
