(function () {
    "use strict";

    angular
        .module('slate.rates')
        .directive('slateRateSheetEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/rates/ratesheet.edit.dir.html',
            scope: {
                rateSheet: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $state,
        $location,
        SlateRateSheetLineItem,
        SlateRateSheetEstimate
    ) {
        /* jshint validthis: true */
        var vm = this;

        vm.saving = false;

        vm.containerSizes = ["20ft", "40ft", "40ftHQ", "45ft"];
        
        vm.originPorts = [
            "Shanghai, China",
            "Ningbo, China",
            "KaoHsiung/Keelung",
            "Yantian/Shenzhen, China",
            "Qingdao, China",
            "Tianjin/Xingang, China",
            "Hong Kong",
            "Xiamen",
            "Taichung, Taiwan",
            "HoChiMinh, Vietnam",
            "Zhongshan/Zhuhai (CFS)",
            "Busan, Korea",
            "Italy",
        ];

        vm.destinationPorts = [
            "New York",
            "Norfolk",
            "Savannah",
            "Los Angeles (Long Beach)",
            "Toronto",
            "Cleveland",
            "Nashville",
            "Baltimore",
            "Chicago",
            "Houston (CFS)",
            "Dallas (CFS)",
            "Louisville",
            "Portland",
            "Utah",
        ];

        vm.truckingFinalDests = [
            "Franklin, TN 37064",
            "Nashville, TN 37211",
            "Waukegan, IL 60085",
            "Manitowoc, WI 54221",
            "Solon, OH 44139",
            "North Arlington, NJ 07031",
            "South Brunswick, NJ",
            "Cedar Grove, NJ",
            "Wharlton, NJ",
            "Edison, NJ",
            "Dayton, NJ",
            "Holmdel, NJ",
            "Blauvelt, NY",
            "Antioch, TN 37013",
            "Social Circle, GA 30025",
            "Arcadia, CA 91006",
            "Valencia, CA",
            "Mount Olive, NJ 07828",
            "Meridian, ID",
            "Fairfax, VT",
            "Palm Springs, FL",
            "Milford, NH 03055",
            "Rockville, MD 20850",
        ];

        vm.addFCLLineItem = addFCLLineItem;
        vm.addLineItem = addLineItem;
        vm.dupLineItem = dupLineItem;
        vm.isComplete = isComplete;
        vm.saveObject = saveObject;

        vm.fclLineItems = vm.rateSheet.line_items.filter(function(line_item) { return line_item.shipment_type == 'oceanfcl'; });
        vm.truckingLineItems = vm.rateSheet.line_items.filter(function(line_item) { return line_item.shipment_type == 'trucking'; });
        vm.lclLineItems = vm.rateSheet.line_items.filter(function(line_item) { return line_item.shipment_type == 'oceanlcl'; });
        vm.sonicLineItems = vm.rateSheet.line_items.filter(function(line_item) { return line_item.shipment_type == 'sonic'; });

        var lineItems = {
            'oceanfcl': vm.fclLineItems,
            'trucking': vm.truckingLineItems,
            'oceanlcl': vm.lclLineItems,
            'sonic': vm.sonicLineItems,
        };

        function addLineItem(shipment_type) {
            var line_item = new SlateRateSheetLineItem();
            var estimate = new SlateRateSheetEstimate();
            
            line_item.shipment_type = shipment_type;
            line_item.estimates.push(estimate);

            lineItems[shipment_type].push(line_item);
        }

        function addFCLLineItem() {
            var line_item = new SlateRateSheetLineItem();
            line_item.shipment_type = 'oceanfcl';
            vm.containerSizes.forEach(function(size) {
                var estimate = new SlateRateSheetEstimate();
                estimate.container_size = size;
                line_item.estimates.push(estimate);
            });
            line_item.setContainerProperties();
            vm.fclLineItems.push(line_item);
        }

        function isComplete() {
            if(!vm.rateSheet.valid_from_date) { return false; }
            if(!vm.rateSheet.valid_to_date) { return false; }
            return true;
        }

        function dupLineItem(lineItem, index) {
            var newLineItem = new SlateRateSheetLineItem();
            var data = lineItem.getModelData();
            newLineItem.loadFromPayload(_.cloneDeep(data));
            newLineItem.id = undefined;
            newLineItem.estimates.forEach(function(estimate) {
                estimate.id = undefined;
                estimate.line_item = undefined;
            });

            lineItems[lineItem.shipment_type].splice(index, 0, newLineItem);
        }

        function saveObject() {
            if (vm.saving) { return false; }

            vm.saving = true;
            vm.saved = false;
            vm.error = false;

            var items = [vm.fclLineItems, vm.truckingLineItems, vm.lclLineItems, vm.sonicLineItems];
            items.forEach(function(lineItems) {
                lineItems.forEach(function(lineItem, index) {
                    lineItem.sort_order = index;
                });
            });

            var line_items = [];
            vm.rateSheet.line_items = line_items.concat(vm.fclLineItems, vm.truckingLineItems, vm.lclLineItems, vm.sonicLineItems);

            vm.rateSheet.saveModel().then(function() {
                vm.saved = true;
                $timeout(function() {
                    vm.saving = false;
                    $state.reload();
                }, 300);
            }, function(e) {
                vm.saving = false;
                vm.error = true;
            });
        }


    }

})();
