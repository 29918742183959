(function() {
    "use strict";

    angular
        .module('slate.schedule')
        .directive('slateScheduleDay', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/schedule/schedule.day.dir.html',
            scope: {
                userId: '=',
                initialDay: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $q, SlateUserAvail, SlateScheduleEvents) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;
        var userPromise;
        vm.loading = true;
        
        vm.avail = SlateUserAvail;
        userPromise = vm.avail.getUser(vm.userId);

        vm.day = new SlateScheduleEvents();

        if(!vm.initialDay) {
            vm.initialDay = new Date();
        }

        vm.dateForward = dateForward;
        vm.dateBack = dateBack;

        vm.remaining = {'am': 0, 'pm': 0};
        vm.totals = {'am': 0, 'pm': 0};
        userPromise.then(function() {
            updateModel();
        });
        

        function updateModel() {
            vm.loading = true;
            modelPromise = vm.day.getList({startDate: vm.initialDay.toISOString(), endDate: vm.initialDay.toISOString(), user_id: vm.userId });
            modelPromise.then(function() {
                var total = {'am': 0, 'pm': 0};
                
                angular.forEach(vm.day.list, function(item) {
                    total[item.am_pm] += item.duration;
                });
                
                vm.remaining.am = vm.avail.users[vm.userId][vm.initialDay.getDay()].am - total.am;
                vm.remaining.pm = vm.avail.users[vm.userId][vm.initialDay.getDay()].pm - total.pm;
                vm.totals = total;
                
                vm.loading = false;
            });

        }

        function dateForward() {
            vm.initialDay.setDate(vm.initialDay.getDate() + 1);
            userPromise.then(function() {
                updateModel();
            });
        }

        function dateBack() {
            vm.initialDay.setDate(vm.initialDay.getDate() - 1);
            userPromise.then(function() {
                updateModel();
            });
        }

        function saveAvail() {
            vm.avail.saveModel();
        }

    }


})();
