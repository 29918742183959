(function() {
    "use strict";

    angular
        .module('slate.rates')
        .directive('slateRateSheetList', slateRatesList);

    function slateRatesList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/rates/ratesheet.list.dir.html',
            scope: {
                
            },
            controller: listController,
            controllerAs: 'vm',
            
        };
    }

    function listController($scope, $state, $stateParams, $location, $q, $http, SlateAuth, SlateDeleteObjSrv, SlateRateSheet, SlateRateSheets) {
        /* jshint validthis: true */
        var vm = this;

        vm.loading = true;

        vm.dupRateSheet = dupRateSheet;
        vm.deleteRateSheet = deleteRateSheet;

        vm.rateSheets = new SlateRateSheets();

        getData();

        function getData() {
            vm.rateSheets.getList().then(function() {
                vm.loading = false;
            });
        }

        function dupRateSheet(id) {
            if (window.confirm("Are you sure you want to copy this Rate Sheet?")) {
                var apiUrl = API_URL + 'rates/ratesheet/' + id + '/copy/';

                $http.post(apiUrl).then(function(response) {
                    $state.transitionTo("rateSheetEditPage", {id: response.data.payload.id});
                }, function(e) {
                    console.error(e);
                });
            }
        }

        function deleteRateSheet(rateSheet) {
            if (window.confirm("Are you sure you want to delete this Rate Sheet?")) {
                rateSheet.deleteModel().then(function() {
                    getData();
                }, function(e) {
    
                });
            }
        }

    }


})();
