(function () {
    "use strict";

    angular
        .module('slate.inspections')
        .service('InspectionFileEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateInspectionLineItem) {
        var templateUrl = '/partials/inspections/inspection.files.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(instance) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj: function () {
                        var inspection = new SlateInspectionLineItem();
                        if (!instance || !instance.id) {
                            return inspection;
                        }
                        return inspection.loadFromPayload(instance);
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $http, $q, $uibModalInstance, getLinkSrv, SlateAuth, SlateFileNodeList, SlateInspections, SlateInspectionLineItems, QIMAProductReference, obj) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;
        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;

        vm.saving = false;
        vm.errorSaving = false;
        vm.getComponents = getComponents;
        vm.getProjects = getProjects;
        vm.getVariations = getVariations;
        vm.getAssemblyFiles = getAssemblyFiles;
        vm.getTechnicalFiles = getTechnicalFiles;
        vm.getArtFiles = getArtFiles;
        vm.toggleFile = toggleFile;
        vm.isComplete = isComplete;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.obj.files['Assembly Files'] = [];
        vm.obj.files['Technical Files'] = [];
        vm.obj.files['Art Files'] = [];

        function getFiles(project, parentType, path) {
            var files = new SlateFileNodeList();
            return files.getList({ parent_fk: project, parent_type: parentType, path: path });
        }

        function getComponents() {
            var seen = {};
            vm.components = vm.obj.line_items.reduce(function (prevValue, item) {
                if (!seen[item.po_lineitem_info.parent_info.id]) {
                    seen[item.po_lineitem_info.parent_info.id] = true;
                    prevValue.push(item.po_lineitem_info.parent_info);
                }
                return prevValue;
            }, []);
        }
        // vm.components = getComponents();

        function getProjects() {
            var seen = {};
            vm.projects = vm.obj.line_items.reduce(function (prevValue, item) {
                if (!seen[item.po_lineitem_info.project_info.id]) {
                    seen[item.po_lineitem_info.project_info.id] = true;
                    prevValue.push(item.po_lineitem_info.project_info);
                }
                return prevValue;
            }, []);
            // return vm.projects;
        }

        function getVariations() {
            var seen = {};
            vm.variations = vm.obj.line_items.reduce(function (prevValue, item) {
                if (!seen[item.po_lineitem_info.component_info.id]) {
                    seen[item.po_lineitem_info.component_info.id] = true;
                    prevValue.push(item.po_lineitem_info.component_info);
                }
                return prevValue;
            }, []);
        }
        // vm.projects = getProjects();

        function getAssemblyFiles() {
            if (!vm.obj.assemblyFiles) {
                getFiles(vm.obj.po_lineitem_info.project_info.id, 'project', 'assemblies').then(function (files) {
                    vm.obj.assemblyFiles = files.list;
                    getFiles(vm.obj.id, 'inspectionlineitem', 'assembly-files').then(function (nodes) {
                        var coreIds = nodes.list.map(function (file) { return file.core_id; });
                        vm.obj.assemblyFiles.forEach(function (file) {
                            if (coreIds.includes(file.core_id)) {
                                file.selected = true;
                                file.disabled = true;
                            }
                        });
                    });
                });
            }
        }
        getAssemblyFiles();

        function getTechnicalFiles() {
            if (!vm.obj.technicalFiles) {
                getFiles(vm.obj.po_lineitem_info.parent_info.id, 'project', 'design-files').then(function (files) {
                    vm.obj.technicalFiles = files.list;
                    getFiles(vm.obj.id, 'inspectionlineitem', 'technical-files').then(function (nodes) {
                        var coreIds = nodes.list.map(function (file) { return file.core_id; });
                        vm.obj.technicalFiles.forEach(function (file) {
                            if (coreIds.includes(file.core_id)) {
                                file.selected = true;
                                file.disabled = true;
                            }
                        });
                    });
                });
            }
        }
        getTechnicalFiles();

        function getArtFiles() {
            if (!vm.obj.artFiles) {
                getFiles(vm.obj.po_lineitem_info.component_info.id, 'project', 'design-files').then(function (files) {
                    vm.obj.artFiles = files.list;
                    getFiles(vm.obj.id, 'inspectionlineitem', 'art-files').then(function (nodes) {
                        var coreIds = nodes.list.map(function (file) { return file.core_id; });
                        vm.obj.artFiles.forEach(function (file) {
                            if (coreIds.includes(file.core_id)) {
                                file.selected = true;
                                file.disabled = true;
                            }
                        });
                    });
                });
            }
        }
        getArtFiles();

        function isComplete() {
            if (!vm.obj.products[0].refs.length) { return false; }
            return true;
        }

        function saveObject() {
            $uibModalInstance.close(vm.obj);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function toggleFile(file, dirName) {
            var dir = vm.obj.files[dirName];
            if (file.selected) {
                dir.push(file.id);
            } else {
                var index = dir.indexOf(file.id);
                dir.splice(index, 1);
            }
        }

        function sendToQIMA() {
            vm.saving = true;
            var url = API_URL + '/qima/bookings/' + vm.booking.id;
            $http.post(url).then(function (resp) {
                vm.saving = false;
            }, function () {
                vm.saving = false;
                vm.error = true;
            });
        }



    }

})();
