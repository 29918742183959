(function () {
    'use strict';

    angular
        .module('slate.helpdesk')
        .directive('slateTicketList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/helpdesk/ticket.list.dir.html',
            scope: {
                modelFilters: '=?filters',
                displayed: '=?',
                showNew: '=?',
                hideFilters: '=?',
                fields: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, getLinkSrv, SlateDeleteObjSrv, SlateTickets, SlateTicket, SlateAddTicket) {
        /* jshint validthis: true */
        const vm = this;

        const multiSearchFields = ['status'];

        vm.auth = SlateAuth;

        vm.addNew = addNew;

        vm.deleteTicket = deleteTicket;

        vm.baseTicket = new SlateTicket();

        if (!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'id',
                'status',
                'name',
                'submitter',
                'assigned_to',
                'type',
                'area',
                'priority',
                'created',
                'closed',
                'open_time'
            ];
            /* beautify preserve:end */
        }

        vm.types = ['slate',
            'acctseed',
            'salesforce',
            'email',
            'office',
            'printer',
            'computer',
            'newaccount',
            'removeaccount',
            'other'];

        vm.getLink = getLinkSrv;
        vm.loading = true;
        vm.filters = {};
        if (!vm.hideFilters) {
            vm.filters = {
                page: 1,
                count: 25,
                status: null,
                startDate: $location.search().startDate,
                endDate: $location.search().endDate
            };
        }

        multiSearchFields.forEach((field) => {
            vm.filters[field] = null;
        });


        if (!vm.modelFilters) { vm.modelFilters = {}; }
        angular.extend(vm.filters, {
            q: $location.search().q
        }, vm.modelFilters, $location.search(), vm.filters);
        if (typeof vm.filters.page === 'string') { vm.filters.page = parseInt(vm.filters.page, 10); }
        if (typeof vm.filters.count === 'string') { vm.filters.count = parseInt(vm.filters.count, 10); }
        if (typeof vm.filters.order_num === 'string') { vm.filters.order_num = parseInt(vm.filters.order_num, 10); }
        let parts;
        if (vm.filters.startDate) {
            parts = vm.filters.startDate.split('-');
            vm.start = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
        }
        if (vm.filters.endDate) {
            parts = vm.filters.endDate.split('-');
            vm.end = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
        }
        $scope.$watch('vm.start', () => {
            if (vm.start) {
                vm.filters.startDate = `${vm.start.getFullYear()}-${vm.start.getMonth() + 1}-${vm.start.getDate()}`;
            } else {
                vm.filters.startDate = null;
            }
        });
        $scope.$watch('vm.end', () => {
            if (vm.end) {
                vm.filters.endDate = `${vm.end.getFullYear()}-${vm.end.getMonth() + 1}-${vm.end.getDate()}`;
            } else {
                vm.filters.endDate = null;
            }
        });
        let deliveryParts;
        if (vm.filters.startDate) {
            deliveryParts = vm.filters.startDate.split('-');
            vm.startDelivery = new Date(parseInt(deliveryParts[0], 10), parseInt(deliveryParts[1], 10) - 1, parseInt(deliveryParts[2], 10));
        }
        if (vm.filters.endDate) {
            deliveryParts = vm.filters.endDate.split('-');
            vm.endDelivery = new Date(parseInt(deliveryParts[0], 10), parseInt(deliveryParts[1], 10) - 1, parseInt(deliveryParts[2], 10));
        }

        multiSearchFields.forEach((field) => {
            if (vm.filters[field] && typeof vm.filters[field] === 'string') {
                vm.filters[field] = [vm.filters[field]];
            }
        });

        vm.ticketList = new SlateTickets();

        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;
        vm.updateFromFilter();

        function deleteTicket(ticket) {
            SlateDeleteObjSrv.delete('helpdeskticket', ticket.id).then(() => {
                vm.updateFromFilter();
            });
        }


        function addNew() {
            SlateAddTicket.add().then(() => window.location.reload());

            // $location.url(url + '?' + queryString);
        }

        $scope.$watchCollection(() => vm.filters, (value) => {
            updateFromFilter();
        });
        function hasFilterData() {
            let hasData = false;
            angular.forEach(vm.filters, (value, key) => {
                if (key === 'page' || key === 'count' || key === 'q') {
                    return false;
                }
                if (typeof vm.filters[key] === 'undefined' || vm.filters[key] === undefined || vm.filters[key] === null) {
                    return false;
                }
                if (vm.filters[key] === vm.modelFilters[key]) {
                    return false;
                }
                if (typeof vm.filters[key] === 'string' && vm.filters[key].length === 0) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;
            });
            return hasData;
        }

        function updateFromFilter() {
            vm.loading = true;
            if (typeof vm.filters.status === 'string') {
                vm.filters.status = [vm.filters.status];
            }

            if (!vm.hideFilters) {
                angular.forEach(vm.filters, (value, item) => {
                    if (vm.modelFilters[item]) { return false; }
                    if (vm.filters[item]) {
                        $location.replace().search(item, vm.filters[item]);
                    } else {
                        $location.replace().search(item, null);
                    }

                    return true;
                });
            }

            const updatePromise = vm.ticketList.getList(vm.filters);
            updatePromise.then(() => {
                vm.loading = false;
                vm.displayed = vm.ticketList.list.length;
            });

            return updatePromise;
        }
    }
})();
