(function () {
    "use strict";

    angular
        .module('slate.orderplan')
        .service('SlateOrderPlanEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateOrderPlanStep) {
        var templateUrl = '/partials/orderplan/order.step.edit.sidebar.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(item, options) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj: function () {
                        var newitem = new SlateOrderPlanStep();
                        if(!item) {
                            return newitem;
                        }
                        if(typeof item == 'string') {
                            return newitem.loadFromServer(item);
                        }
                        return newitem.loadFromPayload(item);
                    },
                    orderId: function() {
                        if(typeof options === 'object' && options.order) {
                            return options.order;
                        }
                        return null;
                    },
                    purchaseorderId: function() {
                        if(typeof options === 'object' && options.purchaseorder) {
                            return options.purchaseorder;
                        }
                        return null;
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance,  SlateAuth, obj, orderId, purchaseorderId, SlateOrder, SlatePurchaseOrder, SlateOrderPlanSteps) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

        vm.step = obj;
        vm.order = new SlateOrder();
        vm.purchaseOrder = new SlatePurchaseOrder();
        vm.steps = new SlateOrderPlanSteps();
        vm.saving = false;
        vm.error = false;
        vm.saveObject = saveObject;
        vm.deleteObject = deleteObject;
        vm.cancel = cancel;

        vm.getStepsList = getStepsList;

        vm.isComplete = isComplete;

        vm.addParent = addParent;
        vm.deleteParent = deleteParent;
        vm.toggleComplete = toggleComplete;

        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            //zIndex: 2501,
        };

        vm.step_types = [
            'salesorder',
            'purchaseorder',
            'qc_check',
            'shipment',
            'fill',
            'billing',
            'final_billing',
            'misc'
        ];

        getSteps();

        function getSteps() {

            if (orderId && !vm.saving) {
                vm.loading = true;
                vm.steps.getList({ order: orderId }).then(function () {
                    vm.loading = false;
                });
            }
        }

        if(orderId) { vm.order.loadFromServer(orderId); }
        if(purchaseorderId) { vm.purchaseOrder.loadFromServer(purchaseorderId); }

        function saveObject() {
            vm.saving = true;
            vm.error = false;
            vm.step.order = orderId;

            vm.step.saveModel().then(function() {
                vm.saving = false;
                $uibModalInstance.close(vm.step);
            }, function() {
                vm.saving = false;
                vm.error = true;
            });
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }

        function deleteObject() {
            if(!confirm('Are you sure you want to delete this step?')) {
                return false;
            }

            vm.saving = true;
            vm.error = false;
            vm.step.deleteModel().then(function(response) {
                vm.saving = false;
                $uibModalInstance.close(null);
            }, function() {
                vm.saving = false;
                vm.error = true;
            });
        }

        function isComplete() {
            if(!vm.step.parents.every(function(item) {
                return item.length;
            })) { return false; }

            if(!vm.step.name) { return false; }
            return true;
        }

        function addParent(step) {
            step.parents.push('');
        }

        function deleteParent(step, idx) {
            step.parents.splice(idx, 1);
        }

        function toggleComplete() {
            if(!vm.auth.perms.orderplan_can_force_complete) { return false;}
            vm.step.completed = !vm.step.completed;
        }

        function getStepsList() {
            return vm.steps.list.filter(function(item) {
                return item.id != vm.step.id;
            });
        }
    }



})();
