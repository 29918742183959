(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('salesOrderListPage', {
                url: '/salesorders/',
                template: "<div class=\"content-pane list-page\"><slate-sales-order-list show-totals='true'></slate-sales-order-list></div>",
                current_app: 'salesorders',
                slateTitle: 'Sales Orders',
            })
            .state('salesOrderNewPage', {
                url: '/salesorders/so/new/',
                templateUrl: '/partials/salesorders/salesorder.new.page.html',
                controller: 'SlateSalesOrderNewPageController',
                controllerAs: 'vm',
                current_app: 'salesorders',
                slateTitle: 'Create New Sales Order - Sales Orders',
            })
            .state('salesOrderEditPage', {
                url: '/salesorders/so/:id/edit/',
                templateUrl: '/partials/salesorders/salesorder.edit.page.html',
                controller: 'SlateSalesOrderEditPageController',
                controllerAs: 'vm',
                current_app: 'salesorders',
                slateTitle: 'Edit Sales Order - Sales Orders',
            })
            .state('salesOrderShipPage', {
                url: '/salesorders/so/:id/ship/',
                templateUrl: '/partials/salesorders/ship.new.page.html',
                controller: 'SlateSOShipNewPageController',
                controllerAs: 'vm',
                current_app: 'salesorders',
                slateTitle: 'Ship - Sales Orders',
            })
            .state('salesOrderReleasePage', {
                url: '/salesorders/so/:id/release/',
                templateUrl: '/partials/salesorders/releases/release.bulk.page.html',
                controller: 'SlateSOReleaseNewPageController',
                controllerAs: 'vm',
                current_app: 'salesorders',
                slateTitle: 'Release - Sales Orders',
            })

            ;
    }
})();