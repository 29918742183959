(function () {
    "use strict";

    angular
        .module('slate.pos')
        .service('SlatePORequestEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlatePO) {
        var templateUrl = '/partials/pos/porequest.edit.dialog.html';

        var srv = {
            edit: editObj
        };

        return srv;


        function editObj(obj, specs) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    obj: function () {
                        var po = new SlatePO();
                        po.loadFromPayload(angular.copy(obj));
                        return po;
                    },
                    specs: function () {
                        return specs;
                    }
                }
            });

            return modalInstance.result.then(function (results) {
                return results;
            }, function () {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $window, $http, $q, $location, $state, $uibModalInstance, getLinkSrv, SlateAuth,
                            SlatePOSpecs, SlatePOSpec, SlatePOSpecEditSrv,
                            SlateCompanies, SlateLocations, SlateContacts,
                            SlateProjects, SlateProject, SlateRolesSrv, SlateAssignedRoles,
                            SlateFileNode, SlateShipments, SlateShipment, SlateGoods, SlateGood, obj, specs) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.obj = obj;

        // Capture drag and drop events so that when users miss the drag and drop location
        // it doesn't open the tab to the file and lose the form information they had half filled
        // because users will forever be harder to deal with
        $window.addEventListener("dragover",function(e){
            e = e || event;
            e.preventDefault();
        },false);
        $window.addEventListener("drop",function(e){
            e = e || event;
            e.preventDefault();
        },false);

        $location.search('openPO', vm.obj.id);

        if (specs) {
            vm.specs = specs;
        } else {
            vm.specs = new SlatePOSpecs();
            if (vm.obj.id) {
                vm.specs.getList({ porequest: vm.obj.id });
            }
        }

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.saveObjectClose = saveObjectClose;
        vm.cancel = cancel;

        vm.dirty = false;

        vm.companies = new SlateCompanies();
        vm.companies.getList();

        vm.company_locations = new SlateLocations();
        vm.setShipto = setShipto;
        vm.setFreightto = setFreightto;

        vm.vendors = new SlateCompanies();
        vm.vendors.getList({
            vendor: true,
            qualified: true
        });

        vm.staff = new SlateContacts();
        vm.staff.getList({ staff: true });

        vm.files = [];
        vm.removeFile = removeFile;

        vm.cap_prefix = cap_prefix;

        vm.projects = new SlateProjects();
        if (vm.obj.company) {
            vm.projects.getList({
                company: vm.obj.company,
                recursive: true
            });
            vm.company_locations.getList({ company: vm.obj.company });
        }

        vm.addNewSpec = addNewSpec;
        vm.editSpec = editSpec;
        vm.calcSpecTotal = calcSpecTotal;

        vm.hasSelectedSpecs = hasSelectedSpecs;
        vm.createShipment = createShipment;
        vm.gotoShipment = gotoShipment;

        vm.savingShipment = false;

        vm.genWorkOrder = genWorkOrder;

        //vm.createShipment = createMultipleShipment;

        vm.dateIsOpen = false;
        vm.dateOpen = dateOpen;
        vm.weekendsDisabled = weekendsDisabled;

        vm.isComplete = isComplete;

        vm.getShipto = getShipto;

        vm.shipments = new SlateShipments();
        vm.shipmentsLoading = true;
        if(vm.obj.id) {
            vm.shipments.getList({porequest: vm.obj.id}).then(function() {
                vm.shipmentsLoading = false;
            });
        }

        function isComplete() {
            if (!vm.obj.ship_terms) { return false; }
            if (!vm.obj.order) { return false; }


            return true;
        }

        vm.canFullEdit = function () { return true; };

        vm.froalaOptions = {
            toolbarButtons: ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            zIndex: 10,
        };

        function projectSync() {
            var assroles = new SlateAssignedRoles();
            var project = new SlateProject();
            var role_dict = SlateRolesSrv.dict;
            assroles.getList({
                parent_type: 'project',
                parent_fk: vm.obj.project
            }).then(function () {

                angular.forEach(assroles.list, function (item) {
                    if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Sales Manager' && !vm.obj.salesperson) {
                        vm.obj.salesperson = item.member_fk;
                    }
                    if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Project Manager' && !vm.obj.proj_mgr) {
                        vm.obj.proj_mgr = item.member_fk;
                    }
                });

            });
            if (!vm.obj.company) {
                project.loadFromServer(vm.obj.project).then(function () {
                    if (project.parent_type == 'company') {
                        vm.obj.company = project.parent_fk;
                    }
                });
            }

        }
        $scope.$watch('vm.obj.project', function () {
            if (vm.obj.project) {
                projectSync();
            }
        });

        function addNewSpec() {
            var spec = new SlatePOSpec();
            spec.jansy_po = vm.obj.jansy_po;
            spec.client_po = vm.obj.client_po;
            SlatePOSpecEditSrv.edit(spec, {company: vm.obj.company}, vm.obj.project).then(function (newspec) {
                if (vm.obj.revision) { newspec.was_revised = true; }
                vm.specsDirty = true;
                vm.specs.list.push(newspec);
            }, function () {
                //do nothing if canceled?
            });
        }

        function editSpec(spec, $idx) {
            SlatePOSpecEditSrv.edit(spec, {company: vm.obj.company}, vm.obj.project).then(function (newspec) {
                if (spec.id || vm.obj.revision) {
                    newspec.was_revised = true;
                    vm.obj.revision = true;
                }
                vm.specsDirty = true;
                //    vm.specs.getList({porequest: vm.obj.id});
                vm.specs.list[$idx] = newspec;
            }, function () { });
        }

        function hasSelectedSpecs() {
            var hasSelected = false;
            hasSelected = vm.specs.list.some(function (item) {
                if (item.selected) { return true; }
            });
            return hasSelected;
        }

        function createShipment() {
            if(vm.savingShipment) { return false;}
            vm.savingShipment = true;
            var shipment = new SlateShipment();
            var allHavePos = vm.specs.list.filter(function (spec) { return spec.selected; }).every(function (spec) {
                if (spec.jansy_po) { return true; }
            });

            if (!allHavePos && !confirm('Some chosen line items are missing PO#s, are you sure you want to create shipments?')) {
                return false;
            }
            shipment.name = vm.obj.description;
            if (vm.obj.jansy_po) {
                shipment.name = vm.obj.jansy_po + ' - ' + shipment.name;
            }

            shipment.project = vm.obj.project;
            shipment.client = vm.obj.company;
            shipment.order = vm.obj.order;
            shipment.sales_mgr = vm.obj.salesperson;
            shipment.porequest = vm.obj.id;

            vm.specs.list.forEach(function(spec) {
                if(!shipment.ready_date || spec.ready_date > shipment.ready_date) {
                    shipment.ready_date = spec.ready_date;
                }
            });
            var ship_num = 1;
            if(vm.shipments.list && vm.shipments.list.length) { ship_num = vm.shipments.list.length + 1; }
            shipment.name = shipment.name + ' shipment ' + ship_num;
            shipment.name = shipment.name.substring(0,72);

            shipment.saveModel().then(function () {
                var deferred = $q.defer();
                var promises = [];
                vm.specs.list.filter(function (spec) { return spec.selected; }).forEach(function (spec) {
                    var good = new SlateGood();
                    good.parent_type = 'shipment';
                    good.parent_fk = shipment.id;
                    good.name = spec.name.substring(0,72);
                    good.client = vm.obj.company;
                    good.vendor = spec.vendor;
                    good.po_name = spec.jansy_po;
                    good.item_num = spec.jansy_pn;
                    //good.production_date = spec.ready_date;
                    good.qty = spec.qty;
                    //good.notes = spec.notes;
                    promises.push(good.saveModel());
                });
                $q.all(promises).then(function () {
                    var url = '/logistics/shipment/' + shipment.id + '/edit/';
                    vm.savingShipment = false;
                    location.assign($state.href('shipmentEditPage', {id: shipment.id}));
                    $uibModalInstance.close(vm.obj);
                });
                return deferred.promise;
            });
        }

        function gotoShipment(shipment) {
            //$location.path($state.href('shipmentEditPage', {id: shipment.id})).search({});
            var url = '/logistics/shipment/' + shipment.id + '/edit/';
            location.assign($state.href('shipmentEditPage', {id: shipment.id}));

            $uibModalInstance.close(vm.obj);
        }

        function createMultipleShipment() {
            var promises = [];
            var deferred = $q.defer();

            var allHavePos = vm.specs.list.filter(function (spec) { return spec.selected; }).every(function (spec) {
                if (spec.jansy_po) { return true; }
            });
            if (!allHavePos && !confirm('Some chosen line items are missing PO#s, are you sure you want to create shipments?')) {
                return false;
            }
            vm.specs.list.filter(function (spec) { return spec.selected; }).forEach(function (spec) {

                var shipment = new SlateShipment();

                shipment.name = spec.name + ' - ' + vm.obj.description;
                if (spec.jansy_po) {
                    shipment.name = spec.jansy_po + ' - ' + shipment.name;
                }
                shipment.project = vm.obj.project;
                shipment.client = vm.obj.company;
                shipment.order = vm.obj.order;
                shipment.sales_mgr = vm.obj.salesperson;
                shipment.porequest = vm.obj.id;
                shipment.ready_date = spec.ready_date;

                promises.push(shipment.saveModel().then(function () {
                    var good = new SlateGood();
                    good.parent_type = 'shipment';
                    good.parent_fk = shipment.id;
                    good.name = spec.name;
                    good.client = vm.obj.company;
                    good.vendor = vm.obj.vendor;
                    good.po_name = spec.jansy_po;
                    good.item_num = spec.jansy_pn;
                    good.production_date = spec.ready_date;
                    good.qty = spec.qty;
                    good.notes = spec.notes;
                    promises.push(good.saveModel());

                }));
                spec.selected = false;
            });

            $q.all(promises).then(function () {
                vm.shipments.getList({porequest: vm.obj.id}).then(function() {
                    deferred.resolve();
                });
            });
            return deferred.promise;

        }

        function dateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.dateIsOpen = true;
        }

        function weekendsDisabled(data) {
            var date = data.date,
                mode = data.mode;
            return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
        }

        function setShipto(address) {
            var data = "";
            if (address.street1) { data += address.street1 + "\n"; }
            if (address.street2) { data += address.street2 + "\n"; }
            if (address.street3) { data += address.street3 + "\n"; }
            if (address.city) { data += address.city + ","; }
            data += address.state + " " + address.postal;
            if (address.country) { data += "\n" + address.country; }

            vm.obj.shipto = data;
        }

        function setFreightto(fav) {
            vm.obj.freightto = fav.name + "\n" + fav.value;
        }


        $scope.$watch('vm.obj.company', function () {
            if (vm.obj.company) {
                vm.projects.getList({
                    company: vm.obj.company,
                    recursive: true
                });
                vm.selected_location = undefined;
                vm.company_locations.getList({ company: vm.obj.company });
            }
        });

        function removeFile(index) {
            vm.files.splice(index, 1);
        }

        $scope.$watch('vm.filesAdd', function () {

            angular.forEach(vm.filesAdd, function (file) {
                vm.files.push(file);
            });
        });

        function saveObjectClose() {
            var promise;
            if (vm.saving === true) {
                return false;
            }
            promise = saveObject();
            promise.then(function () {
                vm.saving = false;
                $location.search('openPO', null);
                $uibModalInstance.close(vm.obj);
            });
            return promise;

        }

        function saveObject() {
            var deferred = $q.defer();

            if (vm.saving === true) {
                return false;
            }
            vm.errorSaving = false;
            vm.saving = true;

            vm.obj.saveModel().then(function () {
                var filepromise = $q.resolve();
                var promises = [];
                vm.savedFiles = [];

                var specRemove = [];
                angular.forEach(vm.specs.list, function (spec, idx) {
                    spec.porequest = vm.obj.id;
                    spec.sort_order = idx;
                    if (!vm.obj.revision) { spec.was_revised = false; }
                    if (!spec.to_delete) {
                        promises.push(spec.saveModel());
                    } else if (spec.id) {
                        promises.push(spec.deleteModel());
                    }
                });


                angular.forEach(vm.files, function (file) {
                    var newFile = new SlateFileNode();
                    var payload;

                    if (vm.obj.company) {
                        payload = {
                            parent_type: 'company',
                            parent_fk: vm.obj.company,
                            path: 'Purchase Orders/' + vm.obj.description,
                            filename: file.name,
                            description: file.description,
                        };
                    }

                    if (payload) {
                        newFile.loadFromPayload(payload);

                        filepromise = filepromise.then(function () {
                            return newFile.saveModel(file).then(function () {
                                vm.savedFiles.push(newFile);
                                var parent_fk = vm.obj.id;
                                var parent_type = 'porequest';
                                // if (vm.obj.order) {
                                //     parent_fk = vm.obj.order;
                                //     parent_type = 'order';
                                // }

                                var linkedNode = new SlateFileNode();
                                linkedNode.loadFromPayload({
                                    mimetype: newFile.mimetype,
                                    parent_type: parent_type,
                                    parent_fk: parent_fk,
                                    path: '',
                                    filename: file.name,
                                    core_id: newFile.core_id,
                                    description: file.description,
                                });
                                linkedNode.saveModel();
                            });
                        });
                    } else {
                        payload = {
                            parent_type: 'sampletracker',
                            parent_fk: vm.obj.id,
                            path: '',
                            filename: file.name,
                            description: file.description,
                        };
                        newFile.loadFromPayload(payload);
                        filepromise = filepromise.then(function () {
                            return newFile.saveModel(file).then(function () {
                                vm.savedFiles.push(newFile);
                            });
                        });
                    }

                });
                promises.push(filepromise);
                $q.all(promises).then(function () {
                    vm.saving = false;
                    vm.POSpecFrom.$setPristine();
                    vm.specsDirty = false;
                    vm.files = [];
                    vm.specs.getList({ porequest: vm.obj.id });
                    return deferred.resolve(vm.obj);
                },function () {
                    vm.saving = false;
                    vm.errorSaving = true;
                });
            }, function () {
                vm.saving = false;
                vm.errorSaving = true;
            });
            return deferred.promise;
        }

        function cancel() {
            if (vm.specsDirty || vm.files.length || vm.POSpecFrom.$dirty) {
                if (!confirm('You have not saved this SO/PO request. Are you sure you want to close without saving?')) {
                    return false;
                }
            }
            $location.search('openPO', null);
            if (vm.dirty) {
                $uibModalInstance.close(vm.obj);
            } else {
                $uibModalInstance.dismiss('cancel');
            }

        }

        function cap_prefix() {

            vm.obj.client_code = vm.obj.client_code.toUpperCase();
        }

        function calcSpecTotal() {
            var total = new Big(0);
            vm.specs.list.filter(function(item) { return !item.deleted; }).forEach(function (spec) {
                var val = new Big(spec.getCostTotal());

                total = total.add(val);
            });
            return total.toFixed(5);
        }

        function getShipto(shipto) {
            return '<pre>' + shipto + '</pre>';
        }

        function WODownload() {
            var url = API_URL + 'pos/genworkorder/';
            var items = [];

            if(!vm.obj.id) { return ''; }
            if(!vm.hasSelectedSpecs() || vm.specsDirty || vm.files.length || vm.POSpecFrom.$dirty) { return ''; }

            vm.specs.list.forEach(function(item) {
                if(item.selected) { items.push('spec=' + item.id); }
            });
            url = url + '?' + items.join('&');
            return url;
        }

        function genWorkOrder() {
            var url = API_URL + 'pos/genworkorder/';
            var items = [];
            if(vm.woSaving) { return false; }
            if(!vm.obj.id) { return ''; }
            if(vm.specsDirty || vm.files.length || vm.POSpecFrom.$dirty) { return ''; }

            vm.woSaving = true;
            vm.woError = false;

            return $http.get(url, { params: {porequest: vm.obj.id} }).then(function() {
                vm.woSaving = false;
                $scope.$broadcast('updateFiles', 'porequest', vm.obj.id);
            }, function() {
                vm.woSaving = false;
                vm.woError = true;

            });
        }
    }

})();
