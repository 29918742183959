(function () {
    'use strict';

    angular
        .module('slate.library.auth')
        .factory('SlateUser', User);


    /** @ngInject */
    function User($http, $q, ngToast, $cookies, SlateCompanies) {
        const apiUrl = `${API_URL}auth/`;

        /* beautify preserve:start */
        const fields = [
            { name: 'contact_id', def: null, readonly: true },
            { name: 'contact_slug', def: null, readonly: true },
            { name: 'contact_email', def: null, readonly: true },
            { name: 'contact_name', def: null, readonly: true },
            { name: 'token', def: false, readonly: true },
            { name: 'exp', def: false, readonly: true },
            { name: 'remote_ip', def: false, readonly: true },
            {
                name: 'settings', def: {}, type: 'json', readonly: true
            },
            { name: 'is_staff', def: false, readonly: true },
            { name: 'is_admin', def: false, readonly: true },
            { name: 'homepage', def: false, readonly: true }
        ];
        /* beautify preserve:end */

        // instantiate our initial object
        const model = function () {
            const self = this;

            self.clear();

            return self;
        };

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.getToken = getToken;
        model.prototype.getUserFromToken = getUserFromToken;
        model.prototype.checkToken = checkToken;
        model.prototype.changePassword = changePassword;
        model.prototype.setUserContext = setUserContext;

        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;
            });
            self.permissions = [];
            // self.companies = new SlateCompanies();

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;

            if (typeof payload !== 'object') {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                self[field.name] = payload[field.name];
                if (field.type === 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                        // pass
                    }
                }
            });

            return self;
        }

        function getToken(email, password, remember, type) {
            /* jshint validthis: true */
            const self = this;
            let promise = null;
            let login_type = '';
            const deferred = $q.defer();

            if (typeof (type) !== 'undefined') {
                login_type = type;
            }

            const userData = {
                username: email,
                password,
                remember: false,
                classes: '',
                login_type
            };

            try {
                userData.classes = document.getElementsByTagName('html')[0].classList.value;
            } catch (ex) {
                // pass
            }

            if (remember) {
                userData.remember = true;
            }

            if (typeof password === 'undefined' || typeof email === 'undefined') {
                deferred.reject(self);
                return deferred.promise;
            }
            if (!email) {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.post(`${apiUrl}get-token/`, userData);
            promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;
                self.groups = response.data.groups;
                // self.companies.getList({'mine':true});
                self.setUserContext();
            }, (response) => {
                self.clear();
            });

            return promise;
        }

        function getUserFromToken(token) {
            /* jshint validthis: true */
            const self = this;
            let promise = null;
            const deferred = $q.defer();
            const userData = {};

            if (typeof self.token === 'undefined' || self.token.length < 1) {
                deferred.reject(self);
                return deferred.promise;
            }

            try {
                userData.classes = document.getElementsByTagName('html')[0].classList.value;
            } catch (ex) {
                // pass
            }
            promise = $http.post(`${apiUrl}check-token/`, userData, {
                headers: {
                    Authorization: token
                }
            });
            promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;
                self.groups = response.data.groups;
                // self.companies.getList({'mine':true});
                self.setUserContext();
            }, () => {
                self.clear();
            });

            return promise;
        }

        function checkToken(renew) {
            /* jshint validthis: true */
            const self = this;
            let promise = null;
            const url = `${apiUrl}check-token/`;
            const userData = {};

            try {
                userData.classes = document.getElementsByTagName('html')[0].classList.value;
            } catch (ex) {
                // pass
            }

            promise = $http.get(url, userData);
            promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;
                self.groups = response.data.groups;
                // self.companies.getList({'mine':true});
                self.setUserContext();
            }, () => {
                ngToast.create({
                    className: 'danger',
                    content: 'You have been logged out! Click the reload button to relogin.',
                    dismissOnTimeout: false
                });
                self.clear();
            });

            return promise;
        }

        function changePassword(currentPassword, password) {
            /* jshint validthis: true */
            const self = this;
            let promise = null;
            const deferred = $q.defer();

            const data = {
                password,
                current: currentPassword
            };

            promise = $http.post(`${apiUrl}change-password/`, data);

            promise.then((response) => {
                deferred.resolve(self);
            }, (response) => {
                deferred.reject(response.data);
            });

            return deferred.promise;
        }

        function setUserContext() {
            /* jshint validthis: true */
            const self = this;
            if (!self) { return false; }

            if (FRONTEND_SENTRY_DSN && window.Sentry) {
                Sentry.setUser({
                    email: self.contact_email,
                    id: self.contact_id,
                    name: self.contact_name
                });
            }

            return true;
        }
    }
})();
