(function () {
    "use strict";

    angular
        .module('slate.app')
        .directive('slateFocus', function () {
            return {
                restrict: 'A',
                link: function (scope, element) {
                    element.find('input')[0].focus();
                }
            };
        });

})();