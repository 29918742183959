(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetActiveUsers', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.active_users.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ListController($scope, $http, $interval, SlateAuth, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var speed;
        var interval;

        speed = vm.settings.speed || 360000;
        
        vm.active_users = [];

        interval = $interval(function() {
            getData();
        }, speed);

        getData();

        function getData() {

            $http.get(API_URL + 'auth/active/').then(function(response) {
                
                vm.active_users = response.data;
            });
        }
    }


})();
