(function () {
    'use strict';

    angular
        .module('slate.inspections')
        .service('SlateBookingEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateBooking) {
        const templateUrl = '/partials/inspections/booking.edit.dialog.html';

        const srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj) {
            let modalInstance;

            modalInstance = $uibModal.open({
                templateUrl,
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj() {
                        const booking = new SlateBooking();
                        if (!obj || !obj.id) {
                            return booking;
                        }
                        return booking.loadFromServer(obj.id);
                    }
                }
            });

            return modalInstance.result.then((results) => obj, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $http, $q, $uibModalInstance, SlateAuth, SlateInspections, QIMAProduct, QIMAProductReference, obj) {
        /* jshint validthis: true */
        const vm = this;

        vm.obj = obj;
        vm.auth = SlateAuth;

        vm.saving = false;
        vm.errorSaving = false;
        vm.toggleItem = toggleItem;
        vm.createBooking = createBooking;
        vm.getLineItems = getLineItems;
        vm.isComplete = isComplete;
        vm.detailsComplete = detailsComplete;
        vm.productRefsComplete = productRefsComplete;
        vm.getInspections = getInspections;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.inspectionFields = [
            'jansy_po', 'description', 'status', 'qc_date'
        ];

        vm.filters = {
            page: 1,
            count: 25,
            order_by: 'qc_date',
            unbooked: 'true',
            status: ['New', 'Scheduled', 'In Process', 'Fail']
        };

        vm.inspections = new SlateInspections();

        function getLineItems(inspection) {
            // if (inspection.line_items) { return; }

            inspection.line_items = inspection.line_items.map((item) => {
                const product = new QIMAProduct();
                product.name = `${item.po_lineitem_info.component_info.composite_num}/${item.po_lineitem_info.component_info.name}`;
                product.name = product.name.slice(0, 74);
                product.inspection_lineitem = item.id;
                product.inspection_lineitem_info = item;

                const ref = new QIMAProductReference();
                ref.po_number = inspection.purchase_order_info.jansy_po;
                ref.sku_code = item.po_lineitem_info.component_info.composite_num;
                ref.name = `${item.po_lineitem_info.component_info.composite_num}/${item.po_lineitem_info.component_info.name}`;
                ref.qty = item.qty;

                product.refs = [ref];
                return product;
            });

            // inspection.line_items = inspection.line_items.map(function (item) {
            //     var ref = new QIMAProductReference();
            //     ref.po_number = inspection.purchase_order_info.jansy_po;
            //     ref.sku_code = item.po_lineitem_info.component_info.composite_num;
            //     ref.name = item.po_lineitem_info.component_info.name;
            //     ref.qty = item.qty;
            //     ref.inspection_lineitem = item.id;
            //     ref.inspection_lineitem_info = item;
            //     ref.custom_field_1 = item.id;
            //     return ref;
            // });
            // var lineItems = new SlateInspectionLineItems();
            // inspection.loadingItems = true;
            // lineItems.getList({ inspection: inspection.id }).then(function () {
            //     inspection.loadingItems = false;
            // }, function () {

            // });
        }

        $scope.$watch('vm.qcDateStart', () => {
            if (vm.qcDateStart) {
                vm.filters.qc_date_start = dateFns.format(vm.qcDateStart, 'YYYY-MM-DD');
            } else {
                vm.filters.qc_date_start = null;
            }
        });
        $scope.$watch('vm.qcDateEnd', () => {
            if (vm.qcDateEnd) {
                vm.filters.qc_date_end = dateFns.format(vm.qcDateEnd, 'YYYY-MM-DD');
            } else {
                vm.filters.qc_date_end = null;
            }
        });

        function getInspections() {
            vm.loadingInspections = true;
            vm.inspections.getList(vm.filters).then(() => {
                vm.loadingInspections = false;
            }, () => {
                vm.loadingInspections = false;
            });
        }

        $scope.$watchCollection(() => vm.filters, (value) => {
            if (vm.filters.vendor) {
                getInspections();
            }
            vm.obj.vendor = vm.filters.vendor;
        });


        function detailsComplete() {
            if (!vm.bookingDetailsForm.$valid) { return false; }
            return true;
        }

        function productRefsComplete() {
            if (!vm.obj.products[0].refs.length) { return false; }
            return true;
        }

        function isComplete() {
            if (!vm.obj.products[0].refs.length) { return false; }
            if (!vm.obj.vendor) { return false; }
            return true;
        }

        function setQCDate() {
            let minDate = vm.obj.products[0].inspection_lineitem_info.qc_date;
            vm.obj.products.forEach((product) => {
                const qcDate = product.inspection_lineitem_info.qc_date;
                if (dateFns.isBefore(qcDate, minDate)) {
                    minDate = qcDate;
                }
            });
            if (minDate) {
                vm.obj.service_date = minDate;
                const shipDate = dateFns.addDays(vm.obj.service_date, 2);
                vm.obj.ship_date = shipDate;
            }
        }

        function saveObject() {
            vm.saving = true;
            setQCDate();
            vm.obj.saveModel().then(() => {
                vm.saving = false;
                $uibModalInstance.close();
            }, () => {
                vm.error = true;
                vm.saving = false;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function toggleItem(item) {
            if (item.selected) {
                vm.obj.products.push(item);
            } else {
                const index = vm.obj.products.indexOf(item);
                vm.obj.products.splice(index, 1);
            }
        }


        function createBooking() {
            SlateBookingEditSrv.edit().then(() => {

            }, () => {

            });
        }
    }
})();
