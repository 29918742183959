(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .directive('slatePurchaseOrderList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/purchaseorders/purchaseorder.list.dir.html',
            scope: {
                modelFilters: '=?filters',
                showNew: '=?',
                hideFilters: '=?',
                fields: '=?',
                showTotals: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlatePurchaseOrders, SlatePurchaseOrder, getLinkSrv, SlateDeleteObjSrv, SlateSettings) {
        /* jshint validthis: true */
        var vm = this;

        var modelPromise;
        var filesPromise;
        vm.auth = SlateAuth;

        vm.get_url = get_url;

        vm.addNew = addNew;

        vm.deletePO = deletePO;

        vm.getStyle = getStyle;

        var multiSearchFields = ['status', 'shipvia', 'ship_terms', 'category', 'material'];

        if (!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'status',
                'name',
                'jansy_po',
                'client_po',
                'company',
                'vendor',
                'warehouse',
                'proj_mgr',
                'sales_mgr',
                'price_total',
                // 'sell_total',
                // 'margin',
                'date',
                'desired_date',
                'fully_received',
                'created',
                'created_by'
            ];
            /* beautify preserve:end */
        }

        vm.getLink = getLinkSrv;
        vm.loading = true;
        vm.filters = {
            page: 1,
            count: 25,
        };
        if (!vm.hideFilters) {
            angular.extend(vm.filters, {
                status: null,
                startDate: $location.search().startDate,
                endDate: $location.search().endDate,
                startDesiredDate: $location.search().startDesiredDate,
                endDesiredDate: $location.search().endDesiredDate,
            });
        }

        multiSearchFields.forEach(function (field) {
            vm.filters[field] = null;
        });

        var basicStatus = $location.search().status;

        if (!vm.modelFilters) { vm.modelFilters = {}; }
        angular.extend(vm.filters, {
            q: $location.search().q,
        }, vm.modelFilters, $location.search(), vm.filters);
        if (typeof vm.filters.page == 'string') { vm.filters.page = parseInt(vm.filters.page); }
        if (typeof vm.filters.count == 'string') { vm.filters.count = parseInt(vm.filters.count); }
        if (typeof vm.filters.jansy_po == 'string') { vm.filters.jansy_po = parseInt(vm.filters.jansy_po); }
        if (typeof vm.filters.order_num == 'string') { vm.filters.order_num = parseInt(vm.filters.order_num); }
        if (typeof vm.filters.version == 'string') { vm.filters.version = parseInt(vm.filters.version); }
        var parts;
        if (vm.filters.startDate) {
            parts = vm.filters.startDate.split('-');
            vm.start = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
        }
        if (vm.filters.endDate) {
            parts = vm.filters.endDate.split('-');
            vm.end = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
        }
        $scope.$watch('vm.start', function () {
            if (vm.start) {
                vm.filters.startDate = vm.start.getFullYear() + '-' + (vm.start.getMonth() + 1) + '-' + vm.start.getDate();
            } else {
                vm.filters.startDate = null;
            }
        });
        $scope.$watch('vm.end', function () {
            if (vm.end) {
                vm.filters.endDate = vm.end.getFullYear() + '-' + (vm.end.getMonth() + 1) + '-' + vm.end.getDate();
            } else {
                vm.filters.endDate = null;
            }
        });

        if (vm.filters.startDesiredDate) {
            parts = vm.filters.startDesiredDate.split('-');
            vm.startDesired = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
        }
        if (vm.filters.endDesiredDate) {
            parts = vm.filters.endDesiredDate.split('-');
            vm.endDesired = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
        }
        $scope.$watch('vm.startDesired', function () {
            if (vm.startDesired) {
                vm.filters.startDesiredDate = vm.startDesired.getFullYear() + '-' + (vm.startDesired.getMonth() + 1) + '-' + vm.startDesired.getDate();
            } else {
                vm.filters.startDesiredDate = null;
            }
        });
        $scope.$watch('vm.endDesired', function () {
            if (vm.endDesired) {
                vm.filters.endDesiredDate = vm.endDesired.getFullYear() + '-' + (vm.endDesired.getMonth() + 1) + '-' + vm.endDesired.getDate();
            } else {
                vm.filters.endDesiredDate = null;
            }
        });

        multiSearchFields.forEach(function (field) {
            if (vm.filters[field] && typeof vm.filters[field] === 'string') {
                vm.filters[field] = [vm.filters[field]];
            }
        });

        vm.poList = new SlatePurchaseOrders();

        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;

        vm.getSellTotal = getSellTotal;
        vm.getPriceTotal = getPriceTotal;
        vm.getMarginTotal = getMarginTotal;

        function get_url() {
            var url = API_URL + 'purchaseorders/po/';
            if ($window.location.search.length) {
                return url + $window.location.search + '&download=true';
            } else {
                return url + '?download=true';
            }
        }

        function addNew() {
            var url = "/purchaseorders/po/new/";
            var params = {
                order: vm.modelFilters.order,
            };
            var queryString;
            queryString = queryString = Object.keys(params).map(function (key) {
                return key + '=' + params[key];
            }).join('&');
            $location.url(url + '?' + queryString);
        }

        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            updateFromFilter();
        });
        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function (value, key) {
                if (key == 'page' || key == 'count' || key == 'q') {
                    return false;
                }
                if (typeof vm.filters[key] == 'undefined' || vm.filters[key] === undefined || vm.filters[key] === null) {
                    return false;
                }
                if (vm.filters[key] == vm.modelFilters[key]) {
                    return false;
                }
                if (typeof vm.filters[key] == 'string' && vm.filters[key].length === 0) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;

            });
            return hasData;
        }

        function updateFromFilter() {
            var updatePromise;

            vm.loading = true;
            if (typeof vm.filters.status == 'string') {
                vm.filters.status = [vm.filters.status];
            }

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            updatePromise = vm.poList.getList(vm.filters);
            updatePromise.then(function () {
                vm.loading = false;
            });
        }

        function deletePO(po) {
            SlateDeleteObjSrv.delete('purchaseorder', po.id).then(function () {
                updateFromFilter();
            });
        }

        function getPriceTotal() {
            var total = Big(0.0);
            vm.poList.list.forEach(function (item) {
                if (!item.deleted && !(item.status == 'Cancelled' || item.status == 'Draft')) {
                    total = total.add(item.price_total);
                }
            });
            return total.toFixed(5);
        }

        function getSellTotal() {
            var total = Big(0.0);
            vm.poList.list.forEach(function (item) {
                if (!item.deleted && !(item.status == 'Cancelled' || item.status == 'Draft')) {
                    total = total.add(item.sell_total);
                }
            });
            return total.toFixed(5);
        }

        function getMarginTotal() {
            var sell = getSellTotal();
            var cost = getPriceTotal();
            var total = Big(0.0);
            if (sell == 0.00000) { return 0.0; }
            total = total.add(sell).minus(cost);
            total = total.div(sell);
            total = total.times(100.0);
            return total.toFixed(2);
        }

        function getStyle(field) {
            if (field == 'name') {
                return {
                    'width': '100%',
                    'overflow': 'hidden',
                    'white-space': 'nowrap',
                    'text-overflow': 'ellipsis'
                };
            }

            return {
                'overflow': 'hidden',
                'white-space': 'nowrap',
                'text-overflow': 'ellipsis',
            };
        }

        vm.categories = [];
        vm.materials = [];
        SlateSettings.getConfig('material_categories').then(function (config) {
            vm.categories = config;
        });
        SlateSettings.getConfig('materials').then(function (config) {
            vm.material_list = config;
            getMatList();
        });

        $scope.$watch('vm.filters.category', function () { getMatList(); });
        $scope.$watch('vm.filters.material_list.value', function () { getMatList(); });

        function getMatList() {
            var newlist = [];
            if (!vm.material_list || !vm.material_list.value) { return []; }

            if (!vm.filters.category || !vm.filters.category.length) {
                vm.materials = vm.material_list.value;
                return vm.material_list.value;
            }
            angular.forEach(vm.material_list.value, function (item) {
                if (vm.filters.category.indexOf(item.category) > -1) {
                    newlist.push(item);
                }
            });
            vm.materials = newlist;
            if (!vm.materials.length) {
                vm.materials = vm.material_list.value;
            }

            return vm.materials;
        }
    }


})();
