(function () {
    "use strict";

    angular
        .module('slate.library.contacts')
        .factory('SlateContacts', ModelList)
        .factory('SlateContact', ModelDetail);

    var apiUrl = API_URL + 'contacts/contacts/';

    function ModelList($http, $q, $cacheFactory, $timeout, SlateContact) {
        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.saveAll = saveAll;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters, cache) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            var cacheObject = false;
            if (cache) {
                cacheObject = $cacheFactory.get('slate-contacts');
                $timeout(function () {
                    cacheObject.removeAll();
                }, 60000);
            }

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters,
                cache: cacheObject
            });

            promise.then(function (response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function (item) {
                    var itemModel = new SlateContact();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveAll(defaults) {
            /* jshint validthis: true */
            var self = this;
            var promises = [];
            var i = 0;
            angular.forEach(self.list, function (model) {
                angular.forEach(defaults, function (value, field) {
                    model[field] = value;
                });
                model.order = i;
                promises.push(model.saveModel());
                i = i + 1;
            });

            return $q.all(promises);
        }

    }

    function ModelDetail($http, $q, $cacheFactory, $timeout, SlateAuth, SlateLocations, SlateContactMethods) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            { name: 'id', def: null, readonly: true },
            { name: 'slug', def: null, readonly: true },
            { name: 'email', def: null },
            { name: 'full_name', def: null },
            { name: 'display_name', def: null },
            { name: 'timezone', def: null },
            { name: 'settings', def: {}, type: 'json' },
            { name: 'saving_dashboard', def: false },
            { name: 'receive_unread_notifications_emails', def: true },

            { name: 'user_type', def: 'slate' },
            { name: 'homepage', def: 'dashboard' },

            { name: 'is_active', def: false },

            { name: 'is_house_account', def: false },
            { name: 'exclude_sales_budgets', def: false },

            { name: 'updated', def: null, readonly: true },
            { name: 'created', def: null, readonly: true },
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        model.prototype.getLocations = getLocations;
        model.prototype.getContactMethods = getContactMethods;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function (field) {
                self[field.name] = field.def;
            });
            self.locations = new SlateLocations();
            self.contactmethods = new SlateContactMethods();

            deferred.resolve(self);

            return deferred.promise;
        }

        function getLocations() {
            /* jshint validthis: true */
            var self = this;
            if (!self.locations) {
                self.locations = new SlateLocations();
            }
            return self.locations.getList({
                contact: self.id
            });
        }

        function getContactMethods() {
            /* jshint validthis: true */
            var self = this;
            if (!self.contactmethods) {
                self.contactmethods = new SlateContactMethods();
            }
            return self.contactmethods.getList({
                contact: self.id
            });
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (SlateAuth.user.contact_id == payload.id && typeof self.dashboard_config == 'undefined') {
                fields.push({
                    name: 'dashboard_config',
                    def: [],
                    type: 'json'
                });
            }


            if (typeof payload !== "object") {
                console.log('payload must be an object', payload);
                return $q.reject(self);
            }

            angular.forEach(fields, function (field) {
                self[field.name] = payload[field.name];
                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) { }
                }
            });
            if (!self.locations) {
                self.locations = new SlateLocations();
            }
            if (!self.contactmethods) {
                self.contactmethods = new SlateContactMethods();
            }

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id, ignoreMethods, cache) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load from server without id');
                return $q.reject(self);
            }

            var cacheObject = false;
            if (cache) {
                cacheObject = $cacheFactory.get('slate-contacts');
                $timeout(function () {
                    cacheObject.removeAll();
                }, 60000);
            }

            promise = $http.get(modelUrl, {
                cache: cacheObject
            });

            promise.then(function (response) {
                var promises = [];
                var loadPromise;

                loadPromise = self.loadFromPayload(response.data.payload);

                return loadPromise.then(function () {
                    self.permissions = response.data.permissions;
                    if (!ignoreMethods) {
                        promises.push(self.getLocations());
                        promises.push(self.getContactMethods());
                    }

                    return $q.when(promises).then(function () {

                        deferred.resolve(self);
                    });

                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }
            if (!self.photo && self.actPhoto) {
                self.photo = self.actPhoto.id;
            }

            angular.forEach(fields, function (field) {
                if (!field.readonly && typeof (self[field.name]) != 'undefined' && self[field.name] !== null) {
                    data[field.name] = self[field.name];
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name], function (key, value) {
                            if (key == "$$hashKey") {
                                return undefined;
                            }
                            return value;
                        });
                    }
                }
            });

            var has_primary_email = false;
            var has_primary_phone = false;


            angular.forEach(self.contactmethods.list, function (cm) {
                if (cm.method == 'email' && cm.is_primary === true) {
                    if (!has_primary_email) {
                        has_primary_email = true;
                    } else {
                        cm.is_primary = false;
                    }
                }
                if (cm.method == 'phone' && cm.is_primary === true) {
                    if (!has_primary_phone) {
                        has_primary_phone = true;
                    } else {
                        cm.is_primary = false;
                    }
                }
            });
            angular.forEach(self.contactmethods.list, function (cm) {
                if (cm.method == 'email' && !has_primary_email) {
                    cm.is_primary = true;
                    has_primary_email = true;
                }
                if (cm.method == 'phone' && !has_primary_phone) {
                    cm.is_primary = true;
                    has_primary_phone = true;
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function (response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                if (self.locations) {
                    promises.push(self.locations.saveAll({
                        'parent_type': 'contact',
                        'parent_id': self.id
                    }));
                }

                if (self.contactmethods) {
                    promises.push(self.contactmethods.saveAll({
                        'parent_type': 'contact',
                        'parent_fk': self.id
                    }));
                }

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function (response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
