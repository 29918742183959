(function () {
    "use strict";

    angular
        .module('slate.invoices')
        .directive('slateInvoiceEmailListDetails', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/invoices/invoices.emails.list.details.dir.html',
            scope: {
                invEmailID: '=email',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(SlateShipment, $scope, $timeout, $state, $window, $location, $q, $http, $translate, $interval, getLinkSrv, SlateInvoiceEmail) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;

        vm.email = new SlateInvoiceEmail();

        vm.email.loadFromServer(vm.invEmailID).then(function() {
            vm.loading = false;
        });

    }

})();
