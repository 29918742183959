(function () {
    "use strict";

    angular
    .module('slate.billings')
    .service('SlateBillingApprovalSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateBilling) {
        var templateUrl = '/partials/billings/billing.approval.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(item, salesOrder) {
            var modalInstance;
            if (item.status != 'Approved') { return $q.resolve(); }

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj: function () {
                        return item;
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance,  obj, SlateSettings, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;

        vm.item = obj;

        vm.isComplete = isComplete;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        
        function saveObject() {
            $uibModalInstance.close();
        }
        
        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }
            
        var idx = Math.floor(Math.random() * vm.item.line_items.length);
        vm.lineitem = vm.item.line_items[idx];
        function isComplete() {
            if (!vm.item_qty || vm.item_qty != vm.lineitem.qty) { return false; }
            if (!(vm.item.confirmed_totals && vm.item.confirmed_responsible)) { return false; }
            return true;
        }

        


    }



})();
