(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportOrderHolds', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.order_hold.dir.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $http, $location, $httpParamSerializer, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'reports/order_holds/';
        vm.getLink = getLinkSrv;

        var modelPromise;

        vm.getDownloadUrl = getDownloadUrl;
        vm.updateFromFilter = updateFromFilter;

        vm.data = [];
        vm.filters = {};
        vm.loading = true;

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        function updateFromFilter() {
            vm.loading = true;

            if(vm.filters.on_hold) {
                $location.replace().search('on_hold', vm.filters.on_hold);
            } else {
                $location.replace().search('on_hold', null);
            }
            if(vm.filters.on_system_hold) {
                $location.replace().search('on_system_hold', vm.filters.on_system_hold);
            } else {
                $location.replace().search('on_system_hold', null);
            }

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
            });

            modelPromise.then(function(resp) {
                vm.data = resp.data.payload;
                vm.loading = false;
            });
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }
    }
})();