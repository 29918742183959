(function () {
    "use strict";

    angular
        .module('slate.salesbudgets')
        .directive('slateSalesBudgetForm', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/salesbudgets/salesbudget.edit.dir.html',
            scope: {
                company_id: '=company',
                budgets: '=',
                start_date: '=?start',
                complete: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function ListController(
        $scope,
        $http,
        getLinkSrv,
        SlateAuth,
        SlateSalesBudgets
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;
        
        
        vm.budgets = new SlateSalesBudgets();
        vm.canEdit = canEdit;

        vm.requestNewBudget = requestNewBudget;

        vm.getHistoryForBudget = getHistoryForBudget;
        
        $scope.$watch('vm.company_id', function (old_val, new_val) {
            if (vm.company_id) { loadBudgets(); }
        });

        $scope.$watch('vm.start_date', function (old_val, new_val) {
            if (vm.company_id) { loadBudgets(); }
        });

        function canEdit(budget) {
            if(budget.approved) { return false; }
            if(vm.auth.perms.salesbudgets_can_update_all) { return true;}
            if(budget.sales_mgr_info.is_house_account && vm.auth.perms.salesbudgets_can_update_house) { return true;}
            if(vm.auth.user.contact_id == budget.sales_mgr) {return true; }

            return false;
        }

        $scope.$watch(function() { return vm.budgets.list; }, function(newCollection, oldCollection) {
            if(!vm.auth.perms.salesbudgets_can_update_all) { return false; }
            console.log('budgets list changed');
            console.log(newCollection);
            console.log(oldCollection);
            if(!oldCollection.length) { return false; }
            newCollection.forEach(function(item, idx) {
                console.log(oldCollection);
                if(item.value != oldCollection[idx].value && !item.notes.includes('Changed') ) {
                    var now = new Date().toJSON().slice(0,10);
                    var notes = '';
                    if(item.notes) { notes = item.notes; }
                    item.notes = notes + ' Changed by ' + vm.auth.user.contact_name + ' on ' + now;
                    item.notes = item.notes.trim();
                }
            });
        }, true);
        
        function loadBudgets() {
            var running_year, running_month, start_date;

            if(!vm.start_date) {
                vm.start_date = new Date();
                vm.start_date = new Date(vm.start_date.getFullYear(), 0, 1);
            }
            
            running_year = vm.start_date.getFullYear();
            running_month = vm.start_date.getMonth() + 1;
            start_date = running_year + '-' + running_month.toString().padStart(2, '0') + '-01';
            
            vm.budgets.getList({company: vm.company_id, active: true, start_date: start_date}).then(function() {
                
            });
        }

        function requestNewBudget() {
            var apiUrl = API_URL + 'salesbudgets/request-new/';
            
            vm.saving = true;

            $http.post(apiUrl, {company: vm.company_id, start:vm.start_date.getFullYear()+'-'+ (vm.start_date.getMonth() + 1)}).then(function() {
                vm.saving = false;
                loadBudgets();
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function getHistoryForBudget(budget) {
            var month = budget.month.getFullYear() + '-' + (budget.month.getMonth()+1).toString().padStart(2, '0');
            
            if(!vm.budgets.histories[budget.company] || !vm.budgets.histories[budget.company].items[month]) { return {
                open_so_value: 0.0,
                previous_year_value: 0.0,
            };}
            return vm.budgets.histories[budget.company].items[month];
        }

    }

})();
