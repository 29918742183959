(function () {
    'use strict';

    angular
        .module('slate.app')
        .controller('SlateController', SlateController);


    function SlateController($rootScope, $scope, $interval, SlateAuth, SlateGetIP, SlateVersionWatch, SidebarSrv, SlateSFLinkage) {
        $scope.ip = SlateGetIP;
        $scope.verwatch = SlateVersionWatch;
        $scope.sidebarsrv = SidebarSrv;

        $scope.sf_link = SlateSFLinkage;

        $rootScope.title = 'Home';
        $rootScope.auth = SlateAuth;
        if (DEBUG === 'True' || DEBUG === true) { $rootScope.DEBUG = true; }

        $rootScope.$on('auth_servererror', (event, data) => {

        });

        function holidayWallpaper() {
            const current_date = new Date();
            const current_month = current_date.getMonth() + 1;
            const current_day = current_date.getDate();
            const current_year = current_date.getFullYear();

            // Halloween
            if (current_month === 10 && current_day > 7) {
                const random_number = Math.floor(Math.random() * 4) + 1;
                document.body.classList.add(`halloween${random_number}`);
            }

            function thanksgiving(theyear) {
                // US thanksgiving is the 4th thursday of november
                const firstDayOfNov = new Date(theyear, 10, 1).getDay();
                const turkyDay = 22 + ((11 - firstDayOfNov) % 7);
                return turkyDay;
            }

            // Thanksgiving
            if (current_month === 11 && current_day > 7 && current_day <= (thanksgiving(current_year) + 1)) {
                const random_number = Math.floor(Math.random() * 6) + 1;
                document.body.classList.add(`thanksgiving${random_number}`);
            }

            // Christmas
            if (current_month === 12 && current_day > 7 && current_day <= 26) {
                const random_number = Math.floor(Math.random() * 6) + 1;
                document.body.classList.add(`xmas${random_number}`);
            }
        }

        holidayWallpaper();
        $interval(holidayWallpaper, 3600000);
    }

    if (FRONTEND_SENTRY_DSN && window.Sentry) {
        angular
            .module('slate.app')
            .factory('logTimeTaken', [function () {
                const logTimeTaken = {
                    request(config) {
                        config.requestTimestamp = new Date().getTime();
                        return config;
                    },
                    response(response) {
                        let time;
                        response.config.responseTimestamp = new Date().getTime();
                        time = response.config.responseTimestamp - response.config.requestTimestamp;
                        time /= 1000.0;
                        if (time > 45 && Sentry && Sentry.getContext) {
                            console.log(`long running request: ${time} seconds`, response.config);
                            Sentry.withScope((scope) => {
                                scope.setExtra('request_config', response.config);
                                scope.setExtra('seconds', time);
                                Sentry.captureMessage('Long request', {
                                    level: 'warning' // one of 'info', 'warning', or 'error'
                                });
                            });
                        }

                        return response;
                    }
                };
                return logTimeTaken;
            }]);
        angular
            .module('slate.app')
            .config(['$httpProvider',
                function ($httpProvider) {
                    $httpProvider.interceptors.push('logTimeTaken');
                }]);
    }

    angular
        .module('slate.app')
        .directive('scrollToBottom', ($timeout) => ({
            scope: {
                scrollToBottom: '='
            },
            restrict: 'A',
            link(scope, element) {
                scope.$watchCollection('scrollToBottom', (newVal) => {
                    if (newVal) {
                        $timeout(() => {
                            element[0].scrollTop = element[0].scrollHeight;
                        }, 0);
                    }
                });
            }
        }));
})();
