(function() {
    "use strict";

    angular
        .module('slate.auth')
        .directive('slateChangePasswordBox', loginbox);

    function loginbox() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/auth/auth.changepassword.dir.html',
            scope: {

            },
            controller: passwordController,
            controllerAs: 'vm',
            bindToController: true,
            link: passwordLinker
        };
    }

    function passwordController($scope, $state, $window, $location, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;
        vm.changePassword = changePassword;
        vm.generatePassword = generatePassword;
        vm.newPasswordType = 'password';
        vm.showNewPassword = true;
        vm.changeType = changeType;

        function changeType(type) {
            if(!vm.showNewPassword) {
                vm.newPasswordType = 'password';

            } else {
                vm.newPasswordType = type;
            }
        }

        function changePassword() {
            var promise;
            vm.saving = true;
            vm.success = null;
            vm.error = null;

            if (!vm.currentPassword || !vm.newPassword) {
                return false;
            }

            vm.changePromise = SlateAuth.user.changePassword(vm.currentPassword, vm.newPassword);
            vm.changePromise.then(function() {
                vm.saving = false;
                vm.success = "Password succesfully changed";
            }, function(data) {
                vm.saving = false;
                if(data.error) {
                    vm.error = data.error;
                } else {
                    vm.error = "Error changing password";
                }
            });
        }

        function generatePassword() {
            SlateAuth.generatePassword().then(function(password) {
                vm.newPassword = password;
                angular.element('#newPassword').focus();
            });
        }

    }

    function passwordLinker(scope, elem, attrs) {
        var inputs = elem.find('input');

        inputs[0].focus();
    }


})();
