(function() {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .directive('slateCartonMarkingPicker', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/purchaseorders/purchaseorder.cartonmarkingtemplate.picker.dir.html',
            scope: {
                template: '=',
                templateObj: '=?',
                purchaseorder: '=',
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function directiveController($scope, $state, $location, $translate, $q, getLinkSrv, SlateCartonMarking, SlateCartonMarkings, SlatePOCartonMarkingSrv) {
        /* jshint validthis: true */
        var vm = this;

        vm.selectTemplate = selectTemplate;

        vm.template = vm.purchaseorder.carton_marking_template || vm.purchaseorder.company_info.carton_marking_template;

        $scope.$watch('vm.template', function() {
            if (vm.template) {
                vm.templateObj = new SlateCartonMarking();
                vm.loading = true;
                vm.templateObj.loadFromServer(vm.template).then(function() {
                    vm.loading = false;
                });
            } else {
                var cartonMarkings = new SlateCartonMarkings();
                cartonMarkings.getList({is_default: true}).then(function() {
                    if (cartonMarkings.list.length) {
                        vm.templateObj = cartonMarkings.list[0];
                    } else {
                        vm.templateObj = null;
                    }
                }, function () {

                });
            }
        });

        function selectTemplate() {
            SlatePOCartonMarkingSrv.edit(vm.purchaseorder, vm.template).then(function(template) {
                vm.template = template.id;
                vm.templateObj = template;
            }, function() {

            });
        }


    }

})();
