(function() {
    "use strict";

    angular
        .module('slate.invoices')
        .directive('slateInvoiceEmailsList', modelList);

    function modelList() {
        return {
            restrict: 'E',

            templateUrl: '/partials/invoices/invoices.emails.list.dir.html',
            scope: {
                
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ListController($scope, $http, $location, $q, SlateAuth, SlateInvoiceEmails, SlateDeleteObjSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;


        vm.loading = true;

        vm.hasFilterData = hasFilterData;
        vm.deleteEmail = deleteEmail;

        vm.filters = {};

        angular.extend(vm.filters, {
            q: $location.search().q,
            entered: $location.search().entered,
            hide_invoices: $location.search().hide_invoices,
            view_all: $location.search().view_all,
            exception: $location.search().exception,
            page: $location.search().page || 1,
            count: $location.search().count || 25,
        });
        if(typeof vm.filters.page == 'string') { vm.filters.page = parseInt(vm.filters.page); }
        if(typeof vm.filters.count == 'string') { vm.filters.count = parseInt(vm.filters.count); }

        if (hasFilterData()) { vm.showFilters = true; }

        vm.invoiceEmails = new SlateInvoiceEmails();

        vm.fields = [
            'entered',
            'exception',
            'ap_number',
            'freight',
            'date',
            'sender',
            'subject',
        ];

        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            updateFromFilter();
        });

        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function(value, key) {
                if (key == 'q' || key == 'page' || key == 'count') { return false; }
                if (!value) { return false; }
                hasData = true;
            });
            return hasData;
        }

        function updateFromFilter() {
            vm.loading = true;

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            return vm.invoiceEmails.getList(vm.filters).then(function() {
                vm.loading = false;
            });
        }

        function deleteEmail(email) {
            SlateDeleteObjSrv.delete('payableemail', email.id).then(function() {
                updateFromFilter();
            });
        }

    }

})();
