(function() {
    "use strict";

    angular
        .module('slate.library.rfqs')
        .factory('SlateRFQs', ModelList)
        .factory('SlateRFQ', ModelDetail);

    var apiUrl = API_URL + 'rfq/rfq/';

    function ModelList($http, $q, SlateRFQ) {
        // Set to url relative to api root.

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.download = download;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlateRFQ();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.totalItems = response.data.totalItems;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function download(filters) {

        }

    }

    function ModelDetail($http, $q, SlateRFQLineItem, SlateRFQResponse) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },

            {name: 'status', def: 'Draft'},
            {name: 'name'},
            {name: 'comp_name', readonly: true},
            {name: 'number', readonly: true},

            {name: 'project'},
            {name: 'project_info', readonly: true},
            {name: 'order'},
            {name: 'order_info', readonly: true},
            {name: 'company'},
            {name: 'company_info', readonly: true},
            {name: 'sales_mgr'},
            {name: 'sales_mgr_info', readonly: true},
            {name: 'proj_mgr'},
            {name: 'proj_mgr_info', readonly: true},

            {name: 'ship_terms'},
            {name: 'ship_method'},

            {name: 'quote_due', type: 'dateOnly'},
            {name: 'delivery_date', type: 'dateOnly'},

            {name: 'domestic', def: false},
            {name: 'international', def: true},
            {name: 'req_alternate_source', def: false},

            {name: 'notes'},

            {name: 'updated', readonly: true, type: 'date'},
            {name: 'created', readonly: true, type: 'date'},
            {name: 'created_by', readonly: true},
            {name: 'created_by_info', readonly: true},

            {name: 'line_items', type:'child_list', model: SlateRFQLineItem},
            {name: 'responses', type:'child_list', model: SlateRFQResponse},

            {name: 'legacy', def: false, readonly: true },
            /*************** Legacy fields below this line  **************/

            {name: 'finalized'},
            {name: 'finalized_on', readonly: true},
            {name: 'reviewed', def: 'New'},
            {name: 'reviewed_by', readonly: true},
            {name: 'reviewed_by_info', readonly: true},
            {name: 'component'},
            {name: 'component_info', readonly: true},
            {name: 'source_rep'},
            {name: 'source_rep_info', readonly: true},
            {name: 'source_rep_do_not_assign', def: true},
            {name: "material" },
            {name: "category" },
            {name: 'field_names', type: 'json', def: []},
            {name: 'splits', type: 'json', def:[1000,5000,25000]},
            {name: 'vendors', type: 'json', def:[]},
            {name: 'vendors_complete', def: false},
            {name: 'china_vendors', type: 'json', def:[]},
            {name: 'china_vendors_complete', def: false},
        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.voidModel = voidModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.duplicate = duplicate;
        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;

                if(field.type=="child_list") {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }



        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                self[field.name] = payload[field.name];

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if (field.type == 'date' && payload[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type == 'dateOnly' && payload[field.name]) {
                    if(typeof payload[field.name] == 'string') {
                        var parts = self[field.name].split('-');
                        self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
                    }
                }

                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) { console.log('didnt parse json', e); }
                }

                if(field.type == 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach(function(item, idx, array) {
                            var obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch(e) {
                        console.log('error loading: ', e);
                    }
                }

            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];

                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                deferred.resolve(self);

            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            angular.forEach(fields, function(field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if(field.type == "date" && self[field.name]) {
                        //data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth()+1) + '-' + self[field.name].getUTCDate();
                        data[field.name] = self[field.name].toISOString();
                    }
                    if(field.type == "dateOnly" && self[field.name]) {
                        data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth()+1) + '-' + self[field.name].getDate();
                    }
                    if(field.trim && data[field.name]) {
                        data[field.name] = data[field.name].substr(0,field.trim);
                    }

                    if(field.type == "child_list" && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach(function(item) {
                            if(!item.toDelete) {
                                data[field.name].push(item.getModelData());
                            }
                        });
                    }

                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function voidModel() {
            /* jshint validthis: true */
            var self = this;
            var modelUrl = apiUrl;

            var promise;
            if (self.id) {
                modelUrl = apiUrl + self.id + '/void/';
            }

            promise = $http.post(modelUrl);
            promise = promise.then(function(response) {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return self;
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

        function duplicate(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred;
            //var skus = new SlateQCRequestSKUs();

            deferred = $q.defer();

            self.loadFromServer(id).then(function() {
                self.id = undefined;
                if(self.name_en) { self.name = self.name + " (copy)"; }

                self.saveModel().then(function() {
                    deferred.resolve(self);
                });
            });


            return deferred.promise;
        }

    }

})();
