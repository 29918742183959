(function () {
    "use strict";

    angular
        .module('slate.qc')
        .directive('slateQclogEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/qc/qclog.edit.dir.html',
            scope: {
                qclog: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $timeout, $state, $location, $q, getLinkSrv, SlateQCLog, SlateCompany, SlateLocations, SlateOrder, SlatePurchaseOrder, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;

        vm.factory = new SlateCompany();
        vm.co_addresses = new SlateLocations();

        vm.setFactoryLocation = setFactoryLocation;
        vm.canFullEdit = canFullEdit;
        vm.isComplete = isComplete;
        vm.save = save;

        $scope.$watch('vm.qclog.purchase_order', function() {
            if (vm.qclog.purchase_order) {
                purchaseOrderSync();
            }
        });
        
        $scope.$watch('vm.qclog.date', function() {
            if (!vm.qclog.date) { vm.qclog.date = new Date(); }
        });

        function save() {
            vm.saving = true;
            vm.qclog.saveModel().then(function () {
                vm.fileSrv.uploadFiles('qclog', vm.qclog.id).then(function () {
                    $timeout(function () {
                        vm.saving = false;
                        $state.go('qclogListPage');
                    }, 1000);
                });
            }, function(e) {
                vm.saving = false;
                vm.error = true;
            });
        }

        function canFullEdit() {
            return true;
        }

        function isComplete() {
            if (!vm.qclog.name) { return false; }
            if (!vm.qclog.project) { return false; }
            if (!vm.qclog.component) { return false; }
            if (!vm.qclog.client) { return false; }
            if (!vm.qclog.order) { return false; }
            if (!vm.qclog.purchase_order) { return false; }
            if (!vm.qclog.inspection_type) { return false; }

            //if (!vm.qclog.order && !SlateAuth.perms.logistics_can_save_without_order) { return false; }
            return true;
        }

        function purchaseOrderSync() {
            var po = new SlatePurchaseOrder();
            po.loadFromServer(vm.qclog.purchase_order).then(function() {
                vm.qclog.name = po.project_info.name.substring(0, 72);
                vm.qclog.client = po.company;
                vm.qclog.project = po.project;
                vm.qclog.order = po.order;
                vm.qclog.proj_mgr = po.proj_mgr;
                vm.qclog.sales_mgr = po.sales_mgr;
                vm.qclog.factory = po.vendor_info.name;
                vm.factory.loadFromServer(po.vendor);
            });
        }
        
        function setFactoryLocation() {
            var selected_addr;

            function select_addr(item) {
                if(item.id == vm.selected_client_address) { selected_addr = item; }
            }
            vm.factory.locations.list.forEach(select_addr);
            if(!selected_addr) { return false;}
            vm.qclog.factory_location = selected_addr.city + ', ' + (selected_addr.state || selected_addr.county || selected_addr.street2);

            vm.selected_client_address = null;
        }
    }

})();
