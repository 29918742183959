(function () {
    'use strict';

    angular
        .module('slate.roles')
        .directive('slateRoleControlPanel', slateDir);

    function slateDir() {
        return {
            restrict: 'E',
            templateUrl: '/partials/roles/roles.cp.details.dir.html',
            scope: {
                role: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ListController($scope, $q, $uibModal) {
        /* jshint validthis: true */
        const vm = this;
        vm.save = save;
        vm.saving = false;
        vm.current_cat = '';
        vm.checkCat = checkCat;
        vm.removeUserFromRole = removeUserFromRole;
        vm.addUsersToRole = addUsersToRole;

        vm.role.loadUsers();

        function save() {
            vm.saving = true;
            vm.error = false;
            vm.role.saveModel().then(function () {
                vm.role.loadUsers();
                vm.saving = false;
            }, function () {
                vm.saving = false;
                vm.error = true;
            });
        }

        function removeUserFromRole(userId) {
            if (window.confirm(`Are you sure you want to remove user from ${vm.role.name}?`)) {
                vm.role.removeUser(userId);
            }
        }

        function addUsersToRole() {
            const templateUrl = '/partials/roles/roles.cp.addusers.dialog.html';

            const modalInstance = $uibModal.open({
                templateUrl,
                controller: addUsersDialogCtrl, // editController,
                controllerAs: 'vm',
                // size: 'lg',
                resolve: {
                    role() {
                        return vm.role;
                    },

                },
            });

            return modalInstance.result.then(function (results) {
                return results;
            }, function () {
                return $q.reject();
            });
        }

        function checkCat(cat) {
            if (cat !== vm.current_cat) {
                vm.current_cat = cat;
                return true;
            }

            return false;
        }
    }


    function addUsersDialogCtrl($scope, $q, $uibModalInstance, SlateContacts, SlateContact, role) {
        /* jshint validthis: true */
        const vm = this;

        vm.saving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.checkBlanks = checkBlanks;
        vm.removeContact = removeContact;
        vm.contacts = [new SlateContact()];

        vm.staffContacts = new SlateContacts();
        vm.staffContacts.getList({
            staff: true,
        }).then(function () {

        });

        function checkBlanks() {
            if (vm.contacts.length < 1 || vm.contacts[vm.contacts.length - 1].id) {
                vm.contacts.push(new SlateContact());
            }
        }

        function removeContact(index) {
            vm.contacts.splice(index, 1);
            checkBlanks();
        }

        function saveObject() {
            const promises = [];
            vm.saving = true;

            angular.forEach(vm.contacts, function (contact) {
                if (typeof (contact.id) === 'string' && contact.id.length > 0) {
                    promises.push(role.addUser(contact.id));
                }
            });
            $q.all(promises).then(function () {
                $uibModalInstance.close(vm.contacts);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
