(function () {
    "use strict";

    angular
        .module('slate.rates')
        .controller('SlateRateSheetEstimateController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $q, $http, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateRateSheet) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.params = {
            weight: 0,
            cbms: 0,
        };
        vm.calc = {
            carton_height: 0,
            carton_length: 0,
            carton_width: 0,
            carton_qty: 0,
            desired_qty: 0,
            item_weight: 0
        };

        vm.calcCBMs = calcCBMs;
        vm.getFullContainers = getFullContainers;
        vm.getPartialContainers = getPartialContainers;

        vm.estimateTypes = ['oceanfcl', 'oceanlcl', 'sonic'];

        $scope.$watch('vm.params', function (old_val, new_val) {
            if (
                parseFloat(vm.params.cbms) > 0 &&
                vm.params.origin_port &&
                vm.params.destination_port
            ) {
                getFreightEstimate();
            }
        }, true);

        $scope.$watch('vm.calc', function (old_val, new_val) {
            vm.params.cbms = calcTotalCBMs();
            vm.params.weight = calcTotalWeight();
        }, true);


        // getFreightLocations();

        function getFreightLocations() {
            var apiUrl = API_URL + 'rates/ports/';
            return $http.get(apiUrl).then(function(response) {
                vm.freightLocations = response.data.payload;
            });
        }

        function getFreightEstimate() {
            /* jshint validthis: true */
            var self = this;
            var apiUrl = API_URL + 'rates/calc-estimate/';

            return $http.get(apiUrl, {params: vm.params}).then(function(response) {
                vm.error = false;
                vm.freightEstimate = response.data.payload.estimates;
                vm.containers = response.data.payload.containers;
            }, function(response) {
                vm.error = true;
                vm.freightEstimate = 0;
                if(response.data && response.data.error) {
                    vm.error_text = response.data.error;
                } else {
                    vm.error_text = 'Server Error';
                }
            });
        }

        function calcCBMs() {
            var l,w,h, cbmPerCarton;
            if ([
                vm.calc.carton_height,
                vm.calc.carton_length,
                vm.calc.carton_width,
            ].some(function (item) { return item === 0.0; })) { return new Big(0); }
            l = new Big(vm.calc.carton_length);
            w = new Big(vm.calc.carton_width);
            h = new Big(vm.calc.carton_height);

            cbmPerCarton = l.times(w).times(h).div(1000000);
            return cbmPerCarton.toString();
        }

        function calcTotalCBMs(desired_qty) {
            var cbmPerCarton, desiredQty, qtyPerCarton, cartons, totalCbms;
            if ([
                vm.calc.desired_qty,
                vm.calc.carton_qty,
            ].some(function(item) { return item === 0.0; })) { return new Big(0); }

            cbmPerCarton = vm.calcCBMs();

            desiredQty = new Big(vm.calc.desired_qty);
            qtyPerCarton = new Big(vm.calc.carton_qty);
            cartons = desiredQty.div(qtyPerCarton);

            totalCbms = cartons.round(0, 3).times(cbmPerCarton);

            return totalCbms.toString();
        }

        function calcTotalWeight() {
            return vm.calc.item_weight * vm.calc.desired_qty;
        }

        function getFullContainers() {
            if(!vm.containers) { return []; }
            return vm.containers.filter(function(item) {
                return item.total >= 1.0;
            });
        }
        function getPartialContainers() {
            if(!vm.containers) { return []; }
            return vm.containers.filter(function(item) {
                return item.total < 1.0;
            });
        }

    }

})();
