(function() {
    "use strict";

    angular
        .module('slate.library.schedule')
        .service('SlateUserAvail', AuthService);


    function AuthService($q, $interval, SlateScheduleAvail) {
        var globalPromise;
        var srv = {
            loadGlobal: loadGlobal,
            getUser: getUser,
            
            global: null,
            users: {}
        };

        globalPromise = srv.loadGlobal();
        
        return srv;

        function loadGlobal() {

            var deferred = $q.defer();
            var promise;
            var globalAvail = new SlateScheduleAvail();

            promise = globalAvail.loadFromServer();
            promise.then(function(results) {
                srv.global = globalAvail.availability;
                deferred.resolve(srv);
            });

            return deferred.promise;
        }

        function getUser(user_id) {
            var deferred = $q.defer();
            var promise;
            var userAvail = new SlateScheduleAvail();

            promise = userAvail.loadFromServer(user_id);
            promise.then(function(results) {
                var composite = angular.copy(srv.global);
                angular.extend(composite, srv.global);

                srv.users[user_id] = composite;
                deferred.resolve(srv);
            });

            return deferred.promise;
        }

    }

})();
