(function () {
    "use strict";

    angular
        .module('slate.customercomplaints')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
            .state('customerComplaintListPage', {
                url: '/customercomplaints/',
                template: "<div class=\"content-pane list-page\"><slate-customer-complaint-list></slate-customer-complaint-list></div>",
                current_app: 'customercomplaints',
                slateTitle: 'Customer Complaints',
            })
            .state('customerComplaintEditPage', {
                url: '/customercomplaints/complaint/:id/edit/',
                templateUrl: '/partials/customercomplaints/customercomplaint.edit.page.html',
                controller: 'SlateCustomerComplaintEditPageController',
                controllerAs: 'vm',
                current_app: 'customercomplaints',
                slateTitle: 'Edit Customer Complaint - Customer Complaints',
            })

            ;
    }
})();