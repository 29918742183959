(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .service('SlateSQLineItemEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateSQLineItem) {
        var templateUrl = '/partials/salesquotes/sq.lineitems/sq.lineitem.edit.sidebar.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(item, options) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj: function () {
                        var newitem = new SlateSQLineItem();
                        if(!item) {
                            return newitem;
                        }
                        return _.cloneDeep(item);
                    },
                    salesQuote: function() {
                        if(typeof options === 'object' && options.salesQuote) {
                            return options.salesQuote;
                        }
                        return null;
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance,  obj, salesQuote, SlateSettings, SlateCompany, SlateProject, SlateLocations, SlateBOMs, SlateBOM, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;

        vm.item = obj;
        vm.salesQuote = salesQuote;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.auth = SlateAuth;

        vm.isComplete = isComplete;
        vm.addSplit = addSplit;
        vm.deleteSplit = deleteSplit;
        vm.updateBreak = updateBreak;
        vm.updateMargin = updateMargin;
        vm.updatePrice = updatePrice;

        vm.boms = new SlateBOMs();


        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            //zIndex: 2501,
        };

        vm.units = [
            'ea',
            'box',
            'bottle',
            'g',
            'kg',
            'oz',
            'liter'
        ];

        console.log('splits started as: ', vm.item.splits);

        vm.item.updateCosts(vm.salesQuote.tariffs);

        function saveObject(skip) {
            sortBreaks();
            if(!isComplete()) { return false; }
            $uibModalInstance.close(vm.item);
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }


        function isComplete() {
            if(!vm.item.finished_good) { return false; }
            if(!vm.item.bom) { return false; }
            if(!vm.item.qty) { return false; }
            if(!vm.item.splits || !vm.item.splits.length) { return false; }
            if(vm.item.splits.some(function(split) { return isNaN(parseFloat(split)); })) { return false; }
            return true;
        }

        function projectSync() {
            var project = new SlateProject();
            project.loadFromServer(vm.item.finished_good).then(function () {
                if (!vm.item.jansy_pn) {
                    if(project.internal_item_number) {
                        vm.item.jansy_pn = project.internal_item_number;
                    } else {
                        vm.item.jansy_pn = project.composite_num;
                    }
                }

                if (!vm.item.name) {
                    if(project.variation_name) {
                        vm.item.name = project.variation_name.substring(0,74);
                    } else {
                        vm.item.name = project.name.substring(0,74);
                    }
                }
                if (!vm.item.client_pn) { vm.item.client_pn = project.client_item_number; }
                if (!vm.item.specs) { vm.item.specs = project.brief; }
                if (!vm.item.category) { vm.item.category = project.category; }
                if (!vm.item.material) { vm.item.material = project.material; }
                if (!vm.item.unit_name) { vm.item.unit_name = project.unit_name; }
                if (!vm.item.unitprice) { vm.item.unitprice = project.buy_price; }
                if (!vm.item.sellprice) { vm.item.sellprice = project.sell_price; }
            });

        }
        $scope.$watch('vm.item.finished_good', function () {
            if (vm.item.finished_good) {
                projectSync();
            }

            if (!vm.item.finished_good) {
                vm.boms.list = [];
            } else {
                vm.boms.getList({ finished_good: vm.item.finished_good }).then(function () {
                    if(vm.boms.list.length > 0 && !vm.boms.list.some(function(bom) { return bom.id == vm.item.bom; })) {
                        vm.item.bom = vm.boms.list[0].id;
                    }

                    if(!vm.item.bom && vm.boms.list.length > 0) {
                        vm.item.bom = vm.boms.list[0].id;
                    }
                });
            }

        });

        SlateSettings.getConfig('material_categories').then(function(config) {
            vm.categories = config;
        });
        SlateSettings.getConfig('materials').then(function(config) {
            vm.material_list = config;
            getMatList();
        });
        vm.materials = [];

        $scope.$watch('vm.item.category', function() {
            getMatList();
        });

        function getMatList() {
            var newlist = [];
            if(!vm.material_list || ! vm.material_list.value) { return []; }
            angular.forEach(vm.material_list.value, function(item) {
                if(item.category == vm.item.category) {
                    newlist.push(item);
                }
            });
            vm.materials = newlist;
            return newlist;
        }

        function addSplit() {
            var largest = Big(0);
            if(vm.item.splits && !Array.isArray(vm.item.splits)) {
                console.log('splits is not an array!', vm.item.splits);
                vm.item.splits = [];
            }
            vm.item.splits.forEach(function(item) {
                var num = Big(item);
                if(num.gt(largest)) { largest = num; }
            });
            largest = largest.times(2);
            if(vm.item.splits.length && largest.eq(0)) { largest = Big(vm.item.qty); }
            if(!vm.item.splits.length || largest.eq(0)) { largest = Big(1000); }
            vm.item.splits.push(largest.toString());
            vm.item.updateCosts(vm.salesQuote.tariffs);
        }

        function deleteSplit(split) {
            vm.item.splits = vm.item.splits.filter(function(item) {
                return item != split;
            });

        }

        function updateMargin(idx, value) {
            var cost, margin;
            console.log('cost is:', vm.item.options.primary[value].cost);

            if(typeof vm.item.options.primary[value].cost == 'undefined') { vm.item.options.primary[value].cost = 0; }
            if(vm.item.options.primary[value].cost == '') { vm.item.options.primary[value].cost = 0; }

            cost = Big(vm.item.options.primary[value].cost);
            margin = Big(vm.item.options.primary[value].margin).div(100);
            vm.item.options.primary[value].lastUpdate = 'margin';
            vm.item.options.primary[value].price = cost.div(Big(1).minus(margin)).toFixed(5);

        }

        function updatePrice(idx, value) {
            var cost, sell;
            if(vm.item.options.primary[value].cost == '') { vm.item.options.primary[value].cost = 0; }

            cost = Big(vm.item.options.primary[value].cost);
            sell = Big(vm.item.options.primary[value].price);
            vm.item.options.primary[value].lastUpdate = 'price';
            vm.item.options.primary[value].margin = sell.minus(cost).div(sell).times(100).toFixed(2);
        }

        function updateBreak(idx, value) {
            var orig_value = _.clone(vm.item.splits[idx]);
            vm.item.splits[idx] = _.clone(value);
            if(vm.item.options.primary && vm.item.options.primary[orig_value]) {
                vm.item.options.primary[value] = _.clone(vm.item.options.primary[orig_value]);
            }
            vm.item.updateCosts(vm.salesQuote.tariffs);
        }

        function sortBreaks() {
            vm.item.splits = vm.item.splits.filter(function(item, idx) {
                return vm.item.splits.indexOf(item) === idx;
            });
            vm.item.splits.sort(function(a, b) {
                var first = parseFloat(a);
                var second = parseFloat(b);

                return first - second;
            });

        }

    }
})();
