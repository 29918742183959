(function() {
    "use strict";

    angular
        .module('slate.orders')
        .directive('slateOrderSpecRow', modelDetails);

    function modelDetails() {
        return {
            restrict: 'A',

            templateUrl: '/partials/orders/order.edit.orderspec.dir.html',
            scope: {
                orderSpec: '=',
                project: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,
            
        };
    }

    function ListController($scope, SlateProjects, SlateBOMItems) {
        /* jshint validthis: true */
        var vm = this;
        vm.orderSpec.bomitems = new SlateBOMItems();
        vm.items_loading = true;

        vm.allItemsSelected = allItemsSelected;
        vm.toggleAllItems = toggleAllItems;

        $scope.$watch('vm.orderSpec.bom', function () {
            if (!vm.orderSpec.bom) {
                vm.orderSpec.bomitems.list = [];
                return vm.orderSpec.bomitems;
            }
            vm.orderSpec.bomitems.getList({ bom: vm.orderSpec.bom }).then(function () {
                vm.items_loading = false;
            });
        });
        
        function allItemsSelected() {
            return vm.orderSpec.bomitems.list.every(function(item) {
                return item.selected;
            });
        }

        function toggleAllItems() {
            if(allItemsSelected()) {
                vm.orderSpec.bomitems.list.forEach(function(item) {
                    item.selected = false;
                });    
            } else {
                vm.orderSpec.bomitems.list.forEach(function(item) {
                    item.selected = true;
                });
            }
        }

    }

})();
