(function () {
    'use strict';

    angular
        .module('slate.library.profilephotos')
        .service('SlateProfilePhotoSrv', ProfileService);

    function ProfileService($q, $http, $timeout) {
        const apiUrl = `${API_URL}profilephotos/get-thumb/`;

        const srv = {
            getPhoto,
            deletePhoto,
            list: {}
        };

        return srv;

        function deletePhoto(type, id) {
            angular.forEach(srv.list, (item, key) => {
                if (key.indexOf(`${type}-${id}-`) === 0) {
                    delete srv.list[key];
                }
            });
        }

        function getPhoto(type, id, size) {
            const key = `${type}-${id}-${size}`;
            const deferred = $q.defer();
            const currentdate = new Date();
            const randomtime = ((Math.random() * 120) + 60) * 60 * 1000;

            if (srv.list[key] && srv.list[key].data && srv.list[key].expires > currentdate) {
                return $q.resolve(srv.list[key].data);
            }
            if (typeof srv.list[key] !== 'object') {
                srv.list[key] = { data: undefined, expires: new Date(currentdate.getTime() + randomtime) };
            }
            $http.get(apiUrl, { params: { size, parent_type: type, parent_fk: id } }).then((resp) => {
                srv.list[key].data = resp.data;
                srv.list[key].expires = new Date(currentdate.getTime() + randomtime);
                $timeout(() => { delete srv.list[key]; }, randomtime);
                deferred.resolve(srv.list[key].data);
            }, (e) => {

            });
            return deferred.promise;
        }
    }
})();
