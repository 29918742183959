(function () {
    'use strict';

    angular
        .module('slate.purchaseorders')
        .controller('SlatePOShipNewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlatePurchaseOrder, SlateShipment) {
        /* jshint validthis: true */
        const vm = this;
        vm.loading = true;
        vm.purchaseorder = new SlatePurchaseOrder();
        vm.shipment = new SlateShipment();

        vm.purchaseorder.loadFromServer($stateParams.id).then(() => {
            vm.shipment.purchaseorder = vm.purchaseorder.id;
            vm.shipment.origin_type = 'purchaseorder';

            vm.shipment.delivery_to = vm.purchaseorder.ship_company;
            // vm.delivery_address = [
            //     vm.purchaseorder.ship_street,
            //     vm.purchaseorder.ship_city,
            //     vm.purchaseorder.ship_state,
            //     vm.purchaseorder.ship_postal,
            //     vm.purchaseorder.ship_country
            // ].filter((item) => item && item !== '');
            // vm.shipment.final_destination = vm.delivery_address.join(', ');
            const address_field = 'final_destination';
            vm.shipment[`${address_field}_street`] = vm.purchaseorder.ship_street;
            vm.shipment[`${address_field}_city`] = vm.purchaseorder.ship_city;
            vm.shipment[`${address_field}_state`] = vm.purchaseorder.ship_state;
            vm.shipment[`${address_field}_postal`] = vm.purchaseorder.ship_postal;
            vm.shipment[`${address_field}_country`] = vm.purchaseorder.ship_country;

            vm.shipment.name = vm.purchaseorder.name;
            if (vm.purchaseorder.jansy_po) {
                vm.shipment.name = `${vm.purchaseorder.jansy_po} - ${vm.shipment.name}`;
            }
            vm.shipment.name = vm.shipment.name.substring(0, 72);

            if (vm.purchaseorder.name) {
                SlateTitle.set(`Ship ${vm.purchaseorder.name} - Purchase Orders`);
            } else {
                SlateTitle.set('Ship - Purchase Orders');
            }
            vm.loading = false;
        });
    }
})();
