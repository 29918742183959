(function() {
    "use strict";

    angular
        .module('slate.roles')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
            .state('controlPanels.rolesList', {
                url: 'roles/',
                //templateUrl: "/partials/ro/presentations.page.html",
                template: "<slate-roles-control-panel roles='vm.roles'></slate-roles-control-panel>",
                current_app: 'controlpanels',
                controller: function(roles) {
                    /* jshint validthis: true */
                    var vm = this;
                    vm.roles = roles;
                },
                controllerAs: 'vm',
                resolve: {
                    roles: function(SlateRolesList) {
                        var rolesList = new SlateRolesList();
                        return rolesList.getList();
                    }
                }
            })
            .state('controlPanels.rolesDetails', {
                url: 'roles/:slug/',
                //templateUrl: "/partials/ro/presentations.page.html",
                template: "<slate-role-control-panel role='vm.role'></slate-role-control-panel>",
                current_app: 'controlpanels',
                controller: function(role) {
                    /* jshint validthis: true */
                    var vm = this;
                    vm.role = role;
                },
                controllerAs: 'vm',
                resolve: {
                    role: function($stateParams, SlateRole) {
                        var role = new SlateRole();
                        return role.loadFromServer($stateParams.slug);
                    }
                }
            })

        ;
    }
})();
