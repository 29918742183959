(function () {
    "use strict";

    angular
        .module('slate.projects')
        .directive('slateProjectSearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/projects/projects.searchbox.dir.html',
            scope: {
                projectId: '=',
                projectName: '=?',
                client: '<?',
                freeform: '<?',
                readonly: '<?',
                root: '=?',
                parentProject: "=?",
                showDropdown: "<?",
                projectObj: "=?",
                finishedGood: "=?",
                variations: "=?",
                components: "=?",
                noLimit: "=?",
                bom: "=?",
                limitFields: '=?'
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function directiveController($scope, $state, $location, $q, getLinkSrv, SlateProject, SlateProjects, SlateBOMItems) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;
        var selectedProject;
        
        vm.id = 'project-search-box-' +  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        vm.projects = new SlateProjects();
        vm.dropdownProjects = new SlateProjects();
        vm.bomItems = new SlateBOMItems();
        vm.bomItemsIds = [];

        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.clear = clear;
        if(typeof vm.projectName != "string") { vm.projectName = ""; }

        vm.projectObj = {
            composite_name: vm.projectName,
            name: vm.projectName,
            id: vm.projectId
        };

        vm.selectDropdown = selectDropdown;
        vm.appendToEl = angular.element(document.querySelector('#dropdown-long-content'));
        vm.getWidth = getWidth;

        function getWidth() {
            var elem = document.getElementById(vm.id);
            return elem.offsetWidth + 'px';
        }

        function getDropDown() {
            var filters = {};
            var promise;

            if(!vm.showDropdown) { return false; }
            if(!vm.parentProject && !vm.client) { console.log('needs a client or parent project'); return false; }
            if (vm.client) { filters.company = vm.client; }
            if (vm.parentProject) { filters.project = vm.parentProject;  filters.recursive = true;}
            if (vm.finishedGood) { filters.finished_only = vm.finishedGood; filters.recursive=true; }
            if (vm.variations) { filters.is_variation = vm.variations; filters.recursive=true; }
            if (vm.components) { filters.finished_or_variation = vm.components; filters.recursive=true; }
            filters.fields = ['id', 'name', 'variation_name', 'composite_num', 'job_number', 'composite_name', 'composite_number', 'variation_number'];
            promise = vm.dropdownProjects.getList(filters).then(function () {
                return vm.dropdownProjects.list;
            });
            return promise;
        }

        function selectDropdown($item) {
            vm.searching = false;
            vm.projectId = $item.id;
            selectedProject = $item.id;
            vm.projectObj = $item;
            vm.projectName = $item.composite_name;
        }

        if (vm.projectId) {
            var project = new SlateProject();
            project.loadFromServer(vm.projectId).then(function () {
                vm.projectObj = project;
                vm.projectName = project.composite_name;
                selectedProject = project.id;
            });
        }

        $scope.$watch('vm.bom', function (newVal, oldVal) {
            if(vm.bom) {
                vm.bomItems.getList({bom: vm.bom}).then(function() {
                    var newList = [];
                    vm.bomItems.list.forEach(function(item) {
                        newList.push(item.component);
                    });
                    vm.bomItemsIds = newList;
                });
            }
        });

        function getSearch(q) {
            var promise;
            var filters = {
                q: q,
                page: 1,
                count: 50
            };
            if (q && q.length < 1) { return $q.reject([]); }
            if (vm.client) { filters.company = vm.client; filters.recursive = true; }
            if (vm.root) { filters.recursive = false; filters.root_only = true; }
            if (vm.parentProject && !vm.noLimit) { filters.project = vm.parentProject; }
            if (vm.finishedGood) { filters.finished_only = vm.finishedGood; filters.recursive=true; }
            if (vm.variations) { filters.is_variation = vm.variations; filters.recursive=true; }
            if (vm.components) { filters.finished_or_variation = vm.components; filters.recursive=true; }
            if (vm.noLimit) { filters.recursive = false; }
            filters.fields = ['id', 'name', 'variation_name', 'composite_num', 'job_number', 'composite_name', 'composite_number', 'variation_number'];
            promise = vm.projects.getList(filters).then(function () {
                if (vm.freeform) {
                    if(vm.projects.list.length != 1 || ( vm.projects.list.length == 1 && q.toLowerCase() != vm.projects.list[0].name.toLowerCase() ) )
                    vm.projects.list.push({ composite_name: q, name: q, id: null });
                }
                return vm.projects.list;
            });
            return promise;

        }

        function selectSearch($item, $model, $label, $event) {
            vm.searching = false;
            vm.projectId = $item.id;
            selectedProject = $item.id;
            vm.projectObj = $item;
            vm.projectName = $item.composite_name;

        }

        function clear() {
            if (vm.readonly) { return true; }
            vm.projectId = null;
            vm.projectObj = null;
            vm.projectName = null;
        }

        $scope.$watch('vm.projectId', function (newVal, oldVal) {

            if (vm.projectId && (newVal != oldVal || !vm.projectObj)) {

                var project = new SlateProject();
                project.loadFromServer(vm.projectId).then(function () {
                    vm.projectObj = project;
                    vm.projectName = project.composite_name;
                    selectedProject = project.id;
                });
            }

        });
        $scope.$watch('vm.client', function () { getDropDown(); });
        $scope.$watch('vm.parentProject', function () { getDropDown(); });

        $scope.$watch('vm.typeaheadOpen', function() {
            if(!vm.typeaheadOpen && !vm.projectObj && vm.projectId) {
                var project = new SlateProject();
                project.loadFromServer(vm.projectId).then(function () {
                    vm.projectObj = project;
                    vm.projectName = project.composite_name;
                    selectedProject = project.id;
                });
            }
        });
    }

})();
