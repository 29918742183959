(function() {
    "use strict";

    angular
        .module('slate.library.datelog')
        .factory('SlateDateChanges', ModelList)
        .factory('SlateDateChange', ModelDetail);


    function ModelList($http, $q, SlateDateChange) {
        var apiUrl = API_URL + 'datelog/datechange/';

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;

            if(typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {params: self.filters});

            promise.then(function (response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function (item) {
                    var itemModel = new SlateDateChange();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }


    }

    function ModelDetail ($http, $q) {
        var apiUrl = API_URL + 'datelog/datechange/';

        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();
            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },

            {name: 'parent'},
            {name: 'parent_fk'},
            {name: 'parent_type', readonly: true},
            
            {name: 'field'},
            {name: 'orig_date', type: 'dateOnly'},
            {name: 'new_date', type: 'dateOnly'},
            {name: 'notes'},
            
            {name: 'created', type: 'date', readonly: true},
            {name: 'created_by', readonly: true},
            {name: 'created_by_info', readonly: true},

        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.getModelData = getModelData;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if(typeof payload !== "object") {
                console.log('payload must be an object', payload);
                return $q.reject(self);
            }

            angular.forEach(fields, function(field) {
                self[field.name] = payload[field.name];
                if(field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type == 'dateOnly' && payload[field.name]) {
                    if(typeof payload[field.name] == 'string') {
                        self[field.name] = dateFns.parse(payload[field.name]);
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl;

            var promise;
            var params = {};

            if(typeof id == "string" ) {
                modelUrl = modelUrl+id+"/";
            }
            if (!self.id && !id) {
                console.log('Tried to load price tier from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl, {params: params});

            promise.then(function(response) {
                self.loadFromPayload(response.data.payload).then(function() {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            var self = this;
            var data = {};

            angular.forEach(fields, function(field) {
               if ((!field.readonly || field.name == 'id') && typeof(self[field.name]) != 'undefined') {
                    data[field.name] = self[field.name];
                    if(data[field.name] && field.type == "date") {
                        data[field.name] = self[field.name].toISOString();
                    }
                    if(field.type == "dateOnly" && self[field.name]) {
                        data[field.name] = dateFns.format(self[field.name], 'YYYY-MM-DD');
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;

            
            if(self.id) { modelUrl = apiUrl + self.id + '/'; }
            
            var data = self.getModelData();
            promise = $http.post(modelUrl, data);
            promise = promise.then(function (response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if(self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function (response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
