(function() {
    "use strict";

    angular
        .module('slate.rates')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
            .state('rateSheetListPage', {
                url: '/rates/',
                template: "<div class='content-pane list-page'><slate-rate-sheet-list></slate-rate-sheet-list></div>",
                current_app: 'rates',
                slateTitle: 'Rate Sheets'
            })
            .state('rateSheetEditPage', {
                url: '/rates/ratesheet/:id/edit/',
                templateUrl: '/partials/rates/ratesheet.edit.page.html',
                controller: 'SlateRateSheetEditPageController',
                controllerAs: 'vm',
                current_app: 'rates'
            })
            .state('rateBasicEstimate', {
                url: '/rates/estimate/',
                templateUrl: '/partials/rates/ratesheet.estimate.edit.page.html',
                controller: 'SlateRateSheetEstimateController',
                controllerAs: 'vm',
                current_app: 'rates'
            })
            ;
    }
})();
