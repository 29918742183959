(function () {
    "use strict";

    angular
        .module('slate.orderplan')
        .directive('slateOrderplanStep', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/orderplan/orderplan.edit.item.dir.html',
            scope: {
                stepNode: '<?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, getLinkSrv, SlateOrderPlanEditSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;

        vm.editstep = editstep;

        function editstep(step) {
            if(step.step_type == 'start') { return false; }

            SlateOrderPlanEditSrv.edit(step, {order: vm.stepNode.item.order}).then(function(newstep) {
                $scope.$emit('reloadOrderPlan');
            });
        }

    }



})();