(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetPoReceive', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.po_receive.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
      }

    function ListController($scope, $http, $interval, SlatePOReceives) {
        /* jshint validthis: true */
        var vm = this;
        var interval = null;
        var speed = vm.settings.speed || getRandomInt(60000, 70000);
        vm.poreceives = new SlatePOReceives();
        vm.completeItem = completeItem;

        function getData() {
            var filters = { };

            if (vm.settings.unprocessed_sage) { filters.unprocessed_sage = vm.settings.unprocessed_sage; }
            vm.poreceives.getList(filters);
        }

        // getData();

        $scope.$watch('vm.settings', function(items) {
            getData();
        }, true);

        interval = $interval(function() {
            getData();
        }, speed);

        $scope.$on('$destroy', function() {
            if (angular.isDefined(interval)) {
                $interval.cancel(interval);
                interval = undefined;
            }
        });

        function completeItem(item) {
            item.processed_sage = !item.processed_sage;
            item.line_items = undefined;
            item.saveModel().then(function() {
                item.toggled = true;
            });
        }

    }


})();
