(function() {
    "use strict";

    angular
        .module('slate.library.contacts')
        .service('SlateTimeZonesSrv', RolesService);

        function RolesService($http) {

            var loaded = false;
            var srv = {
                getList: getList,
                list: [],
            };

            return srv;

            function getList() {
                if(!loaded && !srv.promise) {
                    srv.promise = $http.get(API_URL + 'utils/timezones/');
                    srv.promise.then(function(response) {
                        //populate assignable roles
                        srv.list = response.data;
                        loaded = true;
                    });

                }
                return srv.promise;
            }
        }

})();
