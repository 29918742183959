(function() {
    "use strict";

    angular
        .module('slate.schedule')
        .directive('slateAvailGrid', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/schedule/schedule.avail.dir.html',
            scope: {
                userId: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $q, SlateScheduleAvail) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;
        vm.loading = true;
        
        vm.save = saveAvail;

        vm.avail = new SlateScheduleAvail();
        modelPromise = vm.avail.loadFromServer(vm.userId);
        modelPromise.then(function() {
            vm.loading = false;
        });


        function saveAvail() {
            vm.avail.saveModel();
        }

    }


})();
