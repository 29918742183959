(function () {
    "use strict";

    angular
        .module('slate.qima')
        .controller('SlateBookingEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateBooking) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.booking = new SlateBooking();

        vm.booking.loadFromServer($stateParams.id).then(function () {
            if (vm.booking.name) {
                SlateTitle.set('Edit ' + vm.booking.name + ' - Booking');
            } else {
                SlateTitle.set('Edit Untitled Booking - Bookings');
            }
            vm.loading = false;

        }).catch(angular.noop);

        $scope.$watch('vm.booking.name', function () {
            if (vm.booking.name) {
                SlateTitle.set('Edit ' + vm.booking.name + ' - Bookings');
            } else {
                SlateTitle.set('Edit Untitled Booking - Bookings');
            }
        });

    }

})();


