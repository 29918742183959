(function () {
    "use strict";

    angular
        .module('slate.salesquotes')
        .controller('SlateSalesQuoteNewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateSalesQuote, SlateOrder) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.salesquote = new SlateSalesQuote();

        if($location.search().order) {
            vm.order = new SlateOrder();
            vm.order.loadFromServer($location.search().order).then(function() {
                vm.salesquote.order = vm.order.id;
                vm.salesquote.project = vm.order.project;
                vm.salesquote.sales_mgr = vm.order.sales_mgr;
                vm.salesquote.proj_mgr = vm.order.proj_mgr;
                vm.salesquote.company = vm.order.client;
                vm.salesquote.name = vm.order.name.substring(0,68);
                vm.loading = false;
            });
        } else {
            vm.loading = false;
        }

        $scope.$watch('vm.salesquote.name', function () {
            if (vm.salesquote.name) {
                SlateTitle.set('Edit ' + vm.salesquote.name + ' - Sales Quotes');
            } else {
                SlateTitle.set('Edit Untitled Quote - Sales Quotes');
            }
        });

    }

})();
