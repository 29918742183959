(function() {
    "use strict";

    angular
        .module('slate.inspections')
        .directive('slateInspectionResultList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/inspections/inspection.result.list.dir.html',
            scope: {
                inspection: '=',
                lineItem: '=?',
                fileSrv: '=?',
                showTotals: '=?',
                fields: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlateInspectionLineItem, SlateInspectionResult, SlateInspectionResultEditSrv, SlateInspectionResultNewSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

        if(!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'status',
                'qc_date',
                'inspection_date',
                'factory_confirmed',
                'time_spent',
                'travel_spent',
                'travel_mode',
            ];
            /* beautify preserve:end */
        }
        
        vm.editResult = editResult;
        vm.reinspect = reinspect;

        // Set base statuses
        vm.lineItem.inspection_results.forEach(function(result) { result.baseStatus = result.status; });

        function editResult(item, index) {
            SlateInspectionResultEditSrv.edit(item, {inspection: vm.inspection}).then(function(obj) {
                vm.lineItem.inspection_results[index] = obj;
            }, function() {

            });
        }

        function reinspect(result) {
            var newResult = new SlateInspectionResult();
            newResult.time_spent = result.time_spent;
            newResult.travel_spent = result.travel_spent;
            newResult.travel_mode = result.travel_mode;

            SlateInspectionResultNewSrv.edit(newResult, {inspection: vm.inspection}).then(function(obj) {
                vm.lineItem.inspection_results.splice(0, 0, obj);
            }, function() {

            });
        }


    }


})();
