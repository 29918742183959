(function() {
    "use strict";

    angular
        .module('slate.auditlog')
        .directive('slateAuditLogChanges', AuditLogList);

    
    function AuditLogList() {
        return {
            restrict: 'E',
            scope: {
                changes: '=?',
                parentModel: '=?',
                data: '=?',
            },
            templateUrl: '/partials/auditlogs/auditlog.changes.list.dir.html',
            controller: auditLogController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function auditLogController($stateParams, $http) {
        /* jshint validthis: true */
        var vm = this;
        
        vm.getField = getField;
        vm.getChangedField = getChangedField;
        vm.isLineItemUpdate = isLineItemUpdate;
        vm.getLineItem = getLineItem;

        vm.fields = {
            'purchaseorder': 'PO',
            'salesorder': 'SO',
            'order': 'ORDERS',
            'billing': 'BILLINGS',
            'qclog': 'QC_LOGS',
            'qcrequest': 'QC_REQ',
            'customercomplaint': 'CUSTOMER_COMPLAINTS',
            'shipment': 'LOGISTICS.SHIP',
            'rfq': 'RFQ',
        };

        function getField(change) {
            var model = vm.fields[vm.parentModel];
            if (Array.isArray(change[1]) && change[1][0] == 'line_items' || change[1][0] == 'goods') {
                if (vm.parentModel == 'billing' || vm.parentModel == 'rfq') {
                    model += '.LINEITEMS';
                } else if (vm.parentModel == 'shipment') {
                    model = 'LOGISTICS.GOODS';
                } else {
                    model += '_LINEITEMS';
                }
            }
            if (vm.parentModel == 'qcrequest') {
                return model + '.' + getChangedField(change[1]).toUpperCase();
            }
            return model + '.FIELDS.' + getChangedField(change[1]);
        }

        function isLineItemUpdate(change) {
            return Array.isArray(change) && change[0] == 'line_items' || change[0] == 'goods';
        }

        function getLineItem(change) {
            var idx = change[1][1];
            if (change[1][0] == 'goods') {
                return vm.data.goods[idx].name;
            }
            return vm.data.line_items[idx].name;
        }

        function getChangedField(change) {
            if (isLineItemUpdate(change)) {
                return change[2];
            }
            return change;
        }
    }

})();
