(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .directive('slatePurchaseForceBuild', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/purchaseorders/forcebuild.dir.html',
            scope: {
                purchaseOrder: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $q,
        $timeout,
        $state,
        $translate,
        $http,
        SlateLocation,
        SlateLocations,
        SlatePOLineItems,
        SlatePOLineItem,
        SlateGood,
        SlateBOM,
        SlateBOMItems
    ) {
        /* jshint validthis: true */
        var vm = this;

        vm.saveObject = saveObject;
        vm.isComplete = isComplete;

        vm.getTotals = getTotals;

        vm.getLocation = getLocation;
        vm.geolocate = geolocate;

        $scope.$watch('vm.purchaseOrder.id', function (old_val, new_val) {
            if (vm.purchaseOrder.id && !vm.saving) {
                vm.purchaseOrder.line_items.forEach(function(po_item) {
                    po_item.qty_to_build = 0;
                    po_item.bom_items = new SlateBOMItems();
                    po_item.bom_items.getList({bom: po_item.finished_good_info.bom}).then(function() {
                        po_item.bom_items.list.forEach(function(bom_item) {
                            bom_item.qty_to_receive = 0;
                            getStock(bom_item.component).then(function(stock) {
                                bom_item.stock = stock;
                                bom_item.stock.forEach(function(stockLoc) {
                                    stockLoc.qty_to_use = 0;
                                });
                            });
                        });
                    });
                });

                vm.vendor = vm.purchaseOrder.vendor;
                vm.delivery_to = vm.purchaseOrder.company;
                vm.ready_date = vm.purchaseOrder.date;
                vm.deliver_date = vm.purchaseOrder.desired_date;
            }
        });

        function getStock(component_id) {
            var deferred = $q.defer();
            var url = API_URL + "projects/project/" + component_id + "/stock/";

            $http.get(url).then(function(response) {
                deferred.resolve(response.data.payload);
            });

            return deferred.promise;
        }

        function getTotals(bom_item) {
            var total = 0;
            if(!Number.isNaN(bom_item.qty_to_receive)) {
                total += bom_item.qty_to_receive;
            }
            if(bom_item.stock) {
                bom_item.stock.forEach(function(stockItem) {
                    if(!Number.isNaN(stockItem.qty_to_use)) {
                        total = total + stockItem.qty_to_use;
                    }

                });
            }
            return total;
        }

        function saveObject() {
            var deferred = $q.defer();
            var url = API_URL + 'purchaseorders/po/' + vm.purchaseOrder.id + '/forcebuild/';
            var data = {};

            if (vm.saving === true) {
                return false;
            }

            vm.saving = true;
            vm.error = false;

            data.vendor = vm.vendor;
            data.warehouse = vm.origin_warehouse;
            data.client = vm.delivery_to;
            data.ready_date = vm.ready_date.getFullYear() + '-' + (vm.ready_date.getMonth()+1) + '-' + vm.ready_date.getDate();
            data.deliver_date =  vm.deliver_date.getFullYear() + '-' + ( vm.deliver_date.getMonth()+1) + '-' +  vm.deliver_date.getDate();

            data.transfers = [];
            data.receives = [];
            data.build = {};

            vm.purchaseOrder.line_items.forEach(function(po_item) {
                if(po_item.qty_to_build) {
                    data.build[po_item.id] = po_item.qty_to_build;
                }
                po_item.bom_items.list.forEach(function(bom_item) {
                    if(bom_item.qty_to_receive) {
                        data.receives.push({
                            po_lineitem: po_item.id,
                            component: bom_item.component,
                            qty: bom_item.qty_to_receive
                        });
                    }
                    bom_item.stock.forEach(function(stockLoc) {
                        if(stockLoc.qty_to_use) {
                            data.transfers.push({
                                warehouse_from: stockLoc.warehouse_id,
                                component: bom_item.component,
                                qty: stockLoc.qty_to_use,
                                finished_good: po_item.finished_good
                            });
                        }
                    });
                });
            });

            $http.post(url, {force_data: data}).then(function(response) {
                deferred.resolve(response.data.payload);
                vm.saving = false;
                /*
                $state.transitionTo(
                    'purchaseOrderEditPage',
                    { id: vm.purchaseOrder.id }
                );
                */
            }, function(e) {
                vm.saving = false;
                vm.error = true;
                console.log(e);
            });

            return deferred.promise;
        }

        function isComplete() {
            if(!vm.vendor) { return false; }
            if(!vm.origin_warehouse) { return false; }
            if(!vm.ready_date) { return false; }
            if(!vm.deliver_date) { return false; }
            return true;
        }



        /******* Mapping stuff  ***********/
        vm.sessionToken = uuidv4();

        function getLocation(q) {
            var promise;
            var deferred = $q.defer();

            //var url = "/geolocate/";
            var url = API_URL + "utils/geocomplete/";
            var params = {
                'sessiontoken': vm.sessionToken,
                'lang': $translate.use(),
                'address': q
            };

            if (q.length < 5) {
                deferred.reject();
                return deferred.promise;
            }

            promise = $http.get(url, {
                params: params
            }).then(function (response) {
                var locations = [];

                angular.forEach(response.data.predictions, function (item) {
                    var name = item.description;

                    locations.push({ 'name': name, 'details': item });
                });
                deferred.resolve(locations);

            });

            return deferred.promise;
        }

        function geolocate(address, variable) {
            var promise;
            var url = API_URL + "utils/geolocate/";
            var params = {
                'lang': $translate.use(),
                'address': address
            };

            if (address.length < 5) {
                vm[variable] = { lat: 0, lng: 0 };
                return false;
            }

            promise = $http.get(url, {
                params: params
            }).then(function (response) {
                var results = [];
                vm[variable] = { lat: 0, lng: 0 };
                console.log('results ', response.data.results);
                results = response.data.results;
                if (results && results.length) {
                    vm[variable].lat = results[0].geometry.location.lat;
                    vm[variable].lng = results[0].geometry.location.lng;
                }
            });

            return promise;
        }

    }

})();
