(function () {
    'use strict';

    angular
        .module('slate.library.logistics')
        .factory('SlateShipments', ModelList)
        .factory('SlateShipment', ModelDetail);

    const apiUrl = `${API_URL}logistics/shipment/`;

    function ModelList($http, $q, SlateShipment) {
        // Set to url relative to api root.

        // instantiate our initial object
        const list = function () {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.download = download;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            const avail_fields = new SlateShipment().getFieldsList();
            const update_filter = {};
            const update_fields = [];

            if (self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === 'object') {
                if (filters.fields) {
                    filters.fields.forEach((item) => {
                        update_fields.push(item);
                        if (avail_fields.includes(`${item}_info`)) {
                            update_fields.push(`${item}_info`);
                        }
                    });
                    update_filter.fields = update_fields;
                }

                self.filters = filters;
            }

            const promise = $http.get(apiUrl, {
                params: Object.assign({}, self.filters, update_filter),
                timeout: self.canceller.promise
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateShipment();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.totalItems = response.data.totalItems;
                self.permissions = response.data.permissions;
                self.canceller = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function download(filters) {

        }
    }

    function ModelDetail($http, $q, SlateGood) {
        // instantiate our initial object
        const model = function () {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', def: null, readonly: true },


            { name: 'name' },
            { name: 'composite_name', readonly: true },
            { name: 'slug', readonly: true },
            { name: 'status', def: 'new' },
            { name: 'status_change_log', type: 'json', def: [{ status: null, time_stamp: null, user: null }] },
            { name: 'shipment_number', readonly: true },

            { name: 'shipper' },
            { name: 'shipper_info', readonly: true },
            { name: 'carrier' },

            { name: 'warehouse' },
            { name: 'warehouse_info', readonly: true },

            { name: 'container_num' },
            { name: 'container_type' },
            { name: 'shipment_type' },
            { name: 'freight_handler' },
            { name: 'factory_incoterms' },
            { name: 'house_bol' },

            { name: 'forwarder' },
            { name: 'forwarder_info', readonly: true },

            { name: 'cost', type: 'float' },
            { name: 'cost_quote', type: 'float' },
            { name: 'quote_ref' },
            { name: 'origin' },
            { name: 'origin_street' },
            { name: 'origin_city' },
            { name: 'origin_state' },
            { name: 'origin_postal' },
            { name: 'origin_country' },
            { name: 'origin_gps', type: 'json', def: { lat: 0, lng: 0 } },
            { name: 'destination' },
            { name: 'destination_gps', type: 'json', def: { lat: 0, lng: 0 } },
            { name: 'final_destination' },
            { name: 'final_destination_street' },
            { name: 'final_destination_city' },
            { name: 'final_destination_state' },
            { name: 'final_destination_postal' },
            { name: 'final_destination_country' },
            { name: 'final_destination_gps', type: 'json', def: { lat: 0, lng: 0 } },

            { name: 'vessel_name' },

            { name: 'estimated_duty', type: 'float' },
            { name: 'actual_duty', type: 'float' },
            { name: 'hts_code' },

            { name: 'customs_ref' },
            { name: 'invoice_ref' },

            { name: 'etd', type: 'dateOnly' },
            { name: 'date_to_port', type: 'dateOnly' },
            { name: 'eta', type: 'dateOnly' },
            { name: 'cut_off_date', type: 'dateOnly' },
            { name: 'deadline', type: 'dateOnly' },

            { name: 'client_forwarder' },

            { name: 'origin_type' },

            { name: 'delivery_to' },
            { name: 'delivery_to_info', readonly: true },
            { name: 'delivery_warehouse' },
            { name: 'delivery_warehouse_info', readonly: true },
            { name: 'delivery_type' },
            { name: 'notes' },
            { name: 'datelog_notes', type: 'json', def: {} },
            { name: 'proj_mgr_string', readonly: true },

            { name: 'porequest' },

            { name: 'goods', type: 'child_list', model: SlateGood },
            { name: 'total_cbms', readonly: true },

            { name: 'parent_shipment' },
            { name: 'parent_shipment_info', readonly: true },

            { name: 'updated', readonly: true, type: 'date' },
            { name: 'created', readonly: true, type: 'date' },
            { name: 'created_by', readonly: true },
            { name: 'created_by_info', readonly: true },
            { name: 'updated_by', readonly: true },
            { name: 'updated_by_info', readonly: true }

        ];
        /* beautify preserve:end */


        model.prototype.ship_types = [
            'oceanlcl',
            'oceanfcl',
            'oceanexp',
            'air',
            'truck',
            'ups',
            'fedex',
            'dhl',
            'other'
        ];
        model.prototype.incoterms = [
            'exw',
            'fca',
            'fob',
            'cif',
            'cpt',
            'cip',
            'dat',
            'dap',
            'ddp',
            'ddu',
            'other'
        ];

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.getFieldsList = getFields;
        model.prototype.getDisplayName = getDisplayName;
        model.prototype.createBillings = createBillings;
        return model;

        function getDisplayName() {
            /* jshint validthis: true */
            const self = this;
            return `SID# ${self.shipment_number} - ${self.name}`;
        }

        function getFields() {
            const fields_list = [];
            fields.forEach((field) => {
                fields_list.push(field.name);
            });
            return fields_list;
        }

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;

                if (field.type === 'child_list') {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();


            if (typeof payload !== 'object') {
                // eslint-disable-next-line no-console
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                self[field.name] = payload[field.name];

                if (field.type === 'date' && payload[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type === 'dateOnly' && payload[field.name]) {
                    const parts = self[field.name].split('-');
                    self[field.name] = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
                }

                if (field.type === 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.log('didnt parse json', e);
                    }
                }

                if (field.type === 'float' && payload[field.name]) {
                    self[field.name] = parseFloat(self[field.name]);
                    if (Number.isNaN(self[field.name])) {
                        self[field.name] = null;
                    }
                }
                if (field.type === 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach((item, idx, array) => {
                            const obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.log('error loading: ', e);
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;
            const filters = {};

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                // eslint-disable-next-line no-console
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            const promise = $http.get(modelUrl, {
                params: filters
            });

            promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (field.type === 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if (field.type === 'date' && self[field.name]) {
                        // data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth()+1) + '-' + self[field.name].getDate();
                        data[field.name] = self[field.name].toISOString();
                    }
                    if (field.type === 'dateOnly' && self[field.name]) {
                        data[field.name] = `${self[field.name].getFullYear()}-${self[field.name].getMonth() + 1}-${self[field.name].getDate()}`;
                        // data[field.name] = self[field.name].toISOString();
                    }
                    if (field.trim && data[field.name]) {
                        data[field.name] = data[field.name].substr(0, field.trim);
                    }
                    if (field.type === 'child_list' && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach((item) => {
                            if (item.toDelete) { return false; }
                            data[field.name].push(item.getModelData());
                            return null;
                        });
                    }
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then((response) => {
                const promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            const promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function createBillings(btype, salesorder) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/createbillings/`;
            } else {
                deferred.reject();
                return deferred.promise;
            }

            const params = { billing_type: btype || 'Final' };

            if (salesorder) {
                params.salesorder = salesorder;
            }

            const promise = $http.post(modelUrl, params);
            promise.then((response) => {
                deferred.resolve(response.data.payload.id);
            }, () => {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
