(function() {
    "use strict";

    angular
        .module('slate.roles')
        .service('SlateAssignedRolesEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal) {
        var templateUrl = '/partials/roles/roles.edit.srv.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(options) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                //size: 'lg',
                resolve: {
                    options: function() {
                        return options;
                    },
                }
            });

            return modalInstance.result.then(function(results) {
                return results;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($uibModalInstance, SlateAssignedRoles, options) {
        /* jshint validthis: true */
        var vm = this;

        vm.options = options;

        vm.saving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.assroles = new SlateAssignedRoles();
        vm.addRole = addRole;

        vm.assroles.getList({
            parent_type: vm.options.parent_type,
            parent_fk: vm.options.parent_fk
        }).then(function() {

        });

        function addRole() {
            vm.assroles.addBlank({
                parent_type: vm.options.parent_type,
                parent_fk: vm.options.parent_fk
            });
        }

        function saveObject() {
            if (vm.saving === true) {
                return false;
            }
            vm.saving = true;

            vm.assroles.saveAll().then(function() {
                vm.saving = false;
                $uibModalInstance.close(vm.assroles);
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
