(function () {
    'use strict';

    angular
        .module('slate.productprofiles')
        .service('SlateProductProfileEmailViewSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateProductProfileEmail) {
        const templateUrl = '/partials/productprofiles/emails/productprofiles.email.dialog.html';

        const srv = {
            view: editObj
        };


        return srv;


        function editObj(obj) {
            const modalInstance = $uibModal.open({
                templateUrl,
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj() {
                        const email = new SlateProductProfileEmail();
                        if (!obj) { return email; }

                        return email.loadFromServer(obj.id);
                    }
                }
            });

            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $http, $q, $uibModalInstance, obj) {
        /* jshint validthis: true */
        const vm = this;

        vm.obj = obj;
        vm.cancel = cancel;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
