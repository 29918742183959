(function() {
    "use strict";

    angular
        .module('slate.logistics')
        .service('SlateShipmentQtyEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal) {
        var templateUrl = '/partials/logistics/qtyadjust/adjust.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    shipment: function() {
                        return obj;
                    },
                }
            });

            return modalInstance.result.then(function(results) {
                return results;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($uibModalInstance, $http, shipment, SlateAuth, SlateAccountPrdSrv) {
        /* jshint validthis: true */
        var vm = this;

        vm.auth = SlateAuth;
        vm.accountingPeriods = SlateAccountPrdSrv;
        vm.shipment = shipment;

        vm.cancel = cancel;
        vm.save = saveObject;
        vm.isComplete = isComplete;
        vm.closedAccounting = false;

        vm.adjustments = {};

        ['eta', 'etd'].forEach(function(field) {
            console.log(field, ' is ', vm.shipment[field], ' vs ', vm.accountingPeriods.earliest);
            if(vm.shipment[field] < vm.accountingPeriods.earliest) {
                vm.closedAccounting = true;
            }
        });

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function saveObject() {
            var apiUrl = API_URL + 'logistics/shipment/';
            var modelUrl = apiUrl + vm.shipment.id + '/adjust/';

            vm.saving = true;

            $http.post(modelUrl,
                {
                    adjustments: vm.adjustments,
                }
            ).then(function() {
                vm.saving = false;
                $uibModalInstance.close();
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function isComplete() {
            if(!Object.entries(vm.adjustments).some(function(item) {
                if(item[1]) { return true; }
                return false;
            })) { return false; }

            if(Object.entries(vm.adjustments).some(function(item) {
                if(item[1] === 0) { return true; }
                if(item[1] === "0") { return true; }
                return false;
            })) { return false; }

            if(vm.shipment.goods.some(function(good) {
                if(typeof(vm.adjustments[good.id]) === 'undefined') { return false; }
                if(vm.adjustments[good.id] - good.qty < 0) { return true; }
                return false;
            })) { return false; }

            return true;
        }




    }



})();
