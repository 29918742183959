(function () {
    'use strict';

    angular
        .module('slate.companies')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
            .state('companyList', {
                url: '/companies/',
                templateUrl: '/partials/companies/companies.list.page.html',
                controllerAs: 'vm',
                current_app: 'companies'

            })
            .state('vendorList', {
                url: '/vendors/',
                templateUrl: '/partials/companies/vendors.list.page.html',
                controllerAs: 'vm',
                current_app: 'companies'
            })
            .state('companyDetails', {
                url: '/companies/:slug/',
                templateUrl: '/partials/companies/company.details.page.html',
                controller: 'SlateCompanyDetailsPageController',
                controllerAs: 'vm',
                current_app: 'companies',

                resolve: {
                    company($stateParams, SlateCompany) {
                        const company = new SlateCompany();
                        return company.loadFromServer($stateParams.slug);
                    }
                }
            })


            .state('companyDetails.projects', {
                url: 'projects/',
                template: "<slate-projects-list filters='{company: vm.company.id}'></slate-projects-list>",
                current_app: 'companies',
                tabName: 'Projects',
                tabPerms: 'projects_can_view_all'
            })
            .state('companyDetails.orders', {
                url: 'orders/',
                template: "<slate-order-list filters='{client: vm.company.id}'></slate-order-list>",
                current_app: 'companies',
                tabName: 'Orders',
                tabPerms: 'orders_can_access'
            })

            .state('companyDetails.employees', {
                url: 'employees/',
                template: "<slate-employees-list filters='{company: vm.company.id}'></slate-employees-list>",
                tabName: 'Employees',
                current_app: 'companies',
                reloadOnSearch: false,
                tabPerms: 'contacts_can_view_all'
            })
            .state('companyDetails.ouremployees', {
                url: 'our-employees/',
                template: "<slate-employees-list filters='{company: vm.company.id}'></slate-employees-list>",
                tabName: 'Employees',
                current_app: 'companies',
                reloadOnSearch: false,
                tabPerms: 'contacts_can_view_company'
            })
            .state('companyDetails.accounting', {
                url: 'accounting/',
                template: "<slate-accounting company='vm.company'></slate-accounting>",
                current_app: 'companies',
                tabName: 'Accounting',
                tabPerms: 'billings_can_access'
            })
            .state('companyDetails.billingListPage', {
                url: 'billings/',
                template: "<slate-billings-list filters='{company: vm.company.id}' fields=\"['invoice_num','name','billing_type','status','date','proj_mgr','sales_mgr','total','sf_id']\"></slate-billing-list>",
                current_app: 'companies',
                tabName: 'Billings',
                tabPerms: 'billings_can_access'
            })
            .state('companyDetails.shipments', {
                url: 'shipments/',
                template: "<slate-shipments-list filters='{company:vm.company.id}'></slate-shipments-list>",
                current_app: 'companies',
                tabName: 'Shipments',
                tabPerms: 'logistics_can_access'
                // tabConditional: 'project',
                // tabPerms: 'rfq_can_access',
            })
            .state('companyDetails.rfqlogs', {
                url: 'rfqlogs/',
                template: "<slate-rfqs-list filters='{company: vm.company.id}'></slate-rfqs-list>",
                current_app: 'companies',
                tabName: 'RFQS',
                tabPerms: 'rfq_can_access',
                nested: 'Requests'
            })
            .state('companyDetails.purchaseorderList', {
                url: 'purchaseorders/',
                template: "<slate-purchase-order-list show-totals='true' filters='{company: vm.company.id}' hide-filters='true' show-new='false'></slate-purchase-order-list>",
                current_app: 'companies',
                tabName: 'Purchase Orders',
                tabPerms: 'purchaseorders_can_access',
                nested: 'Requests'
            })
            .state('companyDetails.salesorderList', {
                url: 'salesorders/',
                template: "<slate-sales-order-list show-totals='true' filters='{company: vm.company.id}' hide-filters='true' show-new='false'></slate-sales-order-list>",
                current_app: 'companies',
                tabName: 'Sales Orders',
                tabPerms: 'salesorders_can_access',
                nested: 'Requests'
            })
            .state('companyDetails.poList', {
                url: 'porequests/',
                template: "<slate-po-request-list filters='{company: vm.company.id}' hidenew='true'></slate-po-request-list>",
                current_app: 'companies',
                tabName: 'SO/PO Requests (legacy)',
                tabPerms: 'pos_can_access',
                nested: 'Requests'
            })
            .state('companyDetails.invList', {
                url: 'invoice-requests/',
                template: "<slate-invoice-list filters='{company: vm.company.id}'></slate-invoice-list>",
                current_app: 'companies',
                tabName: 'Receiving / Invoice',
                tabPerms: 'invoices_can_access',
                nested: 'Requests'
            })
            .state('companyDetails.inspections', {
                url: 'inspections/',
                template: "<slate-inspection-list filters='{company: vm.company.id}'></slate-inspection-list>",
                current_app: 'companies',
                tabName: 'Inspections',
                tabPerms: 'qcrequest_can_access',
                nested: 'Requests'
            })
            .state('companyDetails.qcrequests', {
                url: 'qcrequests/',
                template: "<slate-qcrequest-list filters='{client: vm.company.id}'></slate-qcrequest-list>",
                current_app: 'companies',
                tabName: 'QC Requests',
                tabPerms: 'qcrequest_can_access',
                nested: 'Requests'
            })
            .state('companyDetails.qclogs', {
                url: 'qclogs/',
                template: "<slate-qclog-list filters='{client: vm.company.id}'></slate-qclog-list>",
                current_app: 'companies',
                tabName: 'QC Logs',
                tabPerms: 'qclog_can_access',
                nested: 'Requests'
            })
            .state('companyDetails.productprofiles', {
                url: 'productprofiles/',
                template: "<slate-product-profiles-list filters='{company: vm.company.id}' show-new='false' hide-filters='true'></slate-product-profiles-list>",
                current_app: 'companies',
                tabName: 'Product Profiles',
                tabPerms: 'productprofiles_can_access',
                nested: 'Requests'
            })

            // .state('companyDetails.pofiles', {
            //     url: 'purchase_order_files/*path?',
            //     controllerAs: 'vm',
            //     bindToController: true,
            //     tag: 'purchase-orders',
            //     template: "<slate-file-browser parent-type='\"company\"' parent-fk='vm.company.id'></slate-file-browser>",
            //     current_app: 'companies',
            //     tabName: 'Purchase Order Files',
            //
            //     defaults: {path: ''}
            // })
            .state('companyDetails.clientfiles', {
                url: 'clientfiles/*path?',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'client-files',
                template: "<slate-file-browser parent-type='\"company\"' parent-fk='vm.company.id'></slate-file-browser>",
                current_app: 'companies',
                tabName: 'Client Files',
                defaults: { path: '' },
                nested: 'Files'
            })
            .state('companyDetails.cartonmarkings', {
                url: 'cartonmarkingtemplates',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'carton-marking-templates',
                template: "<slate-carton-marking-list filters='{company: vm.company.id}'></slate-carton-marking-list>",
                current_app: 'companies',
                tabName: 'Carton Marking Templates',
                tabPerms: 'companies_can_edit',
                defaults: { path: '' },
                nested: 'Files'
            })

            .state('companyDetails.internalfiles', {
                url: 'files/*path?',
                controllerAs: 'vm',
                bindToController: true,
                tag: 'internal-files',
                template: "<slate-file-browser parent-type='\"company\"' parent-fk='vm.company.id'></slate-file-browser>",
                current_app: 'companies',
                tabName: 'Internal Files',
                tabPerms: 'files_can_view_non_client_files',
                defaults: { path: '' },
                nested: 'Files'
            })
            .state('companyDetails.allfiles', {
                url: 'allfiles/*path?',
                controllerAs: 'vm',
                bindToController: true,
                template: "<slate-file-browser parent-type='\"company\"' parent-fk='vm.company.id'></slate-file-browser>",
                current_app: 'companies',
                tabName: 'All Files',
                tabPerms: 'files_can_view_all_files',
                defaults: { path: '' },
                nested: 'Files'

            });
    }
})();
