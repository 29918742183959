(function() {
    "use strict";

    angular
        .module('slate.controlpanel')
        .directive('slateControlPanelMaterials', slateDir);

    function slateDir () {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/controlpanel/controlpanel.materials.dir.html',
            scope: {
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ListController($scope, SlateSettings) {
        /* jshint validthis: true */
        var vm = this;

        vm.addCat = addCat;
        vm.deleteCat = deleteCat;

        vm.addMat = addMat;
        vm.deleteMat = deleteMat;
        vm.sortMat = sortMat;

        vm.save = save;

        SlateSettings.getConfig('material_categories').then(function(config) {
            vm.categories = config;
            if(!vm.categories.value.includes('Finished Good')) {
                vm.categories.value.push('Finished Good');
            }
            if(!vm.categories.value.includes('Other')) {
                vm.categories.value.push('Other');
            }
        });

        SlateSettings.getConfig('materials').then(function(config) {
            vm.material_list = config;
        });

        vm.dragOptions = {
            handle: ".handle",
        };

        function addCat() {
            if(vm.categories.value.indexOf('') == -1 ) {
                vm.categories.value.unshift('');
            }
        }

        function deleteCat(cat, idx) {
            if(confirm('Are you sure you want to delete the category '+cat)) {
                vm.categories.value.splice(idx, 1);
            }
        }

        function addMat() {
            var cat = 'Other';
            if(vm.category_filter) { cat = vm.category_filter; }
            vm.material_list.value.unshift({category:cat, name: ''});
        }

        function deleteMat(mat, idx) {
            if(confirm('Are you sure you want to delete the material '+mat.name)) {
                vm.material_list.value.splice(idx, 1);
            }
        }

        function sortMat() {
            vm.material_list.value.sort(function(a,b) {
                if(a.category < b.category) { return -1; }
                if(a.category > b.category) { return 1; }
                if(a.name.toUpperCase() < b.name.toUpperCase()) { return -1; }
                if(a.name.toUpperCase() > b.name.toUpperCase()) { return 1; }
                return 0;
            });
        }

        function save() {
            vm.saving = true;
            vm.categories.value.reduceRight(function(last, item, index){
                if(item === "") {
                    vm.categories.value.splice(index, 1);
                }
                return true;
            });
            vm.categories.saveModel().then(function() {
                vm.material_list.saveModel().then(function() {
                    vm.saving = false;
                });
            });
        }

    }


})();
