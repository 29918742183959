(function () {
    angular
        .module('slate.library.productprofiles')
        .factory('SlateProductProfileEmails', ModelList)
        .factory('SlateProductProfileEmail', ModelDetail);

    const apiUrl = `${API_URL}productprofiles/profileemail/`;

    function ModelList($http, $q, SlateProductProfileEmail) {
        // Set to url relative to api root.

        // instantiate our initial object
        const list = function () {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;

        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            const avail_fields = new SlateProductProfileEmail().getFieldsList();
            const update_filter = {};
            const update_fields = [];

            if (self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === 'object') {
                if (filters.fields) {
                    filters.fields.forEach((item) => {
                        update_fields.push(item);
                        if (avail_fields.includes(`${item}_info`)) {
                            update_fields.push(`${item}_info`);
                        }
                    });
                    update_filter.fields = update_fields;
                }

                self.filters = filters;
            }

            const promise = $http.get(apiUrl, {
                params: Object.assign({}, self.filters, update_filter),
                timeout: self.canceller.promise
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateProductProfileEmail();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.totalItems = response.data.totalItems;
                self.permissions = response.data.permissions;
                self.canceller = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        const model = function () {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', def: null, readonly: true },

            { name: 'profile' },
            { name: 'profileitem' },
            { name: 'profileitem_info' },

            { name: 'to_email' },

            { name: 'subject' },
            { name: 'text_body' },
            { name: 'html_body' },

            { name: 'created', readonly: true, type: 'date' },
            { name: 'created_by', readonly: true },
            { name: 'created_by_info', readonly: true }
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.getFieldsList = getFields;
        return model;

        function getFields() {
            const fields_list = [];
            fields.forEach((field) => {
                fields_list.push(field.name);
            });
            return fields_list;
        }

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = _.cloneDeep(field.def);

                if (field.type === 'child_list') {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                // console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                self[field.name] = payload[field.name];

                if (field.type === 'date' && payload[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type === 'dateOnly' && payload[field.name]) {
                    const parts = self[field.name].split('-');
                    self[field.name] = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
                }

                if (field.type === 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                        // console.log('didnt parse json', e);
                    }
                }

                if (field.type === 'float' && payload[field.name]) {
                    self[field.name] = parseFloat(self[field.name]);
                    if (Number.isNaN(self[field.name])) {
                        self[field.name] = null;
                    }
                }
                if (field.type === 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach((item, idx) => {
                            const obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch (e) {
                        // console.log('error loading: ', e);
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;
            const filters = {};

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                // console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            const promise = $http.get(modelUrl, {
                params: filters
            });

            promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            // const self = this;

            return false;

            // let modelUrl = apiUrl;

            // let promise;
            // const data = {};

            // if (self.id) {
            //     modelUrl = `${apiUrl + self.id}/`;
            // }

            // angular.forEach(fields, (field) => {
            //     if (!field.readonly) {
            //         data[field.name] = self[field.name];
            //         if (field.type === 'json') {
            //             data[field.name] = JSON.stringify(self[field.name]);
            //         }
            //         if (field.type === 'date' && self[field.name]) {
            //             // data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth()+1) + '-' + self[field.name].getDate();
            //             data[field.name] = self[field.name].toISOString();
            //         }
            //         if (field.type === 'dateOnly' && self[field.name]) {
            //             data[field.name] = `${self[field.name].getFullYear()}-${self[field.name].getMonth() + 1}-${self[field.name].getDate()}`;
            //             // data[field.name] = self[field.name].toISOString();
            //         }
            //         if (field.trim && data[field.name]) {
            //             data[field.name] = data[field.name].substr(0, field.trim);
            //         }
            //         if (field.type === 'child_list' && Array.isArray(data[field.name])) {
            //             data[field.name] = [];
            //             self[field.name].forEach((item) => {
            //                 if (item.toDelete) { return false; }
            //                 data[field.name].push(item.getModelData());
            //                 return true;
            //             });
            //         }
            //     }
            // });

            // promise = $http.post(modelUrl, data);
            // promise = promise.then((response) => {
            //     const promises = [];
            //     self.loadFromPayload(response.data.payload);
            //     self.permissions = response.data.permissions;

            //     return $q.all(promises);
            // });

            // return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            // const self = this;

            return false;

            // const deferred = $q.defer();
            // let modelUrl = apiUrl;

            // if (self.id) {
            //     modelUrl = `${apiUrl + self.id}/`;
            // } else {
            //     deferred.reject(self);
            //     return deferred.promise;
            // }

            // const promise = $http.delete(modelUrl);
            // promise.then(() => {
            //     self.id = null;
            //     deferred.resolve(self);
            // });

            // return deferred.promise;
        }
    }
})();
