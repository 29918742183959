(function () {
    "use strict";

    angular
        .module('slate.orderplan')
        .directive('slateOrderplan', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/orderplan/orderplan.edit.dir.html',
            scope: {
                order: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $stateParams, $timeout, $q, $state, $location, SlateAuth, getLinkSrv, SlateOrderPlanSteps, SlateOrderPlanStep, SlateOrderPlanEditSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;
        if(!vm.order) { vm.order = $stateParams.orderid; }

        vm.save = save;
        vm.saving = false;
        vm.load = true;
        vm.isComplete = isComplete;
        vm.selectedStep = null;

        vm.addstep = addstep;
        vm.editstep = editstep;

        vm.rows = [];

        vm.froalaOptions = {
            toolbarButtons: ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            zIndex: 10,
        };

        vm.steps = new SlateOrderPlanSteps();

        $scope.$watch('vm.order', function (old_val, new_val) {

        });

        getSteps();

        function getSteps() {
            if (vm.order && !vm.saving) {
                vm.loading = true;
                vm.steps.getList({ order: vm.order }).then(function () {
                    vm.tree = vm.steps.getTree();
                    vm.loading = false;
                });
            }
        }

        function save(redirect) {
            var promises = [];
            vm.saving = true;

            vm.steps.list.forEach(function(step) {
                step.order = vm.order;
                promises.push(step.saveModel());
            });

            return $q.all(promises).then(function () {
                vm.rows = vm.steps.getRows();
            }, function (e) {
                vm.saving = false;
                vm.error = true;
            });
        }

        function isComplete() {
            var is_complete = true;

            return is_complete;
        }

        function editstep(step) {
            if(step.step_type == 'start') { return false; }

            SlateOrderPlanEditSrv.edit(step, {order: vm.order}).then(function(newstep) {
                vm.lastSaved = newstep;
                getSteps();
            });
        }
        $scope.$on('reloadOrderPlan', function() {
            $timeout(function() { getSteps(); }, 100);
        });

        function addstep() {
            var step = new SlateOrderPlanStep();
            step.name = 'New Step';
            step.step_type = "misc";
            step.parents = [];
            if(vm.lastSaved) {
                step.parents.push(vm.lastSaved.id);
                step.warehouse = vm.lastSaved.warehouse;
            }
            SlateOrderPlanEditSrv.edit(step, {order: vm.order}).then(function(newstep) {
                vm.lastSaved = newstep;
                getSteps();
            });
        }
    }

})();