(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .controller('SlatePurchaseOrderNewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlatePurchaseOrder, SlateOrder) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.purchaseorder = new SlatePurchaseOrder();
        
        if($location.search().order) {
            vm.order = new SlateOrder();
            vm.order.loadFromServer($location.search().order).then(function() {
                vm.purchaseorder.order = vm.order.id;
                vm.purchaseorder.project = vm.order.project;
                vm.purchaseorder.sales_mgr = vm.order.sales_mgr;
                vm.purchaseorder.proj_mgr = vm.order.proj_mgr;
                vm.purchaseorder.company = vm.order.client;
                vm.purchaseorder.client_po = vm.order.client_po;
                vm.purchaseorder.name = vm.order.name.substring(0,68);
                vm.loading = false;
            });
        } else {
            vm.loading = false;
        }
        
        $scope.$watch('vm.purchaseorder.name', function () {
            if (vm.purchaseorder.name) {
                SlateTitle.set('Edit ' + vm.purchaseorder.name + ' - Purchase Orders');
            } else {
                SlateTitle.set('Edit Untitled order - Purchase Orders');
            }
        });

    }

})();


