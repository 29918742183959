(function () {
    'use strict';

    angular
        .module('slate.contacts')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
            .state('contactsList', {
                url: '/contacts/',
                templateUrl: '/partials/contacts/contacts.list.page.html',
                controllerAs: 'vm',
                current_app: 'contacts'

            })
            .state('contactDetails', {
                url: '/contacts/:slug/',
                templateUrl: '/partials/contacts/contacts.details.page.html',
                controller: 'SlateContactsDetailsPageController',
                controllerAs: 'vm',
                current_app: 'contacts',

                resolve: {
                    contact($stateParams, SlateContact, SlateEmployees) {
                        const contact = new SlateContact();
                        const contactPromise = contact.loadFromServer(
                            $stateParams.slug
                        );
                        contactPromise.then(() => {
                            contact.employment = new SlateEmployees();
                            contact.employment.getList({
                                contact: contact.id
                            });
                        });
                        return contactPromise;
                    }
                }
            })

            .state('contactDetails.projects', {
                url: 'projects/',
                template: "<slate-projects-list filters='{contact: vm.contact.id}'></slate-projects-list>",
                current_app: 'contacts',
                tabName: 'Projects',
                tabPerms: 'projects_can_view_all'
            })

            .state('contactDetails.orders', {
                url: 'orders/',
                template: "<slate-order-list filters='{contact:vm.contact.id}'></slate-order-list>",
                current_app: 'contacts',
                tabName: 'Orders',
                tabPerms: 'orders_can_access'
            })
            .state('contactDetails.purchaseorderList', {
                url: 'purchaseorders/',
                template: "<slate-purchase-order-list show-totals='true' filters='{contact: vm.contact.id}' hide-filters='true' show-new='false'></slate-purchase-order-list>",
                current_app: 'contacts',
                tabName: 'Purchase Orders',
                tabPerms: 'purchaseorders_can_access',
                nested: 'Requests'
            })

            .state('contactDetails.poList', {
                url: 'porequests/',
                template: "<slate-po-request-list filters='{contact: vm.contact.id}' hidenew='true'></slate-po-request-list>",
                current_app: 'contacts',
                tabName: 'SO/PO Requests (legacy)',
                tabPerms: 'pos_can_access'
            })
            .state('contactDetails.settings', {
                url: 'settings/',
                templateUrl: '/partials/contacts/settings.page.html',
                current_app: 'contacts',
                tabName: 'Settings',
                tabPerms: 'logindetails_can_see_tab'
            })
            .state('contactDetails.notificationPrefs', {
                url: 'notification_prefs/',
                template: "<slate-notification-prefs-list user='vm.contact.id'></slate-notification-prefs-list>",
                current_app: 'contacts',
                tabName: 'Notification Prefs',
                tabPerms: 'logindetails_can_see_tab'
            });
    }
})();
