(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetMilestones', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.milestones.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $interval, SlateAuth, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var interval = null;
        var speed = vm.settings.speed || 60000;

        vm.auth = SlateAuth;

        vm.data = [];
        vm.getLink = getLinkSrv;

        function update_data() {
            var params = {};
            if(vm.auth.user.permissions.requests_can_assign && vm.settings.user) {
                params.user = vm.settings.user;
            }
            $http.get(API_URL + 'dashboard/myincompletemilestones/', {params: params}).then(function(res) {
                if (vm.settings && vm.settings.orderby && vm.settings.orderby == 'Desired By') {
                    vm.data = res.data.sort(function(a,b) {
                        if(a.milestone.desired_by < b.milestone.desired_by) { return -1; }
                        if(a.milestone.desired_by > b.milestone.desired_by) { return 1; }
                        return 0;
                    });
                    return vm.data;
                }

                if (vm.settings && vm.settings.orderby && vm.settings.orderby == 'Desired By (reverse)') {
                    vm.data = res.data.sort(function(a,b) {
                        if(a.milestone.desired_by < b.milestone.desired_by) { return 1; }
                        if(a.milestone.desired_by > b.milestone.desired_by) { return -1; }
                        return 0;
                    });
                    return vm.data;
                }

                if (vm.settings && vm.settings.orderby && vm.settings.orderby == 'Name') {
                    vm.data = res.data.sort(function(a,b) {

                        if(a.milestone.name < b.milestone.name) { return -1; }
                        if(a.milestone.name > b.milestone.name) { return 1; }
                        return 0;
                    });
                    return vm.data;
                }

                vm.data = res.data;
            });

        }

        // update_data();

        interval = $interval(function() {
            update_data();
        }, speed);

        $scope.$watchCollection('vm.settings', function(items) {
            update_data();
        }, true);

        $scope.$on('$destroy', function() {
            if (angular.isDefined(interval)) {
                $interval.cancel(interval);
                interval = undefined;
            }
        });

    }


})();
