(function() {
    "use strict";

    angular
        .module('slate.companies')
        .directive('slateCartonMarkingList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/companies/cartonmarking.list.dir.html',
            scope: {
                modelFilters: '=?filters',
                hideFilters: '=?',
                showTotals: '=?',
                fields: '=?',
                company: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlateCartonMarkingDialog, SlateCartonMarking, SlateCartonMarkings, SlateCartonMarkingSelection) {
        /* jshint validthis: true */
        var vm = this;

        vm.loading = true;

        vm.cartonMarkings = new SlateCartonMarkings();

        vm.newCartonMarking = newCartonMarking;
        vm.editCartonMarkingTemplate = editCartonMarkingTemplate;
        vm.saveCompany = saveCompany;
        vm.selectTemplate = selectTemplate;

        vm.fields = [
            'created',
        ];

        vm.filters = {};
        angular.extend(vm.filters, vm.modelFilters, $location.search());

        function newCartonMarking() {
            var cartonMarking = new SlateCartonMarking();
            cartonMarking.company = vm.filters.company;
            SlateCartonMarkingDialog.edit(vm.filters.company, cartonMarking).then(function() {
                updateFromFilter();
            }, function() {

            });
        }

        function editCartonMarkingTemplate(cartonMarking) {
            SlateCartonMarkingDialog.edit(vm.filters.company, cartonMarking.id).then(function() {

            }, function() {

            });
        }

        function selectTemplate() {
            SlateCartonMarkingSelection.edit(vm.filters.company).then(function() {
                updateFromFilter();
            }, function() {

            });
        }

        function saveCompany() {
            vm.saving = true;
            vm.company.saveModel().then(function() {
                vm.saving = false;
                vm.selecting = false;
            }, function() {
                vm.error = true;
                vm.saving = false;
            });
        }

        function updateFromFilter() {
            vm.cartonMarkings.getList(vm.filters).then(function() {

            }, function() {

            });
        }
        updateFromFilter();
        

    }


})();
