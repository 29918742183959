(function() {
    "use strict";

    angular
        .module('slate.library.settings')
        .factory('SlateConfig', ModelDetail);


    function ModelDetail($http, $q) {
        // Set to url relative to api root.
        var apiUrl = API_URL + 'config/setting/';

        // instantiate our initial object
        var model = function construct() {
            var self = this;
            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },
            
            {name: 'key', def: null },
            {name: 'perm', def: null, readonly: true },
            {name: 'value', def: {}, type: 'json'},
            
            {name: 'updated', def: null, readonly: true },
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        
        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });

            deferred.resolve(self);
            return deferred.promise;
        }



        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                self[field.name] = payload[field.name];
                if(
                    (typeof payload[field.name] == 'undefined' && typeof self[field.name] == 'undefined' ) ||
                    (payload[field.name] === null && self[field.name] === null )
                ) {
                    self[field.name] = field.def;
                    return self[field.name];
                }

                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) { console.log('json parse failed', e); }
                }
            });
            
            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without key');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                return self.loadFromPayload(response.data.payload).then(function() {
                        deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.key + '/';
            }

            angular.forEach(fields, function(field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                }

                if (field.type == 'json') {
                    data[field.name] = JSON.stringify(self[field.name], function(key, value) {
                        if (key == "$$hashKey") {
                            return undefined;
                        }
                        return value;
                    });
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                return self.loadFromPayload(response.data.payload);
            });

            return promise;
        }

    }

})();
