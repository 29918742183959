//import { request } from "http";

(function() {
    "use strict";

    angular
        .module('slate.library.pos')
        .factory('SlatePOs', ModelList)
        .factory('SlatePO', ModelDetail);

    var apiUrl = API_URL + 'pos/porequest/';

    function ModelList($http, $q, SlatePO) {
        // Set to url relative to api root.

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        self.pagination = {
            total: 0,
            page: 1,
            count: 25
        };
        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlatePO();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.pagination = {
                    total: parseInt(response.data.pagination.total),
                    page: parseInt(response.data.pagination.page),
                    count: parseInt(response.data.pagination.count),
                };
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },
            {name: 'description'},
            {name: 'status', def: 'Draft'},

            {name:'company'},
            {name:'company_name', readonly: true},
            {name:'company_slug', readonly: true},

            {name: 'project'},
            {name: 'project_info', readonly: true},

            {name: 'proj_mgr'},
            {name: 'proj_mgr_info', readonly: true},

            {name: 'order'},
            {name: 'order_info', readonly: true},

            {name:'vendor'},
            {name:'vendor_name', readonly: true},
            {name:'vendor_slug', readonly: true},

            {name:'date', type:"date"},
            {name:'reorder'},
            {name:'revision'},
            {name:'revised', def:[{}], type:"json"},
            {name:'part_number'},
            {name:'createdby', readonly: true},
            {name:'salesperson'},
            {name:'salesperson_info', readonly: true},
            {name:'jansy_po'},
            {name:'client_po'},
            {name:'billto', def: "Jansy Packaging\n"+
                                "2200 Fletcher Ave, Suite 514\n"+
                                "Fort Lee, NJ 07024\n"+
                                "Attn: Accounts Payable\n"+
                                "201-523-5447"},
            {name:'shipto'},
            {name:'freightto'},
            {name:'shipvia', def:[], type:"json"},
            {name:'terms', def:[], type:"json"},
            {name:'desireddate', def:[], type:"json"},
            {name:'qty_splits', def:[], type:"json"},
            {name:'frieghtcost'},
            {name:'pricetotal', readonly: true},
            {name:'selltotal', readonly: true},
            {name:'margin', readonly: true},
            {name:'notes'},
            {name:'pi_required'},
            {name:'ship_terms'},
            {name:'revision_notes'},
            {name:'created', readonly: true},
            {name:'createdby', readonly: true},
            {name:'updated', readonly: true},
            {name:'deleted', readonly: true, default: false},
        ];
        /* beautify preserve:end */

        /* beautify preserve:start */
        model.prototype.shipTypes = ["Ocean", "Air", "DHL", "UPS", "FedEX", "Other"];
        model.prototype.termsTypes = ["CIF NYC", "Ex-Works", "DDU", "FOB", "Other"];
        model.prototype.freightFavorites = [
            {name:"Freight CO", value:"123 any st, ste 3\nChicago IL, 60610"},
            {name:"Warehouse INC", value:"attn: Frank\n545 Industry St\nunit 12\nLos Angeles CA, 92111"},
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
                if(field.type=="date" && !self[field.name]) {
                    self[field.name] = new Date();
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                if(!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if(field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if(field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch(e) {
                    }
                }

            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];

                return self.loadFromPayload(response.data.payload).then(function() {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            angular.forEach(fields, function(field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if(field.type == "date") {
                        data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth()+1) + '-' + self[field.name].getUTCDate();
                    }
                    if(field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }



    }

})();
