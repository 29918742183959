(function () {
    "use strict";

    angular
        .module('slate.logistics')
        .directive('slateShipmentListDetails', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/logistics/list/shipment.list.details.dir.html',
            scope: {
                shipmentID: '=shipment',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(SlateShipment, $scope, $timeout, $state, $window, $location, $q, $http, $translate, $interval, getLinkSrv, SlateApproval, SlateAuth, SlateProject, SlateRolesSrv, SlateAssignedRoles, SlateGood, SlateGoods, SlateStatusLogs, SlateShipmentWatchers, SlateFileNode, SlateFileNodeList) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;

        vm.shipment = new SlateShipment();

        vm.fields = [
            ['project', 'order', 'proj_mgr', 'sales_mgr'],
            ['origin_type', 'origin_from', 'origin_warehouse', 'delivery_type', 'delivery_to', 'delivery_warehouse'],
            ['ready_date', 'factory_incoterms', 'origin', 'final_destination', '', 'notes']
        ];


        vm.max_rows = 0;
        vm.fields.forEach(function(column) {
            if(column.length > vm.max_rows) { vm.max_rows = column.length; }
        });
        vm.range = range(0, vm.max_rows-1);
        function range(min, max, step) {
            step = step || 1;
            var input = [];
            for (var i = min; i <= max; i += step) {
                input.push(i);
            }
            return input;
        }

        vm.shipment.loadFromServer(vm.shipmentID).then(function() {
            vm.loading = false;
        });

    }

})();
