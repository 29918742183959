(function() {
    "use strict";

    angular
        .module('slate.schedule')
        .directive('slateWeeklySchedule', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/schedule/schedule.week.dir.html',
            scope: {

            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $q, $http, $window, SlateAuth, SlateUserAvail, SlateScheduleEvents) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;
        var modelPromise;
        var apiUrl = API_URL + 'scheduling/week/';

        vm.nextWeek = nextWeek;
        vm.prevWeek = prevWeek;
        vm.selected = selected;
        vm.toggleSelection = toggleSelection;
        vm.entriesByDate = entriesByDate;
        vm.officeIsFull = officeIsFull;
        vm.isDisabled = isDisabled;

        vm.weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

        vm.data = [];
        
        var monday = dateFns.startOfWeek(new Date(), {weekStartsOn: 1});
        var friday = dateFns.addDays(monday, 4);
        vm.days = dateFns.eachDay(monday, friday);

        vm.filters = {
            startDate: dateFns.format(monday, 'YYYY-MM-DD'),
            endDate: dateFns.format(friday, 'YYYY-MM-DD'),
        };
        var office = $window.localStorage.getItem('office');
        if (office) { vm.filters.office = office; }
        
        $scope.$watch('vm.filters.office', function() {
            if (vm.filters.office) {
                $window.localStorage.setItem('office', vm.filters.office);
                updateCalendar();
            }
        });
        
        function updateCalendar() {
            vm.loading = true;

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
            });

            modelPromise.then(function(response) {
                vm.data = response.data.payload;
                vm.loading = false;
            });
        }

        function nextWeek() {
            vm.filters.startDate = dateFns.format(dateFns.addDays(vm.filters.startDate, 7), 'YYYY-MM-DD');
            vm.filters.endDate = dateFns.format(dateFns.addDays(vm.filters.endDate, 7), 'YYYY-MM-DD');
            vm.days = dateFns.eachDay(vm.filters.startDate, vm.filters.endDate);
            updateCalendar();
        }
        
        function prevWeek() {
            vm.filters.startDate = dateFns.format(dateFns.subDays(vm.filters.startDate, 7), 'YYYY-MM-DD');
            vm.filters.endDate = dateFns.format(dateFns.subDays(vm.filters.endDate, 7), 'YYYY-MM-DD');
            vm.days = dateFns.eachDay(vm.filters.startDate, vm.filters.endDate);
            updateCalendar();
        }

        function saveDate(date) {
            vm.saving = true;

            var data = {
                user: vm.auth.user.contact_id,
                date: dateFns.format(date, 'YYYY-MM-DD'),
                office: $window.localStorage.getItem('office'),
            };

            modelPromise = $http.post(apiUrl, data);
            modelPromise.then(function() {
                vm.saving = false;
                updateCalendar();
            });

        }

        function deleteDate(entry) {
            var url = apiUrl + entry.id + '/';
            vm.saving = true;
            modelPromise = $http.delete(url);
            modelPromise.then(function() {
                vm.saving = false;
                updateCalendar();
            });
        }

        function officeIsFull(date) {
            var officeCaps = {
                'NJ': 20,
                'CA': 10,
                'LI': 4
            };

            var entries = entriesByDate(date);
            return entries.length >= officeCaps[$window.localStorage.getItem('office')];
        }

        function toggleSelection(date) {
            if (!isDisabled(date)) {
                if (selected(date)) {
                    var entry = vm.data.find(function(entry) { return entry.user == vm.auth.user.contact_id && dateFns.isEqual(entry.date, date); });
                    deleteDate(entry);
                } else {
                    saveDate(date);
                }
            }
        }

        function isDisabled(date) {
            var today = new Date();
            var endOfDay = new Date().setHours(19, 0, 0);
            if (dateFns.isBefore(date, today)) { return true; }
            if (!vm.selected(date) && dateFns.isAfter(today, endOfDay) && dateFns.differenceInHours(date, today) < 24) { return true; }
            if (!vm.selected(date) && officeIsFull(date)) { return true; }
            return false;
        }

        function selected(date) {
            return vm.data.some(function(entry) {
                return entry.user == vm.auth.user.contact_id && dateFns.isEqual(entry.date, date);
            });
        }

        function entriesByDate(date) {
            return vm.data.filter(function(entry) { return dateFns.isEqual(entry.date, date); });
        }
    }


})();
