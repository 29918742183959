(function() {
    "use strict";

    angular
        .module('slate.qc')
        .controller('SlateQCLogEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateQCLog) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = false;
        vm.qc = new SlateQCLog();

        vm.qc.loadFromServer($stateParams.id).then(function () {
            if (vm.qc.name) {
                SlateTitle.set('Edit ' + vm.qc.name + ' - QC Logs');
            } else {
                SlateTitle.set('Edit Untitled QC Log - QC Logs');
            }
            vm.loading = false;
            
        });

        $scope.$watch('vm.qc.name', function () {
            if (vm.qc.name) {
                SlateTitle.set('Edit ' + vm.qc.name + ' - QC Logs');
            } else {
                SlateTitle.set('Edit Untitled QC Log - QC Logs');
            }
        });

    }

})();


