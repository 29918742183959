(function () {
    "use strict";

    angular
        .module('slate.estimates')
        .directive('slateSalesEstimatesList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/estimates/salesestimates.list.dir.html',
            scope: {
                modelFilters: '=?filters',
                showNew: '=?',
                hideFilters: '=?',
                fields: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlateSalesEstimates, getLinkSrv, SlateDeleteObjSrv) {
        /* jshint validthis: true */
        var vm = this;

        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.addNew = addNew;

        vm.estimates = new SlateSalesEstimates();

        vm.availStatus = ['draft', 'ready', 'pending', 'approved', 'sent', 'complete', 'cancelled', 'exception_hold'];

        /* beautify preserve:start */
        vm.default_fields = [
            'number',
            'status',
            'name',
            'proj_mgr',
            'sales_mgr',
            'company'
        ];
        /* beautify preserve:end */

        /* beautify preserve:start */
        vm.url_filter_fields = [  // these are valid url search parameters
            'q',
            'startDate',
            'endDate',
            'sales_mgr',
            'proj_mgr',
            'order',
            'project',
            'company'
        ];
        /* beautify preserve:end */

        vm.date_fields = ['startDate', 'endDate'];
        vm.multiSearchFields = ['status'];

        vm.showFilters = false;

        // define scope functions

        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;

        // handle grabing filters on load and making sure they're valid, strings to date objects, etc.
        if (!vm.fields) { vm.fields = vm.default_fields; }
        if (!vm.modelFilters) { vm.modelFilters = {}; }
        vm.default_filters = {};
        if (!vm.hideFilters) {
            vm.default_filters.page = 1;
            vm.default_filters.count = 25;
        }

        var searchParams = $location.search();
        vm.url_filters = {};
        vm.url_filter_fields.forEach(function (item) {
            if (searchParams.hasOwnProperty(item)) {
                vm.url_filters[item] = searchParams[item];
            }
        });
        vm.filters = Object.assign({}, vm.default_filters, vm.modelFilters, vm.url_filters);
        if (vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if (vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }

        vm.date_fields.forEach(function (dateField) {
            if (vm.filters[dateField]) {
                parts = vm.filters[dateField].split('-');
                vm[dateField] = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
            }
            $scope.$watch('vm.' + dateField, function () {
                if (vm[dateField]) {
                    vm.filters[dateField] = vm[dateField].getFullYear() + '-' + (vm[dateField].getMonth() + 1) + '-' + vm[dateField].getDate();
                } else {
                    vm.filters[dateField] = null;
                }
            });
        });

        vm.multiSearchFields.forEach(function (field) {
            if (vm.filters[field] && typeof (vm.filters[field]) === 'string') {
                vm.filters[field] = [vm.filters[field]];
            }

        });

        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            updateFromFilter();
        });

        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function (value, key) {
                if (key == 'page' || key == 'count' || key == 'q') { return false; }
                if (value === undefined || value === null || value === '') { return false; }
                if (vm.filters[key] == vm.modelFilters[key]) { return false; }
                if (typeof vm.filters[key] == 'string' && vm.filters[key].length === 0) { return false; }
                hasData = true;
                vm.showFilters = true;
                return true;

            });
            return hasData;
        }

        function updateFromFilter() {
            var modelPromise;
            var send_data = {};

            vm.loading = true;

            vm.multiSearchFields.forEach(function(fieldname) {
                if (typeof vm.filters[fieldname] === 'string') {
                    vm.filters[fieldname] = [vm.filters[fieldname]];
                }
            });

            angular.forEach(vm.filters, function (value, item) {
                if(vm.modelFilters[item]) { return false; }
                if(value === '') { vm.filters[item] = null; }
                if (vm.filters[item]) {
                    if(vm.filters[item] instanceof Date) {
                        $location.replace().search(item, vm.filters[item].toISOString());
                    } else {
                        $location.replace().search(item, vm.filters[item]);
                    }
                } else {
                    $location.replace().search(item, null);
                }
            });
            angular.extend(
                send_data,
                {'fields': vm.fields},
                vm.filters
            );
            vm.url = $window.location.href;
            return vm.estimates.getList(send_data).then(function () {
                vm.loading = false;
            });
        }

        function addNew() {
            var url = "/estimates/sales-estimates/new/";
            var params = {};
            if(vm.modelFilters.order) { params.order = vm.modelFilters.order; }
            if(vm.modelFilters.project) { params.project = vm.modelFilters.project; }

            var queryString;
            queryString = queryString = Object.keys(params).map(function (key) {
                return key + '=' + params[key];
            }).join('&');
            $location.url(url + '?' + queryString);
        }

    }
})();
