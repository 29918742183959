(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .service('SlateSOLineItemPriceTierEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateSOLineItem) {
        var templateUrl= '/partials/salesorders/so.lineitem.pricetier.edit.sidebar.html';

        var srv = {
            edit: editObj
        };
        return srv;

        function editObj(item, lineItem) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj: function () {
                        var newitem = new SlateSOLineItem();
                        if(!item) {
                            return newitem;
                        }
                        if(typeof item == 'string') {
                            return newitem.loadFromServer(item);
                        }
                        return newitem.loadFromPayload(item);
                    },
                    lineItem: function() {
                        return lineItem;
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $http, $q, $uibModalInstance, SlateAuth, obj, SlatePriceTiers) {
        /* jshint validthis: true */
        var vm = this;

        vm.close = close;
        vm.item = obj;
        vm.loading = true;

        vm.saveObject = saveObject;
        vm.isComplete = isComplete;

        vm.priceTiers = new SlatePriceTiers();

        function getPriceTiers() {
            vm.priceTiers.getList({component: vm.item.finished_good, count: 25}).then(function() {
                vm.loading = false;
            }, function() {
                vm.loading = false;
            });
        }
        getPriceTiers();

        function saveObject() {
            vm.item.sellprice = vm.priceTier.getPrice(vm.item.qty);
            $uibModalInstance.close(vm.item);
        }

        function close() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }

        function isComplete() {
            if(!vm.item.price_tier) { return false; }
            return true;
        }

    }



})();
