(function () {
    "use strict";

    angular
        .module('slate.shippingaccounts')
        .directive('slateShippingAccountsEditBox', EditBox);

    function EditBox() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/shippingaccounts/shippingaccounts.edit.dir.html',
            scope: {
                shippingAccounts: '=',
                requiredMethods: '=?'
            },
            bindToController: true,
            controller: EditController,
            controllerAs: 'vm',
            link: EditLinker,
        };
    }

    function EditController($http, SlateShippingAccount) {
        /* jshint validthis: true */
        var vm = this;
        vm.deleteItem = deleteItem;
        vm.addShippingAccount = addShippingAccount;
        vm.getShippingAccounts = getShippingAccounts;

        function addShippingAccount() {
            var newAccount = new SlateShippingAccount();
            vm.shippingAccounts.list.push(newAccount);
        }

        function getShippingAccounts() {
            if (!vm.accountTypes) {
                var url = API_URL + 'companies/shipping-account-types/';
                vm.loading = true;
                vm.error = false;
                $http.get(url).then(function (response) {
                    vm.accountTypes = response.data.payload;
                    vm.loading = false;
                }, function () {
                    vm.loading = false;
                    vm.error = true;
                });
            }
        }
        getShippingAccounts();
    }

    function EditLinker(scope) {
        scope.methods = {
            phone: 'Phone',
            cell: 'Cell Phone',
            fax: 'Fax',
            email: 'EMail',
            url: 'Web Site',
            wechat: 'WeChat Username',
            skype: 'Skype Username',
            facebook: 'Facebook Username',
            linkedin: 'LinkedIn Username',
            twitter: 'Twitter Username',
        };
    }

    function deleteItem(item) {
        /* jshint validthis: true */
        var vm = this;
        if (!item.is_primary) {
            item.toDelete = !item.toDelete;
        }
    }

})();
