(function () {
    'use strict';

    angular
        .module('slate.filebrowser')
        .directive('slateFileBrowser', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/filebrowser/filebrowser.dir.html',
            scope: {
                parentType: '=',
                parentFk: '=',
                readOnly: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController($scope, $state, $location, $timeout, $q, getLinkSrv, SlateAuth, SlateFileNodeList, SlateFileNode, SlateFileVerList, SlateFileNodeUpload, SlateFileVerUpload, SlateFileVerAnnotation, SlateFileNodeClientProps) {
        /* jshint validthis: true */
        const vm = this;
        vm.loading = true;
        vm.nodes = new SlateFileNodeList();
        vm.vers = new SlateFileVerList();
        vm.path = $state.params.path;
        vm.tag = $state.current.tag;
        vm.tab = $state.current.tabName;
        vm.files = [];

        vm.permissions = SlateAuth.user.permissions;

        // local functions
        vm.getUrl = getUrl;
        vm.parseFilesize = parseFilesize;
        vm.get_image_type = get_image_type;
        vm.getDownloadUrl = getDownloadUrl;
        vm.uploadFileDialog = uploadFileDialog;

        vm.removeFile = removeFile;
        vm.deleteNode = deleteNode;
        vm.uploadFiles = uploadFiles;

        vm.makeNewDir = makeNewDir;
        vm.saveNewDir = saveNewDir;
        vm.cancelNewDir = cancelNewDir;
        vm.newDirKeyPress = newDirKeyPress;

        vm.uploadNewVer = uploadNewVer;
        vm.annotateVer = annotateVer;
        vm.canAnnotate = canAnnotate;
        vm.editClientProps = editClientProps;

        vm.sendLink = sendLink;
        vm.getLinkUrl = getLinkUrl;
        vm.copyLinkToClipboard = copyLinkToClipboard;

        init();

        /* beautify preserve:start */
        const types = {
            'image/png': { class: 'flaticon-png-file-extension-interface-symbol' },
            'image/jpeg': { class: 'flaticon-jpg-image-file-format' },
            'application/postscript': { class: 'flaticon-ai-file-format-symbol', color: '#FF6600' },
            'application/x-apple-diskimage': { class: 'flaticon-dmg-file-format-symbol' },
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { class: 'flaticon-xlsx-file-format', color: '#577758' },
            'application/vnd.ms-excel': { class: 'flaticon-xls-file-format-symbol', color: '#577758' },
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': { class: 'flaticon-pptx-file-format-variant', color: '#cf6a41' },
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { class: 'flaticon-docx-file-format', color: '#517fcb' },
            'application/msword': { class: 'flaticon-doc-file-format-symbol', color: '#517fcb' },
            'application/pdf': { class: 'flaticon-pdf-file-format-symbol', color: '#e81209' },
            'application/zip': { class: 'flaticon-zip-compressed-files-extension', color: '#B29772' }
        };
        /* beautify preserve:end */

        function canAnnotate(mimetype) {
            const annotatable = [
                'application/pdf', 'image/png', 'image/jpeg'
                // 'application/vnd.ms-excel',
                // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ];
            if (annotatable.includes(mimetype)) { return true; }
            return false;
        }

        function editClientProps(node) {
            SlateFileNodeClientProps.edit(node).then(() => {
                init();
            });
        }

        function get_image_type(mimetype) {
            let results = { class: 'flaticon-blank-file', color: '#000000' };
            if (types[mimetype]) {
                results = angular.extend({}, results, types[mimetype]);
            }
            return results;
        }

        function getUrl(path) {
            const params = $state.params;
            const tag = `${$state.current.tag}/`;
            let new_path = '/';

            if (typeof path === 'undefined') { return '/'; }
            if (path.startsWith('/')) {
                path = path.substr(1);
            }

            if (path.startsWith(tag)) {
                path = path.replace(tag, '');
            }
            params.path = path;
            new_path = $state.href($state.current.name, params, {
                absolute: true
            }).replace(/~2F/g, '/').replace(/%252F/g, '/');
            // the regex is due to the persistant encoding of the url, regardless of options set in ui-router
            if (!new_path.endsWith('/')) { new_path += '/'; }
            return new_path;
        }

        function parseFilesize(size) {
            let unit = 'B';
            if (size === null || isNaN(size)) {
                return '';
            }
            if (size > 1024) {
                size /= 1024;
                unit = 'K';
            }
            if (size > 1024) {
                size /= 1024;
                unit = 'M';
            }
            if (size > 1024) {
                size /= 1024;
                unit = 'G';
            }
            return size.toFixed(2).toString() + unit;
        }

        function getDownloadUrl(node, ver) {
            if (!ver) {
                return `${API_URL}files/download/${node.id}/`;
            }

            return `${API_URL}files/download/${node.id}/ver/${ver}/`;
        }

        function annotateVer(node, ver) {
            SlateFileVerAnnotation.edit(node, ver).then(() => {
                init();
            });
        }

        function uploadFileDialog() {
            const defaults = new SlateFileNode();

            if (vm.nodes.node && vm.nodes.node.id) {
                defaults.parent_type = 'filenode';
                defaults.parent_fk = vm.nodes.node.id;
            } else {
                defaults.parent_type = vm.parentType;
                defaults.parent_fk = vm.parentFk;
            }

            SlateFileNodeUpload.edit(defaults).then(() => {
                init();
            });
        }

        function removeFile(index) {
            vm.files.splice(index, 1);
        }

        function deleteNode(node) {
            node.deleteModel().then(() => {
                init();
            });
        }

        function uploadFiles() {
            const promises = [];
            let filepromise = $q.resolve();
            const defaults = new SlateFileNode();

            if (vm.nodes.node && vm.nodes.node.id) {
                defaults.parent_type = 'filenode';
                defaults.parent_fk = vm.nodes.node.id;
            } else {
                defaults.parent_type = vm.parentType;
                defaults.parent_fk = vm.parentFk;
            }

            vm.saving = true;
            vm.savedFiles = [];
            angular.forEach(vm.files, (file) => {
                const newFile = new SlateFileNode();
                let pathParts = [];
                newFile.loadFromPayload(defaults);
                newFile.filename = file.name;
                newFile.qc = vm.qc;
                // newFile.mimetype = file.type;

                if (file.path) {
                    pathParts = file.path.split('/');
                    pathParts.pop();
                    newFile.path = pathParts.join('/');
                }

                filepromise = filepromise.then(() => newFile.saveModel(file).then(() => {
                    vm.savedFiles.push(newFile);
                }));
            });

            promises.push(filepromise);
            $q.all(promises).then(() => {
                vm.saving = false;
                init();
            });
        }

        function makeNewDir() {
            vm.newDir = new SlateFileNode();
            vm.newDir.mimetype = 'application/directory';
            vm.newDir.filename = '';
            if (vm.nodes.node) {
                vm.newDir.parent_type = 'filenode';
                vm.newDir.parent_fk = vm.nodes.node.id;
            } else {
                vm.newDir.parent_type = vm.parentType;
                vm.newDir.parent_fk = vm.parentFk;
            }
        }

        function saveNewDir() {
            vm.newDir.saveModel().then(() => {
                vm.newDir = null;
                init();
            });
        }

        function cancelNewDir() {
            vm.newDir = null;
        }

        function uploadNewVer() {
            SlateFileVerUpload.edit(vm.nodes.node).then(() => {
                init();
            });
        }

        function newDirKeyPress(keyEvent) {
            if (keyEvent.which === 13 && vm.newDir.filename.length) {
                saveNewDir();
            }
        }

        function init() {
            let modelPromise;
            const truePathParths = [];
            const truePath = '/';
            let listFilters = {};
            let path = '';

            if ($state.current.tag) { path = `${$state.current.tag}/`; }
            if (vm.path) { path += vm.path; }

            listFilters = {
                parent_type: vm.parentType,
                parent_fk: vm.parentFk,
                path,
                tag: $state.current.tag
            };

            vm.loading = true;
            vm.files = [];

            modelPromise = vm.nodes.getList(listFilters);
            modelPromise.then(() => {
                let vers;
                const promises = [];
                if (vm.nodes.node.id && vm.nodes.node !== 'application/directory') {
                    promises.push(vm.vers.getList(vm.nodes.node.id).then(() => {
                        vm.loading = false;
                    }));
                } else {
                    vm.loading = false;
                }
            });
        }

        $scope.$watch('vm.filesAdd', () => {
            angular.forEach(vm.filesAdd, (file) => {
                vm.files.push(file);
            });
        });

        function sendLink(node) {
            let promise;

            node.linkSent = false;
            if (!node.sendLinkTo) { return false; }
            promise = node.sendLink();
            promise.then(() => {
                node.linkSent = true;
            });
        }

        function getLinkUrl(node) {
            return `https://${$location.host()}/api/v1/files/direct-download/${node.id}/`;
        }

        function copyLinkToClipboard(node) {
            vm.copied = true;
            navigator.clipboard.writeText(getLinkUrl(node));
            $timeout(() => {
                vm.copied = false;
            }, 600);
        }
    }
})();
