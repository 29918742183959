(function () {
    'use strict';

    angular
        .module('slate.profilephotos')
        .directive('slateProfileThumbnail', dirObject);

    function dirObject() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/profilephotos/profilephotos.thumbnail.dir.html',
            scope: {
                parentType: '@',
                parentId: '=',
                size: '=?',
            },
            controller: photosController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function photosController(SlateProfilePhotoSrv) {
        /* jshint validthis: true */
        const vm = this;
        if (!vm.size) { vm.size = 'thumb'; }
        vm.photosrv = SlateProfilePhotoSrv;
        vm.photo = '';

        vm.photosrv.getPhoto(vm.parentType, vm.parentId, vm.size)
            .then((url) => {
                // make sure # in the url is not considered a hash
                vm.photo = url.replaceAll('#', '%23');
            });
    }
})();
