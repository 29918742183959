(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportInventoryPending', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.inventory_pending.dir.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ReportController($scope, $q, $http, $location, $httpParamSerializer) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'reports/inventory_pending/';
        
        var modelPromise;
        var canceller = null;

        vm.getDownloadUrl = getDownloadUrl;
        vm.updateFromFilter = updateFromFilter;
        
        
        vm.data = [];
        vm.filters = {};
        vm.loading = true;
        
        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });
        
        $scope.$watch('vm.depart_date', function() {
            if(vm.depart_date) {
                vm.filters.depart_date = vm.depart_date.getFullYear() + '-' + (vm.depart_date.getMonth()+1) + '-' + vm.depart_date.getDate();
            } else {
                vm.filters.depart_date = null;
            }
        });

        function updateFromFilter() {
            vm.loading = true;

            if (canceller) { canceller.resolve(); }
            canceller = $q.defer();

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
                timeout: canceller.promise
            });

            modelPromise.then(function(resp) {
                vm.data = resp.data.payload;
                vm.loading = false;
                canceller = null;
            });
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }
    }
})();