(function() {
    "use strict";

    angular
        .module('slate.contactmethods')
        .directive('slateContactmethodsEditBox', EditBox);

    function EditBox() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/contactmethods/contactmethods.edit.dir.html',
            scope: {
                contactMethods: '=',
                requiredMethods: '=?'
            },
            bindToController: true,
            controller: EditController,
            controllerAs: 'vm',
            link: EditLinker,
        };
    }

    function EditController() {
        /* jshint validthis: true */
        var vm = this;
        vm.deleteItem = deleteItem;
        vm.validateEmail = validateEmail;

        function validateEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
          }
    }

    function EditLinker(scope) {
        scope.methods = {
            phone: 'Phone',
            cell: 'Cell Phone',
            fax: 'Fax',
            email: 'EMail',
            url: 'Web Site',
            wechat: 'WeChat Username',
            skype: 'Skype Username',
            facebook: 'Facebook Username',
            linkedin: 'LinkedIn Username',
            twitter: 'Twitter Username',
        };
    }

    function deleteItem(item) {
        /* jshint validthis: true */
        var vm = this;
        if(!item.is_primary) {
            item.toDelete = !item.toDelete;
        }
    }

})();
