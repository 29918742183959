(function() {
    "use strict";

    angular
        .module('slate.pos')
        .directive('slatePoRequestList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/pos/porequest.list.dir.html',
            scope: {
                modelFilters: '=?filters',
                hidenew: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlatePOs, SlatePO, SlatePORequestEdit, SlateFileNodeList, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;

        var modelPromise;
        var filesPromise;

        vm.get_url = get_url;

        vm.getLink = getLinkSrv;
        vm.loading = true;
        vm.filters = {
            page: 1,
            count: 25,
            status: null,
            startDate: $location.search().startDate,
            endDate: $location.search().endDate,
        };
        var basicStatus = $location.search().status;
        if(!vm.modelFilters) { vm.modelFilters = {}; }
        angular.extend(vm.filters, {
            q: $location.search().q,
        }, vm.modelFilters, $location.search(), vm.filters);
        if(typeof vm.filters.page == 'string') { vm.filters.page = parseInt(vm.filters.page); }
        if(typeof vm.filters.count == 'string') { vm.filters.count = parseInt(vm.filters.count); }
        var parts;
        if(vm.filters.startDate) { 
            parts = vm.filters.startDate.split('-');
            vm.start = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
        }
        if(vm.filters.endDate) { 
            parts = vm.filters.endDate.split('-');
            vm.end = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
        }
        $scope.$watch('vm.start', function () {
            if (vm.start) {
                vm.filters.startDate = vm.start.getFullYear() + '-' + (vm.start.getMonth()+1) + '-' + vm.start.getDate();
            } else {
                vm.filters.startDate = null;
            }
        });
        $scope.$watch('vm.end', function () {
            if (vm.end) {
                vm.filters.endDate = vm.end.getFullYear() + '-' + (vm.end.getMonth()+1) + '-' + vm.end.getDate();
            } else {
                vm.filters.endDate = null;
            }
        });
        
        
        vm.poList = new SlatePOs();
        
        vm.addNew = addNew;
        vm.editPO = editPO;
        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;
        vm.updateFromFilter();

        vm.getSellTotal = getSellTotal;
        vm.getPriceTotal = getPriceTotal;
        vm.getMarginTotal = getMarginTotal;

        function get_url() {
            var url =   API_URL + 'pos/porequest/';
            if($window.location.search.length ) {
                return url + $window.location.search + '&download=true'; 
            } else {
                return url + '?download=true';
            }
        }
        
        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });
        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function(value, key) {
                if(key == 'page' || key == 'count' || key == 'q') {
                    return false;
                }
                if(typeof vm.filters[key] == 'undefined' || vm.filters[key] === undefined || vm.filters[key] === null) {
                    return false;
                }
                if(vm.filters[key] == vm.modelFilters[key]) {
                    return false;
                }
                if(typeof vm.filters[key] == 'string' && vm.filters[key].length === 0) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;
                
            });
            return hasData;
        }

        function updateFromFilter() {
            var updatePromise;

            vm.loading = true; 
            if (typeof vm.filters.status == 'string') {
                vm.filters.status = [vm.filters.status];
            }

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            updatePromise = vm.poList.getList(vm.filters);
            updatePromise.then(function() {
                vm.loading = false;
                $timeout(function() {
                    var po_to_open = $location.search().openPO;
                    if(!po_to_open || vm.openPOEditing === true) { return true; }
                    
                    vm.poList.list.forEach(function (item, idx) {
                        if(item.id == po_to_open) {
                            vm.openPOEditing = true;
                            vm.editPO(item);
                        }
                    });
                }, 500);
                
            });
        }

        function editPO(po) {
            var promise;

            promise = SlatePORequestEdit.edit(po);
            promise.then(function() {
                //$state.reload();
                updateFromFilter();
            }, function() {

            });
            return promise;

        }

        function addNew($event) {
            var newPO = new SlatePO();
            var promise;
            if (vm.modelFilters) {
                newPO.loadFromPayload(vm.modelFilters);
            }

            promise = SlatePORequestEdit.edit(newPO);
            promise.then(function() {
                $state.reload();
            }, function() {

            });
            return promise;
        }

        function getPriceTotal() {
            var total = Big(0.0);
            vm.poList.list.forEach(function (item) {
                total = total.add(item.pricetotal);
            });
            return total.toFixed(5);
        }

        function getSellTotal() {
            var total = Big(0.0);
            vm.poList.list.forEach(function (item) {
                total = total.add(item.selltotal);
            });
            return total.toFixed(5);
        }

        function getMarginTotal() {
            var sell = getSellTotal();
            var cost = getPriceTotal();
            var total = Big(0.0);
            if(sell == 0.00000) { return 0.0; }
            total = total.add(sell).minus(cost);
            total = total.div(sell);
            total = total.times(100.0);
            return total.toFixed(2);
        }
    }


})();
