(function() {
    "use strict";

    angular
        .module('slate.qc')
        .directive('slateQcrequestList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/qc/qcrequest.list.dir.html',
            scope: {
                modelFilters: '=filters'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $q, SlateQCRequests, SlateQCRequest, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;

        vm.getLink = getLinkSrv;
        vm.loading = true;
        
        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;
        vm.duplicate = reqdupe;

        var multiSearchFields = ['req_type'];
        
        vm.filters = {
            page: 1,
            count: 25,
        };
        multiSearchFields.forEach(function(field) {
            vm.filters[field] = null;
        });

        
        angular.extend(vm.filters, {
            q: $location.search().q,
            created_by: $location.search().created_by,
            startDate: $location.search().startDate,
            endDate: $location.search().endDate
        }, vm.modelFilters, $location.search());
        if(vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if(vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }
        if(vm.filters.startDate) { vm.start = new Date(vm.filters.startDate); }
        if(vm.filters.endDate) { vm.end = new Date(vm.filters.endDate); }
        if(vm.filters.created_start) { vm.filters.created_start = new Date(vm.filters.created_start); }
        if(vm.filters.created_end) { vm.filters.created_end = new Date(vm.filters.created_end); }

        multiSearchFields.forEach(function(field) {
            if(vm.filters[field] && typeof vm.filters[field] === 'string') {
                vm.filters[field] = [vm.filters[field]];
            }
        });
        
        vm.qcList = new SlateQCRequests();

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        $scope.$watch('vm.start', function () {
            if (vm.start) {
                vm.filters.startDate = vm.start.toISOString();
            } else {
                vm.filters.startDate = null;
            }

        });
        $scope.$watch('vm.end', function () {
            if (vm.end) {
                vm.filters.endDate = vm.end.toISOString();
            } else {
                vm.filters.endDate = null;
            }
        });

        // parse dates for list view
        $scope.parseInspections = function(inspections){
            var res = "";
            for (var i = 0; i < inspections.length; i++){
                if(inspections[i].date){
                    // return evreything after first white space which just removes the day of week from date string
                    inspections[i].date.substr(0, inspections[i].date.indexOf(' '));
                    res += inspections[i].date.substr(inspections[i].date.indexOf(' ')+1) + "\n";
                }
            }
            return res;
        };

        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function(value, key) {
                if(key == 'page' || key == 'count' || key == 'q') {
                    return false;
                }
                if(value === undefined || value === null) {
                    return false;
                }
                if(vm.modelFilters && vm.modelFilters[key] == value) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;
                
            });
            return hasData;
        }
        function updateFromFilter() {
            var modelPromise;

            vm.loading = true;

            angular.forEach(vm.filters, function(value, item) {
                if (vm.filters[item] && (!vm.modelFilters || vm.modelFilters[item] != vm.filters[item])) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = vm.qcList.getList(vm.filters);
            modelPromise.then(function() {
                vm.loading = false;
            });
        }

        function reqdupe(id) {
            if (window.confirm("Are you sure you want to copy this QC Request?")) { 
                var req = new SlateQCRequest();
                req.duplicate(id).then(function() {
                    $state.transitionTo("qcrequestEditPage", {id: req.id});
                });
            }
        }

    }
})();