(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .controller('SlateRfqViewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($state, $location, SlateTitle, getLinkSrv, $stateParams, SlateRFQ, SlateCompany) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.link = getLinkSrv;
        vm.companies = {};
        vm.rfq = new SlateRFQ();
        vm.rfq.loadFromServer($stateParams.id).then(function () {

            if(vm.rfq.name) {
                SlateTitle.set(vm.rfq.name + ' - RFQs');
            } else {
                SlateTitle.set('Untitled RFQ - RFQs');
            }
            loadCompanies(vm.rfq.vendors);
            loadCompanies(vm.rfq.china_vendors);

            vm.loading = false;
        });

        function loadCompanies(list) {
            angular.forEach(list, function(coId) {
                if(!vm.companies.hasOwnProperty(coId)) {
                    vm.companies[coId] = new SlateCompany();
                    vm.companies[coId].loadFromServer(coId);
                }
            });
        }


    }

})();
