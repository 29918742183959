(function() {
    "use strict";

    angular
        .module('slate.orders')
        .controller('SlateOrderEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateOrder) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.order = new SlateOrder();

        vm.order.loadFromServer($stateParams.id).then(function () {
            if (vm.order.name) {
                SlateTitle.set('Edit ' + vm.order.name + ' - Orders');
            } else {
                SlateTitle.set('Edit Untitled order - Orders');
            }
            vm.loading = false;
            
        }).catch(angular.noop);

        $scope.$watch('vm.order.name', function () {
            if (vm.order.name) {
                SlateTitle.set('Edit ' + vm.order.name + ' - Orders');
            } else {
                SlateTitle.set('Edit Untitled order - Orders');
            }
        });

    }

})();


