(function () {
    'use strict';

    angular
        .module('slate.library.discussion')
        .factory('SlateDiscussionMsgs', ModelList)
        .factory('SlateDiscussionMsg', ModelDetail);

    const apiUrl = `${API_URL}discussions/msgs/`;


    function ModelList($http, $q, SlateDiscussionMsg) {
        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];
            self.cache = {};
            self.last_check = null;
            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof filters === 'object') {
                self.filters = angular.extend({}, filters, self.filters);
            }

            const promise = $http.get(apiUrl, { params: self.filters });
            promise.then((response) => {
                const startDate = new Date();
                self.filters.start = startDate.toISOString();

                angular.forEach(response.data.payload, (item) => {
                    let itemModel;
                    if (typeof self.cache[item.id] === 'undefined') {
                        itemModel = new SlateDiscussionMsg();
                        itemModel.loadFromPayload(item);
                        self.list.push(itemModel);
                        self.cache[item.id] = item.created;
                    }
                });

                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();
            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', def: null, readonly: true },

            { name: 'createdby', readonly: true },
            { name: 'createdby_name', readonly: true },
            { name: 'createdby_slug', readonly: true },

            { name: 'created', type: 'date', readonly: true },

            { name: 'message' },
            { name: 'urgent', def: false },

            { name: 'discussion' },

        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                console.log('payload must be an object', payload);
                return $q.reject(self);
            }

            angular.forEach(fields, (field) => {
                self[field.name] = payload[field.name];
                if (field.type === 'float' && self[field.name]) {
                    self[field.name] = parseFloat(self[field.name]);
                }
                if (field.type === 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if (field.type === 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                        console.log('invalid json: ', payload[field.name]);
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = apiUrl;

            let params = {};

            if (typeof id === 'string') {
                modelUrl = `${modelUrl + id}/`;
            }
            if (typeof id === 'object') {
                params = id;
            }

            const promise = $http.get(modelUrl, { params });

            promise.then((response) => {
                const promises = [];
                let loadPromise;

                self.loadFromPayload(response.data.payload);

                return loadPromise.then(() => {
                    self.permissions = response.data.permissions;

                    return $q.when(promises).then(() => {
                        deferred.resolve(self);
                    });
                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            let modelUrl = apiUrl;

            const data = {};

            if (self.id) { modelUrl = `${apiUrl + self.id}/`; }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (field.type === 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });

            const promise = $http.post(modelUrl, data);
            return promise.then((response) => {
                const promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            const promise = $http.delete(modelUrl);
            promise.then(() => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }
})();
