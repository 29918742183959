(function() {
    "use strict";

    angular
        .module('slate.app')
        .factory('SlateErrorInterceptor', SlateErrorInterceptor)
        .config(['$httpProvider', function($httpProvider) {
            $httpProvider.defaults.useXDomain = true;
            $httpProvider.interceptors.push('SlateErrorInterceptor');
        }]);

    function SlateErrorInterceptor($q, ngToast) {

        return {
            responseError: function(response) {
                var error = {};
                var cancelState = false;
                if(
                    response.config.timeout &&
                    response.config.timeout.$$state.status == 1
                ) { cancelState = true; }

                if (response.status <= 0 && !cancelState) {
                    error = {
                        error: "<strong>Unable to connect to server.</strong> The server might be down, or you may be disconnected from the internet."
                    };
                    response.error_msg = error;
                    ngToast.danger(error.error);
                }

                if (response.status >= 500) {
                    error = {
                        error: "There was an error on the server. Please try again in a bit"
                    };
                    response.error_msg = error;
                    ngToast.danger(error.error);
                }


                if (response.status === 403) {
                    error = {
                        error: "You are not authorized for this resource. Please have an admin check your permissions."
                    };
                    if(response.data.error && response.data.error != 'Permission Denied') {
                        error.error = 'Permission Denied: ' + response.data.error;
                    }
                    response.error_msg = error;
                    ngToast.warning(error.error);
                }

                return $q.reject(response);
            }
        };



    }

})();
