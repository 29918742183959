(function () {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportPendingTasksByUser', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.pending_tasks_by_user.dir.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $http, $location, $httpParamSerializer, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'projects/milestones/';
        vm.getLink = getLinkSrv;

        var modelPromise;

        vm.getDownloadUrl = getDownloadUrl;
        vm.updateFromFilter = updateFromFilter;
        vm.getTotalEstimatedTime = getTotalEstimatedTime;

        vm.data = [];
        vm.filters = { incomplete: 'only', order_by: 'desired_by' };

        angular.extend(vm.filters, $location.search());

        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            if (vm.filters.contact) {
                updateFromFilter();
            }
        });

        function updateFromFilter() {
            vm.loading = true;

            if (vm.filters.contact) {
                $location.replace().search('contact', vm.filters.contact);
            } else {
                $location.replace().search('contact', null);
            }

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
            });

            modelPromise.then(function (resp) {
                vm.data = resp.data.payload;
                vm.pagination = resp.data.pagination;
                vm.loading = false;
            });
        }

        function getTotalEstimatedTime() {
            var total = Big(0);
            vm.data.forEach(function (milestone) {
                total = total.plus(milestone.hours_est);
            });
            return total.valueOf();
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }
    }
})();