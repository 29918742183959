(function() {
    "use strict";

    angular
        .module('slate.invoices')
        .directive('slateInvoiceList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/invoices/invoices.list.dir.html',
            scope: {
                modelFilters: '=?filters'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, SlateInvoices, SlateInvoice, SlateInvoiceEdit, SlateFileNodeList, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;

        var modelPromise;
        var filesPromise;

        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.filters = {
            page: 1,
            count: 25
        };
        if(!vm.modelFilters) { vm.modelFilters = {}; }
        angular.extend(vm.filters, {
            q: $location.search().q,
            startDate: $location.search().startDate,
            endDate: $location.search().endDate,
            
        }, vm.modelFilters, $location.search());
        if(vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if(vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }
        var parts;
        if(vm.filters.startDate) { 
            parts = vm.filters.startDate.split('-');
            vm.start = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
        }
        if(vm.filters.endDate) { 
            parts = vm.filters.endDate.split('-');
            vm.end = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
        }
        $scope.$watch('vm.start', function () {
            if (vm.start) {
                vm.filters.startDate = vm.start.getFullYear() + '-' + (vm.start.getMonth()+1) + '-' + vm.start.getDate();
            } else {
                vm.filters.startDate = null;
            }
        });
        $scope.$watch('vm.end', function () {
            if (vm.end) {
                vm.filters.endDate = vm.end.getFullYear() + '-' + (vm.end.getMonth()+1) + '-' + vm.end.getDate();
            } else {
                vm.filters.endDate = null;
            }
        });
        
        
        var validFilters = [
            'page',
            'count',
            'q',
            'status',
            'status_list',
            'contact',
            'startDate',
            'endDate',
            'min_margin',
            'max_margin'
        ];
                

        vm.invs = new SlateInvoices();

        vm.addNew = addNew;
        vm.editInv = editInv;
        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;

        vm.getSellTotal = getSellTotal;
        vm.getCostTotal = getCostTotal;
        vm.getMarginTotal = getMarginTotal;

        vm.get_url = get_url;
        
        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });
        
        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function(value, key) {
                if(key == 'page' || key == 'count' || key == 'q') {
                    return false;
                }
                if(!validFilters.includes(key)) { return false; }
                if(value === undefined || value === null || value === '') {
                    return false;
                }
                if(vm.filters[key] == vm.modelFilters[key]) {
                    return false;
                }

                hasData = true;
                vm.showFilters = true;
                return true;
                
            });
            return hasData;
        }

        function updateFromFilter() {
            var updatePromise;

            vm.loading = true; 

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item] && validFilters.includes(item) && vm.filters[item] != vm.modelFilters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            updatePromise = vm.invs.getList(vm.filters);
            updatePromise.then(function() {
                vm.loading = false;
            });
        }

        function editInv(inv) {
            var promise;

            promise = SlateInvoiceEdit.edit(inv);
            promise.then(function() {
                $state.reload();
            }, function() {

            });
            return promise;

        }

        function addNew($event) {
            var newInv = new SlateInvoice();
            var promise;
            if (vm.modelFilters) {
                newInv.loadFromPayload(vm.modelFilters);
            }

            promise = SlateInvoiceEdit.edit(newInv);
            promise.then(function() {
                $state.reload();
            }, function() {

            });
            return promise;
        }

        function getCostTotal() {
            var total = Big(0.0);
            vm.invs.list.forEach(function (item) {
                total = total.add(item.costtotal);
            });
            return total.toFixed(5);
        }

        function getSellTotal() {
            var total = Big(0.0);
            vm.invs.list.forEach(function (item) {
                total = total.add(item.pricetotal);
            });
            return total.toFixed(5);
        }

        function getMarginTotal() {
            var sell = getSellTotal();
            var cost = getCostTotal();
            var total = Big(0.0);
            if(sell == 0.00000) { return 0.0; }
            total = total.add(sell).minus(cost);
            total = total.div(sell);
            total = total.times(100.0);
            return total.toFixed(2);
        }

        function get_url() {
            var url =   API_URL + 'invoices/invrequest/';
            if($window.location.search.length ) {
                return url + $window.location.search + '&download=true'; 
            } else {
                return url + '?download=true';
            }
        }

    }


})();
