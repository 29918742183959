(function () {
    "use strict";

    angular
        .module('slate.datelog')
        .service('SlateDateChangeDialog', editDialogController);


    function editDialogController($rootScope, $state, $location, $window, $q, $uibModal, SlateAuth, SlateDateChange) {
        var templateUrl = '/partials/datelog/datelog.dialog.dir.html';

        var srv = {
            popout: popout,
        };

        return srv;

        function popout(parent, origDate, field) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    parent: function() { return parent; },
                    origDate: function() { return origDate; },
                    field: function() { return field; }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateAuth, SlateDateLogList, parent, origDate, field) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;
        vm.dateLog = SlateDateLogList;

        vm.parent = parent;
        vm.origDate = origDate;
        vm.field = field;
        vm.notes = vm.parent.datelog_notes[vm.field];
        vm.getFieldLocale = getFieldLocale;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.isComplete = isComplete;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function isComplete() {
            if(!vm.notes) { return false; }
            return true;
        }

        function saveObject() {
            $uibModalInstance.close(vm.notes);
        }

        function getFieldLocale() {
            switch (vm.dateLog.parent_type) {
                case 'salesorder':
                    return 'SO.FIELDS.';
                case 'purchaseorder':
                    return 'PO.FIELDS.';
                case 'shipment':
                    return 'LOGISTICS.SHIP.FIELDS.';
            }
        }

    }

    
})();
