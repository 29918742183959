(function () {
    "use strict";

    angular
        .module('slate.library.presentations')
        .service('SlateTags', ModelList);

    var apiUrl = API_URL + 'presentations/tags/';

    function ModelList($http, $q) {
        // Set to url relative to api root.
        var promise;

        // instantiate our initial object
        var list = {
            list: [],
            getList: getList,
            addTags: addTags
        };

        list.promise = getList();
        return list;

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var promise;

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl);

            promise.then(function (response) {
                list.list = response.data.payload;

                return self;
            });

            return promise;
        }

        function addTags(tags) {
            var flatList = [];
            angular.forEach(list.list, function (tag) {
                flatList.push(tag.name);
            });
            angular.forEach(tags, function(tag) {
                if(flatList.indexOf(tag) == -1) {
                    list.list.unshift({id: 'free-form-'+tag, group: 'Entered Text', name: tag });
                }
            });
        }


    }



})();
