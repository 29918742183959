(function() {
    "use strict";

    angular
        .module('slate.library.logistics')
        .factory('SlateShipmentWatchers', ModelList);

    var apiUrl = API_URL + 'logistics/watcher/';

    function ModelList($http, $q) {
        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.isWatched = isWatched;
        list.prototype.watch = watch;
        list.prototype.unWatch = unWatch;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function isWatched(shipment) {
            var modelUrl = apiUrl + shipment + '/';
            return $http.get(modelUrl).then(function(response) {
                return response.data.watched;
            }, function(response) { return $q.reject(response); });
        }

        function watch(shipment) {
            return $http.post(apiUrl, {shipment: shipment});
        }

        function unWatch(shipment) {
            var modelUrl = apiUrl + shipment + '/';
            return $http.delete(modelUrl);
        }

    }

})();
