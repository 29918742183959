(function() {
    "use strict";

    angular
        .module('slate.library.rates')
        .factory('SlateRateSheetEstimates', ModelList)
        .factory('SlateRateSheetEstimate', ModelDetail);

    var apiUrl = API_URL + 'rates/ratesheet/';

    function ModelList($http, $q, SlateRateSheetEstimate) {
        // Set to url relative to api root.

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.loadFromPayload = loadFromPayload;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function loadFromPayload(payload) {
            var newlist = [];
            //set this for correct model
            angular.forEach(payload, function(item) {
                var itemModel = new SlateRateSheetEstimate();

                itemModel.loadFromPayload(item);
                newlist.push(itemModel);
            });
            self.list = newlist;
            return newlist;
        }
    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };


        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },

            {name: 'line_item'},
            
            {name: 'estimated_rate', type: 'float'},
            {name: 'unit_name'},
            {name: 'container_size'},
        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.getModelData = getModelData;
        model.prototype.deleteModel = deleteModel;
        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }



        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                self[field.name] = payload[field.name];

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        
        function getModelData() {
            /* jshint validthis: true */
            var self = this;
            var data = {};

            angular.forEach(fields, function(field) {
                if ((!field.readonly || field.name == 'id') && typeof(self[field.name]) != 'undefined') {
                    data[field.name] = self[field.name];

                    if (field.type == 'float') {
                        self[field.name] = parseFloat(self[field.name]);
                    }
                }
            });

            return data;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
