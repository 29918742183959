(function () {
    "use strict";

    angular
        .module('slate.invoices')
        .service('SlateInvoiceEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateInvoice) {
        var templateUrl = '/partials/invoices/invoices.edit.dialog.html';

        var srv = {
            edit: editObj
        };

        return srv;


        function editObj(obj, specs) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    obj: function () {
                        var po = new SlateInvoice();
                        if(!obj.id) {
                            return obj;
                        }
                        return po.loadFromServer(obj.id);
                    }
                }
            });

            return modalInstance.result.then(function (results) {
                return results;
            }, function () {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, $window,
                            SlateInvoiceLineItems, SlateInvoiceLineItem, SlateInvoiceLineitemEditSrv,
                            SlateCompanies, SlateLocations, SlateContacts,
                            SlateSalesOrder, SlateSOLineItems, SlateSOLineItem,
                            SlateProject, SlateRolesSrv, SlateAssignedRoles, SlateFileNode, obj) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.isComplete = isComplete;

        vm.companies = new SlateCompanies();
        vm.companies.getList();

        vm.company_locations = new SlateLocations();
        vm.setShipto = setShipto;
        vm.setBillto = setBillto;

        $window.addEventListener("dragover",function(e){
            e = e || event;
            e.preventDefault();
        },false);
        $window.addEventListener("drop",function(e){
            e = e || event;
            e.preventDefault();
        },false);

        vm.vendors = new SlateCompanies();
        vm.vendors.getList({
            vendor: true,
            qualified: true
        });

        vm.staff = new SlateContacts();
        vm.staff.getList({ staff: true });

        vm.files = [];
        vm.removeFile = removeFile;

        vm.addNewItem = addNewItem;
        vm.editItem = editItem;
        vm.calcCostTotal = calcCostTotal;
        vm.calcSellTotal = calcSellTotal;
        vm.calcMarginTotal = calcMarginTotal;

        function addNewItem() {
            var spec = new SlateInvoiceLineItem();
            spec.client_po = vm.obj.client_po;
            spec.jansy_po = vm.obj.jansy_po;
            SlateInvoiceLineitemEditSrv.edit(spec, {}, vm.obj.project).then(function(newitem){
                vm.obj.lineitems.push(newitem);
            });
        }

        function editItem(spec, $idx) {
            SlateInvoiceLineitemEditSrv.edit(spec, {}, vm.obj.project).then(function(newspec) {
                vm.itemsDirty = true;
                vm.obj.lineitems[$idx] = newspec;
            }, function(){  });

        }


        function projectSync() {
            var assroles = new SlateAssignedRoles();
            var project = new SlateProject();
            var role_dict = SlateRolesSrv.dict;
            assroles.getList({
                parent_type: 'project',
                parent_fk: vm.obj.project
            }).then(function () {

                angular.forEach(assroles.list, function (item) {
                    if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Sales Manager' && !vm.obj.salesperson) {
                        vm.obj.salesperson = item.member_fk;
                    }
                    if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Project Manager' && !vm.obj.proj_mgr) {
                        vm.obj.proj_mgr = item.member_fk;
                    }
                });

            });
            if (!vm.obj.company) {
                project.loadFromServer(vm.obj.project).then(function () {
                    if (project.parent_type == 'company') {
                        vm.obj.company = project.parent_fk;
                    }
                });
            }

        }
        $scope.$watch('vm.obj.project', function () {
            if (vm.obj.project) {
                projectSync();
            }
        });

        function salesorderSync() {
            var so_items = new SlateSOLineItems();
            if(!vm.obj.salesorder) { return false; }
            if(vm.obj.lineitems.length) { return false; }

            so_items.getList({sales_order: vm.obj.salesorder}).then(function() {
                so_items.list.forEach(function(so_item) {
                    var lineitem = new SlateInvoiceLineItem();
                    lineitem.so_lineitem = so_item.id;
                    lineitem.jansy_component = so_item.finished_good;
                    lineitem.qty = so_item.qty;
                    lineitem.sellprice = so_item.sellprice;
                    lineitem.unitprice = so_item.unitprice;
                    lineitem.name = so_item.name;

                    vm.obj.lineitems.push(lineitem);
                });
            });
        }
        $scope.$watch('vm.obj.salesorder', function (newVal, oldVal) {
            if (vm.obj.salesorder && !oldVal) {
                salesorderSync();
            }
        });

        function setShipto(address) {
            var data = "";
            if (address.street1) { data += address.street1 + "\n"; }
            if (address.street2) { data += address.street2 + "\n"; }
            if (address.street3) { data += address.street3 + "\n"; }
            if (address.city) { data += address.city + ","; }
            data += address.state + " " + address.postal;
            if (address.country) { data += "\n" + address.country; }

            vm.obj.shipto = data;
        }

        function setBillto(address) {
            var data = "";
            if (address.street1) { data += address.street1 + "\n"; }
            if (address.street2) { data += address.street2 + "\n"; }
            if (address.street3) { data += address.street3 + "\n"; }
            if (address.city) { data += address.city + ","; }
            data += address.state + " " + address.postal;
            if (address.country) { data += "\n" + address.country; }

            vm.obj.billto = data;
        }


        $scope.$watch('vm.obj.company', function () {
            if (vm.obj.company) {
                vm.selected_location = undefined;
                vm.company_locations.getList({ company: vm.obj.company });
            }
        });

        function removeFile(index) {
            vm.files.splice(index, 1);
        }

        $scope.$watch('vm.filesAdd', function () {

            angular.forEach(vm.filesAdd, function (file) {
                vm.files.push(file);
            });
        });

        function isComplete() {
            if(!vm.obj.description) { return false;}
            if(!vm.obj.order) { return false;}
            if(!vm.obj.salesorder) { return false;}
            if(vm.obj.status != 'Draft' && !vm.obj.shipment_number) { return false;}

            return true;
        }

        function saveObject() {
            if (vm.saving === true) {
                return false;
            }
            vm.saving = true;
            vm.error = false;

            if(vm.obj.shipdate && typeof vm.obj.shipdate.setHours == 'function') {
                vm.obj.shipdate.setHours(12,0,0);
            }
            vm.obj.saveModel().then(function () {
                var filepromise = $q.resolve();
                var promises = [];
                vm.savedFiles = [];

                vm.obj.lineitems = vm.obj.lineitems.filter(function(item) {
                    if(item.to_delete) { return false; }
                    return true;
                });

                angular.forEach(vm.files, function (file) {
                    var newFile = new SlateFileNode();
                    var payload;

                    if (vm.obj.company) {
                        payload = {
                            parent_type: 'company',
                            parent_fk: vm.obj.company,
                            path: 'Invoice Requests/' + vm.obj.description,
                            filename: file.name,
                            description: file.description,
                        };
                    }

                    if (payload) {
                        newFile.loadFromPayload(payload);

                        filepromise = filepromise.then(function () {
                            return newFile.saveModel(file).then(function () {
                                vm.savedFiles.push(newFile);
                                var parent_fk = vm.obj.id;
                                var parent_type = 'invoicerequest';
                                // if (vm.obj.order) {
                                //     console.log('upload to order ', vm.obj.order);
                                //     parent_fk = vm.obj.order;
                                //     parent_type = 'order';
                                // }

                                var linkedNode = new SlateFileNode();
                                linkedNode.loadFromPayload({
                                    mimetype: newFile.mimetype,
                                    parent_type: parent_type,
                                    parent_fk: parent_fk,
                                    path: '',
                                    filename: file.name,
                                    core_id: newFile.core_id,
                                    description: file.description,
                                });
                                linkedNode.saveModel();
                            });
                        });
                    } else {
                        var parent_fk = vm.obj.id;
                        var parent_type = 'invoicerequest';
                        // if (vm.obj.order) {
                        //     parent_fk = vm.obj.order;
                        //     parent_type = 'order';
                        // }

                        payload = {
                            parent_type: parent_type,
                            parent_fk: parent_fk,
                            path: '',
                            filename: file.name,
                            description: file.description,
                        };
                        newFile.loadFromPayload(payload);
                        filepromise = filepromise.then(function () {
                            return newFile.saveModel(file).then(function () {
                                vm.savedFiles.push(newFile);
                            });
                        });
                    }

                });
                promises.push(filepromise);
                $q.all(promises).then(function () {
                    vm.saving = false;
                    $uibModalInstance.close(vm.obj);
                }, function () {
                    vm.saving = false;
                    vm.errorSaving = true;
                });
            }, function () {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function calcSellTotal() {
            var total = new Big("0.0");
            angular.forEach(vm.obj.lineitems, function (spec) {
                var val;
                try {
                    val = new Big(spec.getSellTotal());
                } catch (e) { val = new Big(0); }
                if (!isNaN(val)) {
                    total = total.plus(val);
                }
            });
            return total.toFixed(5);
        }

        function calcCostTotal() {
            var total = new Big("0.0");
            angular.forEach(vm.obj.lineitems, function (spec) {
                var val;
                try {
                    val = new Big(spec.getCostTotal());
                } catch (e) { val = new Big(0); }
                if (!isNaN(val)) {
                    total = total.plus(val);
                }
            });
            return total.toFixed(5);
        }

        function calcMarginTotal() {
            var margin = new Big("0.0");
            var cost = calcCostTotal();
            var sell = calcSellTotal();

            if(!sell || sell == '0.00000') { return 0.0; }
            margin = margin.plus(sell).minus(cost);
            margin = margin.div(sell);
            margin = margin.times(100);

            return margin.toFixed(2);
        }


    }



})();
