(function () {
    'use strict';

    angular
        .module('slate.locations')
        .directive('slateWarehouseSearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/locations/warehouse.searchbox.dir.html',
            scope: {
                warehouseId: '=',
                companyIds: '=?',
                warehouseType: '=?',
                readonly: '<?',
                showDropdown: '<?',
                validOnly: '<?',
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function directiveController($scope, $q, SlateOrder, SlateCompany, SlateLocations, SlateLocation) {
        /* jshint validthis: true */
        const vm = this;

        vm.specs = new SlateLocations();
        vm.dropdownSpecs = new SlateLocations();
        vm.dropdownList = [];
        let selectedSpec;

        vm.getDropDown = getDropDown;
        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.clear = clear;

        vm.autoselected = false;

        vm.selectDropdown = selectDropdown;
        if (typeof vm.companyIds === 'string') { vm.companyIds = [vm.companyIds]; }

        getDropDown();

        function getDropDown() {
            const deferred = $q.defer();
            const promises = [];
            const newList = [];

            if (!vm.showDropdown) { return false; }
            if (!vm.companyIds) { return false; }

            vm.companyIds.forEach((companyId) => {
                const company = new SlateCompany();
                if (!companyId) { return false; }

                const promise = company.loadFromServer(companyId).then(() => {
                    const warehouses = new SlateLocations();
                    const filters = {
                        company: companyId,
                        warehouse_only: true,
                    };
                    if (vm.warehouseType) {
                        filters.warehouse_type = vm.warehouseType;
                    }
                    return warehouses.getList(filters).then(() => {
                        newList.push({
                            company_name: company.name,
                            warehouses: warehouses.list,
                        });
                    });
                });

                promises.push(promise);
                return promise;
            });

            $q.all(promises).then(() => {
                vm.dropdownList = newList;
                autoSelectDropdown();
                deferred.resolve(vm.dropdownList);
            }, () => {
                vm.dropdownList = newList;
                autoSelectDropdown();
                deferred.resolve(vm.dropdownList);
            });

            return deferred.promise;
        }

        function select_warehouse_is_valid() {
            return vm.dropdownList.some((co_entry) => {
                if (!co_entry.warehouses) { return false; }
                return co_entry.warehouses.some((warehouse) => warehouse.id === vm.warehouseId && warehouse.warehouse_type.includes((vm.warehouseType)));
            });
        }

        function autoSelectDropdown() {
            if (vm.validOnly && vm.warehouseId && !select_warehouse_is_valid()) {
                clear();
            }

            if (vm.warehouseId && !vm.autoselected) {
                return false;
            }

            return vm.dropdownList.find((co_entry) => {
                if (!co_entry.warehouses) { return false; }
                return co_entry.warehouses.find((warehouse) => {
                    if (vm.warehouseId) { return false; }
                    if (vm.warehouseType && !warehouse.warehouse_type.includes((vm.warehouseType))) { return false; }

                    selectDropdown(warehouse, true);
                    vm.autoselected = true;
                    return warehouse;
                });
            });
        }

        function selectDropdown($item, force) {
            if (vm.readonly && !force) { return false; }
            vm.searching = false;
            vm.warehouseId = $item.id;
            selectedSpec = $item.id;
            vm.specObj = $item;
            vm.specName = $item.qualifier;
            if ($item.warehouse_name) { vm.specName = $item.warehouse_name; }
            return true;
        }


        function getSearch(q) {
            if (vm.readonly) { return false; }
            const filters = {
                q,
                page: 1,
                count: 50,
            };
            vm.searching = true;
            if (q && q.length < 3) { return $q.reject([]); }
            // if (vm.companyId) { filters.company = vm.companyId;}
            filters.warehouse_only = true;
            if (vm.warehouseType) { filters.warehouse_type = vm.warehouseType; }
            const promise = vm.specs.getList(filters).then(() => vm.specs.list);
            return promise;
        }

        function selectSearch($item, $model, $label, $event) {
            if (vm.readonly) { return false; }
            vm.searching = false;
            vm.warehouseId = $item.id;
            selectedSpec = $item.id;
            vm.specObj = $item;
            vm.specName = $item.qualifier;
            if ($item.warehouse_name) { vm.specName = $item.warehouse_name; }
            return true;
        }

        function clear() {
            if (vm.readonly) { return true; }
            vm.warehouseId = null;
            selectedSpec = null;
            vm.specObj = null;
            vm.specName = null;
            return true;
        }

        $scope.$watch('vm.warehouseId', () => {
            if (selectedSpec !== vm.warehouseId && vm.warehouseId) {
                const spec = new SlateLocation();
                spec.loadFromServer(vm.warehouseId).then(() => {
                    vm.specObj = spec;
                    vm.specName = spec.qualifier;
                    if (spec.warehouse_name) { vm.specName = spec.warehouse_name; }
                    selectedSpec = spec.id;
                });
            }
        });

        $scope.$watch('vm.companyIds', () => {
            getDropDown();
        });

        $scope.$watch('vm.warehouseType', () => {
            getDropDown();
        });

        $scope.$watch('vm.typeaheadOpen', () => {
            if (vm.readonly) { return false; }
            if (!vm.typeaheadOpen && !vm.specObj && vm.warehouseId) {
                const spec = new SlateLocation();
                spec.loadFromServer(vm.warehouseId).then(() => {
                    vm.specObj = spec;
                    vm.specName = spec.qualifier;
                    if (spec.warehouse_name) { vm.specName = spec.warehouse_name; }
                    selectedSpec = spec.id;
                });
            }
            return true;
        });
    }
})();
