(function() {
    "use strict";

    angular
        .module('slate.salesorders')
        .directive('slateSalesOrderList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/salesorders/salesorder.list.dir.html',
            scope: {
                modelFilters: '=?filters',
                showNew: '=?',
                hideFilters: '=?',
                fields: '=?',
                showTotals: '=?',
                soList: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlateSalesOrders, SlateSalesOrder, getLinkSrv, SlateDeleteObjSrv) {
        /* jshint validthis: true */
        var vm = this;

        var modelPromise;
        var filesPromise;

        var multiSearchFields = ['shipvia', 'ship_terms', 'status'];

        vm.auth = SlateAuth;

        vm.get_url = get_url;
        vm.getStyle = getStyle;

        vm.addNew = addNew;

        vm.deleteSO = deleteSO;

        if(!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'status',
                'name',
                'jansy_so',
                'client_po',
                'company',
                'proj_mgr',
                'sales_mgr',
                'price_total',
                'sell_total',
                'margin',
                'date',
                'desired_date',
                'created_by'

            ];
            /* beautify preserve:end */
        }

        vm.getLink = getLinkSrv;
        vm.loading = true;
        vm.filters = {
            page: 1,
            count: 25,    
        };
        if(!vm.hideFilters) {
            angular.extend(vm.filters, {
                status: null,
                startDate: $location.search().startDate,
                endDate: $location.search().endDate,
            });
        }

        multiSearchFields.forEach(function(field) {
            vm.filters[field] = null;
        });

        var basicStatus = $location.search().status;

        if(!vm.modelFilters) { vm.modelFilters = {}; }
        angular.extend(vm.filters, {
            q: $location.search().q,
        }, vm.modelFilters, $location.search());
        if(typeof vm.filters.page == 'string') { vm.filters.page = parseInt(vm.filters.page); }
        if(typeof vm.filters.count == 'string') { vm.filters.count = parseInt(vm.filters.count); }
        if(typeof vm.filters.order_num == 'string') { vm.filters.order_num = parseInt(vm.filters.order_num); }
        var parts;
        if(vm.filters.startDate) {
            parts = vm.filters.startDate.split('-');
            vm.start = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
        }
        if(vm.filters.endDate) {
            parts = vm.filters.endDate.split('-');
            vm.end = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
        }
        $scope.$watch('vm.start', function () {
            if (vm.start) {
                vm.filters.startDate = vm.start.getFullYear() + '-' + (vm.start.getMonth()+1) + '-' + vm.start.getDate();
            } else {
                vm.filters.startDate = null;
            }
        });
        $scope.$watch('vm.end', function () {
            if (vm.end) {
                vm.filters.endDate = vm.end.getFullYear() + '-' + (vm.end.getMonth()+1) + '-' + vm.end.getDate();
            } else {
                vm.filters.endDate = null;
            }
        });
        var deliveryParts;
        if(vm.filters.startDate) {
            deliveryParts = vm.filters.startDate.split('-');
            vm.startDelivery = new Date(parseInt(deliveryParts[0]), parseInt(deliveryParts[1])-1, parseInt(deliveryParts[2]) );
        }
        if(vm.filters.endDate) {
            deliveryParts = vm.filters.endDate.split('-');
            vm.endDelivery = new Date(parseInt(deliveryParts[0]), parseInt(deliveryParts[1])-1, parseInt(deliveryParts[2]) );
        }
        $scope.$watch('vm.startDelivery', function () {
            if (vm.startDelivery) {
                vm.filters.startDeliveryDate = vm.startDelivery.getFullYear() + '-' + (vm.startDelivery.getMonth()+1) + '-' + vm.startDelivery.getDate();
            } else {
                vm.filters.startDeliveryDate = null;
            }
        });
        $scope.$watch('vm.endDelivery', function () {
            if (vm.endDelivery) {
                vm.filters.endDeliveryDate = vm.endDelivery.getFullYear() + '-' + (vm.endDelivery.getMonth()+1) + '-' + vm.endDelivery.getDate();
            } else {
                vm.filters.endDeliveryDate = null;
            }
        });

        multiSearchFields.forEach(function(field) {
            if (vm.filters[field] && typeof vm.filters[field] === 'string') {
                vm.filters[field] = [vm.filters[field]];
            }
        });

        vm.soList = new SlateSalesOrders();

        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;
        
        //vm.updateFromFilter();

        vm.getSellTotal = getSellTotal;
        vm.getPriceTotal = getPriceTotal;
        vm.getMarginTotal = getMarginTotal;

        function get_url() {
            var url =   API_URL + 'salesorders/so/';
            if($window.location.search.length ) {
                return url + $window.location.search + '&download=true';
            } else {
                return url + '?download=true';
            }
        }

        function deleteSO(so){
            SlateDeleteObjSrv.delete('salesorder', so.id).then(function() {
                vm.updateFromFilter();
            });
        }


        function addNew() {
            var url = "/salesorders/so/new/";
            var params = {
                order: vm.modelFilters.order,
            };
            var queryString;
            queryString = queryString = Object.keys(params).map(function(key) {
                return key + '=' + params[key];
            }).join('&');
            $location.url(url + '?' + queryString);
        }

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });
        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function(value, key) {
                if(key == 'page' || key == 'count' || key == 'q') {
                    return false;
                }
                if(typeof vm.filters[key] == 'undefined' || vm.filters[key] === undefined || vm.filters[key] === null) {
                    return false;
                }
                if(vm.filters[key] == vm.modelFilters[key]) {
                    return false;
                }
                if(typeof vm.filters[key] == 'string' && vm.filters[key].length === 0) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;

            });
            return hasData;
        }

        function updateFromFilter() {
            var updatePromise;
            var filter_data;
            vm.loading = true;
            if (typeof vm.filters.status == 'string') {
                vm.filters.status = [vm.filters.status];
            }

            if(!vm.hideFilters) {
                angular.forEach(vm.filters, function (value, item) {
                    if(vm.modelFilters[item]) { return false; }
                    if (vm.filters[item]) {
                        $location.replace().search(item, vm.filters[item]);
                    } else {
                        $location.replace().search(item, null);
                    }
                });
            }

            filter_data = _.cloneDeep(vm.filters);
            filter_data.fields = vm.fields;
            updatePromise = vm.soList.getList(filter_data);
            updatePromise.then(function() {
                vm.loading = false;
            });
        }


        function getPriceTotal() {
            var total = Big(0.0);
            vm.soList.list.forEach(function (item) {
                total = total.add(item.price_total);
            });
            return total.toFixed(5);
        }

        function getSellTotal() {
            var total = Big(0.0);
            vm.soList.list.forEach(function (item) {
                total = total.add(item.sell_total);
            });
            return total.toFixed(5);
        }

        function getMarginTotal() {
            var sell = getSellTotal();
            var cost = getPriceTotal();
            var total = Big(0.0);
            if(sell == 0.00000) { return 0.0; }
            total = total.add(sell).minus(cost);
            total = total.div(sell);
            total = total.times(100.0);
            return total.toFixed(2);
        }


        function getStyle(field) {
            if(field == 'name') {
                return {
                    'width': '100%',
                    'overflow': 'hidden',
                    'white-space': 'nowrap',
                    'text-overflow': 'ellipsis'
                };
            }

            return {
                'overflow': 'hidden',
                'white-space': 'nowrap',
                'text-overflow': 'ellipsis',
            };
        }
    }


})();
