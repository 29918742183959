(function() {
    "use strict";

    angular
        .module('slate.customercomplaints')
        .controller('SlateCustomerComplaintEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateCustomerComplaint) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.complaint = new SlateCustomerComplaint();

        vm.complaint.loadFromServer($stateParams.id).then(function () {
            if (vm.complaint.purchase_order_info.name) {
                SlateTitle.set('Edit ' + vm.complaint.purchase_order_info.name + ' - Customer Complaint');
            } else {
                SlateTitle.set('Edit Untitled Customer Complaint - Customer Complaints');
            }
            vm.loading = false;
            
        }).catch(angular.noop);

    }

})();


