(function () {
    "use strict";

    angular
        .module('slate.presentations')
        .directive('slatePresPhotoList', slateListDir);

    function slateListDir() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/presentations/presphotos.list.dir.html',
            scope: {
                filters: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
        };
    }

    function ListController($scope, $state, $stateParams, $location, $q, SlatePresCategories, SlatePresPhotos, SlatePresPhotoUpload, SlatePresentations, SlatePresentation, SlateSlide, SlatePresPhotoEdit) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;


        vm.cats = SlatePresCategories;
        vm.selected_cats = {};
        vm.presList = new SlatePresentations();
        vm.presList.getList();
        vm.presRefresh = presRefresh;
        vm.presSelect = presSelect;
        vm.pres = new SlatePresentation();
        vm.gettingPres = false;
        vm.selectImage = selectImage;
        vm.selectedPhotos=[];
        vm.editPhoto = editPhoto;
        vm.editSelected = editSelected;
        vm.photoDragOptions = {
            connectWith: ".slides",
            stop: function () {

            },
            update: function (e, ui) {
                if (ui.item.sortable.droptarget.hasClass('slides')) {
                    vm.pres.slides.list.splice(ui.item.sortable.dropindex, 0, {});
                    var newSlide = new SlateSlide();
                    vm.pres.slides.list.splice(ui.item.sortable.dropindex, 1, newSlide);
                    newSlide.loadFromPayload({
                        photopayload: ui.item.sortable.model,
                    });

                }
                ui.item.sortable.cancel();
            }
        };

        vm.cats.promise.then(function () {
            if (!vm.filters.cats) { vm.filters.cats = []; }
            angular.forEach(vm.cats.list, function (cat) {
                if (typeof vm.selected_cats[cat.group] == "undefined") {
                    vm.selected_cats[cat.group] = {};
                }
                if (vm.filters.cats.indexOf(cat.name) !== -1) {
                    vm.selected_cats[cat.group][cat.name] = true;
                } else {
                    vm.selected_cats[cat.group][cat.name] = false;
                }

            });
        });

        $scope.$watch(function () {
            return vm.selected_cats;
        }, function () {
            var cats = [];
            angular.forEach(vm.selected_cats, function (group, key) {
                angular.forEach(group, function (value, key) {
                    if (value) {
                        cats.push(key);
                    }
                });
            });
            vm.filters.cats = cats;
        }, true);


        vm.pagerOptions = {};
        if (!$scope.filters) {
            $scope.filters = {};
        }
        vm.filters = {
            confidential: $location.search().confidential || false,
            q: $location.search().q,
            tags: $location.search().tags,
            cats: [],
        };


        vm.photoList = new SlatePresPhotos();
        modelPromise = updateFromFilter();

        vm.galleries = [];

        vm.upload = upload;
        vm.addNew = addNew;
        vm.updateFromFilter = updateFromFilter;
        vm.updatePagerInfo = updatePagerInfo;

        vm.saveAndDownload = saveAndDownload;

        modelPromise.then(function () {
            angular.extend(vm.pagerOptions, {
                currentPage: parseInt($location.search().page) || 1,
                itemsPerPage: parseInt($location.search().itemsperpage) || 25,
            });
            vm.pagerOptions.numPages = vm.pagerOptions.currentPage + 1;
        });


        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            updateFromFilter();
        });

        function updatePagerInfo() {
            $location.replace().search('page', vm.pagerOptions.currentPage);
            $location.replace().search('itemsperpage', vm.pagerOptions.itemsPerPage);
            updateFromFilter();
        }

        function updateFromFilter() {
            var newList = [];
            var offset = 0;
            var page = parseInt($location.search().page) || 1;
            var itemsPerPage = parseInt($location.search().itemsperpage) || 25;
            var promise;
            // update search parans
            angular.forEach(vm.filters, function (value, key) {
                if (value === "") {
                    $location.replace().search(key, null);
                } else {
                    $location.replace().search(key, value);
                }

            });


            promise = vm.photoList.getList(angular.extend({}, vm.filters, $scope.filters));

            promise.then(function () {
                vm.pagerOptions.totalItems = vm.photoList.list.length;
                offset = itemsPerPage * (page - 1);
                vm.pagedPhotos = vm.photoList.list.slice(offset, itemsPerPage + offset);
            });

            return promise;

        }

        function upload() {
            var newObj;
            SlatePresPhotoUpload.edit().then(function (results) {
                angular.forEach(results, function (photo) {
                    vm.photoList.list.splice(0, 0, photo);

                });
                updateFromFilter();
            });

        }

        function addNew($event) {
            var newObj = new SlatePresPhoto();
            var promise;
            promise = SlateGalleryEdit.edit(newObj);
            promise.then(function () {
                //$location.path(getLinkSrv.getUrl('company', newCompany.id, newCompany.slug)).search({});
                $state.transitionTo($state.current, $stateParams, {
                    reload: true,
                    inherit: false,
                    notify: true
                });
            }, function () {

            });
            return promise;
        }

        function presRefresh(q) {

            if (q.length >= 3 && false) {
                vm.presList.getList({
                    q: q
                });
            } else {
                vm.presList.getList();
            }
        }

        function presSelect(id) {

            var newpres = new SlatePresentation();
            newpres.loadFromServer(id).then(function () {
                vm.pres = newpres;
            });

        }


        function saveAndDownload() {
            vm.gettingPres = true;
            vm.presUrl = null;
            vm.pres.saveModel().then(function () {
                vm.pres.download().then(function (payload) {
                    vm.gettingPres = false;
                    if (payload.url) {
                        vm.presUrl = payload.url;
                    }
                });
            });
        }


        function selectImage(image, $index, $event) {
            image.selected = !image.selected;
            if ($event.shiftKey && $index) {
                var step = $index - 1;
                var i;
                var found = false;
                for (step = $index - 1; step >= 0 && !found; step--) {
                    if (vm.pagedPhotos[step].selected) { found = true; }
                }
                if (found) {
                    for (i = step+1; i <= $index; i++) {
                        vm.pagedPhotos[i].selected = true;
                    }
                }
            }
            var selectedPhotos = [];
            angular.forEach(vm.pagedPhotos, function (photo, idx) {
                if (photo.selected) {
                    selectedPhotos.push(photo);
                }
            });
            vm.selectedPhotos = selectedPhotos;
        }

        function editPhoto(photo) {
            SlatePresPhotoEdit.edit([photo]).then(function() { updateFromFilter();}, function() {updateFromFilter();} );
        }

        function editSelected() {
            SlatePresPhotoEdit.edit(vm.selectedPhotos).then(function() { updateFromFilter();}, function() {updateFromFilter();} );
        }
    }



})();
