(function () {
    "use strict";

    angular
        .module('slate.projects')
        .service('SlatePriceTierEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlatePriceTier, SlateProject) {
        var templateUrl = '/partials/projects/project.pricing.edit.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj, projectId) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj: function () {
                        var newPriceTier = new SlatePriceTier();
                        if (!obj) {
                            return newPriceTier;
                        }
                        return newPriceTier.loadFromPayload(obj);
                    },
                    project: function() {
                        var project = new SlateProject();
                        return project.loadFromServer(projectId);
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return obj;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateAuth, obj, project) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;
        vm.project = project;
        vm.auth = SlateAuth;

        vm.saving = false;
        vm.errorSaving = false;
        vm.minDate = new Date();
        vm.addPriceBreak = addPriceBreak;
        vm.isComplete = isComplete;
        vm.saveObject = saveObject;
        vm.cancel = cancel;


        function addPriceBreak() {
            vm.obj.prices.push({quantity: 0, price: 0});
        }

        function isComplete() {
            if (vm.project.project_nomenclature == 'variation' && !vm.obj.vendor) { return false; }
            if (!vm.obj.expires) { return false; }
            if(vm.obj.prices.some(function(item) {
                if(item.quantity === '' || item.price === '') { return true; }
                if(isNaN(item.quantity) || isNaN(item.price)) { return true; }
            })) { return false; }
            return true;
        }

        function saveObject() {
            if (vm.saving) { return false; }
            if (!vm.isComplete()) { return false; }
            vm.saving = true;

            vm.obj.saveModel().then(function() {
                console.log('id', vm.obj.id);
                vm.fileSrv.uploadFiles('pricetier', vm.obj.id).then(function() {
                    vm.saving = false;
                    $uibModalInstance.close(vm.obj);
                }, function() {
                    vm.saving = false;
                    vm.errorSaving = true;    
                });
                vm.saving = false;
            }, function () {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }

})();
