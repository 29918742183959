(function() {
    "use strict";

    angular
        .module('slate.qc')
        .controller('SlateQCLogNewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateQCLog) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = false;
        vm.qc = new SlateQCLog();
    }

})();
