(function() {
    "use strict";

    angular
        .module('slate.library.roles')
        .factory('SlateBlanketRoles', ModelDetail);


    var apiUrl = API_URL + 'roles/blanket_roles/';


    function ModelDetail($http, $q) {

        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },
            {name: 'is_global', def: true, readonly: true },
            {name: 'is_default', def: false, readonly: true },
            {name: 'roles', def: []},
            {name: 'member_type', def: 'contact' },
            {name: 'member_fk', def: null },
            {name: 'member_slug', def: null, readonly: true },
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                self[field.name] = payload[field.name];
            });

            if (self.role_permissions) {
                self.role_permissions = self.role_permissions.sort(function compare(a, b) {
                    var results = a.cat.localeCompare(b.cat);
                    if (results === 0) {
                        return a.perm_type.localeCompare(b.perm_type);
                    }
                    return results;
                });
            }

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id, type) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var promise;

            if (!self.member_fk && !id) {
                console.log('Tried to load from server without id');
                return self;
            }

            promise = $http.get(apiUrl, {
                params: {
                    member_fk: id,
                    member_type: type
                }
            });

            promise.then(function(response) {

                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            angular.forEach(fields, function(field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                }
            });

            promise = $http.post(modelUrl, data);
            promise.then(function(response) {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;
                deferred.resolve(self);
            });

            return deferred.promise;
        }


    }

})();
