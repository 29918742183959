(function () {
    'use strict';

    angular
        .module('slate.salesquotes')
        .directive('slateSalesEstimateLineitems', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/estimates/salesestimates.lineitems.dir.html',
            scope: {
                lineitems: '=',
                salesEstimate: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController() {
        /* jshint validthis: true */
        const vm = this;

        vm.addLineitem = addLineitem;
        vm.removeItem = removeItem;
        vm.addBreak = addBreak;
        vm.removeBreak = removeBreak;

        vm.froalaOptions = {
            toolbarButtons: ['bold',
                'italic',
                'underline',
                '|',
                'align',
                'formatOL',
                'formatUL',
                'insertImage'],
            imageUploadURL: `${API_URL}files/nodes/`,
            imageUploadMethod: 'POST',
            imageUploadParams: {
                parent_type: 'salesestimate',
                parent_fk: vm.salesEstimate.id,
                path: '/',
                filename: new Date().toISOString()
            },
            events: {
                'image.replaced': function ($img, response) {
                    console.log($img);
                    console.log(response);
                }
            },
            placeholderText: 'Item Specifications',
            height: 200,
            zIndex: 50
        };

        function addLineitem() {
            vm.lineitems.push({
                name: 'untitled',
                breaks: [{
                    qty: '1',
                    price: '0.25'
                }]
            });
        }

        function removeItem(idx) {
            vm.lineitems.splice(idx, 1);
        }

        function addBreak(item) {
            item.breaks.push({ qty: '0', price: '0' });
        }

        function removeBreak(item, idx) {
            item.breaks.splice(idx, 1);
        }
    }
})();
