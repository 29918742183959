(function() {
    "use strict";

    angular
        .module('slate.notifications')
        .directive('slateNotificationsList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/notifications/notifications.list.dir.html',
            scope: {
                user: '=?',
                hideFilters: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $location, $http, SlateAuth, SlateNotifications) {
        /* jshint validthis: true */
        var vm = this;

        vm.loading = true;

        vm.auth = SlateAuth;
        vm.notifications = new SlateNotifications();

        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;
        vm.markRead = markRead;
        vm.markAllRead = markAllRead;

        if(!vm.hideFilters) {
            vm.filters = {
                page: 1,
                count: 50,
                startDate: $location.search().startDate,
                endDate: $location.search().endDate,
            };
        }
        angular.extend(vm.filters, {
            q: $location.search().q,
        }, $location.search(), vm.filters);
        if(typeof vm.filters.page == 'string') { vm.filters.page = parseInt(vm.filters.page); }
        if(typeof vm.filters.count == 'string') { vm.filters.count = parseInt(vm.filters.count); }
        var parts;
        if(vm.filters.startDate) {
            parts = vm.filters.startDate.split('-');
            vm.start = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
        }
        if(vm.filters.endDate) {
            parts = vm.filters.endDate.split('-');
            vm.end = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
        }
        $scope.$watch('vm.start', function () {
            if (vm.start) {
                vm.filters.startDate = vm.start.getFullYear() + '-' + (vm.start.getMonth()+1) + '-' + vm.start.getDate();
            } else {
                vm.filters.startDate = null;
            }
        });
        $scope.$watch('vm.end', function () {
            if (vm.end) {
                vm.filters.endDate = vm.end.getFullYear() + '-' + (vm.end.getMonth()+1) + '-' + vm.end.getDate();
            } else {
                vm.filters.endDate = null;
            }
        });

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function(value, key) {
                if(key == 'page' || key == 'count' || key == 'q') {
                    return false;
                }
                if(typeof vm.filters[key] == 'undefined' || vm.filters[key] === undefined || vm.filters[key] === null) {
                    return false;
                }
                if(typeof vm.filters[key] == 'string' && vm.filters[key].length === 0) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;

            });
            return hasData;
        }

        function updateFromFilter() {
            var filters = {};
            vm.loading = true;

            if(!vm.hideFilters) {
                angular.forEach(vm.filters, function (value, item) {
                    if (vm.filters[item]) {
                        $location.replace().search(item, vm.filters[item]);
                    } else {
                        $location.replace().search(item, null);
                    }
                });
            }

            vm.notifications.getList(vm.filters).then(function() {
                vm.loading = false;
            });
        }

        function markRead(idx) {
            if(!vm.notifications.list[idx].read) {
                vm.notifications.list[idx].read = true;
                vm.notifications.list[idx].saveModel();
            }
        }

        function markAllRead() {
            var apiUrl = API_URL + 'notifications/notification/mark-read/';
            var data = {};
            if(vm.loading) { return false; }
            if(!window.confirm('Are you sure you want to mark all notifications as read?')) { return false;}
            vm.loading = true;
            if(vm.filters.user && vm.auth.perms.notifications_can_see_others) { data.user = vm.filter.user; }
            $http.post(apiUrl, data).then(function(response) {
                updateFromFilter();
            },
            function(response){
                vm.loading = false;
           });

        }
    }


})();
