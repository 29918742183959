(function () {
    "use strict";

    angular
        .module('slate.library.rfqs')
        .factory('SlateRFQResponseLineItems', ModelList)
        .factory('SlateRFQResponseLineItem', ModelDetail);


        var apiUrl = API_URL + 'rfq/rfq-item-prices-by-component/';

        function ModelList($http, $q, SlateRFQResponseLineItem) {
            // Set to url relative to api root.

            // instantiate our initial object
            var list = function construct() {
                var self = this;

                self.clear();

                return self;
            };

            list.prototype.clear = clear;
            list.prototype.getList = getList;

            return list;

            function clear() {
                /* jshint validthis: true */
                var self = this;

                self.list = [];
                self.filters = {};
                self.permissions = [];

                return self;
            }

            function getList(filters, unique) {
                /* jshint validthis: true */
                var self = this;
                var deferred = $q.defer();
                var promise;
                var promises = [];

                if (typeof filters === "object") {
                    self.filters = filters;
                }

                promise = $http.get(apiUrl, {
                    params: self.filters
                });

                promise.then(function(response) {
                    var newlist = [];
                    //set this for correct model
                    angular.forEach(response.data.payload, function(item) {
                        var itemModel = new SlateRFQResponseLineItem();
                        itemModel.loadFromPayload(item);

                        newlist.push(itemModel);
                    });

                    if(unique) {
                        newlist = newlist.filter(function(item, idx) {
                            return idx == newlist.findIndex(function(el) { return item.rfq_response.vendor.id == el.rfq_response.vendor.id; });
                        });
                    }

                    self.list = newlist;
                    self.totalItems = response.data.totalItems;
                    self.permissions = response.data.permissions;

                    deferred.resolve(self);
                });

                return deferred.promise;
            }
        }

    function ModelDetail($http, $q, SlateRFQResponseLineItemOption, SlateRFQResponseLineItemFlatCost) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            { name: 'id', readonly: true },
            { name: 'rfq_response' },
            { name: 'rfq_comp' },

            { name: 'sort_order' },

            { name: 'name' },

            { name: 'desired_qty', type: 'float', def: 0 },
            { name: 'unit_name', def: "ea" },

            { name: 'carton_qty', type: 'float', def: 0 },
            { name: 'carton_net_weight', type: 'float', def: 0 },
            { name: 'carton_gross_weight', type: 'float', def: 0 },
            { name: 'carton_weight_measure', def: "kg" },
            { name: 'carton_length', type: 'float', def: 0 },
            { name: 'carton_width', type: 'float', def: 0 },
            { name: 'carton_height', type: 'float', def: 0 },
            { name: 'carton_size_measure', def: "mm" },
            { name: 'packout_method' },

            { name: 'item_weight', type: 'float', def: 0 },
            { name: 'item_weight_measure', def: "kg" },

            { name: 'specs' },
            { name: 'component', readonly: true },
            { name: 'component_info', readonly: true },

            { name: 'splits', type: 'json', def: [] },

            { name: 'options', type: 'child_list', model: SlateRFQResponseLineItemOption, readonly: true },
            { name: 'flatcosts', type: 'child_list', model: SlateRFQResponseLineItemFlatCost, readonly: true },

            { name: 'created', readonly: true },
            { name: 'updated', readonly: true },
            { name: 'deleted', readonly: true, default: false },
        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.getModelData = getModelData;
        model.prototype.calcCBMs = calcCBMs;
        model.prototype.calcTotalCBMs = calcTotalCBMs;
        model.prototype.getSplitCost = getSplitCost;
        model.prototype.getTariffTotal = getTariffTotal;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function (field) {
                self[field.name] = _.cloneDeep(field.def);
                if (field.type == "date" && !self[field.name]) {
                    self[field.name] = new Date();
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function (field) {
                if (!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if (field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type == 'dateOnly' && payload[field.name]) {
                    if (typeof payload[field.name] == 'string') {
                        var parts = self[field.name].split('-');
                        self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
                    }
                }

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }
                if (field.type == 'int') {
                    self[field.name] = parseInt(self[field.name]);
                }


                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                    }
                }

                if (field.type == 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach(function (item, idx, array) {
                            var obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch (e) {
                        console.log('error loading: ', e);
                    }
                }

            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function (response) {
                var promises = [];

                return self.loadFromPayload(response.data.payload).then(function () {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            var self = this;
            var data = {};

            angular.forEach(fields, function (field) {
                if ((!field.readonly || field.name == 'id') && typeof (self[field.name]) != 'undefined') {
                    data[field.name] = self[field.name];
                    if (field.type == "date") {
                        data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth() + 1) + '-' + self[field.name].getUTCDate();
                    }
                    if (field.type == "dateOnly" && self[field.name]) {
                        data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth() + 1) + '-' + self[field.name].getDate();
                    }
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            data = getModelData();

            promise = $http.post(modelUrl, data);
            promise = promise.then(function (response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function (response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

        function calcCBMs() {
            /* jshint validthis: true */
            var self = this;

            var l,w,h, cbmPerCarton;
            if ([
                self.carton_height,
                self.carton_length,
                self.carton_width,
            ].some(function (item) { return item === 0.0; })) { return new Big(0); }
            l = new Big(Qty(self.carton_length, self.carton_size_measure).to('cm').scalar);
            w = new Big(Qty(self.carton_width, self.carton_size_measure).to('cm').scalar);
            h = new Big(Qty(self.carton_height, self.carton_size_measure).to('cm').scalar);

            cbmPerCarton = l.times(w).times(h).div(1000000);
            return cbmPerCarton;
        }

        function calcTotalCBMs(desired_qty) {
            /* jshint validthis: true */
            var self = this;

            var cbmPerCarton, desiredQty, qtyPerCarton, cartons, totalCbms;
            if(!desired_qty) { desired_qty = self.desired_qty; }
            if ([
                desired_qty,
                self.carton_qty,
            ].some(function(item) { return item === 0.0; })) { return new Big(0); }

            cbmPerCarton = self.calcCBMs();

            desiredQty = new Big(desired_qty);
            qtyPerCarton = new Big(self.carton_qty);
            cartons = desiredQty.div(qtyPerCarton);

            totalCbms = cartons.round(0, 3).times(cbmPerCarton);

            return totalCbms;
        }

        function getTariffTotal(split, qty, tariffs) {
            /* jshint validthis: true */
            var self = this;
            var cost = 0.0;

            if(!self.rfq_response.vendor.include_tariff) { return 'n/a'; }

            if(isNaN(qty)) { qty = 0.00; }
            cost = Big(self.getSplitCost(split));

            return cost.times(qty).times(tariffs).toFixed(3);
        }

        function getSplitCost(split, option_name) {
            /* jshint validthis: true */
            var self = this;
            var option;
            var split_num = parseFloat(split);
            var key;
            var key_number = 0.0;

            option =  self.options.find(function(item) {
                if(option_name) {
                    return item.name == option_name;
                } else {
                    return item.primary;
                }
            });

            Object.keys(option.prices).forEach(function(price) {
                var current_num;
                if(isNaN(price) || isNaN(option.prices[price])) { return false; }
                if(option.prices[price] === '') { return false; }
                current_num = parseFloat(price);

                if(key === undefined) {
                    key = price;
                    key_number = parseFloat(price);
                }
                if(current_num <= split_num && current_num > key_number) {
                    key = price;
                    key_number = parseFloat(price);
                }
            });
            if(!option.prices[key]) { return "0.00"; }
            return option.prices[key];
        }

    }

})();
