(function() {
    "use strict";

    angular
        .module('slate.reports')
        .controller('SlateReportsPageController', SlateReportsController);

    function SlateReportsController($stateParams, $httpParamSerializer) {
        /* jshint validthis: true */
        var vm = this;
        vm.getDownloadUrl = getDownloadUrl;

        var now = new Date();
        var oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

        if(typeof $stateParams.report == 'string') {
            vm.report_type = $stateParams.report;
        }

        vm.filters = {
            startDate: oneWeekAgo,
            endDate: now
        };


        function getDownloadUrl() {
            return API_URL + 'reports/' + vm.report_type + '/?' + $httpParamSerializer(vm.filters);
        }

        // Date picker stuff

        vm.startDateIsOpen = false;
        vm.startDateOpen = startDateOpen;

        vm.endDateIsOpen = false;
        vm.endDateOpen = endDateOpen;

        function startDateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.startDateIsOpen = true;
        }

        function endDateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.endDateIsOpen = true;
        }


    }
})();
