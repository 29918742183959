(function () {
    "use strict";

    angular
        .module('slate.estimates')
        .controller('SlateVendorEstimateEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateVendorEstimate, SlateOrder) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.estimate = new SlateVendorEstimate();

        vm.estimate.loadFromServer($stateParams.id).then(function () {
            vm.loading = false;
        }).catch(angular.noop);

        $scope.$watch('vm.estimate.name', function () {
            if (vm.estimate.name) {
                SlateTitle.set('Edit ' + vm.estimate.name + ' - Vendor Estimates');
            } else {
                SlateTitle.set('Edit Untitled Estimate - Vendor Estimates');
            }
        });

    }

})();
