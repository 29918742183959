(function () {
    'use strict';

    angular
        .module('slate.library.logistics')
        .service('SlateAccountPrdSrv', RolesService);

    function RolesService($http) {
        let loaded = false;
        const srv = {
            getList,
            isClosed,
            list: []
        };

        setInterval(() => {
            loaded = false;
            getList();
        }, 360000);

        return srv;

        function getList() {
            if (!loaded && !srv.promise) {
                srv.promise = $http.get(`${API_URL}logistics/accounting_periods/`);
                srv.promise.then((response) => {
                    let parts; let
                        earliest;
                    // populate assignable roles

                    if (response.data.earliest) {
                        parts = response.data.earliest.split('-');
                        earliest = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
                    }

                    srv.list = JSON.parse(response.data.payload);
                    srv.earliest = earliest;
                    loaded = true;
                });
            }
            return srv.promise;
        }

        function isClosed(passed_date) {
            let check_date;
            if (passed_date instanceof Date) {
                check_date = `${passed_date.getUTCFullYear()}-${(passed_date.getUTCMonth() + 1).toString().padStart(2, '0')}-${passed_date.getUTCDate().toString().padStart(2, '0')}`;
            } else if (typeof passed_date === 'string') {
                check_date = _.cloneDeep(passed_date);
            } else {
                return false;
            }
            if (srv.list.some((item) => check_date >= item.AcctSeed__Start_Date__c && check_date <= item.AcctSeed__End_Date__c)) { return false; }

            return true;
        }
    }
})();
