(function () {
    'use strict';

    angular
        .module('slate.library.roles')
        .factory('SlateFileNodeList', ModelList)
        .factory('SlateFileNode', ModelDetail);

    const apiUrl = `${API_URL}files/nodes/`;

    function ModelList($http, $q, SlateFileNode) {
        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.node = new SlateFileNode();
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let promise;
            const promises = [];

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, { params: self.filters });

            promise.then((response) => {
                const newlist = [];
                self.node = new SlateFileNode();
                self.node.loadFromPayload(response.data.payload.node);
                // set this for correct model
                angular.forEach(response.data.payload.children, (item) => {
                    const itemModel = new SlateFileNode();

                    itemModel.loadFromPayload(item);
                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;
                self.breadcrumbs = response.data.payload.breadcrumbs;

                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q, Upload) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', def: null, readonly: true },
            { name: 'slug', def: null, readonly: true },
            { name: 'path', def: '' },

            { name: 'parent', def: null, readonly: true },
            { name: 'parent_slug', def: null, readonly: true },
            { name: 'parent_type', def: null },
            { name: 'parent_fk', def: null },
            { name: 'company_fk', def: null, readonly: true },

            { name: 'core', def: null, readonly: true },
            { name: 'core_id' },
            { name: 'version', def: null, readonly: true },
            { name: 'total_child_nodes', def: null, readonly: true },

            { name: 'mimetype', def: 'application/octet-stream' },
            { name: 'filename', def: 'Untitled' },
            { name: 'filesize', def: 0 },
            { name: 'description', def: '' },
            { name: 'notes', def: '' },
            { name: 'locked', def: false },

            { name: 'created', def: null, readonly: true },
            { name: 'updated', def: null, readonly: true },

            { name: 'client_viewable', def: false },
            { name: 'client_uploadable', def: false },
            { name: 'publishable', def: false },
            { name: 'client_inherit', def: false },
            { name: 'apply_to_children', def: false },

            { name: 'template', def: false },
            { name: 'breadcrumbs', def: false, readonly: true },

            { name: 'selected', def: false, readonly: true },
            { name: 'progress', def: 0, readonly: true },
            { name: 'lockOld', def: true },
            { name: 'unique', def: false },

            { name: 'qc', def: false },
            { name: 'public', def: false },
            { name: 'has_preview', readonly: true },

            { name: 'deleted', def: false, readonly: true }
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        model.prototype.togglePublic = togglePublic;
        model.prototype.sendLink = sendLink;


        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                self[field.name] = payload[field.name];
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            let promise;

            if (id) { modelUrl = `${apiUrl + id}/`; }

            if (!self.id && !id) {
                console.log('Tried to load from server without id');
                return self;
            }

            promise = $http.get(modelUrl);

            promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveModel(file) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) { modelUrl = `${apiUrl + self.id}/`; }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                }
            });

            if (file) {
                data.file = file;
                if (file.type) {
                    data.mimetype = file.type;
                } else {
                    data.mimetype = 'application/octet-stream';
                }


                promise = Upload.upload({
                    url: modelUrl,
                    data
                }).then((response) => {
                    self.progress = 100;
                    self.loadFromPayload(response.data.payload);
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                }, (resp) => {
                    deferred.reject(self);
                }, (evt) => {
                    self.progress = parseInt(100.0 * evt.loaded / evt.total);
                    file.progress = self.progress;
                });
            } else {
                promise = $http.post(modelUrl, data).then((response) => {
                    const promises = [];
                    self.loadFromPayload(response.data.payload);
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                }, (response) => {
                    deferred.reject(response);
                });
            }

            return deferred.promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) { modelUrl = `${apiUrl + self.id}/`; }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                }
            });

            promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function togglePublic() {
            /* jshint validthis: true */
            const self = this;

            self.public = !self.public;
            return self.saveModel();
        }

        function sendLink() {
            /* jshint validthis: true */
            const self = this;

            let promise;

            const modelUrl = `${API_URL}files/send-link/${self.id}/`;

            if (!self.id || !self.sendLinkTo) { return false; }

            promise = $http.post(modelUrl, { send_to: self.sendLinkTo });
            promise.then(() => {
                self.sendLinkTo = '';
            });
            return promise;
        }
    }
})();
