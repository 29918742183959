(function() {
    "use strict";

    angular
        .module('slate.timetracking')
        .service('SlateAddTime', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateTimeTrack) {
        var templateUrl = '/partials/timetracking/timetracking.addtime.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                //size: 'lg',
                resolve: {
                    obj: function() {
                        var newObj = new SlateTimeTrack();
                        newObj.loadFromPayload(obj);
                        return newObj;
                    }
                }
            });

            return modalInstance.result.then(function(results) {
                return results;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($uibModalInstance, obj) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.startDateIsOpen = false;
        vm.startDateOpen = startDateOpen;

        vm.addTime = addTime;

        function saveObject() {
            if (vm.saving === true) {
                return false;
            }
            vm.saving = true;

            vm.obj.saveModel().then(function() {
                vm.saving = false;
                $uibModalInstance.close(vm.obj);

            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function startDateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.startDateIsOpen = true;
        }

        function addTime(amount) {
            vm.obj.hours_spent = parseFloat(vm.obj.hours_spent) + amount;
        }

    }


})();
