(function () {
    "use strict";

    angular
        .module('slate.logistics')
        .directive('slateShipmentSelector', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/logistics/list/shipment.selector.dir.html',
            scope: {
                selected: '=',
                multiSelect: '=?',
                modelFilters: '=?filters',
                fields: '=?',
                defaultSearch: '=?',
                hideFilters: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $window, SlateAuth, SlateShipments, getLinkSrv, SlateDeleteObjSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.shipments = new SlateShipments();
        vm.filters = {};
        if(!Array.isArray(vm.selected)) {
            vm.selected = [];
        }
        if(vm.defaultSearch) { vm.filters.q = vm.defaultSearch; }

        vm.isSelected = function isSelected(shipment) {
            return vm.selected.some(function(item) { return shipment.id == item.id; });
        };

        vm.selectShipment = function selectShipment(shipment) {
            if(!vm.multiSelect) {
                vm.selected = [shipment];
                return vm.selected;
            }
            if(vm.selected.some(function(item) { return shipment.id == item.id; })) { return false; }
            vm.selected.push(shipment);
        };

        vm.deselectShipment = function deselectShipment(shipment) {
            vm.selected = vm.selected.filter(function(item) {
                if(item.id == shipment.id) {  return false; }
                return true;
            });
        };

        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            updateFromFilter();
        });


        function updateFromFilter() {
            var modelPromise;
            var send_data = {};
            if(!vm.filters.q || vm.filters.q.length < 3) { return false; }
            vm.loading = true;

            angular.extend(
                send_data,
                vm.modelFilters,
                {
                    'fields': ['id', 'shipment_number', 'name'],
                    'status': ['new', 'quoting', 'quoted', 'quote_appr', 'schedule_req', 'schedule_up', 'pending'],
                    'page': 1,
                    'count': 25
                },
                vm.filters
            );

            return vm.shipments.getList(send_data).then(function () {
                vm.loading = false;
            });
        }


    }


})();
