(function() {
    "use strict";

    angular
        .module('slate.salesbudgets')
        .service('SlateNewBusinessEditDialog', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateAuth) {
        var templateUrl = '/partials/salesbudgets/newbusiness.edit.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(sales_mgr_id, start_date) {
            var modalInstance;
            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    start_date: function() { return start_date; },
                    sales_mgr_id: function() {
                        return sales_mgr_id;
                    },
                }
            });


            return modalInstance.result.then(function(results) {
                return results;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($uibModalInstance, $scope, $q, sales_mgr_id, start_date, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;
        
        vm.auth = SlateAuth;
        vm.sales_mgr_id = sales_mgr_id;
        vm.start_date = start_date;
        
        vm.cancel = cancel;
        vm.save = saveObject;
        
        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function saveObject() {
            var promises = [];

            vm.saving = true;

            vm.newbusiness.list.forEach(function(budget) {
                promises.push(budget.saveModel());
            });

            $q.all(promises).then(function() {
                $uibModalInstance.close();
                vm.saving = false;
            }, function() {
                vm.saving = false;
            });
            
        }

    }



})();
