(function () {
    'use strict';

    angular
        .module('slate.projects')
        .directive('slateProjectsVariationList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/projects/projects.variation.list.dir.html',
            scope: {
                modelFilters: '=filters',
                variations: '=?',
                rootOnly: '=?',
                subview: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController($scope, $state, $location, $q, $window, SlateAuth, SlateSettings, SlateTitle, SlateProjects, SlateProject, SlateProjectEdit, getLinkSrv, SlateDeleteObjSrv) {
        /* jshint validthis: true */
        const vm = this;
        vm.auth = SlateAuth;
        vm.get_url = get_url;
        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;

        vm.loading = true;
        vm.filters = {
            page: 1,
            count: 50
        };

        vm.fields = [
            'id',
            'name',
            'slug',
            'job_number',
            'component_number',
            'variation_number',
            'composite_num',
            'client_item_number',
            'stock_component',
            'finished_good',
            'total_inventory',
            'category',
            'material',


            'created',
            'updated'

        ];

        SlateSettings.getConfig('material_categories').then((config) => {
            vm.categories = config;
        });
        SlateSettings.getConfig('materials').then((config) => {
            vm.material_list = config;
            getMatList();
        });
        vm.materials = [];

        vm.projectTypes = ['Production',
            'Design',
            'Speculative',
            'Pricing',
            'Component',
            'Primary',
            'Secondary',
            'Gift Set',
            'Smart Stock',
            'Turn Key',
            'Protective Packaging'];

        angular.extend(vm.filters, {
            // vendor: $location.search().vendor || false,
            q: $location.search().q
        }, vm.modelFilters);
        if (vm.filters.page) { vm.filters.page = parseInt(vm.filters.page, 10); }
        if (vm.filters.count) { vm.filters.count = parseInt(vm.filters.count, 10); }


        if (vm.filters.q === null) { vm.filters.q = ''; }

        if (!vm.modelFilters || (vm.modelFilters.length === 1 && typeof vm.modelFilters.root_only !== 'undefined')) {
            SlateTitle.set('Projects');
        }

        vm.projectsList = new SlateProjects();

        vm.addNew = addNewProject;
        vm.editComponent = editComponent;
        vm.deleteProject = deleteProject;
        vm.updateFromFilter = updateFromFilter;

        $scope.$watchCollection(() => vm.filters, (value) => {
            updateFromFilter();
        });

        function hasFilterData() {
            let hasData = false;
            angular.forEach(vm.filters, (value, key) => {
                if (key === 'page' || key === 'count' || key === 'q') {
                    return false;
                }
                if (value === undefined || value === null || value === '') {
                    return false;
                }
                if (vm.filters[key] === vm.modelFilters[key]) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;
            });
            return hasData;
        }

        function updateFromFilter() {
            angular.forEach(vm.filters, (value, item) => {
                if (vm.filters[item] && !vm.modelFilters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            const modelPromise = vm.projectsList.getList(angular.extend({}, vm.filters, {
                // root_only: vm.rootOnly,
                fields: vm.fields
            }));
            modelPromise.then(() => {
                vm.loading = false;
            });
        }

        function addNewProject($event) {
            const newProject = new SlateProject();

            if (vm.modelFilters.company) {
                newProject.parent_type = 'company';
                newProject.parent_fk = vm.modelFilters.company;
            }
            if (vm.modelFilters.project) {
                newProject.parent_type = 'project';
                newProject.parent_fk = vm.modelFilters.project;
            }

            const promise = SlateProjectEdit.edit(newProject, newProject.parent_type, newProject.parent_fk);
            promise.then((obj) => {
                if (!obj.skip) {
                    $location.path(getLinkSrv.getUrl('project', newProject.id, newProject.slug)).search({});
                } else {
                    updateFromFilter();
                }
            }, () => {

            });
            return promise;
        }

        function editComponent(project) {
            const promise = SlateProjectEdit.edit(project);
            promise.then((newProject) => {
                if (newProject.slug !== project.slug) {
                    $location.path(getLinkSrv.getUrl('project', newProject.id, newProject.slug)).search({});
                } else {
                    $state.reload();
                }
            }, () => {

            });
            return promise;
        }

        function deleteProject(project) {
            SlateDeleteObjSrv.delete('project', project.id).then(() => {
                updateFromFilter();
            });
        }

        function get_url() {
            const url = `${API_URL}projects/`;
            if ($window.location.search.length) {
                return `${url + $window.location.search}&download=true`;
            }
            return `${url}?download=true`;
        }

        vm.makeOneToOne = makeOneToOne;
        function makeOneToOne(project) {
            project.makeOneToOne().then((details) => {
                $state.go('projectDetails.BOMList', { slug: details.slug });
            });
        }

        $scope.$watch('vm.filters.category', () => {
            getMatList();
        });

        $scope.$watch('vm.filters.material', () => {
            // console.log(vm.filters.material);
        });

        function getMatList() {
            let newlist = [];
            if (!vm.material_list || !vm.material_list.value) { return []; }
            angular.forEach(vm.material_list.value, (item) => {
                if (item.category === vm.filters.category) {
                    newlist.push(item);
                }
            });
            newlist = newlist.map((a) => a.name);
            vm.materials = newlist;
            return newlist;
        }
    }
})();
