(function () {
    "use strict";

    angular
        .module('slate.app')
        .directive('slateLeadsForm', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/slate/slate.leadsform.dir.html',
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, SlateAuth, SlateContactMethods, SlateEmployees, $timeout, $state, $location, $q, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;

        $state.transitionTo(
            'dashboard',
            {location: 'replace'}
        );

        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            zIndex: 10,
        };

        vm.def_form = {
            oid: "00De0000005F7yA",
            retURL: $location.absUrl(),
            //'00N1H00000GxQ1b': SlateAuth.user.contact_name,

        };
        vm.form = angular.extend({}, vm.def_form);
        vm.save = save;
        vm.canSave = canSave;
        vm.fake = fake;
        vm.saving = false;

        function fake() {
            var url = "/fakedata/?lang=EN&fromAge=21&toAge=65";
            var promise;

            var industries = ['Consumer Electronics', 'Food and Beverage', 'Health and Beauty', 'Lifestyle', 'Other'];
            var cosizes = [
                "Startup",
                "$500,000 - $10 million",
                "$10 million - $100 million",
                "$100 million- $250 million",
                "$250 million"
            ];
            var sources = [
                "Call",
                "Customer Referral",
                "Existing Account",
                "Other",
                "Outside Referral",
                "Target",
                "Trade Show",
                "Website"
            ];
            var adjectives = [
                'funky',
                'stinky',
                'awesome',
                'lovely',
                'cosmetic',
                'premium',
                'cost effective'
            ];
            var nouns = [
                'boxes',
                'packaging',
                'cartons',
                'containers',
                'totes',
            ];

            promise = $http.get(url).then(function (results) {
                var person = results.data._embedded.people[0];
                vm.form.FirstName = person.firstName;
                vm.form.LastName = person.lastName;

                vm.form.Title = 'Sales Dude';
                vm.form.Company = person.company.name;
                vm.form.Email = person.company.email;
                vm.form.Phone = person.telephoneNumber;
                vm.form.Website = 'http://' + person.company.domain;

                vm.form.Industry = industries[Math.floor(Math.random() * industries.length)];
                vm.form.Company_Size__c = cosizes[Math.floor(Math.random() * cosizes.length)];
                vm.form.LeadSource = sources[Math.floor(Math.random() * sources.length)];
                vm.form.Submitter__c = SlateAuth.user.contact_name;
                vm.form.Description_Short__c = "Let's make some " + adjectives[Math.floor(Math.random() * adjectives.length)] + " " + nouns[Math.floor(Math.random() * nouns.length)] + "!";
                $http.get('/baconipsum/?type=all-meat&paras=3&start-with-lorem=1').then(function (results) {
                    vm.form.Message__c = results.data.join("\n\n");
                });
            });


        }

        function save() {
            var promise;
            var url = API_URL + 'leads';
            //var url = '/web-to-lead-test/?encoding=UTF-8';

            vm.saving = true;

            promise = $http.post(url, vm.form).then(function () {
                vm.form = angular.extend({}, vm.def_form);
                vm.saving = false;
                vm.error = false;
            }, function() {
                vm.saving = false;
                vm.error = true;
            });

        }

        function canSave() {
            if(vm.form.LeadSource=='Existing Account' || vm.form.LeadSource=='Ongoing Project') {
                if(!vm.form.companyID) { return false; }
                if(!vm.form.salesID) { return false; }
                if(!vm.form.close_date) { return false; }
                if(!vm.form.projectName) { return false; }
                if(!vm.form.Brief) { return false; }
            }

            if(vm.form.LeadSource=='Ongoing Project') {
                if(!vm.form.amount) { return false; }
                if(!vm.form.margin) { return false; }
                if(!vm.form.projectmgrID) { return false; }
            }

            if(vm.form.LeadSource!='Existing Account' && vm.form.LeadSource!='Ongoing Project') {
                if(!vm.form.LastName) { return false; }
                if(!vm.form.Company) { return false; }
                if(!vm.form.Description_Short__c) { return false; }
                if(!vm.form.LeadSource) { return false; }
            }

            return true;
        }


    }

})();
