(function () {
    "use strict";

    angular
        .module('slate.salesforce')
        .directive('slateSalesforcePopout', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/salesforce/salesforce.popout.dir.html',
            scope: {

            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        getLinkSrv,
        SlateAuth,
        SlateSFLinkage,
        SlateSfLinksDialog
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;
        vm.sf_linkage = SlateSFLinkage;

        vm.showButton = showButton;
        vm.openDialog = openDialog;

        vm.sfdialog = SlateSfLinksDialog;

        function openDialog() {
            vm.sfdialog.popout();
        }

        $scope.$watch('vm.salesOrder.id', function (old_val, new_val) {

        });


        function showButton() {
            return vm.sf_linkage.parent_type && vm.sf_linkage.parent_fk;
        }


    }

})();
