(function () {
    'use strict';

    angular
        .module('slate.reports')
        .directive('slateReportHoursbymonth', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/reports/reports.report.hours_by_month.dir.html',
            scope: {
                filters: '<'
            },
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ReportController($scope, $state, $location, $http, $q, $stateParams, $httpParamSerializer, getLinkSrv) {
        /* jshint validthis: true */
        const vm = this;
        vm.linksrv = getLinkSrv;

        const now = new Date();
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

        let modelPromise;

        vm.data = {};
        vm.getDownloadUrl = getDownloadUrl;

        const apiUrl = `${API_URL}reports/hours_by_month/`;

        vm.loading = true;
        vm.pagerOptions = {};
        if (!vm.filters) {
            vm.filters = {
                startDate: oneWeekAgo,
                endDate: now
            };
        }

        vm.filters = angular.extend(vm.filters, {
            // vendor: $location.search().vendor || false,
            q: $location.search().q
        });


        // Date picker stuff

        vm.startDateIsOpen = false;
        vm.startDateOpen = startDateOpen;

        vm.endDateIsOpen = false;
        vm.endDateOpen = endDateOpen;

        function startDateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.startDateIsOpen = true;
        }

        function endDateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.endDateIsOpen = true;
        }

        updateFromFilter();

        $scope.$watchCollection(() => vm.filters, (value) => {
            updateFromFilter();
        });

        function updateFromFilter() {
            vm.loading = true;

            if (vm.filters.q) {
                $location.replace().search('q', vm.filters.q);
            } else {
                $location.replace().search('q', null);
            }

            modelPromise = $http.get(apiUrl, {
                params: vm.filters
            });

            modelPromise.then((resp) => {
                vm.data = resp.data.payload;
                vm.loading = false;
            });
        }

        function getDownloadUrl() {
            return `${apiUrl}?download=true&${$httpParamSerializer(vm.filters)}`;
        }
    }
})();
