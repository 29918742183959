(function () {
    'use strict';

    angular
        .module('slate.companies')
        .controller('SlateCompanyDetailsPageController', SlateCompanyDetailsPageController);


    function SlateCompanyDetailsPageController($scope, $state, $location, SlateTitle, SlateSFLinkage, getLinkSrv, SlateCompanyEdit, company, SlateAuth, SlateCompanyBrands) {
        /* jshint validthis: true */
        const vm = this;
        vm.company = company;
        vm.editCompany = editCompany;
        vm.signed_nda = signed_nda;
        vm.isVendor = isVendor;
        vm.auth = SlateAuth;

        vm.brands = new SlateCompanyBrands();
        vm.brands.getList({ company: vm.company.id });

        vm.editing = false;

        SlateTitle.set(vm.company.name);

        vm.sf_linkage = SlateSFLinkage;

        $scope.$watch('vm.company.id', (old_val, new_val) => {
            if (vm.company.id && !vm.saving) {
                vm.sf_linkage.parent_type = 'company';
                vm.sf_linkage.parent_fk = vm.company.id;
            }
        });

        function editCompany() {
            if (vm.editing) { return false; }
            vm.editing = true;
            const promise = SlateCompanyEdit.edit(company);
            promise.then((newCompany) => {
                vm.editing = false;
                if (newCompany.slug !== company.slug) {
                    $location.path(getLinkSrv.getUrl('company', newCompany.id, newCompany.slug)).search({});
                } else {
                    $state.reload();
                }
            }, () => {
                vm.editing = false;
            });
            return promise;
        }

        function signed_nda() {
            vm.company.signed_nda = true;
            vm.company.saveModel().then(() => {
                $state.reload();
            });
        }

        function isVendor() {
            if (vm.company.vendor) { return 'vendor'; }
            return '';
        }
    }
})();
