(function () {
    'use strict';

    angular
        .module('slate.library.bom')
        .factory('SlateBOMs', ModelList)
        .factory('SlateBOM', ModelDetail);

    const apiUrl = `${API_URL}boms/bom/`;

    function ModelList($http, $q, SlateBOM) {
        // Set to url relative to api root.

        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.download = download;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            const promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateBOM();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.totalItems = response.data.totalItems;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function download(filters) {

        }
    }

    function ModelDetail($http, $q, SlateGoods) {
        // instantiate our initial object
        const model = function construct(init_data) {
            const self = this;

            self.clear();
            angular.extend(self, init_data);

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', def: null, readonly: true },

            { name: 'item_description' },
            { name: 'version', readonly: true },
            { name: 'finalized' },

            { name: 'finished_good' },
            { name: 'finished_good_info', readonly: true },

            { name: 'client' },
            { name: 'client_info', readonly: true },

            { name: 'internal_item_number' },
            { name: 'client_item_number' },
            { name: 'weight_claim' },
            { name: 'qty_required', readonly: true },
            { name: 'scrap_factor', readonly: true },
            { name: 'upc' },
            { name: 'assembly_instructions' },
            { name: 'buy_price', type: 'float', readonly: true },

            { name: 'updated', readonly: true, type: 'date' },
            { name: 'created', readonly: true, type: 'date' },
            { name: 'created_by', readonly: true },
            { name: 'created_by_info', readonly: true }

        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.duplicate = duplicate;
        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            // const x = new Date();
            // const currentTimeZoneOffsetInHours = x.getTimezoneOffset() / 60;

            if (typeof payload !== 'object') {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                self[field.name] = payload[field.name];

                if (field.type === 'date' && payload[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type === 'dateOnly' && payload[field.name]) {
                    const parts = self[field.name].split('-');
                    self[field.name] = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
                }

                if (field.type === 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(payload[field.name]);
                    } catch (e) {
                        console.log('could not parse json');
                        console.log(field.name, payload[field.name]);
                        console.log(e);
                    }
                }

                if (field.type === 'float' && payload[field.name]) {
                    self[field.name] = parseFloat(self[field.name]);
                    if (Number.isNaN(self[field.name])) {
                        self[field.name] = null;
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id, show_goods) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;
            const filters = {};

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            if (show_goods) { filters.show_goods = true; }

            const promise = $http.get(modelUrl, {
                params: filters
            });

            promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (field.type === 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if (field.type === 'date' && self[field.name]) {
                        data[field.name] = self[field.name].toISOString();
                    }
                    if (field.type === 'dateOnly' && self[field.name]) {
                        data[field.name] = `${self[field.name].getFullYear()}-${self[field.name].getMonth() + 1}-${self[field.name].getDate()}`;
                    }
                    if (field.trim && data[field.name]) {
                        data[field.name] = data[field.name].substr(0, field.trim);
                    }
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then((response) => {
                const promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            const promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function duplicate() {
            /* jshint validthis: true */
            const self = this;
            let modelUrl = apiUrl;

            let promise;
            if (self.id) {
                modelUrl = `${apiUrl + self.id}/duplicate/`;
            }

            promise = $http.post(modelUrl);
            promise = promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return self;
            });

            return promise;
        }
    }
})();
