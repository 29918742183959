(function() {
    "use strict";

    angular
        .module('slate.bom')
        .directive('slateCostedBomCalculator', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/bom/bom.costedbomcalculator.dir.html',
            scope: {
                modelFilters: '=filters',
                projectNomenclature: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $q, $http, $window, SlateAuth, SlateBomItemPriceTierEditSrv, SlateBOMs, SlateBOMItems) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

        vm.getTotalCost = getTotalCost;
        vm.getMargin = getMargin;
        vm.selectPriceTier = selectPriceTier;
        vm.hasPriceTiers = hasPriceTiers;
        
        vm.itemPriceTiers = {};
        vm.boms = new SlateBOMs();
        vm.bomItems = new SlateBOMItems();
        
        vm.loading = true;
        vm.filters = {
            page: 1,
            count: 25,
        };

        angular.extend(vm.filters, vm.modelFilters, $location.search());
        if(vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if(vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });
        $scope.$watch('vm.bom', function() {
            if (vm.bom) {
                vm.loadingItems = true;
                vm.bomItems.getList({bom: vm.bom}).then(function() {
                    vm.loadingItems = false;
                });
            }
        });

        function updateFromFilter() {
            var modelPromise;

            vm.loading = true;

            angular.forEach(vm.filters, function(value, item) {
                if (vm.filters[item] && !vm.modelFilters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = vm.boms.getList(vm.filters);
            modelPromise.then(function() {
                vm.loading = false;
            }, function () {
                vm.loading = false;
            });
        }

        function selectPriceTier(item) {
            SlateBomItemPriceTierEditSrv.edit(item, vm.itemPriceTiers).then(function(obj) {
                console.log(obj.price_tier, obj.price_tier.getPrice(50));
                vm.itemPriceTiers = obj.bom_item_price_tiers;
                item.priceTier = obj.price_tier;
            });
        }

        function getTotalCost() {
            var total = new Big(0);
            vm.bomItems.list.forEach(function(item) {
                var itemTotal = new Big(item.getItemTotal(1));
                var unitprice = new Big(item.priceTier.getPrice(vm.qty));
                total = total.add(itemTotal.times(unitprice));
            });
            return total.valueOf();
        }

        function getMargin() {
            if (!vm.sellprice) {
                return 0.0;
            }

            var total = new Big(0);
            var cost = getTotalCost();
            var sell = new Big(vm.sellprice);
            total = total.add(sell).minus(cost);
            total = total.div(sell);
            total = total.times(100.0);
            return total.valueOf();
        }

        function hasPriceTiers() {
            return vm.bomItems.list.every(function(item) { return item.hasOwnProperty('priceTier'); });
        }

    }


})();
