(function () {
    "use strict";

    angular
        .module('slate.logistics')
        .controller('SlateFollowupShipmentNewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateShipment) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.origin_shipment = new SlateShipment();
        vm.shipment = new SlateShipment();

        vm.origin_shipment.loadFromServer($stateParams.id).then(function () {
            vm.shipment.origin_type = 'stock';
            vm.shipment.name = _.truncate(vm.origin_shipment.name, {'length': 60}) + ' - Followup';


            SlateTitle.set('Ship Followup - Shipments');
            vm.loading = false;

        });
    }

})();
