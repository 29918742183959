(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .controller('SlatePOReceiveNewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlatePurchaseOrder, SlatePOReceive) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.purchaseorder = new SlatePurchaseOrder();
        vm.receive = new SlatePOReceive();
        

        vm.purchaseorder.loadFromServer($stateParams.id).then(function () {
            vm.receive.purchase_order = vm.purchaseorder.id;
            vm.receive.warehouse = vm.purchaseorder.warehouse;

            if (vm.purchaseorder.name) {
                SlateTitle.set('Receive ' + vm.purchaseorder.name + ' - Purchase Orders');
            } else {
                SlateTitle.set('Receie - Purchase Orders');
            }
            vm.loading = false;

        }).catch(angular.noop);


        if($location.search().order) {
            vm.order = new SlateOrder();
            vm.order.loadFromServer($location.search().order).then(function() {
                vm.purchaseorder.order = vm.order.id;
                vm.purchaseorder.project = vm.order.project;
                vm.purchaseorder.sales_mgr = vm.order.sales_mgr;
                vm.purchaseorder.proj_mgr = vm.order.proj_mgr;
                vm.purchaseorder.company = vm.order.client;
                vm.purchaseorder.client_po = vm.order.client_po;
                vm.purchaseorder.name = vm.order.name.substring(0,68);
                vm.loading = false;
            });
        } else {
            vm.loading = false;
        }
        
    }

})();


