(function() {
    "use strict";

    angular
        .module('slate.warehouses')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
        .state('warehouseListPage', {
            url: '/warehouses/warehouse/',
            template: "<div class='content-pane list-page'><slate-warehouses-list></slate-warehouses-list></div>",
            current_app: 'warehouses',
            slateTitle: 'Warehouses',
        })
        
        .state('warehouseNewPage', {
            url: '/warehouses/warehouse/new/',
            templateUrl: '/partials/warehouses/warehouse.edit.page.html',
            controller: 'SlateWarehouseNewPageController',
            controllerAs: 'vm',
            current_app: 'warehouses',
            slateTitle: 'Create New Warehouse',
        })
        .state('warehouseEditPage', {
            url: '/warehouses/warehouse/:id/edit/',
            templateUrl: '/partials/warehouses/warehouse.edit.page.html',
            controller: 'SlateWarehouseEditPageController',
            controllerAs: 'vm',
            current_app: 'warehouses',
            slateTitle: 'Edit Warehouse',
        })
        ;
    }
})();
