(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .directive('slateSalesOrderBulkRelease', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/salesorders/releases/release.bulk.dir.html',
            scope: {
                salesOrder: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $q,
        $timeout,
        $state,
        $translate,
        $http,
        SlateLocation,
        SlateLocations,
        SlateGood,
        getLinkSrv,
        SlateSORelease
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;

        vm.saveObject = saveObject;
        vm.isComplete = isComplete;

        function saveObject() {
            var deferred = $q.defer();

            if (vm.saving === true) {
                return false;
            }

            vm.saving = true;
            vm.error = false;

            vm.salesOrder.line_items.forEach(function(item) {
                var release = new SlateSORelease();
                if(!item.qty_to_release) { return false; }
                release.qty = parseFloat(item.qty_to_release);
                release.date = vm.release_date;
                item.releases.push(release);
            });

            vm.salesOrder.saveModel().then(function() {
                $timeout(function () {
                    vm.saving = false;
                    deferred.resolve();
                    $state.transitionTo(
                        'salesOrderEditPage',
                        { id: vm.salesOrder.id },
                        { location: 'replace' }
                    );
                }, 300);
            }, function () {
                vm.saving = false;
                vm.error = true;
            });

            return deferred.promise;
        }

        function isComplete() {
            if(!vm.release_date) { return false; }
            if(!vm.salesOrder.line_items.some(function(item) { return item.qty_to_release; })) { return false; }
            if(!vm.salesOrder.line_items.every(function(item) {
                var qty = 0.0;
                if(!item.qty_to_release) { return true; }
                try {
                    qty = parseFloat(item.qty_to_release);
                } catch(e) {
                    return false;
                }
                if(qty > item.getUnreleased()) { return false; }
                return true;
            })) { return false; }
            return true;
        }



    }

})();
