(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .directive('slatePurchaseOrderEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/purchaseorders/purchaseorder.edit.dir.html',
            scope: {
                purchaseOrder: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $http,
        $state,
        $location,
        getLinkSrv,
        SlateAuth,
        SlateCompany,
        SlateLocations,
        SlatePurchaseOrder,
        SlatePOLineItems,
        SlatePOLineItem,
        SlateShipments, SlateShipment, SlateGoods, SlateGood,
        SlateOrderPlanStep,
        SlateOrderPlanSteps,
        SlateOrderPlanLot,
        SlateOrderPlanLots,
        SlateSFLinkage,
        SlateDateChangeSrv,
        SlateDateLogList
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.saveObject = saveObject;
        vm.isComplete = isComplete;
        vm.hasAddress = hasAddress;
        vm.setAddress = setAddress;
        vm.disableSave = disableSave;
        vm.canViewPDF = canViewPDF;
        vm.vendorHasWarehouse = vendorHasWarehouse;

        vm.froalaOptions = {
            toolbarButtons: ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            zIndex: 10,
        };

        vm.baseVendor = vm.purchaseOrder.vendor;
        vm.baseStatus = vm.purchaseOrder.status;

        vm.POStatusOptions = ['Draft', 'PO', 'PO Revision', 'Sent', 'Partially Received', 'Received', 'Closed', 'Cancelled', 'Exception Hold'];
        vm.POStatusAvailable = [vm.purchaseOrder.status];
        checkAvailStatus();

        vm.ship_types = ['oceanlcl', 'oceanfcl', 'oceanexp', 'air', 'truck', 'ups', 'fedex', 'dhl', 'other'];
        vm.incoterms = ['exw', 'fca', 'fob', 'cif', 'cpt', 'cip', 'dat', 'dap', 'ddp', 'ddu', 'other'];

        function checkAvailStatus() {
            var index;

            vm.POStatusAvailable = [vm.baseStatus];
            if(vm.baseStatus == 'Draft') { vm.POStatusAvailable.push('PO', 'Exception Hold', 'Cancelled'); }
            if(vm.baseStatus == 'PO') { vm.POStatusAvailable.push('Sent','Exception Hold', 'Cancelled'); }
            if(vm.baseStatus == 'PO Revision') { vm.POStatusAvailable.push( 'Sent', 'Exception Hold', 'Cancelled'); }
            if(vm.baseStatus == 'Sent') { vm.POStatusAvailable.push( 'PO Revision', 'Closed', 'Exception Hold', 'Cancelled','Received', 'Partially Received'); }
            if(vm.baseStatus == 'Partially Received') { vm.POStatusAvailable.push( 'PO Revision', 'Closed', 'Exception Hold','Received'); }
            if(vm.baseStatus == 'Received') { vm.POStatusAvailable.push('Closed', 'Exception Hold'); }
            if(vm.baseStatus == 'Closed') { vm.POStatusAvailable.push('Exception Hold', 'Cancelled'); }
            if(vm.baseStatus == 'Exception Hold') { vm.POStatusAvailable.push('PO', 'PO Revision', 'Sent', 'Closed', 'Cancelled', 'Received', 'Partially Received'); }
            if(vm.purchaseOrder.line_items.some(function(item) { return item.qty_received > 0; })) {
                index = vm.POStatusAvailable.indexOf('Cancelled');
                if (index > -1) {
                    vm.POStatusAvailable.splice(index, 1);
                }
            }
        }


        vm.countries = Countries;

        vm.lineItemsDirty = false;

        vm.company = new SlateCompany();
        vm.vendor = new SlateCompany();
        vm.ship_company = new SlateCompany();
        vm.co_addresses = new SlateLocations();
        vm.vendor_addresses = new SlateLocations();

        vm.shipments = new SlateShipments();

        vm.sf_linkage = SlateSFLinkage;
        vm.dateLog = SlateDateLogList;
        vm.dateChangeSrv = SlateDateChangeSrv;
        
        vm.dateChangeSrv.watch(vm.purchaseOrder, ['desired_date']);

        $scope.$watch('vm.purchaseOrder.id', function (old_val, new_val) {
            if (vm.purchaseOrder.id && !vm.saving) {
                vm.baseVendor = vm.purchaseOrder.vendor;
                vm.baseStatus = vm.purchaseOrder.status;

                vm.sf_linkage.parent_type = 'purchaseorder';
                vm.sf_linkage.parent_fk = vm.purchaseOrder.id;
                vm.dateLog.parent_type = 'purchaseorder';
                vm.dateLog.parent_fk = vm.purchaseOrder.id;

                updateDependants();
            }
        });

        $scope.$watch('vm.purchaseOrder.company', function (old_val, new_val) {
            if (vm.purchaseOrder.company) {
                vm.company.loadFromServer(vm.purchaseOrder.company);
                vm.co_addresses.getList({company: vm.purchaseOrder.company});
            }
        });
        $scope.$watch('vm.purchaseOrder.vendor', function (old_val, new_val) {
            if (vm.purchaseOrder.vendor) {
                vm.vendor.loadFromServer(vm.purchaseOrder.vendor);
                vm.vendor_addresses.getList({company: vm.purchaseOrder.vendor});
            }
        });
        $scope.$watch('vm.purchaseOrder.ship_company', function (old_val, new_val) {
            if (vm.purchaseOrder.ship_company && old_val != new_val) {
                vm.ship_company.loadFromServer(vm.purchaseOrder.ship_company).then(function() {
                    vm.purchaseOrder.ship_co_name = vm.ship_company.name;
                    vm.ship_company.contactmethods.getList({company: vm.purchaseOrder.ship_company});
                });
            }
        });
        $scope.$watchCollection('vm.ship_company.contactmethods.list', function() {
            if(vm.ship_company.contactmethods.list.filter(function(item) { return item.method == 'phone'; }).length == 1) {
                vm.purchaseOrder.ship_phone = vm.ship_company.contactmethods.list[0].value;
            }
        });

        function vendorHasWarehouse() {
            var warehouse = vm.vendor_addresses.list.find(function(addr) { return addr.is_warehouse; });
            return warehouse != undefined;
        }
        
        function setAddress(address) {
            var selected_addr;

            function select_addr(item) {
                if(item.id == vm.selected_client_address) { selected_addr = item; }
            }
            vm.co_addresses.list.forEach(select_addr);
            vm.vendor_addresses.list.forEach(select_addr);
            vm.ship_company.locations.list.forEach(select_addr);
            if(!selected_addr) { return false;}

            vm.purchaseOrder.ship_street = selected_addr.street1;
            if(selected_addr.street2) {
                vm.purchaseOrder.ship_street = vm.purchaseOrder.ship_street + ", " + selected_addr.street2;
            }
            if(selected_addr.street3) {
                vm.purchaseOrder.ship_street = vm.purchaseOrder.ship_street + ", " + selected_addr.street3;
            }
            vm.purchaseOrder.ship_city = selected_addr.city;
            vm.purchaseOrder.ship_state = selected_addr.state;
            vm.purchaseOrder.ship_postal = selected_addr.postal;
            vm.purchaseOrder.ship_country = selected_addr.country;

            vm.selected_client_address = null;
        }

        function updateDependants() {
            $timeout(check_po_update, 5000);

            if(vm.purchaseOrder.id) {
                vm.shipmentsLoading = true;
                vm.shipments.getList({purchaseorder: vm.purchaseOrder.id}).then(function() {
                    vm.shipmentsLoading = false;
                });

                if (canViewPDF()) {
                    getPDFURL().then(function(url) {
                        vm.pdfUrl = url;
                    }, function() {

                    });
                }
            }
        }

        $scope.$watch('vm.lineItemsDirty', function (old_val, new_val) {
            if(!old_val && new_val) {
                vm.purchaseOrder.revision = true;
            }
        });

        function hasAddress() {
            if(
                !vm.purchaseOrder.ship_phone ||
                !vm.purchaseOrder.ship_street ||
                !vm.purchaseOrder.ship_city ||
                !vm.purchaseOrder.ship_state ||
                !vm.purchaseOrder.ship_postal
            ) { return false; }
            return true;
        }
        function isComplete() {
            if(!hasAddress()) { return false; }
            if(!vm.purchaseOrder.shipvia) { return false; }
            if(!vm.purchaseOrder.ship_terms) { return false; }
            if(!vm.purchaseOrder.order) { return false; }
            if(!vm.purchaseOrder.vendor) { return false; }
            if(!vendorHasWarehouse()) { return false; }

            if(vm.purchaseOrder.status != 'Draft' && !vm.purchaseOrder.date) { return false; }
            if(vm.purchaseOrder.status != 'Draft' && !vm.purchaseOrder.desired_date) { return false; }

            return true;
        }
        function disableSave() {
            if(vm.saving) { return true; }
            if(!vm.isComplete()) { return true; }
            if(!vm.purchaseOrder.salesorder_info.sf_id && vm.purchaseOrder.status != 'Draft' && !DEBUG) { return true; }
            return false;
        }
        function saveObject() {
            var deferred = $q.defer();

            if (vm.saving === true) {
                return false;
            }

            //
            vm.saving = true;
            vm.error = false;

            vm.purchaseOrder.saveModel().then(function () {
                var promises = [];

                if (vm.fileSrv) {
                    promises.push(vm.fileSrv.uploadFiles('purchaseorder', vm.purchaseOrder.id));
                }
                if (vm.dateChange) {
                    promises.push(vm.dateChange.saveModel());
                }

                return $q.all(promises).then(function () {
                    $timeout(function () {
                        vm.saving = false;
                        vm.PurchaseOrderFrom.$setPristine();
                        //

                        vm.baseStatus = vm.purchaseOrder.status;
                        checkAvailStatus();

                        vm.fileSrv.files = [];
                        vm.dateChange = null;
                        vm.lineItemsDirty = false;
                        //$state.go('ordersListPage');
                        if ($state.current.name != 'purchaseOrderEditPage') {
                            $state.transitionTo(
                                'purchaseOrderEditPage',
                                {id: vm.purchaseOrder.id},
                                {location: 'replace'}
                            );
                        }
                        $timeout(check_po_update, 5000);
                    }, 300);

                }, function () {
                    vm.saving = false;
                    vm.error = true;
                });

            }, function () {
                vm.saving = false;
                vm.error = true;
            });


            return deferred.promise;
        }

        function check_po_update() {
            var test_po = new SlatePurchaseOrder();
            if(vm.purchaseOrder.status == 'Draft') { return false; }

            if(isNaN(vm.test_po_count)) { vm.test_po_count = 0; }
            test_po.loadFromServer(vm.purchaseOrder.id).then(function() {
                if(test_po.jansy_po) {
                    vm.test_po_count = 0;
                    vm.purchaseOrder.jansy_po = test_po.jansy_po;
                    vm.purchaseOrder.status = test_po.status;
                } else {
                    if(vm.test_po_count <=3) {
                        vm.test_po_count++;
                        $timeout(check_po_update, 5000);
                    } else {
                        vm.test_po_count = 0;
                    }
                }
            });
        }

        function canViewPDF() {
            return ['Sent', 'Partially Received', 'Received', 'Closed'].includes(vm.purchaseOrder.status);
        }

        function getPDFURL() {
            var deferred = $q.defer();
            var apiUrl = API_URL + 'salesforce/fetchpdfurl/';

            var promise = $http.get(apiUrl, {
                params: {parent_type: 'purchaseorder', parent_fk: vm.purchaseOrder.id}
            });
            promise.then(function(response) {
                deferred.resolve(response.data.payload);
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }


    }

})();
