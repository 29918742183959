(function() {
    "use strict";

    angular
        .module('slate.controlpanel')
        .directive('slateControlPanelsMenu', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/controlpanel/controlpanels.menu.dir.html',
            scope: {
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $timeout, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.permissions = SlateAuth.user.permissions;

        vm.panels = [
            {name: 'Departments and Roles', icon:'mdi-account-multiple', state:'controlPanels.rolesList', perm:'roles_can_alter'},
            //{name: 'Filearea Templates', icon:'mdi-folder-multiple', url: '/controlpanels/roles/', perm:'roles_can_alter'},
            //{name: 'Holiday and Sick Leave', icon:'mdi-calendar-remove', url: '/controlpanels/roles/', perm:'roles_can_alter'},
            {name: 'Materials List', icon:'mdi-account-multiple', state:'controlPanels.materials', perm:'roles_can_alter'},
            
        ];

        vm.gotoPanel = gotoPanel;

        function gotoPanel(state) {
            return $state.go(state);
        }

    }


})();
