(function () {
    'use strict';

    angular
        .module('slate.inspections')
        .directive('slateInspectionEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/inspections/inspection.edit.dir.html',
            scope: {
                inspection: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function ListController($scope, $http, getLinkSrv, InspectionFileEditSrv, SlateFileNodeList, SlateInspection) {
        /* jshint validthis: true */
        const vm = this;

        vm.getLink = getLinkSrv;
        vm.saveObject = saveObject;
        vm.showFinalStatus = showFinalStatus;
        vm.hasFailedItems = hasFailedItems;

        vm.statuses = [
            { name: 'New', complete: false }, { name: 'Scheduled', complete: false }, { name: 'Factory Confirmed', complete: false }, { name: 'In Process', complete: false }
        ];

        if (vm.inspection.contact_methods) {
            const phone = vm.inspection.contact_methods.find((item) => item.is_primary && item.method == 'phone');
            if (phone) { vm.phone_number = phone.value; }
            const email = vm.inspection.contact_methods.find((item) => item.is_primary && item.method == 'email');
            if (email) { vm.email = email.value; }
        }
        vm.inspector = vm.inspection.purchase_order_info.company_info.inspector || vm.inspection.purchase_order_info.vendor_info.inspector || 'qima';

        function hasFailedItems() {
            return vm.inspection.line_items.some((item) => item.status == 'Fail');
        }

        function showFinalStatus() {
            return ['Complete', 'Fail'].includes(vm.inspection.status);
        }

        function saveObject() {
            if (vm.saving) { return false; }

            vm.saving = true;
            vm.inspection.saveModel().then(() => {
                vm.saving = false;
            }, () => {
                vm.saving = false;
            });
        }

        function hasExceptionHold() {
            return vm.inspection.line_items.some((item) => item.status == 'Exception Hold');
        }

        function getDirs() {
            const nodes = new SlateFileNodeList();
            nodes.getList({ parent_type: 'inspection', parent_fk: vm.inspection.id }).then(() => {
                vm.dirs = {};
                nodes.list.forEach((node) => { vm.dirs[node.slug] = node.id; });
            }, () => {

            });
        }
        getDirs();
    }
})();
