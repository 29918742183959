(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetOpenOrders', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.openorders.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $interval, SlateOrders, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var interval = null;
        var speed = vm.settings.speed || 60000;
        vm.getLink = getLinkSrv;
        vm.orders = new SlateOrders();
        vm.month = 0;
        vm.months = ['This month', 'Next Month', 'Month After'];
        vm.dates = [];
        vm.setMonth = setMonth;
        vm.getTotal = getTotal;

        getMonths();

        function getMonths() {
            var today = new Date();
            var useDate = today;
            for (var i = 0; i < 3; i++) {
                useDate = dateFns.addMonths(today, i);
                vm.months[i] = useDate.toLocaleString('default', { month: 'long' }) + ' ' + useDate.getFullYear();
                vm.dates[i] = {
                    start: dateFns.startOfMonth(useDate),
                    end: dateFns.endOfMonth(useDate)
                };
            }
        }

        function getDateString(datething) {
            return datething.getFullYear() + '-' + (datething.getMonth() + 1) + '-' + datething.getDate();
        }

        function getData() {
            var filters = {
                order_by: 'invoice_date',
                invoiceStartDate: getDateString(vm.dates[vm.month].start),
                invoiceEndDate: getDateString(vm.dates[vm.month].end)
            };
            if (vm.settings.hide_closed) { filters.hide_closed = true; }
            if (vm.settings.only_development) { filters.only_development = true; }
            if (vm.settings.mine) { filters.mine = true; }
            if (vm.settings.sales_mgr) { filters.sales_mgr = vm.settings.sales_mgr; }
            if (vm.settings.proj_mgr) { filters.proj_mgr = vm.settings.proj_mgr; }
            vm.orders.getList(filters);
        }

        function setMonth(i) {
            vm.month = i;
            getData();
        }

        function getTotal() {
            var total = Big(0);
            vm.orders.list.forEach(function(item) {
                if(vm.settings.hide_closed && ['Closed Billed', 'Closed Complete'].includes(item.sf_status)) {
                    return false;
                }

                try {
                    total = Big(item.projected_amount).add(total);
                } catch(error) {
                    console.log(error);
                }

            });
            return parseFloat(total.toFixed(2));
        }

        $scope.$watch('vm.settings', function(items) {
            getMonths();
            getData();
        }, true);

        interval = $interval(function() {
            getMonths();
            getData();
        }, speed);

        $scope.$on('$destroy', function() {
            if (angular.isDefined(interval)) {
                $interval.cancel(interval);
                interval = undefined;
            }
        });

    }


})();
