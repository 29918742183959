(function () {
    'use strict';

    angular
        .module('slate.library.auth')
        .factory('SlateAuthInterceptor', SlateAuthInterceptor)
        .config(['$httpProvider',
            function ($httpProvider) {
                $httpProvider.defaults.useXDomain = true;
                $httpProvider.interceptors.push('SlateAuthInterceptor');
            }]);

    function SlateAuthInterceptor($rootScope, $q, $location) {
        return {

            responseError(response) {
                let error = {};
                const next = sessionStorage.getItem('next_url') || '/';
                // console.log('401 response', response);
                if (response.status === 401 && !next.startsWith('/login')) {
                    $rootScope.$broadcast('auth_loggedout', error);

                    if (!sessionStorage.getItem('next_url') && !$location.url().startsWith('/login')) {
                        sessionStorage.setItem('next_url', $location.url());
                    }
                    if (!$location.url().startsWith('/login') && response.data.detail !== 'Token Expired.') {
                        $location.url('/login/');
                    }
                }

                if (response.status <= 0) {
                    error = {
                        error: '<strong>Unable to connect to server.</strong> The server might be down, or you may be disconnected from the internet.'
                    };
                    response.error_msg = error;
                    $rootScope.$broadcast('auth_servererror', error);
                }

                if (response.status === 500) {
                    error = {
                        error: 'There was an error on the server. Please try again in a bit'
                    };
                    response.error_msg = error;
                    $rootScope.$broadcast('auth_servererror', error);
                }

                if (response.status === 403) {
                    error = {
                        error: 'You are not authorized for this resource. Please have an admin check your permissions.'
                    };
                    response.error_msg = error;
                    $rootScope.$broadcast('auth_servererror', error);
                }


                return $q.reject(response);
            }
        };
    }
})();
