(function() {
    "use strict";

    angular
        .module('slate.app')
        .service('SlateGetIP', editDialog);


    function editDialog($rootScope, $q, $http) {

        var srv = {
            ip: '0.0.0.0',
            ips: []
        };

        srv.promise = $http.get(API_URL+"auth/get-ip/").then(function(response) {
            srv.ip = response.data;
        });

        return srv;



    }



})();
