(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetQcRequests', modelList)
        .directive('slateWidgetQcRequestsLine', modelLine);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.qcrequests.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $interval, SlateAuth, SlateRolesSrv, SlateQCRequests, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var interval = null;
        var speed = vm.settings.speed || 1080000;

        vm.auth = SlateAuth;

        vm.getLink = getLinkSrv;
        vm.qcrequests = new SlateQCRequests();

        function getData() {
            var filters = { };
            if (vm.settings.show_completed === true) {
                filters.hide_completed = 10;
            } else {
                filters.hide_completed = 0;
            }
            vm.qcrequests.getList(filters).then(function() {
                vm.qcrequests.list.sort(function compare(a, b) {
                    if(a.completed) { return 1; }
                    if (a.inspection_date < b.inspection_date) {
                        return -1;
                    }
                    if (a.inspection_date > b.inspection_date) {
                        return 1;
                    }
                    return 0;
                });
            });
        }

        // getData();

        $scope.$watch('vm.settings', function(items) {
            getData();
        }, true);

        interval = $interval(function() {
            getData();
        }, speed);

        $scope.$on('$destroy', function() {
            if (angular.isDefined(interval)) {
                $interval.cancel(interval);
                interval = undefined;
            }
        });
    }

    function modelLine() {
        return {
            restrict: 'E',
            
            templateUrl: '/partials/dashboard/dashboard.widget.qcrequestsline.html',
            scope: {
                entry: "="
            },
            controller: LineController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function LineController($scope, SlateAuth, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var unwatch;

        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;

        vm.toggleCompleted = toggleCompleted;

        function toggleCompleted() {
            if(vm.auth.perms.qcrequest_can_mark_complete) {
                vm.entry.completed = !vm.entry.completed;
                vm.entry.saveModel();
            }
        }

        $scope.$watch('vm.entry.assigned_to', function(new_item, old_item) {
            if(new_item != old_item) {
                vm.entry.saveModel();
            }
            
        });
    
    }

})();
