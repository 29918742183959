(function () {
    "use strict";

    angular
        .module('slate.helpdesk')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('ticketListPage', {
                url: '/helpdesk/tickets/',
                template: "<div class='content-pane list-page'><slate-ticket-list hide-filters='false'></slate-ticket-list></div>",
                current_app: 'helpdesk',
                slateTitle: 'Helpdesk',
            })
            .state('ticketEditPage', {
                url: '/helpdesk/tickets/:id/',
                templateUrl: '/partials/helpdesk/ticket.edit.page.html',
                controller: 'SlateTicketEditPageController',
                controllerAs: 'vm',
                current_app: 'helpdesk',
                slateTitle: 'Edit Ticket - Helpdesk',
            })

            // .state('shipmentNewPage', {
            //     url: '/logistics/shipment/new/',
            //     templateUrl: '/partials/logistics/shipment.edit.page.html',
            //     controller: 'SlateShipmentNewPageController',
            //     controllerAs: 'vm',
            //     current_app: 'logistics',
            //     slateTitle: 'Create New Shipment - Logistics',
            // })
            // .state('shipmentFollowupPage', {
            //     url: '/logistics/shipment/:id/ship/',
            //     templateUrl: '/partials/logistics/followup/ship.new.page.html',
            //     controller: 'SlateFollowupShipmentNewPageController',
            //     controllerAs: 'vm',
            //     current_app: 'logistics',
            //     slateTitle: 'Ship Followup - Logistics',
            // })

            ;
    }
})();