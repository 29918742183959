(function () {
    "use strict";

    angular
        .module('slate.qc')
        .directive('slateQcrequestEdit', modelList)
        .directive('slateQcrequestSkuLine', skuModelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/qc/qcrequest.edit.dir.html',
            scope: {
                qcrequest: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $timeout, $state, $location, $q, getLinkSrv, SlateQCRequest, SlateAuth, SlateQCRequestSKUs, SlateQCRequestSKU, SlateFileNode, SlateFileNodeList, SlatePurchaseOrder, SlateEmployees) {
        /* jshint validthis: true */
        var vm = this;

        vm.save = save;
        vm.user = SlateAuth;
        vm.addSku = addSku;
        vm.isComplete = isComplete;
        vm.canFullEdit = canFullEdit;

        vm.addInspectionDate = addInspectionDate;
        vm.removeInspectionDate = removeInspectionDate;
        vm.saving = false;
        vm.error = false;
        vm.parsed = false;

        vm.froalaOptions = {
            toolbarButtons: ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            zIndex: 10,
        };
    
        vm.rawNodes = new SlateFileNodeList();
        vm.areas = ['shipping', 'signedsamples', 'artwork', 'drawings'];
        vm.nodes = {};
        vm.fileSrv = {
            shipping: {},
            artwork: {},
            drawings: {},
            signedsamples: {}
        };


        function getNodes() {
            var modelPromise;

            var nodeListFilters = {
                parent_type: 'qcrequest',
                parent_fk: vm.qcrequest.id
            };

            modelPromise = vm.rawNodes.getList(nodeListFilters);
            modelPromise.then(function () {
                angular.forEach(vm.rawNodes.list, function (node) {
                    if (vm.areas.includes(node.filename)) { vm.nodes[node.filename] = node.id; }
                });
            });

        }


        vm.skus = new SlateQCRequestSKUs();

        if (vm.qcrequest.id) {
            vm.skus.getList({ qcrequest: vm.qcrequest.id });
            getNodes();
        }
        $scope.$watch('vm.qcrequest.id', function () {
            if (vm.qcrequest.id) {
                vm.skus.getList({ qcrequest: vm.qcrequest.id });
                getNodes();
            }
            if (vm.qcrequest.inspection_dates.length > 0 && !vm.parsed) {
                parseInspectionDates();
                vm.parsed = true;
            }
        });

        function contactSync(companyId) {
            var employeeList = new SlateEmployees();
            employeeList.getList({company: companyId}).then(function() {
                if (employeeList.list.length) {
                    var contact = employeeList.list[0].contactModel;
                    vm.qcrequest.contact_name = contact.full_name;
                    vm.qcrequest.contact_email = contact.email;
                    contact.getContactMethods().then(function() {
                        var contactPhone = contact.contactmethods.list.find(function(method) { return method.method == 'phone' && method.is_primary; });
                        if (contactPhone) { 
                            vm.qcrequest.contact_phone = contactPhone.value;
                        } else {
                            vm.qcrequest.contact_phone = '';
                        }
                    });
                }
            });
        }

        function purchaseOrderSync() {
            var po = new SlatePurchaseOrder();
            po.loadFromServer(vm.qcrequest.purchase_order).then(function() {
                vm.qcrequest.client = po.company;
                vm.qcrequest.project = po.project;
                vm.qcrequest.order = po.order;
                vm.qcrequest.proj_mgr = po.proj_mgr;
                vm.qcrequest.sales_mgr = po.sales_mgr;
                vm.qcrequest.factory = po.vendor;

                if (!vm.skus.list.length) {
                    po.line_items.forEach(function(item) {
                        var sku = new SlateQCRequestSKU();
                        sku.component = item.component;
                        sku.name_en = item.component_info.composite_name;
                        sku.qty = item.qty;
                        vm.skus.list.push(sku);
                    });
                }

                contactSync(po.vendor);
            });
        }
        $scope.$watch('vm.qcrequest.purchase_order', function() {
            if (vm.qcrequest.purchase_order) {
                purchaseOrderSync();
            }
        });

        function addSku() {
            var sku = new SlateQCRequestSKU();
            vm.skus.list.push(sku);
        }

        // convert strings to date objects for each inspection date
        function parseInspectionDates(){
            var newArr = [];
            for (var i = 0; i < vm.qcrequest.inspection_dates.length; i++){
                var newDate = new Date(vm.qcrequest.inspection_dates[i].date);
                newArr.push({date: newDate, notes: vm.qcrequest.inspection_dates[i].notes});
            }
            vm.qcrequest.inspection_dates = newArr;
        }

        // serialize back to strings for saving
        function serializeInspectionDates(){
            for (var i = 0; i < vm.qcrequest.inspection_dates.length; i++){
                vm.qcrequest.inspection_dates[i].date = vm.qcrequest.inspection_dates[i].date.toDateString();
            }
        }

        function addInspectionDate() {
            vm.qcrequest.inspection_dates.push({date: null, notes: ""});
        }

        function removeInspectionDate(item) {
            var index;

            index = vm.qcrequest.inspection_dates.indexOf(item);
            if(index > -1) {
                vm.qcrequest.inspection_dates.splice(index, 1);
            }


        }

        function save() {
            serializeInspectionDates();
            var promises = [];
            vm.saving = true;
            vm.error = false;
            vm.qcrequest.saveModel().then(function () {
                console.log(vm.fileSrv);
                var nodeCreationPromises = [];
                angular.forEach(vm.areas, function (filename) {
                    if (vm.fileSrv[filename].files && vm.fileSrv[filename].files.length > 0) {
                        
                        if (!vm.nodes[filename]) {
                            var deferred = $q.defer();
                            promises.push(deferred.promise);

                            var node = new SlateFileNode();
                            node.mimetype = "application/directory";
                            node.filename = filename;

                            node.parent_type = 'qcrequest';
                            node.parent_fk = vm.qcrequest.id;
                            console.log('saving node');
                            node.saveModel().then(function () {
                                vm.nodes[filename] = node.id;
                                
                                promises.push(vm.fileSrv[filename].uploadFiles('filenode', node.id).then(function () {
                                    deferred.resolve();
                                }, function () {
                                    deferred.reject();
                                }));
                            });
                        } else {
                            promises.push(vm.fileSrv[filename].uploadFiles('filenode', vm.nodes[filename]).then(function () {

                            }));
                        }

                    }
                });

                var i = 0;
                angular.forEach(vm.skus.list, function (item) {
                    if (!item.toDelete) {
                        i = i + 1;
                        item.order = i;
                        item.qcrequest = vm.qcrequest.id;
                        promises.push(item.saveModel());
                    } else {
                        item.deleteModel();
                    }

                });
                return $q.all(promises).then(function () {
                    $timeout(function () {
                        vm.saving = false;
                        $state.go('qcrequestListPage');
                    }, 500);
                }, function (e) {
                    vm.saving = false;
                    vm.error = true;
                });
            }, function (e) {
                vm.saving = false;
                vm.error = true;
            });
        }

        function canFullEdit() {
            return true;
        }

        function isComplete() {
            if (!vm.qcrequest.name_en && !vm.qcrequest.name_zh) { return false; }
            if (!vm.qcrequest.client) { return false; }
            if (!vm.qcrequest.project) { return false; }
            if (!vm.qcrequest.inspection_date) { return false; }
            if (!vm.qcrequest.factory) { return false; }
            if (!vm.qcrequest.contact_name) { return false; }
            if (!vm.qcrequest.contact_email) { return false; }
            if (!vm.qcrequest.contact_phone) { return false; }

            if (vm.qcrequest.request_type == "inspection") {
                if (
                    typeof vm.qcrequest.data == "undefined" ||
                    typeof vm.qcrequest.data.inspection == "undefined" ||
                    !vm.qcrequest.data.inspection.type
                ) { return false; }

                if (!vm.qcrequest.purchase_order) { return false; }

                if (vm.skus.list.length < 1) {
                    return false;
                }

            }



            if (vm.qcrequest.request_type == "audit" && (
                typeof vm.qcrequest.data == "undefined" ||
                typeof vm.qcrequest.data.audit == "undefined" ||
                !vm.qcrequest.data.audit.type
            )) { return false; }

            return true;
        }

    }


    function skuModelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/qc/qcrequest.edit.skuline.dir.html',
            scope: {
                sku: '=',
                project: '=?',
            },
            controller: SkuListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function SkuListController($scope, SlateProjects) {
        /* jshint validthis: true */
        var vm = this;

        $scope.$watch('vm.selectedPrj', function () {
            if (vm.selectedPrj && !vm.sku.name_en) {
                vm.sku.name_en = vm.selectedPrj.composite_name;
            }
        });

    }

})();
