(function () {
    "use strict";

    angular
        .module('slate.salesquotes')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('salesQuotesListPage', {
                url: '/salesquotes/',
                template: "<div class=\"content-pane list-page\"><slate-sales-quotes-list show-totals='true'></slate-sales-quotes-list></div>",
                current_app: 'salesquotes',
                slateTitle: 'Sales Quotes',
            })
            .state('salesQuoteNewPage', {
                url: '/salesquotes/quote/new/',
                templateUrl: '/partials/salesquotes/salesquote.new.page.html',
                controller: 'SlateSalesQuoteNewPageController',
                controllerAs: 'vm',
                current_app: 'salesquotes',
                slateTitle: 'Create New Sales Quote - Sales Quote',
            })
            .state('salesQuoteEditPage', {
                url: '/salesquotes/quote/:id/edit/',
                templateUrl: '/partials/salesquotes/salesquote.edit.page.html',
                controller: 'SlateSalesQuoteEditPageController',
                controllerAs: 'vm',
                current_app: 'salesquotes',
                slateTitle: 'Edit Sales Quote - Sales Quote',
            })
            ;
    }
})();