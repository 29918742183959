var activityLogServices = angular.module('slate.activityLogSrv', []);

activityLogServices.factory('ActivityLogList', function ($http, $q) {
    "use strict";

    var srv = function(filters) {
        this.activityLogList = [];
        this.filters = filters || {} ;

    };

    var apiUrl = API_URL + 'activitylog/';


    srv.prototype.getList = function (filters) {
        var self = this;
        var defered = $q.defer();

        if (typeof filters !== 'undefined') { self.filters = filters; }

        if(!self.filters || self.filters.length < 1) { defered.reject([]); return defered.promise; }
        
        $http({
            method: 'GET',
            url: apiUrl,
            params: self.filters,
            cache: false
        })
        .success( function(data, status, headers, config) {
            if(status==200) {
                self.activityLogList = _.sortBy(data, function(item) {
                    return Date(item.created);
                }).reverse();
                defered.resolve(self.activityLogList);
            } else {
                defered.reject([]);
            }
        });

        return defered.promise;
    };

    return srv;
});
