(function () {
    'use strict';

    angular
        .module('slate.library.roles')
        .factory('SlateFileVerList', ModelList)
        .factory('SlateFileVer', ModelDetail);

    const apiUrl = `${API_URL}files/nodes/`;

    function ModelList($http, $q, SlateFileVer) {
        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(node_id, filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let promise;
            const promises = [];
            const url = `${apiUrl + node_id}/vers/`;

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            promise = $http.get(url, { params: self.filters });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateFileVer();

                    itemModel.loadFromPayload(item);
                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        // fields = ('id', 'core', 'notes', 'filename', 'created', 'createdby', 'createdby_name', 'version',  'final', 'disabled', 'annotations')
        const fields = [
            { name: 'id', def: null, readonly: true },

            { name: 'has_preview', def: null, readonly: true },

            { name: 'core', def: null, readonly: true },
            { name: 'version', def: null, readonly: true },

            { name: 'notes', def: null },
            { name: 'filename', def: null },
            { name: 'final', def: null },
            { name: 'disabled', def: null },
            { name: 'annotations', def: null },

            { name: 'created', def: null, readonly: true },
            { name: 'createdby', def: null, readonly: true },
            { name: 'createdby_name', def: null, readonly: true },
            { name: 'createdby_slug', def: null, readonly: true },
            { name: 'qc', def: false }

        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;


        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                self[field.name] = payload[field.name];
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            const apiUrl = `${API_URL}files/vers/`;
            let modelUrl = `${apiUrl + self.id}/`;

            let promise;

            if (id) { modelUrl = `${apiUrl + id}/`; }

            if (!self.id && !id) {
                console.log('Tried to load from server without id');
                return self;
            }

            promise = $http.get(modelUrl);

            promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            const apiUrl = `${API_URL}files/vers/`;
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) { modelUrl = `${apiUrl + self.id}/`; }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                }
            });

            promise = $http.post(modelUrl, data);
            promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;
                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) { modelUrl = `${apiUrl + self.id}/`; }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                }
            });

            promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }
})();
