(function () {
    "use strict";

    angular
        .module('slate.billings')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('billingListPage', {
                url: '/billings/',
                template: "<div class=\"content-pane list-page\"><slate-billings-list></slate-billing-list></div>",
                current_app: 'salesorders',
                slateTitle: 'Billings List',
            })
            .state('billingEditPage', {
                url: '/billings/:id/edit/',
                templateUrl: '/partials/billings/billing.edit.page.html',
                controller: 'BillingsEditPageController',
                controllerAs: 'vm',
                current_app: 'salesorders',
                slateTitle: 'Edit Billing',
            })
            ;
    }
})();