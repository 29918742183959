(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportNonlabor', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/reports/reports.report.non_labor_pos.dir.html',
            scope: {

            },
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $state, $location, $http, $q, $stateParams, $httpParamSerializer, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.linksrv = getLinkSrv;
        vm.loading = true;

        vm.data = [];
        vm.getDownloadUrl = getDownloadUrl;

        var apiUrl = API_URL + 'reports/non_labor_pos/';
        var now = new Date();

        var endLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
        var defaultEnd = endLastMonth.getFullYear() + '-' + (endLastMonth.getMonth()+1) + '-' + endLastMonth.getDate();
        var twomonthsago = new Date(now.getFullYear(), now.getMonth() - 4, 1);
        var defaultStart = twomonthsago.getFullYear() + '-' + (twomonthsago.getMonth()+1) + '-' + twomonthsago.getDate();

        vm.filters = {
            startDate: $location.search().startDate || defaultStart,
            endDate: $location.search().endDate || defaultEnd,
            companies: $location.search().companies || []
        };
        var parts;
        if(vm.filters.startDate) {
            parts = vm.filters.startDate.split('-');
            vm.start = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
        }
        if(vm.filters.endDate) {
            parts = vm.filters.endDate.split('-');
            vm.end = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
        }
        $scope.$watch('vm.start', function () {
            if (vm.start) {
                vm.filters.startDate = vm.start.getFullYear() + '-' + (vm.start.getMonth()+1) + '-' + vm.start.getDate();
            } else {
                vm.filters.startDate = null;
            }
        });
        $scope.$watch('vm.end', function () {
            if (vm.end) {
                vm.filters.endDate = vm.end.getFullYear() + '-' + (vm.end.getMonth()+1) + '-' + vm.end.getDate();
            } else {
                vm.filters.endDate = null;
            }
        });

        if (typeof vm.filters.companies == 'string') { vm.filters.companies = [vm.filters.companies]; }

        $scope.$watchCollection(function () {
            return JSON.stringify(vm.filters);
        }, function (value) {
            console.log('filters changed');
            updateFromFilter();
        });

        function updateFromFilter() {
            var newData = [];
            vm.loading = true;

            angular.forEach(vm.filters, function (value, item) {
                if(value === '') { vm.filters[item] = null; }
                if (vm.filters[item]) {
                    if(vm.filters[item] instanceof Date) {
                        $location.replace().search(item, vm.filters[item].toISOString());
                    } else {
                        $location.replace().search(item, vm.filters[item]);
                    }
                } else {
                    $location.replace().search(item, null);
                }
            });

            $http.get(apiUrl, {params: vm.filters}).then(function(resp) {
                vm.data = resp.data.payload;
                vm.loading = false;
            });
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }

        vm.addCo = function addCo() {
            if(vm.filters.companies[vm.filters.companies.length - 1] != '') {
                vm.filters.companies.push('');
            }
        };



    }


})();
