var slateApp = angular.module('slate.app', [
    // core modules
    'ngAnimate',
    'ngSanitize',
    'ngCookies',
    'ngTouch',

    'froala',
    'gridster',

    // vendor modules
    'ui.router',
    'ui.bootstrap',
    'ui.sortable',
    'ui.select',

    // 'ui-leaflet',

    'ngFileUpload',
    'ngImgCrop',

    'ngToast',

    'pascalprecht.translate',
    'cleave.js',

    // Slate common modules
    'slate.templates',
    'slate.library',

    // Site Specific
    'slate.auth',
    'slate.roles',
    'slate.navbar',
    'slate.controlpanel',
    'slate.companies',
    'slate.contacts',
    'slate.locations',
    'slate.contactmethods',
    'slate.shippingaccounts',
    'slate.profilephotos',
    'slate.projects',
    'slate.presentations',
    'slate.filebrowser',
    'slate.approvals',
    'slate.workflows',
    'slate.requests',
    'slate.timetracking',
    'slate.sampletracking',
    'slate.dashboard',
    'slate.schedule',
    'slate.discussion',
    'slate.pos',
    'slate.purchaseorders',
    'slate.salesorders',
    'slate.salesquotes',
    'slate.salesbudgets',
    'slate.invoices',
    'slate.reports',
    'slate.rfqs',
    'slate.qc',
    'slate.qima',
    'slate.inspections',
    'slate.customercomplaints',
    'slate.logistics',
    'slate.orders',
    'slate.datelog',
    'slate.bom',
    'slate.orderplan',
    'slate.billings',
    'slate.auditlog',
    'slate.notifications',
    'slate.rates',
    'slate.conditions',
    'slate.estimates',
    'slate.helpdesk',
    'slate.vendorsetuprequests',
    'slate.salesforce',
    'slate.productprofiles',
    'slate.synergy',

]);

slateApp.value('froalaConfig', {
    key: 'GF3G2G1A6B4B3pD1D1D1A4E1A3A16B3D8B4kWd1WDPTa1ZNRGe1OC1c1==',
    toolbarButtons: ["bold", "italic", "underline", 'fontSize', "|",
        "align", "formatOL", "formatUL", "|",
        "quote", "insertTable", "|", 'insertLink'
    ],
    iconsTemplate: 'font_awesome_5l',
    charCounterCount: false,
    pluginsEnabled: ['align', 'colors', 'draggable', 'emoticons', 'entities', 'fontAwesome', 'fontFamily', 'fontSize', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quote', 'table', 'image', 'url', 'video', 'wordPaste'],
    spellcheck: true
});

slateApp.config(function ($stateProvider, $uibModalProvider, $qProvider, $urlMatcherFactoryProvider, $urlRouterProvider, $locationProvider, $httpProvider, $cookiesProvider, $compileProvider, ngToastProvider, $translateProvider) {
    "use strict";

    if (DEBUG != "True") {
        console.log('disabling debugging');
        $compileProvider.debugInfoEnabled(false);

        //$qProvider.errorOnUnhandledRejections(false);
    }
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|webcal|local|file|data|blob|weixin):/);

    $translateProvider.useStaticFilesLoader({
        prefix: '/locales/locale-',
        suffix: '.json'
    });
    $translateProvider.useSanitizeValueStrategy('sce');
    $translateProvider.useLocalStorage();
    $translateProvider.registerAvailableLanguageKeys(['en', 'zh'], {
        'en_*': 'en',
        'zh_*': 'zh'
    });
    $translateProvider.fallbackLanguage('en');
    $translateProvider.determinePreferredLanguage(); //.preferredLanguage('en');

    $uibModalProvider.options.backdrop = "static";

    ngToastProvider.configure({
        animation: 'slide',
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        combineDuplications: true,
    });

    $httpProvider.defaults.useXDomain = true;
    $httpProvider.defaults.withCredentials = true;
    $httpProvider.defaults.headers.common.Accept = "application/json;charset=utf-8";
    //$httpProvider.defaults.headers.common["Accept-Charset"]="charset=utf-8";


    $cookiesProvider.defaults.path = '/';
    $cookiesProvider.defaults.secure = true;

    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
    });

    $urlRouterProvider.otherwise("/");
    $urlRouterProvider.deferIntercept();
    //reloadOnSearch: false,

    $stateProvider

        .state('controlPanel', {
            url: '/controlpanel/',
            templateUrl: '/partials/controlpanel/controlPanelsList.html',
            controller: 'controlPanelCtrl',
            current_app: 'controlpanels',
            slateTitle: 'Control Panels'
        })
        .state('controlPanel.Workflow', {
            url: 'workflows/',
            templateUrl: '/partials/controlpanel/workflowPanel.html',
            controller: 'workflowPanelCtrl',
            current_app: 'controlpanels',
        })
        .state('controlPanel.workflowDetails', {
            url: 'workflows/:workflowid/',
            templateUrl: '/partials/projects/details.milestone.html',
            controller: 'workflowDetailsPanelCtrl',
            current_app: 'controlpanels',
            current_tab: 'workflow'
        })
        .state('reportHours', {
            url: '/reports/hours/',
            templateUrl: '/partials/reports/reports_hours.html',
            current_app: 'reports',
        })
        .state('leadsForm', {
            url: '/internal-leads/',
            template: "<div class='content-pane'><slate-leads-form></slate-leads-form></div>",
            current_app: 'internalleads',
            slateTitle: 'Internal Lead',
        })
        .state('projconvForm', {
            url: '/projconv/',
            template: "<div class='content-pane'><slate-proj-conv-form></slate-proj-conv-form></div>",
            current_app: 'internalleads',
            slateTitle: 'Project to Salesforce Conversion',
        })
        ;


});



slateApp.controller('dashboardCtrl', function ($scope, $http, $window) {
    "use strict";


});


slateApp.service('getLinkSrv', function ($state) {
    "use strict";
    var srv = {};

    srv.getLink = function (name, type, id, slug) {
        var url = srv.getUrl(type, id, slug);
        if (url) {
            return '<a href="' + url + '">' + name + "</a>";
        }
        return name;
    };

    srv.getUrl = function (type, id, slug) {

        if (type == "contact") {
            return '/contacts/' + slug + '/';
        }
        if (type == "company") {
            return '/companies/' + slug + '/';
        }
        if (type == "project") {
            return '/projects/' + slug + '/';
        }
        if (type == 'order') {
            return '/orders/orders/' + slug + '/edit/';
        }
        if (type == 'purchaseorder') { return $state.href('purchaseOrderEditPage', { id: id }, { absolute: true }); }
        if (type == 'salesorder') { return $state.href('salesOrderEditPage', { id: id }, { absolute: true }); }
        if (type == 'shipment') { return $state.href('shipmentEditPage', { id: id }, { absolute: true }); }
        if (type == 'billing') { return $state.href('billingEditPage', { id: id }, { absolute: true }); }
        if (type == 'qclog') { return $state.href('qclogEditPage', { id: id }, { absolute: true }); }
        if (type == 'helpdeskticket') { return $state.href('ticketEditPage', { id: id }, { absolute: true }); }

        return '';
    };

    return srv;
});

slateApp.filter('numberFixedLen', function () {
    return function (n, len) {
        var num = parseInt(n, 10);
        len = parseInt(len, 10);
        if (isNaN(num) || isNaN(len)) {
            return n;
        }
        num = '' + num;
        while (num.length < len) {
            num = '0' + num;
        }
        return num;
    };
});
