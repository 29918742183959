( function () {
    "use strict";

    angular
        .module( 'slate.app' )
        .directive( 'slateAffix', slateAffix );

    function slateAffix() {
        return {
            restrict: 'A',
            link: affixLinker,
        };
    }


    function affixLinker( scope, elem, attrs ) {
        var frame;
        var org_pos;
        var minTopSpace = attrs.minTopSpace;
        var minTopElement = angular.element(attrs.minTopElement)[0];
        var minBotSpace = attrs.minBotSpace;
        var minBotElement = angular.element(attrs.minBotElement)[0];
        var side = attrs.offsetSide;
        var cssStyles = window.getComputedStyle(elem[0]);

        org_pos = elem[ 0 ].getBoundingClientRect();

        angular.element( window ).bind( "scroll", function () {
            if ( frame ) {
                window.cancelAnimationFrame( frame );
            }
            frame = requestAnimationFrame( update_element );


        } );


        function update_element() {
            frame = null;
            var topElBot = 0;
            var botElTop = 0;
            var cssData = {};
            var cur_pos = elem[ 0 ].getBoundingClientRect();

            var doc = document.documentElement;
            var top = ( window.pageYOffset || doc.scrollTop ) - ( doc.clientTop || 0 );

            if(minTopElement) {
                topElBot = minTopElement.offsetHeight;
            }
            if(minBotElement) {
                botElTop = window.innerHeight - minBotElement.getBoundingClientRect().top; //minBotElement.offsetHeight;
                if(botElTop < 0) { botElTop = 0; }
            }
            
            if (top > (org_pos.top )) {

                if(elem.css('position') != 'fixed') {
                    cssData.position= 'fixed';
                    cssData.top = topElBot;


                    if(side == "right") {
                        cssData[side] = document.body.clientWidth - org_pos[side];
                    } else {
                        cssData[side] = org_pos[side];
                    }

                }
                cssData.bottom = botElTop;
                elem.css(cssData);
            } else {

                cssData.position= "";
                cssData.top = "";
                cssData.bottom = "";
                cssData[side] = "";

                elem.css(cssData);
            }

        }


    }


} )();
