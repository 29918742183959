(function () {
    'use strict';

    angular
        .module('slate.auth')
        .directive('slateLoginBox', loginbox);

    function loginbox() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/auth/auth.loginbox.dir.html',
            scope: {

            },
            controller: loginBoxController,
            controllerAs: 'vm',
            link: loginBoxLinker
        };
    }

    function loginBoxController($scope, $state, $window, $location, SlateAuth) {
        /* jshint validthis: true */
        const vm = this;
        vm.loginDetails = { username: '', password: '', rememberMe: true };
        vm.loginDetails.username = $window.localStorage.slateLastLoggedInAs;
        vm.auth = SlateAuth;
        vm.login = login;
        vm.use_okta = ['True', 'true', true].includes(USE_OKTA);

        function login() {
            vm.error = null;

            const promise = SlateAuth.newLogin(vm.loginDetails.username, vm.loginDetails.password, vm.loginDetails.rememberMe);
            promise.then(() => {
                const next = sessionStorage.getItem('next_url');
                sessionStorage.removeItem('next_url');
                if (!next || next == null || next.startsWith('/login')) {
                    // $location.url('/');
                    $state.go(
                        'dashboard',
                        { location: 'replace' }
                    );
                } else {
                    location.replace(next);

                    // location.reload();
                    // $location.url(next);
                }
            }, (response) => {
                vm.error = response.error || 'Error logging in';
                vm.loginDetails.password = '';
            });
        }

    }

    function loginBoxLinker(scope, elem, attrs) {
        const inputs = elem.find('input');

        inputs[0].focus();
    }
})();
