(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .controller('BillingsEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, SlateTitle, $stateParams, SlateBilling) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.billing = new SlateBilling();

        vm.billing.loadFromServer($stateParams.id).then(function () {
            if (vm.billing.name) {
                SlateTitle.set('Edit ' + vm.billing.name + ' - Billings');
            } else {
                SlateTitle.set('Edit Untitled billing - Billings');
            }
            vm.loading = false;

        }).catch(angular.noop);

        $scope.$watch('vm.billing.name', function () {
            if (vm.billing.name) {
                SlateTitle.set('Edit ' + vm.billing.name + ' - Billings');
            } else {
                SlateTitle.set('Edit Untitled billing - Billings');
            }
        });

    }

})();
