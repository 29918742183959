(function () {
    "use strict";

    angular
        .module('slate.logistics')
        .directive('slateShipmentsDisagnostics', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/logistics/shipment.diagnostic.dir.html',
            scope: {
                shipment: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $state, $location, $window, $q, SlateAuth, SlateShipments, SlateShipment, getLinkSrv, SlateDeleteObjSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;
        vm.diagnostics_run = false;
        vm.diagnostics = {};
        vm.diagnostics_keys = [];
        vm.getData = getData;
        vm.resendPending = resendPending;
        vm.hasPending = hasPending;
        vm.sending = false;

        function getData() {
            var url = API_URL + 'logistics/shipment/' + vm.shipment.id + '/diagnostics/';
            var params = {};
            if(vm.check_sf) { params.check_sf = true; }
            return $http.get(url, {params: params}).then(function(response) {
                vm.diagnostics_run = true;
                vm.diagnostics = response.data.payload;
                vm.diagnostics_keys = Object.keys(vm.diagnostics);
            });
        }

        function resendPending() {
            if(!vm.auth.perms.logistics_can_diagnose_resend) { return false; }
            var url = API_URL + 'logistics/shipment/' + vm.shipment.id + '/reship/';
            vm.sending = true;
            vm.resendError = false;
            return $http.post(url).then(function(response) {
                vm.sending = false;
                vm.getData();
            }, function(response) {
                vm.sending = false;
                vm.resendError = true;
                console.log('resend error:', response.data);
            });
        }

        function hasPending() {
            if(vm.diagnostics.pending_receives) { return true; }
            if(vm.diagnostics.pending_transfers) { return true; }
            if(vm.diagnostics.pending_deliveries) { return true; }
            if(vm.diagnostics.pending_builds) { return true; }
            return false;
        }

    }


})();
