(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .service('SlateSQLineItemFixedCostsEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateSQLineItem) {
        var templateUrl = '/partials/salesquotes/sq.lineitems/sq.lineitem.fixedcosts.sidebar.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(item, options) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj: function () {
                        return item;
                    },
                    lineItem: function() {
                        if(typeof options === 'object' && options.lineItem) {
                            return options.lineItem;
                        }
                        return null;
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance,  obj, lineItem, SlateSettings, SlateCompany, SlateProject, SlateLocations, SlateBOMs, SlateBOM, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;

        vm.fixedcosts = obj;
        vm.item = lineItem;
        vm.saveObject = saveObject;
        vm.updateMargin = updateMargin;
        vm.updatePrice = updatePrice;
        vm.clearFixedCost = clearFixedCost;
        vm.toggleAmortize = toggleAmortize;
        vm.cancel = cancel;
        vm.auth = SlateAuth;

        // vm.isComplete = isComplete;

        vm.addCost = addCost;
        vm.deleteCost = deleteCost;

        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            //zIndex: 2501,
        };


        function saveObject(skip) {
            vm.fixedcosts.forEach(function(fixedcost) {
                if(Number.isNaN(fixedcost.price)) { fixedcost.price = 0; }
                if(Number.isNaN(fixedcost.cost)) { fixedcost.cost = 0; }
            });
            $uibModalInstance.close(vm.fixedcosts);
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }

        function addCost() {
            vm.fixedcosts.push({name:'Fixed Cost Name', cost: 0, margin: 0, price: 0});
        }

        function deleteCost(idx) {
            vm.fixedcosts.splice(idx, 1);
        }

        function updatePrice(fixedcost) {
            var cost, margin;

            if(typeof fixedcost.cost == 'undefined') { fixedcost.cost = 0; }
            if(fixedcost.cost == '') { fixedcost.cost = 0; }
            if(!fixedcost.margin) { fixedcost.margin = 0; }

            cost = Big(fixedcost.cost);
            margin = Big(fixedcost.margin).div(100);
            fixedcost.lastUpdate = 'margin';
            fixedcost.price = cost.div(Big(1).minus(margin)).toFixed(5);
        }

        function updateMargin(fixedcost) {
            var cost, sell;
            if(fixedcost.cost == '') { fixedcost.cost = 0; }

            cost = Big(fixedcost.cost);
            sell = Big(fixedcost.price);
            fixedcost.lastUpdate = 'price';
            fixedcost.margin = sell.minus(cost).div(sell).times(100).toFixed(2);
        }

        function clearFixedCost(fixedCost) {
            if (fixedCost.amortize) {
                fixedCost.margin = 0;
                fixedCost.price = 0;
                fixedCost.lastUpdate = '';
            }
        }

        function toggleAmortize(fixedcost) {
            fixedcost.amortize = !fixedcost.amortize;
            clearFixedCost(fixedcost);
        }

    }
})();
