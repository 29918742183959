(function () {
    "use strict";

    angular
        .module('slate.companies')
        .directive('slateLoginDetailsPane', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/contacts/logindetails.settings.dir.html',
            scope: {
                contact: '='
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function directiveController($scope, $state, $location, $q, $timeout, $http, getLinkSrv, SlateLoginSettings, SlateBlanketRoles, SlateAuth, SlateRolesSrv) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;
        vm.saving = false;
        vm.slateAuth = SlateAuth;

        vm.loginDetails = new SlateLoginSettings();
        modelPromise = vm.loginDetails.loadFromServer(vm.contact.id);

        vm.blanketRoles = new SlateBlanketRoles();
        vm.blanketRoles.loadFromServer(vm.contact.id, 'contact');

        vm.roles = SlateRolesSrv;

        vm.saveUserType = saveUserType;
        vm.saveHomepage = saveHomepage;
        vm.saveLoginReq = saveLoginReq;
        vm.toggle_login = toggle_login;
        vm.toggle_staff = toggle_staff;
        vm.toggle_unread_notifications_emails = toggle_unread_notifications_emails;
        vm.saveRoles = saveRoles;
        vm.sendNewPassword = sendNewPassword;

        vm.user_types = ['slate', 'vendor', 'qc'];
        vm.homepages = ['dashboard', 'vendors',];
        vm.login_reqs = ['okta', 'any'];

        function saveUserType() {
            vm.saving = true;
            vm.loginDetails.saveModel().then(function () {
                $timeout(function () { vm.saving = false; }, 300);
            });
        }

        function saveHomepage() {
            vm.saving = true;
            vm.loginDetails.saveModel().then(function () {
                $timeout(function () { vm.saving = false; }, 300);
            });
        }

        function saveLoginReq() {
            vm.saving = true;
            vm.loginDetails.saveModel().then(function () {
                $timeout(function () { vm.saving = false; }, 300);
            });
        }

        function toggle_login() {
            vm.saving = true;
            vm.loginDetails.is_active = !vm.loginDetails.is_active;
            vm.loginDetails.saveModel().then(function () {
                $timeout(function () { vm.saving = false; }, 300);
            });
        }

        function toggle_staff() {
            vm.saving = true;
            vm.loginDetails.is_staff = !vm.loginDetails.is_staff;
            vm.loginDetails.saveModel().then(function () {
                $timeout(function () { vm.saving = false; }, 300);
            });
        }

        function toggle_unread_notifications_emails() {
            vm.saving = true;
            vm.contact.receive_unread_notifications_emails = !vm.contact.receive_unread_notifications_emails;
            vm.contact.saveModel().then(function () {
                $timeout(function () { vm.saving = false; }, 300);
            });
        }

        function saveRoles() {
            vm.saving = true;
            vm.blanketRoles.saveModel().then(function () {
                $timeout(function () { vm.saving = false; }, 300);
            });
        }

        function sendNewPassword() {
            var apiUrl = API_URL + 'auth/send-new-password/';

            vm.sendingPassword = true;
            vm.success = null;
            vm.error = null;

            var promise = $http.post(apiUrl, { contact: vm.contact.id });
            promise.then(function () {
                vm.sendingPassword = false;
                vm.success = 'New password sent';
            }, function (response) {
                vm.sendingPassword = false;
                if (response.data.error) {
                    vm.error = response.data.error;
                } else {
                    vm.error = 'Error sending new password';
                }
            });
        }
    }

})();
