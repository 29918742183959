(function() {
    "use strict";

    angular
        .module('slate.approvals')
        .directive('slateApprovalStatus', ApprovalStatusDir);

    function ApprovalStatusDir() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/approvals/approvals.status.dir.html',
            scope: {
                parentType: '=',
                parentFk: '=',
                rootType: '=?',
                rootFk: '=?',
                companyFk: '=?',
                onApprove: '&',
                onReject: '&',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ListController($rootScope, $scope, $interval, SlateAuth, SlateApproval, SlateApprovalEdit) {
        /* jshint validthis: true */
        var vm = this;
        var timer;

        vm.isApprover = false;
        vm.approve = approve;
        vm.reject = reject;
        vm.approval = new SlateApproval();
        vm.approval.loadFromServer(vm.parentType, vm.parentFk, vm.rootType, vm.rootFk).then(function() {
            if(vm.approval.steps.current && SlateAuth.user.contact_id == vm.approval.steps.current.approver) {
                vm.isApprover = true;
            }
        });

        vm.editCycle = editCycle;

        function editCycle() {
            vm.isApprover = false;
            vm.shownotes = false;
            
            SlateApprovalEdit.edit(vm.approval, vm.companyFk, vm.rootType, vm.rootFk).then(function() {
                vm.approval.loadFromServer(vm.parentType, vm.parentFk, vm.rootType, vm.rootFk).then(function() {
                    if(vm.approval.steps.current && SlateAuth.user.contact_id == vm.approval.steps.current.approver) {
                        vm.isApprover = true;
                        $rootScope.$broadcast('approvalUpdated', {parentType: vm.parentType, parentFk: vm.parentFk});
                    }
                });

            });
        }

        function approve() {
            vm.isApprover = false;
            vm.shownotes = false;
            vm.approval.steps.current.approve().then(function() {
                $rootScope.$broadcast('approvalUpdated', {parentType: vm.parentType, parentFk: vm.parentFk});
                vm.approval.steps.getList().then(function() {
                    vm.approval.steps.updateCurrent();
                });
                vm.onApprove();
            });
        }

        function reject() {
            vm.isApprover = false;
            vm.shownotes = false;
            vm.approval.steps.current.reject().then(function() {
                $rootScope.$broadcast('approvalUpdated', {parentType: vm.parentType, parentFk: vm.parentFk});
                vm.approval.steps.getList().then(function() {
                    vm.approval.steps.updateCurrent();
                });
                vm.onReject();
            });
        }

        timer = $interval(function() {
            vm.approval.loadFromServer(vm.parentType, vm.parentFk, vm.rootType, vm.rootFk).then(function() {
                if(vm.approval.steps.current && SlateAuth.user.contact_id == vm.approval.steps.current.approver) {
                    vm.isApprover = true;
                }
            });
        }, 30000);

        $scope.$on("$destroy", function() {
            if (timer) {
                $interval.cancel(timer);
            }
        });
    }


})();
