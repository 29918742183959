(function () {
    "use strict";

    angular
        .module('slate.salesquotes')
        .controller('SlateSalesQuoteEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateSalesQuote) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.salesquote = new SlateSalesQuote();

        vm.salesquote.loadFromServer($stateParams.id).then(function () {
            if (vm.salesquote.name) {
                SlateTitle.set('Edit ' + vm.salesquote.name + ' - Sales Quotes');
            } else {
                SlateTitle.set('Edit Untitled Quote - Sales Quotes');
            }
            
            vm.salesquote.getFreightEstimate().then(function() {
                vm.loading = false;
            });

        }).catch(angular.noop);

        $scope.$watch('vm.salesquote.name', function () {
            if (vm.salesquote.name) {
                SlateTitle.set('Edit ' + vm.salesquote.name + ' - Sales Quotes');
            } else {
                SlateTitle.set('Edit Untitled Quote - Sales Quotes');
            }
        });

    }

})();
