(function() {
    "use strict";

    angular
        .module('slate.projects')
        .directive('slateProjectsComponentList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/projects/projects.component.list.dir.html',
            scope: {
                modelFilters: '=filters',
                variations: '=?',
                rootOnly: '=?',
                subview: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $q, $window, SlateAuth, SlateSettings, SlateTitle, SlateProjects, SlateProject, SlateProjectEdit, getLinkSrv, SlateDeleteObjSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;
        vm.get_url = get_url;
        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;

        vm.loading = true;
        vm.filters = {
            page: 1,
            count: 50,
        };

        vm.fields = [
            'id',
            'name',
            'slug',
            'job_number',
            'component_number',
            'variation_number',
            'composite_num',
            'client_item_number',
            'stock_component',
            'finished_good',
            'total_inventory',

            'created',
            'updated',

        ];

        angular.extend(vm.filters, {
            //vendor: $location.search().vendor || false,
            q: $location.search().q
        }, vm.modelFilters);
        if(vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if(vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }


        if(vm.filters.q === null) { vm.filters.q = ''; }

        if(!vm.modelFilters || (vm.modelFilters.length == 1 && typeof vm.modelFilters.root_only != 'undefined')) {
            SlateTitle.set('Projects');
        }

        SlateSettings.getConfig('material_categories').then(function(config) {
            vm.categories = config;
        });
        SlateSettings.getConfig('materials').then(function(config) {
            vm.material_list = config;
            getMatList();
        });
        vm.materials = [];

        vm.projectsList = new SlateProjects();

        vm.addNew = addNewProject;
        vm.editComponent = editComponent;
        vm.deleteProject = deleteProject;
        vm.updateFromFilter = updateFromFilter;

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function(value, key) {
                if(key == 'page' || key == 'count' || key == 'q') {
                    return false;
                }
                if(value === undefined || value === null || value === '') {
                    return false;
                }
                if(vm.filters[key] == vm.modelFilters[key]) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;

            });
            return hasData;
        }

        function updateFromFilter() {
            var modelPromise;

            angular.forEach(vm.filters, function(value, item) {
                if (vm.filters[item] && !vm.modelFilters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = vm.projectsList.getList(angular.extend({}, vm.filters,{
                //root_only: vm.rootOnly,
                fields: vm.fields
            }));
            modelPromise.then(function() {
                vm.loading = false;
            });

        }

        function addNewProject($event) {
            var newProject = new SlateProject();
            var promise;
            if (vm.modelFilters.company) {
                newProject.parent_type = 'company';
                newProject.parent_fk = vm.modelFilters.company;
            }
            if (vm.modelFilters.project) {
                newProject.parent_type = 'project';
                newProject.parent_fk = vm.modelFilters.project;
            }

            promise = SlateProjectEdit.edit(newProject, newProject.parent_type, newProject.parent_fk);
            promise.then(function(obj) {
                if(!obj.skip) {
                    $location.path(getLinkSrv.getUrl('project', newProject.id, newProject.slug)).search({});
                } else {
                    updateFromFilter();
                }

            }, function() {

            });
            return promise;
        }

        function editComponent(project) {
            var promise;
            promise = SlateProjectEdit.edit(project);
            promise.then(function(newProject) {
                if (newProject.slug != project.slug) {
                    $location.path(getLinkSrv.getUrl('project', newProject.id, newProject.slug)).search({});
                } else {
                    $state.reload();
                }
            }, function() {

            });
            return promise;
        }

        function deleteProject(project) {
            SlateDeleteObjSrv.delete('project', project.id).then(function() {
                updateFromFilter();
            });
        }

        function get_url() {
            var url =  API_URL + 'projects/';
            if($window.location.search.length ) {
                return url + $window.location.search + '&download=true';
            } else {
                return url + '?download=true';
            }
        }

        $scope.$watch('vm.filters.category', function() {
            getMatList();
        });

        function getMatList() {
            var newlist = [];
            if(!vm.material_list || ! vm.material_list.value) { return []; }
            angular.forEach(vm.material_list.value, function(item) {
                if(item.category == vm.filters.category) {
                    newlist.push(item);
                }
            });
            newlist = newlist.map(function(a) {return a.name;});
            vm.materials = newlist;
            return newlist;
        }

    }


})();
