(function() {
    "use strict";

    angular
        .module('slate.reports')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('reportsList', {
                url: '/reports/',
                templateUrl: '/partials/reports/reports.list.page.html',
                controller: 'SlateReportsPageController',
                controllerAs: 'vm',
                current_app: 'reports',
                slateTitle: 'Reports',
            })
            .state('reportsFilters', {
                url: '/reports/:report/',
                templateUrl: '/partials/reports/reports.list.page.html',
                controller: 'SlateReportsPageController',
                controllerAs: 'vm',
                current_app: 'reports',
                slateTitle: 'Reports',
            })
        ;
    }
})();
