(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetSalesquotes', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.salesquotes.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $interval, SlateSalesQuotes, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var interval = null;
        var speed = vm.settings.speed || 60000;
        vm.getLink = getLinkSrv;
        vm.salesQuotes = new SlateSalesQuotes();

        function getData() {
            var filters = {};
            if (vm.settings.mine) { filters.selfonly = true; }
            if (vm.settings.status) { filters.status = vm.settings.status; }
            vm.salesQuotes.getList(filters);
        }

        // getData();

        $scope.$watch('vm.settings', function(items) {
            getData();
        }, true);

        interval = $interval(function() {
            getData();
        }, speed);

        $scope.$on('$destroy', function() {
            if (angular.isDefined(interval)) {
                $interval.cancel(interval);
                interval = undefined;
            }
        });

    }


})();
