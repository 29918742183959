(function() {
    "use strict";

    angular
        .module('slate.workflows')
        .service('SlateWorkflowEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateWorkflow) {
        var templateUrl = '/partials/workflows/workflows.edit.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                //size: 'lg',
                resolve: {
                    obj: function() {
                        var newObj = new SlateWorkflow();
                        newObj.loadFromPayload(obj);
                        return newObj;
                    }
                }
            });

            return modalInstance.result.then(function(results) {
                return results;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($uibModalInstance, SlateAssignedRoles, obj) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.wizardControl = {};

        vm.detailsIsComplete = detailsIsComplete;

        vm.desiredByIsOpen = false;
        vm.desiredByOpen = desiredByOpen;

        vm.assroles = new SlateAssignedRoles();
        vm.addRole = addRole;

        vm.updateCheckboxes = updateCheckboxes;
        vm.removeCheckbox = removeCheckbox;

        vm.forms = forms;

        vm.dragOptions = {
            handle: ".handle",
            //placeholder: "beingDragged",
        };


        if(vm.obj.id) {
            vm.assroles.getList({
                parent_type: 'milestone',
                parent_fk: vm.obj.id
            }).then(function() {

            });
        }

        function addRole() {
            vm.assroles.addBlank({
                parent_type: 'milestone',
                parent_fk: vm.obj.id
            });
        }

        function removeCheckbox(index) {
            vm.obj.checkboxes.splice(index, 1);
        }

        function updateCheckboxes() {
            vm.obj.checkboxes = vm.form.fields;
            vm.form = {};
        }

        function saveObject() {
            if (vm.saving === true) {
                return false;
            }
            vm.saving = true;

            vm.obj.saveModel().then(function() {
                vm.assroles.saveAll({parent_fk: vm.obj.id, parent_type: 'milestone'}).then(function() {
                    vm.saving = false;
                    $uibModalInstance.close(vm.obj);
                }, function(response) {
                    
                });
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }


        function detailsIsComplete() {
            if (!vm.obj.name) {
                return false;
            }
            return true;
        }

        function desiredByOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.desiredByIsOpen = true;
        }
    }

    /* beautify preserve:start */
    var forms = [
        {
            name: 'Basic QC',
            fields: [
                {text:"Check This", value:false},
                {text:"Check That", value:false},
                {text:"and stuff", value:false},
            ]
        },
        {
            name: 'Client Specific QC',
            fields: [
                {text:"Check This", value:false},
                {text:"Check That", value:false},
                {text:"and stuff", value:false},
                {text:"Lables 15mm border", value:false},
                {text:"blue is 150% more blue", value:false},
                {text:"logo pops", value:false},


            ]
        },
    ];
    /* beautify preserve:end */


})();
