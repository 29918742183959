(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .controller('SlateRfqResponsePageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $timeout, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateAuth, SlateRFQ, SlateRFQResponse, SlateShortRFQResponses) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;
        vm.loading = true;

        vm.response = new SlateRFQResponse();
        vm.rfq = new SlateRFQ();

        vm.getCurrentIndex = getCurrentIndex;
        vm.prevResponse = prevResponse;
        vm.nextResponse = nextResponse;
        vm.isFirst = isFirst;
        vm.isLast = isLast;

        vm.baseStatus = '';
        vm.canApprove = canApprove;
        vm.canSaveAcceptance = canSaveAcceptance;
        vm.saveAcceptance = saveAcceptance;
        vm.accept = accept;
        vm.reject = reject;

        function getData(id) {
            vm.loading = true;
            vm.response = new SlateRFQResponse();
            vm.response.loadFromServer(id).then(function (response) {
                vm.baseStatus = vm.response.status;
                if (vm.response.rfq && vm.response.rfq.name) {
                    SlateTitle.set('View ' + vm.response.rfq.name + ' - RFQ Responses');
                } else {
                    SlateTitle.set('View Untitled RFQ - RFQ Responses');
                }
                $state.transitionTo(
                    'rfqResponsePage',
                    {id: vm.response.id},
                    {location: 'replace'}
                );
                vm.loading = false;
                if(!vm.rfq.id) {
                    vm.rfq.loadFromServer(vm.response.rfq);
                }
            });

        }
        getData($stateParams.id);

        function prevResponse() {
            var cur_idx = getCurrentIndex(vm.response.id);
            if(cur_idx == 0) { return false; }
            getData(vm.rfq.responses[cur_idx - 1].id);
        }

        function nextResponse() {
            var cur_idx = getCurrentIndex(vm.response.id);
            if(cur_idx == vm.rfq.responses.length - 1) { return false; }
            getData(vm.rfq.responses[cur_idx + 1].id);
        }

        function isFirst() {
            if(!vm.rfq.id) { return false; }
            return vm.rfq.responses[0].id == vm.response.id;
        }

        function isLast() {
            var lastIdx;
            if(!vm.rfq.id) { return false; }
            lastIdx = vm.rfq.responses.length - 1;
            return vm.rfq.responses[lastIdx].id == vm.response.id;
        }

        function getCurrentIndex(id) {
            if(!vm.rfq.id) { return -1; }

            for (var i = 0; i < vm.rfq.responses.length; i += 1) {
                if (vm.rfq.responses[i].id === id) {
                    return i;
                }
            }
            return -1;
        }

        function canApprove() {
            if(!['ready'].includes(vm.baseStatus)) { return false; }
            if(vm.auth.user.contact_id == vm.rfq.created_by) { return true; }
            if(vm.auth.user.contact_id == vm.rfq.sales_mgr) { return true; }
            if(vm.auth.user.contact_id == vm.rfq.proj_mgr) { return true; }
            return false;
        }

        function canSaveAcceptance() {
            if(vm.saving) { return false; }
            if(vm.response.status != 'accepted' && vm.response.status != 'rejected') { return false; }
            if(vm.response.status == 'rejected' && !vm.response.rejection_reason) { return false; }
            return true;
        }

        function accept() {
            vm.response.status = 'accepted';
        }

        function reject() {
            vm.response.status = 'rejected';
        }

        function saveAcceptance() {
            vm.saving = true;
            vm.response.saveModel().then(function() {
                $timeout(function() {
                    vm.baseStatus = vm.response.status;
                    vm.saving = false;
                }, 300);

            });
        }
    }

})();
