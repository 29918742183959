(function () {
    "use strict";

    angular
        .module('slate.qima')
        .directive('slateBookingProductRefList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/qima/booking.productrefs.list.html',
            scope: {
                booking: '=',
                hidenew: '=?',
                showTotals: '=?',
                fileSrv: '=?',
                fields: '=?',
                projectId: '=?',
                dirty: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlateInspectionLineItems, SlateInspectionResultNewSrv, SlateInspectionLineItemEditSrv) {
        /* jshint validthis: true */
        var vm = this;

        if (!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'jansy_po',
                'sku_code',
                'name',
                'qty',
                // 'qc_date',
            ];
            /* beautify preserve:end */
        }



    }


})();
