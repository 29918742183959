(function () {
    'use strict';

    angular
        .module('slate.discussion')
        .directive('slateDiscussion', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/discussion/discussion.box.dir.html',
            scope: {
                parentType: '=',
                parentFk: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $interval, $state, $location, $q, SlateAuth, getLinkSrv, SlateDiscussion, SlateDiscussionMsgs, SlateDiscussionMsg) {
        /* jshint validthis: true */
        const vm = this;
        let timer;

        vm.getLinkSrv = getLinkSrv;

        vm.loading = true;
        vm.addMsg = addMsg;
        vm.message = '';
        vm.message_urgent = false;

        vm.auth = SlateAuth;

        vm.froalaOptions = {
            toolbarButtons: [
                'bold',
                'italic',
                'underline',
                'fontSize',
                '|',
                'align',
                'formatOL',
                'formatUL',
                '|',
                'quote',
                'insertTable',
                '|',
                'insertLink',
            ],
        };

        vm.discussion = new SlateDiscussion();
        const modelPromise = vm.discussion.loadFromServer({ parent_fk: vm.parentFk, parent_type: vm.parentType });

        vm.msgs = new SlateDiscussionMsgs();

        modelPromise.then(() => {
            vm.msgs.getList({ discussion: vm.discussion.id }).then(() => {
                vm.loading = false;
                timer = $interval(() => {
                    if (!vm.auth.windowFocus) { return false; }
                    return vm.msgs.getList({ discussion: vm.discussion.id });
                }, 120000);
            });
        });


        function addMsg() {
            const msg = new SlateDiscussionMsg();
            msg.discussion = vm.discussion.id;
            msg.message = vm.message;
            msg.urgent = vm.message_urgent;

            msg.saveModel().then(() => {
                vm.msgs.getList();
            });
            vm.message = '';
            vm.message_urgent = false;
        }

        $scope.$on('$destroy', () => {
            if (timer) {
                $interval.cancel(timer);
            }
        });
    }
})();
