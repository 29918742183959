(function () {
    'use strict';

    angular
        .module('slate.companies')
        .directive('slateCompaniesList', slateCompaniesList);

    function slateCompaniesList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/companies/companies.list.dir.html',
            scope: {
                vendors: '=?',
                fields: '=?',
                modelFilters: '=?filters'
            },
            controller: companiesListController,
            controllerAs: 'vm',
            link: companiesListLinker

        };
    }

    function companiesListController($scope, $state, $location, $q, SlateAuth, SlateDeleteObjSrv, SlateTitle, SlateCompanies, SlateCompany, SlateCompanyEdit, getLinkSrv) {
        /* jshint validthis: true */
        const vm = this;
        vm.showFilters = false;
        vm.countries = Countries;

        vm.auth = SlateAuth;

        vm.pagerOptions = {
        };

        vm.filters = angular.extend($scope.modelFilters || {}, { page: 1, count: 50 }, $location.search());

        vm.fields = $scope.fields || ['prefix',
            'name',
            'country',
            'updated',
            'on_hold',
            'vendor',
            'material_categories'];

        vm.isVendorList = $state.current.name === 'vendorList';

        if (!vm.isVendorList) {
            $scope.$watch('vm.companiesFilter', () => {
                if (!vm.companiesFilter) {
                    vm.filters.vendor = null;
                    // vm.filters.qualified = null;
                    vm.filters.customers = null;
                } else if (vm.companiesFilter === 'vendors') {
                    vm.filters.vendor = true;
                    // vm.filters.qualified = vm.filters.qualified || false;
                    vm.filters.customers = null;
                } else if (vm.companiesFilter === 'customers') {
                    vm.filters.vendor = null;
                    // vm.filters.qualified = null;
                    vm.filters.customers = true;
                }
            });
        }

        SlateTitle.set('Companies');

        vm.companiesList = new SlateCompanies();

        vm.companies = [];

        vm.addNewCo = addNewCo;
        vm.deleteCo = deleteCo;
        vm.updateFromFilter = updateFromFilter;

        vm.hasFilterData = hasFilterData;

        if (vm.filters.page) { vm.filters.page = parseInt(vm.filters.page, 10); }
        if (vm.filters.count) { vm.filters.count = parseInt(vm.filters.count, 10); }

        $scope.$watchCollection(() => vm.filters, (value) => {
            updateFromFilter();
        });

        function updateFromFilter() {
            vm.loading = true;

            angular.forEach(vm.filters, (value, item) => {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });
            const req_filters = Object.assign({}, vm.filters, { fields: ['id', 'slug', ...vm.fields, 'brands'] });
            const modelPromise = vm.companiesList.getList(req_filters);
            modelPromise.then(() => {
                vm.loading = false;
            });
        }

        function hasFilterData() {
            if (vm.filters.vendor) { return true; }
            if (vm.filters.qualified) { return true; }
            if (vm.filters.preferred) { return true; }
            if (vm.filters.country) { return true; }
            return false;
        }

        function addNewCo($event) {
            const newCompany = new SlateCompany();
            newCompany.contactmethods.addBlank({ method: 'phone', is_primary: true });
            newCompany.contactmethods.addBlank({ method: 'url', is_primary: true });
            const promise = SlateCompanyEdit.edit(newCompany);
            promise.then(() => {
                $location.path(getLinkSrv.getUrl('company', newCompany.id, newCompany.slug)).search({});
            }, () => {

            });
            return promise;
        }

        function deleteCo(co) {
            SlateDeleteObjSrv.delete('company', co.id).then(() => {
                updateFromFilter();
            });
        }
    }

    function companiesListLinker(scope, elem, attrs) {

    }
})();
