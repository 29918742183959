(function() {
    "use strict";

    angular
        .module('slate.approvals')
        .directive('slateMyApprovalsList', slateApprovalsList);

    function slateApprovalsList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/approvals/approvals.list.dir.html',
            scope: {
                
            },
            controller: listController,
            controllerAs: 'vm',
            
        };
    }

    function listController($scope, $state, $stateParams, $location, $q, SlateAuth, SlateTitle, SlateApproval, SlateApprovalSteps, SlateApprovalStep, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;
        
        vm.auth = SlateAuth;

        vm.bulkApprove = bulkApprove;
        vm.bulkReject = bulkReject;

        vm.getLinkSrv = getLinkSrv;
        vm.filters = {};
        if(vm.auth.user.permissions.approvals_can_view_other && $stateParams.userid) {
            vm.filters.userid = $stateParams.userid;
        }
        vm.approvalsList = new SlateApprovalSteps();
        modelPromise = vm.approvalsList.getMyList(vm.filters);
        
        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            vm.approvalsList.getMyList(vm.filters);
        });


        function bulkApprove() {
            var promises = [];
            angular.forEach(vm.approvalsList.list, function(item) {
                if(item.bulk) { promises.push(item.approve()); }
            });
            $q.all(promises).then(function() {
                vm.approvalsList.getMyList(vm.filters);
            });
        }
        function bulkReject() {
            var promises = [];
            angular.forEach(vm.approvalsList.list, function(item) {
                if(item.bulk) { promises.push(item.reject()); }
            });
            $q.all(promises).then(function() {
                vm.approvalsList.getMyList(vm.filters);
            });
        }

    }


})();
