(function () {
    'use strict';

    angular
        .module('slate.logistics')
        .directive('slateShipmentSearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/logistics/shipment.searchbox.dir.html',
            scope: {
                shipment: '=',
                company: '=?',
                salesorder: '=?',
                readonly: '<?',
                placeholder: '<?',
                showDropdown: '=?',
                shipmentObj: '=?'
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function directiveController($scope, $state, $location, $q, getLinkSrv, SlateShipment, SlateShipments) {
        /* jshint validthis: true */
        const vm = this;
        let selectedShipment = '';

        vm.shipments = new SlateShipments();
        vm.coShipments = new SlateShipments();

        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.selectCoShipment = selectCoShipment;
        vm.getShipmentName = getShipmentName;
        vm.clear = clear;

        if (vm.shipment) {
            const shipment = new SlateShipment();
            shipment.loadFromServer(vm.shipment, true, true).then(() => {
                vm.shipmentObj = shipment;
                selectedShipment = shipment.id;
            });
        }

        function getDropdown() {
            const filters = {};
            if (vm.company && vm.showDropdown) {
                filters.company = vm.company;
            }
            if (vm.salesorder && vm.showDropdown) {
                filters.salesorder = vm.salesorder;
            }
            if (!Object.keys(filters).length) { return false; }
            return vm.coShipments.getList(filters, true);
        }

        function getShipmentName(shipment) {
            return `SID# ${shipment.shipment_number} - ${shipment.name}`;
        }

        $scope.$watch('vm.company', () => { getDropdown(); });

        function getSearch(q) {
            const filters = { q };
            if (vm.company) {
                filters.company = vm.company;
            }
            if (vm.salesorder) {
                filters.salesorder = vm.salesorder;
            }

            if (q && q.length < 1) { return $q.reject([]); }
            return vm.shipments.getList(filters, true).then((response) => vm.shipments.list);
        }

        function selectSearch($item, $model, $label, $event) {
            vm.searching = false;
            selectedShipment = $item.id;
            vm.shipment = $item.id;
        }

        function clear() {
            if (vm.readonly) { return true; }
            vm.shipment = null;
            vm.shipmentObj = null;
            return null;
        }

        function selectCoShipment(shipment) {
            vm.searching = false;
            vm.shipmentObj = shipment;
            selectedShipment = shipment.id;
            vm.shipment = shipment.id;
        }

        $scope.$watch('vm.shipment', () => {
            if (selectedShipment !== vm.shipment && vm.shipment) {
                const shipment = new SlateShipment();
                shipment.loadFromServer(vm.shipment, true, true).then(() => {
                    vm.shipmentObj = shipment;
                    selectedShipment = shipment.id;
                });
            }
        });
    }
})();
