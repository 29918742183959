(function () {
    "use strict";

    angular
        .module('slate.datelog')
        .service('SlateDateLogList', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings) {
        var templateUrl = '/partials/datelog/datelog.list.dir.html';

        var srv = {
            popout: popout,
            parent_fk: '',
            parent_type: '',
        };


        return srv;


        function popout() {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    parentFk: function() { return srv.parent_fk; }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateAuth, SlateDateChanges, SlateDateLogList, parentFk) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

        vm.parentFk = parentFk;
        vm.cancel = cancel;

        vm.dateLog = SlateDateLogList;
        vm.dateChanges = new SlateDateChanges();

        vm.fields = [
            'date_field',
            'orig_date',
            'new_date',
            'created',
            'created_by',
        ];

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function updateFromFilter() {
            vm.loading = true;

            vm.dateChanges.getList({parent_fk: vm.parentFk}).then(function() {
                vm.loading = false;
            }, function() {
                vm.loading = false;
            });
        }
        updateFromFilter();


    }



})();
