(function () {
    'use strict';

    angular
        .module('slate.synergy')
        .controller('SlateSynergyEmailEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateSynergyEmail) {
        /* jshint validthis: true */
        const vm = this;
        vm.loading = true;
        vm.email = new SlateSynergyEmail();

        vm.email.loadFromServer($stateParams.id).then(() => {
            vm.loading = false;
        }).catch(angular.noop);
    }
})();
