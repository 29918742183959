(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportWeeklySchedule', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.weekly_schedule.dir.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $q, $http, $location, $httpParamSerializer) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'reports/weekly_schedule/';

        var modelPromise;
        var canceller = null;

        vm.getDownloadUrl = getDownloadUrl;
        vm.updateFromFilter = updateFromFilter;

        vm.loading = true;
        vm.data = [];
        vm.filters = {};

        angular.extend(vm.filters, $location.search());

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        $scope.$watch('vm.start', function () {
            if (vm.start) {
                vm.filters.startDate = dateFns.format(vm.start, 'YYYY-MM-DD');
            } else {
                vm.filters.startDate = null;
            }
        });
        $scope.$watch('vm.end', function () {
            if (vm.end) {
                vm.filters.endDate = dateFns.format(vm.end, 'YYYY-MM-DD');
            } else {
                vm.filters.endDate = null;
            }
        });

        function updateFromFilter() {
            vm.loading = true;

            if (canceller) { canceller.resolve(); }
            canceller = $q.defer();

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
                timeout: canceller.promise
            });

            modelPromise.then(function(resp) {
                vm.data = resp.data.payload;
                vm.loading = false;
                canceller = null;
            });
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }
    }
})();