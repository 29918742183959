(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportBommargin', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/reports/reports.report.bommargin.dir.html',
            scope: {
                filters: '<',
            },
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $state, $location, $http, $q, $stateParams, $httpParamSerializer, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.linksrv = getLinkSrv;

        var now = new Date();
        var endLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
        var twomonthsago = new Date(now.getFullYear(), now.getMonth() - 4, 1);

        var modelPromise;
        var rawData = {};

        vm.data = {};
        vm.getDownloadUrl = getDownloadUrl;

        var apiUrl = API_URL + 'reports/bom_margins/';

        vm.loading = true;
        vm.pagerOptions = {};
        if (!vm.filters) {
            vm.filters = {
                startDate: twomonthsago,
                endDate: endLastMonth
            };
        }

        vm.filters = angular.extend(vm.filters, {

        });

        vm.getTotalCost = getTotalCost;
        vm.getTotalSell = getTotalSell;
        vm.getTotalMargin = getTotalMargin;

        vm.startDateIsOpen = false;
        vm.startDateOpen = startDateOpen;

        vm.endDateIsOpen = false;
        vm.endDateOpen = endDateOpen;

        function startDateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.startDateIsOpen = true;
        }

        function endDateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.endDateIsOpen = true;
        }

        // updateFromFilter();

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        function updateFromFilter() {
            var newData = [];
            if(!vm.filters.company && !vm.filters.sales_mgr) { vm.loading = false; return false; }
            vm.loading = true;

            modelPromise = $http.get(apiUrl, {
                params: vm.filters
            });

            modelPromise.then(function(resp) {
                vm.data = resp.data.payload;
                vm.loading = false;
            });
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }

        function getTotalCost() {
            var total = Big(0.0);
            vm.data.forEach(function (item) {
                total = total.add(Big(item.bom_price).times(item.qty));
            });
            return total.toFixed(5);
        }

        function getTotalSell() {
            var total = Big(0.0);
            vm.data.forEach(function (item) {
                total = total.add(Big(item.sellprice).times(item.qty));
            });
            return total.toFixed(5);
        }

        function getTotalMargin() {
            var sell = getTotalSell();
            var cost = getTotalCost();
            var total = Big(0.0);
            if(sell == 0.00000) { return 0.0; }
            total = total.add(sell).minus(cost);
            total = total.div(sell);
            total = total.times(100.0);
            return total.toFixed(2);
        }


    }


})();
