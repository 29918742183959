// import { request } from "http";

(function () {
    'use strict';

    angular
        .module('slate.library.purchaseorders')
        .factory('SlatePurchaseOrders', ModelList)
        .factory('SlatePurchaseOrder', ModelDetail);

    const apiUrl = `${API_URL}purchaseorders/po/`;

    function ModelList($http, $q, SlatePurchaseOrder) {
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        self.pagination = {
            total: 0,
            page: 1,
            count: 25
        };
        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let promise;
            const promises = [];

            if (self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters,
                timeout: self.canceller.promise
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlatePurchaseOrder();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.pagination = {
                    total: parseInt(response.data.pagination.total),
                    page: parseInt(response.data.pagination.page),
                    count: parseInt(response.data.pagination.count)
                };
                self.permissions = response.data.permissions;
                self.canceller = null;

                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q, SlatePOLineItem) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', def: null, readonly: true },
            { name: 'name' },
            { name: 'status', def: 'Draft' },
            { name: 'version', readonly: true },

            { name: 'salesorder' },
            { name: 'salesorder_info', readonly: true },

            { name: 'company' },
            { name: 'company_info', readonly: true },
            { name: 'vendor' },
            { name: 'vendor_info', readonly: true },

            { name: 'project' },
            { name: 'project_info', readonly: true },
            { name: 'order' },
            { name: 'order_info', readonly: true },

            { name: 'proj_mgr' },
            { name: 'proj_mgr_info', readonly: true },
            { name: 'sales_mgr' },
            { name: 'sales_mgr_info', readonly: true },

            { name: 'jansy_po' },
            { name: 'client_po' },

            { name: 'date', type: 'dateOnly' },
            { name: 'reorder' },
            { name: 'revision', def: false },
            { name: 'pi_required' },
            { name: 'carton_marking_template' },
            { name: 'carton_marking_template_info', readonly: true },

            { name: 'warehouse' },
            { name: 'warehouse_info', readonly: true },

            { name: 'shipvia' },
            { name: 'ship_terms' },

            { name: 'ship_company' },
            { name: 'ship_co_name' },
            { name: 'ship_street' },
            { name: 'ship_state' },
            { name: 'ship_postal' },
            { name: 'ship_phone' },
            { name: 'ship_country', def: 'United States' },
            { name: 'ship_city' },

            { name: 'terms', def: [], type: 'json' },
            { name: 'taxable' },

            { name: 'desired_date', type: 'dateOnly' },

            { name: 'freight_cost' },
            { name: 'est_shipping_cost', def: 0.0 },
            { name: 'price_total', readonly: true, def: 0.0 },
            { name: 'sell_total', readonly: true, def: 0.0 },
            { name: 'margin', readonly: true, def: 0.0 },

            { name: 'fully_received', readonly: true, def: false },

            { name: 'notes' },
            { name: 'revision_notes' },
            { name: 'datelog_notes', type: 'json', def: {} },

            { name: 'line_items', type: 'child_list', model: SlatePOLineItem },

            { name: 'created', readonly: true },
            { name: 'created_by', readonly: true },
            { name: 'updated', readonly: true },
            { name: 'deleted', readonly: true, default: false },

            { name: 'revised', def: [{}], type: 'json' }
        ];
        /* beautify preserve:end */

        /* beautify preserve:start */
        model.prototype.shipTypesOld = ['Ocean',
            'Air',
            'Truck',
            'DHL',
            'UPS',
            'FedEX',
            'USPS',
            'Other'];
        model.prototype.shipTypes = ['oceanlcl',
            'oceanfcl',
            'oceanexp',
            'air',
            'truck',
            'ups',
            'fedex',
            'dhl',
            'other'];
        model.prototype.termsTypesOld = ['Ex-Works',
            'DDU',
            'FOB',
            'CIF',
            'FCA',
            'DAT',
            'DDP',
            'Other'];
        model.prototype.termsTypes = ['exw',
            'fca',
            'fob',
            'cif',
            'cpt',
            'cip',
            'dat',
            'dap',
            'ddp',
            'ddu',
            'other'];
        model.prototype.freightFavorites = [
            { name: 'Freight CO', value: '123 any st, ste 3\nChicago IL, 60610' }, { name: 'Warehouse INC', value: 'attn: Frank\n545 Industry St\nunit 12\nLos Angeles CA, 92111' }
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.getCompositeName = getCompositeName;
        return model;

        function getCompositeName() {
            /* jshint validthis: true */
            const self = this;
            return `PO#${self.jansy_po} - ${self.name}`;
        }

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;
                if (field.type == 'child_list') {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                if (!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if (field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if (field.type == 'dateOnly' && self[field.name] && typeof self[field.name] === 'string') {
                    const parts = self[field.name].split('-');
                    self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
                }

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                        // do nothing
                    }
                }

                if (field.type == 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach((item, idx, array) => {
                            const obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch (e) {
                        console.log('error loading: ', e);
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            let promise;

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then((response) => {
                const promises = [];

                return self.loadFromPayload(response.data.payload).then(() => {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;
            const promises = [];
            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (data[field.name] && field.type == 'date') {
                        data[field.name] = `${self[field.name].getUTCFullYear()}-${self[field.name].getUTCMonth() + 1}-${self[field.name].getUTCDate()}`;
                    }
                    if (field.type == 'dateOnly' && self[field.name]) {
                        data[field.name] = `${self[field.name].getFullYear()}-${self[field.name].getMonth() + 1}-${self[field.name].getDate()}`;
                    }
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if (field.type == 'child_list' && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach((item) => {
                            if (item.toDelete) {
                                promises.push(item.deleteModel());
                                return false;
                            }
                            data[field.name].push(item.getModelData());
                        });
                    }
                }
            });

            if (data.name) {
                data.name = data.name.substring(0, 72);
            }

            return $q.all(promises).then(() => {
                let promise = $http.post(modelUrl, data);
                promise = promise.then((response) => {
                    self.loadFromPayload(response.data.payload);
                    self.permissions = response.data.permissions;
                });
                return promise;
            });
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }
})();
