(function() {
    "use strict";

    angular
        .module('slate.schedule')
        .directive('slateScheduleGrid', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/schedule/schedule.grid.dir.html',
            scope: {
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, SlateTitle, SlateAuth, SlateRolesSrv, SlateScheduleGridSrv) {
        /* jshint validthis: true */
        var vm = this;
        var rolesFromSearch = $location.search().roles;
        var filterDefaults = {
            roles: rolesFromSearch || [],
        };

        vm.roles = SlateRolesSrv;
        vm.gridSrv = SlateScheduleGridSrv;
        

        if (!rolesFromSearch || !rolesFromSearch.length) {
            rolesFromSearch = SlateAuth.user.groups;
        }
        if (typeof(rolesFromSearch) == 'string') {
            filterDefaults.roles = [];
            filterDefaults.roles.push(vm.roles.byName[rolesFromSearch].id);
        } else {
            filterDefaults.roles = [];
            angular.forEach(rolesFromSearch, function(item) {
                filterDefaults.roles.push(vm.roles.byName[item].id);
            });
        }

        vm.filters = angular.extend({}, vm.modelFilters, filterDefaults);

        updateFromFilters();

        $scope.$watch('vm.filters', function() {
            var roleNames = [];

            angular.forEach(vm.filters.roles, function(item) {
                roleNames.push(vm.roles.dict[item].name);
            });
            $location.search({
                'roles': roleNames
            });
            updateFromFilters();
        }, true);

        function updateFromFilters() {
            //vm.gridSrv.setRoles(vm.filters.roles);
            vm.gridSrv.filters = vm.filters;
            vm.gridSrv.update();
        }




    }


})();
