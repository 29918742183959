(function() {
    "use strict";

    angular
        .module('slate.rfqs')
        .directive('slateRfqItemPrices', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/rfqs/rfq.lineitems.prices.dir.html',
            scope: {
                itemId: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $window, $state, $location, $q, $timeout, SlateRFQLineItems, SlateRFQLineItem, SlateRFQLineItemEditSrv, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;

        vm.loading = false;
        vm.data = [];

        function getData() {
            var apiUrl = API_URL + 'rfq/rfq-item-prices/' + vm.itemId + '/';
            if(!vm.itemId) { return false; }

            $http.get(apiUrl).then(function(response) {
                vm.data = response.data.payload;
                vm.loading = false;
            });
        }
        $scope.$watch('vm.itemId', function (newVal, oldVal) {
            if(newVal != oldVal && vm.itemId) {
                getData();
            }
        });
        getData();

    }


})();
