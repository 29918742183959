/* eslint-disable no-param-reassign */
(function () {
    'use strict';

    angular
        .module('slate.salesbudgets')
        .directive('slateSalesBudgetsBulk', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/salesbudgets/salesbudgets.bulk.page.html',
            scope: {

            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ListController($scope, $http, $location, $q, getLinkSrv, SlateAuth, SlateContacts, SlateCompanies, SlateSalesBudgets, SlateSalesBudgetEditDialog, SlateSalesNewBusinesses, SlateNewBusinessEditDialog) {
        /* jshint validthis: true */
        const vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.sales_staff = new SlateContacts();
        vm.companies = new SlateCompanies();
        vm.budgets = new SlateSalesBudgets();
        vm.newbusiness = new SlateSalesNewBusinesses();

        vm.save = save;
        vm.approve = approve;
        vm.getUrl = getUrl;
        vm.getHistoricalUrl = getHistoricalUrl;

        vm.requestBudgetSalesMgr = requestBudgetSalesMgr;
        vm.requestBudgetCompany = requestBudgetCompany;
        vm.requestBudgetAll = requestBudgetAll;

        vm.editBudget = editBudget;
        vm.editNewBusiness = editNewBusiness;

        vm.getSalesMgrTotal = getSalesMgrTotal;
        vm.getOverallTotals = getOverallTotals;
        vm.getFinalTotal = getFinalTotal;

        vm.default_filters = {
            company: '',
            sales_mgr: '',
        };

        vm.start_date = new Date();
        if (vm.start_date.getMonth() < 7) {
            vm.start_date = new Date(vm.start_date.getFullYear(), 0, 1);
        } else {
            vm.start_date = new Date(vm.start_date.getFullYear() + 1, 0, 1);
        }
        vm.months = [];


        vm.url_filter_fields = [ // these are valid url search parameters
            'sales_mgr', 'company', 'start_date',
        ];
        vm.date_fields = ['start_date'];
        const searchParams = $location.search();

        vm.url_filters = {};
        vm.url_filter_fields.forEach((item) => {
            if (searchParams.hasOwnProperty(item)) {
                vm.url_filters[item] = searchParams[item];
            }
        });
        vm.filters = Object.assign({}, vm.default_filters, vm.url_filters);
        vm.date_fields.forEach((dateField) => {
            if (vm.filters[dateField]) {
                const parts = vm.filters[dateField].split('-');
                vm[dateField] = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
            }
            $scope.$watch(`vm.${dateField}`, () => {
                if (vm[dateField]) {
                    vm.filters[dateField] = `${vm[dateField].getFullYear()}-${vm[dateField].getMonth() + 1}-${vm[dateField].getDate()}`;
                } else {
                    vm.filters[dateField] = null;
                }
            });
        });

        if (!vm.auth.perms.salesbudgets_can_approve) {
            vm.filters.sales_mgr = vm.auth.user.contact_id;
        }

        $scope.$watch('vm.start_date', () => {
            const dateField = 'start_date';

            if (vm.start_date.getDate() !== 1) {
                vm.start_date = new Date(vm.start_date.getFullYear(), vm.start_date.getMonth(), 1);
                return false;
            }
            vm.months = getDates();
            vm.filters[dateField] = `${vm[dateField].getFullYear()}-${vm[dateField].getMonth() + 1}-${vm[dateField].getDate()}`;
            loadBudgets();
            return true;
        });

        $scope.$watch('vm.filters.company', () => {
            loadBudgets();
        });

        $scope.$watch('vm.filters.sales_mgr', (new_val, old_val) => {
            loadCompanies();
            loadBudgets();
            if (old_val && new_val !== old_val) {
                vm.filters.company = '';
            }
        });

        function loadCompanies() {
            if (!vm.filters.sales_mgr) {
                vm.companies.list = [];
                return false;
            }
            return vm.companies.getList({ sales_mgr: vm.filters.sales_mgr });
        }

        function loadBudgets() {
            vm.loading = true;
            const filters = {
                active: true,
                start_date: `${vm.start_date.getFullYear()}-${vm.start_date.getMonth() + 1}-01`,
            };

            angular.forEach(vm.filters, (value, item) => {
                if (value === '') { vm.filters[item] = null; }
                if (vm.filters[item]) {
                    if (vm.filters[item] instanceof Date) {
                        $location.replace().search(item, vm.filters[item].toISOString());
                    } else {
                        $location.replace().search(item, vm.filters[item]);
                    }
                } else {
                    $location.replace().search(item, null);
                }
            });

            if (vm.filters.company) { filters.company = vm.filters.company; }
            if (vm.filters.sales_mgr) { filters.sales_mgr = vm.filters.sales_mgr; }
            vm.budgets.getList(filters).then(() => {
                vm.newbusiness.getList(filters).then(() => {
                    vm.loading = false;
                });
            });
        }

        function save() {
            vm.saving = true;

            vm.budget.saveModel().then(() => {
                vm.saving = false;
            }, () => {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function approve(company_id) {
            const promises = [];
            vm.approving = true;

            vm.budgets.list.forEach((budget) => {
                if (budget.company !== company_id) { return false; }
                budget.approved = true;
                promises.push(budget.saveModel());
                return true;
            });

            return $q.all(promises).then(() => {
                vm.approving = false;
                loadBudgets();
            });
        }

        function requestBudgetCompany(company_id) {
            const apiUrl = `${API_URL}salesbudgets/request-new/`;
            vm.saving = true;

            if (!company_id) { company_id = vm.filters.company; }

            return $http.post(apiUrl, { company: company_id, start: `${vm.start_date.getFullYear()}-${vm.start_date.getMonth() + 1}` }).then(() => {
                vm.saving = false;
                loadBudgets();
            }, () => {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function requestBudgetSalesMgr() {
            const apiUrl = `${API_URL}salesbudgets/request-new/`;
            vm.saving = true;

            return $http.post(apiUrl, { sales_mgr: vm.filters.sales_mgr, start: `${vm.start_date.getFullYear()}-${vm.start_date.getMonth() + 1}` }).then(() => {
                vm.saving = false;
                loadBudgets();
            }, () => {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function requestBudgetAll() {
            const apiUrl = `${API_URL}salesbudgets/request-new/`;

            if (!window.confirm(`Are you sure you want to setup new budgets for all sales staff for all their accounts?\n\nThis will zero out budgets starting ${vm.start_date.getFullYear()}-${vm.start_date.getMonth() + 1}`)) { return false; }

            vm.saving = true;

            return $http.post(apiUrl, { all: true, start: `${vm.start_date.getFullYear()}-${vm.start_date.getMonth() + 1}` }).then(() => {
                vm.saving = false;
                loadBudgets();
            }, () => {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function editBudget(company_id) {
            SlateSalesBudgetEditDialog.edit(company_id, vm.start_date).then(() => {
                loadBudgets();
            });
        }

        function editNewBusiness(sales_mgr_id) {
            SlateNewBusinessEditDialog.edit(sales_mgr_id, vm.start_date).then(() => {
                loadBudgets();
            });
        }

        function getDates() {
            let running_year = vm.start_date.getFullYear();
            let running_month = vm.start_date.getMonth() + 1;
            const months = [];

            while (months.length < 12) {
                months.push(`${running_year}-${running_month.toString().padStart(2, '0')}`);
                running_month += 1;
                if (running_month > 12) { running_month = 1; running_year += 1; }
            }

            return months;
        }

        function getUrl() {
            const url = `${API_URL}salesbudgets/salesbudget/`;
            const running_year = vm.start_date.getFullYear();
            const running_month = vm.start_date.getMonth() + 1;
            const query_date = `${running_year}-${running_month.toString().padStart(2, '0')}-01`;

            return `${url}?start_date=${query_date}&download=true`;
        }

        function getSalesMgrTotal(sales_mgr_id) {
            const salesMgr = vm.budgets.salesMgrs.find((item) => item.id === sales_mgr_id);
            let total = 0.0;

            getDates().forEach((month) => {
                if (!salesMgr || !salesMgr.totals) { return false; }

                if (!Number.isNaN(salesMgr.totals[month])) {
                    total += salesMgr.totals[month];
                }
                // if(vm.newbusiness.salesMgrs && vm.newbusiness.salesMgrs.includes(sales_mgr_id) && vm.newbusiness.salesMgrs[sales_mgr_id].includes(month) && !Number.isNaN(vm.newbusiness.salesMgrs[sales_mgr_id][month].value )) {
                if (vm.newbusiness.salesMgrs && Object.prototype.hasOwnProperty.call(vm.newbusiness.salesMgrs, sales_mgr_id) && Object.prototype.hasOwnProperty.call(vm.newbusiness.salesMgrs[sales_mgr_id], month) && !Number.isNaN(vm.newbusiness.salesMgrs[sales_mgr_id][month].value)) {
                    total += vm.newbusiness.salesMgrs[sales_mgr_id][month].value;
                }
                return true;
            });
            return total;
        }

        function getOverallTotals(month) {
            let total = 0.0;
            vm.budgets.salesMgrs.forEach((salesMgr) => {
                if (!salesMgr || !salesMgr.totals) { return false; }
                if (!Number.isNaN(salesMgr.totals[month])) {
                    total += salesMgr.totals[month];
                }
                if (vm.newbusiness.salesMgrs && Object.prototype.hasOwnProperty.call(vm.newbusiness.salesMgrs, salesMgr.id) && Object.prototype.hasOwnProperty.call(vm.newbusiness.salesMgrs[salesMgr.id], month) && !Number.isNaN(vm.newbusiness.salesMgrs[salesMgr.id][month].value)) {
                    total += vm.newbusiness.salesMgrs[salesMgr.id][month].value;
                }
                return true;
            });
            return total;
        }

        function getFinalTotal() {
            let total = 0.0;
            getDates().forEach((month) => {
                total += getOverallTotals(month);
            });

            return total;
        }

        function getHistoricalUrl() {
            let url = `${API_URL}salesbudgets/sales-history-download/`;
            const running_year = vm.start_date.getFullYear();
            const running_month = vm.start_date.getMonth() + 1;
            const query_date = `${running_year}-${running_month.toString().padStart(2, '0')}-01`;

            url = `${url}?start_date=${query_date}`;

            if (vm.filters.sales_mgr) {
                url = `${url}&sales_mgr=${vm.filters.sales_mgr}`;
            }
            return url;
        }
    }
})();
