( function () {
    "use strict";

    angular
        .module( 'slate.library.presentations' )
        .factory( 'SlatePresentations', ModelList )
        .factory( 'SlatePresentation', ModelDetail );

    var apiUrl = API_URL + 'presentations/presentations/';

    function ModelList( $http, $q, SlatePresentation ) {
        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList( filters ) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if ( typeof filters === "object" ) {
                self.filters = filters;
            }

            promise = $http.get( apiUrl, {
                params: self.filters
            } );

            promise.then( function ( response ) {
                var newlist = [];
                //set this for correct model
                angular.forEach( response.data.payload, function ( item ) {
                    var itemModel = new SlatePresentation();
                    itemModel.loadFromPayload( item );

                    newlist.push( itemModel );
                } );

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve( self );
            } );

            return deferred.promise;
        }

    }

    function ModelDetail( $http, $q, SlateSlides ) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        var fields = [ {
                name: 'id',
                def: null,
                readonly: true
            }, {
                name: 'name',
                def: null
            }, {
                name: 'prepared_for',
                def: null
            }, {
                name: 'filename',
                def: null,
                readonly: true
            },


            {
                name: 'updated',
                def: null,
                readonly: true
            }, {
                name: 'created',
                def: null,
                readonly: true
            }, {
                name: 'createdby',
                def: null,
                readonly: true
            },
        ];

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.download = download;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach( fields, function ( field ) {
                self[ field.name ] = field.def;
            } );
            self.slides = new SlateSlides();

            deferred.resolve( self );

            return deferred.promise;
        }



        function loadFromPayload( payload ) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if ( typeof payload !== "object" ) {
                console.log( 'payload must be an object' );
                return self;
            }

            angular.forEach( fields, function ( field ) {
                self[ field.name ] = payload[ field.name ];
            } );

            deferred.resolve( self );

            return deferred.promise;
        }

        function loadFromServer( id ) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if ( id ) {
                modelUrl = apiUrl + id + '/';
            }

            if ( !self.id && !id ) {
                console.log( 'Tried to load from server without id' );
                return $q.reject( self );
            }

            promise = $http.get( modelUrl );

            promise.then( function ( response ) {
                var promises = [];

                return self.loadFromPayload( response.data.payload ).then( function () {
                    self.permissions = response.data.permissions;

                    promises.push( self.slides.getList( {
                        presentation: id
                    } ) );
                    return $q.when( promises ).then( function () {
                        deferred.resolve( self );
                    } );

                } );
            } );

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if ( self.id ) {
                modelUrl = apiUrl + self.id + '/';
            }

            angular.forEach( fields, function ( field ) {
                if ( !field.readonly ) {
                    data[ field.name ] = self[ field.name ];
                }
            } );

            promise = $http.post( modelUrl, data );
            promise = promise.then( function ( response ) {
                var promises = [];
                self.loadFromPayload( response.data.payload );
                self.permissions = response.data.permissions;
                promises.push( self.slides.saveAll( {
                    presentation: self.id
                } ) );
                return $q.all( promises );
            } );

            return promise;
        }

        function download() {
            /* jshint validthis: true */
            var self = this;
            var promise;
            var modelUrl = apiUrl + self.id + '/download/';
            var isSafari = false;
            if ( !self.id && !id ) {
                console.log( 'Tried to load from server without id' );
                return $q.reject( self );
            }
            var ua = navigator.userAgent.toLowerCase();
            if ( ua.indexOf( 'safari' ) != -1 ) {
                if ( ua.indexOf( 'chrome' ) == -1 ) {
                    isSafari = true;
                }
            }
            if (isSafari) {
                promise = $http.get( modelUrl, {
                    params: {
                        link: true
                    }
                } ).then( function ( response ) {
                    return response.data.payload;
                } );
                return promise;

            } else {
                promise = $http.get( modelUrl, {
                    responseType: 'arraybuffer',
                } ).then( function ( response ) {
                    var type = "application/octet-stream";
                    var blob;
                    var objectUrl;
                    //type = "application/vnd.openxmlformats-officedocument.presentationml.presentation";

                    if ( response.headers( 'Content-Type' ) ) {
                        //type = response.headers('Content-Type');
                    }
                    blob = new Blob( [ response.data ], {
                        type: type,
                    } );
                    saveAs( blob, self.name + '.pptx' );
                    //saveAs(blob, 'filename.pptx');
                    return self;
                } );

                return promise;
            }

        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if ( self.id ) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject( self );
                return deferred.promise;
            }

            promise = $http.delete( modelUrl );
            promise.then( function ( response ) {
                self.id = null;
                deferred.resolve( self );
            } );

            return deferred.promise;

        }

    }

} )();
