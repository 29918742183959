(function () {
    "use strict";

    angular
        .module('slate.projects')
        .service('SlateCartonMarkingSelection', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateCompany) {
        var templateUrl = '/partials/companies/company.cartonmarking.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj, projectId) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                windowClass: 'modal-sidebar right',
                resolve: {
                    obj: function () {
                        var co = new SlateCompany();
                        if (!obj) {
                            return co;
                        }
                        if (typeof obj == 'string') {
                            return co.loadFromServer(obj);
                        }
                        return co.loadFromPayload(obj);
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return obj;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateAuth, SlateCartonMarkings, obj) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;
        vm.auth = SlateAuth;
        vm.cartonMarkings = new SlateCartonMarkings();

        vm.saving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        function isComplete() {
           
        }

        function saveObject() {
            vm.saving = true;
            vm.error = false;
            vm.obj.saveModel().then(function() {
                vm.saving = false;
                $uibModalInstance.close();
            }, function() {
                vm.saving = false;
                vm.error = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.cartonMarkings.getList({company: vm.obj.id});

    }

})();
