(function() {
    "use strict";

    angular
        .module('slate.library.rates')
        .factory('SlateRateSheetLineItems', ModelList)
        .factory('SlateRateSheetLineItem', ModelDetail);

    var apiUrl = API_URL + 'rates/ratesheet/';

    function ModelList($http, $q, SlateRateSheetLineItem) {
        // Set to url relative to api root.

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.download = download;
        list.prototype.loadFromPayload = loadFromPayload;


        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        
        function loadFromPayload(payload) {
            var newlist = [];
            //set this for correct model
            angular.forEach(payload, function(item) {
                var itemModel = new SlateRateSheetLineItem();

                itemModel.loadFromPayload(item);
                newlist.push(itemModel);
            });
            self.list = newlist;
            return newlist;
        }

        function download(filters) {

        }

    }

    function ModelDetail($http, $q, SlateRateSheetEstimate) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };


        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },

            {name: 'rate_sheet'},

            {name: 'shipment_type'},

            {name: 'origin_port'},
            {name: 'destination_port'},
            {name: 'final_destination'},
            
            {name: 'transit_time'},

            {name: 'sort_order'},

            {name: 'estimates', type:'child_list', model: SlateRateSheetEstimate},
        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.getModelData = getModelData;
        model.prototype.setContainerProperties = setContainerProperties;
        model.prototype.deleteModel = deleteModel;
        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;

                if(field.type=="child_list") {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                self[field.name] = payload[field.name];

                if(field.type == 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach(function(item, idx, array) {
                            var obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch(e) {
                        console.log('error loading: ', e);
                    }
                }
            });

            self.setContainerProperties();

            deferred.resolve(self);

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            var self = this;
            var data = {};

            angular.forEach(fields, function(field) {
                if ((!field.readonly || field.name == 'id') && typeof(self[field.name]) != 'undefined') {
                    data[field.name] = self[field.name];
                    if(field.type == "date") {
                        data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth()+1) + '-' + self[field.name].getUTCDate();
                    }
                    if(field.type == "dateOnly" && self[field.name]) {
                        data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth()+1) + '-' + self[field.name].getDate();
                    }
                    if(field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if(field.type == 'float') {
                        data[field.name] = parseFloat(self[field.name]);
                        if(isNaN(data[field.name])) { data[field.name] = null; }
                    }
                    if(field.trim && data[field.name]) {
                        data[field.name] = data[field.name].substr(0,field.trim);
                    }
                }
            });

            return data;
        }

        function setContainerProperties() {
            /* jshint validthis: true */
            var self = this;
            var containerSizes = {'20ft': true, '40ft': true, '40ftHQ': true, '45ft': true};

            self.estimates.forEach(function(estimate, idx, array) {
                if (containerSizes[estimate.container_size]) {
                    Object.defineProperty(array, estimate.container_size, {
                        get: function() {
                            return estimate.estimated_rate;
                        },
                        set: function(value) {
                            estimate.estimated_rate = value;
                        }
                    });
                }
            });
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
