(function() {
    "use strict";

    angular
        .module('slate.billings')
        .directive('slateBillingsNavbar', dirObject);

    function dirObject() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/billings/billings.notification.dir.html',
            scope: {

            },
            controller: navbarController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function navbarController($scope, $q, $interval, getLinkSrv, SlateBillings) {
        /* jshint validthis: true */
        var vm = this;
        var speed = 60000;
        var interval;

        if(DEBUG) {
            speed = speed * 120;
        }
        vm.getLink = getLinkSrv;
        vm.notifications = new SlateBillings();
        updateData();

        interval = $interval(function() {
            updateData();
        }, speed);

        $scope.$on("billingChange", function() {
            updateData();
        });

        function updateData() {
            vm.notifications.getList({my_ready: true});
        }

    }

})();
