/* eslint-disable func-names */
(function () {
    'use strict';

    angular
        .module('slate.library.companies')
        .factory('SlateCompanies', ModelList)
        .factory('SlateCompany', ModelDetail);

    function ModelList($http, $q, SlateCompany, SlateLocations) {
        // Set to url relative to api root.
        const apiUrl = `${API_URL}companies/companies/`;

        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === 'object') {
                self.filters = angular.copy(filters);
            }

            const promise = $http.get(apiUrl, {
                params: self.filters,
                timeout: self.canceller.promise
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateCompany();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.totalItems = response.data.totalItems;
                self.permissions = response.data.permissions;
                self.canceller = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q, SlateLocations, SlateContactMethods, SlateShippingAccounts) {
        // Set to url relative to api root.
        const apiUrl = `${API_URL}companies/companies/`;

        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        const fields = [
            { name: 'id', def: null, readonly: true },
            { name: 'name', def: null },
            { name: 'name_zh', def: null },
            { name: 'prefix', def: null },
            { name: 'description', def: null },
            { name: 'slug', def: null, readonly: true },
            { name: 'vendor', def: false },
            { name: 'vendor_qualified', def: false },
            { name: 'vendor_preferred', def: false },
            { name: 'rfq_report', def: false },
            { name: 'rfq_report_recipients' },
            { name: 'country', def: null },
            // {name: 'contact_addresses', def: [] },
            { name: 'vendor_caps', def: [], type: 'json' },
            { name: 'material_categories', def: [], type: 'json' },
            { name: 'source', def: null },
            { name: 'quality', def: null },
            { name: 'inspector', def: 'qima' },
            { name: 'carton_marking_template' },
            { name: 'qc_contact' },
            { name: 'qc_contact_info' },
            { name: 'signed_nda', def: false },
            { name: 'credit_app', def: false },
            { name: 'credit_terms' },
            { name: 'billing_terms', readonly: true },
            { name: 'credit_limit', readonly: true },
            { name: 'over_credit_limit', def: false, readonly: true },
            { name: 'so_total_value', readonly: true },
            { name: 'po_total_value', readonly: true },
            { name: 'has_logins', def: false, readonly: true },
            { name: 'on_hold', def: false },
            { name: 'hold_reason' },

            { name: 'quote_min_margin' },
            { name: 'quote_max_sales' },
            { name: 'include_tariff', def: false },
            { name: 'show_landed_costs', def: false },

            { name: 'ar_balance', readonly: true },
            { name: 'past_due_balance', readonly: true },
            { name: 'ar_01_to_30_days', readonly: true },
            { name: 'ar_31_to_60_days', readonly: true },
            { name: 'ar_61_to_90_days', readonly: true },
            { name: 'ar_91_to_120_days', readonly: true },
            { name: 'ar_over_120_days', readonly: true },

            { name: 'origin_port' },
            { name: 'destination_port' },
            { name: 'final_destination' },

            { name: 'is_intercompany' },

            { name: 'mocra_details' },

            { name: 'sales_mgr' },
            { name: 'sales_mgr_info', readonly: true },

            { name: 'brands', def: [], readonly: true },


            { name: 'created', def: null, readonly: true },
            { name: 'updated', def: null, readonly: true },

            // controls combining code
            { name: 'companyToCombine', def: '' },
            { name: 'make_brand_contact' },
            { name: 'make_brand', def: false }
        ];

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        model.prototype.getLocations = getLocations;
        model.prototype.getContactMethods = getContactMethods;
        model.prototype.getShippingAccounts = getShippingAccounts;
        model.prototype.getZHName = getZHName;

        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = _.cloneDeep(field.def);
            });

            self.locations = new SlateLocations();
            self.contactmethods = new SlateContactMethods();
            self.shipping_accounts = new SlateShippingAccounts();

            deferred.resolve(self);

            return deferred.promise;
        }


        function getLocations(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            if (!self.locations) {
                self.locations = new SlateLocations();
            }
            if (!payload) {
                return self.locations.getList({ company: self.id });
            }

            self.locations.loadFromPayload(payload.locations);

            deferred.resolve(self);
            return deferred.promise;
        }

        function getContactMethods(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            if (!self.contactmethods) {
                self.contactmethods = new SlateContactMethods();
            }
            if (!payload) {
                return self.contactmethods.getList({ company: self.id });
            }

            self.contactmethods.loadFromPayload(payload.contact_methods);

            deferred.resolve(self);
            return deferred.promise;
        }

        function getShippingAccounts() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (!self.shipping_accounts) {
                self.shipping_accounts = new SlateShippingAccounts();
            }

            self.shipping_accounts.getList({ company: self.id });

            deferred.resolve(self);
            return deferred.promise;
        }

        function getZHName() {
            /* jshint validthis: true */
            const self = this;
            if (self.name_zh) { return self.name_zh; }
            return self.name;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                // eslint-disable-next-line no-console
                console.log('payload must be an object');
                // eslint-disable-next-line no-console
                console.log('not object:', payload);
                return self;
            }

            angular.forEach(fields, (field) => {
                self[field.name] = payload[field.name];

                if (field.type === 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                        // Do nothing
                    }
                }
            });

            if (!self.locations) {
                self.locations = new SlateLocations();
            }
            if (!self.contactmethods) {
                self.contactmethods = new SlateContactMethods();
            }
            if (!self.shipping_accounts) {
                self.shipping_accounts = new SlateShippingAccounts();
            }

            // if(!self.name_zh && self.name) { self.name_zh = self.name; }

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            if (id) { modelUrl = `${apiUrl + id}/`; }

            if (!self.id && !id) {
                // eslint-disable-next-line no-console
                console.log('Tried to load from server without id');
                return $q.reject(self);
            }

            const promise = $http.get(modelUrl);

            promise.then((response) => {
                const promises = [];

                return self.loadFromPayload(response.data).then(() => {
                    self.permissions = response.data.permissions;
                    promises.push(self.getLocations(response.data));
                    promises.push(self.getContactMethods(response.data));
                    promises.push(self.getShippingAccounts());

                    return $q.when(promises).then(() => {
                        deferred.resolve(self);
                    });
                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) { modelUrl = `${apiUrl + self.id}/`; }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (field.type === 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });


            let has_primary_url = false;
            let has_primary_phone = false;


            angular.forEach(self.contactmethods.list, (cm) => {
                if (cm.method === 'url' && cm.is_primary === true) {
                    if (!has_primary_url) {
                        has_primary_url = true;
                    } else {
                        cm.is_primary = false;
                    }
                }
                if (cm.method === 'phone' && cm.is_primary === true) {
                    if (!has_primary_phone) {
                        has_primary_phone = true;
                    } else {
                        cm.is_primary = false;
                    }
                }
            });
            angular.forEach(self.contactmethods.list, (cm) => {
                if (cm.method === 'url' && !has_primary_url) {
                    cm.is_primary = true;
                    has_primary_url = true;
                }
                if (cm.method === 'phone' && !has_primary_phone) {
                    cm.is_primary = true;
                    has_primary_phone = true;
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then((response) => {
                const promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                if (self.locations) {
                    promises.push(self.locations.saveAll({ parent_type: 'company', parent_id: self.id }));
                }

                if (self.contactmethods) {
                    promises.push(self.contactmethods.saveAll({ parent_type: 'company', parent_fk: self.id }));
                }

                if (self.shipping_accounts) {
                    promises.push(self.shipping_accounts.saveAll({ parent_type: 'company', parent_fk: self.id }));
                }

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            const promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }
})();
