var scheduleDirMod = angular.module('slate.schedule');

scheduleDirMod.directive('slateSchedule', slateSchedule);


function slateSchedule() {
    return {
        restrict: 'E',
        //replace: 'true',
        templateUrl: '/partials/schedule/schedule.list.html',
        scope: {
            modelFilters: '=filters'
        },
        controller: ListController,
        controllerAs: 'vm',
        bindToController: true,

    };
}

function ListController($scope, $location, $element, $document, $interval, getLinkSrv, SlateAuth, SlateWorkflows, SlateRolesSrv, SlateAssignedRolesEdit) {
    /* jshint validthis: true */
    var vm = this;
    var rolesFromSearch = $location.search().roles;
    var filterDefaults = {
        onlyDesired: true,
        roles: [],
    };

    var el = angular.element($element);
    el.find('.schedule-grid').on('scroll', function () {
        var left = angular.element(this).scrollLeft();
        var top = angular.element(this).scrollTop();
        el.find('.time-row').css('top', top + 'px');
        el.find('.name').css('left', left + 'px');
        el.find('.corner-controls').css('left', left + 'px');
    });

    setTimeout(function () {
        var target;
        el.find('.block').on('dragover', function (e) {
            if (e.preventDefault) {
                e.preventDefault(); // Necessary. Allows us to drop.
            }
            //e.dataTransfer.dropEffect = 'move';
            
        });
        el.find('.block').on('drop', function (e) {
            if (e.stopPropagation) {
                e.stopPropagation(); // stops the browser from redirecting.
            }
            
        });
        el.find('.unsch').on('dragstart', function (e) {
            if (!target || !target.classList.contains('handle')) {
                e.preventDefault();
            }
        });
        el.find('.unsch .handle').on('mousedown', function (e) {
            target = e.target;
            
        });
        el.find('.unsch .handle').on('mouseup', function (e) {
            target = undefined;
            
        });

    }, 1000);


    vm.names = ['Jason', 'Angela', 'Sam', 'Chris', 'Joe', 'Hennie', 'Jason', 'Angela', 'Sam', 'Chris', 'Joe', 'Hennie'];
    vm.times = ['10:00am', '10:15am', '10:30am', '10:45am', '11:00am', '11:15am', '11:30am', '11:45am', '12:00am', '12:15am', '12:30am', '12:45am'];
    vm.unsch = ['lame', 'test', 'what'];

    vm.loading = true;

    vm.roles = SlateRolesSrv;
    vm.getLink = getLinkSrv;
    vm.editRoles = editRoles;

    vm.totalHrsSpent = totalHrsSpent;
    vm.totalHrsEst = totalHrsEst;

    function totalHrsEst() {
        let total = 0.0;
        vm.milestones.list.forEach((item) => {
            if(!item.hours_est) { return 0; }
            total = total + item.hours_est;
        });
        return total;
    }

    function totalHrsSpent() {
        let total = 0.0;
        vm.milestones.list.forEach((item) => {
            console.log('item hours spent:', item.hours_spent);
            if(!item.hours_spent) { return 0; }
            total = total + item.hours_spent;
        });
        return total;
    }

    if (!rolesFromSearch || !rolesFromSearch.length) {
        rolesFromSearch = '';
        if(SlateAuth.user) {
            rolesFromSearch = SlateAuth.user.groups;
        }
        
    }
    if (typeof (rolesFromSearch) == 'string') {
        filterDefaults.roles = [];
        if(vm.roles.byName[rolesFromSearch]) {
            filterDefaults.roles.push(vm.roles.byName[rolesFromSearch].id);
        }
        
    } else {
        filterDefaults.roles = [];
        angular.forEach(rolesFromSearch, function (item) {
            filterDefaults.roles.push(vm.roles.byName[item].id);
        });
    }

    vm.filters = angular.extend({}, vm.modelFilters, filterDefaults);

    vm.milestones = new SlateWorkflows();


    $scope.$watch('vm.filters', function () {
        var roleNames = [];
        vm.loading = true;

        angular.forEach(vm.filters.roles, function (item) {
            roleNames.push(vm.roles.dict[item].name);
        });
        $location.search({
            'roles': roleNames
        });
        updateList();
    }, true);

    function updateList() {
        var params = {
            incomplete: 'only',
            only_desired: vm.filters.onlyDesired,
            roles: vm.filters.roles
        };
        if(vm.filters.unassigned) { params.unassigned = true; }
        if(vm.filters.unreviewed) { params.unreviewed = true; }
        if(vm.filters.approved) { params.approved = true; }
        
        vm.milestones.getList(params).then(function () {
            vm.milestones.list.sort(function (a, b) {
                if (a.desired_by < b.desired_by) {
                    return -1;
                }
                if (a.desired_by > b.desired_by) {
                    return 1;
                }
                // a must be equal to b
                return 0;
            });
            vm.loading = false;
        });
    
    }

    function editRoles(milestone) {
        var options = {
            parent_fk: milestone.id,
            parent_type: 'milestone',
            allowRoles: true
        };
        SlateAssignedRolesEdit.edit(options).then(function () {
            updateList();
        });
    }

    vm.reloader = $interval(function () {
        updateList();
    }, 300000);

    $scope.$on('$destroy', function () {
        if (angular.isDefined(vm.reloader)) {
            $interval.cancel(vm.reloader);
            vm.reloader = undefined;
        }
    });


}
