(function() {
    "use strict";

    angular
        .module('slate.filebrowser')
        .directive('slateFileNodePicker', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/filebrowser/filenode.picker.dir.html',
            scope: {
                parentType: '=',
                parentFk: '=',
                rootPath: '=?',
                currentNode: '=?',
                chosenNode: '=?',
                path: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $q, getLinkSrv, SlateFileNodeList, SlateFileNode) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.node = new SlateFileNode();
        vm.childNodes = new SlateFileNodeList();
        vm.getUrl = getUrl;
        vm.parseFilesize = parseFilesize;
        vm.selectNode = selectNode;

        if (!vm.rootPath) {
            
        }


        init();

        function getUrl(path) {
            var params = $state.params;
            params.path = path;
            return $state.href($state.current.name, params, {
                absolute: true,
            }).replace(/~2F/g, '/');
        }

        function parseFilesize(size) {
            var unit = "B";
            if (size > 1024) {
                size = size / 1024;
                unit = "K";
            }
            if (size > 1024) {
                size = size / 1024;
                unit = "M";
            }
            if (size > 1024) {
                size = size / 1024;
                unit = "G";
            }
            return size.toFixed(2).toString() + unit;
        }

        function selectNode(node, event) {
            vm.chosenNode = node;
            if (event) {
                event.stopPropagation();
                event.preventDefault();
            }
        }

        function init() {
            var modelPromise;
            var truePathParths = [];
            var truePath = '/';
            var listFilters = {
                parent_type: vm.parentType,
                parent_fk: vm.parentFk,
                path: truePath,
            };
            vm.loading = true;

            if ($state.current.tag) {
                listFilters.tag = $state.current.tag;
            }
            if (!vm.path && $state.params.path) {
                vm.path = $state.params.path;
            }

            if (vm.rootPath && vm.rootPath.split) {
                truePathParths = vm.rootPath.split('/');
            }
            if (vm.path) {
                truePathParths = truePathParths.concat(vm.path.split('/'));
            }
            if (truePathParths.length) {
                truePath = truePathParths.join('/');
            }
            listFilters.path = truePath;


            modelPromise = vm.childNodes.getList(listFilters);
            modelPromise.then(function() {

                vm.loading = false;

            });

        }



    }


})();
