(function () {
    'use strict';

    angular
        .module('slate.profilephotos')
        .directive('slateProfilePhotoBox', dirObject);

    function dirObject() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/profilephotos/profilephotosbox.dir.html',
            scope: {
                parentType: '@',
                parentId: '=',
                size: '=?',
            },
            controller: photosController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function photosController(SlateProfilePhotoSrv, SlateProfilePhotoUpload) {
        /* jshint validthis: true */
        const vm = this;
        if (!vm.size) { vm.size = 'sm'; }
        vm.photosrv = SlateProfilePhotoSrv;
        vm.photo = '';
        vm.loading = true;

        vm.photosrv.getPhoto(vm.parentType, vm.parentId, vm.size)
            .then((url) => {
                vm.photo = url;
                vm.loading = false;
                return url;
            });

        vm.upload = function () {
            let promise = null;

            promise = SlateProfilePhotoUpload.upload(vm.parentType, vm.parentId);
            promise = promise.then(() => {
                vm.photosrv.getPhoto(vm.parentType, vm.parentId, vm.size)
                    .then((url) => {
                        vm.photo = url;
                        vm.loading = false;
                        return url;
                    });
            });
            return promise;
        };
    }
})();
