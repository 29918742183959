(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportShipmentDates', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/reports/reports.report.shipmentdates.dir.html',
            scope: {

            },
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $state, $location, $http, $q, $stateParams, $httpParamSerializer, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.linksrv = getLinkSrv;
        vm.loading = true;

        vm.filters = {};

        vm.data = [];
        vm.getDownloadUrl = getDownloadUrl;

        var apiUrl = API_URL + 'reports/shipment_bad_dates/';

        updateFromFilter();

        function updateFromFilter() {
            vm.loading = true;

            $http.get(apiUrl, {params: vm.filters}).then(function(resp) {
                vm.data = resp.data.payload;
                vm.loading = false;
            });
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }

    }


})();
