(function() {
    "use strict";

    angular
        .module('slate.rfqs')
        .directive('slateRfqLineItemList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/rfqs/rfq.lineitems.list.dir.html',
            scope: {
                rfq: '=',
                hidenew: '=?',
                fields: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlateRFQLineItems, SlateRFQLineItem, SlateRFQLineItemEditSrv, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

        if(!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'photo',
                'comp_number',
                'comp_name',
                'qty',
                'unit_name',
                'category',
                'material'
            ];
            /* beautify preserve:end */
        }

        vm.getLink = getLinkSrv;
        vm.loading = false;

        vm.editLineItem = editLineItem;
        vm.addNewLineItem = addNewLineItem;
        vm.deleteLineItem = deleteLineItem;

        vm.getStyle = getStyle;

        vm.baseStatus = vm.rfq.status;

        function isEditable() {
            if(['Draft', 'Ready'].includes(vm.baseStatus)) { return true; }
            if(vm.auth.perms.rfq_can_override_status) { return true; }

            return false;
        }

        function addNewLineItem() {
            var new_lineitem = new SlateRFQLineItem();
            SlateRFQLineItemEditSrv.edit(new_lineitem, !isEditable(), vm.rfq.project).then(function (lineitem) {
                vm.dirty = true;
                vm.rfq.line_items.push(lineitem);
            }, function () {
                //do nothing if canceled?
            });
        }

        function editLineItem(lineitem, $idx) {
            SlateRFQLineItemEditSrv.edit(lineitem, !isEditable(), vm.rfq.project).then(function (new_lineitem) {
                if(!isEditable()) { return false; }
                vm.dirty = true;
                vm.rfq.line_items[$idx] = new_lineitem;
            }, function () { });
        }

        function deleteLineItem(lineitem, $idx) {
            if(!vm.auth.perms.rfq_can_override_status) { return false; }
            lineitem.toDelete = !lineitem.toDelete;
        }

        function getStyle(field) {
            if(field == 'comp_name' || field == "specs") {
                return {width: '100%'};
            }

            if(field == 'photo') {
                return {width: '48px', height: '48px'};
            }

            return {
                'overflow': 'hidden',
                'white-space': 'nowrap',
                'text-overflow': 'ellipsis',
            };
        }

    }


})();
