(function() {
    "use strict";

    angular
        .module('slate.library.invoices')
        .factory('SlateInvoiceLineItems', ModelList)
        .factory('SlateInvoiceLineItem', ModelDetail);

    var apiUrl = API_URL + 'invoices/invspec/';

    function ModelList($http, $q, SlateInvoiceLineItem) {
        // Set to url relative to api root.

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlateInvoiceLineItem();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', readonly: true },
            {name: 'invrequest'},

            {name: 'so_lineitem'},

            {name: 'jansy_project'},
            {name: 'jansy_project_info', readonly: true},

            {name: 'jansy_component'},
            {name: 'jansy_component_info', readonly: true},

            {name: 'jansy_pn'},
            {name: 'client_pn'},
            {name: 'jansy_po'},
            {name: 'client_po'},

            {name: 'qty', def: 0, type: 'int'},
            {name: 'unitprice', def: 0.0, type: 'float'},
            {name: 'sellprice', def: 0.0, type: 'float'},

            {name: 'name'},
            {name: 'material'},
            {name: 'do_not_invoice'},

            {name: 'freight_method'},
            {name: 'freight_terms'},
            {name: 'freight_cost', def: 0.0, type: 'float'},
            {name: 'freight_billable', def: 0.0, type: 'float'},

            {name: 'sort_order'}

        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.getModelData = getModelData;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.setFromProj = setFromProj;
        model.prototype.getCostTotal = getCostTotal;
        model.prototype.getSellTotal = getSellTotal;
        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
                if(field.type=="date" && !self[field.name]) {
                    self[field.name] = new Date();
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                if(!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if(field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }
                if (field.type == 'int') {
                    self[field.name] = parseInt(self[field.name]);
                }


                if(field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch(e) {
                    }
                }

            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];

                return self.loadFromPayload(response.data.payload).then(function() {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            var self = this;
            var data = {};

            angular.forEach(fields, function(field) {
               if ((!field.readonly || field.name == 'id') && typeof(self[field.name]) != 'undefined') {
                    data[field.name] = self[field.name];
                    if(field.type == "date") {
                        data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth()+1) + '-' + self[field.name].getUTCDate();
                    }
                    if(field.type == "dateOnly" && self[field.name]) {
                        data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth()+1) + '-' + self[field.name].getDate();
                    }
                    if(field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            data = getModelData();

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

        function setFromProj(proj) {
            /* jshint validthis: true */
            var self = this;
            self.jansy_pn = proj.composite_num;
            self.name = proj.name;

        }
        function getCostTotal() {
            /* jshint validthis: true */
            var self = this;
            var total = 0;
            var qty = new Big(0);
            var cost = new Big(0);
            var freight = new Big(0);

            try { cost = new Big(self.unitprice); } catch(e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch(e) { qty = new Big(0); }
            try { freight = new Big(self.freight_cost); } catch(e) { freight = new Big(0); }

            total = qty.times(cost).plus(freight);

            return total.toFixed(5);
        }

        function getSellTotal() {
            /* jshint validthis: true */
            var self = this;
            var total = 0;
            var qty;
            var cost;
            var freight;

            if(self.do_not_invoice) { return 0.00; }

            try { cost = new Big(self.sellprice); } catch(e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch(e) { qty = new Big(0); }
            try { freight = new Big(self.freight_billable); } catch(e) { freight = new Big(0); }

            total = qty.times(cost).plus(freight);

            return total.toFixed(5);
        }

    }

})();
