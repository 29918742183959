(function () {
    "use strict";

    angular
        .module('slate.orders')
        .directive('slateOrderSpecSearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/orders/orderspec.searchbox.dir.html',
            scope: {
                orderSpecId: '=',
                readonly: '<?',
                orderId: "=?",
                showDropdown: "<?"
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function directiveController($scope, $q, SlateOrder, SlateOrderSpec, SlateOrderSpecs) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;

        vm.specs = new SlateOrderSpecs();
        vm.dropdownSpecs = new SlateOrderSpecs();
        var selectedSpec;

        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.clear = clear;

        vm.selectDropdown = selectDropdown;

        function getDropDown() {
            var filters = {};
            var promise;
            
            if (!vm.showDropdown) { return false; }

            if (!vm.orderId) { return false; }
            if (vm.orderId) { filters.order = vm.orderId;}
            promise = vm.dropdownSpecs.getList(filters).then(function () {
                return vm.dropdownSpecs.list;
            });
            return promise;
        }

        function selectDropdown($item) {
            vm.searching = false;
            vm.orderSpecId = $item.id;
            selectedSpec = $item.id;
            vm.specObj = $item;
            vm.specName = $item.finished_good_info.composite_name;
        }


        function getSearch(q) {
            var promise;
            var filters = { 
                q: q,
                page: 1,
                count: 50
            };
            vm.searching = true;
            if (q && q.length < 3) { return $q.reject([]); }
            if (vm.orderId) { filters.order = vm.orderId;}
            promise = vm.specs.getList(filters).then(function () {
                return vm.specs.list;
            });
            return promise;
        }

        function selectSearch($item, $model, $label, $event) {
            vm.searching = false;
            vm.orderSpecId = $item.id;
            selectedSpec = $item.id;
            vm.specObj = $item;
            vm.specName = $item.finished_good_info.composite_name;
        }

        function clear() {
            if (vm.readonly) { return true; }
            vm.orderSpecId = null;
            selectedSpec = null;
            vm.specObj = null;
            vm.specName = null;
        }

        $scope.$watch('vm.orderSpecId', function () {
            if (selectedSpec != vm.orderSpecId && vm.orderSpecId) {
                var spec = new SlateOrderSpec();
                spec.loadFromServer(vm.orderSpecId).then(function () {
                    vm.specObj = spec;
                    vm.specName = spec.finished_good_info.composite_name;
                    selectedSpec = spec.id;
                });
            }
            
        });
        $scope.$watch('vm.orderId', function () { getDropDown(); });

        $scope.$watch('vm.typeaheadOpen', function() {
            if(!vm.typeaheadOpen && !vm.specObj && vm.orderSpecId) {
                var spec = new SlateOrderSpec();
                spec.loadFromServer(vm.orderSpecId).then(function () {
                    vm.specObj = spec;
                    vm.specName = spec.finished_good_info.composite_name;
                    selectedSpec = spec.id;
                });
            }
        });
    }

})();
