function SidebarService() {
    self = this;
    self.sidebars = {};
    self.shadow = false;

    self.toggle = function toggle(sidebarName) {
        var currentValue = self.sidebars[sidebarName];
        if (typeof self.sidebars[sidebarName] == "undefined") {
            self.sidebars[sidebarName] = false;
        }
        angular.forEach(self.sidebars, function(value, key) {
            self.sidebars[key] = false;
        });

        self.sidebars[sidebarName] = !currentValue;
        self.shadow = self.sidebars[sidebarName];
    };

    self.fullNav = function fullNav() {
        return window.innerWidth > 1024;
    };

    self.closeall = function closeall() {
        angular.forEach(self.sidebars, function(value, key) {
            self.sidebars[key] = false;
        });
        self.shadow = false;
    };

}
angular
    .module('slate.navbar')
    .service('SidebarSrv', SidebarService);