(function() {
    "use strict";

    angular
        .module('slate.qc')
        .directive('slateQclogList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/qc/qclog.list.dir.html',
            scope: {
                modelFilters: '=filters'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, SlateQCLogs, SlateQCLog, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;

        var modelPromise;

        vm.getLink = getLinkSrv;
        vm.loading = true;
        
        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;

        vm.get_url = get_url;

        if (!vm.filters) {
            vm.filters = {};
        }

        angular.extend(vm.filters, {
            q: $location.search().q,
            created_by: $location.search().created_by,
            factory: $location.search().factory,
            start: $location.search().start,
            end: $location.search().end,
        }, vm.modelFilters, $location.search(),  {page: 1, count: 25});
        if(vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if(vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }
        if(vm.filters.start) { vm.start = new Date(vm.filters.start); }
        if(vm.filters.end) { vm.end = new Date(vm.filters.end); }
        
        vm.qcList = new SlateQCLogs();

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        $scope.$watch('vm.start', function () {
            if (vm.start) {
                vm.filters.start = vm.start.toISOString();
            } else { vm.filters.start = null; }

        });
        $scope.$watch('vm.end', function () {
            if (vm.end) {
                vm.filters.end = vm.end.toISOString();
            } else { vm.filters.end = null; }
        });


        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function(value, key) {
                if(key == 'page' || key == 'count' || key == 'q') {
                    return false;
                }
                if(value === undefined || value === null) {
                    return false;
                }
                if(vm.modelFilters && vm.modelFilters[key] == value) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;
                
            });
            return hasData;
        }

        function updateFromFilter() {
            var modelPromise;

            vm.loading = true;

            angular.forEach(vm.filters, function(value, item) {
                if (vm.filters[item] && (!vm.modelFilters || vm.modelFilters[item] != vm.filters[item])) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = vm.qcList.getList(vm.filters);
            modelPromise.then(function() {
                vm.loading = false;
            });
        }

        function get_url() {
            var url =  API_URL + 'qc/qclog/';
            if($window.location.search.length ) {
                return url + $window.location.search + '&download=true'; 
            } else {
                return url + '?download=true';
            }
        }


    }
})();