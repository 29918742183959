(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateContactProjectPo', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.contact_project_po.dir.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($q, $scope, $http, $location, $httpParamSerializer, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'reports/contact_project_po/';
        vm.getLink = getLinkSrv;

        var modelPromise;

        vm.getDownloadUrl = getDownloadUrl;
        vm.updateFromFilter = updateFromFilter;

        vm.data = [];
        vm.filters = { contacts: ['', ] };
        vm.loading = false;

        vm.updateFromFilter = updateFromFilter;
        vm.addContact = addContact;

        function getSearchContacts() {
            var urlFilters = $location.search();
            if(!urlFilters.contacts) { return false; }
            if(typeof urlFilters.contacts == 'string') {
                vm.filters.contacts = [urlFilters.contacts];
            } else {
                vm.filters.contacts = urlFilters.contacts;
            }
        }

        getSearchContacts();

        $scope.$watchCollection(function() {
            return vm.filters.contacts;
        }, function() {
            updateFromFilter();
        });

        function addContact() {
            vm.filters.contacts.push('');
        }

        function updateFromFilter() {
            vm.loading = true;
            var contacts;

            if(vm.canceller) { vm.canceller.resolve(); }
            vm.canceller = $q.defer();

            contacts = vm.filters.contacts.filter(function(item) {
                if(typeof item != 'string') { return false; }
                return item.length;
            });

            if(contacts.length) {
                $location.replace().search('contacts', contacts);
            } else {
                $location.replace().search('contacts', null);
                vm.data = [];
                vm.loading = false;
                vm.canceller.resolve();
                vm.canceller = null;
                return false;
            }

            modelPromise = $http.get(apiUrl, {
                params: {contacts: contacts},
                timeout: vm.canceller.promise
            });

            modelPromise.then(function(resp) {
                vm.data = resp.data.payload;
                vm.loading = false;
                vm.canceller = null;
            });
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }
    }
})();