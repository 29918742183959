(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportShipmentAudit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/reports/reports.report.shipment_audit.dir.html',
            scope: {

            },
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $state, $location, $http, $q, $stateParams, $httpParamSerializer, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.linksrv = getLinkSrv;
        vm.loading = true;
        var canceller = null;

        vm.filters = {
            date: $location.search().date || dateFns.format(new Date(), 'YYYY-MM-DD'),
            status: $location.search().status || 'intransit',
        };

        vm.date = dateFns.parse(vm.filters.date);

        vm.fields = [
            'shipment_number',
            'name',
            'status',
            'freight_handler',
            'forwarder',
            'etd',
            'eta',
        ];

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        $scope.$watch('vm.date', function() {
            if (vm.date) {
                vm.filters.date = dateFns.format(vm.date, 'YYYY-MM-DD');
            } else {
                vm.filters.date = dateFns.format(new Date(), 'YYYY-MM-DD');
                vm.date = dateFns.parse(vm.filters.date);
            }
        });

        vm.data = [];
        vm.getDownloadUrl = getDownloadUrl;

        var apiUrl = API_URL + 'reports/shipment_audit/';

        updateFromFilter();

        function updateFromFilter() {
            vm.loading = true;

            if(canceller) { canceller.resolve(); }
            canceller = $q.defer();

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            $http.get(apiUrl, {params: vm.filters}).then(function(resp) {
                vm.data = resp.data.payload;
                canceller = null;
                vm.loading = false;
            });
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }

    }


})();
