(function () {
    "use strict";

    angular
        .module('slate.projects')
        .service('SlateSfLinksDialog', editDialog);


    function editDialog(
            $rootScope,
            $q,
            $uibModal,
            getLinkSrv,
            SlateAuth,
            SlateSFLinkage
        ) {
        var templateUrl = '/partials/salesforce/sflinks.dialog.srv.html';

        var srv = {
            popout: editObj
        };


        return srv;


        function editObj() {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',

            });

            return modalInstance.result;
        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateSettings, SlateAuth, SlateSFLinkage) {
        /* jshint validthis: true */
        var vm = this;

        vm.auth = SlateAuth;
        vm.sf_linkage = SlateSFLinkage;

        vm.saving = false;
        vm.errorSaving = false;
        vm.cancel = cancel;

        vm.deleteItem = deleteItem;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function deleteItem(id) {
            if(!window.confirm('Are you sure you want to delete this link?')) { return false; }

            vm.sf_linkage.delete(id);
        }

    }

})();
