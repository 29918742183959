(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .controller('SlateSOShipNewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($q, $scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateSalesOrder, SlateShipment) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.salesorder = new SlateSalesOrder();
        vm.shipment = new SlateShipment();

        vm.salesorder.loadFromServer($stateParams.id).then(function () {
            var promises = [];
            console.log('got sales order');

            vm.shipment.origin_type = 'stock';

            vm.shipment.delivery_to = vm.salesorder.ship_company;
            vm.delivery_address = [
                vm.salesorder.ship_street,
                vm.salesorder.ship_city,
                vm.salesorder.ship_state,
                vm.salesorder.ship_postal,
                vm.salesorder.ship_country
            ].filter(function(item) { return item && item != ''; });
            vm.shipment.final_destination = vm.delivery_address.join(', ');

            vm.shipment.name = vm.salesorder.name;
            if(vm.salesorder.jansy_so) {
                vm.shipment.name = vm.salesorder.jansy_so + ' - ' + vm.shipment.name;
            }
            vm.shipment.name = vm.shipment.name.substring(0,72);

            if (vm.salesorder.name) {
                SlateTitle.set('Ship ' + vm.salesorder.name + ' - Purchase Orders');
            } else {
                SlateTitle.set('Ship - Purchase Orders');
            }

            vm.salesorder.line_items.forEach(function(item) {
                promises.push(item.getBoms());
                console.log('getting bom items');
            });

            return $q.all(promises).then(function () {
                vm.loading = false;
            });

        });
    }

})();
