(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportComponentsInstransit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.components_intransit.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $http, $location, $httpParamSerializer, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'reports/components_intransit/';
        vm.getLink = getLinkSrv;

        var modelPromise;

        vm.getDownloadUrl = getDownloadUrl;
        vm.updateFromFilter = updateFromFilter;

        vm.data = [];
        vm.filters = {
            exclude_trucking: $location.search().exclude_trucking,
            client_id: $location.search().client_id
        };
        vm.loading = true;

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        function updateFromFilter() {
            vm.loading = true;

            if(vm.filters.exclude_trucking) {
                $location.replace().search('exclude_trucking', vm.filters.exclude_trucking);
            } else {
                $location.replace().search('exclude_trucking', null);
            }
            if(vm.filters.client_id) {
                $location.replace().search('client_id', vm.filters.client_id);
            } else {
                $location.replace().search('client_id', null);
            }

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
            });

            modelPromise.then(function(resp) {
                vm.data = resp.data.payload;
                vm.loading = false;
            });
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }
    }
})();