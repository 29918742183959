(function() {
    "use strict";

    angular
        .module('slate.workflows')
        .directive('slateWorkflowRow', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/workflows/workflows.row.dir.html',
            scope: {
                workflow: '=',
                onEdit: '&',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $stateParams, $location, $timeout, $anchorScroll, $q, getLinkSrv, SlateWorkflow, SlateWorkflowEdit, SlateTimeTracks, SlateTimeTrack, SlateAddTime) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;

        vm.time_tracks = new SlateTimeTracks();
        vm.getLinkSrv = getLinkSrv;

        vm.editWorkflow = editWorkflow;
        vm.addTime = addTime;
        vm.toggleCompletion= toggleCompletion;

        init();

        if($location.hash() == "workflow-"+vm.workflow.id) {
            vm.toggled = true;
            $timeout(function() {
                $anchorScroll.yOffset = 120;
                $anchorScroll();
            }, 300);
        }


        function init() {
            var modelPromise;
            var listFilters;
            vm.loading = true;

            vm.time_tracks.getList(
                {
                    parent_fk: vm.workflow.id,
                    parent_type: 'milestone'
                }
            ).then(function(){
                vm.loading = false;
            });


        }

        function editWorkflow() {
            SlateWorkflowEdit.edit(vm.workflow).then(function(workflow) {
                $state.go(".", { "#": $location.hash() });
                $state.reload();
            });
        }

        function addTime() {
            var timetrack = new SlateTimeTrack();

            timetrack.parent_type = 'milestone';
            timetrack.parent_fk = vm.workflow.id;

            SlateAddTime.edit(timetrack).then(function() {
                var params = $stateParams;
                //$state.go(".", { "#": $location.hash() });
                //$state.reload();
                vm.workflow.loadFromServer().then(function() {
                    vm.time_tracks.getList(
                        {
                            parent_fk: vm.workflow.id,
                            parent_type: 'milestone'
                        }
                    );
                });
            }, function() {
                console.log('canceled');
            });
        }

        function toggleCompletion() {

                vm.workflow.complete = !vm.workflow.complete;
                vm.workflow.saveModel();


        }

    }


})();
