(function() {
    "use strict";

    angular
        .module('slate.library.settings')
        .service('SlateSettings', ConfigService);


    function ConfigService($q, SlateConfig) {

        var srv = {
            getConfig: getConfig,
        };

        var configs = {};

        return srv;


        function getConfig(key) {
            var deferred = $q.defer();

            if(configs.hasOwnProperty(key)) {
                deferred.resolve(configs[key].data);
                return deferred.promise;
            }

            configs[key] = {data: new SlateConfig() };
            configs[key].data.loadFromServer(key).then(function() {
                deferred.resolve(configs[key].data);
            });
            
            return deferred.promise;
        }
    }

})();
