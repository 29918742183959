(function () {
    'use strict';

    angular
        .module('slate.billings')
        .directive('slateBillingEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/billings/billing.edit.dir.html',
            scope: {
                billing: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $http,
        $state,
        $translate,
        getLinkSrv,
        SlateAuth,
        SlateSalesOrder,
        SlateBillingApprovalSrv,
        SlateShipments,
        SlateSFLinkage
    ) {
        /* jshint validthis: true */
        const vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.countries = Countries;

        vm.sf_linkage = SlateSFLinkage;

        vm.salesOrder = new SlateSalesOrder();
        vm.salesOrder.loadFromServer(vm.billing.salesorder);

        vm.shipments = new SlateShipments();
        vm.shipmentsLoading = true;
        vm.shipments.getList({
            salesorder: vm.billing.salesorder
            // "delivery_type": 'customer',
        }).then(() => {
            vm.shipmentsLoading = false;
        });

        vm.currentDateTime = new Date();

        vm.geolocate = geolocate;
        vm.getLocation = getLocation;

        $scope.$watch('vm.billing.id', (old_val, new_val) => {
            if (vm.billing.id && !vm.saving) {
                vm.sf_linkage.parent_type = 'billing';
                vm.sf_linkage.parent_fk = vm.billing.id;
            }
        });

        $scope.$watch('vm.billing.shipment', (new_val, old_val) => {
            if (new_val === old_val) { return false; }
            if (!new_val) { return false; }

            if (vm.billing.shipment_info.freight_handler !== 'customer') {
                vm.billing.delivery_street = vm.billing.shipment_info.final_destination_street;
                vm.billing.delivery_city = vm.billing.shipment_info.final_destination_city;
                vm.billing.delivery_state = vm.billing.shipment_info.final_destination_state;
                vm.billing.delivery_postal = vm.billing.shipment_info.final_destination_postal;
                vm.billing.delivery_country = vm.billing.shipment_info.final_destination_country;
            } else {
                vm.billing.delivery_street = vm.billing.shipment_info.origin_street;
                vm.billing.delivery_city = vm.billing.shipment_info.origin_city;
                vm.billing.delivery_state = vm.billing.shipment_info.origin_state;
                vm.billing.delivery_postal = vm.billing.shipment_info.origin_postal;
                vm.billing.delivery_country = vm.billing.shipment_info.origin_country;
            }

            return true;
        });

        vm.saveObject = saveObject;

        vm.canViewPDF = canViewPDF;
        vm.isComplete = isComplete;
        vm.isEditable = isEditable;

        vm.shouldHighlight = shouldHighlight;

        vm.baseStatus = vm.billing.status;

        vm.BillingStatusOptions = ['Draft',
            'Ready',
            'Approved',
            'Transmitted',
            'Paid',
            'Exception Hold'];
        vm.BillingStatusAvailable = [vm.billing.status, 'Exception Hold'];

        if (['Draft', 'Ready'].includes(vm.billing.status)) { vm.BillingStatusAvailable.push('Approved'); }
        if (vm.billing.status === 'Exception Hold') { vm.BillingStatusAvailable.push('Ready'); }

        if (vm.auth.perms.billings_can_override) {
            vm.BillingStatusAvailable = ['Draft',
                'Ready',
                'Approved',
                'Transmitted',
                'Paid',
                'Exception Hold'];
        }

        vm.cantSaveReasons = [];

        function isComplete() {
            const cantSaveReasons = [];
            const today = new Date();
            const startDate = new Date(today.getTime() - 31 * 24 * 60 * 60 * 1000);
            if (vm.billing.date.getTime() < startDate) {
                cantSaveReasons.push('Date too far in past.');
            }
            if (vm.billing.status === 'Approved' && !vm.billing.company_info.billing_terms) {
                cantSaveReasons.push('Approved billing must have company with billing terms set by accting.');
            }
            if (!isEditable()) {
                cantSaveReasons.push('Can only save billings that started as Draft and Ready.');
            }
            if (!vm.billing.line_items.every((lineitem) => lineitem.qty > 0)) {
                cantSaveReasons.push('All line items must have a qty above 0');
            }
            if (vm.billing.billing_type === 'Final') {
                if (!vm.billing.getIsNonInventory()) {
                    if (!vm.billing.shipment) {
                        cantSaveReasons.push('Must select a shipment.');
                    }
                    [
                        'delivery_street',
                        'delivery_city',
                        'delivery_state',
                        'delivery_postal',
                        'delivery_country'
                    ].forEach((field) => {
                        if (!vm.billing[field]) {
                            cantSaveReasons.push(`Delivery address ( ${field} ) must be filled in.`);
                        }
                    });
                }
            }
            vm.cantSaveReasons = cantSaveReasons;
            if (vm.cantSaveReasons.length) {
                return false;
            }
            return true;
        }
        vm.getWhyCantSave = function () {
            return vm.cantSaveReasons.join('  ');
        };

        function isEditable() {
            if (vm.auth.perms.billings_can_override) { return true; }
            if (vm.baseStatus === 'Draft') { return true; }
            if (vm.baseStatus === 'Ready') { return true; }

            return false;
        }

        function saveObject() {
            const deferred = $q.defer();

            if (vm.saving === true) {
                return false;
            }

            if (!isEditable()) { return false; }

            SlateBillingApprovalSrv.edit(vm.billing).then(() => {
                vm.saved = false;
                vm.saving = true;
                vm.error = false;

                vm.billing.saveModel().then(() => {
                    const promises = [];

                    if (vm.fileSrv) {
                        promises.push(vm.fileSrv.uploadFiles('salesorder', vm.billing.salesorder));
                    }

                    return $q.all(promises).then(() => {
                        $timeout(() => {
                            vm.saving = false;
                            vm.saved = true;
                            vm.billingFrom.$setPristine();
                            vm.baseStatus = vm.billing.status;
                            if ($state.current.name !== 'billingEditPage') {
                                $state.transitionTo(
                                    'billingEditPage',
                                    { id: vm.billing.id },
                                    { location: 'replace' }
                                );
                            }
                        }, 300);
                    }, () => {
                        vm.saving = false;
                        vm.error = true;
                    });
                }, () => {
                    vm.saving = false;
                    vm.error = true;
                });
            }, () => {

            });

            return deferred.promise;
        }

        function canViewPDF() {
            return vm.baseStatus === 'Transmitted' || vm.baseStatus === 'Paid';
        }

        function getPDFURL() {
            const deferred = $q.defer();
            const apiUrl = `${API_URL}salesforce/fetchpdfurl/`;

            const promise = $http.get(apiUrl, {
                params: { parent_type: 'billing', parent_fk: vm.billing.id }
            });
            promise.then((response) => {
                deferred.resolve(response.data.payload);
            }, () => {
                deferred.reject();
            });

            return deferred.promise;
        }
        if (canViewPDF()) {
            getPDFURL().then((url) => {
                vm.pdfUrl = url;
            }, () => {

            });
        }

        function shouldHighlight(etd) {
            return Math.abs(dateFns.differenceInDays(etd, vm.billing.date)) <= 7;
        }

        vm.sessionToken = uuidv4();

        function getLocation(q) {
            const deferred = $q.defer();

            // var url = "/geolocate/";
            const url = `${API_URL}utils/geocomplete/`;
            const params = {
                sessiontoken: vm.sessionToken,
                lang: $translate.use(),
                address: q
            };

            if (q.length < 5) {
                deferred.reject();
                return deferred.promise;
            }

            $http.get(url, {
                params
            }).then((response) => {
                const locations = [];

                angular.forEach(response.data.predictions, (item) => {
                    const name = item.description;

                    locations.push({ name, details: item });
                });
                deferred.resolve(locations);
            });

            return deferred.promise;
        }

        function geolocate(address, variable, addy_prefix) {
            const url = `${API_URL}utils/geolocate/`;
            const params = {
                lang: $translate.use(),
                address
            };

            if (address.length < 5) {
                vm.shipment[variable] = { lat: 0, lng: 0 };
                return false;
            }

            const promise = $http.get(url, {
                params
            }).then((response) => {
                let results = [];
                vm.billing[variable] = { lat: 0, lng: 0 };

                results = response.data.results;
                if (results && results.length) {
                    vm.billing[variable].lat = results[0].geometry.location.lat;
                    vm.billing[variable].lng = results[0].geometry.location.lng;
                }

                const comps = results[0].address_components;
                let street_num = comps.find((item) => item.types.includes('street_number'));
                street_num = street_num ? street_num.short_name : '';
                let street_name = comps.find((item) => item.types.includes('route'));
                street_name = street_name ? street_name.short_name : 'Unkown St.';
                const city = comps.find((item) => item.types.includes('locality'));
                const state = comps.find((item) => item.types.includes('administrative_area_level_1'));
                const postal = comps.find((item) => item.types.includes('postal_code'));
                const country = comps.find((item) => item.types.includes('country'));

                vm.billing[`${addy_prefix}_street`] = [street_num, street_name].join(' ');
                vm.billing[`${addy_prefix}_city`] = city ? city.short_name : 'Unkown City.';
                vm.billing[`${addy_prefix}_state`] = state ? state.short_name : '';
                vm.billing[`${addy_prefix}_postal`] = postal ? postal.long_name : '';
                vm.billing[`${addy_prefix}_country`] = country ? country.long_name : 'United States';
            });

            return promise;
        }
    }
})();
