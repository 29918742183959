(function () {
    'use strict';

    angular
        .module('slate.library.orders')
        .factory('SlateOrders', ModelList)
        .factory('SlateOrder', ModelDetail);

    const apiUrl = `${API_URL}orders/orders/`;

    function ModelList($http, $q, SlateOrder) {
        // Set to url relative to api root.

        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.download = download;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let promise;
            const promises = [];

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateOrder();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.totalItems = response.data.totalItems;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function download(filters) {

        }
    }

    function ModelDetail($http, $q, SlateGoods) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', def: null, readonly: true },

            { name: 'name' },
            { name: 'composite_name', def: '', readonly: true },
            { name: 'slug', readonly: true },
            { name: 'status', def: 'new' },
            { name: 'sf_status', readonly: true },
            { name: 'order_number', readonly: true },

            { name: 'on_hold', def: false },
            { name: 'hold_reason' },
            { name: 'on_system_hold', def: false },

            { name: 'project' },
            { name: 'project_info', readonly: true },
            { name: 'projects', type: 'list', def: [] },

            { name: 'finished_good' },
            { name: 'finished_good_info', readonly: true },
            { name: 'bom' },

            { name: 'client' },
            { name: 'client_info', readonly: true },
            { name: 'sales_mgr' },
            { name: 'sales_mgr_info', readonly: true },
            { name: 'proj_mgr' },
            { name: 'proj_mgr_info', readonly: true },

            { name: 'projected_amount', type: 'float' },
            { name: 'projected_margin' },
            { name: 'close_date', type: 'dateOnly', readonly: true },
            { name: 'invoice_date', type: 'dateOnly' },
            { name: 'po_received_date', type: 'dateOnly' },
            { name: 'qty' },

            { name: 'reorder', def: false },
            { name: 'client_po' },
            { name: 'stock_or_custom' },

            { name: 'opp_type' },

            { name: 'notes' },

            { name: 'is_intercompany', def: false, readonly: true },
            { name: 'intercompany_rep' },
            { name: 'intercompany_value', type: 'float', def: 0.0 },
            { name: 'intercompany_op_num' },
            { name: 'alt_client' },

            { name: 'updated', readonly: true, type: 'date' },
            { name: 'created', readonly: true, type: 'date' },
            { name: 'created_by', readonly: true },
            { name: 'created_by_info', readonly: true }

        ];
        /* beautify preserve:end */

        model.prototype.opp_types = [
            'Design',
            'Primary',
            'Secondary',
            'Turn Key',
            'Protective Packaging',
            'Gift Set',
            'Protective Packaging',
            'Product Design'
        ];

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.splitSalesorders = splitSalesorders;
        model.prototype.cleanupOrderplan = cleanupOrderplan;
        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;
                if (field.type == 'child_list') {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            const x = new Date();
            const currentTimeZoneOffsetInHours = x.getTimezoneOffset() / 60;

            if (typeof payload !== 'object') {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                self[field.name] = payload[field.name];

                if (field.type == 'date' && payload[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type == 'dateOnly' && payload[field.name]) {
                    const parts = self[field.name].split('-');
                    self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
                }

                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) { console.log('didnt parse json', e); }
                }

                if (field.type == 'float' && payload[field.name]) {
                    self[field.name] = parseFloat(self[field.name]);
                    if (isNaN(self[field.name])) {
                        self[field.name] = null;
                    }
                }
            });

            self.composite_name = `OR${self.order_number} ${self.name}`;
            if (self.project_info && self.project_info.job_number) {
                self.composite_name = `${self.project_info.job_number}-${self.composite_name}`;
            }

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id, show_goods) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;
            const filters = {};

            let promise;

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            if (show_goods) { filters.show_goods = true; }

            promise = $http.get(modelUrl, {
                params: filters
            });

            promise.then((response) => {
                const promises = [];

                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if (field.type == 'date' && self[field.name]) {
                        // data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth()+1) + '-' + self[field.name].getDate();
                        data[field.name] = self[field.name].toISOString();
                    }
                    if (field.type == 'dateOnly' && self[field.name]) {
                        data[field.name] = `${self[field.name].getFullYear()}-${self[field.name].getMonth() + 1}-${self[field.name].getDate()}`;
                        // data[field.name] = self[field.name].toISOString();
                    }
                    if (field.trim && data[field.name]) {
                        data[field.name] = data[field.name].substr(0, field.trim);
                    }
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then((response) => {
                const promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function splitSalesorders() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/split-sos/`;
            } else {
                deferred.reject();
                return deferred.promise;
            }

            promise = $http.post(modelUrl);
            promise.then((response) => {
                deferred.resolve(self);
            }, () => {
                deferred.reject(self);
            });

            return deferred.promise;
        }

        function cleanupOrderplan() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/cleanup-orderplan/`;
            } else {
                deferred.reject();
                return deferred.promise;
            }

            promise = $http.post(modelUrl);
            promise.then((response) => {
                deferred.resolve(self);
            }, () => {
                deferred.reject(self);
            });

            return deferred.promise;
        }
    }
})();
