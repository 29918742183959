(function () {
    "use strict";

    angular
        .module('slate.app')
        .directive('numberInputOld', function ($filter) {
            return {
                require: 'ngModel',
                link: function (scope, elem, attrs, ngModelCtrl) {

                    ngModelCtrl.$formatters.push(function (modelValue) {
                        return setDisplayNumber(modelValue, true);
                    });

                    // it's best to change the displayed text using elem.val() rather than
                    // ngModelCtrl.$setViewValue because the latter will re-trigger the parser
                    // and not necessarily in the correct order with the changed value last.
                    // see http://radify.io/blog/understanding-ngmodelcontroller-by-example-part-1/
                    // for an explanation of how ngModelCtrl works.
                    ngModelCtrl.$parsers.push(function (viewValue) {
                        setDisplayNumber(viewValue);
                        return setModelNumber(viewValue);
                    });

                    // occasionally the parser chain doesn't run (when the user repeatedly
                    // types the same non-numeric character)
                    // for these cases, clean up again half a second later using "keyup"
                    // (the parser runs much sooner than keyup, so it's better UX to also do it within parser
                    // to give the feeling that the comma is added as they type)
                    elem.bind('keyup focus', function () {
                        setDisplayNumber(elem.val());
                    });

                    function setDisplayNumber(val, formatter) {
                        var format = attrs.format;
                        var displayValue;
                        var decimalPos = -1;
                        if(!format) { format = '0,0.[00000]'; }
                        displayValue = numeral(val).format(format);

                        if (typeof val=='string') {
                            if(val.slice(-1) === '.') {
                                displayValue = displayValue+'.';
                            }
                            if(val.indexOf('.') != -1) {
                                decimalPos = val.length - val.indexOf('.') - 1;
                                if(decimalPos <= 4) {
                                    displayValue = val;
                                }
                            }
                        }




                        if (typeof formatter !== 'undefined') {
                            return (displayValue === '') ? 0 : displayValue;
                        } else {
                            elem.val((displayValue === '0') ? '0' : displayValue);
                        }
                    }

                    function setModelNumber(val) {
                        var displayValue;
                        var format = attrs.rawformat;
                        if(!format) { format = '0.[00000]'; }
                        displayValue = numeral(val).format(format);

                        return numeral(displayValue).value();
                    }
                }
            };
        });

})();