(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .directive('slateSoLineItemList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/salesorders/so.lineitem.list.dir.html',
            scope: {
                salesOrder: '=',
                hidenew: '=?',
                showTotals: '=?',
                showBoms: '=?',
                selectedBomItems: '=?',
                bomItemUnitCosts: '=?',
                bomMargin: '=?',
                fields: '=?',
                projectId: '=?',
                dirty: '=?',
                savingPO: "=?savingpo"
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope, $window, $state, $location, $q, $timeout,
        SlateItemStockDialog, SlateSOLineItemPriceTierEditSrv,
        SlateSOLineItems, SlateSOLineItem, SlateSOLineItemEditSrv, SlateBOMItems,
        SlatePriceTier, getLinkSrv, SlateAuth, SlateSOReleaseEditSrv) {
        /* jshint validthis: true */
        var vm = this;

        if (!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'photo',
                'sort_order',
                'jansy_pn',
                'client_pn',
                'name',
                'qty',
                'unit_name',
                'total_units_billed',
                'items_shipped',
                'runningprice',
                'runningmargin',
                'bomprice',
                'bommargin',
                'sellprice',
                'sellprice_total',

            ];
            /* beautify preserve:end */
        }

        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;
        vm.loading = false;

        vm.editLineItem = editLineItem;
        vm.addNewLineItem = addNewLineItem;
        vm.deleteLineItem = deleteLineItem;

        vm.displayErrorEdit = displayErrorEdit;
        vm.displayErrorTrash = displayErrorTrash;

        vm.getPriceTotal = getPriceTotal;
        vm.getSellTotal = getSellTotal;
        vm.getMarginTotal = getMarginTotal;
        vm.getBOMMarginTotal = getBOMMarginTotal;

        vm.getStyle = getStyle;

        vm.createPO = createPO;
        vm.hasSelectedBomItem = hasSelectedBomItem;
        vm.canCreatePO = canCreatePO;
        vm.selectPriceTier = selectPriceTier;

        vm.editRelease = editRelease;
        vm.deleteRelease = deleteRelease;

        vm.stock = stockDialog;

        if (!vm.selectedBomItems) { vm.selectedBomItems = {}; }

        function getPriceTotal() {
            var total = Big(0.0);
            if (!Array.isArray(vm.salesOrder.line_items)) { return 0.0; }
            vm.salesOrder.line_items.forEach(function (item) {
                total = total.add(item.getCostTotal());
            });
            return total.toFixed(5);
        }

        function getBOMItemUnitCosts() {
            var bomItems = new SlateBOMItems();
            var priceTier = new SlatePriceTier();
            var promises = [];
            vm.salesOrder.line_items.forEach(function (line_item) {
                var deferred = $q.defer();
                bomItems.getList({ bom: line_item.bom }).then(function () {
                    var bomPromises = [];
                    bomItems.list.forEach(function (item) {
                        if (!vm.salesOrder.bom_item_price_tiers[item.id]) {
                            vm.bomItemUnitCosts[item.id] = item.component_info.buy_price;
                        } else {
                            bomPromises.push(priceTier.loadFromServer(vm.salesOrder.bom_item_price_tiers[item.id]).then(function () {
                                vm.bomItemUnitCosts[item.id] = priceTier.getPrice(item.getItemTotal(line_item.qty));
                            }));
                        }
                    });
                    $q.all(bomPromises).then(function () {
                        deferred.resolve();
                    });
                });
                promises.push(deferred.promise);
            });
            return $q.all(promises);
        }

        function getBOMTotals() {
            vm.loading = true;
            var promises = [];
            vm.salesOrder.line_items.forEach(function (item) {
                promises.push(item.getBOMPrice(vm.bomItemUnitCosts));
            });
            $q.all(promises).then(function () {
                vm.loading = false;
            });
        }
        getBOMItemUnitCosts().then(function () {
            getBOMTotals();
        });

        function getBOMPriceTotal() {
            var total = Big(0.0);
            if (!Array.isArray(vm.salesOrder.line_items)) { return 0.0; }
            vm.salesOrder.line_items.forEach(function (item) {
                total = total.add(item.getBOMCostTotal());
            });
            return total.toFixed(5);
        }


        function getSellTotal() {
            var total = Big(0.0);
            if (!Array.isArray(vm.salesOrder.line_items)) { return 0.0; }
            vm.salesOrder.line_items.forEach(function (item) {
                total = total.add(item.getSellTotal());
            });
            return total.toFixed(5);
        }

        function getMarginTotal() {
            var sell = getSellTotal();
            var cost = getPriceTotal();
            var total = Big(0.0);
            if (sell == 0.00000) { return 0.0; }
            total = total.add(sell).minus(cost);
            total = total.div(sell);
            total = total.times(100.0);
            return total.toFixed(2);
        }

        function getBOMMarginTotal() {
            var sell = getSellTotal();
            var cost = getBOMPriceTotal();
            var total = Big(0.0);
            if (sell == 0.00000) { return 0.0; }
            total = total.add(sell).minus(cost);
            total = total.div(sell);
            total = total.times(100.0);
            vm.bomMargin = total.toFixed(2);
            return total.toFixed(2);
        }

        function addNewLineItem() {
            var new_lineitem = new SlateSOLineItem();
            SlateSOLineItemEditSrv.edit(new_lineitem, { salesOrder: vm.salesOrder }).then(function (lineitem) {
                if (vm.salesOrder.id) { lineitem.was_revised = true; }
                vm.dirty = true;
                vm.salesOrder.line_items.push(lineitem);
                getBOMItemUnitCosts().then(function () {
                    lineitem.getBOMPrice(vm.bomItemUnitCosts);
                });
            }, function () {
                //do nothing if canceled?
            });
        }

        function editLineItem(lineitem, $idx) {
            SlateSOLineItemEditSrv.edit(lineitem, { salesOrder: vm.salesOrder }).then(function (new_lineitem) {
                if (lineitem.id || vm.salesOrder) { new_lineitem.was_revised = true; }
                vm.dirty = true;
                vm.salesOrder.line_items[$idx] = new_lineitem;
                getBOMItemUnitCosts().then(function () {
                    new_lineitem.getBOMPrice(vm.bomItemUnitCosts);
                });
            }, function () { });
        }

        function deleteLineItem(lineitem, $idx) {
            if (!vm.auth.user.permissions.salesorders_can_delete_li && vm.salesOrder.status != 'Draft') {
                alert("Once out of draft mode, you can not delete a salesorder line item. Please contact Derek or Nicole B for help.");
                return false;
            }

            if (lineitem.po_created) {
                alert("A purchase order has been created with this line item. It cannot be deleted.");
            } else {
                lineitem.toDelete = !lineitem.toDelete;
            }
        }

        function displayErrorEdit(lineitem, $idx) {
            alert("This SO is closed. The SO line item can no longer be edited.");
        }

        function displayErrorTrash(lineitem, $idx) {
            alert("This SO is closed. The SO line item can no longer be deleted.");
        }

        function getStyle(field) {
            if (field == 'name') {
                return { width: '100%' };
            }

            return {
                'overflow': 'hidden',
                'white-space': 'nowrap',
                'text-overflow': 'ellipsis',
            };
        }

        function createPO() {
            $scope.$emit('createPO');
        }

        function hasSelectedBomItem() {
            if (!vm.salesOrder.id) { return false; }
            return vm.salesOrder.line_items.some(function (item) {
                if (!item.selectedBomItems) { return false; }

                if (Object.keys(item.selectedBomItems).length > 0) { return true; }

                return false;
            });
        }

        function canCreatePO() {
            if (!vm.hasSelectedBomItem()) { return false; }
            if (['Draft', 'SO', 'SO Revision', 'Approved', 'Emailed'].includes(vm.salesOrder.status)) { return false; }
            if (!vm.salesOrder.id) { return false; }
            if (!vm.salesOrder.status) { return false; }
            if (vm.dirty) { return false; }
            if (!vm.salesOrder.company_info.billing_terms) { return false; }
            if ((vm.salesOrder.order_info.on_hold ||
                vm.salesOrder.company_info.on_hold ||
                vm.salesOrder.order_info.on_system_hold) &&
                !vm.auth.user.permissions.companies_can_override_holds) { return false; }

            return true;
        }

        function stockDialog(componentId) {
            SlateItemStockDialog.showStock(componentId);
        }

        function selectPriceTier(lineItem, $idx) {
            SlateSOLineItemPriceTierEditSrv.edit(lineItem).then(function (new_lineitem) {
                if (!new_lineitem.bomTotal) {
                    new_lineitem.getBOMPrice(vm.bomItemUnitCosts);
                }
                vm.salesOrder.line_items[$idx] = new_lineitem;
            }, function () {

            });
        }

        function editRelease(release, lineItem, $idx) {
            SlateSOReleaseEditSrv.edit(release, { lineItem: lineItem }).then(function (new_release) {
                console.log(new_release);
                if (!new_release) { return false; }
                lineItem.releases[$idx] = new_release;
            });
        }

        function deleteRelease(release, lineitem, $idx) {
            release.toDelete = !release.toDelete;
        }


    }


})();
