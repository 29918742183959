(function () {
    'use strict';

    angular
        .module('slate.library.projects')
        .factory('SlatePriceTiers', ModelList)
        .factory('SlatePriceTier', ModelDetail);

    const apiUrl = `${API_URL}projects/pricing/`;

    function ModelList($http, $q, SlatePriceTier) {
        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let promise;
            const promises = [];

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model

                self.count = response.data.count;

                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlatePriceTier();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.pagination = {
                    total: parseInt(response.data.pagination.total),
                    page: parseInt(response.data.pagination.page),
                    count: parseInt(response.data.pagination.count)
                };

                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', readonly: true },

            { name: 'quote_number' },

            { name: 'vendor' },
            { name: 'vendor_info' },

            { name: 'component' },
            { name: 'component_info' },

            { name: 'prices', type: 'json', def: [] },
            { name: 'notes' },

            { name: 'quoted_on', type: 'dateOnly' },
            { name: 'created', readonly: true },
            { name: 'created_by' },
            { name: 'created_by_info' },
            { name: 'expires', type: 'dateOnly' }
        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.getModelData = getModelData;
        model.prototype.saveModel = saveModel;
        model.prototype.getPrice = getPrice;
        model.prototype.isValid = isValid;

        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = _.cloneDeep(field.def);
                if (field.type == 'date' && !self[field.name]) {
                    self[field.name] = new Date();
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            let promise;

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                console.log('Tried to load price tier from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then((response) => {
                const promises = [];

                return self.loadFromPayload(response.data).then(() => {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                if (!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if (field.type == 'dateOnly' && payload[field.name]) {
                    if (typeof payload[field.name] === 'string') {
                        self[field.name] = dateFns.parse(payload[field.name]);
                    }
                }
                if (field.type == 'json' && payload[field.name] && typeof payload[field.name] === 'string') {
                    try {
                        self[field.name] = JSON.parse(payload[field.name]);
                    } catch (e) {
                        console.log('could not parse json');
                        console.log(field.name, payload[field.name]);
                        console.log(e);
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            const self = this;
            const data = {};

            angular.forEach(fields, (field) => {
                if ((!field.readonly || field.name == 'id') && typeof (self[field.name]) !== 'undefined') {
                    data[field.name] = self[field.name];
                    if (field.type == 'dateOnly' && self[field.name]) {
                        data[field.name] = dateFns.format(self[field.name], 'YYYY-MM-DD');
                    }
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name], (key, value) => {
                            if (key == '$$hashKey') {
                                return undefined;
                            }
                            return value;
                        });
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            const modelUrl = apiUrl;
            let promise;

            const data = self.getModelData();

            promise = $http.post(modelUrl, data);
            promise.then((response) => {
                self.loadFromPayload(response.data);
                deferred.resolve(self);
            }, () => {
                deferred.reject(self);
            });

            return deferred.promise;
        }

        function getPrice(qty) {
            /* jshint validthis: true */
            const self = this;

            let maxQty = null;
            let price = null;

            self.prices.forEach((priceBreak) => {
                const quantity = parseInt(priceBreak.quantity);
                if (maxQty === null || qty >= quantity && quantity > maxQty) {
                    maxQty = quantity;
                    price = priceBreak.price;
                }
            });

            return price;
        }

        function isValid() {
            /* jshint validthis: true */
            const self = this;

            return dateFns.isBefore(new Date(), dateFns.endOfDay(self.expires));
        }
    }
})();
