(function() {
    "use strict";

    angular
        .module('slate.projects')
        .directive('slateProjectMultiselect', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/projects/multiselector/project.multiselector.dir.html',
            scope: {
                projects: '=',
                hasProjects: '=?',
                company: '=?',
                readonly: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateRFQResponses, SlateShortRFQResponse) {
        /* jshint validthis: true */
        var vm = this;

        $scope.$watchCollection('vm.projects', function() {
            vm.projects = vm.projects.filter(function(item, idx, arr) {
                if(item == null) { return false; }
                if(item == '') { return false; }
                return true;
            });
        });

        $scope.$watch('vm.new_project', function() {
            if(vm.new_project) { vm.projects.push(vm.new_project); }
            $timeout(function() {
                vm.new_project = null;
                vm.new_project_obj = null;
                vm.new_project_name = null;
            }, 300);
        });


    }


})();
