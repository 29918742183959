(function () {
    "use strict";

    angular
        .module('slate.library.projects')
        .factory('SlateProjects', ModelList)
        .factory('SlateProject', ModelDetail);

    function ModelList($http, $q, SlateProject) {
        // Set to url relative to api root.
        var apiUrl = API_URL + 'projects/project/';

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if (typeof filters === "object") {
                self.filters = filters;
            }

            if(self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            promise = $http.get(apiUrl, {
                params: self.filters,
                timeout: self.canceller.promise
            });

            promise.then(function (response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function (item) {
                    var itemModel = new SlateProject();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.totalItems = response.data.totalItems;
                self.permissions = response.data.permissions;
                self.canceller = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }

    }

    function ModelDetail($http, $q, SlateLocations, SlateContactMethods, SlatePriceTier, SlateWatchList) {
        // Set to url relative to api root.
        var apiUrl = API_URL + 'projects/project/';

        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            { name: 'id', def: null, readonly: true },
            { name: 'name', def: null },
            { name: 'slug', def: null, readonly: true },
            { name: 'parent', def: null, readonly: true },
            { name: 'parent_type', def: null },
            { name: 'parent_fk', def: null },
            { name: 'parent_slug', def: null, readonly: true },
            { name: 'parent_num', def: null, readonly: true },
            { name: 'parent_info', def: null, readonly: true },

            { name: 'prefix', def: null },
            { name: 'composite_num', def: null, readonly: true },
            { name: 'composite_name', def: null, readonly: true },
            { name: 'job_number', def: null, readonly: true },
            { name: 'component_number', def: null, readonly: true },
            { name: 'variation_number', def: null, readonly: true },
            { name: 'boss_job_number', def: null },
            { name: 'closed', def: null },
            { name: 'brief', def: null },
            { name: 'ordered', def: null },
            { name: 'delivered', def: null },
            { name: 'status', def: null },
            { name: 'spend', def: 0.00, type: 'float' },
            { name: 'proj_margin', def: 0, type: 'float' },
            { name: 'close_date', type: 'dateOnly' },
            { name: 'custom_cartonmarking_field'},

            { name: "finished_good" },
            { name: "stock_component" },
            { name: "non_inventory", def: false },
            { name: "supplier" },
            { name: "vendor" },
            { name: 'vendor_info', def: null, readonly: true },
            { name: "internal_item_number" },
            { name: "vendor_item_number" },
            { name: "client_item_number" },
            { name: "secondary_client_item_number" },
            { name: "unit_name", def: "ea" },
            { name: "scrap_factor"},
            { name: "cbm", def: "0"},
            { name: "qty_per_pallet", def: 0, type: 'float' },
            { name: 'buy_price', def: 0.00, type: 'float' },
            { name: 'sell_price', def: 0.00, type: 'float' },
            { name: 'total_inventory', def: 0.00, type: 'float' },
            { name: 'price_tiers', type:'child_list', model: SlatePriceTier },

            { name: "material" },
            { name: "category" },

            { name: 'hours_spent', def: null, readonly: true, type: 'float' },
            { name: 'total_expenses', def: 0, readonly: true, type: 'float' },

            { name: 'project_type', def: null },
            { name: 'breadcrumbs', def: [], readonly: true },

            { name: 'variations_total', def: 0, readonly: true },

            { name: 'is_intercompany', def: false, },
            { name: 'intercompany_rep' },
            { name: 'alt_client' },

            { name: 'created', def: null, readonly: true },
            { name: 'updated', def: null, readonly: true },
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.get_client = get_client;
        model.prototype.get_root_project = get_root_project;
        model.prototype.makeOneToOne = makeOneToOne;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function (field) {
                self[field.name] = _.cloneDeep(field.def);
                if(field.type=="child_list") {
                    self[field.name] = [];
                }
            });


            self.watcher = new SlateWatchList();
            self.watched = false;

            deferred.resolve(self);
            return deferred.promise;
        }



        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function (field) {
                self[field.name] = payload[field.name];
                if (
                    (typeof payload[field.name] == 'undefined' && typeof self[field.name] == 'undefined') ||
                    (payload[field.name] === null && self[field.name] === null)
                ) {
                    self[field.name] = field.def;
                    return self[field.name];
                }

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if (field.type == 'dateOnly' && payload[field.name]) {
                    var parts = self[field.name].split('-');
                    self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
                }

                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) { console.log('json parse failed'); }
                }

                if(field.type == 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach(function(item, idx, array) {
                            var obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch(e) {
                        console.log('error loading: ', e);
                    }
                }
            });

            self.project_nomenclature = "project";
            if (self.component_number != "0") {
                self.project_nomenclature = "component";
            }
            if (self.variation_number != "0") {
                self.project_nomenclature = "variation";
                if (self.parent_info) {
                    self.variation_name = self.parent_info.name + ' - ' + self.name;
                }

            }
            if (self.finished_good) {
                self.project_nomenclature = 'finished_good';
            }


            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function (response) {
                var promises = [];

                return self.loadFromPayload(response.data.payload).then(function () {
                    self.permissions = response.data.permissions;
                    promises.push(self.watcher.isWatched(self.id).then(function (isWatched) {
                        // anything other that true is a no or an error (which is a no)
                        if (isWatched === true) {
                            self.watched = isWatched;
                        } else {
                            self.watched = false;
                        }
                    }));

                    return $q.when(promises).then(function () {
                        deferred.resolve(self);
                    });

                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            angular.forEach(fields, function (field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                }
                if (field.type == "dateOnly" && self[field.name]) {
                    data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth() + 1) + '-' + self[field.name].getDate();
                    //data[field.name] = self[field.name].toISOString();
                }

                if (field.type == 'json') {
                    data[field.name] = JSON.stringify(self[field.name], function (key, value) {
                        if (key == "$$hashKey") {
                            return undefined;
                        }
                        return value;
                    });
                }

            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function (response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function (response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

        function get_client() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (self.breadcrumbs && self.breadcrumbs.length > 1) {
                return self.breadcrumbs[0];

            }
            return null;
        }

        function get_root_project() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (self.breadcrumbs && self.breadcrumbs.length > 2) {
                deferred.resolve(self.breadcrumbs[1]);
                return deferred.promise;
            }

            self.loadFromServer(self.id).then(function () {
                if (self.breadcrumbs && self.breadcrumbs.length > 2) {
                    deferred.resolve(self.breadcrumbs[1]);
                } else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }

        function makeOneToOne() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            

            var modelUrl = apiUrl + self.id + '/make-one-to-one/';

            var promise;

            if (!self.id) {
                console.log('Tried make one to one without id');
                return $q.reject(self);
            }

            promise = $http.post(modelUrl);

            promise.then(function (response) {
                console.log('response: ', response.data);
                console.log('created: ', response.data.id);
                deferred.resolve(response.data);
            });
            
            return deferred.promise;
        }

    }

})();
