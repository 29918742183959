(function() {
    "use strict";

    angular
        .module('slate.contacts')
        .controller('SlateProjectDetailsPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateAuth, SlateTitle, getLinkSrv, project, SlateProjectEdit, SlateRolesSrv,  SlateAssignedRoles, SlateSFLinkage) {
        /* jshint validthis: true */
        var vm = this;
        vm.project = project;
        vm.company = vm.project.vendor;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.roles = SlateRolesSrv;

        vm.sf_linkage = SlateSFLinkage;

        $scope.$watch('vm.project.id', function (old_val, new_val) {
            if (vm.project.id && !vm.saving) {
                vm.sf_linkage.parent_type = 'project';
                vm.sf_linkage.parent_fk = vm.project.id;
            }
        });

        vm.assroles = new SlateAssignedRoles();
        vm.assroles.getList({
            parent_type: 'project',
            parent_fk: vm.project.id
        });

        vm.getProjectName = getProjectName;
        vm.editProject = editProject;
        vm.watch = watch;
        vm.unWatch = unWatch;
        vm.getTotalExp = getTotalExp;

        SlateTitle.set(vm.project.name);

        function getProjectName() {
            return "#" + vm.project.composite_num + " - " + vm.project.name;
        }

        function editProject() {
            var promise;
            promise = SlateProjectEdit.edit(project);
            promise.then(function(newProject) {
                if (newProject.slug != project.slug) {
                    $location.path(getLinkSrv.getUrl('project', newProject.id, newProject.slug)).search({});
                } else {
                    $state.reload();
                }
            }, function() {

            });
            return promise;
        }

        function watch() {
            vm.project.watcher.watch(vm.project.id).then(function() { vm.project.watched = true; });
        }

        function unWatch() {
            vm.project.watcher.unWatch(vm.project.id).then(function() { vm.project.watched = false; });
        }

        function getTotalExp() {
            return parseFloat(vm.project.total_expenses) + (parseFloat(vm.project.hours_spent) * 75);
        }

    }

})();
