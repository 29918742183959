(function () {
    "use strict";

    angular
        .module('slate.orderplan')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('orderplanPage', {
                url: '/orderplan/:orderid/',
                template: "<div class='content-pane list-page'><slate-orderplan></slate-orderplan></div>",
                current_app: 'orders',
                slateTitle: 'Order Plan'
            })
            ;
    }
})();