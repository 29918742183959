(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportActualmargin', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/reports/reports.report.actualmargin.dir.html',
            scope: {
                filters: '<',
            },
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $state, $location, $http, $q, $stateParams, $httpParamSerializer, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.linksrv = getLinkSrv;

        var now = new Date();
        var endLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
        var twomonthsago = new Date(now.getFullYear(), now.getMonth() - 4, 1);
        
        var modelPromise;
        var rawData = {};

        vm.data = {};
        vm.getDownloadUrl = getDownloadUrl;

        var apiUrl = API_URL + 'reports/actual_gross_margin/';

        vm.loading = true;
        vm.pagerOptions = {};
        if (!vm.filters) {
            vm.filters = {
                startDate: twomonthsago,
                endDate: endLastMonth
            };
        }

        vm.filters = angular.extend(vm.filters, {
            
        });


        vm.startDateIsOpen = false;
        vm.startDateOpen = startDateOpen;

        vm.endDateIsOpen = false;
        vm.endDateOpen = endDateOpen;

        function startDateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.startDateIsOpen = true;
        }

        function endDateOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.endDateIsOpen = true;
        }

        // updateFromFilter();

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        function updateFromFilter() {
            var newData = [];
            vm.loading = true;

            modelPromise = $http.get(apiUrl, {
                params: vm.filters
            });

            modelPromise.then(function(resp) {
                vm.data = resp.data;
                vm.data.months = vm.data.months.sort();
                vm.loading = false;
            });
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }


    }


})();
