(function () {
    'use strict';

    angular
        .module('slate.qima')
        .directive('slateBookingEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/qima/booking.edit.dir.html',
            scope: {
                booking: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function ListController($scope, $http, getLinkSrv, QIMABookingEditSrv, SlateProductRefEditSrv, SlateBooking) {
        /* jshint validthis: true */
        const vm = this;

        vm.getLink = getLinkSrv;
        vm.saveObject = saveObject;
        vm.isComplete = isComplete;
        vm.addProductRefs = addProductRefs;
        vm.sendToQIMA = sendToQIMA;

        $scope.$watch('vm.booking.service_date', (newVal, oldVal) => {
            if (!vm.booking.service_date) {
                vm.booking.ship_date = undefined;
            } else {
                const newDate = dateFns.addDays(vm.booking.service_date, 2);
                vm.booking.ship_date = newDate;
            }
        });

        function saveObject() {
            if (vm.saving) { return false; }

            vm.saving = true;
            vm.booking.saveModel().then(() => {
                vm.saving = false;
            }, () => {
                vm.saving = false;
            });

            return this;
        }

        function isComplete() {
            if (!vm.booking.service_date) { return false; }
            if (!vm.booking.ship_date) { return false; }
            return true;
        }

        vm.getInspections = getInspections;
        function getInspections() {
            vm.inspections = vm.booking.products[0].refs.reduce((prevValue, ref) => {
                const inspectionId = ref.inspection_lineitem_info.inspection;
                if (!prevValue.includes(inspectionId)) {
                    prevValue.push(inspectionId);
                }
                return prevValue;
            }, []);
        }
        // getInspections();

        function addProductRefs() {
            SlateProductRefEditSrv.edit(vm.booking).then((products) => {
                vm.booking.products = products;
            }, () => {

            });
        }

        function sendToQIMA() {
            QIMABookingEditSrv.edit(vm.booking).then((booking) => {
                vm.booking.loadFromPayload(booking);
            }, () => {

            });
        }
    }
})();
