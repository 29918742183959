(function() {
    "use strict";

    angular
        .module('slate.salesorders')
        .directive('slateSalesQuoteLineItemList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/salesquotes/sq.lineitems/sq.lineitems.list.dir.html',
            scope: {
                salesQuote: '=',
                showTotals: '=?',
                fields: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $window,
        $state,
        $location,
        $q,
        $timeout,
        SlateSQLineItem,
        SlateSQLineItemEditSrv,
        SlateSQLineItemOptionEditSrv,
        SlateSQLineItemFixedCostsEditSrv,
        SlateBOMItems,
        SlateRFQResponses,
        SlateRFQResponseLineItems,
        getLinkSrv,
        SlateAuth
    ) {
        /* jshint validthis: true */
        var vm = this;

        if(!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'photo',
                'jansy_pn',
                'name',
                'category',
                'material',
                'qty',
                'unit_name',
            ];
            /* beautify preserve:end */
        }

        vm.splits = [];
        getSplits();

        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;
        vm.loading = false;

        vm.selectVendor = selectVendor;
        vm.editLineItem = editLineItem;
        vm.addNewLineItem = addNewLineItem;
        vm.deleteLineItem = deleteLineItem;
        vm.toggleImages = toggleImages;

        vm.addOption = addOption;
        vm.editOption = editOption;
        vm.deleteOption = deleteOption;
        vm.addRFQOption = addRFQOption;

        vm.editFixedCosts = editFixedCosts;
        vm.deleteFixedCost = deleteFixedCost;
        vm.addRFQFixedCost = addRFQFixedCost;

        vm.getStyle = getStyle;

        vm.hideImages = $window.localStorage.hideImages == "true";

        vm.getSplits = getSplits;

        vm.getKeys = function(obj) { return Object.keys(obj); };

        vm.bomItems = new SlateBOMItems();
        updateBoms();

        $scope.$watch('vm.salesQuote.updated', function (old_val, new_val) {
            updateBoms();
        });

        function addNewLineItem() {
            var new_lineitem = new SlateSQLineItem();
            new_lineitem.splits = vm.splits;
            SlateSQLineItemEditSrv.edit(new_lineitem, {salesQuote: vm.salesQuote}).then(function (lineitem) {
                updateBom(lineitem);
                vm.salesQuote.line_items.push(lineitem);
                getSplits();
            }, function () {
                //do nothing if canceled?
            });

        }

        function editLineItem(lineitem, $idx) {
            SlateSQLineItemEditSrv.edit(lineitem, {salesQuote: vm.salesQuote}).then(function (new_lineitem) {
                updateBom(new_lineitem);
                vm.salesQuote.line_items[$idx] = new_lineitem;
                getSplits();
            }, function () { });
        }

        function deleteLineItem(lineitem, $idx) {
            lineitem.toDelete = !lineitem.toDelete;
        }


        function getStyle(field) {
            if(field == 'name') {
                return {width: '100%'};
            }

            return {
                'overflow': 'hidden',
                'white-space': 'nowrap',
                'text-overflow': 'ellipsis',
            };
        }

        function getSplits() {
            var splits = [];
            vm.salesQuote.line_items.forEach(function(item) {
                if(Array.isArray(item.splits)) {
                    splits = splits.concat(item.splits);
                }
            });
            splits = splits.filter(function(item, idx) {
                return splits.indexOf(item) === idx;
            });
            splits.sort(function(a, b) {
                var first = parseFloat(a);
                var second = parseFloat(b);

                return first - second;
            });
            vm.splits = splits;
            return splits;
        }

        function selectVendor(lineItem, bomItem, response) {
            lineItem.selectVendor(bomItem, response, vm.salesQuote.tariffs);
            vm.salesQuote.getFreightEstimate();
        }

        function addOption(lineItem) {
            var option = {'data': {}};
            lineItem.splits.forEach(function(split) {
                option.data[split] = undefined;
            });
            SlateSQlineItemOptionEditSrv.edit(option, {lineItem: lineItem}).then(function (lineItem) {
                lineItem.updateCosts(vm.salesQuote.tariffs);
                lineItem.expanded = true;
            }, function () {
                //do nothing if canceled?
            });
        }

        function editOption(lineitem, option_name) {
            var orig_name = option_name;
            var option = {'name': option_name, 'data': {}};
            option.data.amortize = lineitem.options[option_name].amortize;
            
            lineitem.splits.forEach(function(split) {
                option.data[split] = undefined;
                if(lineitem.options[option_name][split]) {
                    option.data[split] = _.cloneDeep(lineitem.options[option_name][split]);
                }
            });
            SlateSQLineItemOptionEditSrv.edit(option, {lineItem: lineitem}).then(function (option) {
                lineitem.updateCosts(vm.salesQuote.tariffs);
            }, function () {
                //do nothing if canceled?
            });
        }

        function addRFQOption(lineItem, option) {
            lineItem.addRFQOption(option);
            vm.editOption(lineItem, option.name);
            lineItem.updateCosts(vm.salesQuote.tariffs);
            // updateBoms();
        }


        function deleteOption(lineItem, option_name) {
            if(option_name == 'primary') { return false; }
            delete(lineItem.options[option_name]);
            lineItem.updateCosts(vm.salesQuote.tariffs);
        }

        function editFixedCosts(lineItem) {
            var fixedcosts = _.cloneDeep(lineItem.fixed_costs);
            SlateSQLineItemFixedCostsEditSrv.edit(fixedcosts).then(function(fixedcost_results) {
                lineItem.fixed_costs = fixedcost_results;
                lineItem.updateCosts(vm.salesQuote.tariffs);
                lineItem.expanded = true;
            });
        }

        function deleteFixedCost(lineItem, idx) {
            lineItem.fixed_costs.splice(idx, 1);
            lineItem.updateCosts(vm.salesQuote.tariffs);
        }

        function addRFQFixedCost(lineItem, option) {
            lineItem.addRFQFixedCost(option);
            editFixedCosts(lineItem);
            lineItem.updateCosts(vm.salesQuote.tariffs);
        }

        function updateBoms() {
            var promises = [];
            vm.salesQuote.line_items.forEach(function(lineItem) {
                promises.push(updateBom(lineItem));
            });
            $q.all(promises).then(function() {
                vm.salesQuote.getFreightEstimate();
            });
        }

        function updateBom(lineItem) {
            if(!lineItem.bomItems) { lineItem.bomItems = new SlateBOMItems(); }
            return lineItem.bomItems.getList({bom: lineItem.bom}).then(function() {
                var promises = [];
                lineItem.bomItems.list.forEach(function(bomItem) {
                    if(!bomItem.responses) { bomItem.responses = new SlateRFQResponseLineItems();}
                    promises.push(bomItem.responses.getList({component: bomItem.component, sort_by: '-updated'}, true));
                });
                return $q.all(promises).then(function() { lineItem.updateCosts(vm.salesQuote.tariffs); });
            });
        }

        function toggleImages() {
            vm.hideImages = !vm.hideImages;
            $window.localStorage.hideImages = vm.hideImages; 
        }

    }
})();
