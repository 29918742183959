(function() {
    "use strict";

    angular
        .module('slate.filebrowser')
        .directive('slateFileBrowserAttachmentsFortable', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/filebrowser/filebrowser.attachments.fortable.dir.html',
            scope: {
                parentType: '=',
                parentFk: '=',
                path: '=?',
                showNoFiles: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $q, slateLightbox, getLinkSrv, SlateFileNodeList) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.nodes = new SlateFileNodeList();
        vm.images = [];

        //local functions
        vm.getUrl = getUrl;
        vm.parseFilesize = parseFilesize;
        vm.get_image_type = get_image_type;
        vm.getDownloadUrl = getDownloadUrl;
        vm.openLightbox = openLightboxModal;

        //init();

        vm.fields = ['id', 'filename', 'mimetype', ];

        /* beautify preserve:start */
        var types = {
            'image/png': {class: 'flaticon-png-file-extension-interface-symbol'},
            "image/jpeg": {class: 'flaticon-jpg-image-file-format'},
            'application/postscript': {class: 'flaticon-ai-file-format-symbol', color: '#FF6600'},
            'application/x-apple-diskimage': {class: 'flaticon-dmg-file-format-symbol'},
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {class: 'flaticon-xlsx-file-format', color:"#577758"},
            'application/vnd.ms-excel': {class: 'flaticon-xls-file-format-symbol', color:"#577758"},
            "application/vnd.openxmlformats-officedocument.presentationml.presentation": {class: 'flaticon-pptx-file-format-variant', color:"#cf6a41"},
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {class: 'flaticon-docx-file-format', color:"#517fcb"},
            "application/msword": {class: 'flaticon-doc-file-format-symbol', color:"#517fcb"},
            'application/pdf': {class: 'flaticon-pdf-file-format-symbol', color: '#e81209'},
            'application/zip': {class: 'flaticon-zip-compressed-files-extension', color: '#B29772'},
        };
        /* beautify preserve:end */


        function get_image_type(mimetype) {
            var results = {
                class: 'flaticon-blank-file',
                color: '#000000'
            };
            if (types[mimetype]) {
                results = angular.extend({}, results, types[mimetype]);
            }
            return results;
        }

        function getUrl(path) {
            var params = $state.params;
            var new_path = "/";
            if (path.startsWith('/')) {
                path = path.substr(1);
            }
            params.path = path;
            new_path = $state.href($state.current.name, params, {
                absolute: true,
            }).replace(/~2F/g, '/').replace(/%252F/g, '/');
            // the regex is due to the persistant encoding of the url, regardless of options set in ui-router
            if (!new_path.endsWith('/')) {
                new_path = new_path + '/';
            }
            return new_path;
        }

        function parseFilesize(size) {
            var unit = "B";
            if(size === null || isNaN(size)) {
                return '';
            }
            if (size > 1024) {
                size = size / 1024;
                unit = "K";
            }
            if (size > 1024) {
                size = size / 1024;
                unit = "M";
            }
            if (size > 1024) {
                size = size / 1024;
                unit = "G";
            }
            return size.toFixed(2).toString() + unit;
        }

        function getDownloadUrl(node, ver) {

            if (!ver) {
                return API_URL + 'files/download/' + node.id + '/';
            }

            return API_URL + 'files/download/' + node.id + '/ver/' + ver + '/';
        }

        function init() {
            var modelPromise;
            var truePathParths = [];
            var truePath = '/';
            var listFilters = {
                parent_type: vm.parentType,
                parent_fk: vm.parentFk,
                path: vm.path,
                fields: vm.fields
            };

            if(!vm.parentFk || !vm.parentType) { return false; }

            vm.loading = true;

            modelPromise = vm.nodes.getList(listFilters);
            modelPromise.then(function() {
                angular.forEach(vm.nodes.list, function(node) {
                    if(node.mimetype.startsWith('image')) {
                        vm.images.push({
                            url: vm.getDownloadUrl(node),
                            caption: node.description,
                            mimetype: node.mimetype,
                            filename: node.filename,
                        });
                    }
                });
                vm.loading = false;
            });
            return modelPromise;
        }

        $scope.$watch('vm.parentFk', function () { init(); });

        function openLightboxModal(index, e) {
            e.stopPropagation();
            e.preventDefault();
            slateLightbox.openModal(vm.images, index);
        }

    }


})();
