(function () {
    'use strict';

    angular
        .module('slate.productprofiles')
        .service('SlateProductProfileEmailSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateProductProfileItem) {
        const templateUrl = '/partials/productprofiles/productprofile.item.email.srv.html';

        const srv = {
            email: editObj,
        };


        return srv;


        function editObj(obj) {
            const modalInstance = $uibModal.open({
                templateUrl,
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj() {
                        const profile = new SlateProductProfileItem();
                        if (!obj) { return profile; }

                        return profile.loadFromServer(obj.id);
                    },
                },
            });

            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $http, $q, $uibModalInstance, obj, SlateAuth) {
        /* jshint validthis: true */
        const vm = this;

        vm.auth = SlateAuth;
        vm.obj = obj;

        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.isComplete = isComplete;

        const apiUrl = `${API_URL}productprofiles/profileitem/`;

        function saveObject() {
            if (!vm.isComplete()) { return false; }

            const url = `${apiUrl}${vm.obj.id}/email/`;
            vm.saving = true;

            const params = {};
            if (vm.additional_message) { params.message = vm.additional_message; }
            if (vm.obj.contact) { params.contact = vm.obj.contact; }

            return $http.post(url, params).then((response) => {
                vm.saving = false;
                $uibModalInstance.close(vm.obj);
            }, (e) => {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function isComplete() {
            if (!vm.obj.contact) { return false; }

            return true;
        }
    }
})();
