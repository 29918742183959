(function () {
    'use strict';

    angular
        .module('slate.productprofiles')
        .service('SlateProductProfileGenCompsSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateProductProfile) {
        const templateUrl = '/partials/productprofiles/productprofile.gencomps.dialog.html';

        const srv = {
            gen: editObj,
        };


        return srv;


        function editObj(obj) {
            const modalInstance = $uibModal.open({
                templateUrl,
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj() {
                        const profile = new SlateProductProfile();
                        if (!obj) { return profile; }

                        return profile.loadFromServer(obj.id);
                    },
                },
            });

            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $http, $q, $uibModalInstance, obj, SlateProductProfileItems, SlateAuth) {
        /* jshint validthis: true */
        const vm = this;

        vm.auth = SlateAuth;
        vm.obj = obj;
        vm.line_items = new SlateProductProfileItems();
        vm.line_items.getList({ profile: vm.obj.id });

        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.isComplete = isComplete;
        vm.toggleAll = toggleAll;
        vm.allSelected = allSelected;

        const apiUrl = `${API_URL}productprofiles/profile/`;

        function saveObject() {
            if (!vm.isComplete()) { return false; }

            const url = `${apiUrl}${vm.obj.id}/gen_components/`;
            vm.saving = true;

            const params = {};

            const selected_ids = [];
            vm.line_items.list.forEach((item) => {
                if (item.selected) {
                    selected_ids.push(item.id);
                }
            });
            params.item_ids = selected_ids;

            return $http.post(url, params).then((response) => {
                vm.saving = false;
                $uibModalInstance.close(vm.obj);
            }, (e) => {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function isComplete() {
            if (!vm.line_items.list.some((item) => item.selected)) { return false; }
            return true;
        }

        function allSelected() {
            return vm.line_items.list.every((item) => item.selected);
        }

        function toggleAll(e) {
            const itemsSelected = vm.line_items.list.filter((item) => item.selected).length;
            if (itemsSelected == 0) { vm.line_items.list.forEach((item) => { item.selected = true; }); }
            if (itemsSelected > 0 && itemsSelected < vm.line_items.list.length) { vm.line_items.list.forEach((item) => { item.selected = true; }); }
            if (itemsSelected === vm.line_items.list.length) { vm.line_items.list.forEach((item) => { item.selected = false; }); }
        }
    }
})();
