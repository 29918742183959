(function () {
    "use strict";

    angular
        .module('slate.datelog')
        .directive('slateDatelogPopout', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/datelog/datelog.popout.dir.html',
            scope: {

            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $location,
        getLinkSrv,
        SlateAuth,
        SlateSFLinkage,
        SlateDateLogList
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;
        vm.sf_linkage = SlateSFLinkage;
        vm.dateLog = SlateDateLogList;

        vm.showButton = showButton;
        vm.openDialog = openDialog;

        function openDialog() {
            vm.dateLog.popout();
        }

        function showButton() {
            return ['purchaseorder', 'shipment', 'salesorder'].includes(vm.dateLog.parent_type);
        }

    }

})();
