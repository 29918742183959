//import { request } from "http";

(function() {
    "use strict";

    angular
        .module('slate.library.orderplan')
        .factory('SlateOrderPlanSteps', ModelList)
        .factory('SlateOrderPlanStep', ModelDetail);

    var apiUrl = API_URL + 'orderplan/steps/';

    function ModelList($http, $q, SlateOrderPlanStep) {
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        self.pagination = {
            total: 0,
            page: 1,
            count: 25
        };
        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.getStart = getStart;
        list.prototype.getRows = getRows;
        list.prototype.getTree = getTree;
        list.prototype.findStep = findStep;
        list.prototype.getStep = getStep;
        list.prototype.insertStep = insertStep;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];
            self.start_step = null;
            self.getStart = getStart;
            return self;
        }

        function getStart() {
            /* jshint validthis: true */
            var self = this;
            var start = null;
            self.list.some(function(item) {
                if(item.step_type == 'start') {
                    start = item;
                    return true;
                }
                return false;
            });

            if(start === null) {
                start = new SlateOrderPlanStep();
                start.name = 'Start';
                start.step_type = 'start';
                start.completed = true;
                start.order = self.filters.order;
                start.saveModel().then(function() {
                    self.list.unshift(start);
                });
            }

            return start;
        }


        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then(function(response) {
                var newlist = [];

                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlateOrderPlanStep();
                    itemModel.loadFromPayload(item);
                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.start_step = self.getStart(newlist);

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function getRows() {
            /* jshint validthis: true */
            var self = this;

            var rows = [];
            var used = {};
            var count = 0;
            var current_row=0;
            var t0, t1;

            t0 = performance.now();

            self.list.forEach(function(item) { if(item.id) { count++; } });

            used[self.start_step.id] = 0;
            rows.push([self.start_step]);

            while(Object.keys(used).length < count && current_row <= count) {
                rows.push([]);
                current_row++;
                /* jshint ignore:start */
                self.list.forEach(function(item) {

                    // skip if the item is already done
                    if(typeof used[item.id] != 'undefined') { return false; }

                    // if any parents are not already in the system, skip.
                    if(item.parents.some(function(parent_id) {
                        if(used[parent_id] >= current_row) { return true; }
                        if(typeof used[parent_id] == 'undefined') { return true; }
                        return false;
                    })) { return false; }

                    used[item.id] = current_row;
                    rows[current_row].push(item);
                });
                /* jshint ignore:end */
            }

            t1 = performance.now();
            // console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.");

            return rows;

        }

        function getTree() {
            /* jshint validthis: true */
            var self = this;
            var tree = [];
            var used = {};
            var t0, t1;
            t0 = performance.now();

            var roots = self.list.filter(function(item) { return item.parents.length == 0; });
            roots.forEach(function(item) {
                if(typeof item != 'undefined' && !used.hasOwnProperty(item.id)) {
                    tree.push(getLeaf(item));
                }
            });

            function getLeaf(item) {
                var leaf = {};
                used[item.id] = true;
                leaf.id = item.id;
                leaf.item = item;
                leaf.children = [];
                item.children.forEach(function(child_obj) {
                    if(typeof used[child_obj.id] != 'undefined') { return false; }
                    item = self.list.find(function(step) { return step.id == child_obj.id; });
                    if(typeof item != 'undefined') {
                        leaf.children.push(getLeaf(item));
                    }
                });
                if(!leaf.id) {
                    leaf.id = create_UUID();
                }
                return leaf;
            }

            self.list.forEach(function(item) {
                if(!used.hasOwnProperty(item.id)) {
                    tree.push({
                        id: item.id,
                        item: item,
                        children: []
                    });
                }
            });

            t1 = performance.now();
            // console.log("Built tree in " + (t1 - t0) + " milliseconds.");

            return tree;
        }

        function findStep(step_type, start_step_id) {
            /* jshint validthis: true */
            var self = this;

            var checkList = [start_step_id];
            var results = [];
            var checkItem;
            var checkID;

            while(checkList.length) {
                checkID = checkList.shift();
                /* jshint ignore:start */
                checkItem = self.list.find(function(item) { return item.id == checkID; });
                /* jshint ignore:end */
                if(checkItem.step_type == step_type) { results.push(checkItem); }
                checkList = checkList.concat(checkItem.children.map(function(item) { return item.id; }));
            }

            return results;
        }

        function getStep(target_type, target_fk) {
            /* jshint validthis: true */
            var self = this;

            var step;
            step = self.list.find(function(item) {
                return item.target_fk == target_fk && item.target_type == target_type;
            });
            return step;
        }

        function insertStep(step, parent_step_type, parent_type, parent_fk, child_type) {
            /* jshint validthis: true */
            var steps = this;
            var parentStep = steps.list.find(function(item) {
                return item.step_type == parent_step_type && item.target_type == parent_type && item.target_fk == parent_fk;
            });
            var promises = [];

            if(!parentStep) {
                return $q.reject();
            }

            // do other steps of the same type have this parent?
            if(!steps.list.some(function(item) {
                return item.step_type == step.step_type && item.parents.includes(parentStep.id);
            })) {
                // no parellel steps

                if(!step.parents.includes(parentStep.id)) { step.parents.push(parentStep.id); }

                steps.list.forEach(function(item) {
                    var idx = item.parents.indexOf(parentStep.id);

                    if(item === step || item.id == step.id) { return false; }

                    if(idx > -1) {
                        item.parents.splice(idx, 1, step.id);
                        promises.push(item.saveModel());
                    }
                });

            } else {
                // insert in parallel
                if(!step.parents.includes(parentStep.id)) { step.parents.push(parentStep.id); }
                var connect_steps = [];
                connect_steps = steps.findStep(child_type, parentStep.id);
                connect_steps.forEach(function(item) {
                    if(step.id == item.id) {
                        return false;
                    }
                    var idx = item.parents.indexOf(parentStep.id);
                    if(idx > -1) {
                        item.parents.splice(idx, 1);
                    }
                    item.parents.push(step.id);
                    promises.push(item.saveModel());
                });
            }
            promises.push(step.saveModel());
            return $q.all(promises);

        }

        function create_UUID(){
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = (dt + Math.random()*16)%16 | 0;
                dt = Math.floor(dt/16);
                return (c=='x' ? r :(r&0x3|0x8)).toString(16);
            });
            return uuid;
        }

    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },
            {name: 'name'},
            {name: 'notes'},

            {name: 'order'},
            {name: 'order_info', readonly: true},

            {name: 'step_type'},
            {name: 'target_type'},
            {name: 'target_fk'},
            {name: 'target_info', readonly: true},

            {name: 'warehouse'},
            {name: 'warehouse_info', readonly: true},

            {name: 'completed'},
            {name: 'ready'},

            {name: 'parents', def: []},
            {name: 'children', def: []},

            {name: 'created', readonly: true},
            {name: 'created_by', readonly: true},
            {name: 'created_by_info', readonly: true},
            {name: 'updated', readonly: true},
            {name: 'deleted', readonly: true, default: false},

            {name:'revised', def:[{}], type:"json"},
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
                //if(field.type=="date" && !self[field.name]) {
                //    self[field.name] = new Date();
                //}
            });

            if(typeof self.stepColor == 'undefined') {
                self.stepColor = '#000000';
            }

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                return self;
            }

            angular.forEach(fields, function(field) {
                if(!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if(field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if(field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch(e) {
                    }
                }

            });
            if(self.id) {
                self.stepColor = "#" + self.id.substring(0,6);
            }

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];

                return self.loadFromPayload(response.data.payload).then(function() {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            if(self.parents.length) {
                self.parents = self.parents.filter(function(value, idx, arr) {
                    if(!value) { return false; }
                    return arr.indexOf(value) == idx;
                });
                if(!Array.isArray(self.parents)){
                    self.parents = [];
                }

            }

            angular.forEach(fields, function(field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if(data[field.name] && field.type == "date") {
                        data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth()+1) + '-' + self[field.name].getUTCDate();
                    }
                    if(field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }



    }

})();
