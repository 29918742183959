(function () {
    "use strict";

    angular
        .module('slate.projects')
        .directive('slateComponentInstock', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/projects/instock/instock.number.dir.html',
            scope: {
                componentId: '=',
                warehouseId: '=',
                instock: '=?',
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function directiveController($scope, $http, $q) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'projects/project/';

        vm.loading = false;
        vm.instock = 0;

        $scope.$watch('vm.componentId', function () { debouncedGetStock(); });
        $scope.$watch('vm.warehouseId', function () { debouncedGetStock(); });

        function getStock() {
            var modelUrl = apiUrl + vm.componentId + '/stock/';
            vm.loading = true;
            vm.error = false;

            if(vm.canceller) { vm.canceller.resolve(); }
            vm.canceller = $q.defer();

            if(!vm.componentId || !vm.warehouseId) {
                vm.instock = 0;
                vm.loading = false;
                return false;
            }

            return $http.get(modelUrl, {
                timeout: vm.canceller.promise
            }).then(function(response) {
                vm.canceller = null;
                vm.loading = false;
                response.data.payload.forEach(function(item) {
                    if(vm.warehouseId == item.warehouse_id) {
                        vm.instock = parseFloat(Big(item.qty).toFixed(2));
                    }
                });
            }, function() {
                vm.canceller = null;
                vm.loading = false;
                vm.instock = 0;
                vm.error = true;
            });
        }
        var debouncedGetStock = _.debounce(getStock, 150);

    }

})();
