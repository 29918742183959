(function () {
    'use strict';

    angular
        .module('slate.bom')
        .directive('slateBomStockList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/logistics/confirmations/bom_stock.dir.html',
            scope: {
                bomId: '=?',
                qty: '=?',
                warehouseId: '=?',
                requiredFilled: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ListController($scope, $state, $location, $window, $q, SlateAuth, SlateDeleteObjSrv, getLinkSrv, SlateProject, SlateBOM, SlateBOMItems, SlateBOMEditSrv, SlatePriceTier, SlateBomItemPriceTierEditSrv, SlateItemStockDialog) {
        /* jshint validthis: true */
        const vm = this;

        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.bom = new SlateBOM();
        vm.bomitems = new SlateBOMItems();

        $scope.$watch('vm.bom', () => {
            if (vm.bom) {
                vm.bom.loadFromServer(vm.bomId);
                vm.bomitems.getList({ bom: vm.bomId }).then(() => {
                    vm.loading = false;
                });
            }
        });
    }
})();
