/* eslint-disable no-undef */
(function () {
    'use strict';

    angular
        .module('slate.auth')
        .directive('slateLoginOktaBox', loginbox);

    function loginbox() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/auth/auth.loginbox.okta.dir.html',
            scope: {

            },
            controller: loginBoxController,
            controllerAs: 'vm'
        };
    }

    function loginBoxController($scope, $state, $window, $timeout, $location, SlateAuth) {
        /* jshint validthis: true */
        const vm = this;
        // vm.loginDetails = { username: '', password: '', rememberMe: true };
        // vm.auth = SlateAuth;
        vm.login = login;
        vm.logout = logout;

        vm.logging_in = false;
        vm.error = false;


        function login(username, password, rememberMe) {
            vm.error = null;
            vm.logging_in = true;

            const promise = SlateAuth.newLogin(username, password, rememberMe, 'okta');
            promise.then(() => {
                const next = sessionStorage.getItem('next_url');
                sessionStorage.removeItem('next_url');
                if (next == null || next.startsWith('/login')) {
                    $location.url('/');
                } else {
                    $location.url(next);
                }
            }, (response) => {
                vm.error = response.error || 'Error logging in';
                vm.logging_in = false;
                vm.loginDetails.password = '';
            });
        }
        //issuer: 'https://dev-50383635.okta.com/oauth2/default',
        const oktaConfig = {
            issuer: OKTA_DOMAIN,
            url: OKTA_DOMAIN,
            redirectUri: `${location.protocol}//${location.host}${location.port ? ":" + location.port : ''}/login/okta/`,
            clientId: OKTA_CLIENT_ID,
            useInteractionCodeFlow: true,
            scopes: ['openid', 'profile', 'email']
        };

        const authClient = new OktaAuth(oktaConfig);

        if (authClient.isLoginRedirect()) {
            vm.logging_in = true;
            // Wait for digests and whatnot to settle
            $timeout(() => {
                authClient.token.parseFromUrl()
                    .then(data => {
                        const { idToken } = data.tokens;
                        login(idToken.claims.email, idToken.idToken, true);
                    });
            });
        } else {
            // console.log("Attempt to retrieve ID Token from redirect");
            authClient.token.getWithRedirect({
                responseType: ['id_token']
            });
        }

        function logout() {
            window.location.reload();
        }
    }
})();
