(function () {
    "use strict";

    angular
        .module('slate.salesforecast')
        .directive('slateSalesForecastEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/salesforecast/salesforecast.edit.form.dir.html',
            scope: {
                forecast: '=',
                complete: '=?',
                sales_mgr: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function ListController(
        $scope,
        getLinkSrv,
        SlateAuth
        
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.isComplete = isComplete;

        $scope.$watch('vm.forecast.id', function (old_val, new_val) {
            if (vm.forecast && vm.forecast.id) {
                isComplete();
            }
        });

        function isComplete() {
            var complete = true;
            

            
            vm.complete = complete;
            return vm.complete;
        }

    }

})();
