(function() {
    "use strict";

    angular
        .module('slate.sampletracking')
        .directive('slateSamplesList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/sampletracking/samples.list.dir.html',
            scope: {
                modelFilters: '=filters'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $q, $window, SlateSamples, SlateSample, SlateSampleEdit, SlateFileNodeList, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;

        var modelPromise;
        var filesPromise;

        var multiSearchFields = ['sample_type', 'provider'];

        vm.get_url = get_url;
        vm.getLink = getLinkSrv;
        vm.loading = true;
        vm.filters = {
            page: 1,
            count: 25,
        };
        //if(!vm.modelFilters) { vm.modelFilters = {}; }
        
        multiSearchFields.forEach(function(field) {
            vm.filters[field] = null;
        });
        
        angular.extend(vm.filters, {
            q: $location.search().q,
            type_code: $location.search().type_code,
            company: $location.search().company,
            contact: $location.search().contact,
            project: $location.search().project,
            proj_mgr: $location.search().proj_mgr,
            sales_mgr: $location.search().sales_mgr,
            po: $location.search().po,
            jansy_po: $location.search().jansy_po,
            sample_type: $location.search().sample_type
        }, vm.modelFilters, $location.search());
        if(vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if(vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }
        
        vm.sampleList = new SlateSamples();
        
        vm.addNew = addNewSample;
        vm.editSample = editSample;
        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;
        vm.duplicate = reqdupe;
        
        vm.getTrackingLink = getTrackingLink;

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        multiSearchFields.forEach(function(field) {
            if (vm.filters[field] && typeof vm.filters[field] === 'string') {
                vm.filters[field] = [vm.filters[field]];
            }
        });

        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function(value, key) {
                if(key == 'page' || key == 'count' || key == 'q') {
                    return false;
                }
                if(value === undefined || value === null || value === '') {
                    return false;
                }
                //Breaks type_code filter do not uncomment for now
                if(vm.filters[key] == vm.modelFilters[key]) {
                  return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;
                
            });
            return hasData;
        }

        function updateFromFilter() {
            var modelPromise;

            vm.loading = true;

            angular.forEach(vm.filters, function(value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });
            modelPromise = vm.sampleList.getList(vm.filters);
            modelPromise.then(function() {
                vm.loading = false;
            });
            
        }

        function editSample(sample) {
            var promise;

            promise = SlateSampleEdit.edit(sample);
            promise.then(function() {
                $state.reload();
            }, function() {

            });
            return promise;

        }

        function addNewSample($event) {
            var newSample = new SlateSample();
            var promise;
            if (vm.modelFilters) {
                newSample.loadFromPayload(vm.modelFilters);
            }
            newSample.type_code = "S";

            if (vm.filters.type_code) { newSample.type_code = vm.filters.type_code; }

            promise = SlateSampleEdit.edit(newSample);
            promise.then(function() {
                $state.reload();
            }, function() {

            });
            return promise;
        }

        function getShippingCo(trackNum) {
            var targetCompany = 'NONE';
            var usps_num = /\b\w{2}(\d{9})US|\b\d{22}\b|7(\d{19})|03(\d{18})|23(\d{18})|82(\d{8}$)/;
            var fedex_num = /\b\d{15}\b|DT(\d{12})/;
            var dhl_num = /(\b[A-Z]{3})(\d{6,7})|\b\d{7}\b/;
            var ups_num = /1Z(\w{16})|\b\d{12}\b|T(\d{10})|\b\d{9}\b/;

            trackNum = trackNum.replace(/\s/g, '');
            targetCompany = 'NONE';

            if (ups_num.test(trackNum)) {
                targetCompany = 'UPS';
            }
            if (usps_num.test(trackNum)) {
                targetCompany = 'USPS';
            }
            if (fedex_num.test(trackNum)) {
                targetCompany = 'FEDEX';
            }
            if (dhl_num.test(trackNum)) {
                targetCompany = 'DHL';
            }

            return targetCompany;
        }


        function getTrackingLink(trackNum, targetCompany) {
            var targetUrl = '#';
            var msg = '';

            if(!targetCompany) {
                targetCompany = getShippingCo(trackNum);
            }

            //url's for tracking
            var ups_url = 'http://wwwapps.ups.com/WebTracking/processRequest?HTMLVersion=5.0&Requester=NES&AgreeToTermsAndConditions=yes&loc=en_US&tracknum=';
            var dhl_url = 'http://www.dhl.com/en/express/tracking.html?brand=DHL&AWB=';
            var usps_url = 'http://trkcnfrm1.smi.usps.com/PTSInternetWeb/InterLabelInquiry.do?origTrackNum=';
            var fedex_url = 'http://www.fedex.com/Tracking?tracknumbers=';
            var sf_url = 'https://www.sf-express.com/us/en/dynamic_function/waybill/#search/bill-number/';

            switch (targetCompany) {
                case 'UPS':
                    targetUrl = ups_url;
                    break;
                case 'DHL':
                    targetUrl = dhl_url;
                    break;
                case 'USPS':
                    targetUrl = usps_url;
                    break;
                case 'FEDEX':
                    targetUrl = fedex_url;
                    break;
                case 'SF':
                    targetUrl = sf_url;
                    break;
            }

            targetUrl += trackNum;
            return targetUrl;
        }

        function get_url() {
            // follow path of slate/urls.py and local path
            var url = API_URL + 'sampletracking/samples/';
            if($window.location.search.length ) {
                return url + $window.location.search + '&download=true'; 
            } else {
                return url + '?download=true';
            }
        }

        function reqdupe(id) {
            var toDup = vm.sampleList.list.filter(function (item) {
                return item.id == id;
            });
            console.log(toDup);
            var promise;
            var newSample = new SlateSample();
            if(toDup.length){
                newSample.description = toDup[0].description;
                newSample.company = toDup[0].company;
                newSample.project = toDup[0].project;
                newSample.component = toDup[0].component;
                newSample.vendor = toDup[0].vendor;
                newSample.sample_type = toDup[0].sample_type;
                newSample.po = toDup[0].po;
                newSample.jansy_po = toDup[0].jansy_po;
                newSample.storage_location = toDup[0].storage_location;
                newSample.qty = toDup[0].qty;
                newSample.shipping_tracking = toDup[0].shipping_tracking;
                newSample.shipping_provider = toDup[0].shipping_provider;
                newSample.vendor_desc = toDup[0].vendor_desc;
                newSample.type_code = "S";
            }
            else {
                return;
            }

            if (vm.filters.type_code) { newSample.type_code = vm.filters.type_code; }
            
            console.log(newSample);

            promise = SlateSampleEdit.edit(newSample);
            promise.then(function() {
                $state.reload();
            }, function() {

            });
            return promise;
        }

    }


})();
