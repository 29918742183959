(function() {
    "use strict";

    angular
        .module('slate.library.roles')
        .factory('SlateRolesList', ModelList)
        .factory('SlateRole', ModelDetail);

    function ModelList($http, $q, SlateRole) {
        // Set to url relative to api root.
        var apiUrl = API_URL + 'roles/roles/';

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if(typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {params: self.filters});

            promise.then(function (response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function (item) {
                    var itemModel = new SlateRole();

                    itemModel.loadFromPayload(item);
                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

    }

    function ModelDetail ($http, $q) {
        // Set to url relative to api root.
        var apiUrl = API_URL + 'roles/roles/';

        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },
            {name: 'name', def: null },
            {name: 'slug', def: null, readonly: true },
            {name: 'can_be_direct_assigned', def: false },
            {name: 'can_be_requested', def: false },
            {name: 'role_permissions', def: [] },
            {name: 'users', def: [], readonly: true },
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        model.prototype.addUser = addUser;
        model.prototype.removeUser = removeUser;
        model.prototype.loadUsers = loadUsers;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if(typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                self[field.name] = payload[field.name];
            });

            if(self.role_permissions) {
                 self.role_permissions = self.role_permissions.sort(function compare(a, b) {
                    var results = a.cat.localeCompare(b.cat);
                    if(results === 0) {
                      return a.perm_type.localeCompare(b.perm_type);
                    }
                    return results;
                  });
            }

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if(id) { modelUrl = apiUrl + id + '/'; }

            if(!self.id && !id) {
                console.log('Tried to load from server without id');
                return self;
            }

            promise = $http.get(modelUrl);

            promise.then(function (response) {

                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if(self.id) { modelUrl = apiUrl + self.id + '/'; }

            angular.forEach(fields, function(field) {
                if(!field.readonly) {
                    data[field.name] = self[field.name];
                }
            });

            promise = $http.post(modelUrl, data);
            promise.then(function (response) {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;
                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if(self.id) { modelUrl = apiUrl + self.id + '/'; }

            angular.forEach(fields, function(field) {
                if(!field.readonly) {
                    data[field.name] = self[field.name];
                }
            });

            promise = $http.delete(modelUrl);
            promise.then(function (response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

        function addUser(userId) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if(self.id) { modelUrl = apiUrl + self.slug + '/users/' + userId + "/"; }
            
            promise = $http.post(modelUrl);

            promise.then(function (response) {
                self.loadUsers().then(function() {
                    deferred.resolve(self);
                });
                
            });

            return deferred.promise;
        }

        function removeUser(userId) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if(self.id) { modelUrl = apiUrl + self.slug + '/users/' + userId + "/"; }
            
            promise = $http.delete(modelUrl);

            promise.then(function (response) {
                self.loadUsers().then(function() {
                    deferred.resolve(self);
                });
                
            });

            return deferred.promise;

        }

        function loadUsers() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if(self.id) { modelUrl = apiUrl + self.slug + '/users/'; }
            
            promise = $http.get(modelUrl);

            promise.then(function (response) {

                self.users = response.data.payload;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
