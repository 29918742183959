(function () {
    'use strict';

    angular
        .module('slate.filebrowser')
        .directive('slateFileBrowserAttachments', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/filebrowser/filebrowser.attachments.dir.html',
            scope: {
                parentType: '=',
                parentFk: '=',
                path: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController($scope, $state, $location, $q, $timeout, SlateAuth, slateLightbox, getLinkSrv, SlateFileNodeList, SlateFileVerAnnotation) {
        /* jshint validthis: true */
        const vm = this;
        vm.loading = true;
        vm.nodes = new SlateFileNodeList();
        vm.images = [];

        vm.auth = SlateAuth;

        // local functions
        vm.getUrl = getUrl;
        vm.parseFilesize = parseFilesize;
        vm.get_image_type = get_image_type;
        vm.getDownloadUrl = getDownloadUrl;
        vm.openLightbox = openLightboxModal;

        vm.canDelete = canDelete;
        vm.deleteNode = deleteNode;
        // vm.undeleteNode = undeleteNode;
        vm.toggleDeleted = toggleDeleted;
        vm.show_deleted = false;

        vm.canAnnotate = canAnnotate;
        vm.annotateVer = annotateVer;

        vm.getStyle = getStyle;

        let initBounch;

        $scope.$watch('vm.parentFk', () => {
            if (vm.parentFk && vm.parentType) { init(); }
        });
        $scope.$watch('vm.parentType', () => {
            if (vm.parentFk && vm.parentType) { init(); }
        });

        $scope.$on('uploadComplete', (event, type, id) => {
            init();
        });

        $scope.$on('updateFiles', (event, type, id) => {
            if (vm.parentFk === id && vm.parentType === type) {
                init();
            }
        });

        /* beautify preserve:start */
        const types = {
            'image/png': { class: 'flaticon-png-file-extension-interface-symbol' },
            'image/jpeg': { class: 'flaticon-jpg-image-file-format' },
            'application/postscript': { class: 'flaticon-ai-file-format-symbol', color: '#FF6600' },
            'application/x-apple-diskimage': { class: 'flaticon-dmg-file-format-symbol' },
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { class: 'flaticon-xlsx-file-format', color: '#577758' },
            'application/vnd.ms-excel': { class: 'flaticon-xls-file-format-symbol', color: '#577758' },
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': { class: 'flaticon-pptx-file-format-variant', color: '#cf6a41' },
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { class: 'flaticon-docx-file-format', color: '#517fcb' },
            'application/msword': { class: 'flaticon-doc-file-format-symbol', color: '#517fcb' },
            'application/pdf': { class: 'flaticon-pdf-file-format-symbol', color: '#e81209' },
            'application/zip': { class: 'flaticon-zip-compressed-files-extension', color: '#B29772' }
        };
        /* beautify preserve:end */


        function canAnnotate(mimetype) {
            const annotatable = [
                'application/pdf', 'image/png', 'image/jpeg'
            ];
            if (annotatable.indexOf(mimetype) > -1) { return true; }
            return false;
        }

        function annotateVer(node, ver) {
            SlateFileVerAnnotation.edit(node, ver).then(() => {
                init();
            });
        }

        function deleteNode(node) {
            node.deleteModel().then(() => {
                init();
            });
        }

        function get_image_type(mimetype) {
            let results = {
                class: 'flaticon-blank-file',
                color: '#000000'
            };
            if (types[mimetype]) {
                results = angular.extend({}, results, types[mimetype]);
            }
            return results;
        }

        function getUrl(path) {
            const params = $state.params;
            let new_path = '/';
            let orig_path = path;
            if (orig_path.startsWith('/')) { orig_path = orig_path.substr(1); }
            params.path = orig_path;
            new_path = $state.href($state.current.name, params, {
                absolute: true
            }).replace(/~2F/g, '/').replace(/%252F/g, '/');
            // the regex is due to the persistant encoding of the url, regardless of options set in ui-router
            if (!new_path.endsWith('/')) {
                new_path += '/';
            }
            return new_path;
        }

        function parseFilesize(size) {
            let unit = 'B';
            let munged_size = size;
            if (munged_size === null || Number.isNaN(munged_size)) {
                return '';
            }
            if (munged_size > 1024) {
                munged_size /= 1024;
                unit = 'K';
            }
            if (munged_size > 1024) {
                munged_size /= 1024;
                unit = 'M';
            }
            if (munged_size > 1024) {
                munged_size /= 1024;
                unit = 'G';
            }
            return munged_size.toFixed(2).toString() + unit;
        }

        function getDownloadUrl(node, ver) {
            if (!ver) {
                return `${API_URL}files/download/${node.id}/`;
            }

            return `${API_URL}files/download/${node.id}/ver/${ver}/`;
        }

        function init() {
            if (initBounch) {
                $timeout.cancel(initBounch);
                initBounch = null;
            }
            initBounch = $timeout(initReal, 50);
            initBounch.then(() => { initBounch = null; });
        }

        function initReal() {
            const listFilters = {
                parent_type: vm.parentType,
                parent_fk: vm.parentFk,
                path: vm.path
            };

            if (!vm.parentFk || !vm.parentType) {
                console.log('trying to load file attachment without parent');
                return $q.reject();
            }

            vm.loading = true;

            if (vm.show_deleted) {
                listFilters.show_deleted = true;
            }
            const modelPromise = vm.nodes.getList(listFilters);
            modelPromise.then(() => {
                const imagesList = [];
                angular.forEach(vm.nodes.list, (node) => {
                    if (node.mimetype.startsWith('image')) {
                        imagesList.push({
                            url: vm.getDownloadUrl(node),
                            caption: node.description
                        });
                    }
                });
                vm.images = imagesList;
                vm.loading = false;
            });
            return modelPromise;
        }

        function openLightboxModal(index) {
            slateLightbox.openModal(vm.images, index);
        }

        function toggleDeleted() {
            vm.show_deleted = !vm.show_deleted;
            init();
        }

        function canDelete(node) {
            const today = new Date();
            const created = new Date(node.created);
            try {
                if ($scope.$root.auth.user && $scope.$root.auth.user.permissions.files_can_delete_attachments) {
                    return true;
                }
            } catch (error) {
                console.log(error);
            }

            const diffMs = (today - created);
            const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

            if ($scope.$root.auth.user.contact_id === node.core.createdby && diffMins < 720) {
                return true;
            }

            return false;
        }

        function getStyle(node) {
            const styles = {};
            if (node && node.deleted) { styles['text-decoration'] = 'line-through'; }

            return styles;
        }
    }
})();
