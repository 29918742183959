(function() {
    "use strict";

    angular
        .module('slate.library.discussion')
        .factory('SlateDiscussions', ModelList)
        .factory('SlateDiscussion', ModelDetail);


    function ModelList($http, $q, SlateDiscussion) {
        var apiUrl = API_URL + 'discussions/discussion-list/';

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters, short) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if(typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {params: self.filters});

            promise.then(function (response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function (item) {
                    var itemModel = new SlateDiscussion();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveAll(defaults) {
            /* jshint validthis: true */
            var self = this;
            var promises = [];
            angular.forEach(self.list, function(model) {
                angular.forEach(defaults, function(value, field) {
                    model[field] = value;
                });
                promises.push(model.saveModel());
            });

            return $q.all(promises);
        }

    }

    function ModelDetail ($http, $q) {
        var apiUrl = API_URL + 'discussions/discussion/';

        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();
            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },

            {name: 'createdby', readonly: true },
            {name: 'createdby_name', readonly: true },
            {name: 'createdby_slug', readonly: true },

            {name: 'created', type: 'date', readonly: true},

            {name: 'name'},
            {name: 'private', def: false },

        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if(typeof payload !== "object") {
                console.log('payload must be an object', payload);
                return $q.reject(self);
            }

            angular.forEach(fields, function(field) {
                self[field.name] = payload[field.name];
                if(field.type == 'float' && self[field.name]) {
                    self[field.name] = parseFloat(self[field.name]);
                }
                if(field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if(field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch(e) {
                        console.log('invalid json: ', payload[field.name]);
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl;

            var promise;
            var params = {};

            if(typeof id == "string" ) {
                modelUrl = modelUrl+id+"/";
            }
            if(typeof id == "object") {
                params = id;
            }

            promise = $http.get(modelUrl, {params: params});

            promise.then(function (response) {
                var promises = [];
                var loadPromise;

                loadPromise = self.loadFromPayload(response.data.payload);

                return loadPromise.then(function() {
                    self.permissions = response.data.permissions;

                    return $q.when(promises).then(function() {

                        deferred.resolve(self);
                    });

                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if(self.id) { modelUrl = apiUrl + self.id + '/'; }

            angular.forEach(fields, function(field) {
                if(!field.readonly) {
                    data[field.name] = self[field.name];
                    if(field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function (response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if(self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function (response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
