(function() {
    "use strict";

    angular
        .module('slate.presentations')
        .service('SlatePresPhotoUpload', editDialog);


    function editDialog($rootScope, $q, $uibModal) {
        var templateUrl = '/partials/presentations/presphotos.upload.srv.dialog.html';

        var srv = {
            edit: editObj
        };
        return srv;


        function editObj(obj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                size: 'lg',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',

                resolve: {
                    obj: function() {
                            return obj;
                    },
                }
            });

            return modalInstance.result.then(function(results) {
                return results;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlatePresPhoto, SlateTags, SlatePresCategories, obj) {
        /* jshint validthis: true */
        var vm = this;

        if(!obj) {
            vm.obj= {};
        } else {
            vm.obj = obj;
        }

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.wizardControl = {};

        vm.tags = SlateTags;
        vm.cats = SlatePresCategories;

        vm.image_types = ["3D Rendering", "Illustration", "Photograph", 'Sketch'];
        vm.files = [];

        function saveObject() {
            var promises = [];

            vm.saving = true;
            vm.savedPhotos = [];
            angular.forEach(vm.files, function(file) {
                vm.obj.filename = file.name;
                var photo = new SlatePresPhoto();
                photo.loadFromPayload(vm.obj);

                promises.push(photo.saveModel(file).then(function() {
                    vm.savedPhotos.push(photo);
                }));
            });

            $q.all(promises).then(function() {
                vm.saving = false;
                $uibModalInstance.close(vm.savedPhotos);
            });

        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.$watch('vm.filesAdd', function() {
            angular.forEach(vm.filesAdd, function(file) {
                vm.files.push(file);
            });
        });


    }



})();
