(function () {
    "use strict";

    angular
        .module('slate.datelog')
        .service('SlateDateChangeSrv', editDialogController);


    function editDialogController($rootScope, $state, $location, $window, $q, $uibModal, SlateAuth, SlateDateChangeDialog) {
        var srv = {
            watch: watch
        };

        return srv;

        function watch(parent, fields) {
            var origDates = {};
            fields.forEach(function(field) { origDates[field] = parent[field]; });
            var baseDates = angular.copy(origDates);
            
            fields.forEach(function(field) {
                var updated;
                
                $rootScope.$watch(function() { return parent[field]; }, function(newVal, oldVal) {
                    if (!updated) { 
                        updated = true;
                        return;
                    }

                    if (parent.id && origDates[field] && !dateFns.isEqual(origDates[field], newVal) && !dateFns.isEqual(baseDates[field], newVal)) { 
                        SlateDateChangeDialog.popout(parent, origDates[field], field).then(function(notes) {
                            parent.datelog_notes[field] = notes;
                            baseDates[field] = newVal;
                        }, function() {
                            updated = false;
                            parent[field] = baseDates[field];
                        });
                    }
                });
            });
        }

    
    }

    
})();
