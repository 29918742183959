(function () {
    'use strict';

    angular
        .module('slate.helpdesk')
        .service('SlateAddTicket', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateAuth) {
        const templateUrl = '/partials/helpdesk/ticket.add.dialog.html';

        const srv = {
            add: editObj,
        };


        return srv;


        function editObj() {
            const modalInstance = $uibModal.open({
                templateUrl,
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {},
            });


            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $uibModalInstance, $http, SlateAuth, SlateTicket) {
        /* jshint validthis: true */
        const vm = this;

        vm.auth = SlateAuth;

        vm.cancel = cancel;
        vm.save = saveObject;
        vm.isComplete = isComplete;

        vm.froalaOptions = {
            toolbarButtons: ['bold',
                'italic',
                'underline',
                '|',
                'align',
                'formatOL',
                'formatUL'],
            zIndex: 50,
        };

        vm.ticket = new SlateTicket();

        $scope.$watch('vm.ticket.type', () => {
            if (vm.ticket.type === 'slate') {
                vm.ticket.url = window.location.href.substring(0, 256);
            }
        });

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function saveObject() {
            vm.saving = true;
            vm.error = false;
            vm.ticket.saveModel().then(() => {
                // $uibModalInstance.close(vm.ticket);
                vm.fileSrv.uploadFiles('helpdeskticket', vm.ticket.id).then(() => {
                    vm.saving = false;
                });
            }, () => {
                vm.saving = false;
                vm.error = true;
            });
        }

        function isComplete() {
            if (!vm.ticket.type) { return false; }
            if (vm.ticket.type === 'slate' && !vm.ticket.area) { return false; }
            return true;
        }
    }
})();
