(function() {
    "use strict";

    angular
        .module('slate.library.shippingaccounts')
        .factory('SlateShippingAccounts', ModelList)
        .factory('SlateShippingAccount', ModelDetail);

    var apiUrl = API_URL + 'companies/shippingaccounts/';

    function ModelList($http, $q, SlateShippingAccount) {
        // Set to url relative to api root.


        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.loadFromPayload = loadFromPayload;
        list.prototype.addBlank = addBlank;
        list.prototype.saveAll = saveAll;
        list.prototype.hasAll = hasAll;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var newlist = [];

            //set this for correct model
            angular.forEach(payload, function (item) {
                var itemModel = new SlateShippingAccount();

                itemModel.loadFromPayload(item);
                newlist.push(itemModel);
            });
            self.list = newlist;

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var promise;

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            return promise.then(function(response) {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions || [];

                return self;
            });

        }

        function addBlank(defaults) {
            /* jshint validthis: true */
            var self = this;
            var newModel = new SlateShippingAccount();
            if (defaults) {
                newModel.loadFromPayload(defaults);
            }
            self.list.push(newModel);
            return newModel;
        }

        function saveAll(defaults) {
            /* jshint validthis: true */
            var self = this;
            var promises = [];

            angular.forEach(self.list, function(item) {
                if (defaults) {
                    item.loadFromPayload(defaults);
                }

                if (!item.toDelete) {
                    promises.push(item.saveModel());
                } else {
                    promises.push(item.deleteModel());
                }

                console.log(item);
            });

            return $q.all(promises).then(function() {
                angular.forEach(self.list, function(item) {
                    var index = self.list.indexOf(item);
                    self.list.splice(index, 1);
                });
            });
        }

        function hasAll(typeList) {
            /* jshint validthis: true */
            var self = this;
            var hasAllItems = true;
            angular.forEach(typeList, function(contactType) {
                var hasItem = false;

                angular.forEach(self.list, function(item) {
                    if (item.method == contactType && item.value && item.value.length && !item.toDelete) {
                        hasItem = true;
                    }
                });
                if (!hasItem) {
                    hasAllItems = false;
                }
            });
            return hasAllItems;
        }
    }



    function ModelDetail($http, $q) {
        // instantiate our initial object
        var model = function construct() {
            /* jshint validthis: true */
            var self = this;


            self.clear();

            return self;
        };

        //Set to fields for model
        var fields = [{
                name: 'id',
                def: null,
                readonly: true
            }, {
                name: 'parent',
                def: null,
                readonly: true
            }, {
                name: 'parent_fk',
                def: null
            }, {
                name: 'parent_type',
                def: null
            }, {
                name: 'account_type',
                def: null
            }, {
                name: 'value',
                def: null
            },

        ];

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;


        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });

            return self;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                if (typeof payload[field.name] !== 'undefined') {
                    self[field.name] = payload[field.name];
                }
            });

            if(self.method === "url" && self.value && !self.value.startsWith('http')) {
                self.value= "http://" + self.value;
            }

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load from server without id');
                return self;
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {

                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions || [];

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            if(self.method === "url" && self.value && !self.value.startsWith('http')) {
                self.value= "http://" + self.value;
            }

            angular.forEach(fields, function(field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                }
            });

            promise = $http.post(modelUrl, data);
            promise.then(function(response) {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;
                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
