(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .directive('slateRfqsList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/rfqs/rfqs.list.dir.html',
            scope: {
                modelFilters: '=filters',
                subview: '=?',
                showNew: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $window, $q, SlateSettings, SlateAuth, SlateRFQs, SlateRFQ, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;

        var multiSearchFields = ['category', 'material', 'reviewed', 'status'];

        vm.auth = SlateAuth;

        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;

        vm.duplicate = reqdupe;

        vm.getEditUrl = getEditUrl;

        vm.get_url = get_url;
        vm.get_new_url = get_new_url;

        vm.filters = {
            page: 1,
            count: 25,
        };
        multiSearchFields.forEach(function(field) {
            vm.filters[field] = null;
        });

        angular.extend(vm.filters, {
            q: $location.search().q,
            startDate: $location.search().startDate,
            endDate: $location.search().endDate,
            client: $location.search().client,
            vendor: $location.search().vendor,
            contact: $location.search().contact,
        }, vm.modelFilters, $location.search());

        if(vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if(vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }
        if(vm.filters.startDate) { vm.start = new Date(vm.filters.startDate); }
        if(vm.filters.endDate) { vm.end = new Date(vm.filters.endDate); }
        if(typeof vm.filters.category == 'string') { vm.filters.category = [vm.filters.category]; }
        if(typeof vm.filters.material == 'string') { vm.filters.material = [vm.filters.material]; }

        vm.rfqList = new SlateRFQs();

        $scope.$watch('vm.start', function () {
            if (vm.start) {
                vm.filters.startDate = vm.start.toISOString();
            } else {
                delete (vm.filters.startDate);
            }

        });
        $scope.$watch('vm.end', function () {
            if (vm.end) {
                vm.filters.endDate = vm.end.toISOString();
            } else {
                delete (vm.filters.endDate);
            }
        });

        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            updateFromFilter();
        });

        multiSearchFields.forEach(function(field) {
            if (vm.filters[field] && typeof vm.filters[field] === 'string') {
                vm.filters[field] = [vm.filters[field]];
            }
        });

        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function(value, key) {
                if(key == 'page' || key == 'count' || key == 'q') {
                    return false;
                }
                if(typeof vm.filters[key] == 'undefined' || vm.filters[key] === undefined || vm.filters[key] === null) {
                    return false;
                }
                if(vm.modelFilters && vm.filters[key] == vm.modelFilters[key]) {
                    return false;
                }
                if(typeof vm.filters[key] == 'string' && vm.filters[key].length === 0) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;

            });
            return hasData;
        }
        function updateFromFilter() {
            var modelPromise;

            vm.loading = true;

            angular.forEach(vm.filters, function (value, item) {

                if (vm.filters[item] && (!vm.modelFilters || vm.modelFilters[item] != vm.filters[item])) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = vm.rfqList.getList(vm.filters);
            modelPromise.then(function () {
                vm.loading = false;
            });
            vm.url = $window.location.href;
        }

        function getEditUrl(rfq) {
            if(rfq.legacy == true) {
                return $state.href('rfqLegacyEditPage', {id: rfq.id}, {absolute: true});
            }
            return $state.href('rfqEditPage', {id: rfq.id}, {absolute: true});
        }

        function get_url() {
            var url =  API_URL + 'rfq/rfq/';
            if($window.location.search.length ) {
                return url + $window.location.search + '&download=true';
            } else {
                return url + '?download=true';
            }
        }

        function get_new_url() {
            var url =  '/rfqs/new/';
            var parts = [];
            if(vm.modelFilters) {
                if(vm.modelFilters.client) { parts.push('client=' + vm.modelFilters.client); }
                if(vm.modelFilters.company) { parts.push('client=' + vm.modelFilters.company); }
                if(vm.modelFilters.project) { parts.push('project=' + vm.modelFilters.project); }
                if(parts.length) {
                    url = url + '?' + parts.join('&');
                }
            }
            return url;
        }

        function reqdupe(id) {
            if (window.confirm("Are you sure you want to copy this QC Request?")) {
                var req = new SlateRFQ();
                req.duplicate(id).then(function() {
                    $state.transitionTo("rfqEditPage", {id: req.id});
                });
            }
        }

        vm.categories = [];
        vm.materials = [];
        SlateSettings.getConfig('material_categories').then(function(config) {
            vm.categories = config;
        });
        SlateSettings.getConfig('materials').then(function(config) {
            vm.material_list = config;
            getMatList();
        });

        $scope.$watch('vm.filters.category', function() { getMatList(); });
        $scope.$watch('vm.filters.material_list.value', function() { getMatList(); });

        function getMatList() {
            var newlist = [];
            if(!vm.material_list || !vm.material_list.value) { return []; }

            if(!vm.filters.category || !vm.filters.category.length) {
                vm.materials = vm.material_list.value;
                return vm.material_list.value;
            }
            angular.forEach(vm.material_list.value, function(item) {
                if(vm.filters.category.indexOf(item.category) > -1) {
                    newlist.push(item);
                }
            });
            vm.materials = newlist;
            if(!vm.materials.length) {
                vm.materials = vm.material_list.value;
            }

            return vm.materials;
        }


    }


})();
