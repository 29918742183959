(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .controller('SlateSalesOrderEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateSalesOrder) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.salesorder = new SlateSalesOrder();

        vm.salesorder.loadFromServer($stateParams.id).then(function () {
            if (vm.salesorder.name) {
                SlateTitle.set('Edit ' + vm.salesorder.name + ' - Sales Orders');
            } else {
                SlateTitle.set('Edit Untitled order - Sales Orders');
            }
            vm.loading = false;

        }).catch(angular.noop);

        $scope.$watch('vm.salesorder.name', function () {
            if (vm.salesorder.name) {
                SlateTitle.set('Edit ' + vm.salesorder.name + ' - Sales Orders');
            } else {
                SlateTitle.set('Edit Untitled order - Sales Orders');
            }
        });

    }

})();


