(function () {
    'use strict';

    angular
        .module('slate.orders')
        .directive('slateOrderList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/orders/order.list.dir.html',
            scope: {
                modelFilters: '=?filters',
                showNew: '=?',
                hideFilters: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController($scope, $window, $state, $location, $q, SlateAuth, SlateDeleteObjSrv, SlatePOs, SlatePO, SlateOrders, SlateFileNodeList, getLinkSrv) {
        /* jshint validthis: true */
        const vm = this;
        vm.auth = SlateAuth;

        vm.getLink = getLinkSrv;
        vm.loading = true;
        if (!vm.filters) {
            vm.filters = {
                page: 1,
                count: 25
            };
        }

        vm.date_fields = ['invoiceStartDate', 'invoiceEndDate',];
        

        angular.extend(vm.filters, {
            q: $location.search().q,
            sales_mgr: $location.search().sales_mgr,
            proj_mgr: $location.search().proj_mgr,
            client: $location.search().client,
            project: $location.search().project,
            status: $location.search().status,
            page: $location.search().page,
            count: $location.search().count
        }, vm.modelFilters, $location.search(), { page: 1, count: 25 });
        if (vm.filters.page) { vm.filters.page = parseInt(vm.filters.page, 10); }
        if (vm.filters.count) { vm.filters.count = parseInt(vm.filters.count, 10); }

        vm.date_fields.forEach(function (dateField) {
            if (vm.filters[dateField]) {
                let parts = vm.filters[dateField].split('-');
                vm[dateField] = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
            }
            $scope.$watch('vm.' + dateField, function () {
                if (vm[dateField]) {
                    vm.filters[dateField] = vm[dateField].getFullYear() + '-' + (vm[dateField].getMonth() + 1) + '-' + vm[dateField].getDate();
                } else {
                    vm.filters[dateField] = null;
                }
            });
        });


        vm.orderList = new SlateOrders();

        vm.deleteOrder = deleteOrder;

        vm.updateFromFilter = updateFromFilter;
        // vm.updateFromFilter();

        vm.hasFilterData = hasFilterData;

        vm.get_new_url = get_new_url;
        vm.get_download_url = get_url;

        vm.avail_fields = [
            'order_number',
            'name',
            'projected_amount',
            'invoice_date',
            'reorder',
            'sf_status',
            'client',
            // 'project',
            'proj_mgr',
            'sales_mgr',
            'is_intercompany'
        ];
        vm.fields = vm.avail_fields;

        $scope.$watch('vm.filters.only_open', (value) => {
            if (vm.filters.only_open) { vm.filters.only_development = false; }
        });

        $scope.$watch('vm.filters.only_development', (value) => {
            if (vm.filters.only_development) { vm.filters.only_open = false; }
        });

        $scope.$watchCollection(() => vm.filters, (value) => {
            updateFromFilter();
        });


        function updateFromFilter() {
            let updateFilters;
            vm.loading = true;

            if (vm.filters.only_development) { vm.filters.only_open = false; }

            angular.forEach(vm.filters, (value, item) => {
                if (vm.modelFilters && vm.filters[item] === vm.modelFilters[item]) {
                    return false;
                }

                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }

                return true;
            });

            updateFilters = Object.assign({}, vm.filters);
            if(!updateFilters.order_by) {
                updateFilters.order_by = '-created';
            }

            const modelPromise = vm.orderList.getList(updateFilters);
            modelPromise.then(() => {
                vm.loading = false;
            });
            vm.url = $window.location.href;
        }


        function get_new_url() {
            let url = '/orders/orders/new/';
            const parts = [];
            if (vm.modelFilters) {
                if (vm.modelFilters.client) { parts.push(`client=${vm.modelFilters.client}`); }
                if (vm.modelFilters.company) { parts.push(`client=${vm.modelFilters.company}`); }
                if (vm.modelFilters.project) { parts.push(`project=${vm.modelFilters.project}`); }
                if (vm.orderList.list.length) { parts.push('reorder=true'); }
                if (parts.length) {
                    url = `${url}?${parts.join('&')}`;
                }
            }
            return url;
        }

        function get_url() {
            const url = API_URL + 'orders/orders/';
            const downloadFilters = {};

            Object.keys(vm.filters).forEach((item) => {
                if(['page', 'count'].includes(item)) { return false; }
                if([null, undefined].includes(vm.filters[item])) { return false; }
                downloadFilters[item] = vm.filters[item];
            });

            if(!downloadFilters.order_by) {
                downloadFilters.order_by = '-created';
            }

            const searchParams = new URLSearchParams(downloadFilters);
            console.log(searchParams.toString());
            if (searchParams.toString()) {
                return url + '?' + searchParams.toString() + '&download=true';
            } else {
                return url + '?download=true';
            }
        }

        function deleteOrder(order) {
            SlateDeleteObjSrv.delete('order', order.id).then(() => {
                updateFromFilter();
            });
        }

        function hasFilterData() {
            let hasData = false;
            angular.forEach(vm.filters, (value, key) => {
                if (key === 'page' || key === 'count' || key === 'order_by' || key === 'q') {
                    return false;
                }
                if (value === undefined || value === null) {
                    return false;
                }
                if (vm.modelFilters && vm.filters[key] === vm.modelFilters[key]) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;
            });
            return hasData;
        }
    }
})();
