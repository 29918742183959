(function () {
    "use strict";

    angular
        .module('slate.estimates')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('vendorEstimateListPage', {
                url: '/estimates/vendor-estimates/',
                template: "<div class='content-pane list-page'><slate-vendor-estimates-list show-new='true'></slate-vendor-estimates-list></div>",
                current_app: 'vendorEstimates',
                slateTitle: 'Vendor Estimates',
            })
            .state('vendorEstimateNewPage', {
                url: '/estimates/vendor-estimates/new/',
                templateUrl: '/partials/estimates/vendorestimates.edit.page.html',
                controller: 'SlateVendorEstimateNewPageController',
                controllerAs: 'vm',
                current_app: 'vendorEstimates',
                slateTitle: 'New - Vendor Estimates',
            })
            .state('vendorEstimateEditPage', {
                url: '/estimates/vendor-estimates/:id/edit/',
                templateUrl: '/partials/estimates/vendorestimates.edit.page.html',
                controller: 'SlateVendorEstimateEditPageController',
                controllerAs: 'vm',
                current_app: 'vendorEstimates',
                slateTitle: 'Edit - Vendor Estimates',
            })
            .state('vendorEstimateResponseViewPage', {
                url: '/estimates/vendor-estimate-response/:id/view/',
                templateUrl: '/partials/estimates/vendorestimates.view.page.html',
                controller: 'SlateVendorEstimateResponseViewPageController',
                controllerAs: 'vm',
                current_app: 'vendorEstimates',
                slateTitle: 'View Response - Vendor Estimates',
            })
            .state('salesEstimateListPage', {
                url: '/estimates/sales-estimates/',
                template: "<div class='content-pane list-page'><slate-sales-estimates-list show-new='true'></slate-sales-estimates-list></div>",
                current_app: 'salesEstimates',
                slateTitle: 'Sales Estimates',
            })
            .state('salesEstimateNewPage', {
                url: '/estimates/sales-estimates/new/',
                templateUrl: '/partials/estimates/salesestimates.edit.page.html',
                controller: 'SlateSalesEstimateNewPageController',
                controllerAs: 'vm',
                current_app: 'salesEstimates',
                slateTitle: 'New - Sales Estimates',
            })
            .state('salesEstimateEditPage', {
                url: '/estimates/sales-estimates/:id/edit/',
                templateUrl: '/partials/estimates/salesestimates.edit.page.html',
                controller: 'SlateSalesEstimateEditPageController',
                controllerAs: 'vm',
                current_app: 'salesEstimates',
                slateTitle: 'Edit - Sales Estimates',
            })

            ;
    }
})();