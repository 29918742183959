var controlPanelCtrl = angular.module('slate.controlPanel.ctrl', ['slate',  'slate.milestones.srv', 'JansyTaskSrv', 'slate.profilePhotoSrv',]);

controlPanelCtrl.controller('controlPanelCtrl', function($scope) {
    $scope.panels = [
        {
            name: 'Workflow Templates', 
            img: '/img/controlpanels/workflows.png',
            description: 'Edit templates listed when creating a new project or adding a workflow from a template.',
            url: '/controlpanel/workflows/'
        },
        {
            name: 'FileArea Templates', 
            img: '/img/controlpanels/fileareas.png',
            description: 'Folders that are autopopulated in new project file areas.',
            url: '/controlpanel/fileareas/'
        },
    ];

});

controlPanelCtrl.controller('workflowPanelCtrlOld', function($scope, MilestoneList) {
    $scope.milestoneList = new MilestoneList();

    $scope.getList = function () {
        $scope.milestoneList.getList({templates: 'only'});
    };


    $scope.getList();
});

controlPanelCtrl.controller('workflowPanelCtrl', function ($scope, $uibModal, MilestoneList, tasksSrv, getLinkSrv) {
    $scope.getLink = getLinkSrv;
    $scope.loaded = false;
    $scope.milestoneList = new MilestoneList();
    $scope.availRolesforFilter = [];
    $scope.filters = {
        role: {},
        completed: true
    };


    $scope.sortableOptions = {
        handle: '.dragHandle',
        stop: function(e, ui) { 
            for (var index in $scope.milestoneList.milestones) {
                $scope.milestoneList.milestones[index].order = parseInt(index) + 1;
                $scope.milestoneList.milestones[index].saveObject();
            }
        }
      };

    $scope.getAvailRolesforFilter = function() {
        var roleList = [];
        var added = {};
        angular.forEach($scope.milestoneList.milestones, function(milestone) {
            angular.forEach(milestone.roles.roles, function(role) {
                var member_hash = role.member_type + role.member_fk;
                if(!added[member_hash]) {
                    added[member_hash] = true;
                    roleList.push(role);
                }
            });
        });
        $scope.availRolesforFilter = roleList;
    };

    $scope.getList = function () {
        $scope.loaded = false;
        $scope.milestoneList.getList({templates: 'only'}).then(function() { 
                $scope.getAvailRolesforFilter(); 
                $scope.loaded = true;
        });
    };

    $scope.totalCompleted = function() {
        var total = 0;
        angular.forEach($scope.milestoneList.milestones, function(milestone) {
            if (milestone.computeDone().perc >= 100) {
                total++;
            }
        });
        return total;

    };

    $scope.addMilestoneFromTemplate = function () {
        var promise = milestonesSrv.createFromTemplate();
        promise.then(function(data) {
            $scope.getList();
        });
    };


    $scope.getDone = function(milestone) {
        var done = milestone.computeDone();
        return done;
    };

    $scope.addTask = function (milestone) {
        var promise = tasksSrv.editObject({milestone: milestone.id});
        
        promise.then(function(data) {
            milestone.tasks.push(data);
        });
    };

    $scope.makeTemplate = function (milestone) {
        var promise = milestonesSrv.makeTemplate(milestone);
        promise.then(function(data) {

        });
    };

    $scope.deleteMilestone = function (milestone) {
        var index = $scope.milestoneList.milestones.indexOf(milestone);
        if(index > -1) {
            $scope.milestoneList.milestones.splice(index, 1);
        }
        milestone.deleteObject();
    };
    
    $scope.assignMilestone = function(milestone) {
        milestone.roles.editRoles('milestone', milestone.id, true);
    };

    $scope.getList(); 
    

});


controlPanelCtrl.controller('workflowDetailsPanelCtrl', function ($scope, $state, $stateParams, currentObjectSrv, Milestone, tasksSrv, getLinkSrv) {
    $scope.loaded = false;
    $scope.getLink = getLinkSrv;

    $scope.milestone = new Milestone();

    $scope.gotoWorkflows = function() {
        
        $state.transitionTo('controlPanel.Workflow', $stateParams);
    };

    $scope.getMileStone = function () {
        $scope.milestone.id = $stateParams.workflowid;
        $scope.milestone.getObject().then(function() {
            $scope.loaded = true;
        });
    };


    $scope.getDone = function(milestone) {
        var done = milestone.computeDone();
        return done;
    };

    $scope.addTask = function (milestone) {
        var promise = tasksSrv.editObject({milestone: milestone.id});
        
        promise.then(function(data) {
            milestone.tasks.push(data);
        });
    };

    $scope.makeTemplate = function (milestone) {
        var promise = milestonesSrv.makeTemplate(milestone);
        promise.then(function(data) {

        });
    };

    $scope.deleteMilestone = function (milestone) {
        var index = $scope.milestoneList.milestones.indexOf(milestone);
        if(index > -1) {
            $scope.milestoneList.milestones.splice(index, 1);
        }
    };
    
    $scope.assignMilestone = function(milestone) {
        milestone.roles.editRoles('milestone', milestone.id, true);
    };

    $scope.getMileStone(); 
        

});
