(function() {
    "use strict";

    angular
        .module('slate.app')
        .service('slateLightbox', editDialog);


    function editDialog($rootScope, $q, $uibModal) {
        var templateUrl = '/partials/slate/slate.lightbox.html';

        var srv = {
            openModal: editObj
        };

        return srv;

        function editObj(images, index) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    images: function() {
                        return images;
                    },
                    index: function() {
                        return index;
                    }
                }
            });
        }
    }

    function editDialogCtrl($uibModalInstance, images, index) {
        /* jshint validthis: true */
        var vm = this;
        vm.images = images;
        vm.index = index;

        vm.cancel = cancel;
        vm.size = "?size=fullsize";
        if(window.innerWidth <= 740) {
            vm.size="?size=thumb";
        } else if(window.innerWidth <= 1440) {
            vm.size="?size=tablet";
        } else if(window.innerWidth <= 2880) {
            vm.size="?size=screen";
        } 

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }



})();
