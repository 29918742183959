(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetRfqLogs', modelList)
        .directive('slateWidgetRfqLogsLine', modelLine);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.rfqlog.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $interval, SlateAuth, SlateRolesSrv, SlateRFQs, SlateRFQ, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var interval = null;
        var speed = vm.settings.speed || 1080000;
        vm.auth = SlateAuth;

        vm.loading = true;

        vm.settings.page = 1;
        if(!vm.settings.count) {
            vm.settings.count = 8;
        }

        vm.getLink = getLinkSrv;
        vm.rfqlogs = new SlateRFQs();

        vm.statusOptions = ['Draft', 'Ready', 'Quoting', 'Completed', 'Exception Hold', 'Finalized', 'Revised Quote'];

        function getData() {
            var filters = {
                only_mine: vm.settings.only_mine,
                my_rfqs: vm.settings.my_rfqs,
                only_china: vm.settings.only_china,
                alt_source: vm.settings.alt_source,
                unassigned: vm.settings.unassigned,
                page: vm.settings.page,
                count: vm.settings.count
            };
            if (vm.settings.show_completed === true) {
                filters.hide_completed = 10;
            } else {
                filters.hide_completed = 0;
            }
            if(vm.settings.status && vm.settings.status !== '') {
                filters.status = vm.settings.status;
            }
            if(vm.settings.unapproved) { filters.reviewed = 'New'; filters.view_all = true; }
            vm.rfqlogs.getList(filters).then(function() {
                vm.loading = false;
            });
        }

        //getData();

        $scope.$watch('vm.settings', function(items) {
            vm.loading = true;
            getData();
        }, true);

        interval = $interval(function() {
            getData();
        }, speed);

        $scope.$on('$destroy', function() {
            if (angular.isDefined(interval)) {
                $interval.cancel(interval);
                interval = undefined;
            }
        });
    }

    function modelLine() {
        return {
            restrict: 'E',

            templateUrl: '/partials/dashboard/dashboard.widget.rfqlogline.html',
            scope: {
                entry: "="
            },
            controller: LineController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function LineController($scope, SlateAuth, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var unwatch;

        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;

        vm.toggleCompleted = toggleCompleted;
        vm.getCompleted = getCompleted;

        function getCompleted() {
            if(vm.entry.source_rep_do_not_assign) {
                return vm.entry.vendors_complete;
            } else {
                return vm.entry.china_vendors_complete;
            }
        }

        function toggleCompleted() {

            if(vm.auth.perms.rfq_can_mark_complete) {
                if(vm.entry.source_rep_do_not_assign) {
                    vm.entry.vendors_complete = !vm.entry.vendors_complete;
                } else {
                    vm.entry.china_vendors_complete = !vm.entry.china_vendors_complete;
                }
                vm.entry.saveModel();
            }
        }

        $scope.$watch('vm.entry.source_rep', function(new_item, old_item) {
            if(new_item != old_item && vm.auth.perms.rfq_can_ass_rep) {

                vm.entry.saveModel();
            }

        });

    }

})();
