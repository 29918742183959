(function() {
    "use strict";

    angular
        .module('slate.customercomplaints')
        .directive('slateCustomerComplaintList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/customercomplaints/customercomplaint.list.dir.html',
            scope: {
                hideFilters: '=?',
                modelFilters: '=?filters',
                showTotals: '=?',
                fields: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlateDeleteObjSrv, SlateCustomerComplaints) {
        /* jshint validthis: true */
        var vm = this;

        vm.loading = true;
        vm.auth = SlateAuth;
        vm.complaints = new SlateCustomerComplaints();
        vm.deleteComplaint = deleteComplaint;
        var multiSearchFields = ['status'];

        if(!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'jansy_po',
                'description',
                'status',
                'date',
                'created',
            ];
            /* beautify preserve:end */
        }

        vm.filters = angular.extend({page: 1, count: 25}, vm.modelFilters, $location.search());
        if (vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if (vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        function updateFromFilter() {
            vm.loading = true;

            multiSearchFields.forEach(function(field) {
                if (vm.filters[field] && typeof vm.filters[field] === 'string') {
                    vm.filters[field] = [vm.filters[field]];
                }
            });

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            vm.complaints.getList(vm.filters).then(function() {
                vm.loading = false;
            }, function() {
    
            });
        }

        function deleteComplaint(complaint) {
            SlateDeleteObjSrv.delete('customercomplaint', complaint.id).then(function() {
                updateFromFilter();
            });
        }

    }


})();
