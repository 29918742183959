(function () {
    "use strict";

    angular
        .module('slate.bom')
        .directive('slateBomItemsEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/bom/bomitems.edit.dir.html',
            scope: {
                bom: '=',
                bomitems: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $timeout, $q, SlateBOMItems, SlateBOMItem) {
        /* jshint validthis: true */
        var vm = this;

        vm.addBOMItem = addBOMItem;

        function addBOMItem() {
            var bomitem = new SlateBOMItem();
            vm.bomitems.list.push(bomitem);
        }
    }

})();
