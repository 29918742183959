(function() {
    "use strict";

    angular
        .module('slate.companies')
        .directive('slateAccounting', slateDirective);

    function slateDirective() {
        return {
            restrict: 'E',
            templateUrl: '/partials/companies/company.accounting.dir.html',
            scope: {
                company: '=',
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function directiveController($scope) {
        /* jshint validthis: true */
        var vm = this;

    }

})();
