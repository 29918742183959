(function() {
    "use strict";

    angular
        .module('slate.library.presentations')
        .service('SlatePresCategories', ModelList);

    var apiUrl = API_URL + 'presentations/categories/';

    function ModelList($http, $q) {
        // Set to url relative to api root.
        var promise;

        // instantiate our initial object
        var list = {
            list: [],
            getList: getList,
        };

        list.promise = getList();
        return list;

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var promise;

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl);

            promise.then(function(response) {
                list.list = response.data.payload;

                return list;
            });

            return promise;
        }

    }



})();
