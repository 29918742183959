(function () {
    'use strict';

    angular
        .module('slate.library.locations')
        .factory('SlateLocations', ModelList)
        .factory('SlateLocation', ModelDetail);

    const apiUrl = `${API_URL}contacts/addresses/`;

    function ModelList($http, $q, SlateLocation) {
        // Set to url relative to api root.


        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.loadFromPayload = loadFromPayload;
        list.prototype.addBlank = addBlank;
        list.prototype.saveAll = saveAll;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const newlist = [];

            // set this for correct model
            angular.forEach(payload, (item) => {
                const itemModel = new SlateLocation();

                itemModel.loadFromPayload(item);
                newlist.push(itemModel);
            });
            self.list = newlist;

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            const promise = $http.get(apiUrl, { params: self.filters });

            promise.then((response) => {
                self.loadFromPayload(response.data);
                self.permissions = response.data.permissions || [];

                return self;
            });

            return promise;
        }

        function addBlank() {
            /* jshint validthis: true */
            const self = this;
            const newModel = new SlateLocation();
            self.list.push(newModel);
            return newModel;
        }

        function saveAll(defaults) {
            /* jshint validthis: true */
            const self = this;
            const promises = [];

            angular.forEach(self.list, (item) => {
                if (typeof defaults === 'object') {
                    item.loadFromPayload(defaults);
                }

                if (!item.toDelete) {
                    promises.push(item.saveModel());
                } else {
                    promises.push(item.deleteModel());
                }
            });

            return $q.all(promises).then(() => {
                angular.forEach(self.list, (item) => {
                    const index = self.list.indexOf(item);
                    self.list.splice(index, 1);
                });
            });
        }
    }


    function ModelDetail($http, $q) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        const fields = [
            { name: 'id', readonly: true },
            { name: 'parent', readonly: true },
            { name: 'parent_id' },
            { name: 'parent_type' },
            { name: 'has_warehouse' },

            { name: 'qualifier' },
            { name: 'street1' },
            { name: 'street2' },
            { name: 'street3' },
            { name: 'city' },
            { name: 'state' },
            { name: 'county' },
            { name: 'postal' },
            { name: 'country', def: 'United States' },

            { name: 'is_warehouse', def: false },
            { name: 'warehouse_name' },
            { name: 'warehouse_type', type: 'json' }

        ];

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.warehouse_types = ['customer',
            'factory',
            'filler',
            'warehouse',
            'invlocation',
            'oceanlcl',
            'oceanfcl',
            'oceanexp',
            'air',
            'truck',
            'ups',
            'fedex',
            'dhl',
            'other'];

        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            angular.forEach(fields, (field) => {
                self[field.name] = _.cloneDeep(field.def);
            });

            return self;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            if (typeof payload !== 'object') {
                console.log('payload must be an object', payload);
                return self;
            }

            angular.forEach(fields, (field) => {
                if (typeof payload[field.name] !== 'undefined') {
                    self[field.name] = payload[field.name];
                }

                if (field.type === 'json' && payload[field.name] && typeof payload[field.name] === 'string') {
                    try {
                        self[field.name] = JSON.parse(payload[field.name]);
                    } catch (e) {
                        console.log('could not parse', field.name);
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            if (id) { modelUrl = `${apiUrl + id}/`; }

            if (!self.id && !id) {
                console.log('Tried to load from server without id');
                return self;
            }

            const promise = $http.get(modelUrl);

            promise.then((response) => {
                self.loadFromPayload(response.data);
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            const data = {};

            if (self.id) { modelUrl = `${apiUrl + self.id}/`; }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (field.type === 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });

            const promise = $http.post(modelUrl, data);
            promise.then((response) => {
                self.loadFromPayload(response.data);
                self.permissions = response.data.permissions;
                deferred.resolve(self);
            }, () => { console.log('failed to save location'); });

            return deferred.promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            const promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }
})();
