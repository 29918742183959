(function () {
    'use strict';

    angular
        .module('slate.library.roles')
        .factory('SlateAssignedRoles', ModelList)
        .factory('SlateAssignedRole', ModelDetail);

    const apiUrl = `${API_URL}roles/assigned_roles/`;

    function ModelList($http, $q, SlateAssignedRole, SlateRolesSrv) {
        // Set to url relative to api root.


        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.loadListFromPayload = loadListFromPayload;
        list.prototype.addBlank = addBlank;
        list.prototype.saveAll = saveAll;
        list.prototype.canDelete = canDelete;
        list.prototype.hasAll = hasAll;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];
            self.requiredRoles = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            let promise;

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then((response) => {
                self.permissions = response.data.permissions;
                return self.loadListFromPayload(response.data.payload);
            });

            return promise;
        }

        function loadListFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;

            const newlist = [];

            // set this for correct model
            angular.forEach(payload, (item) => {
                const itemModel = new SlateAssignedRole();

                itemModel.loadFromPayload(item);
                newlist.push(itemModel);
            });
            self.list = newlist;
            return self;
        }

        function addBlank(defaults) {
            /* jshint validthis: true */
            const self = this;
            const newModel = new SlateAssignedRole();
            if (defaults) {
                newModel.loadFromPayload(defaults);
            }
            self.list.push(newModel);
            return newModel;
        }

        function canDelete(item) {
            /* jshint validthis: true */
            const self = this;
            let count = 0;

            if (!item.roles.length) { return true; }

            if (item.roles.length && self.requiredRoles.indexOf(SlateRolesSrv.dict[item.roles[0]].name) < 0) {
                return true;
            }

            if (item.toDelete) { return true; }

            angular.forEach(self.list, (role) => {
                if (role.roles.length && item.roles.length && role.roles[0] == item.roles[0] && !role.toDelete) {
                    count += 1;
                }
            });
            return count > 1;
        }

        function hasAll() {
            /* jshint validthis: true */
            const self = this;
            const hasroles = {};
            let hasall = true;
            angular.forEach(self.list, (role) => {
                if (role.member_fk && role.roles.length) {
                    hasroles[SlateRolesSrv.dict[role.roles[0]].name] = true;
                }
            });

            angular.forEach(self.requiredRoles, (item) => {
                if (!hasroles[item]) { hasall = false; }
            });
            return hasall;
        }

        function allValid() {
            /* jshint validthis: true */
            const self = this;
            return self.hasall();
        }

        function saveAll(defaults) {
            /* jshint validthis: true */
            const self = this;
            const promises = [];

            angular.forEach(self.list, (item) => {
                if (typeof defaults === 'object') {
                    item.loadFromPayload(defaults);
                }

                if (!item.toDelete && item.member) {
                    promises.push(item.saveModel());
                } else if (item.id) {
                    promises.push(item.deleteModel());
                }
            });

            return $q.all(promises).then(() => self.getList(defaults));
        }
    }


    function ModelDetail($http, $q) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', def: null, readonly: true },
            { name: 'parent', def: null, readonly: true },
            { name: 'parent_fk', def: null },
            { name: 'parent_type', def: null },

            { name: 'member', def: null, readonly: true },
            { name: 'member_fk', def: null },
            { name: 'member_type', def: null },
            { name: 'member_slug', def: null, readonly: true },

            { name: 'roles', def: [] }
        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;
            });

            return self;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            if (typeof payload !== 'object') {
                console.log('payload must be an object', payload);
                return self;
            }

            angular.forEach(fields, (field) => {
                if (typeof payload[field.name] !== 'undefined') {
                    self[field.name] = payload[field.name];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            let promise;

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                console.log('Tried to load from server without id');
                return self;
            }

            promise = $http.get(modelUrl);

            promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                }
            });

            promise = $http.post(modelUrl, data);
            promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;
                deferred.resolve(self);
            }, () => {

            });

            return deferred.promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }
})();
