(function () {
    'use strict';

    angular
        .module('slate.library.bom')
        .factory('SlateBOMItems', ModelList)
        .factory('SlateBOMItem', ModelDetail);

    const apiUrl = `${API_URL}boms/bomitem/`;

    function ModelList($http, $q, SlateBOMItem) {
        // Set to url relative to api root.

        // instantiate our initial object
        const list = construct;
        function construct() {
            /* jshint validthis: true */
            const self = this;

            self.clear();

            return self;
        }

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            if (!self.filters.bom) {
                // eslint-disable-next-line no-console
                console.log('tried to load bom items with no id');
                deferred.reject();
                return deferred.promise;
            }
            const promise = $http.get(apiUrl, {
                params: self.filters,
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateBOMItem();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.totalItems = response.data.totalItems;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        const model = construct;
        function construct() {
            /* jshint validthis: true */
            const self = this;

            self.clear();

            return self;
        }

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', def: null, readonly: true },

            { name: 'bom' },

            { name: 'item_description' },
            { name: 'sequence' },

            { name: 'component' },
            { name: 'component_info', readonly: true },
            { name: 'component_bom' },

            { name: 'vendor' },
            { name: 'vendor_info', readonly: true },

            { name: 'supplier' },

            { name: 'internal_item_number' },
            { name: 'vendor_item_number' },
            { name: 'client_item_number' },

            { name: 'category' },
            { name: 'material' },

            { name: 'unit_name' },
            { name: 'qty_required' },
            { name: 'scrap_factor' },
            { name: 'notes' },

            { name: 'rfq' },

            { name: 'updated', readonly: true, type: 'date' },
            { name: 'created', readonly: true, type: 'date' },
            { name: 'created_by', readonly: true },
            { name: 'created_by_info', readonly: true },

        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.getItemTotal = getItemTotal;
        model.prototype.checkBOMInStock = checkBOMInStock;
        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                // eslint-disable-next-line no-console
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                self[field.name] = payload[field.name];

                if (field.type === 'date' && payload[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type === 'dateOnly' && payload[field.name]) {
                    const parts = self[field.name].split('-');
                    self[field.name] = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
                }

                if (field.type === 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.log('didnt parse json', e);
                    }
                }

                if (field.type === 'float' && payload[field.name]) {
                    self[field.name] = parseFloat(self[field.name]);
                    if (Number.isNaN(self[field.name])) {
                        self[field.name] = null;
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id, show_goods) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;
            const filters = {};

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            if (show_goods) { filters.show_goods = true; }

            const promise = $http.get(modelUrl, {
                params: filters,
            });

            promise.then((response) => {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            let modelUrl = apiUrl;

            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (field.type === 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if (field.type === 'date' && self[field.name]) {
                        data[field.name] = self[field.name].toISOString();
                    }
                    if (field.type === 'dateOnly' && self[field.name]) {
                        data[field.name] = `${self[field.name].getFullYear()}-${self[field.name].getMonth() + 1}-${self[field.name].getDate()}`;
                    }
                    if (field.trim && data[field.name]) {
                        data[field.name] = data[field.name].substr(0, field.trim);
                    }
                }
            });

            const promise = $http.post(modelUrl, data);
            return promise.then((response) => {
                const promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            const promise = $http.delete(modelUrl);
            promise.then(() => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function getItemTotal(qty_param) {
            /* jshint validthis: true */
            const self = this;

            let qty = new Big(qty_param);
            let scrap;

            if (!self.qty_required) { self.qty_required = 0; }
            if (!self.scrap_factor) { self.scrap_factor = 0; }

            const req = new Big(self.qty_required);
            scrap = new Big(self.scrap_factor);
            scrap = scrap.div(100);

            qty = qty.times(req);
            qty = qty.plus(qty.times(scrap));
            return qty.toString();
        }

        function checkBOMInStock(warehouse_id, req_qty) {
            /* jshint validthis: true */
            const self = this;

            const deferred = $q.defer();

            const stockApiUrl = `${API_URL}projects/project/${self.component}/stock/`;
            let req_inStock = true;

            $http.get(stockApiUrl).then((response) => {
                response.data.payload.forEach((item) => {
                    if (warehouse_id === item.warehouse_id) {
                        const instock = parseFloat(Big(item.qty).toFixed(2));
                        if (instock < (self.qty * req_qty)) {
                            req_inStock = false;
                        }
                    }
                });
                if (req_inStock) {
                    deferred.resolve(true);
                } else {
                    deferred.resolve(false);
                }
            }, () => {
                deferred.resolve(false);
            });

            return deferred.promise;
        }
    }
})();
