var activityLogControllers = angular.module('slate.activityLogCtrl', ['slate', 'slate.activityLogSrv', 'slate.currentObjectSrv']);

activityLogControllers.controller('activityLogTabCtrl', function ($scope, $state, $stateParams, $location, getLinkSrv, ActivityLogList, currentObjectSrv) {
    "use strict";
    
    $scope.currentObject = currentObjectSrv;
    $scope.getLinkSrv = getLinkSrv;
    $scope.actLogs = [];
    $scope.actLogType = $state.current.activitylogType;

    $scope.pager = {
        itemsPerPage: 15,
        boundryLinks: true,
        directionLinks: true,
        maxSize: 10,
        totalItems: 0,
        page: 1
    };

    var filters = {};

    var params = $location.search();
    if (typeof params.page !== 'undefined') {
        
        $scope.pager.page = params.page;
    }

    // set filters depending on what type of object it is.
    if($state.current.activitylogType == "activity") {
        currentObjectSrv.promise.then(function() {
            
            if(currentObjectSrv.type == "contact") {
                filters.q = currentObjectSrv.object.id;
                $scope.activitylog = new ActivityLogList(filters);
                $scope.activitylog.getList(filters);
            }
        });
    } else {
        currentObjectSrv.promise.then(function() {
            filters[currentObjectSrv.type] = currentObjectSrv.object.id;
            $scope.activitylog = new ActivityLogList(filters);
            $scope.activitylog.getList(filters);

        });
    }
    
    //$scope.activitylog = new ActivityLogList(filters);
    //$scope.activitylog.getList(filters);

    $scope.updateDataSet = function() {
        var startIndex = ($scope.pager.page-1) * $scope.pager.itemsPerPage;
        var endIndex = startIndex + 1 + $scope.pager.itemsPerPage;        
        if(typeof $scope.activitylog !== "undefined") {
            $scope.actLogs = $scope.activitylog.activityLogList.slice(startIndex, endIndex);
        }
    };

    $scope.$watch(function(){return $location.search().page;}, function(page){
        if (typeof page === 'undefined') {
            $scope.pager.page = 1;
        } else {
            $scope.pager.page = page;
        }
        $scope.updateDataSet();
    });

    $scope.$watchCollection('activitylog.activityLogList', function() {
    
        $scope.pager.totalItems = $scope.activitylog.activityLogList.length;
        $scope.updateDataSet();
    });

    $scope.$watch('pager.page', function() {
        var page = $location.search().page;
        if (typeof page !== 'undefined' && $scope.pager.page != 1) {
            $location.search('page', $scope.pager.page);
        }
    });



});
