(function () {
    'use strict';

    angular
        .module('slate.library', [
            'slate.library.auth',
            'slate.library.roles',
            'slate.library.settings',
            'slate.library.companies',
            'slate.library.contacts',
            'slate.library.locations',
            'slate.library.contactmethods',
            'slate.library.shippingaccounts',
            'slate.library.profilephotos',
            'slate.library.projects',
            'slate.library.presentations',
            'slate.library.filebrowser',
            'slate.library.approvals',
            'slate.library.workflows',
            'slate.library.timetracking',
            'slate.library.sampletracking',
            'slate.library.discussion',
            'slate.library.pos',
            'slate.library.purchaseorders',
            'slate.library.salesorders',
            'slate.library.salesquotes',
            'slate.library.salesbudgets',
            'slate.library.invoices',
            'slate.library.notifications',
            'slate.library.schedule',
            'slate.library.rfqs',
            'slate.library.qc',
            'slate.library.inspections',
            'slate.library.qima',
            'slate.library.customercomplaints',
            'slate.library.logistics',
            'slate.library.orders',
            'slate.library.datelog',
            'slate.library.bom',
            'slate.library.orderplan',
            'slate.library.billings',
            'slate.library.rates',
            'slate.library.conditions',
            'slate.library.estimates',
            'slate.library.helpdesk',
            'slate.library.vendorsetuprequests',
            'slate.library.salesforce',
            'slate.library.productprofiles',
            'slate.library.synergy'
        ]);
})();
