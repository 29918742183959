(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .controller('SlateRfqResponseSummaryPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $timeout, $state, $location, SlateTitle, getLinkSrv, $q, $stateParams, SlateAuth, SlateRFQ, SlateRFQResponse, SlateRFQResponses) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;
        vm.loading = true;

        vm.responses = new SlateRFQResponses();
        vm.rfq = new SlateRFQ();

        function getData(id) {
            var promises = [];
            vm.loading = true;
            promises.push(vm.rfq.loadFromServer($stateParams.id));
            promises.push(vm.responses.getList({rfq: $stateParams.id}));
            $q.all(promises).then(function() {
                vm.loading = false;
            });
        }
        getData($stateParams.id);

    }

})();
