(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .directive('slateRfqResponseSummary', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/rfqs/rfq.response.summary.dir.html',
            scope: {
                responses: '=',
                rfq: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $state,
        getLinkSrv,
        SlateAuth,
        SlateRFQ
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

        vm.statusOptions = ['Draft', 'Ready', 'Quoting', 'Quoted', 'Completed', 'Cancelled', 'Exception Hold'];


        vm.items = {};

        vm.rfq.line_items.forEach(function(item) {
            console.log(item);
            var splits = item.splits;
            if(!splits.includes(item.qty)) { splits.push(item.qty); }
            vm.items[item.id] = {
                name: item.component_info.composite_name,
                splits: item.splits,
                primary: {},
                primary_lows: {},
                options: {},
                flatcosts: {}
            };
            vm.responses.list.forEach(function(response) {
                response.line_items.forEach(function(resp_item) {
                    if(resp_item.rfq_comp != item.id) {
                        return false;
                    }
                });
            });
        });


    }

})();
