(function() {
    "use strict";

    angular
        .module('slate.logistics')
        .controller('SlateShipmentEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, SlateDateChangeSrv, $stateParams, SlateShipment) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.shipment = new SlateShipment();
        vm.dateChangeSrv = SlateDateChangeSrv;

        vm.shipment.loadFromServer($stateParams.id).then(function () {
            if (vm.shipment.name) {
                SlateTitle.set('Edit ' + vm.shipment.name + ' - Logistics');
            } else {
                SlateTitle.set('Edit Untitled Shipment - Logistics');
            }
            vm.loading = false;
            
        }).catch(angular.noop);

        $scope.$watch('vm.logistics.name', function () {
            if (vm.shipment.name) {
                SlateTitle.set('Edit ' + vm.shipment.name + ' - Logistics');
            } else {
                SlateTitle.set('Edit Untitled Shipment - Logistics');
            }
        });

    }

})();


