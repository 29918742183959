(function () {
    'use strict';

    angular
        .module('slate.synergy')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('synergyEmailsListPage', {
                url: '/synergy/email/',
                template: '<div class="content-pane list-page"><slate-synergy-emails-list></slate-synergy-emails-list></div>',
                current_app: 'synergy',
                slateTitle: 'Intercompany Emails'
            })
            .state('synergyEmailEditPage', {
                url: '/synergy/email/:id/edit/',
                templateUrl: '/partials/synergy/synergy.emails.edit.page.html',
                controller: 'SlateSynergyEmailEditPageController',
                controllerAs: 'vm',
                current_app: 'synergy',
                slateTitle: 'Edit Synergy Email'
            });
    }
})();
