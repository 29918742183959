(function () {
    "use strict";

    angular
        .module('slate.bom')
        .directive('slateBomItemsList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/bom/bomitems.list.dir.html',
            scope: {
                modelFilters: '=filters',
                bomItemsList: '=?',
                finalized: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $window, $q, getLinkSrv, SlateAuth, SlateProject, SlateRFQ, SlateBOM, SlateBOMItem, SlateBOMItems, SlateBOMItemEditSrv, SlateBOMAddCompsSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

        vm.bomItemsList = new SlateBOMItems();

        vm.isEditable = isEditable;
        vm.editObj = editObj;
        vm.newObj = newObj;
        vm.addComps = addComps;
        vm.deleteObj = deleteObj;

        vm.newRfq = newRfq;

        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.updateFromFilter = updateFromFilter;

        vm.count_selected_items = count_selected_items;
        vm.toggle_selected_items = toggle_selected_items;

        vm.filters = {};

        vm.avail_fields = [
            'internal_item_number',
            'component',
            'item_description',
            //'vendor',
            'supplier',
            //'vendor_item_number',
            'client_item_number',
            'qty_required',
            'unit_name',
            'scrap_factor',
            //'notes',
            //'updated',
            //'created',
            //'created_by'
        ];
        vm.fields = vm.avail_fields;

        angular.extend(vm.filters, vm.modelFilters);

        $scope.$watchCollection(function () { return vm.filters; }, function (value) {
            updateFromFilter();
        });

        function updateFromFilter() {
            var modelPromise;

            vm.loading = true;

            modelPromise = vm.bomItemsList.getList(vm.filters);
            modelPromise.then(function () {
                vm.loading = false;
            });
        }

        function count_selected_items() {
            var count = 0;
            vm.bomItemsList.list.forEach(function(item) {
                if(item.selected) { count++; }
            });
            return count;
        }

        function toggle_selected_items() {
            if(count_selected_items() == 0) {
                vm.bomItemsList.list.forEach(function(item) {
                    item.selected = true;
                });
                return true;
            }

            vm.bomItemsList.list.forEach(function(item) {
                item.selected = false;
            });
            return false;

        }

        function isEditable() {
            if (vm.finalized && !vm.auth.user.permissions.bom_can_edit_finalized_boms) { return false; }
            return true;
        }

        function editObj(obj) {
            if (!isEditable()) {
                alert('BOM is finalized. Must duplicate or make new BOM.');
                return false;
            }
            SlateBOMItemEditSrv.edit(obj).then(function() {
                updateFromFilter();
            });
        }

        function deleteObj(obj) {
            if (!isEditable()) {
                alert('BOM is finalized. Must duplicate or make new BOM.');
                return false;
            }

            if(!confirm('Are you sure you want to delete this line item?')) {
                return false;
            }

            obj.deleteModel().then(function() {
                updateFromFilter();
            });
        }

        function newObj(obj) {
            var newbom = new SlateBOMItem();
            newbom.bom = vm.modelFilters.bom;
            SlateBOMItemEditSrv.edit(newbom).then(function () {
                updateFromFilter();
            });
        }

        function addComps() {
            SlateBOMAddCompsSrv.edit(vm.modelFilters.bom).then(function() {
                updateFromFilter();
            });
        }

        function newRfq(bom, bomitem) {
            var rfq = new SlateRFQ();
            var bomObj = new SlateBOM();
            var project = new SlateProject();
            var promises = [];
            bomObj.loadFromServer(vm.modelFilters.bom).then(function() {
                promises.push(project.loadFromServer(bomObj.finished_good).then(function() {
                    if(project.breadcrumbs && project.breadcrumbs.length > 1) {
                        rfq.project = project.breadcrumbs[1].parent_fk;
                        rfq.client = project.breadcrumbs[0].parent_fk;
                    }
                }));
                rfq.name = bomitem.item_description;
                rfq.notes = bomitem.notes;

                rfq.component = bomitem.component;

                $q.all(promises).then(function() {
                    rfq.saveModel().then(function() {
                        bomitem.rfq = rfq.id;
                        bomitem.saveModel().then(function() {
                            $location.path('/rfqs/'+ rfq.id + '/edit/');
                        });

                    });
                });

            });


        }

    }


})();
