(function () {
    'use strict';

    angular
        .module('slate.library.auth')
        .service('SlateAuth', AuthService);


    function AuthService($rootScope, $window, $cookies, $q, $interval, $location, $http, SlateUser) {
        const srv = {
            loadToken,
            newLogin,
            logout,
            generatePassword,
            resetPassword,
            windowFocus: true,

            promise: null,
            user: null,
            perms: {},
            token: null
        };

        $rootScope.$on('auth_loggedout', () => {
            deleteToken();
        });


        angular.element($window).bind('focus', () => {
            srv.windowFocus = true;
        }).bind('blur', () => {
            srv.windowFocus = false;
        });

        srv.tokenCheck = $interval(() => {
            if (document.hidden) { return false; }
            if (!srv.user) { return false; }

            srv.promise = srv.user.checkToken(true);
            return true;
        }, 300000);

        return srv;


        function generatePassword() {
            const apiUrl = `${API_URL}auth/genpassword/`;
            return $http.get(apiUrl).then((response) => response.data.payload);
        }


        function loadToken() {
            const user = new SlateUser();

            if (!$window.localStorage.slateToken) {
                return $q.reject({});
            }
            const token = $window.localStorage.slateToken;


            const promise = user.getUserFromToken(token);
            srv.promise = promise;

            promise.then(() => {
                srv.user = user;
                srv.perms = user.permissions;
                srv.token = user.token;
                saveToken();
            }, () => {
                deleteToken();

                if (!sessionStorage.getItem('next_url') && !$location.url().startsWith('/login')) {
                    sessionStorage.setItem('next_url', $location.url());
                }
                $location.url('/login/');
            });

            return promise;
        }

        function saveToken() {
            $window.localStorage.slateToken = srv.token;
            $http.defaults.headers.common.Authorization = srv.token;
            $cookies.put('Authorization', srv.token);

            if (FRONTEND_SENTRY_DSN && window.Sentry) {
                Sentry.setUser({
                    email: srv.user.email
                });
            }
            return srv;
        }

        function deleteToken() {
            srv.user = null;
            srv.perms = {};
            srv.token = null;

            delete $window.localStorage.slateToken;
            delete $http.defaults.headers.common.Authorization;
            $cookies.remove('Authorization');
            return srv;
        }

        function logout() {
            deleteToken();

            sessionStorage.removeItem('next_url');
            $location.url('/login/');

            return srv;
        }

        function newLogin(email, password, remember, type) {
            /* jshint validthis: true */
            const user = new SlateUser();
            let promise = null;

            user.clear();
            promise = user.getToken(email, password, remember, type).then(() => {
                srv.user = user;
                srv.perms = user.permissions;
                srv.token = srv.user.token;
                saveToken();
            }, (response) => {
                deleteToken();
                return $q.reject(response.data);
            });

            return promise;
        }

        function resetPassword() {
            const apiUrl = `${API_URL}auth/forgot-password/email=${srv.contact_email}`;

            return $http.get(apiUrl).then((response) => response.data.payload);
        }
    }
})();
