(function () {
    "use strict";

    angular
        .module('slate.bom')
        .directive('slateBomItemRowEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/bom/bomitem.row.edit.dir.html',
            scope: {
                bomitem: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $timeout, $q, SlateBOMItems, SlateBOMItem, SlateProject) {
        /* jshint validthis: true */
        var vm = this;


        vm.compVendors = [];

        vm.units = [
            'ea',
            'box',
            'bottle',
            'g',
            'kg',
            'oz',
            'liter',
            'awesomeness',
        ];

        function projectSync() {
            var project = new SlateProject();
            project.loadFromServer(vm.bomitem.component).then(function () {

                if (!vm.bomitem.internal_item_number) { vm.bomitem.internal_item_number = project.composite_num; }
                if (!vm.bomitem.vendor_item_number) { vm.bomitem.vendor_item_number = project.vendor_item_number; }
                if (!vm.bomitem.client_item_number) { vm.bomitem.client_item_number = project.client_item_num; }
                if (!vm.bomitem.notes) { vm.bomitem.notes = project.brief; }
                if (!vm.bomitem.item_description) { vm.bomitem.item_description = project.name; }

            });

        }
        $scope.$watch('vm.bomitem.component', function () {
            if (vm.bomitem.component) {
                projectSync();
            }

        });
        $scope.$watch('vm.component', function() {
            if(vm.component) {
                vm.compVendors = getComponentVendors();
                vendorSync();
            }
        });
        $scope.$watch('vm.vendor', function() {
            vendorSync();
        });

        function vendorSync() {
            if(!vm.component || !vm.vendor) { return false; }
            angular.forEach(vm.component.vendor_item_nums, function(item) {
                if(item.vendor_id == vm.vendor.id) {
                    if(!vm.bomitem.scrap_factor) { vm.bomitem.scrap_factor = item.scrap_factor; }
                    if(!vm.bomitem.vendor_item_number) { vm.bomitem.vendor_item_number = item.item_num; }
                }
            });
        }

        function getComponentVendors() {
            var ids = [];
            if(!vm.component) { return false; }
            angular.forEach(vm.component.vendor_item_nums, function(item) {
                ids.push(item.vendor_id);
            });
            return ids;
        }
    }

})();
