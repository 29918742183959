(function () {
    'use strict';

    angular
        .module('slate.logistics')
        .directive('slateShipmentEdit', modelList)
        .directive('slateShipmentGoodsList', goodsModelList)
        .directive('slateShipmentStatusLogList', statusLogModelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/logistics/shipment.edit.dir.html',
            scope: {
                shipment: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function ListController($rootScope, $scope, $timeout, $state, $window, $location, $q, $http, $translate, $interval, getLinkSrv, SlateApproval, SlateAuth, SlateAccountPrdSrv, SlateProject, SlateRolesSrv, SlateAssignedRoles, SlateGood, SlateGoods, SlateStatusLogs, SlateShipmentWatchers, SlateShipmentQtyEdit, SlateShipmentConfirmQty, SlateFileNode, SlateFileNodeList, SlateDateChange, SlateDateChangeSrv, SlateDateLogList, SlateApprovalEdit, SlateBOM, SlateBOMItems) {
        /* jshint validthis: true */
        const vm = this;
        vm.isLogVisible = false;
        vm.status_log = null;

        vm.save = save;
        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;

        vm.dateChangeSrv = SlateDateChangeSrv;
        vm.dateLog = SlateDateLogList;
        vm.checkClosedDates = checkClosedDates;

        vm.accountingPeriods = SlateAccountPrdSrv;
        vm.countries = Countries;

        vm.isComplete = isComplete;
        vm.canFullEdit = canFullEdit;
        vm.toggleChangeLog = toggleChangeLog;
        vm.isReadonly = isReadonly;
        vm.hasSufficientStock = hasSufficientStock;
        vm.bom_items_instock = true;

        vm.bom_items = {};
        vm.bom_locks = {};

        vm.getWarehouse = getWarehouse;

        vm.addGood = addGood;

        vm.genShipmentRefDoc = genShipmentRefDoc;
        vm.genCommercialInvoice = genCommercialInvoice;
        vm.genPackingList = genPackingList;
        vm.uploadFiles = uploadFiles;

        vm.generateBillings = generateBillings;

        vm.getLocation = getLocation;
        vm.geolocate = geolocate;

        vm.combineShipments = combineShipments;
        vm.adjustqty = adjustqty;

        vm.toggleChangeLog = toggleChangeLog;

        vm.watch = watch;
        vm.unWatch = unWatch;

        vm.avail_tabs = ['Discussions', 'Related Shipments', 'Diagnostics'];
        [vm.current_tab] = vm.avail_tabs;

        vm.ship_types = ['oceanlcl',
            'oceanfcl',
            'oceanexp',
            'air',
            'truck',
            'ups',
            'fedex',
            'dhl',
            'other'];
        vm.incoterms = ['exw',
            'fca',
            'fob',
            'cif',
            'cpt',
            'cip',
            'dat',
            'dap',
            'ddp',
            'ddu',
            'other'];

        vm.baseStatus = vm.shipment.status;
        vm.baseStatusOptions = ['new',
            'quoting',
            'quoted',
            'quote_appr',
            'schedule_req',
            'schedule_up',
            'pending',
            'intransit',
            'customs',
            'port',
            'delivered',
            'exceptionhold',
            'cancelled'];
        vm.statusOptions = vm.baseStatusOptions;
        vm.statusAvailable = [vm.baseStatus, 'exceptionhold', 'cancelled'];
        checkAvailStatus();

        vm.shipment.datelog_notes = {};
        vm.dateChangeSrv.watch(vm.shipment, ['eta', 'etd']);

        function checkAvailStatus() {
            let newlist = [vm.baseStatus, 'exceptionhold', 'cancelled'];
            if (vm.shipment.freight_handler === 'customer') {
                vm.statusOptions = ['new',
                    'pending',
                    'delivered',
                    'exceptionhold',
                    'cancelled'];
            } else if (vm.shipment.freight_handler === 'vendor') {
                vm.statusOptions = ['new',
                    'pending',
                    'intransit',
                    'customs',
                    'port',
                    'delivered',
                    'exceptionhold',
                    'cancelled'];
            } else {
                vm.statusOptions = vm.baseStatusOptions;
            }

            if (vm.auth.perms.logistics_can_override_statusXX) {
                vm.statusAvailable = _.cloneDeep(vm.statusOptions);
                return vm.statusAvailable;
            }
            if (vm.shipment.freight_handler !== 'customer') {
                if (vm.baseStatus === 'new') { newlist = newlist.concat(['quoting']); }
                if (vm.baseStatus === 'new' && vm.shipment.freight_handler === 'vendor') {
                    newlist = newlist.concat(['pending']);
                }
                if (vm.baseStatus === 'quoting') {
                    newlist = newlist.concat(['quoted']);
                    if (vm.shipment.cost_quote) {
                        if (['fedex', 'dhl', 'ups'].includes(vm.shipment.shipment_type)) { newlist = newlist.concat(['pending', 'intransit']); }
                    }
                }
                if (vm.baseStatus === 'quoted') {
                    newlist = newlist.concat(['quoting']);
                    if (vm.shipment.cost_quote) {
                        newlist = newlist.concat(['quote_appr']);
                        if (['fedex', 'dhl', 'ups'].includes(vm.shipment.shipment_type)) { newlist = newlist.concat(['pending', 'intransit']); }
                    }
                }
                if (vm.baseStatus === 'quote_appr') { newlist = newlist.concat(['schedule_req']); }
                if (vm.baseStatus === 'schedule_req') { newlist = newlist.concat(['schedule_up']); }
                if (vm.baseStatus === 'schedule_up') { newlist = newlist.concat(['pending', 'schedule_req']); }
                if (vm.baseStatus === 'pending') { newlist = newlist.concat(['intransit']); }
                if (vm.baseStatus === 'intransit') { newlist = [vm.baseStatus, 'port', 'customs', 'delivered']; }
                if (vm.baseStatus === 'customs') { newlist = ['intransit', 'port', vm.baseStatus, 'delivered']; }
                if (vm.baseStatus === 'port') { newlist = ['intransit', 'customs', vm.baseStatus, 'delivered']; }
                if (vm.baseStatus === 'delivered') { newlist = [vm.baseStatus]; }
            }

            if (vm.shipment.freight_handler === 'customer') {
                if (!['pending', 'delivered'].includes(vm.baseStatus)) { newlist = newlist.concat(['pending']); }
                if (vm.baseStatus === 'pending') { newlist = newlist.concat(['delivered']); }
                if (vm.baseStatus === 'delivered') { newlist = [vm.baseStatus]; }
            }

            if (!vm.hasSufficientStock()) {
                newlist = newlist.filter((item) => {
                    if (item === vm.baseStatus) { return true; }
                    if (['intransit', 'customs', 'port', 'delivered'].includes(item)) { return false; }
                    return true;
                });
            }

            vm.statusAvailable = newlist;
            if (SlateAuth.user.is_admin) {
                ['pending', 'intransit', 'delivered'].forEach((item) => {
                    if (!vm.statusAvailable.includes(item)) {
                        vm.statusAvailable.push(item);
                    }
                });
            }
            return vm.statusAvailable;
        }
        $scope.$watch('vm.shipment.freight_handler', () => { checkAvailStatus(); });

        $window.addEventListener('dragover', (e) => {
            e.preventDefault();
        }, false);
        $window.addEventListener('drop', (e) => {
            e.preventDefault();
        }, false);

        vm.statuslogs = new SlateStatusLogs();
        vm.watchers = new SlateShipmentWatchers();

        $scope.$watch('vm.shipment.id', () => {
            if (vm.shipment.id) {
                if (vm.shipment.goods.length < 1) {
                    addGood();
                }

                vm.watchers.isWatched(vm.shipment.id)
                    .then((response) => {
                        if (response === true) {
                            vm.shipment.watched = true;
                        }
                    });

                vm.statuslogs.getList({
                    shipment: vm.shipment.id
                });

                vm.dateLog.parent_fk = vm.shipment.id;
                vm.dateLog.parent_type = 'shipment';

                vm.checkClosedDates();
            } else {
                addGood();
            }
        });

        vm.isEtdClosed = false;
        vm.isEtaClosed = false;

        function checkClosedDates() {
            if (!['intransit', 'customs', 'port', 'delivered'].includes(vm.baseStatus)) {
                vm.isEtdClosed = vm.accountingPeriods.isClosed(vm.shipment.etd);
            } else {
                vm.isEtdClosed = false;
            }

            if (!['delivered'].includes(vm.baseStatus)) {
                vm.isEtaClosed = vm.accountingPeriods.isClosed(vm.shipment.eta);
            } else {
                vm.isEtaClosed = false;
            }
        }
        $scope.$watch('vm.shipment.etd', () => { vm.checkClosedDates(); });
        $scope.$watch('vm.shipment.eta', () => { vm.checkClosedDates(); });

        function isReadonly() {
            const vendor_is_origin = vm.shipment.goods.some((good) => {
                if (!good.purchaseorder_info) { return false; }
                return good.purchaseorder_info.vendor && good.purchaseorder_info.vendor === vm.shipment.origin_from;
            });
            if (vm.shipment.origin_type === 'purchaseorder' && vendor_is_origin) { return true; }
            return false;
        }

        $scope.$watchCollection(() => vm.shipment.goods.map((item) => item.isInstock), () => { checkAvailStatus(); });

        function hasSufficientStock() {
            // po type shipments will receive when moving from pending to intransit
            isBOMInStock();

            if (vm.shipment.origin_type === 'purchaseorder' && vm.shipment.status === 'pending' && vm.bom_items_instock) { return true; }

            let missing_goods = false;
            missing_goods = vm.shipment.goods.some((good) => {
                if (good.component_info.category === 'Finished Good' && good.bom) {
                    // reverse logic fron bom stock
                    return !vm.bom_items_instock && (good.isInstock < good.qty);
                }
                // return true if instock isn't even set, to avoid comparing undefined with a number
                // return true if the instock amount is insufficient
                if (!good.isInstock) return true;
                return good.isInstock < good.qty;
            });


            return !missing_goods;
        }

        $scope.$watchCollection('vm.bom_items', () => {
            // loop over keys. if any are false, set vm.bom_items_instock to false
            let bom_items_instock = true;
            Object.values(vm.bom_items).forEach((item) => {
                if (!item) { bom_items_instock = false; }
                return item;
            });
            vm.bom_items_instock = bom_items_instock;
            return checkAvailStatus();
        });
        function isBOMInStock() {
            const promises = [];
            vm.shipment.goods.forEach((good) => {
                if (!good.bom || good.component_info.category !== 'Finished Good') { return false; }
                const bomitems = new SlateBOMItems();
                const missing_items = false;
                const warehouse = getWarehouse(good);

                if (vm.bom_locks.hasOwnProperty(good.bom)) { return false; }
                vm.bom_locks[good.bom] = true;

                const promise = bomitems.getList({ bom: good.bom }).then(() => {
                    bomitems.list.forEach((bom_item) => {
                        if (typeof (vm.bom_items[bom_item.id]) !== 'undefined') { return false; }
                        bom_item.checkBOMInStock(warehouse, good.qty).then((result) => {
                            vm.bom_items[bom_item.id] = result;
                        });
                        return true;
                    });
                });
                promises.push(promise);

                return !missing_items;
            });
            return $q.all(promises);
        }


        function save() {
            if (vm.saving) { return false; }
            if (!isComplete()) { return false; }
            vm.saving = true;
            if (['quote_appr', 'schedule_req'].includes(vm.shipment.status) && vm.shipment.eta && vm.shipment.etd) {
                vm.shipment.status = 'schedule_up';
            }
            return SlateShipmentConfirmQty.edit(vm.shipment, vm.baseStatus).then(() => vm.shipment.saveModel().then(() => {
                const promises = [];
                const parent_fk = vm.shipment.id;
                const parent_type = 'shipment';
                promises.push(vm.fileSrv.uploadFiles(parent_type, parent_fk));
                return $q.all(promises).then(() => {
                    $timeout(() => {
                        vm.saving = false;
                        vm.baseStatus = vm.shipment.status;
                        checkAvailStatus();
                        $state.go('shipmentEditPage', { id: vm.shipment.id }, { location: 'replace', reload: true });
                        // window.history.back();
                    }, 300);
                });
            }, () => {
                vm.saving = false;
                vm.error = true;
            }), () => {
                vm.saving = false;
            });
        }

        vm.popConfirmationDialog = popConfirmationDialog;
        function popConfirmationDialog() {
            SlateShipmentConfirmQty.edit(vm.shipment, vm.baseStatus, true);
        }

        function watch() {
            // vm.shipment.watched = true;
            vm.watchers.watch(vm.shipment.id).then(() => { vm.shipment.watched = true; });
        }

        function unWatch() {
            // vm.shipment.watched = false;
            vm.watchers.unWatch(vm.shipment.id).then(() => { vm.shipment.watched = false; });
        }

        /* -------------------------- Approval Section -------------------------- */

        vm.isApprover = false;
        vm.approve = approve;
        vm.reject = reject;
        vm.onApproval = onApproval;
        vm.onReject = onReject;
        vm.editCycle = editCycle;
        vm.approval = new SlateApproval();

        vm.approval.loadFromServer(vm.parentType, vm.parentFk, vm.rootType, vm.rootFk).then(() => {
            if (vm.approval.steps.current && SlateAuth.user.contact_id === vm.approval.steps.current.approver) {
                vm.isApprover = true;
            }
        });

        function editCycle() {
            vm.isApprover = false;
            vm.shownotes = false;

            SlateApprovalEdit.edit(vm.approval, vm.companyFk, vm.rootType, vm.rootFk).then(() => {
                vm.approval.loadFromServer(vm.parentType, vm.parentFk, vm.rootType, vm.rootFk).then(() => {
                    if (vm.approval.steps.current && SlateAuth.user.contact_id === vm.approval.steps.current.approver) {
                        vm.isApprover = true;
                        $rootScope.$broadcast('approvalUpdated', { parentType: vm.parentType, parentFk: vm.parentFk });
                    }
                });
            });
        }

        function onApproval() {
            if (vm.saving) {
                return false;
            }
            vm.saving = true;
            vm.shipment.status = 'pending';
            return vm.shipment.saveModel().then(() => {
                vm.saving = false;
            }, () => {
                vm.saving = false;
                vm.error = true;
            });
        }

        function onReject() {
            if (vm.saving || vm.shipment.status !== 'schedule_up') {
                return false;
            }
            vm.saving = true;
            vm.shipment.status = 'schedule_req';
            return vm.shipment.saveModel().then(() => {
                vm.saving = false;
            }, () => {
                vm.saving = false;
                vm.error = true;
            });
        }

        function approve() {
            vm.isApprover = false;
            vm.shownotes = false;
            vm.approval.steps.current.approve().then(() => {
                $rootScope.$broadcast('approvalUpdated', { parentType: vm.parentType, parentFk: vm.parentFk });
                vm.approval.steps.getList().then(() => {
                    vm.approval.steps.updateCurrent();
                });
            });
        }

        function reject() {
            vm.isApprover = false;
            vm.shownotes = false;
            vm.approval.steps.current.reject().then(() => {
                $rootScope.$broadcast('approvalUpdated', { parentType: vm.parentType, parentFk: vm.parentFk });
                vm.approval.steps.getList().then(() => {
                    vm.approval.steps.updateCurrent();
                });
            });
        }

        const timer = $interval(() => {
            vm.approval.loadFromServer(vm.parentType, vm.parentFk, vm.rootType, vm.rootFk).then(() => {
                if (vm.approval.steps.current && SlateAuth.user.contact_id === vm.approval.steps.current.approver) {
                    vm.isApprover = true;
                }
            });
        }, 30000);

        $scope.$on('$destroy', () => {
            if (timer) {
                $interval.cancel(timer);
            }
        });

        /* -------------------------- End of Approval Section -------------------------- */


        function getWarehouse(good) {
            if (vm.shipment.freight_handler === 'customer') { return good.origin_warehouse; }
            if (vm.shipment.freight_handler === 'vendor') { return good.origin_warehouse; }
            if (['intransit', 'customs', 'port', 'delivered'].includes(vm.baseStatus)) { return vm.shipment.warehouse; }
            if (vm.shipment.origin_type === 'stock') { return good.origin_warehouse; }
            return null;
        }

        vm.failed_reasons = '';
        function isComplete() {
            if (vm.shipment.freight_handler === 'customer') {
                return isCompleteCustomer();
            }
            if (vm.shipment.freight_handler === 'vendor') {
                return isCompleteVendor();
            }
            return isCompleteJansy();
        }

        function isCompleteCustomer() {
            const reasons = [];
            if (!vm.shipment.name) { reasons.push('Shipment has no Name'); }

            if (!vm.shipment.shipment_type) { reasons.push('Shipment Type not set'); }
            if (!vm.shipment.factory_incoterms) { reasons.push('IncoTerms not set'); }
            // if(!vm.shipment.delivery_to) { reasons.push('Delivery company must be set'); }
            // if (!vm.shipment.final_destination) { reasons.push('Delivery address must be set'); }

            const now = new Date().getTime();
            if (vm.shipment.etd && vm.shipment.status === 'intransit' && now < vm.shipment.etd.getTime()) {
                reasons.push('intransit shipments cannot have etd in the future');
            }
            if (vm.shipment.eta && vm.shipment.freight_handler !== 'customer' && vm.shipment.status === 'delivered' && now < vm.shipment.eta.getTime()) {
                reasons.push('delivered shipments can not have eta in the future');
            }
            if (vm.shipment.status === 'intransit' && !vm.shipment.etd) {
                reasons.push('intransit shipments need an etd');
            }
            if (vm.shipment.status === 'delivered' && !vm.shipment.etd) {
                reasons.push('delivered shipments need an etd');
            }

            if (vm.shipment.goods.some((good) => !good.origin_from || !good.origin_warehouse)) { reasons.push('Goods are missing pickup origin'); }

            vm.failed_reasons = reasons;
            if (reasons.length) { return false; }

            return true;
        }

        function isCompleteVendor() {
            const reasons = [];
            if (!vm.shipment.name) { reasons.push('Shipment has no Name'); }

            if (!vm.shipment.shipment_type) { reasons.push('Shipment Type not set'); }
            if (!vm.shipment.factory_incoterms) { reasons.push('IncoTerms not set'); }
            if (!vm.shipment.freight_handler) { reasons.push('Freight Handler not set'); }
            if (!vm.shipment.delivery_to) { reasons.push('Delivery company must be set'); }
            // if (!vm.shipment.final_destination) { reasons.push('Delivery address must be set'); }
            if (!vm.shipment.final_destination_street) { reasons.push('Delivery address must be set'); }
            if (!vm.shipment.final_destination_city) { reasons.push('Delivery address must be set'); }
            if (!vm.shipment.final_destination_state) { reasons.push('Delivery address must be set'); }
            if (!vm.shipment.final_destination_country) { reasons.push('Delivery address must be set'); }


            const now = new Date().getTime();
            if (vm.shipment.etd && vm.shipment.status === 'intransit' && now < vm.shipment.etd.getTime()) {
                reasons.push('intransit shipments cannot have etd in the future');
            }
            if (vm.shipment.eta && vm.shipment.status === 'delivered' && now < vm.shipment.eta.getTime()) {
                reasons.push('delivered shipments can not have eta in the future');
            }
            if (vm.shipment.status === 'intransit' && !vm.shipment.etd) {
                reasons.push('intransit shipments need an etd');
            }
            if (vm.shipment.status === 'delivered' && (!vm.shipment.eta || !vm.shipment.etd)) {
                reasons.push('delivered shipments need eta or etd');
            }
            if (vm.shipment.goods.some((good) => !good.origin_from || !good.origin_warehouse)) { reasons.push('Goods are missing pickup origin'); }

            if (vm.isEtdClosed && !vm.auth.user.is_admin) {
                reasons.push('Departure date is in a closed accounting period');
            }
            if (vm.isEtaClosed && !vm.auth.user.is_admin) {
                reasons.push('Arrival date is in a closed accounting period');
            }

            vm.failed_reasons = reasons;
            if (reasons.length) { return false; }

            return true;
        }

        function isCompleteJansy() {
            const reasons = [];
            if (!vm.shipment.name) { reasons.push('Shipment has no Name'); }

            if (!vm.shipment.warehouse) { reasons.push('Freight handler is not customer or vendor and is missing warehouse'); }
            if (!vm.shipment.shipment_type) { reasons.push('Shipment Type not set'); }
            if (!vm.shipment.factory_incoterms) { reasons.push('IncoTerms not set'); }
            if (!vm.shipment.freight_handler) { reasons.push('Freight Handler not set'); }

            // cost and quoting is not valid for customer and vendor
            if (Number.isNaN(vm.shipment.cost_quote)) { reasons.push('Cost Quote is not a number'); }
            if (vm.shipment.status === 'quote_appr' && vm.shipment.cost_quote === 0) { reasons.push('Quote for approval but has not cost quote'); }

            const now = new Date().getTime();
            if (vm.shipment.etd && vm.shipment.ready_date && vm.shipment.etd.getTime() < vm.shipment.ready_date.getTime()) {
                reasons.push('etd can not be before exworks date');
            }
            if (vm.shipment.etd && vm.shipment.status === 'intransit' && now < vm.shipment.etd.getTime()) {
                reasons.push('intransit shipments cannot have etd in the future');
            }
            if (vm.shipment.eta && vm.shipment.status === 'delivered' && now < vm.shipment.eta.getTime()) {
                reasons.push('delivered shipments can not have eta in the future');
            }
            if (vm.shipment.status === 'intransit' && !vm.shipment.etd) {
                reasons.push('intransit shipments need an etd');
            }
            if (vm.shipment.status === 'intransit' && !vm.shipment.house_bol) {
                reasons.push('intransit shipments need a BOL or Tracking #');
            }
            if (vm.shipment.status === 'delivered' && (!vm.shipment.eta || !vm.shipment.etd)) {
                reasons.push('delivered shipments need eta or etd');
            }
            if (vm.shipment.status === 'schedule_up' && ((!vm.shipment.cut_off_date && !['ups', 'fedex', 'dhl'].includes(vm.shipment.shipment_type)) || !vm.shipment.eta || !vm.shipment.etd)) {
                reasons.push('Schedule updates should have a cutoff date, eta, etd');
            }
            if (vm.shipment.warehouse && vm.shipment.origin_warehouse && vm.shipment.warehouse === vm.shipment.origin_warehouse) {
                reasons.push('vendor warehouse and intransit warehouse can not be the same');
            }

            if (vm.shipment.shipment_type !== 'truck') {
                if (vm.shipment.etd && vm.shipment.date_to_port && vm.shipment.date_to_port.getTime() < vm.shipment.etd.getTime()) {
                    reasons.push('Date to port can not be before etd');
                }
                if (vm.shipment.eta && vm.shipment.date_to_port && vm.shipment.eta.getTime() < vm.shipment.date_to_port.getTime()) {
                    reasons.push('eta can not be less than date to port');
                }
                if (vm.shipment.status === 'schedule_up' && !vm.shipment.date_to_port && !['ups', 'fedex', 'dhl'].includes(vm.shipment.shipment_type)) {
                    reasons.push('Schedule updates should have a date to port');
                }
            }
            if (vm.shipment.goods.some((good) => !good.origin_from || !good.origin_warehouse)) { reasons.push('Goods are missing pickup origin'); }

            if (vm.shipment.freight_handler !== 'vendor' && vm.shouldBeVendor()) {
                reasons.push('Some items pickup warehouse matches the shipment intransit location. This might be a vendor shipment, or the wrong intransit warehouse.');
            }

            if (vm.isEtdClosed && !vm.auth.user.is_admin) {
                reasons.push('Departure date is in a closed accounting period');
            }
            if (vm.isEtaClosed && !vm.auth.user.is_admin) {
                reasons.push('Arrival date is in a closed accounting period');
            }

            if (vm.shipment.delivery_type === 'customer') {
                if (!vm.shipment.final_destination_street) { reasons.push('Delivery address must be set'); }
                if (!vm.shipment.final_destination_city) { reasons.push('Delivery address must be set'); }
                if (!vm.shipment.final_destination_state) { reasons.push('Delivery address must be set'); }
                if (!vm.shipment.final_destination_country) { reasons.push('Delivery address must be set'); }
            }


            vm.failed_reasons = reasons;
            if (reasons.length) { return false; }

            return true;
        }

        function canFullEdit() {
            return true;
        }

        function toggleChangeLog() {
            vm.isLogVisible = !vm.isLogVisible;
        }

        function projectSync() {
            const assroles = new SlateAssignedRoles();
            const project = new SlateProject();
            const role_dict = SlateRolesSrv.dict;
            assroles.getList({
                parent_type: 'project',
                parent_fk: vm.shipment.project
            }).then(() => {
                angular.forEach(assroles.list, (item) => {
                    if (item.member_type === 'contact' && role_dict[item.roles[0]].name === 'Project Manager' && !vm.shipment.proj_mgr) {
                        vm.shipment.proj_mgr = item.member_fk;
                    }
                    if (item.member_type === 'contact' && role_dict[item.roles[0]].name === 'Sales Manager' && !vm.shipment.sales_mgr) {
                        vm.shipment.sales_mgr = item.member_fk;
                    }
                });
            });
            if (!vm.shipment.client) {
                project.loadFromServer(vm.shipment.project).then(() => {
                    if (project.parent_type === 'company') {
                        vm.shipment.client = project.parent_fk;
                    }
                });
            }
        }
        $scope.$watch('vm.shipment.project', () => {
            if (vm.shipment.project) {
                projectSync();
            }
        });

        function genShipmentRefDoc() {
            const url = `${API_URL}logistics/genrefdoc/`;

            if (vm.woSaving) { return false; }
            if (!vm.shipment.id) { return false; }
            // if(vm.specsDirty || vm.files.length || vm.POSpecFrom.$dirty) { return ''; }

            vm.woSaving = true;
            vm.woError = false;

            return $http.get(url, { params: { shipment: vm.shipment.id } }).then(() => {
                vm.woSaving = false;
                $scope.$broadcast('updateFiles', 'shipment', vm.shipment.id);
            }, () => {
                vm.woSaving = false;
                vm.woError = true;
            });
        }

        function genPackingList() {
            const url = `${API_URL}logistics/genpackinglist/`;

            if (vm.plSaving) { return false; }
            if (!vm.shipment.id) { return false; }

            vm.plSaving = true;
            vm.plError = false;

            return $http.get(url, { params: { shipment: vm.shipment.id, exclude_vendor: vm.excludeVendorInfo } }).then(() => {
                vm.plSaving = false;
                $scope.$broadcast('updateFiles', 'shipment', vm.shipment.id);
            }, () => {
                vm.plSaving = false;
                vm.plError = true;
            });
        }

        function genCommercialInvoice() {
            const url = `${API_URL}logistics/gencomminvoice/`;

            if (vm.ciSaving) { return false; }
            if (!vm.shipment.id) { return false; }

            vm.ciSaving = true;
            vm.ciError = false;

            return $http.get(url, { params: { shipment: vm.shipment.id, exclude_vendor: vm.excludeVendorInfo } }).then(() => {
                vm.ciSaving = false;
                $scope.$broadcast('updateFiles', 'shipment', vm.shipment.id);
            }, () => {
                vm.ciSaving = false;
                vm.ciError = true;
            });
        }

        vm.sessionToken = uuidv4();

        function getLocation(q) {
            const deferred = $q.defer();

            // var url = "/geolocate/";
            const url = `${API_URL}utils/geocomplete/`;
            const params = {
                sessiontoken: vm.sessionToken,
                lang: $translate.use(),
                address: q
            };

            if (q.length < 5) {
                deferred.reject();
                return deferred.promise;
            }

            $http.get(url, {
                params
            }).then((response) => {
                const locations = [];

                angular.forEach(response.data.predictions, (item) => {
                    const name = item.description;

                    locations.push({ name, details: item });
                });
                deferred.resolve(locations);
            });

            return deferred.promise;
        }

        function geolocate(address, variable, addy_prefix) {
            const url = `${API_URL}utils/geolocate/`;
            const params = {
                lang: $translate.use(),
                address
            };

            if (address.length < 5) {
                vm.shipment[variable] = { lat: 0, lng: 0 };
                return false;
            }

            const promise = $http.get(url, {
                params
            }).then((response) => {
                let results = [];
                vm.shipment[variable] = { lat: 0, lng: 0 };

                results = response.data.results;
                if (results && results.length) {
                    vm.shipment[variable].lat = results[0].geometry.location.lat;
                    vm.shipment[variable].lng = results[0].geometry.location.lng;
                }

                const comps = results[0].address_components;
                let street_num = comps.find((item) => item.types.includes('street_number'));
                street_num = street_num ? street_num.short_name : '';
                let street_name = comps.find((item) => item.types.includes('route'));
                street_name = street_name ? street_name.short_name : 'Unkown St.';
                const city = comps.find((item) => item.types.includes('locality'));
                const state = comps.find((item) => item.types.includes('administrative_area_level_1'));
                const postal = comps.find((item) => item.types.includes('postal_code'));
                const country = comps.find((item) => item.types.includes('country'));

                console.log(street_num, street_name, city, state, postal, country);

                vm.shipment[`${addy_prefix}_street`] = [street_num, street_name].join(' ');
                vm.shipment[`${addy_prefix}_city`] = city ? city.short_name : 'Unkown City.';
                vm.shipment[`${addy_prefix}_state`] = state ? state.short_name : '';
                vm.shipment[`${addy_prefix}_postal`] = postal ? postal.long_name : '';
                vm.shipment[`${addy_prefix}_country`] = country ? country.long_name : 'United States';
            });

            return promise;
        }

        $scope.$watch('vm.shipment.client', () => {
            angular.forEach(vm.shipment.goods, (good) => {
                if (!good.client) {
                    good.client = vm.shipment.client;
                }
            });
        });

        function addGood() {
            const good = new SlateGood();
            good.client = vm.shipment.client;
            vm.shipment.goods.push(good);
            // reOrderComps();
        }

        function combineShipments() {
            let url = `${API_URL}logistics/shipment/`;

            if (vm.combine_into_shipment.length !== 1) { return false; }
            if (!vm.auth.perms.logistics_can_combine) { return false; }
            if (!window.confirm(`Are you sure you want to move these goods to shipment #${vm.combine_into_shipment[0].shipment_number}`)) {
                return false;
            }

            vm.combining = true;
            vm.combineError = false;
            url = `${url + vm.shipment.id}/combine/`;

            return $http.post(url, { target_shipment: vm.combine_into_shipment[0].id }).then(() => {
                $timeout(() => {
                    $state.go('shipmentEditPage', { id: vm.combine_into_shipment[0].id }, { location: 'replace', reload: true });
                }, 300);
            }, () => {
                vm.combining = false;
                vm.combineError = true;
            });
        }

        function adjustqty() {
            SlateShipmentQtyEdit.edit(vm.shipment).then(() => {
                $state.go('shipmentEditPage', { id: vm.shipment.id }, { location: 'replace', reload: true });
            });
        }

        vm.shouldBeVendor = shouldBeVendor;
        function shouldBeVendor() {
            return vm.shipment.goods.some((good) => good.origin_warehouse === vm.shipment.warehouse);
        }

        function uploadFiles() {
            vm.uploadingFiles = true;
            vm.fileSrv.uploadFiles('shipment', vm.shipment.id).then(() => {
                vm.uploadingFiles = false;
            }, () => {
                vm.uploadingFiles = false;
            });
        }

        function generateBillings() {
            vm.generatingBillings = true;
            vm.shipment.createBillings().then(() => {
                vm.generatingBillings = false;
            });
        }
    }

    function goodsModelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/logistics/goods.list.dir.html',
            scope: {
                shipment: '='
            },
            controller: GoodsListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function GoodsListController($scope, SlateAuth, SlateProjects, SlateGoodEditSrv, SlateGoodPOGroupEditSrv, getLinkSrv) {
        /* jshint validthis: true */
        const vm = this;
        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;
        vm.getPOs = getPOs;
        vm.editGood = editGood;
        vm.editPOGroup = editPOGroup;
        vm.poMissingOrigin = poMissingOrigin;
        vm.getStyle = getStyle;
        vm.baseStatus = vm.shipment.status;

        if (!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'photo',
                'item_num',
                'name',
                'category',
                'material',
                'ready_date',
                'num_cartons',
                'cbm',
                'total_weight',
                'qty',
                'unit',
                'approvals'
            ];
            /* beautify preserve:end */
            if (['pending', 'intransit', 'customs', 'port'].includes(vm.shipment.status) || (vm.shipment.origin_type === 'stock' && !['intransit',
                'customs',
                'port',
                'delivered',
                'exceptionhold',
                'cancelled'].includes(vm.shipment.status))) {
                vm.fields.splice(vm.fields.indexOf('qty'), 0, 'instock');
            }
        }

        function editGood(good, $idx) {
            SlateGoodEditSrv.edit(good, { shipment: vm.shipment }).then((newGood) => {
                vm.dirty = true;
                vm.shipment.goods[$idx] = newGood;
            }, () => { });
        }

        function editPOGroup(po_id) {
            const fields = ['client',
                'project',
                'order',
                'purchaseorder',
                'proj_mgr',
                'sales_mgr',
                'ready_date',
                'origin_from',
                'origin_warehouse'];
            const data = {};
            fields.forEach((field) => {
                let field_contents;
                let field_mismatch = false;
                vm.shipment.goods.forEach((item) => {
                    if (item.purchaseorder !== po_id) { return false; }
                    if (field_mismatch) { return false; }
                    if (!field_contents) { field_contents = item[field]; }
                    if (field_contents !== item[field] && !(item[field] instanceof Date)) { // must encapsilate instanceof
                        field_mismatch = true;
                    } else if (item[field] instanceof Date && field_contents.getTime() !== item[field].getTime()) {
                        field_mismatch = true;
                    }
                    return true;
                });
                if (field_contents && !field_mismatch) {
                    data[field] = field_contents;
                }
            });

            SlateGoodPOGroupEditSrv.edit(data).then((results) => {
                Object.keys(results).forEach((key) => {
                    if (results[key] === null) {
                        delete (results[key]);
                    }
                });
                vm.shipment.goods.forEach((good) => {
                    if (good.purchaseorder !== po_id) { return false; }
                    Object.assign(good, results);
                    return true;
                });
            });
        }

        function getStyle(field) {
            if (field === 'name') {
                return { width: '100%' };
            }

            if (field === 'photo') {
                return { width: '48px', height: '48px' };
            }

            return {
                overflow: 'hidden',
                'white-space': 'nowrap',
                'text-overflow': 'ellipsis'
            };
        }

        function getPOs() {
            const po_ids = [];
            const pos = [];
            vm.shipment.goods.forEach((item) => {
                if (!po_ids.includes(item.purchaseorder)) {
                    po_ids.push(item.purchaseorder);
                    pos.push(item.purchaseorder_info);
                }
            });
            return pos;
        }

        function poMissingOrigin(po_id) {
            return vm.shipment.goods.some((good) => {
                if (good.purchaseorder !== po_id) { return false; }
                if (!good.origin_from || !good.origin_warehouse) { return true; }

                return false;
            });
        }

        vm.getWarehouse = getWarehouse;
        function getWarehouse(good) {
            if (vm.shipment.freight_handler === 'customer') { return good.origin_warehouse; }
            if (vm.shipment.freight_handler === 'vendor') { return good.origin_warehouse; }
            if (['intransit', 'customs', 'port', 'delivered'].includes(vm.baseStatus)) { return vm.shipment.warehouse; }
            if (vm.shipment.origin_type === 'stock') { return good.origin_warehouse; }
            return null;
        }
    }

    function statusLogModelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/logistics/shipment.statuslog.list.html',
            scope: {
                logs: '='
            },
            controller: StatusLogListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function StatusLogListController() {
        /* jshint validthis: true */
        // eslint-disable-next-line no-unused-vars
        const vm = this;
    }
})();
