(function() {
    "use strict";

    angular
        .module('slate.logistics')
        .service('SlateGoodPOGroupEditSrv', editDialog);

    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateGood) {
        var templateUrl = '/partials/logistics/good_po_group.edit.sidebar.html';

        var srv = {
            edit: editObj
        };

        return srv;


        function editObj(item, options) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj: function () {
                        var newitem = {};
                        if(!item) {
                            return newitem;
                        }
                        return _.cloneDeep(item);
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateGood, obj, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;

        vm.auth = SlateAuth;
        vm.item = obj;
        vm.saving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.isComplete = isComplete;

        function saveObject(skip) {
            $uibModalInstance.close(vm.item);
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }

        function isComplete() {
            if(!vm.item.origin_from) { return false; }
            if(!vm.item.origin_warehouse) { return false; }

            return true;
        }
    }
})();