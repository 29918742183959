(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportInventoryHistory', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.inventory_history.dir.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $q, $http, $location, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'reports/inventory_history/';
        vm.getLink = getLinkSrv;

        var modelPromise;

        vm.updateFromFilter = updateFromFilter;

        vm.data = [];
        vm.filters = {};
        vm.loading = true;
        vm.canceller = null;

        vm.filters = angular.extend(vm.filters, {
            component: $location.search().component,
        });

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function() {
            updateFromFilter();
        });

        function updateFromFilter() {
            vm.loading = true;

            if(vm.canceller) { vm.canceller.resolve(); }
            vm.canceller = $q.defer();

            if(!vm.filters.component) {
                vm.data = [];
                vm.loading = false;
                return false;
            }

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
                timeout: vm.canceller.promise
            });

            modelPromise.then(function(resp) {
                vm.data = resp.data.payload;
                vm.loading = false;
                vm.canceller = null;
            });
        }

        
    }
})();