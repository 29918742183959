(function() {
    "use strict";

    angular
        .module('slate.logistics')
        .service('SlateGoodEditSrv', editDialog);

    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateGood) {
        var templateUrl = '/partials/logistics/good.edit.sidebar.html';

        var srv = {
            edit: editObj
        };

        return srv;


        function editObj(item, options) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj: function () {
                        var newitem = new SlateGood();
                        if(!item) {
                            return newitem;
                        }
                        if(typeof item == 'string') {
                            return newitem.loadFromServer(item);
                        }
                        return newitem.loadFromPayload(item);
                    },
                    shipment: function() {
                        if(typeof options === 'object' && options.shipment) {
                            return options.shipment;
                        }
                        return null;
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateGood, obj, shipment, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;

        vm.auth = SlateAuth;
        vm.item = obj;
        vm.shipment = shipment;
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.checkNumSign = checkNumSign;
        vm.calcTotalCBMs = calcTotalCBMs;

        vm.isComplete = isComplete;

        vm.units = [
            'ea',
            'box',
            'bottle',
            'g',
            'kg',
            'oz',
            'liter'
        ];

        vm.origItem =  new SlateGood();
        vm.origItem.loadFromPayload(vm.item);

        function calcCBMs() {
            if ([
                vm.item.length,
                vm.item.width,
                vm.item.height
            ].some(function(item) { return item === 0.0; })) { return new Big(0); }

            var l = new Big(vm.item.length);
            var w = new Big(vm.item.width);
            var h = new Big(vm.item.height);

            var cbmPerCarton = l.times(w).times(h).div(1000000);

            return cbmPerCarton;
        }

        function calcTotalCBMs() {
            if ([
                vm.item.qty,
                vm.item.qty_per_carton,
            ].some(function(item) { return item === 0.0; })) { return new Big(0); }

            var cbmPerCarton = calcCBMs();

            var qtyToShip = new Big(vm.item.qty);
            var qtyPerCarton = new Big(vm.item.qty_per_carton);
            var cartons = qtyToShip.div(qtyPerCarton);

            var totalCBMs = cartons.round(0, 3).times(cbmPerCarton);

            vm.item.cbm = totalCBMs.toString();
        }

        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
        };

        function saveObject(skip) {
            $uibModalInstance.close(vm.item);
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }

        function isComplete() {
            if(vm.item.qty < 1) { return false; }
            if(vm.item.qty_per_carton < 1) { return false; }
            return true;
        }

        function checkNumSign(good, field) {
            if (good[field] < 1) {
                good[field] = Math.abs(good[field]);
            }
        }

    }
})();