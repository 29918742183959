(function () {
    'use strict';

    angular
        .module('slate.salesorders')
        .directive('slateSalesOrderShip', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/salesorders/ship.edit.dir.html',
            scope: {
                salesOrder: '=',
                shipment: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController(
        $scope,
        $q,
        $timeout,
        $state,
        $translate,
        $http,
        SlateLocation,
        SlateLocations,
        SlateGood,
        getLinkSrv
    ) {
        /* jshint validthis: true */
        const vm = this;
        vm.getLink = getLinkSrv;

        vm.new_shipment = vm.saveObject = saveObject;
        vm.isComplete = isComplete;
        vm.showShipmentLocation = showShipmentLocation;
        vm.showDeliveryLocation = showDeliveryLocation;
        vm.showOriginLocation = showOriginLocation;
        vm.checkNumSign = checkNumSign;
        vm.calcTotalCBMs = calcTotalCBMs;

        vm.getLocation = getLocation;
        vm.geolocate = geolocate;

        vm.ship_types = ['oceanlcl',
            'oceanfcl',
            'oceanexp',
            'air',
            'truck',
            'ups',
            'fedex',
            'dhl',
            'other'];

        vm.incoterms = {
            jansy: ['exw',
                'fca',
                'fob',
                'cif',
                'cpt',
                'cip',
                'dat',
                'dap',
                'ddp',
                'ddu',
                'other'],
            customer: ['exw', 'fob', 'fca'],
            vendor: ['exw',
                'fca',
                'fob',
                'cif',
                'cpt',
                'cip',
                'dat',
                'dap',
                'ddp',
                'ddu',
                'other']
        };

        vm.shipment_existing = 'New Shipment';

        vm.pickup_info = {
        };

        vm.loadingLocations = {};
        vm.autoselectedLocation = {};

        function saveObject() {
            const deferred = $q.defer();

            if (vm.saving === true) {
                return false;
            }

            vm.saving = true;
            vm.error = false;
            vm.shipment.goods = [];
            vm.salesOrder.line_items.forEach((item) => {
                if (!isNaN(item.qty_to_ship) && item.qty_to_ship) {
                    const ship_item = new SlateGood();

                    ship_item.name = item.name.slice(0, 72);
                    ship_item.item_num = item.finished_good_info.composite_num;
                    ship_item.unit = item.unit_name;
                    ship_item.component = item.finished_good;

                    ship_item.solineitem = item.id;
                    ship_item.salesorder = vm.salesOrder.id;
                    ship_item.client = vm.salesOrder.company;

                    ship_item.project = vm.salesOrder.project;
                    ship_item.order = vm.salesOrder.order;
                    ship_item.sales_mgr = vm.salesOrder.sales_mgr;
                    ship_item.proj_mgr = vm.salesOrder.proj_mgr;
                    ship_item.ready_date = vm.pickup_info.ready_date;
                    ship_item.origin_from = vm.pickup_info.origin_from;
                    ship_item.origin_warehouse = vm.pickup_info.origin_warehouse;

                    ship_item.qty = item.qty_to_ship;
                    ship_item.qty_per_carton = item.qty_per_carton;
                    ship_item.length = item.length;
                    ship_item.width = item.width;
                    ship_item.height = item.height;
                    ship_item.weight_per_unit = item.weight_per_unit;
                    ship_item.cbm = item.cbm_to_ship;
                    ship_item.hts_code = item.hts_to_ship;
                    ship_item.notes = item.ship_comments;

                    item.ship_item = ship_item;

                    vm.shipment.goods.push(ship_item);
                }

                if (item.bom_items) {
                    item.bom_items.list.forEach((bom_item) => {
                        if (isNaN(bom_item.qty_to_ship) || bom_item.qty_to_ship == '0' || !parseFloat(bom_item.qty_to_ship)) {
                            return false;
                        }

                        const ship_item = new SlateGood();

                        ship_item.name = bom_item.component_info.name.slice(0, 72);
                        ship_item.item_num = bom_item.component_info.composite_num;
                        ship_item.unit = bom_item.unit_name;
                        ship_item.component = bom_item.component;

                        ship_item.solineitem = item.id;
                        ship_item.salesorder = vm.salesOrder.id;
                        ship_item.client = vm.salesOrder.company;

                        ship_item.project = vm.salesOrder.project;
                        ship_item.order = vm.salesOrder.order;
                        ship_item.sales_mgr = vm.salesOrder.sales_mgr;
                        ship_item.proj_mgr = vm.salesOrder.proj_mgr;
                        ship_item.ready_date = vm.pickup_info.ready_date;
                        ship_item.origin_from = vm.pickup_info.origin_from;
                        ship_item.origin_warehouse = vm.pickup_info.origin_warehouse;

                        ship_item.qty = bom_item.qty_to_ship;
                        ship_item.qty_per_carton = bom_item.qty_per_carton;
                        ship_item.length = bom_item.length;
                        ship_item.width = bom_item.width;
                        ship_item.height = bom_item.height;
                        ship_item.weight_per_unit = bom_item.weight_per_unit;
                        ship_item.cbm = bom_item.cbm_to_ship;
                        ship_item.hts_code = bom_item.hts_to_ship;
                        ship_item.notes = bom_item.ship_comments;

                        bom_item.ship_item = ship_item;

                        vm.shipment.goods.push(ship_item);
                    });
                }
            });

            if (vm.shipment.freight_handler == 'customer' && !vm.shipment.delivery_type) {
                vm.shipment.delivery_type = 'customer';
            }

            vm.shipment.saveModel().then(() => {
                const promises = [];

                if (vm.fileSrv) {
                    promises.push(vm.fileSrv.uploadFiles('shipment', vm.shipment.id));
                }

                return $q.all(promises).then(() => {
                    $timeout(() => {
                        vm.saving = false;
                        deferred.resolve();
                        if ($state.current.name != 'shipmentEditPage') {
                            $state.transitionTo(
                                'shipmentEditPage',
                                { id: vm.shipment.id },
                                { location: 'replace' }
                            );
                        }
                    }, 300);
                }, () => {
                    vm.saving = false;
                    vm.error = true;
                });
            }, () => {
                vm.saving = false;
                vm.error = true;
            });

            return deferred.promise;
        }

        function isComplete() {
            if (!vm.shipment.freight_handler) { return false; }
            if (!vm.pickup_info.ready_date) { return false; }
            if (!vm.shipment.factory_incoterms) { return false; }
            if (vm.shipment.freight_handler !== 'customer') {
                if (vm.shipment.freight_handler != 'vendor') {
                    if (!vm.shipment.warehouse) { return false; }
                    if (!vm.shipment.forwarder) { return false; }
                }
                if (!vm.shipment.shipment_type) { return false; }
                if (!vm.shipment.origin) { return false; }
                if (!vm.shipment.delivery_to) { return false; }
                if (vm.shipment.delivery_type != 'customer' && !vm.shipment.delivery_warehouse) { return false; }
                if (!vm.shipment.final_destination) { return false; }
            }

            if (!vm.salesOrder.line_items.some((item) => {
                if (!isNaN(item.qty_to_ship) && item.qty_to_ship > 0) { return true; }
                if (item.bom_items.list.some((item) => {
                    if (!isNaN(item.qty_to_ship) && item.qty_to_ship > 0) { return true; }
                    return false;
                })) { return true; }
                return false;
            })) { return false; }

            if (!vm.salesOrder.line_items.every((item) => {
                if (item.qty_to_ship && item.qty_to_ship > item.qty_instock) { return false; }
                return true;
            })) { return false; }

            return true;
        }

        $scope.$watch('vm.shipment.freight_handler', (old_val, new_val) => {
            if (vm.shipment.freight_handler == 'customer' && !vm.shipment.delivery_to) {
                vm.shipment.delivery_to = vm.salesOrder.company;
            }

            if (vm.shipment.freight_handler == 'vendor') {
                vm.shipment.delivery_type = 'customer';
            }
        });

        $scope.$watch('vm.shipment.delivery_type', (old_val, new_val) => {
            if (vm.shipment.delivery_type == 'customer' && !vm.shipment.delivery_to) {
                vm.shipment.delivery_to = vm.salesOrder.company;
            }
        });


        function showDeliveryLocation() {
            if (vm.shipment.freight_handler == 'customer') { return false; }
            if (!vm.shipment.delivery_to) { return false; }
            if (vm.loadingLocations.delivery_warehouse) { return false; }
            if (vm.autoselectedLocation.delivery_warehouse) { return false; }
            if (vm.shipment.delivery_type == 'customer') { return false; }
            return true;
        }

        function showOriginLocation() {
            if (!vm.pickup_info.origin_from) { return false; }
            if (vm.loadingLocations.origin_warehouse) { return false; }
            if (vm.autoselectedLocation.origin_warehouse) { return false; }

            return true;
        }

        function showShipmentLocation() {
            if (!vm.shipment.forwarder) { return false; }
            if (!vm.shipment.shipment_type) { return false; }
            if (vm.loadingLocations.warehouse) { return false; }
            if (vm.autoselectedLocation.warehouse) { return false; }

            return true;
        }

        $scope.$watch('vm.shipment.delivery_to', (old_val, new_val) => {
            if (vm.shipment.delivery_to) { checkLocation('delivery_to', 'customer', 'delivery_warehouse', 'final_destination'); }
        });

        $scope.$watch('vm.pickup_info.origin_from', (old_val, new_val) => {
            if (vm.pickup_info.origin_from) { checkLocation('origin_from', 'factory', 'origin_warehouse', 'origin'); }
        });

        $scope.$watch('vm.shipment.delivery_warehouse', (old_val, new_val) => {
            if (vm.shipment.delivery_warehouse) { updateAddress(vm.shipment.delivery_warehouse, 'final_destination'); }
        });

        $scope.$watch('vm.pickup_info.origin_warehouse', (old_val, new_val) => {
            if (vm.pickup_info.origin_warehouse) { updateAddress(vm.pickup_info.origin_warehouse, 'origin'); }
        });

        $scope.$watch('vm.shipment.forwarder', (old_val, new_val) => {
            if (vm.shipment.forwarder) { checkLocation('forwarder', vm.shipment.shipment_type, 'warehouse'); }
        });

        $scope.$watch('vm.shipment.shipment_type', (old_val, new_val) => {
            if (vm.shipment.forwarder) { checkLocation('forwarder', vm.shipment.shipment_type, 'warehouse'); }
        });

        function updateAddress(warehouse_id, address_field) {
            const location = new SlateLocation();
            location.loadFromServer(warehouse_id).then(() => {
                if (address_field) {
                    vm.shipment[address_field] = '';
                    ['street1',
                        'street2',
                        'city',
                        'state',
                        'postal',
                        'country'].forEach((fieldname) => {
                            if (location[fieldname]) {
                                if (vm.shipment[address_field].length > 0) { vm.shipment[address_field] += ', '; }
                                vm.shipment[address_field] += location[fieldname];
                            }
                        });
                    geolocate(vm.shipment[address_field], `${address_field}_gps`);
                }
            });
        }

        function checkLocation(company_field, type_field, warehouse_field, address_field) {
            const locations = new SlateLocations();
            const filters = { company: vm.shipment[company_field] };

            if (!vm.shipment[company_field]) { return false; }
            console.log('getting list');
            if (type_field && type_field.length) {
                console.log('adding warehosue type', type_field);
                filters.warehouse_type = type_field;
            }

            vm.loadingLocations[warehouse_field] = true;

            locations.getList(filters).then(() => {
                if (locations.list.length != 1) {
                    vm.loadingLocations[warehouse_field] = false;
                    vm.autoselectedLocation[warehouse_field] = false;
                    return false;
                }
                vm.shipment[warehouse_field] = locations.list[0].id;
                vm.loadingLocations[warehouse_field] = false;
                vm.autoselectedLocation[warehouse_field] = true;
            });
        }

        function checkNumSign(lineItem, field) {
            if (lineItem[field] < 0) {
                lineItem[field] = Math.abs(lineItem[field]);
            }
        }

        function calcCBMs(lineItem) {
            if ([
                lineItem.length, lineItem.width, lineItem.height
            ].some((item) => item == 0.0 || typeof item === 'undefined')) { return new Big(0); }

            const l = new Big(lineItem.length);
            const w = new Big(lineItem.width);
            const h = new Big(lineItem.height);

            const cbmPerCarton = l.times(w).times(h).div(1000000);

            return cbmPerCarton;
        }

        function calcTotalCBMs(lineItem) {
            if ([
                lineItem.qty_to_ship, lineItem.qty_per_carton
            ].some((item) => item === 0.0 || typeof item === 'undefined')) { return new Big(0); }

            const cbmPerCarton = calcCBMs(lineItem);

            const qtyToShip = new Big(lineItem.qty_to_ship);
            const qtyPerCarton = new Big(lineItem.qty_per_carton);
            const cartons = qtyToShip.div(qtyPerCarton);

            const totalCBMs = cartons.round(0, 3).times(cbmPerCarton);

            lineItem.cbm_to_ship = totalCBMs;
        }

        /** ***** Mapping stuff  ********** */
        vm.sessionToken = uuidv4();

        function getLocation(q) {
            let promise;
            const deferred = $q.defer();

            // var url = "/geolocate/";
            const url = `${API_URL}utils/geocomplete/`;
            const params = {
                sessiontoken: vm.sessionToken,
                lang: $translate.use(),
                address: q
            };

            if (q.length < 5) {
                deferred.reject();
                return deferred.promise;
            }

            promise = $http.get(url, {
                params
            }).then((response) => {
                const locations = [];

                angular.forEach(response.data.predictions, (item) => {
                    const name = item.description;

                    locations.push({ name, details: item });
                });
                deferred.resolve(locations);
            });

            return deferred.promise;
        }

        function geolocate(address, variable) {
            let promise;
            const url = `${API_URL}utils/geolocate/`;
            const params = {
                lang: $translate.use(),
                address
            };

            if (address.length < 5) {
                vm.shipment[variable] = { lat: 0, lng: 0 };
                return false;
            }

            promise = $http.get(url, {
                params
            }).then((response) => {
                let results = [];
                vm.shipment[variable] = { lat: 0, lng: 0 };
                console.log('results ', response.data.results);
                results = response.data.results;
                if (results && results.length) {
                    vm.shipment[variable].lat = results[0].geometry.location.lat;
                    vm.shipment[variable].lng = results[0].geometry.location.lng;
                }
            });

            return promise;
        }
    }
})();
