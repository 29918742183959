(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .service('SlateRFQLineItemEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateRFQLineItem) {
        var templateUrl = '/partials/rfqs/rfq.lineitem.edit.sidebar.html';

        var srv = {
            edit: editObj
        };

        return srv;


        function editObj(item, readonly, parentProject) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj: function () {
                        var newitem = new SlateRFQLineItem();
                        if(!item) {
                            return newitem;
                        }
                        if(typeof item == 'string') {
                            return newitem.loadFromServer(item);
                        }
                        return newitem.loadFromPayload(item);
                    },
                    parentProject: function() {
                        return parentProject;
                    },
                    readonly: function() {
                        if(readonly) { return true; }
                        return false;
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $timeout, $uibModalInstance,  obj, readonly, parentProject, SlateSettings, SlateProject) {
        /* jshint validthis: true */
        var vm = this;

        vm.readonly = readonly;
        vm.parentProject = parentProject;
        vm.item = obj;
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.isComplete = isComplete;

        vm.isNumber = angular.isNumber;
        vm.add_split = add_split;
        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            //zIndex: 2501,
        };

        vm.units = [
            'ea',
            'box',
            'bottle',
            'g',
            'kg',
            'oz',
            'liter'
        ];

        vm.cleaveConfig = {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            numeralPositiveOnly: true,
            rawValueTrimPrefix: true
        };


        function saveObject(skip) {
            vm.item.splits.sort(function compareNumbers(a, b) { return parseFloat(a) - parseFloat(b); });
            vm.item.options = vm.item.options.filter(function(item, idx) {
                if(typeof(item) != 'string') { return false; }
                if(!item.length) { return false; }
                if(item == '') { return false; }
                return true;
             });
            $uibModalInstance.close(vm.item);
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }

        function isComplete() {
            if(!vm.item.qty) { return false; }
            if(!vm.item.splits.length) { return false; }
            if(vm.item.options.some(function(item) { return item.length > 72; })) { return false; }

            return true;
        }

        SlateSettings.getConfig('material_categories').then(function(config) {
            vm.categories = config;
        });

        SlateSettings.getConfig('materials').then(function(config) {
            vm.material_list = config;
            getMatList();
        });
        vm.materials = [];

        $scope.$watch('vm.item.category', function() {
            getMatList();
        });

        function getMatList() {
            var newlist = [];
            if(!vm.material_list || ! vm.material_list.value) { return []; }
            angular.forEach(vm.material_list.value, function(item) {
                if(item.category == vm.item.category) {
                    newlist.push(item);
                }
            });
            vm.materials = newlist;
            return newlist;
        }

        function projectSync() {
            var project = new SlateProject();
            project.loadFromServer(vm.item.component).then(function () {
                vm.item.specs = project.brief;
                vm.item.category = project.category;
                vm.item.material = project.material;
                vm.item.unit_name = project.unit_name;
            });
        }
        $scope.$watch('vm.item.component', function (newVal, oldVal) {
            if (vm.item.component && newVal != oldVal) {
                projectSync();
            }

        });

        function add_split(qty) {
            var els;
            if(isNaN(qty)) { qty = 0; }
            vm.item.splits.forEach(function(item) { console.log(item); });
            if(!vm.item.splits.some(function(item) { return [qty, '' + qty + ''].includes(item); })) {
                vm.item.splits.push('' + qty + '');
                $timeout(function() {
                    els = document.querySelectorAll("#rfq_item_splits input");
                    els[els.length-1].focus();
                }, 100);
            } else {
                console.log('item value of zero');
            }
        }

    }



})();
