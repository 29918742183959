(function () {
    'use strict';

    angular
        .module('slate.library.profilephotos')
        .factory('SlateProfilePhoto', ModelDetail);


    function ModelDetail($http, $q, Upload) {
        // Set to url relative to api root.
        const apiUrl = `${API_URL}profilephotos/`;

        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [

            { name: 'id', def: null, readonly: true },
            { name: 'parent', def: null, readonly: true },
            { name: 'parent_type', def: null },
            { name: 'parent_id', def: null },
            { name: 'parent_slug', def: null, readonly: true },
            { name: 'filename', def: null },
            { name: 'original', def: null },
            { name: 'lg_size', def: '/img/placeholders/project.png' },
            { name: 'sm_size', def: '/img/placeholders/project.png' },
            { name: 'icon_size', def: '/img/placeholders/project.png' },
            { name: 'thumb', def: '/img/placeholders/project.png' },
            { name: 'base_url', def: null, readonly: true },
            { name: 'created', def: null, readonly: true },
            { name: 'progress', def: 0, readonly: true }
        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                self[field.name] = payload[field.name];
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(type, id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            const modelUrl = apiUrl;

            const filter = {};

            if (!self.id && !id) {
                return $q.reject(self);
            }

            filter.parent_type = type;
            filter.parent_fk = id;

            const promise = $http.get(modelUrl, {
                params: filter
            });

            promise.then((response) => {
                const promises = [];

                return self.loadFromPayload(response.data).then(() => {
                    self.permissions = response.data.permissions;

                    return $q.when(promises).then(() => {
                        deferred.resolve(self);
                    });
                });
            });

            return deferred.promise;
        }

        function saveModel(file) {
            /* jshint validthis: true */
            const self = this;
            const modelUrl = apiUrl;

            const data = {};
            data.parent_type = self.parent_type;
            data.parent_fk = self.parent_id;
            data.file = file;

            const promise = Upload.upload({
                url: modelUrl,
                data
            }).then((resp) => {
                self.progress = 100;
            }, (resp) => {

            }, (evt) => {
                self.progress = parseInt(100.0 * (evt.loaded / evt.total), 10);
            });

            return promise;
        }
    }
})();
