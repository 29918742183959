(function() {
    "use strict";

    angular
        .module('slate.auditlog')
        .directive('slateAuditLog', AuditLogList);


    function AuditLogList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/auditlogs/auditlogs.list.dir.html',
            controller: auditLogController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function auditLogController($stateParams, $http) {
        /* jshint validthis: true */
        var vm = this;

        vm.parent_model = $stateParams.parent_model;
        vm.getChanges = getChanges;
        vm.getField = getField;

        var apiUrl = API_URL + 'auditlog/' + vm.parent_model + '/' + $stateParams.id + '/';
        vm.data = [];
        vm.loading = true;

        $http.get(apiUrl).then(function(resp) {
            var logEntries = resp.data.payload;
            logEntries.forEach(function(entry) {
                try {
                    entry.changed = JSON.parse(entry.changed);
                } catch(error) {
                    console.log(error);
                    entry.changed = {};
                }
                try {
                    entry.data = JSON.parse(entry.data);
                } catch(error) {
                    console.log(error);
                    entry.data = {};
                }

            });
            if (logEntries.length) { vm.parent = logEntries[0].parent; }
            vm.data = logEntries;
            vm.loading = false;
        }, function(e) {
            vm.loading = false;
        });

        vm.fields = {
            'purchaseorder': 'PO',
            'salesorder': 'SO',
            'order': 'ORDERS',
            'billing': 'BILLINGS',
            'qclog': 'QC_LOGS',
            'qcrequest': 'QC_REQ',
            'shipment': 'LOGISTICS.SHIP',
            'company': 'COMPANY',
            'rfq': 'RFQ',
        };

        function getField(change) {
            var model = vm.fields[vm.parentModel];

            if (Array.isArray(change[1]) && change[1][0] == 'line_items' || change[1][0] == 'goods') {
                if (vm.parentModel == 'billing' || vm.parentModel == 'rfq') {
                    model += '.LINEITEMS';
                } else if (vm.parentModel == 'shipment') {
                    model = 'LOGISTICS.GOODS';
                } else {
                    model += '_LINEITEMS';
                }
            }
            if (vm.parentModel == 'qcrequest') {
                return model + '.' + getChangedField(change[1]).toUpperCase();
            }
            return model + '.FIELDS.' + getChangedField(change[1]);
        }

        function getChanges(logEntry) {
            var changes = [];

            logEntry.changed.forEach(function(change) {
                if (Array.isArray(change[1])) {
                    var idx = change[1][1];
                    if (change[1][0] == 'goods') {
                        changes.push(logEntry.data.goods[idx].name);
                    } else {
                        changes.push(logEntry.data.line_items[idx].name);
                    }
                } else {
                    changes.push(change[1]);
                }
            });

            return changes.join(', ');
        }
    }

})();
