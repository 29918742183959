(function () {
    "use strict";

    angular
        .module('slate.qima')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
            .state('bookingListPage', {
                url: '/bookings/',
                template: "<div class=\"content-pane list-page\"><slate-booking-list></slate-booking-list></div>",
                current_app: 'bookings',
                slateTitle: 'Bookings',
            })
            .state('bookingEditPage', {
                url: '/bookings/booking/:id/edit/',
                templateUrl: '/partials/qima/booking.edit.page.html',
                controller: 'SlateBookingEditPageController',
                controllerAs: 'vm',
                current_app: 'bookings',
                slateTitle: 'Edit Booking - Bookings',
            })

            ;
    }
})();