(function() {
    "use strict";

    angular
        .module('slate.profilephotos')
        .service('SlateProfilePhotoUpload', editDialog);


    function editDialog($rootScope, $q, $uibModal) {
        var templateUrl = '/partials/profilephotos/profilephotos.upload.dialog.html';

        var srv = {
            upload: editObj
        };


        return srv;


        function editObj(parentType, parentId, url) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                resolve: {
                    obj: function() {
                        return {
                            parentType: parentType,
                            parentId: parentId,
                            url: url
                        };
                    },
                }
            });

            return modalInstance.result.then(function(results) {
                return results;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $uibModalInstance, SlateProfilePhoto, SlateProfilePhotoSrv, Upload, obj) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;
        vm.crop = true;
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.nonCropUrl = '';

        vm.photo = new SlateProfilePhoto();
        vm.photo.loadFromServer(obj.parentType, obj.parentId);
        SlateProfilePhotoSrv.getPhoto(obj.parentType, obj.parentId, 'original')
            .then(function(url) {
                vm.original = url;
                vm.nonCropUrl = url;
                if(url.endsWith('.gif') || url.endsWith('.webp')) {
                    vm.crop = false; vm.cantcrop = true;
                } else {
                    vm.cantcrop = false;
                }
            });

        $scope.$watch('vm.picFile', function() {
            if(vm.picFile && vm.picFile.type == 'image/gif') {
                vm.crop = false;
                vm.cantcrop = true;
            } else {
                vm.crop = true;
                vm.cantcrop = false;
            }
            getImage();
        });

        function saveObject() {
            var promise;
            vm.saving = true;

            if(vm.crop || !vm.picFile) {
                promise = vm.photo.saveModel(Upload.dataUrltoBlob(vm.croppedDataUrl));
            } else {
                promise = vm.photo.saveModel(vm.picFile);
            }

            promise.then(function() {
                SlateProfilePhotoSrv.deletePhoto(obj.parentType, obj.parentId);
                vm.saving = false;
                $uibModalInstance.close(vm.photo);
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function getImage() {
            if(!vm.picFile) { return false; }
            vm.nonCropUrl = URL.createObjectURL(vm.picFile);
        }

    }



})();
