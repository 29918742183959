(function() {
    "use strict";

    angular
        .module('slate.filebrowser')
        .directive('slateFileList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/filebrowser/filebrowser.list.dir.html',
            scope: {
                parentType: '=',
                parentFk: '=',
                lineItems: '=',
                path: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $q, $timeout, slateLightbox, getLinkSrv, SlateFileNodeList, SlateFileVerAnnotation) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.nodes = new SlateFileNodeList();
        vm.images = [];
        vm.files = [];

        //local functions
        vm.getUrl = getUrl;
        vm.parseFilesize = parseFilesize;
        vm.get_image_type = get_image_type;
        vm.getDownloadUrl = getDownloadUrl;
        vm.openLightbox = openLightboxModal;

        vm.deleteNode = deleteNode;

        vm.canAnnotate = canAnnotate;
        vm.annotateVer = annotateVer;

        vm.canDelete = canDelete;

        var initBounch;

//        init();
        $scope.$watch('vm.parentFk', function () {
            if (vm.parentFk && vm.parentType) { init(); }
        });
        $scope.$watch('vm.lineItems', function () {
        if (vm.lineItems.length && vm.parentType) { init(); }
        });

        $scope.$on('uploadComplete', function(event, type, id) {
            init();
        });

        $scope.$on('updateFiles', function(event, type, id) {
            if(vm.parentFk==id && vm.parentType==type) {
                init();
            }
        });

        /* beautify preserve:start */
        var types = {
            'image/png': {class: 'flaticon-png-file-extension-interface-symbol'},
            "image/jpeg": {class: 'flaticon-jpg-image-file-format'},
            'application/postscript': {class: 'flaticon-ai-file-format-symbol', color: '#FF6600'},
            'application/x-apple-diskimage': {class: 'flaticon-dmg-file-format-symbol'},
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {class: 'flaticon-xlsx-file-format', color:"#577758"},
            'application/vnd.ms-excel': {class: 'flaticon-xls-file-format-symbol', color:"#577758"},
            "application/vnd.openxmlformats-officedocument.presentationml.presentation": {class: 'flaticon-pptx-file-format-variant', color:"#cf6a41"},
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {class: 'flaticon-docx-file-format', color:"#517fcb"},
            "application/msword": {class: 'flaticon-doc-file-format-symbol', color:"#517fcb"},
            'application/pdf': {class: 'flaticon-pdf-file-format-symbol', color: '#e81209'},
            'application/zip': {class: 'flaticon-zip-compressed-files-extension', color: '#B29772'},
        };
        /* beautify preserve:end */


        function canAnnotate(mimetype) {
            var annotatable = [
                'application/pdf',
                'image/png',
                "image/jpeg",
            ];
            if (annotatable.indexOf(mimetype) > -1) { return true; }
            return false;
        }

        function annotateVer(node, ver) {
            SlateFileVerAnnotation.edit(node, ver).then(function() {
                init();
            });
        }

        function deleteNode(node) {
            node.deleteModel().then(function() {
                init();
            });
        }

        function get_image_type(mimetype) {
            var results = {
                class: 'flaticon-blank-file',
                color: '#000000'
            };
            if (types[mimetype]) {
                results = angular.extend({}, results, types[mimetype]);
            }
            return results;
        }

        function getUrl(path) {
            var params = $state.params;
            var new_path = "/";
            if (path.startsWith('/')) {
                path = path.substr(1);
            }
            params.path = path;
            new_path = $state.href($state.current.name, params, {
                absolute: true,
            }).replace(/~2F/g, '/').replace(/%252F/g, '/');
            // the regex is due to the persistant encoding of the url, regardless of options set in ui-router
            if (!new_path.endsWith('/')) {
                new_path = new_path + '/';
            }
            return new_path;
        }

        function parseFilesize(size) {
            var unit = "B";
            if(size === null || isNaN(size)) {
                return '';
            }
            if (size > 1024) {
                size = size / 1024;
                unit = "K";
            }
            if (size > 1024) {
                size = size / 1024;
                unit = "M";
            }
            if (size > 1024) {
                size = size / 1024;
                unit = "G";
            }
            return size.toFixed(2).toString() + unit;
        }

        function getDownloadUrl(node, ver) {

            if (!ver) {
                return API_URL + 'files/download/' + node.id + '/';
            }

            return API_URL + 'files/download/' + node.id + '/ver/' + ver + '/';
        }

        function init() {
            if(initBounch) {
                $timeout.cancel(initBounch);
                initBounch = null;
            }
            initBounch = $timeout(initReal, 500);
            initBounch.then(function() { initBounch = null; });
        }

        function getProjectIds() {
            var ids = [];
            var seen = {};
            vm.lineItems.forEach(function(item) {
                if (!seen[item.parent_info.parent_fk]) {
                    seen[item.parent_info.parent_fk] = true;
                    ids.push(item.parent_info.parent_fk);
                }
            });
            return ids;
        }

        function getComponents() {
            var comps = [];
            var seen = {};
            vm.lineItems.forEach(function(item) {
                if (!seen[item.parent_info.id]) {
                    seen[item.parent_info.id] = true;
                    comps.push({composite_num: item.parent_info.composite_num, id: item.parent_info.id});
                }
            });
            return comps;
        }

        function initReal() {
            var modelPromise;
            var truePathParths = [];
            var truePath = '/';
            var listFilters = {
                parent_type: vm.parentType,
                parent_fk: vm.parentFk,
                path: vm.path,
            };

            if(!vm.lineItems.length || !vm.parentType) {
                console.log('trying to load file attachment without parent');
                return $q.reject();
            }

            var promises = [];

            vm.loading = true;

            var projectIds = getProjectIds();
            vm.components = getComponents();
            
            vm.lineItems.forEach(function(item) {
                var artFiles = new SlateFileNodeList();
                var assemblyFiles = new SlateFileNodeList();
                item.files = {};
                promises.push(artFiles.getList({parent_type: vm.parentType, parent_fk: item.po_lineitem_info.component, path: 'design-files'}).then(function(list) {
                    item.files['design-files'] = list;
                }));
            });
            
            vm.components.forEach(function(comp) {
                var techFiles = new SlateFileNodeList();
                comp.files = {};
                promises.push(techFiles.getList({parent_type: vm.parentType, parent_fk: comp.id, path: 'design-files'}).then(function(list) {
                    comp.files['technical-files'] = list;
                }));
            });

            // projectIds.forEach(function(id) {
            //     promises.push(assemblyFiles.getList({parent_type: vm.parentType, parent_fk: }))
            // });

            var promise = $q.all(promises);
            promise.then(function() { vm.loading = false; });
            return promise;
            // modelPromise = vm.nodes.getList(listFilters);
            // modelPromise.then(function() {
            //     var imagesList = [];
            //     angular.forEach(vm.nodes.list, function(node) {
            //         if(node.mimetype.startsWith('image')) {
            //             imagesList.push({
            //                 url: vm.getDownloadUrl(node),
            //                 caption: node.description,
            //             });
            //         }
            //     });
            //     vm.images = imagesList;
            //     vm.loading = false;
            // });
            // return modelPromise;
        }

        function openLightboxModal(index) {
            slateLightbox.openModal(vm.images, index);
        }


        function canDelete(node) {
            var today = new Date();
            var created = new Date(node.created);
            var diffMins, diffMs;
            try {
                if($scope.$root.auth.user && $scope.$root.auth.user.permissions.files_can_delete_attachments) {
                    return true;
                }
            } catch(error) {
                console.log(error);
            }

            diffMs = (today - created);
            diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

            if($scope.$root.auth.user.contact_id == node.core.createdby && diffMins < 720) {
                return true;
            }

            return false;
        }

    }


})();
