(function () {
    'use strict';

    angular
        .module('slate.library.salesorders')
        .factory('SlateSalesOrders', ModelList)
        .factory('SlateSalesOrder', ModelDetail);

    const apiUrl = `${API_URL}salesorders/so/`;

    function ModelList($http, $q, SlateSalesOrder) {
        const list = function () {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            self.pagination = {
                total: 0,
                page: 1,
                count: 25
            };
            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            const promise = $http.get(apiUrl, {
                params: self.filters,
                timeout: self.canceller.promise
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateSalesOrder();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.pagination = {
                    total: parseInt(response.data.pagination.total, 10),
                    page: parseInt(response.data.pagination.page, 10),
                    count: parseInt(response.data.pagination.count, 10)
                };
                self.permissions = response.data.permissions;
                self.canceller = null;

                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q, SlateSOLineItem) {
        // instantiate our initial object
        const model = function () {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', def: null, readonly: true },
            { name: 'sf_id', def: null, readonly: true },
            { name: 'name' },
            { name: 'status', def: 'Draft' },
            { name: 'active_status', def: 'Active', readonly: true },
            { name: 'version', readonly: true },
            { name: 'is_non_inventory', def: false, readonly: true },

            { name: 'company' },
            { name: 'company_info', readonly: true },

            { name: 'project' },
            { name: 'project_info', readonly: true },

            { name: 'projects', type: 'list', def: [] },

            { name: 'order' },
            { name: 'order_info', readonly: true },

            { name: 'proj_mgr' },
            { name: 'proj_mgr_info', readonly: true },
            { name: 'sales_mgr' },
            { name: 'sales_mgr_info', readonly: true },

            { name: 'jansy_so' },
            { name: 'client_po' },
            { name: 'customs_po' },
            { name: 'freight_po' },

            { name: 'date', type: 'dateOnly' },
            { name: 'reorder' },
            { name: 'revision', def: false },
            { name: 'split_shipment', def: false },
            { name: 'cross_order_deferrals', def: false },

            { name: 'customer_did_not_sign', def: false },

            { name: 'shipvia' },
            { name: 'ship_terms' },

            { name: 'ship_street' },
            { name: 'ship_state' },
            { name: 'ship_postal' },
            { name: 'ship_phone' },
            { name: 'ship_country', def: 'United States' },
            { name: 'ship_city' },

            { name: 'terms', readonly: true },
            { name: 'taxable' },

            { name: 'desired_date', type: 'dateOnly' },

            { name: 'frieght_cost' },
            { name: 'price_total', readonly: true, def: 0.0 },
            { name: 'sell_total', readonly: true, def: 0.0 },
            { name: 'margin', readonly: true, def: 0.0 },
            { name: 'bom_item_price_tiers', def: {}, type: 'json' },
            { name: 'bom_margin', readonly: true, def: 0.0 },
            { name: 'bom_cost', readonly: true, def: 0.0 },
            { name: 'bom_margin_variance', def: false },

            { name: 'notes' },
            { name: 'revision_notes' },
            { name: 'split_shipment_notes' },
            { name: 'datelog_notes', type: 'json', def: {} },

            { name: 'line_items', type: 'child_list', model: SlateSOLineItem },

            { name: 'created', readonly: true },
            { name: 'created_by', readonly: true },
            { name: 'updated', readonly: true },
            { name: 'deleted', readonly: true, default: false },

            { name: 'revised', def: [{}], type: 'json' },
            { name: 'has_shipments', def: false, readonly: true }
        ];
        /* beautify preserve:end */

        /* beautify preserve:start */
        model.prototype.shipTypes = ['Ocean',
            'Air',
            'Truck',
            'DHL',
            'UPS',
            'FedEX',
            'USPS',
            'Customer Handled',
            'Other'];
        model.prototype.termsTypes = ['Ex-Works',
            'DDU',
            'FOB',
            'CIF',
            'FCA',
            'DAT',
            'DDP',
            'Other'];
        model.prototype.freightFavorites = [
            { name: 'Freight CO', value: '123 any st, ste 3\nChicago IL, 60610' }, { name: 'Warehouse INC', value: 'attn: Frank\n545 Industry St\nunit 12\nLos Angeles CA, 92111' }
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.cleanupModel = cleanupModel;
        model.prototype.cleanupSFDupes = cleanupSFDupes;
        model.prototype.createBilling = createBilling;

        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;

                if (field.type === 'date' && !self[field.name]) {
                    // self[field.name] = new Date();
                }
                if (field.type === 'child_list') {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                // eslint-disable-next-line no-console
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                if (!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if (field.type === 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type === 'dateOnly' && payload[field.name]) {
                    const parts = self[field.name].split('-');
                    self[field.name] = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
                }


                if (field.type === 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if (field.type === 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                        // handle error
                    }
                }

                if (field.type === 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach((item, idx, array) => {
                            const obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.log('error loading: ', e);
                    }
                }

                return true;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                // eslint-disable-next-line no-console
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            const promise = $http.get(modelUrl);

            promise.then((response) => self.loadFromPayload(response.data.payload).then(() => {
                self.permissions = response.data.permissions;
                deferred.resolve(self);
            }));

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            let modelUrl = apiUrl;

            let promise;
            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (data[field.name] && field.type === 'date') {
                        data[field.name] = self[field.name].toISOString();
                    }
                    if (field.type === 'dateOnly' && self[field.name]) {
                        data[field.name] = `${self[field.name].getFullYear()}-${self[field.name].getMonth() + 1}-${self[field.name].getDate()}`;
                        // data[field.name] = self[field.name].toISOString();
                    }
                    if (field.type === 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if (field.type === 'child_list' && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach((item) => {
                            if (item.toDelete) { return false; }
                            return data[field.name].push(item.getModelData());
                        });
                    }
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then((response) => {
                const promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            const promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function cleanupModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/cleanup/`;
            } else {
                deferred.reject();
                return deferred.promise;
            }

            const promise = $http.post(modelUrl);
            promise.then((response) => {
                deferred.resolve(self);
            }, () => {
                deferred.reject(self);
            });

            return deferred.promise;
        }

        function cleanupSFDupes() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/cleanup_sf_dupes/`;
            } else {
                deferred.reject();
                return deferred.promise;
            }

            const promise = $http.post(modelUrl);
            promise.then((response) => {
                deferred.resolve(self);
            }, () => {
                deferred.reject(self);
            });

            return deferred.promise;
        }

        function createBilling(btype) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/createbilling/`;
            } else {
                deferred.reject();
                return deferred.promise;
            }

            const promise = $http.post(modelUrl, { billing_type: btype || 'Final' });
            promise.then((response) => {
                deferred.resolve(response.data.payload.id);
            }, () => {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
