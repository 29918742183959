(function() {
    "use strict";

    angular
        .module('slate.logistics')
        .controller('SlateShipmentNewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateShipment) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = false;
        vm.shipment = new SlateShipment();
        if($location.search().client) { vm.shipment.client = $location.search().client; }
        if($location.search().company) { vm.shipment.client = $location.search().ccompany; }
        if($location.search().project) { vm.shipment.project = $location.search().project; }
        
        $scope.$watch('vm.logistics.name', function () {
            if (vm.shipment.name) {
                SlateTitle.set('Edit ' + vm.shipment.name + ' - Logistics');
            } else {
                SlateTitle.set('Edit Untitled Shipment - Logistics');
            }
        });

    }

})();


