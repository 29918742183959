(function () {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetTimesofar', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.timesofar.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $interval, SlateAuth, SlateRolesSrv, SlateRequests, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var interval = null;
        var speed = vm.settings.speed || 60000;
        vm.data = {
            'month': {
                'available': 160,
                'hours': 100,
                'spent': 0,
                'expected': 0
            },
            'week': {
                'available': 40,
                'hours': 25,
                'spent': 0,
                'expected': 0
            }
        };

        function getData() {
            $http.get(API_URL + 'dashboard/timesofar/', { params: vm.settings  }  ).then(function (response) {

              vm.data = response.data;
            });

        }

        getData();

        $scope.$watch('vm.settings', function (items) {
            getData();
        }, true);

        interval = $interval(function () {
            getData();
        }, speed);
        $scope.$on('$destroy', function () {
            if (angular.isDefined(interval)) {
                $interval.cancel(interval);
                interval = undefined;
            }
        });
    }


})();
