(function() {
    "use strict";

    angular
        .module('slate.workflows')
        .directive('slateWorkflowListBox', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/workflows/workflows.box.dir.html',
            scope: {
                parentType: '=',
                parentFk: '=',
                templates: '=?',
                showSearch: '=',
                onEdit: '&',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $timeout, $state, $location, $q, getLinkSrv, SlateAuth, SlateWorkflows, SlateWorkflow, SlateWorkflowEdit) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;
        vm.loading = true;
        
        vm.workflows = new SlateWorkflows();

        vm.addNew = addNew;

        vm.dragOptions = {
            handle: ".handle",
            placeholder: "beingDragged",
            stop: sortingStop,
        };

        vm.init = init;
        vm.on_edit = on_edit;

        init();



        function init() {

            var modelPromise;
            var listFilters;
            vm.loading = true;
            if (!vm.templates) {
                listFilters = {
                    parent_fk: vm.parentFk,
                    parent_type: vm.parentType,
                };
                listFilters[vm.parentType] = vm.parentFk;

            } else {
                listFilters = {
                    templates: true,
                };
            }

            modelPromise = vm.workflows.getList(listFilters);
            return modelPromise.then(function() {
                vm.loading = false;
            });

        }

        function on_edit() {
            init();
            //vm.onEdit();
        }

        function addNew() {
            var newWorkflow = new SlateWorkflow();
            newWorkflow[vm.parentType] = vm.parentFk;
            SlateWorkflowEdit.edit(newWorkflow).then(function(workflow) {
                init().then(function() {
                    $timeout(function() {
                        var id = "workflow-"+workflow.id.toString();
                        var element = document.getElementById(id);
                        element.scrollIntoView({block: "end", behavior: "smooth"});
                    }, 50);

                });
            });
        }

        function sortingStop(e, ui) {
            vm.workflows.saveAll();
        }

    }


})();
