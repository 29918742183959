(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .controller('SlateSalesOrderNewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateSalesOrder, SlateOrder) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.salesorder = new SlateSalesOrder();
        
        if($location.search().order) {
            vm.order = new SlateOrder();
            vm.order.loadFromServer($location.search().order).then(function() {
                vm.salesorder.order = vm.order.id;
                vm.salesorder.project = vm.order.project;
                vm.salesorder.sales_mgr = vm.order.sales_mgr;
                vm.salesorder.proj_mgr = vm.order.proj_mgr;
                vm.salesorder.company = vm.order.client;
                vm.salesorder.client_po = vm.order.client_po;
                vm.salesorder.name = vm.order.name.substring(0,68);
                vm.loading = false;
            });
        } else {
            vm.loading = false;
        }
        
        $scope.$watch('vm.salesorder.name', function () {
            if (vm.salesorder.name) {
                SlateTitle.set('Edit ' + vm.salesorder.name + ' - Sales Orders');
            } else {
                SlateTitle.set('Edit Untitled order - Sales Orders');
            }
        });

    }

})();


