(function() {
    "use strict";

    angular
        .module('slate.estimates')
        .directive('slateVendorEstimateResponseSelector', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/estimates/vendor.response.selector.dir.html',
            scope: {
                estimate: '=',
                response: '=?',
                filterVendor: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, SlateVendorEstimateResponses) {
        /* jshint validthis: true */
        var vm = this;

        vm.loading = false;

        vm.responses = new SlateVendorEstimateResponses();


        $scope.$watch('vm.estimate', function (newVal, oldVal) {
            var foundResponse;
            if(typeof vm.estimate === 'string') {
                vm.responses.getList({estimate: vm.estimate}).then(function() {
                    if(vm.filterVendor) {
                        foundResponse = vm.responses.list.find(function(item) {
                            return item.vendor_info.id == vm.filterVendor;
                        });

                        if(foundResponse) { vm.response = foundResponse.id; }
                    }
                });
            } else {
                vm.responses.getList({estimate: vm.estimate.id}).then(function() {
                    if(vm.filterVendor) {
                        foundResponse = vm.responses.list.find(function(item) {
                            return item.vendor_info.id == vm.filterVendor;
                        });

                        if(foundResponse) { vm.response = foundResponse.id; }
                    }
                });
            }
        });

        $scope.$watch('vm.filterVendor', function (newVal, oldVal) {
            var foundResponse;

            if(vm.filterVendor) {
                foundResponse = vm.responses.list.find(function(item) {
                    return item.vendor_info.id == vm.filterVendor;
                });

                if(foundResponse) { vm.response = foundResponse.id; }
            }
        });
    }



})();
