(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .directive('slatePurchaseorderSearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/purchaseorders/purchaseorder.searchbox.dir.html',
            scope: {
                purchaseorderId: '=',
                companyId: '=?',
                orderId: '=?',
                purchaseorderObj: '=?',
                readonly: '<?',
                placeholder: '=?'

            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function directiveController($scope, $state, $location, $translate, $q, getLinkSrv, SlatePurchaseOrders, SlatePurchaseOrder) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;
        var selectedItem = "";

        vm.pos = new SlatePurchaseOrders();
        vm.dropdownItems = new SlatePurchaseOrders();

        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.clear = clear;
        vm.selectItem = selectItem;

        vm.dropdownFilters = {};
        if (vm.companyId) { vm.dropdownFilters.company = vm.companyId; }
        if (vm.orderId) { vm.dropdownFilters.order = vm.orderId; }
        if (vm.companyId || vm.orderId) {
            vm.dropdownItems.getList(vm.dropdownFilters);
        }

        if (vm.purchaseorderId) {
            var lineitem = new SlatePurchaseOrder();
            lineitem.loadFromServer(vm.purchaseorderId).then(function () {
                vm.lineitemObj = lineitem;
                vm.purchaseorderObj = lineitem;
                vm.lineitemName = lineitem.name;
                selectedItem = lineitem.id;
            });
        }
        $scope.$watch('vm.purchaseorderId', function () {
            if (selectedItem != vm.purchaseorderId && vm.purchaseorderId) {
                var lineitem = new SlatePurchaseOrder();
                lineitem.loadFromServer(vm.purchaseorderId).then(function () {
                    vm.lineitemObj = lineitem;
                    vm.purchaseorderObj = lineitem;
                    vm.lineitemName = lineitem.name;
                    selectedItem = lineitem.id;
                });
            }
        });

        function getSearch(q) {
            var filters = { q: q };
            if (vm.companyId) { filters.company = vm.companyId; }
            if (vm.orderId) { filters.order = vm.orderId; }

            if (q && q.length < 1) { return $q.reject([]); }
            return vm.pos.getList(filters).then(function (response) {
                return vm.pos.list;
            });
        }

        function selectSearch($item, $model, $label, $event) {
            vm.searching = false;
            vm.purchaseorderId = $item.id;
            vm.purchaseorderObj = $item;
            selectedItem = $item.id;
            vm.lineitemName = $item.name;
        }

        function clear() {
            if (vm.readonly) { return false; }
            vm.purchaseorderId = null;
            vm.purchaseorderObj = null;
            vm.lineitemObj = null;
        }

        function selectItem($item) {
            vm.searching = false;
            vm.purchaseorderId = $item.id;
            vm.purchaseorderObj = $item;
            vm.lineitemObj = $item;
            vm.lineitemName = $item.name;

        }


    }

})();
