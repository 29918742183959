(function () {
    'use strict';

    angular
        .module('slate.productprofiles')
        .service('SlateProductProfileBulkItemsSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateProductProfile) {
        const templateUrl = '/partials/productprofiles/bulk_items/productprofiles.bulkitems.dialog.html';

        const srv = {
            view: editObj
        };


        return srv;


        function editObj(obj) {
            const modalInstance = $uibModal.open({
                templateUrl,
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj() {
                        const profile = new SlateProductProfile();
                        if (!obj) { return profile; }

                        return profile.loadFromServer(obj.id);
                    }
                }
            });

            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $http, $q, $uibModalInstance, Upload, obj, SlateLocations, SlateCompany, SlateContact, SlateEmployee, SlateContactMethod, SlateProductProfileItem) {
        /* jshint validthis: true */
        const vm = this;

        vm.profile = obj;
        vm.cancel = cancel;
        vm.removeFile = removeFile;
        vm.uploadFiles = uploadFiles;

        vm.isComplete = isComplete;

        vm.state = 'upload';

        vm.verify_idx = 0;
        vm.verify_items = [];

        vm.nextItem = nextItem;
        vm.prevItem = prevItem;
        vm.createItems = createItems;

        vm.get_progress_style = get_progress_style;

        vm.setAddress = setAddress;
        vm.locations = new SlateLocations();
        vm.locations.getList({ mine: true });


        vm.files = [];
        $scope.$watch('vm.filesAdd', () => {
            if (!vm.filesAdd || !Array.isArray(vm.filesAdd)) { return false; }
            vm.filesAdd.forEach((file) => {
                vm.files.push(file);
            });
            return true;
        });

        function get_progress_style(progress) {
            // width: {{ file.progress }}%;
            return {
                width: `${progress}%`
            };
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function removeFile(index) {
            vm.files.splice(index, 1);
        }

        function uploadFiles() {
            const url = `${API_URL}productprofiles/profile/${vm.profile.id}/parse_bulk_uploads/`;
            const promises = [];
            const filepromise = $q.resolve();
            vm.uploading = true;
            vm.error = false;
            vm.savedFiles = [];
            vm.files.forEach((file) => {
                const data = {
                    file,
                    mimetype: file.type
                };

                const promise = Upload.upload({
                    url,
                    data
                }).then((response) => {
                    file.progress = 100;
                    vm.verify_items = vm.verify_items.concat(response.data.payload);
                }, (resp) => {

                }, (evt) => {
                    file.progress = parseInt(100.0 * (evt.loaded / evt.total), 10);
                });

                promises.push(promise);

                // filepromise = filepromise.then(() => newFile.saveModel(file).then(() => {
                //     vm.savedFiles.push(newFile);
                // }));
            });
            promises.push(filepromise);

            $q.all(promises).then(() => {
                vm.verify_items.forEach((item) => {
                    if (item.due_date && typeof item.due_date === 'string') {
                        const parts = item.due_date.split('-');
                        item.due_date = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
                    }
                });

                vm.uploading = false;
                vm.state = 'verify';
            }, () => {
                vm.uploading = false;
                vm.error = true;
            });
        }
        vm.cant_save_reasons = [];
        function isComplete() {
            const cant_save_reasons = [];
            if (vm.verify_items.length < 1) { return false; }
            if (vm.saving) { return false; }
            if (vm.verify_items.some((item) => {
                if (!item.found_brand_id) {
                    cant_save_reasons.push(`${item.name} does not have valid brand.`);
                }
                return !item.found_brand_id;
            })) {
                return false;
            }
            vm.cant_save_reasons = cant_save_reasons;
            return true;
        }

        function createItems() {
            if (vm.saving) { return false; }
            if (vm.verify_items.some((item) => !item.found_brand_id)) { return alert('Some items do not have brands assigned!'); }

            vm.saving = true;
            vm.state = 'save';
            const promises = [];
            vm.messages = [];

            const saved_companies = {};
            const saved_contacts = {};

            vm.verify_items.forEach((item) => {
                const company_defered = $q.defer();
                const contact_defered = $q.defer();

                const item_defered = $q.defer();
                // save brand if needed
                if (item.found_brand_id) {
                    company_defered.resolve();
                }

                // save contact if needed (also employment)
                if (!item.create_new_contact && item.found_contact_id) {
                    contact_defered.resolve();
                } else if (saved_contacts[item.contact_email.toLowerCase()]) {
                    item.found_contact_id = saved_companies[item.contact_email.toLowerCase()];
                } else {
                    vm.messages.push(`Creating brand contact for: ${item.contact_name}`);
                    company_defered.promise.then(() => {
                        const contact = new SlateContact();
                        contact.full_name = item.contact_name;
                        contact.email = item.contact_email;
                        contact.saveModel().then(() => {
                            const contact_promises = [];
                            item.found_contact_id = contact.id;
                            const employment = new SlateEmployee();
                            employment.company = item.found_brand_id;
                            employment.contact = item.found_contact_id;
                            employment.title = 'Product Contact';
                            contact_promises.push(employment.saveModel());

                            const email_method = new SlateContactMethod();
                            email_method.method = 'email';
                            email_method.value = item.contact_email;
                            email_method.parent_fk = contact.id;
                            email_method.parent_type = 'contact';
                            contact_promises.push(email_method.saveModel());

                            const phone_method = new SlateContactMethod();
                            phone_method.method = 'phone';
                            phone_method.value = item.contact_phone ? item.contact_phone : '555-555-5555';
                            phone_method.parent_fk = contact.id;
                            phone_method.parent_type = 'contact';
                            contact_promises.push(phone_method.saveModel());

                            $q.all(contact_promises).then(() => {
                                saved_contacts[item.contact_email.toLowerCase()] = contact.id;
                                contact_defered.resolve();
                            });
                        });
                    });
                }

                // construct and save item
                $q.all([
                    company_defered.promise, contact_defered.promise
                ]).then(() => {
                    vm.messages.push(`Creating item: ${item.name}`);
                    const profile_item = new SlateProductProfileItem();
                    profile_item.name = item.name;
                    profile_item.status = 'ready';
                    profile_item.profile = vm.profile.id;
                    profile_item.brand = item.found_brand_id;
                    profile_item.contact = item.found_contact_id;
                    profile_item.marketing_copy_required = item.marketing_copy_required;
                    profile_item.alternate_copy_required = item.alternate_copy_required;
                    profile_item.alternate_copy_label = item.alternate_copy_label;
                    profile_item.due_date = item.due_date;
                    profile_item.samples_needed = item.samples_needed;
                    profile_item.samples_destination = item.samples_destination;
                    profile_item.notes = item.notes;
                    profile_item.saveModel().then(() => {
                        item_defered.resolve();
                    });
                });
                promises.push(item_defered.promise);
            });

            $q.all(promises).then(() => {
                vm.messages.push('Finishing up');
                setTimeout(() => {
                    vm.saving = false;
                    vm.uploading = false;
                    vm.error = false;
                    $uibModalInstance.close('saved');
                    vm.state = 'complete';
                }, 300);
            }, () => {
                vm.saving = false;
                vm.error = true;
            });

            return null;
        }

        function nextItem() {
            if (vm.verify_idx >= vm.verify_items.length - 1) {
                vm.verify_idx = 0;
                return true;
            }

            const modalDiv = document.getElementsByClassName('modal')[0];
            modalDiv.scrollTo(0, 0);

            vm.verify_idx += 1;
            return true;
        }

        function prevItem() {
            if (vm.verify_idx <= 0) {
                vm.verify_idx = vm.verify_items.length - 1;
                return true;
            }

            const modalDiv = document.getElementsByClassName('modal')[0];
            modalDiv.scrollTo(0, 0);

            vm.verify_idx -= 1;
            return true;
        }

        function setAddress(item, address) {
            let addy = address.street1;
            if (address.street2) { addy += `\n${address.street2}`; }
            if (address.street3) { addy += `\n${address.street3}`; }
            addy += `\n${address.city}, ${address.state} ${address.postal}`;
            addy += `\nattn: ${vm.profile.name}`;

            item.samples_destination = addy;
        }
    }
})();
