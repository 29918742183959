(function () {
    "use strict";

    angular
        .module('slate.companies')
        .service('SlateCartonMarkingDialog', editDialogController);

    function editDialogController($rootScope, $state, $location, $window, $q, $uibModal, SlateAuth, SlateCompany, SlateCartonMarking) {
        var templateUrl = '/partials/companies/cartonmarking.dialog.dir.html';

        var srv = {
            edit: editObj,
        };

        return srv;

        function editObj(companyId, obj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    company: function() {
                        var co = new SlateCompany();
                        return co.loadFromServer(companyId);
                    },
                    obj: function() {
                        var newCartonMarking = new SlateCartonMarking();
                        if (!obj) {
                            return newCartonMarking;
                        }
                        if (typeof obj == 'string') {
                            return newCartonMarking.loadFromServer(obj);
                        }
                        return newCartonMarking.loadFromPayload(obj);
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateAuth, SlateCartonMarking, company, obj) {
        /* jshint validthis: true */
        var vm = this;

        vm.auth = SlateAuth;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.isComplete = isComplete;
        vm.company = company;
        vm.cartonMarking = obj;

        vm.fields = [
            'company',
            'jansy_po',
            'client_po',
            'jansy_pn',
            'client_pn',
            'secondary_client_pn',
            'name',
            'pcs',
            'net_wt',
            'gross_wt',
            'size',
            'box_of',
            'made_in',
            'production_date',
            'custom_cartonmarking_field',
        ];

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
        
        function isComplete() {
            return true;
        }
        
        function saveObject() {
            vm.saving = true;
            vm.error = false;
            vm.cartonMarking.saveModel().then(function() {
                vm.saving = false;
                $uibModalInstance.close();
            }, function() {
                vm.saving = false;
                vm.error = true;
            });
        }

        vm.sortableOptions = {
            connectWith: '.dropzone',
            start: function(e, ui) {
                $scope.$apply('vm.draggingField = true');
            },
            update: function(e, ui) {
                if (!ui.item.sortable.received && ui.item.sortable.source[0] == ui.item.sortable.droptarget[0]) {
                    ui.item.sortable.cancel();
                }
                if (!ui.item.sortable.received && ui.item.sortable.sourceModel != ui.item.sortable.droptargetModel) {
                    var exists = ui.item.sortable.droptargetModel.find(function(field) { return ui.item.sortable.model == field; });
                    if (exists) {
                        ui.item.sortable.cancel();
                    }
                }
            },
            stop: function(e, ui) {
                if (!ui.item.sortable.isCanceled() && ui.item.sortable.received) {
                    ui.item.sortable.sourceModel.splice(ui.item.sortable.index, 0, ui.item.sortable.model);
                }
                $scope.$apply('vm.draggingField = false');
            }
        };
        
        vm.dropzone = {
            connectWith: '.trash',
            start: function(e, ui) {
                $scope.$apply('vm.draggingSelection = true');
            },
            stop: function(e, ui) {
                $scope.$apply('vm.draggingSelection = false');
            }
        };
        
        vm.sortableIcons = {
            helper: 'clone',
            'ui-floating': true,
            connectWith: '.icon-dropzone',
            start: function(e, ui) {
                $scope.$apply('vm.draggingIcon = true');
            },
            update: function(e, ui) {
                if (!ui.item.sortable.received && ui.item.sortable.source[0] == ui.item.sortable.droptarget[0]) {
                    ui.item.sortable.cancel();
                }
                if (!ui.item.sortable.received && !ui.item.sortable.droptarget[0].classList.contains("icon-dropzone")) {
                    ui.item.sortable.cancel();
                }
            },
            stop: function(e, ui) {
                if (ui.item.sortable.received) {
                    ui.item.sortable.sourceModel.splice(ui.item.sortable.index, 0, ui.item.sortable.model);
                }
                $scope.$apply('vm.draggingIcon = false');
            }
        };

        vm.icons = [
            {filename: 'CE.png', title: 'CE'},
            {filename: 'danger.png', title: 'Danger'},
            {filename: 'flammable.png', title: 'Flammable'},
            {filename: 'fragile.png', title: 'Fragile'},
            {filename: 'frozen-product.png', title: 'Frozon Product'},
            {filename: 'handle-with-care.png', title: 'Handle with Care'},
            {filename: 'keep-dry.png', title: 'Keep Dry'},
            {filename: 'package-weight.png', title: 'Heavy'},
            {filename: 'recyclable.png', title: 'Recyclable'},
            {filename: 'recycled-package.png', title: 'Recycled Package'},
            {filename: 'temperature-sensitive.png', title: 'Temperature Sensitive'},
            {filename: 'this-way-up.png', title: 'This Way Up'},
            {filename: 'jansy-logo-blue.png', title: 'Jansy logo'},
            {filename: 'barcode.png', title: 'Barcode'}
        ];

    }

    
})();
