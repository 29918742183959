(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetRequests', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.requests.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $interval, SlateAuth, SlateRolesSrv, SlateRequests, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var interval = null;

        vm.loading = false;

        vm.getLink = getLinkSrv;
        vm.requests = new SlateRequests();

        vm.fields = ['id', 'name', 'slug', 'task_count', 'task_complete', 'complete', 'project', 'project_slug', 'createdby', 'createdby_slug', 'createdby_name', 'project','project_slug','project_compsite_num','project_name', 'desired_by', 'reviewed_by_info'];

        function getData() {
            var filters = {
                order_by: '-created',
                roles: vm.settings.groups,
                new_only: vm.settings.new_only,
                'fields': vm.fields,
            };
            vm.loading = true;

            if (vm.settings.mine === true) { filters.mine = true; }
            if (vm.settings.unreviewed === true) { filters.unreviewed = true; }
            if (vm.settings.unassigned === true) { filters.unassigned = true; }

            if (vm.settings.show_completed === true) {
                filters.hide_completed = 10;
            } else {
                filters.hide_completed = 0;
            }
            vm.requests.getList(filters).then(function() {
                vm.requests.list.sort(function compare(a, b) {
                    if(a.complete) { return 1; }
                    if (a.task_count < b.task_count) {
                        return -1;
                    }
                    if (a.task_count > b.task_count) {
                        return 1;
                    }
                    return 0;
                });
                vm.loading = false;
            });
        }

        $scope.$watch('vm.settings', function() {
            getData();
        }, true);

        interval = $interval(function() {
            getData();
        }, 360000);

        $scope.$on('$destroy', function() {
            if (angular.isDefined(interval)) {
                $interval.cancel(interval);
                interval = undefined;
            }
        });
    }


})();
