(function() {
    "use strict";

    angular
        .module('slate.projects')
        .directive('slateLinkedBomList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/projects/projects.linkedboms.list.dir.html',
            scope: {
                modelFilters: '=filters',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $q, $http, $window, getLinkSrv, SlateAuth, SlateBOMs) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;
        vm.updateFromFilter = updateFromFilter;
        vm.getLink = getLinkSrv;

        vm.loading = true;
        vm.filters = {
            page: $location.search().page || 1,
            count: $location.search().count || 25,
        };

        angular.extend(vm.filters, vm.modelFilters);
        if(vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if(vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }

        vm.bomList = new SlateBOMs();
        if ($location.search().openbom) { vm.bomList.toggleBom = true; }

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        function updateFromFilter() {
            vm.loading = true;

            angular.forEach(vm.filters, function(value, item) {
                if (vm.filters[item] && !vm.modelFilters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            vm.bomList.getList(vm.filters).then(function() {
                if (vm.bomList.toggleBom) {
                    var bom = vm.bomList.list.find(function(bom) { return bom.id == $location.search().openbom; });
                    if (bom) { bom.toggled = true; }
                    vm.bomList.toggleBom = false;
                }
                vm.loading = false;
            }, function() {
                vm.loading = false;
            });

        }

        
    }


})();
