(function () {
    'use strict';

    angular
        .module('slate.billings')
        .directive('slateBillingsList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/billings/billing.list.dir.html',
            scope: {
                modelFilters: '=?filters',
                hideFilters: '=?',
                fields: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController($scope, $state, $location, $window, $q, getLinkSrv, SlateAuth, SlateDeleteObjSrv, SlateBillings) {
        /* jshint validthis: true */
        const vm = this;

        vm.billingsList = new SlateBillings();

        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;

        vm.deleteBilling = deleteBilling;

        vm.loading = true;

        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;

        vm.filters = {};
        if (!vm.hideFilters) {
            vm.filters = {
                page: 1,
                count: 25,
                status: null
            };
        }
        if (!vm.modelFilters) { vm.modelFilters = {}; }

        vm.avail_fields = [
            'invoice_num',
            'company',
            'name',
            'billing_type',
            'status',
            'date',
            'proj_mgr',
            'sales_mgr',
            'line_items_total',
            'total',
            'sf_id'
        ];
        if (!vm.fields) {
            vm.fields = vm.avail_fields;
        }

        const multiSearchFields = ['status', 'billing_type'];

        angular.extend(vm.filters, {
            q: $location.search().q
        }, vm.modelFilters, $location.search(), vm.filters);
        if (typeof vm.filters.page === 'string') { vm.filters.page = parseInt(vm.filters.page, 10); }
        if (typeof vm.filters.count === 'string') { vm.filters.count = parseInt(vm.filters.count, 10); }


        if (vm.filters.page) { vm.filters.page = parseInt(vm.filters.page, 10); }
        if (vm.filters.count) { vm.filters.count = parseInt(vm.filters.count, 10); }

        $scope.$watchCollection(() => vm.filters, (value) => {
            updateFromFilter();
        });

        function hasFilterData() {
            let hasData = false;
            angular.forEach(vm.filters, (value, key) => {
                if (key === 'page' || key === 'count' || key === 'q') {
                    return false;
                }
                if (typeof vm.filters[key] === 'undefined' || vm.filters[key] === undefined || vm.filters[key] === null) {
                    return false;
                }
                if (vm.filters[key] === vm.modelFilters[key]) {
                    return false;
                }
                if (typeof vm.filters[key] === 'string' && vm.filters[key].length === 0) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;
            });
            return hasData;
        }

        function updateFromFilter() {
            vm.loading = true;

            multiSearchFields.forEach((field) => {
                if (typeof vm.filters[field] === 'string') {
                    vm.filters[field] = [vm.filters[field]];
                }
            });

            if (!vm.hideFilters) {
                angular.forEach(vm.filters, (value, item) => {
                    if (vm.filters[item]) {
                        $location.replace().search(item, vm.filters[item]);
                    } else {
                        $location.replace().search(item, null);
                    }
                });
            }

            const modelPromise = vm.billingsList.getList(Object.assign({ fields: vm.fields }, vm.filters));
            modelPromise.then(() => {
                vm.loading = false;
            });
            vm.url = $window.location.href;
        }

        function deleteBilling(item) {
            if (!window.confirm('Are you sure you want to delete this billing?')) { return false; }

            item.deleteModel().then(() => {
                vm.updateFromFilter();
            });

            return null;
        }
    }
})();
