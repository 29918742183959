(function () {
    "use strict";

    angular
        .module('slate.projects')
        .service('SlateItemStockDialog', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlatePOLineItem) {
        var templateUrl= '/partials/projects/instock/instock.sidebar.srv.html';

        var srv = {
            showStock: editObj
        };
        return srv;

        function editObj(componentId) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    componentId: function() { return componentId; }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $http, $q, $uibModalInstance, SlateAuth, componentId, SlateProject) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'projects/project/';

        vm.auth = SlateAuth;

        vm.close = close;

        vm.componentId = componentId;
        vm.component = new SlateProject();
        vm.component.loadFromServer(componentId);
        vm.stock = [];

        function close() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }

        $scope.$watch('vm.componentId', function () { getStock(); });

        function getStock() {
            var modelUrl = apiUrl + vm.componentId + '/stock/';
            vm.loading = true;

            if(vm.canceller) { vm.canceller.resolve(); }
            vm.canceller = $q.defer();

            if(!vm.componentId) {
                vm.loading = false;
                vm.stock = [];
                return false;
            }

            return $http.get(modelUrl, {
                timeout: vm.canceller.promise
            }).then(function(response) {
                vm.canceller = null;
                vm.loading = false;
                console.log('got stock', response.data.payload);
                vm.stock = response.data.payload;
            }, function() {
                vm.canceller = null;
                vm.loading = false;
                vm.stock = [];
            });
        }


    }



})();
