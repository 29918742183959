(function () {
    'use strict';

    angular
        .module('slate.synergy')
        .directive('slateSynergyEmailsList', modelList);

    function modelList() {
        return {
            restrict: 'E',

            templateUrl: '/partials/synergy/synergy.emails.list.html',
            scope: {

            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function ListController($scope, $http, $location, $q, SlateAuth, SlateSynergyEmails, SlateDeleteObjSrv) {
        /* jshint validthis: true */
        const vm = this;
        vm.auth = SlateAuth;


        vm.loading = true;

        vm.hasFilterData = hasFilterData;
        vm.deleteEmail = deleteEmail;

        vm.filters = {};
        vm.multiSearchFields = ['categories'];
        // these are valid url search parameters
        vm.url_filter_fields = [
            'is_completed',
            'we_can_help',
            'view_all',
            'internal_contact',
            'categories'
        ];

        vm.default_filters = {};
        if (!vm.hideFilters) {
            vm.default_filters.page = 1;
            vm.default_filters.count = 25;
        }

        const searchParams = $location.search();
        vm.url_filters = {};
        vm.url_filter_fields.forEach((item) => {
            if (searchParams.hasOwnProperty(item)) {
                vm.url_filters[item] = searchParams[item];
            }
        });
        vm.filters = Object.assign({}, vm.default_filters, vm.modelFilters, vm.url_filters);
        if (typeof vm.filters.page === 'string') { vm.filters.page = parseInt(vm.filters.page, 10); }
        if (typeof vm.filters.count === 'string') { vm.filters.count = parseInt(vm.filters.count, 10); }

        vm.multiSearchFields.forEach((field) => {
            if (vm.filters[field] && typeof (vm.filters[field]) === 'string') {
                vm.filters[field] = [vm.filters[field]];
            }
        });

        if (hasFilterData()) { vm.showFilters = true; }

        vm.synergyEmails = new SlateSynergyEmails();

        vm.fields = [
            'sender',
            'subject',
            'date',
            'can_we_help',
            'is_completed',
            'categories',
            'internal_contact',
            'is_spam'
        ];


        $scope.$watchCollection(() => vm.filters, (value) => {
            updateFromFilter();
        });

        function hasFilterData() {
            let hasData = false;
            angular.forEach(vm.filters, (value, key) => {
                if (key === 'q' || key === 'page' || key === 'count') { return false; }
                if (!value) { return false; }
                hasData = true;
                return true;
            });
            return hasData;
        }

        function updateFromFilter() {
            vm.loading = true;

            angular.forEach(vm.filters, (value, item) => {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            return vm.synergyEmails.getList(vm.filters).then(() => {
                vm.loading = false;
            });
        }

        function deleteEmail(email) {
            SlateDeleteObjSrv.delete('payableemail', email.id).then(() => {
                updateFromFilter();
            });
        }
    }
})();
