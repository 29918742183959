(function () {
    "use strict";

    angular
        .module('slate.customercomplaints')
        .service('SlateCustomerComplaintEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlatePurchaseOrder, SlateCustomerComplaint) {
        var templateUrl = '/partials/customercomplaints/customercomplaint.edit.srv.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj, purchaseOrderId) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj: function () {
                        var newComplaint = new SlateCustomerComplaint();
                        if (!obj) {
                            return newComplaint;
                        }
                        return newComplaint.loadFromPayload(obj);
                    },
                    purchaseOrder: function() {
                        var purchaseOrder = new SlatePurchaseOrder();
                        return purchaseOrder.loadFromServer(purchaseOrderId);
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return obj;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateAuth, SlateCustomerComplaintLineItem, obj, purchaseOrder) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;
        vm.purchaseOrder = purchaseOrder;
        vm.auth = SlateAuth;

        vm.toggleAllLineItems = toggleAllLineItems;
        vm.detailsComplete = detailsComplete;
        vm.lineItemsComplete = lineItemsComplete;
        vm.filesComplete = filesComplete;
        
        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.wizardControl = {};
        vm.fields = [
            'jansy_pn',
            'name',
            'defect_rate',
            'qty',
        ];

        vm.obj.purchase_order = vm.purchaseOrder.id;
        vm.obj.name = vm.purchaseOrder.name;

        vm.lineItems = vm.purchaseOrder.line_items.map(function(item) {
            var lineItem = new SlateCustomerComplaintLineItem();
            lineItem.po_lineitem = item.id;
            lineItem.po_lineitem_info = item;
            lineItem.qty = item.qty;
            return lineItem;
        });

        function saveObject() {
            if (vm.saving) {
                return false;
            }
            vm.saving = true;
            vm.error = false;

            vm.obj.line_items = vm.lineItems.filter(function(item) { return item.selected; });

            vm.obj.saveModel().then(function () {
                vm.fileSrv.uploadFiles('customercomplaint', vm.obj.id).then(function() {
                    vm.saving = false;
                    $uibModalInstance.close(vm.obj);
                });
            }, function () {
                vm.saving = false;
                vm.error = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function toggleAllLineItems() {
            vm.lineItems.forEach(function(item) {
                item.selected = vm.selectAll;
            });
        }

        function detailsComplete() {
            if (!vm.obj.date) { return false; }
            if (!vm.obj.notes) { return false; }
            return true;
        }

        function lineItemsComplete() {
            if(!vm.lineItems.some(function(item) { return item.selected; })) { return false; }
            return true;
        }

        function filesComplete() {
            if(vm.filesForm.$invalid) { return false; }
            if(!vm.fileSrv.files.length) { return false; }
            return true;
        }

    }

})();
