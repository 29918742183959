(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .controller('SlatePOForceBuildPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlatePurchaseOrder, SlateShipment) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.purchaseorder = new SlatePurchaseOrder();

        vm.purchaseorder.loadFromServer($stateParams.id).then(function () {
            vm.loading = false;
        });
    }

})();
