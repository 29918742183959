(function () {
    "use strict";

    angular
        .module('slate.inspections')
        .directive('slateInspectionList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/inspections/inspection.list.dir.html',
            scope: {
                hideFilters: '=?',
                modelFilters: '=?filters',
                showTotals: '=?',
                fields: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlateDeleteObjSrv, SlateInspections, SlateBookingEditSrv) {
        /* jshint validthis: true */
        var vm = this;

        vm.loading = true;
        vm.auth = SlateAuth;
        vm.inspections = new SlateInspections();
        vm.deleteInspection = deleteInspection;
        var multiSearchFields = ['status', 'request_type', 'inspection_type'];

        if (!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'jansy_po',
                'project',
                'description',
                'status',
                'vendor',
                'ship_date',
                'inspector',
                'inspection_type',
                'created',
                'created_by',
            ];
            /* beautify preserve:end */
        }

        vm.filters = angular.extend({ page: 1, count: 25 }, vm.modelFilters, $location.search());
        if (vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if (vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }

        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            updateFromFilter();
        });


        $scope.$watch('vm.qcDateStart', function () {
            if (vm.qcDateStart) {
                vm.filters.qc_date_start = dateFns.format(vm.qcDateStart, 'YYYY-MM-DD');
            } else {
                vm.filters.qc_date_start = null;
            }
        });
        $scope.$watch('vm.qcDateEnd', function () {
            if (vm.qcDateEnd) {
                vm.filters.qc_date_end = dateFns.format(vm.qcDateEnd, 'YYYY-MM-DD');
            } else {
                vm.filters.qc_date_end = null;
            }
        });

        function updateFromFilter() {
            vm.loading = true;

            multiSearchFields.forEach(function (field) {
                if (vm.filters[field] && typeof vm.filters[field] === 'string') {
                    vm.filters[field] = [vm.filters[field]];
                }
            });

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            vm.inspections.getList(vm.filters).then(function () {
                vm.loading = false;
            }, function () {

            });
        }

        function deleteInspection(inspection) {
            SlateDeleteObjSrv.delete('inspection', inspection.id).then(function () {
                updateFromFilter();
            });
        }

    }


})();
