var currentObjectServices = angular.module('slate.currentObjectSrv', []);

currentObjectServices.service('currentObjectSrv', function($rootScope) {
    "use strict";
    var srv = {};
    srv.object = null;
    srv.type = null;
    srv.promise = null;

    srv.empty = function() {
        srv.object = null;
        srv.type = null;
        srv.promise = null;
    };

    srv.broadcast = function() {
        $rootScope.$broadcast('currentObjectUpdated', srv);
    };

    return srv;
});