(function() {
    "use strict";

    angular
        .module('slate.sampletracking')
        .service('SlateSampleEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSample) {
        var templateUrl = '/partials/sampletracking/samples.edit.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj: function() {
                        return obj;
                    },
                }
            });

            return modalInstance.result.then(function(results) {

                return obj;
            }, function() {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, getLinkSrv, SlateAuth, SlateCompanies, SlateProjects, SlateProject, SlateAssignedRoles, SlateRolesSrv, SlateFileNode, obj) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.getTrackingLink = getTrackingLink;
        vm.getLink = getLinkSrv;

        vm.companies = new SlateCompanies();
        vm.companies.getList();

        vm.vendors = new SlateCompanies();
        vm.vendors.getList({
            vendor: true
        });

        vm.files = [];
        vm.removeFile = removeFile;

        vm.cap_prefix = cap_prefix;

        vm.toggle_approval = toggle_approval;
        vm.complete = complete;
        
        vm.projects = new SlateProjects();
        vm.components = new SlateProjects();
        if (vm.obj.company) {
            vm.projects.getList({
                company: vm.obj.company
            }).then(function() {
                if(vm.obj.project) {
                    vm.components.getList({
                        project: vm.obj.project,
                        recursive: true
                    });
                }
            });
        }

        vm.codes = vm.obj.getCodes();

        function projectSync() {
            var assroles = new SlateAssignedRoles();
            var project = new SlateProject();
            var role_dict = SlateRolesSrv.dict;
            assroles.getList({
                parent_type: 'project',
                parent_fk: vm.obj.project
            }).then(function () {
                angular.forEach(assroles.list, function (item) {
                    if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Project Manager' && !vm.obj.proj_mgr) {
                        vm.obj.proj_mgr = item.member_fk;
                    }
                    if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Sales Manager' && !vm.obj.sales_mgr) {
                        vm.obj.sales_mgr = item.member_fk;
                    }
                });
            });
            project.loadFromServer(vm.obj.project).then(function () {
                if (project.parent_type == 'company') {
                    vm.obj.company = project.parent_fk;
                }
            });
        }

        $scope.$watch('vm.obj.project', function() {
            if (vm.obj.project) {
                projectSync();
                vm.components.getList({
                    project: vm.obj.project,
                    recursive: true
                });
            }
        });


        function removeFile(index) {
            vm.files.splice(index, 1);
        }

        $scope.$watch('vm.filesAdd', function() {

            angular.forEach(vm.filesAdd, function(file) {
                vm.files.push(file);
            });
        });

        function getTrackingLink() {
            if(!vm.obj.shipping_tracking || !vm.obj.shipping_provider){
                return '#';
            }
            var targetUrl = '#';

            //url's for tracking
            var ups_url = 'http://wwwapps.ups.com/WebTracking/processRequest?HTMLVersion=5.0&Requester=NES&AgreeToTermsAndConditions=yes&loc=en_US&tracknum=';
            var dhl_url = 'http://www.dhl.com/en/express/tracking.html?brand=DHL&AWB=';
            var usps_url = 'http://trkcnfrm1.smi.usps.com/PTSInternetWeb/InterLabelInquiry.do?origTrackNum=';
            var fedex_url = 'http://www.fedex.com/Tracking?tracknumbers=';
            var sf_url = 'https://www.sf-express.com/us/en/dynamic_function/waybill/#search/bill-number/';

            switch (vm.obj.shipping_provider) {
                case 'UPS':
                    targetUrl = ups_url;
                    break;
                case 'DHL':
                    targetUrl = dhl_url;
                    break;
                case 'USPS':
                    targetUrl = usps_url;
                    break;
                case 'FEDEX':
                    targetUrl = fedex_url;
                    break;
                case 'SF':
                    targetUrl = sf_url;
                    break;
            }

            targetUrl += vm.obj.shipping_tracking;
            return targetUrl;
        }



        function saveObject() {
            if (vm.saving === true) {
                return false;
            }
            vm.saving = true;

            vm.obj.saveModel().then(function() {
                var filepromise = $q.resolve();
                vm.savedFiles = [];
                angular.forEach(vm.files, function(file) {
                    var newFile = new SlateFileNode();
                    var payload;

                    if(vm.obj.project) {
                        payload = {
                            parent_type: 'project',
                            parent_fk: vm.obj.project,
                            path: 'samples/' + vm.obj.description,
                            filename: file.name,
                            description: file.description,
                        };
                    } else if(vm.obj.company) {
                        payload = {
                            parent_type: 'company',
                            parent_fk: vm.obj.company,
                            path: 'samples/' + vm.obj.description,
                            filename: file.name,
                            description: file.description,
                        };
                    }

                    if(payload) {
                        newFile.loadFromPayload(payload);

                        filepromise = filepromise.then(function() {
                            return newFile.saveModel(file).then(function() {
                                vm.savedFiles.push(newFile);

                                var linkedNode = new SlateFileNode();
                                linkedNode.loadFromPayload({
                                    mimetype: newFile.mimetype,
                                    parent_type: 'sampletracker',
                                    parent_fk: vm.obj.id,
                                    path: '',
                                    filename: file.name,
                                    core_id: newFile.core_id,
                                    description: file.description,
                                });
                                linkedNode.saveModel();
                            });
                        });
                    } else {
                        
                        payload = {
                            parent_type: 'sampletracker',
                            parent_fk: vm.obj.id,
                            path: '',
                            filename: file.name,
                            description: file.description,
                        };
                        newFile.loadFromPayload(payload);
                        filepromise = filepromise.then(function() {
                            return newFile.saveModel(file).then(function() {
                                vm.savedFiles.push(newFile);
                            });
                        });
                    }

                });

                filepromise.then(function() {
                    vm.saving = false;
                    $uibModalInstance.close(vm.obj);
                });
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function cap_prefix() {

            vm.obj.client_code = vm.obj.client_code.toUpperCase();
        }

        function toggle_approval(flag) {
            if(!SlateAuth.perms.samples_approval) { return false; }
            if(vm.obj[flag+'_by']) { return false; }

            vm.obj[flag] = !vm.obj[flag];
        }

        function complete() {
            if(!vm.obj.description) { return false; }
            if(!vm.obj.sample_type) { return false; }
            if(!vm.obj.company) { return false; }
            if(!vm.obj.vendor) { return false; }

            return true;
        }

    }



})();
