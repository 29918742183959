(function() {
    "use strict";

    angular
        .module('slate.roles')
        .directive('slateRolesEditBox', EditBox);

    function EditBox() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/roles/roles.edit.box.dir.html',
            scope: {
                roles: '=',
                companyFk: '=?',
                requiredRoles: '=?',
                allowRoles: '=?',
                onlyRoles: '=?',
                showDateBox: "=?",
                day: "=?",
            },
            controller: EditController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function EditController($q, SlateRolesSrv, SlateContacts, SlateCompany) {
        /* jshint validthis: true */
        var vm = this;
        var promises = [];
        vm.availRoles = SlateRolesSrv;
        vm.contactOptions = [];
        vm.companyContacts = new SlateContacts();
        vm.company = new SlateCompany();

        if(vm.companyFk && ! vm.onlyRoles) {
            promises.push(vm.company.loadFromServer(vm.companyFk));
            promises.push(vm.companyContacts.getList({company: vm.companyFk}));
        }

        if(!vm.onlyRoles) {
            vm.staffContacts = new SlateContacts();
            promises.push(vm.staffContacts.getList({staff: true}));
        }

        vm.deleteItem = deleteItem;
        vm.selectItem =  selectItem;


        $q.all(promises).then(function() {
            var options = [];
            if(vm.allowRoles || vm.onlyRoles) {
                angular.forEach(vm.availRoles.assignable, function(role) {
                    options.push({
                        source: 'Groups',
                        name: role.name,
                        member_type: 'role',
                        member_fk: role.id
                    });
                });
            }
            if(vm.companyContacts.list.length && ! vm.onlyRoles) {
                angular.forEach(vm.companyContacts.list, function(contact) {
                    options.push({
                        source: vm.company.name,
                        name: contact.full_name,
                        member_type: 'contact',
                        member_fk: contact.id
                    });
                });
            }
            if(!vm.onlyRoles) {
                angular.forEach(vm.staffContacts.list, function(contact) {
                    options.push({
                        source: 'staff',
                        name: contact.full_name,
                        member_type: 'contact',
                        member_fk: contact.id
                    });

                });                
            }
            vm.contactOptions = options;
        });

        function selectItem(role, $item) {
            role.member_fk = $item.member_fk;
            role.member_type = $item.member_type;
            role.member = $item.name;
        }


        function deleteItem(item) {
            if(vm.roles.canDelete(item)) {
                item.toDelete = !item.toDelete;
            }
        }

    }


})();
