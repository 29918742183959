(function() {
    "use strict";

    angular
        .module('slate.library.purchaseorders')
        .factory('SlateBuildGoods', ModelList)
        .factory('SlateBuildGood', ModelDetail);

    var apiUrl = API_URL + 'purchaseorders/builds/';

    function ModelList($http, $q, SlateBuildGood) {
        // Set to url relative to api root.

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.loadFromPayload = loadFromPayload;
        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then(function(response) {
                self.list = self.loadFromPayload(response.data.payload);
                self.totalItems = response.data.totalItems;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;

            var newlist = [];
            //set this for correct model
            angular.forEach(payload, function(item) {
                var itemModel = new SlateBuildGood();
                itemModel.loadFromPayload(item);
                newlist.push(itemModel);
            });
            self.list = newlist;
            return newlist;
        }
    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },

            {name: 'status'},

            {name: 'warehouse_from'},
            {name: 'warehouse_from_info', readonly: true},

            {name: 'shipment'},
            {name: 'shipment_info', readonly: true},

            {name: 'salesorder'},
            {name: 'salesorder_info', readonly: true},

            {name: 'build_date', type:'dateOnly'},

            {name: 'error_msg', readonly: true},
            
            {name: 'line_items', def: [], readonly: true},

            {name: 'created', readonly: true, type: 'date'},
            {name: 'created_by', readonly: true},
            {name: 'created_by_info', readonly: true},

        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.getModelData = getModelData;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                self[field.name] = _.cloneDeep(payload[field.name]);

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if (field.type == 'date' && payload[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if (field.type == 'dateOnly' && self[field.name] && typeof self[field.name] == 'string') {
                    var parts = self[field.name].split('-');
                    self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
                }

                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) { console.log('didnt parse json', e); }
                }

            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];

                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                deferred.resolve(self);

            });

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            var self = this;
            var data = {};

            angular.forEach(fields, function(field) {
                if ((!field.readonly || field.name == 'id') && typeof(self[field.name]) != 'undefined') {
                    data[field.name] = self[field.name];
                    if(field.type == "date") {
                        data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth()+1) + '-' + self[field.name].getUTCDate();
                    }
                    if(field.type == "dateOnly" && self[field.name]) {
                        data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth()+1) + '-' + self[field.name].getDate();
                    }
                    if(field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if(field.type == 'float') {
                        data[field.name] = parseFloat(self[field.name]);
                        if(isNaN(data[field.name])) { data[field.name] = null; }
                    }
                    if(field.trim && data[field.name]) {
                        data[field.name] = data[field.name].substr(0,field.trim);
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            data = getModelData();

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                //return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
