(function () {
    'use strict';

    angular
        .module('slate.salesorders')
        .directive('slateSalesorderLineitemSearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/salesorders/so.lineitem.searchbox.dir.html',
            scope: {
                lineItemId: '=',
                lineItemObj: '=?',
                readonly: '<?',
                salesOrderId: '=?',
                showDropdown: '<?'
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function directiveController($scope, $q, SlateOrder, SlateSOLineItem, SlateSOLineItems) {
        /* jshint validthis: true */
        const vm = this;
        let modelPromise;

        vm.id = `solineitem-search-box-${Math.random().toString(36).substring(2, 15)}${Math.random().toString(36).substring(2, 15)}`;

        vm.specs = new SlateSOLineItems();
        vm.dropdownSpecs = new SlateSOLineItems();
        let selectedSpec;

        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.clear = clear;

        vm.selectDropdown = selectDropdown;

        vm.getWidth = getWidth;

        function getWidth() {
            const elem = document.getElementById(vm.id);
            return `${elem.offsetWidth}px`;
        }

        function getDropDown() {
            const filters = {};
            let promise;

            if (!vm.showDropdown) { return false; }

            if (!vm.salesOrderId) { return false; }
            if (vm.salesOrderId) { filters.sales_order = vm.salesOrderId; }
            promise = vm.dropdownSpecs.getList(filters).then(() => vm.dropdownSpecs.list);
            return promise;
        }

        function selectDropdown($item) {
            vm.searching = false;
            vm.lineItemId = $item.id;
            selectedSpec = $item.id;
            vm.specObj = $item;
            vm.lineItemObj = $item;
            vm.specName = $item.finished_good_info.composite_name;
        }


        function getSearch(q) {
            const filters = {
                q,
                page: 1,
                count: 50
            };
            vm.searching = true;
            if (q && q.length < 3) { return $q.reject([]); }
            if (vm.salesOrderId) { filters.sales_order = vm.salesOrderId; }
            const promise = vm.specs.getList(filters).then(() => {
                if (vm.non_inventory) {
                    return vm.specs.list.filter((item) => item.is_non_inventory);
                }
                return vm.specs.list;
            });
            return promise;
        }

        function selectSearch($item, $model, $label, $event) {
            vm.searching = false;
            vm.lineItemId = $item.id;
            selectedSpec = $item.id;
            vm.specObj = $item;
            vm.lineItemObj = $item;
            vm.specName = $item.finished_good_info.composite_name;
        }

        function clear() {
            if (vm.readonly) { return true; }
            vm.lineItemId = null;
            selectedSpec = null;
            vm.specObj = null;
            vm.lineItemObj = null;
            vm.specName = null;
            return null;
        }

        $scope.$watch('vm.lineItemId', () => {
            if (selectedSpec !== vm.lineItemId && vm.lineItemId) {
                const spec = new SlateSOLineItem();
                spec.loadFromServer(vm.lineItemId).then(() => {
                    vm.specObj = spec;
                    vm.lineItemObj = spec;
                    vm.specName = spec.finished_good_info.composite_name;
                    selectedSpec = spec.id;
                });
            }
        });
        $scope.$watch('vm.salesOrderId', () => { getDropDown(); });

        $scope.$watch('vm.typeaheadOpen', () => {
            if (!vm.typeaheadOpen && !vm.specObj && vm.lineItemId) {
                const spec = new SlateSOLineItem();
                spec.loadFromServer(vm.lineItemId).then(() => {
                    vm.specObj = spec;
                    vm.lineItemObj = spec;
                    vm.specName = spec.finished_good_info.composite_name;
                    selectedSpec = spec.id;
                });
            }
        });
    }
})();
