(function() {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetApprovalsMade', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.approvals-made.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $interval, SlateAuth, getLinkSrv, SlateApprovalSteps) {
        /* jshint validthis: true */
        var vm = this;
        var interval = null;

        vm.getLink = getLinkSrv;
        vm.approvalsList = new SlateApprovalSteps();

        init();

        function init() {
            modelPromise = vm.approvalsList.getList({mine: true});
        }

        interval = $interval(function() {
            init();
        }, 360000);

        $scope.$on('$destroy', function() {
            if (angular.isDefined(interval)) {
                $interval.cancel(interval);
                interval = undefined;
            }
        });

    }


})();
