(function() {
    "use strict";

    angular
        .module('slate.library.salesbudgets')
        .factory('SlateSalesBudgets', ModelList)
        .factory('SlateSalesBugdet', ModelDetail);

    var apiUrl = API_URL + 'salesbudgets/salesbudget/';

    function ModelList($http, $q, SlateSalesBugdet) {
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        self.pagination = {
            total: 0,
            page: 1,
            count: 25
        };
        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.sortList = sortList;
        list.prototype.getCompanies = getCompanies;
        list.prototype.getSalesMgrs = getSalesMgrs;
        list.prototype.getHistories = getHistories;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];
            self.comapnies = [];
            self.salesMgrs = [];

            return self;
        }

        function sortList() {
            /* jshint validthis: true */
            var self = this;
            
            self.list.sort(function(a, b) {
                if(a.sales_mgr_info.full_name < b.sales_mgr_info.full_name) { return -1; }
                if(a.sales_mgr_info.full_name > b.sales_mgr_info.full_name) { return 1; }
                
                if(a.company_info.name < b.company_info.name) { return -1; }
                if(a.company_info.name > b.company_info.name) { return 1; }

                return 0;
            });

            return self;
        }
        
        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];
            var avail_fields = new SlateSalesBugdet().getFieldsList();
            var update_filter = {};
            var update_fields = [];

            if(self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === "object") {
                if(filters.fields) {
                    filters.fields.forEach(function(item) {
                        update_fields.push(item);
                        if(avail_fields.includes(item+'_info')) {
                            update_fields.push(item+'_info');
                        }
                    });
                    update_filter.fields = update_fields;
                }
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters,
                timeout: self.canceller.promise
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlateSalesBugdet();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.pagination = {
                    // total: parseInt(response.data.pagination.total),
                    // page: parseInt(response.data.pagination.page),
                    // count: parseInt(response.data.pagination.count),
                };
                self.permissions = response.data.permissions;
                self.raw_histories = response.data.histories;
                self.histories = self.getHistories();
                self.companies = self.getCompanies();
                self.salesMgrs = self.getSalesMgrs();
                self.canceller = null;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

        function getHistories() {
            /* jshint validthis: true */
            var self = this;

            var companies = {};
            self.raw_histories.forEach(function (item) {
                if(!companies[item.company]) {
                    companies[item.company] = {
                        id: item.company,
                        items: {},
                    };
                }
                
                var parts = item.month.split('-');
                var date_str = parts[0] + '-' + parts[1];
                    
                companies[item.company].items[date_str] = item;
            });

            return companies;            
        }

        function getCompanies() {
            /* jshint validthis: true */
            var self = this;

            var entered = {};
            var companies = [];
            self.list.forEach(function (item) {
                if(item.sales_mgr_info.exclude_sales_budgets) { return false; }
                if(!entered[item.company_info.id]) {
                    companies.push({
                        id: item.company_info.id,
                        name: item.company_info.name,
                        sales_mgr_id: item.sales_mgr,
                        margin: item.company_info.historical_margin,
                        prev_margin: item.company_info.prev_historical_margin,
                        items: {},
                        total: 0.0,
                    });
                    entered[item.company_info.id] = true;
                }
            });

            companies.forEach(function (company) {
                self.list.forEach(function (item) {
                    if(item.sales_mgr_info.exclude_sales_budgets) { return false; }
                    if(item.company == company.id) {
                        var date_str = item.month.getFullYear() + '-' + (item.month.getMonth() + 1).toString().padStart(2, '0');
                        company.items[date_str] = item;
                        company.total = company.total + item.value;
                    }
                });
                company.all_ready = Object.keys(company.items).every(function(key) { 
                    return company.items[key].ready; 
                });

            });
            return companies;
        }

        function getSalesMgrs() {
            /* jshint validthis: true */
            var self = this;

            var entered = {};
            var salesMgrs = [];
            var totals = {};
            self.list.forEach(function (item) {
                var date_str = item.month.getFullYear() + '-' + (item.month.getMonth() + 1).toString().padStart(2, '0');
                
                if(item.sales_mgr_info.exclude_sales_budgets) { return false; }
                if(!totals[item.sales_mgr]) { totals[item.sales_mgr] = {}; }
                if(!totals[item.sales_mgr][date_str]) { totals[item.sales_mgr][date_str] = 0.0; }
                totals[item.sales_mgr][date_str] += item.value;
            });
            self.list.forEach(function (item) {
                if(item.sales_mgr_info.exclude_sales_budgets) { return false; }
                if(!entered[item.sales_mgr_info.id]) {
                    salesMgrs.push({
                        id: item.sales_mgr_info.id,
                        name: item.sales_mgr_info.full_name,
                        totals: totals[item.sales_mgr]
                    });
                    entered[item.sales_mgr_info.id] = true;
                }
            });
            return salesMgrs;
        }


    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },
            
            {name: 'company'},
            {name: 'company_info', readonly: true},
            {name: 'sales_mgr'},
            {name: 'sales_mgr_info', readonly: true},
            
            {name: 'month', type: 'dateOnly'},
            {name: 'value', type: 'float', def: 0.00},
            {name: 'margin', type: 'float', def: 30.00},

            {name: 'open_orders', type: 'float', def: 0.00},
            {name: 'previous_year', type: 'float', def: 0.00},
            
            {name: 'approved', def: false},
            {name: 'ready', def: false},
            {name: 'active', def: false},

            {name: 'notes', def: ''},
            
            {name: 'created', readonly: true},
            {name: 'created_by', readonly: true},
            {name: 'created_by_info', readonly: true},
            {name: 'updated', readonly: true},
            {name: 'deleted', readonly: true, default: false},
            
            

        ];
        /* beautify preserve:end */

        
        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.getFieldsList = getFields;
        model.prototype.getForMonth = getForMonth;
        
        return model;

        function getFields() {
            var fields_list = [];
            fields.forEach(function(field) {
                fields_list.push(field.name);
            });
            return fields_list;
        }

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = _.cloneDeep(field.def);

                if(field.type=="date" && !self[field.name]) {
                    //self[field.name] = new Date();
                }
                if(field.type=="child_list") {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                if(typeof payload[field.name] == 'undefined') {
                    return false;
                }

                self[field.name] = payload[field.name];

                if(field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type == 'dateOnly' && payload[field.name]) {
                    var parts = self[field.name].split('-');
                    self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
                }


                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if(field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch(e) {
                    }
                }

                if(field.type == 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach(function(item, idx, array) {
                            var obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch(e) {
                        console.log('error loading: ', e);
                    }
                }

                self.verbose_date = self.start_year + '-' + self.start_month;


            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id, filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            if(typeof filters === 'undefined') { 
                params={};
            } else if(typeof filters === 'object') { 
                params=_.cloneDeep(filters);
            } else {
                console.log('Tried to pass invalid filters');
                return $q.reject(self);
            }
            
            promise = $http.get(modelUrl, {
                params: params
            });

            promise.then(function(response) {
                var promises = [];

                return self.loadFromPayload(response.data.payload).then(function() {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            angular.forEach(fields, function(field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if(data[field.name] && field.type == "date") {
                        data[field.name] = self[field.name].toISOString();
                    }
                    if(field.type == "dateOnly" && self[field.name]) {
                        data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth()+1) + '-' + self[field.name].getDate();
                        //data[field.name] = self[field.name].toISOString();
                    }
                    if(field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if(field.type == "child_list" && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach(function(item) {
                            if(item.toDelete) { return false; }
                            data[field.name].push(item.getModelData());
                        });
                    }

                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

        function getForMonth(month_str) {
            /* jshint validthis: true */
            var self = this;
            
            var parts = month_str.split('-');
            var year = parseInt(parts[0]);
            var month = parseInt(parts[1]);
            var results = self.line_items.find(function(element) { return element.year == year && element.month == month; });
            if(!results) { return  0; }
            return results.value;
        }

    }

    
})();
