(function () {
    'use strict';

    angular
        .module('slate.productprofiles')
        .directive('slateProductprofileItemList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/productprofiles/productprofile.item.list.dir.html',
            scope: {
                modelFilters: '=?filters',
                showNew: '=?',
                hideFilters: '=?',
                fields: '=?',
                showTotals: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController(
        $scope,
        $location,
        SlateAuth,
        getLinkSrv,
        SlateDeleteObjSrv,
        SlateProductProfile,
        SlateProductProfileItem,
        SlateProductProfileItems,
        SlateProductProfileLineItemEditSrv,
        SlateProductProfileLineItemContactsSrv,
        SlateProductProfileEmailSrv,
        SlateProductProfileGenCompsSrv,
        SlateProductProfileBulkItemsSrv
    ) {
        /* jshint validthis: true */
        const vm = this;

        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;

        vm.addItem = addItem;
        vm.editProfile = editProfile;
        vm.emailItem = emailItem;
        vm.genItems = genItems;
        vm.editContacts = editContacts;
        vm.bulkAddItems = bulkAddItems;
        vm.deleteItem = deleteItem;

        vm.productprofile = new SlateProductProfile();
        vm.productprofile.loadFromServer(vm.modelFilters.profile);
        vm.productprofileitems = new SlateProductProfileItems();

        if (!vm.modelFilters) { vm.modelFilters = {}; }
        if (!vm.fields) {
            /* beautify preserve:start */
            vm.fields = [
                'brand',
                'label',
                'profile',
                'name',
                'status',
                'samples_needed',
                'samples_received',
                'samples_received_total',
                'created',
                'created_by'
            ];
            /* beautify preserve:end */
            vm.fields = vm.fields.filter((field) => !vm.modelFilters.hasOwnProperty(field));
        }


        vm.status_list = [];

        vm.filters = {};
        if (!vm.hideFilters) {
            vm.filters = {
                page: 1,
                count: 25,
                status: null
            };
        }

        if (vm.showNew === undefined) { vm.showNew = true; }

        angular.extend(vm.filters, {
            q: $location.search().q
        }, vm.modelFilters, $location.search(), vm.filters);
        if (typeof vm.filters.page === 'string') { vm.filters.page = parseInt(vm.filters.page, 10); }
        if (typeof vm.filters.count === 'string') { vm.filters.count = parseInt(vm.filters.count, 10); }


        $scope.$watchCollection(() => vm.filters, () => {
            updateFromFilter();
        });

        function hasFilterData() {
            let hasData = false;
            angular.forEach(vm.filters, (value, key) => {
                if (key === 'page' || key === 'count' || key === 'q') {
                    return false;
                }
                if (typeof vm.filters[key] === 'undefined' || vm.filters[key] === undefined || vm.filters[key] === null) {
                    return false;
                }
                if (vm.filters[key] === vm.modelFilters[key]) {
                    return false;
                }
                if (typeof vm.filters[key] === 'string' && vm.filters[key].length === 0) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;
            });
            return hasData;
        }

        function updateFromFilter() {
            vm.loading = true;
            if (typeof vm.filters.status === 'string') {
                vm.filters.status = [vm.filters.status];
            }

            angular.forEach(vm.filters, (value, item) => {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            const updatePromise = vm.productprofileitems.getList(vm.filters);
            updatePromise.then(() => {
                vm.loading = false;
            });
        }

        function addItem() {
            const item = new SlateProductProfileItem();
            if (vm.modelFilters.profile) {
                item.profile = vm.modelFilters.profile;
                item.due_date = vm.productprofile.due_date;
            }
            return editProfile(item);
        }

        function editProfile(profile) {
            SlateProductProfileLineItemEditSrv.edit(profile).then((results) => {
                updateFromFilter();
            });
        }

        function editContacts(profile_item) {
            SlateProductProfileLineItemContactsSrv.edit(profile_item.id);
        }

        function emailItem(profile) {
            SlateProductProfileEmailSrv.email(profile).then(() => {
                profile.status = 'sent';
            });
        }

        function genItems() {
            SlateProductProfileGenCompsSrv.gen(vm.productprofile);
        }

        function bulkAddItems() {
            SlateProductProfileBulkItemsSrv.view(vm.productprofile).then(() => {
                updateFromFilter();
            });
        }

        function deleteItem(item) {
            if (!item.id) {
                vm.productprofileitems.list = vm.productprofileitems.list.filter((ppitem) => item.id !== ppitem.id);
                return null;
            }
            SlateDeleteObjSrv.delete('productprofileitem', item.id).then(() => {
                updateFromFilter();
            });

            return null;
        }
    }
})();
