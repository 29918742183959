(function () {
    "use strict";

    angular
        .module('slate.salesorders')
        .service('SlateSOReleaseEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateSettings, SlateSORelease) {
        var templateUrl = '/partials/salesorders/releases/release.edit.srv.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(item, options) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj: function () {
                        var newitem = new SlateSORelease();
                        if(!item) {
                            return newitem;
                        }
                        if(typeof item == 'string') {
                            return newitem.loadFromServer(item);
                        }
                        return newitem.loadFromPayload(item);
                    },
                    lineItem: function() {
                        if(typeof options === 'object' && options.lineItem) {
                            return options.lineItem;
                        }
                        return null;
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance,  obj, lineItem) {
        /* jshint validthis: true */
        var vm = this;

        vm.item = obj;

        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.isComplete = isComplete;
        vm.isOverordered = isOverordered;

        vm.unreleased = lineItem.getUnreleased();
        vm.max_qty = _.cloneDeep(vm.unreleased);
        if(obj.qty) {
            vm.max_qty = parseFloat(vm.max_qty) + parseFloat(obj.qty);
        }

        function saveObject() {
            if(!isComplete) { return false; }
            $uibModalInstance.close(vm.item);
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }


        function isComplete() {
            if(!vm.item.qty) { return false; }
            if(!vm.item.date) { return false; }
            if(parseFloat(vm.item.qty) > parseFloat(vm.max_qty)) { return false; }
            return true;
        }

        function isOverordered() {
            if(parseFloat(vm.item.qty) > parseFloat(vm.max_qty)) { return true; }
            return false;
        }


    }



})();
