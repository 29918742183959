(function () {
    "use strict";

    angular
        .module('slate.app')
        .directive('slateProjConvForm', modelList)
        .directive('slateProjConvRow', RowModelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/slate/slate.projconversion.dir.html',
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $http, $q, SlateProject, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;

        vm.save = save;
        vm.canSave = canSave;

        vm.saving = false;

        vm.projects = [];
        vm.addProj = addProj;
        vm.removeProj = removeProj;
        vm.checkNewRow = checkNewRow;

        function addProj() {
            vm.projects.push({});
        }

        function removeProj(idx) {
            vm.projects.splice(idx, 1);
        }

        $scope.$watchCollection(function () {
            if (vm.projects.length) {
                return vm.projects[vm.projects.length - 1];
            } else {
                vm.projects.push({});
            }
            return {};
        }, function (newVal, oldVal, scope) {
            checkNewRow();
        });

        function checkNewRow() {
            var row = {};
            if (vm.projects.length) {
                row = vm.projects[vm.projects.length - 1];
            }
            if (row.project || row.amount || row.margin || row.close_date) {
                vm.projects.push({});
            }
        }

        function save() {
            var promise;
            var promises = [];
            var url = API_URL + 'projconversion';
            vm.saving = true;
            vm.error = false;

            angular.forEach(vm.projects, function (item) {
                if (item.project && item.amount && item.margin && item.close_date) {
                    item.saving = true;
                    var data = {
                        project: item.project,
                        amount: item.amount,
                        margin: item.margin,
                        close_date: item.close_date
                    };
                    promises.push($http.post(url, item).then(function () {
                        item.saved = true;
                    }, function () {
                        item.error = true;
                        item.saving = false;
                    }));
                }
            });

            promise = $q.all(promises);
            promise.then(function () {
                for (var i = vm.projects.length - 1; i >= 0; i--) {
                    if (vm.projects[i].saved) { vm.removeProj(i); }
                }
                vm.saving = false;
            }, function () {
                vm.saving = false;
                vm.error = true;
            });

            return promise;
        }

        function canSave() {


            return true;
        }
    }


    function RowModelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/slate/slate.projconversion.row.dir.html',
            controller: RowListController,
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                proj: '=project',
                idx: '=?',
                removeProj: '&'
            },
        };
    }

    function RowListController($scope, $http, $q, SlateProject, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;

        $scope.$watch('vm.proj.project', function () {
            if (vm.proj.projectObj) {
                if (!vm.proj.amount) { vm.proj.amount = parseInt(vm.proj.projectObj.spend); }
                if (!vm.proj.margin) { vm.proj.margin = parseInt(vm.proj.projectObj.proj_margin); }
                if (!vm.proj.close_date) { vm.proj.close_date = vm.proj.projectObj.close_date; }
            }
        });
    }
})();
