(function () {
    "use strict";

    angular
        .module('slate.logistics')
        .directive('slateShipmentFollowup', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/logistics/followup/ship.edit.dir.html',
            scope: {
                originShipment: '=',
                shipment: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $q,
        $timeout,
        $state,
        $translate,
        $http,
        SlateLocation,
        SlateLocations,
        SlatePOLineItems,
        SlatePOLineItem,
        SlateGoods,
        SlateGood,
        getLinkSrv
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;

        vm.shipment_existing = 'New Shipment';

        vm.saveObject = saveObject;
        vm.isComplete = isComplete;
        vm.showShipmentLocation = showShipmentLocation;
        vm.showDeliveryLocation = showDeliveryLocation;
        vm.showOriginLocation = showOriginLocation;
        vm.checkNumSign = checkNumSign;
        vm.calcTotalCBMs = calcTotalCBMs;

        vm.getLocation = getLocation;
        vm.geolocate = geolocate;

        vm.ship_types = ['oceanlcl', 'oceanfcl', 'oceanexp', 'air', 'truck', 'ups', 'fedex', 'dhl', 'other'];

        vm.incoterms = {
            'jansy': ['exw', 'fca', 'fob', 'cif', 'cpt', 'cip', 'dat', 'dap', 'ddp', 'ddu', 'other'],
            'customer': ['exw', 'fob', 'fca'],
            'vendor': ['exw', 'fca', 'fob', 'cif', 'cpt', 'cip', 'dat', 'dap', 'ddp', 'ddu', 'other'],
        };

        vm.pickup_info = {
        };

        vm.loadingLocations = {};
        vm.autoselectedLocation = {};

        $scope.$watch('vm.originShipment.id', function (old_val, new_val) {
            if(!vm.pickup_info.ready_date) { vm.pickup_info.ready_date = vm.originShipment.eta; }
            if(!vm.pickup_info.origin_from) { vm.pickup_info.origin_from = vm.originShipment.delivery_to; }
            if(!vm.pickup_info.origin_warehouse) { vm.pickup_info.origin_warehouse = vm.originShipment.delivery_warehouse; }
        });

        function saveObject() {
            var deferred = $q.defer();

            if (vm.saving === true) {
                return false;
            }

            if(vm.shipment_existing == 'Existing Shipment') {
                return combineObjects();
            }

            vm.saving = true;
            vm.error = false;
            vm.shipment.goods = [];
            vm.shipment.parent_shipment = vm.originShipment.id;

            vm.originShipment.goods.forEach(function (item) {
                if (isNaN(item.qty_to_ship) || !item.qty_to_ship) { return false; }

                var ship_item = new SlateGood();
                ship_item.copyFrom(item);

                ship_item.qty = item.qty_to_ship;
                ship_item.ready_date = vm.pickup_info.ready_date;
                ship_item.origin_from = vm.pickup_info.origin_from;
                ship_item.origin_warehouse = vm.pickup_info.origin_warehouse;

                ship_item.parent_good = item.id;

                vm.shipment.goods.push(ship_item);
            });

            if(vm.shipment.freight_handler == 'customer' && !vm.shipment.delivery_type) {
                vm.shipment.delivery_type = 'customer';
            }

            vm.shipment.saveModel().then(function () {
                var promises = [];

                if (vm.fileSrv) {
                    promises.push(vm.fileSrv.uploadFiles('shipment', vm.shipment.id));
                }

                return $q.all(promises).then(function () {
                    $timeout(function () {
                        vm.saving = false;
                        deferred.resolve();
                        if ($state.current.name != 'shipmentEditPage') {
                            $state.transitionTo(
                                'shipmentEditPage',
                                { id: vm.shipment.id },
                                { location: 'replace' }
                            );
                        }
                    }, 300);
                }, function () {
                    vm.saving = false;
                    vm.error = true;
                });
            }, function () {
                vm.saving = false;
                vm.error = true;
            });

            return deferred.promise;
        }

        function isComplete() {
            if(vm.shipment_existing == 'Existing Shipment') {
                if(vm.combine_into_shipment && vm.combine_into_shipment.length != 1) { return false; }
                if (!vm.pickup_info.ready_date) { return false; }
                if (!vm.pickup_info.origin_from) { return false; }
                if (!vm.pickup_info.origin_warehouse) { return false; }
            } else {
                if (!vm.shipment.freight_handler) { return false; }
                if (!vm.pickup_info.ready_date) { return false; }
                if (!vm.shipment.factory_incoterms) { return false; }
                if (vm.shipment.freight_handler !== 'customer') {
                    if (vm.shipment.freight_handler != 'vendor') {
                        if (!vm.shipment.warehouse) { return false; }
                        if (!vm.shipment.forwarder) { return false; }
                    }
                    if (!vm.shipment.shipment_type) { return false; }
                    if (!vm.shipment.origin) { return false; }
                    if (!vm.shipment.delivery_to) { return false; }
                    if (vm.shipment.delivery_type != 'customer' && !vm.shipment.delivery_warehouse) { return false; }
                    if (!vm.shipment.final_destination) { return false; }
                }
            }
            if (!vm.originShipment.goods.some(function (item) {
                if (isNaN(item.qty_to_ship)) { return false; }
                if (!item.qty_to_ship) { return false; }
                return true;
            })) { return false; }

            return true;
        }

        $scope.$watch('vm.shipment.freight_handler', function (old_val, new_val) {

            if (vm.shipment.freight_handler == 'vendor') {
                vm.shipment.delivery_type = 'customer';
            }
        });


        function showDeliveryLocation() {
            if (vm.shipment.freight_handler == 'customer') { return false; }
            if (!vm.shipment.delivery_to) { return false; }
            if (vm.loadingLocations.delivery_warehouse) { return false; }
            if (vm.autoselectedLocation.delivery_warehouse) { return false; }
            if (vm.shipment.delivery_type == 'customer') { return false; }
            return true;
        }

        function showOriginLocation() {
            if (!vm.pickup_info.origin_from) { return false; }
            if (vm.loadingLocations.origin_warehouse) { return false; }
            if (vm.autoselectedLocation.origin_warehouse) { return false; }

            return true;
        }

        function showShipmentLocation() {
            if (!vm.shipment.forwarder) { return false; }
            if (!vm.shipment.shipment_type) { return false; }
            if (vm.loadingLocations.warehouse) { return false; }
            if (vm.autoselectedLocation.warehouse) { return false; }

            return true;
        }

        $scope.$watch('vm.shipment.delivery_to', function (old_val, new_val) {
            if (vm.shipment.delivery_to) { checkLocation('delivery_to', 'customer', 'delivery_warehouse', 'final_destination'); }
        });

        $scope.$watch('vm.pickup_info.origin_from', function (old_val, new_val) {
            if (vm.pickup_info.origin_from) { checkLocation('origin_from', 'factory', 'origin_warehouse', 'origin'); }
        });

        $scope.$watch('vm.shipment.delivery_warehouse', function (old_val, new_val) {
            if (vm.shipment.delivery_warehouse) { updateAddress(vm.shipment.delivery_warehouse, 'final_destination'); }
        });

        $scope.$watch('vm.pickup_info.origin_warehouse', function (old_val, new_val) {
            if (vm.pickup_info.origin_warehouse) { updateAddress(vm.pickup_info.origin_warehouse, 'origin'); }
        });

        $scope.$watch('vm.shipment.forwarder', function (old_val, new_val) {
            if (vm.shipment.forwarder) { checkLocation('forwarder', vm.shipment.shipment_type, 'warehouse'); }
        });

        $scope.$watch('vm.shipment.shipment_type', function (old_val, new_val) {
            if (vm.shipment.forwarder) { checkLocation('forwarder', vm.shipment.shipment_type, 'warehouse'); }
        });

        function updateAddress(warehouse_id, address_field) {
            var location = new SlateLocation();
            location.loadFromServer(warehouse_id).then(function () {
                if (address_field) {
                    vm.shipment[address_field] = "";
                    ['street1', 'street2', 'city', 'state', 'postal', 'country'].forEach(function (fieldname) {
                        if (location[fieldname]) {
                            if (vm.shipment[address_field].length > 0) { vm.shipment[address_field] += ', '; }
                            vm.shipment[address_field] += location[fieldname];
                        }
                    });
                    geolocate(vm.shipment[address_field], address_field + '_gps');
                }
            });
        }

        function checkLocation(company_field, type_field, warehouse_field, address_field) {
            var locations = new SlateLocations();
            var filters = { company: vm.shipment[company_field] };

            if (!vm.shipment[company_field]) { return false; }
            if (type_field && type_field.length) {
                filters.warehouse_type = type_field;
            }

            vm.loadingLocations[warehouse_field] = true;

            locations.getList(filters).then(function () {
                if (locations.list.length != 1) {
                    vm.loadingLocations[warehouse_field] = false;
                    vm.autoselectedLocation[warehouse_field] = false;
                    return false;
                }
                vm.shipment[warehouse_field] = locations.list[0].id;
                vm.loadingLocations[warehouse_field] = false;
                vm.autoselectedLocation[warehouse_field] = true;
            });
        }

        function checkNumSign(lineItem, field) {
            if (lineItem[field] < 0) {
                lineItem[field] = Math.abs(lineItem[field]);
            }
        }

        function calcCBMs(lineItem) {
            if ([
                lineItem.length,
                lineItem.width,
                lineItem.height
            ].some(function(item) { return item == 0.0 || typeof item == 'undefined'; })) { return new Big(0); }

            var l = new Big(lineItem.length);
            var w = new Big(lineItem.width);
            var h = new Big(lineItem.height);

            var cbmPerCarton = l.times(w).times(h).div(1000000);

            return cbmPerCarton;
        }

        function calcTotalCBMs(lineItem) {
            if ([
                lineItem.qty_to_ship,
                lineItem.qty_per_carton,
            ].some(function(item) { return item === 0.0 || typeof item == 'undefined'; })) { return new Big(0); }

            var cbmPerCarton = calcCBMs(lineItem);

            var qtyToShip = new Big(lineItem.qty_to_ship);
            var qtyPerCarton = new Big(lineItem.qty_per_carton);
            var cartons = qtyToShip.div(qtyPerCarton);

            var totalCBMs = cartons.round(0, 3).times(cbmPerCarton);

            lineItem.cbm_to_ship = totalCBMs;
        }

        /******* Mapping stuff  ***********/
        vm.sessionToken = uuidv4();

        function getLocation(q) {
            var promise;
            var deferred = $q.defer();

            //var url = "/geolocate/";
            var url = API_URL + "utils/geocomplete/";
            var params = {
                'sessiontoken': vm.sessionToken,
                'lang': $translate.use(),
                'address': q
            };

            if (q.length < 5) {
                deferred.reject();
                return deferred.promise;
            }

            promise = $http.get(url, {
                params: params
            }).then(function (response) {
                var locations = [];

                angular.forEach(response.data.predictions, function (item) {
                    var name = item.description;

                    locations.push({ 'name': name, 'details': item });
                });
                deferred.resolve(locations);

            });

            return deferred.promise;
        }

        function geolocate(address, variable) {
            var promise;
            var url = API_URL + "utils/geolocate/";
            var params = {
                'lang': $translate.use(),
                'address': address
            };

            if (address.length < 5) {
                vm.shipment[variable] = { lat: 0, lng: 0 };
                return false;
            }

            promise = $http.get(url, {
                params: params
            }).then(function (response) {
                var results = [];
                vm.shipment[variable] = { lat: 0, lng: 0 };
                results = response.data.results;
                if (results && results.length) {
                    vm.shipment[variable].lat = results[0].geometry.location.lat;
                    vm.shipment[variable].lng = results[0].geometry.location.lng;
                }
            });

            return promise;
        }


    }

})();
