(function () {
    "use strict";

    angular
        .module('slate.bom')
        .service('SlateBOMEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateBOM) {
        var templateUrl = '/partials/bom/bom.edit.sidebar.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(bom, init_data) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    obj: function () {
                        var newbom = new SlateBOM(init_data);
                        if(!bom) {
                            return newbom;
                        }
                        if(typeof bom == 'string') {
                            return newbom.loadFromServer(bom);
                        }
                        return bom;
                    }
                }
            });

            return modalInstance.result.then(function (results) {
                
                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateAuth, obj) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;

        vm.obj = obj;

        vm.saving = false;
        vm.errorSaving = false;
        vm.toggleFinalized = toggleFinalized;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.detailsIsComplete = detailsIsComplete;

        var baseFinalizedStatus = vm.obj.finalized;

        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            zIndex: 2501,
        };

        function toggleFinalized() {
            if (baseFinalizedStatus && !vm.auth.user.permissions.bom_can_edit_finalized_boms) { return false; }
            vm.obj.finalized = !vm.obj.finalized;
        }

        function saveObject(skip) {
            if (vm.saving === true) {
                return false;
            }
            vm.saving = true;

            vm.obj.saveModel().then(function () {
                vm.saving = false;
                $uibModalInstance.close(vm.obj);
            }, function () {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }


        function detailsIsComplete() {
            return true;
        }

    }



})();
