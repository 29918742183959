//import { request } from "http";

(function() {
    "use strict";

    angular
        .module('slate.library.purchaseorders')
        .factory('SlatePOReceives', ModelList)
        .factory('SlatePOReceive', ModelDetail);

    var apiUrl = API_URL + 'purchaseorders/receive/';

    function ModelList($http, $q, SlatePOReceive) {
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        self.pagination = {
            total: 0,
            page: 1,
            count: 25
        };
        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlatePOReceive();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.pagination = {
                    total: parseInt(response.data.pagination.total),
                    page: parseInt(response.data.pagination.page),
                    count: parseInt(response.data.pagination.count),
                };
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

    }

    function ModelDetail($http, $q, SlatePOReceiveLineItem) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },
            {name: 'purchase_order'},
            {name: 'purchase_order_info', readonly: true},

            {name: 'status', readonly: true},

            {name: 'error_msg', readonly: true},

            {name: 'processed_sage', default: false},
            {name: 'processed_logistics', default: false},

            {name: 'variance_ack'},

            {name: 'date', type:"date"},
            {name: 'packing_slip_ref'},
            {name: 'customs_ref'},

            {name: 'warehouse'},
            {name: 'warehouse_info', readonly: true},
            {name: 'warehouse_parent_type', readonly: true},
            {name: 'warehouse_parent_fk', readonly: true},

            {name: 'line_items', type:'child_list', model: SlatePOReceiveLineItem},

            {name: 'created_by', readonly: true},
            {name: 'created', readonly: true},
            {name: 'updated', readonly: true},
            {name: 'deleted', readonly: true, default: false},

        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
                if(field.type=="child_list") {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                if(!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if(field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if(field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch(e) {
                    }
                }
                if(field.type == 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach(function(item, idx, array) {
                            var obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch(e) {
                        console.log('error loading: ', e);
                    }
                }

            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];

                return self.loadFromPayload(response.data.payload).then(function() {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            angular.forEach(fields, function(field) {
                if (!field.readonly && typeof(self[field.name]) != 'undefined') {
                    data[field.name] = self[field.name];
                    if(data[field.name] && field.type == "date") {
                        data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth()+1) + '-' + self[field.name].getUTCDate();
                    }
                    if(field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if(field.type == "child_list" && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach(function(item) {
                            data[field.name].push(item.getModelData());
                        });
                    }
                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }



    }

})();
