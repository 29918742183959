(function() {
    "use strict";

    angular
        .module('slate.library.salesorders')
        .factory('SlateSOReleases', ModelList)
        .factory('SlateSORelease', ModelDetail);

    var apiUrl = API_URL + 'salesorders/lineitem/';

    function ModelList($http, $q, SlateSOLineItem) {
        // Set to url relative to api root.

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlateSOLineItem();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

    }

    function ModelDetail($http, $q, SlateBOMItems) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', readonly: true },
            {name: 'salesorder_lineitem'},
            {name: 'status'},
            {name: 'qty'},
            {name: 'date', type:"dateOnly"},

            {name: 'created', readonly: true},
            {name: 'updated', readonly: true},
        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.getModelData = getModelData;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.setFromProj = setFromProj;
        model.prototype.getCostTotal = getCostTotal;
        model.prototype.getBOMCostTotal = getBOMCostTotal;
        model.prototype.getBOMPrice = getBOMPrice;
        model.prototype.getSellTotal = getSellTotal;
        model.prototype.getBoms = getBoms;
        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = _.cloneDeep(field.def);
                if(field.type=="date" && !self[field.name]) {
                    self[field.name] = new Date();
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                if(!payload[field.name]) {
                    return false;
                }

                self[field.name] = _.cloneDeep(payload[field.name]);

                if(field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type == 'dateOnly' && payload[field.name]) {
                    if(typeof payload[field.name] == 'string') {
                        var parts = self[field.name].split('-');
                        self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
                    }
                }

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }
                if (field.type == 'int') {
                    self[field.name] = parseInt(self[field.name]);
                }


                if(field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch(e) {
                    }
                }

            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];

                return self.loadFromPayload(response.data.payload).then(function() {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            var self = this;
            var data = {};

            angular.forEach(fields, function(field) {
               if ((!field.readonly || field.name == 'id') && typeof(self[field.name]) != 'undefined') {
                    data[field.name] = self[field.name];
                    if(field.type == "date") {
                        data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth()+1) + '-' + self[field.name].getUTCDate();
                    }
                    if(field.type == "dateOnly" && self[field.name]) {
                        data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth()+1) + '-' + self[field.name].getDate();
                    }
                    if(field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            data = getModelData();

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

        function setFromProj(proj) {
            /* jshint validthis: true */
            var self = this;
            // self.jansy_pn = proj.composite_num;
            // bself.name = proj.name;

        }

        function getCostTotal() {
            /* jshint validthis: true */
            var self = this;
            var total = 0;
            var qty;
            var cost;
            var freight;

            try { cost = new Big(self.unitprice); } catch(e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch(e) { qty = new Big(0); }

            total = qty.times(cost);

            return total.toFixed(5);
        }

        function getBOMCostTotal() {
            /* jshint validthis: true */
            var self = this;
            var total = 0;
            var qty;
            var cost;
            var freight;

            try { cost = new Big(self.bomTotal); } catch(e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch(e) { qty = new Big(0); }

            total = qty.times(cost);

            return total.toFixed(5);
        }


        function getSellTotal() {
            /* jshint validthis: true */
            var self = this;
            var total = 0;
            var qty;
            var cost;
            var freight;

            try { cost = new Big(self.sellprice); } catch(e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch(e) { qty = new Big(0); }

            total = qty.times(cost);

            return total.toFixed(5);
        }

        function getBOMPrice(bomItemUnitCosts) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var bomItems = new SlateBOMItems();
            var total = new Big(0.0);
            return bomItems.getList({bom: self.bom}).then(function() {
                bomItems.list.forEach(function(item) {
                    var qty_req = Big(item.qty_required);
                    var scrap = Big(item.scrap_factor).div(100);
                    var scrap_count = scrap.times(qty_req);
                    qty_req = qty_req.add(scrap_count);

                    total = total.add(Big(parseFloat(bomItemUnitCosts[item.id])).times(qty_req));
                });
                self.bomTotal = total.toFixed(5);
            });
        }

        function getBoms() {
            /* jshint validthis: true */
            var self = this;

            if(!self.bom_items) {
                self.bom_items = new SlateBOMItems();
            }
            return self.bom_items.getList({bom: self.bom});
        }

    }

})();
