(function () {
    'use strict';

    angular
        .module('slate.library.synergy')
        .factory('SlateSynergyEmails', ModelList)
        .factory('SlateSynergyEmail', ModelDetail);

    const apiUrl = `${API_URL}synergy/email/`;

    function ModelList($http, $q, SlateSynergyEmail) {
        // Set to url relative to api root.

        // instantiate our initial object
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            const promise = $http.get(apiUrl, {
                params: self.filters,
                timeout: self.canceller.promise
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateSynergyEmail();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;
                self.pagination = {
                    total: parseInt(response.data.pagination.total, 10),
                    page: parseInt(response.data.pagination.page, 10),
                    count: parseInt(response.data.pagination.count, 10)
                };
                self.canceller = null;

                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', def: null, readonly: true },

            { name: 'is_spam', def: false },
            { name: 'is_completed', def: false },
            { name: 'can_we_help', def: '' },

            { name: 'internal_contact' },
            { name: 'internal_contact_info', readonly: true },

            { name: 'categories', type: 'json', def: '[]' },

            { name: 'request_type', def: '' },
            { name: 'projects', type: 'json', def: '[]' },

            { name: 'date', type: 'date' },
            { name: 'sender' },
            { name: 'subject' },
            { name: 'body' },

            // {name: 'attachments', type:'child_list', model: SlateInvoiceLineItem},

            { name: 'created', readonly: true },
            { name: 'updated', readonly: true }
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.getModelData = getModelData;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;

                if (field.type === 'json') {
                    console.log(field.name, field.def);
                    try {
                        self[field.name] = JSON.parse(field.def);
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.log('didnt parse json', e);
                    }
                }

                if (field.type === 'child_list') {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                self[field.name] = payload[field.name];

                if (field.type === 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if (field.type === 'json' && self[field.name] && typeof self[field.name] === 'string') {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.log('didnt parse json', e);
                    }
                }

                if (field.type === 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach((item, idx, array) => {
                            const obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch (e) {
                        console.log('error loading: ', e);
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                console.log('Tried to load invoice email from server without id');
                return $q.reject(self);
            }

            const promise = $http.get(modelUrl);

            promise.then((response) => self.loadFromPayload(response.data.payload).then(() => {
                self.permissions = response.data.permissions;
                deferred.resolve(self);
            }));

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            const self = this;
            const data = {};

            angular.forEach(fields, (field) => {
                if ((!field.readonly || field.name === 'id') && typeof (self[field.name]) !== 'undefined') {
                    data[field.name] = self[field.name];

                    if (field.type === 'date' && self[field.name]) {
                        self[field.name] = new Date(self[field.name]);
                    }

                    if (field.type === 'child_list' && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach((item) => {
                            if (item.toDelete) { return false; }
                            data[field.name].push(item.getModelData());
                            return true;
                        });
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            let modelUrl = apiUrl;

            let promise;
            let data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            }

            data = self.getModelData();

            promise = $http.post(modelUrl, data);
            promise = promise.then((response) => {
                const promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            const promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }
})();
