(function () {
    'use strict';

    angular
        .module('slate.companies')
        .directive('slateCompanySearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/companies/companies.search.dir.html',
            scope: {
                company: '=',
                companyName: '=?',
                vendor: '=?',
                approvedVendor: '=?',
                hideOnHold: '=?',
                excludes: '=?',
                caps: '=?',
                companyObj: '=?',
                readonly: '<?',
                placeholder: '=?',
                limitTo: '=?',
                sales_mgr: '=?'
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function directiveController($scope, $state, $location, $translate, $q, getLinkSrv, SlateCompanies, SlateCompany) {
        /* jshint validthis: true */
        const vm = this;
        let selectedCompany = '';

        vm.companies = new SlateCompanies();
        vm.vendors = new SlateCompanies();

        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.clear = clear;
        vm.translate = $translate;

        vm.selectVendor = selectVendor;


        if (vm.company) {
            const company = new SlateCompany();
            company.loadFromServer(vm.company).then(() => {
                vm.companyObj = company;
                vm.companyName = company.name;
                selectedCompany = company.id;
            });
        }
        if (!vm.company && vm.companyName) {
            const companyNameList = new SlateCompanies();
            companyNameList.getList({ q: vm.companyName }).then(() => {
                if (companyNameList.list.length === 1) {
                    vm.companyObj = companyNameList.list[0];
                    vm.companyName = companyNameList.list[0].name;
                    selectedCompany = companyNameList.list[0].id;
                    vm.company = selectedCompany;
                }
            });
        }
        $scope.$watch('vm.company', () => {
            if (selectedCompany !== vm.company && vm.company) {
                const company = new SlateCompany();
                company.loadFromServer(vm.company).then(() => {
                    vm.companyObj = company;
                    vm.companyName = company.name;
                    selectedCompany = company.id;
                });
            }
        });

        if (vm.caps || vm.excludes || vm.limitTo) { get_vendors(); }
        $scope.$watch('vm.caps', () => {
            get_vendors();
        });
        $scope.$watch('vm.excludes', () => {
            get_vendors();
        });

        $scope.$watch('vm.sales_mgr', () => {
            getSearch();
        });

        function getSearch(q) {
            const filters = { q };
            if (!q || q.length < 1) { return $q.reject([]); }

            if (vm.excludes) { filters.exclude = vm.excludes; }

            if (typeof vm.vendor !== 'undefined') {
                filters.vendor = vm.vendor;
                filters.qualified = vm.vendor;
            }
            if (vm.approvedVendor) {
                filters.vendor = true;
                filters.qualified = true;
            }
            if (vm.hideOnHold) {
                filters.hide_on_hold = true;
            }
            if (vm.sales_mgr) {
                filters.sales_mgr = vm.sales_mgr;
            }
            filters.fields = ['id', 'slug', 'name', 'brands'];
            return vm.companies.getList(filters).then((response) => vm.companies.list);
        }

        function selectSearch($item, $model, $label, $event) {
            vm.searching = false;
            vm.company = $item.id;
            selectedCompany = $item.id;
            vm.companyName = $item.name;
        }

        function clear() {
            if (vm.readonly) { return false; }
            vm.company = null;
            vm.companyObj = null;
            vm.companyName = null;
            return true;
        }


        function get_vendors() {
            const filters = { vendor: true, qualified: true, preferred: true };
            if ((Array.isArray(vm.limitTo) && vm.limitTo.length) || vm.caps || vm.excludes) {
                if (typeof vm.caps === 'string' && vm.caps.length) {
                    filters.caps = vm.caps;
                }
                if (vm.excludes) { filters.exclude = vm.excludes; }

                if (vm.limitTo && Array.isArray(vm.limitTo) && vm.limitTo.length) {
                    filters.limitto = vm.limitTo;
                }
                return vm.vendors.getList(filters).then((response) => vm.vendors.list);
            }
            vm.vendors.list = [];
            return null;
        }

        $scope.$watch('vm.limitTo', () => {
            get_vendors();
        });

        function selectVendor(vendor) {
            vm.searching = false;
            vm.company = vendor.id;
            vm.companyObj = vendor;
            vm.companyName = vendor.name;
        }
    }
})();
