(function () {
    "use strict";

    angular
        .module('slate.logistics')
        .directive('slatePoBuildLogisticsList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/logistics/movements/builds.list.dir.html',
            scope: {
                modelFilters: '=filters',
                errors: '=?',
                buildCount: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $window, $q, $http, SlateAuth, getLinkSrv, SlateBuildGoods) {
        /* jshint validthis: true */
        var vm = this;

        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.updateFromFilter = updateFromFilter;
        vm.deleteItem = deleteItem;

        vm.filters = {
            page: 1,
            count: 25,
        };

        angular.extend(vm.filters, {

        }, vm.modelFilters, $location.search());

        if(vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if(vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }
        if(vm.filters.startDate) { vm.start = new Date(vm.filters.startDate); }
        if(vm.filters.endDate) { vm.end = new Date(vm.filters.endDate); }

        vm.itemList = new SlateBuildGoods();

        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            updateFromFilter();
        });


        function updateFromFilter() {
            var modelPromise;

            vm.loading = true;

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = vm.itemList.getList(vm.filters);
            modelPromise.then(function () {
                vm.loading = false;
                vm.resending = false;
                vm.buildCount = vm.itemList.list.length;
                if(vm.itemList.list.some(function(item) {
                    return item.status == 'error';
                })) {
                    vm.errors = true;
                } else {
                    vm.errors = false;
                }
            });
            
        }

        function deleteItem(item) {
            if(!vm.auth.perms.logistics_can_delete_movement)  {
                return false;
            }
            
            if(!window.confirm('Are you sure you want to delete this Build?'))  {
                return false;
            }

            item.deleteModel().then(function() {
                updateFromFilter();
            });
        }

        vm.resend_type = 'buildfinishedgood';
        vm.resend = resend;
        function resend(id) {
            var url = API_URL + 'logistics/resend-movement/';
            if(vm.resending) { return false; }
            vm.resending = id;
            return $http.post(url, {
                movement_type: vm.resend_type,
                movement_id: id,
            }).then(function() {
                vm.resending = false;
                setTimeout(updateFromFilter, 300);
            }, function() {
                vm.resending = false;
                setTimeout(updateFromFilter, 300);
            });
        }
    }


})();
