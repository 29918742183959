(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .controller('SlateRfqEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateRFQ) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.rfq = new SlateRFQ();
        vm.rfq.loadFromServer($stateParams.id).then(function () {
            if (vm.rfq.name) {
                SlateTitle.set('Edit ' + vm.rfq.name + ' - RFQs');
            } else {
                SlateTitle.set('Edit Untitled RFQ - RFQs');
            }
            vm.loading = false;
        });

        $scope.$watch('vm.rfq.name', function () {
            if (vm.rfq.name) {
                SlateTitle.set('Edit ' + vm.rfq.name + ' - RFQs');
            } else {
                SlateTitle.set('Edit Untitled RFQ - RFQs');
            }
        });

    }

})();
