(function () {
    'use strict';

    angular
        .module('slate.projects')
        .service('SlateProjectEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateProject, SlateCompany) {
        const templateUrl = '/partials/projects/project.edit.dialog.html';

        const srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj, parent_type, parent_fk, prefix) {
            const modalInstance = $uibModal.open({
                templateUrl,
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj() {
                        if (!obj.id) {
                            if (parent_type) {
                                obj.parent_type = parent_type;
                            } else {
                                obj.parent_type = 'company';
                            }

                            if (parent_fk) {
                                obj.parent_fk = parent_fk;
                            }

                            if (prefix) {
                                obj.prefix = prefix;
                            }
                            return obj;
                        }
                        const co = new SlateProject();
                        return co.loadFromServer(obj.id);
                    },
                    base_parent_fk() {
                        return parent_fk;
                    },
                    base_parent_type() {
                        return parent_type;
                    },
                    parent_proj() {
                        let parent_proj = {};
                        if (parent_type === 'project' && parent_fk) {
                            parent_proj = new SlateProject();
                            return parent_proj.loadFromServer(parent_fk);
                        }
                        return parent_proj;
                    }
                }
            });

            return modalInstance.result.then((results) => obj, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateSettings, SlateAuth, SlateCompanies, SlateCompany, SlateAssignedRoles, SlateRolesSrv, SlateProject, SlateOrders, SlateOrder, obj, base_parent_type, base_parent_fk, parent_proj) {
        /* jshint validthis: true */
        const vm = this;

        let parent_type = base_parent_type;
        let parent_fk = base_parent_fk;

        vm.obj = obj;
        vm.parent_proj = parent_proj;
        vm.auth = SlateAuth;

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.wizardControl = {};
        vm.toggleFinished = toggleFinished;
        vm.toggleStock = toggleStock;
        vm.canSave = canSave;

        vm.removeVendorItem = removeVendorItem;
        SlateSettings.getConfig('material_categories').then((config) => {
            vm.categories = config;
        });
        SlateSettings.getConfig('materials').then((config) => {
            vm.material_list = config;
            getMatList();
        });
        vm.materials = [];

        vm.units = [
            'ea',
            'box',
            'bottle',
            'g',
            'kg',
            'oz',
            'liter',
            'awesomeness'
        ];

        vm.projectTypes = ['Production',
            'Design',
            'Speculative',
            'Pricing',
            'Component',
            'Primary',
            'Secondary',
            'Gift Set',
            'Smart Stock',
            'Turn Key',
            'Protective Packaging'];

        vm.detailsIsComplete = detailsIsComplete;
        vm.additionalIsComplete = additionalIsComplete;
        vm.toggleNonInventory = toggleNonInventory;

        vm.companies = new SlateCompanies();
        if (parent_type !== 'project') {
            vm.companies.getList({ fields: ['id', 'slug', 'name'] });
        }

        vm.company = new SlateCompany();

        vm.assroles = new SlateAssignedRoles();
        vm.parent_assroles = new SlateAssignedRoles();

        if (!parent_type || !parent_fk) {
            parent_fk = vm.obj.parent_fk;
            parent_type = vm.obj.parent_type;
        }

        if (parent_type === 'project') {
            vm.parent = new SlateProject();
            vm.parent.loadFromServer(parent_fk).then(() => {
                if (!vm.obj.id) {
                    angular.forEach(['category',
                        'material',
                        'supplier',
                        'vendor',
                        'unit_name',
                        'scrap_factor',
                        'cbm'], (field) => {
                        vm.obj[field] = vm.parent[field];
                    });
                }
            });

            vm.parent_assroles.getList({
                parent_type,
                parent_fk
            }).then(() => {
                angular.forEach(vm.parent_assroles.list, (role) => {
                    vm.assroles.addBlank(
                        angular.extend({
                            parent_type: 'project',
                            parent_fk: vm.obj.id
                        }, angular.copy(role))
                    );
                });
            });
        }

        vm.froalaOptions = {
            zIndex: 2501
        };

        if (parent_type === 'company' || vm.obj.parent_type === 'company') {
            vm.company_fk = parent_fk;
            if (vm.obj.parent_type === 'company') {
                vm.company_fk = vm.obj.parent_fk;
            }
            vm.assroles.requiredRoles = ['Project Manager', 'Sales Manager']; // prev. 'Client'
        }

        if (vm.obj.id) {
            vm.assroles.getList({
                parent_type: 'project',
                parent_fk: vm.obj.id
            }).then(() => {
                if (!vm.assroles.list.length) {
                    angular.forEach(vm.assroles.requiredRoles, (rolename) => {
                        vm.assroles.addBlank({
                            parent_type: 'project',
                            parent_fk: vm.obj.id,
                            roles: [SlateRolesSrv.byName[rolename].id]
                        });
                    });
                }
            });
        } else {
            angular.forEach(vm.assroles.requiredRoles, (rolename) => {
                vm.assroles.addBlank({
                    parent_type: 'project',
                    parent_fk: vm.obj.id,
                    roles: [SlateRolesSrv.byName[rolename].id]
                });
            });
        }

        vm.addRole = addRole;

        function addRole() {
            vm.assroles.addBlank({
                parent_type: 'project',
                parent_fk: vm.obj.id
            });
        }

        function toggleFinished() {
            if (!vm.obj.stock_component) {
                vm.obj.finished_good = !vm.obj.finished_good;
            }
            if (!vm.obj.finished_good) {
                vm.obj.category = null;
            }
        }

        function canSave() {
            return true;
        }

        function toggleStock() {
            if (!vm.obj.finished_good) {
                vm.obj.stock_component = !vm.obj.stock_component;
            }
        }

        function saveObject(skip) {
            if (vm.saving === true) {
                return false;
            }
            vm.saving = true;

            return vm.obj.saveModel().then(() => {
                const promises = [];

                promises.push(
                    vm.assroles.saveAll({
                        parent_type: 'project',
                        parent_fk: vm.obj.id
                    })
                );


                $q.all(promises).then(() => {
                    vm.saving = false;
                    vm.obj.skip = skip;
                    $uibModalInstance.close(vm.obj);
                }, () => {
                    vm.saving = false;
                    vm.errorSaving = true;
                });
            }, () => {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }


        function detailsIsComplete() {
            if (!vm.obj.name || !vm.obj.parent_fk) {
                return false;
            }

            if (vm.obj.parent_type === 'company') {
                return true;
            }

            if (vm.parent.project_nomenclature === 'project' && !vm.obj.category) {
                return false;
            }

            if ((vm.obj.variation_number || vm.parent.project_nomenclature === 'component') && !vm.obj.material) {
                return false;
            }

            return true;
        }

        function additionalIsComplete() {
            if ((vm.parent.project_nomenclature === 'project' || vm.parent.project_nomenclature === 'component') && !vm.obj.finished_good && !vm.obj.supplier) {
                return false;
            }

            return true;
        }

        function removeVendorItem(idx) {
            if (!window.confirm('Are you sure you want to remove this vendor item?')) {
                return false;
            }
            return vm.obj.vendor_item_nums.splice(idx, 1);
        }

        $scope.$watch('vm.obj.finished_good', () => {
            if (vm.obj.finished_good) {
                vm.obj.category = 'Finished Good';
                vm.obj.non_inventory = false;
            }
        });

        $scope.$watch('vm.obj.category', () => {
            getMatList();
            if (vm.obj.category === 'Freight' || vm.obj.category === 'Labor' || vm.obj.category === 'Tooling' || vm.obj.category === 'Samples' || vm.obj.category === 'Other') {
                vm.obj.non_inventory = true;
            }
        });

        function toggleNonInventory() {
            if (vm.obj.category === 'Freight' || vm.obj.category === 'Labor' || vm.obj.category === 'Tooling' || vm.obj.category === 'Samples' || vm.obj.category === 'Other') {
                vm.obj.non_inventory = true;
                return true;
            }

            vm.obj.non_inventory = !vm.obj.non_inventory;

            return null;
        }

        function getMatList() {
            const newlist = [];
            if (!vm.material_list || !vm.material_list.value) { return []; }
            angular.forEach(vm.material_list.value, (item) => {
                if (item.category === vm.obj.category) {
                    newlist.push(item);
                }
            });
            vm.materials = newlist;
            return newlist;
        }
    }
})();
