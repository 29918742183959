(function () {
    'use strict';

    angular
        .module('slate.contacts')
        .directive('slateContactSearchbox', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/contacts/contact.searchbox.dir.html',
            scope: {
                contact: '=',
                staff: '=?',
                company: '=?',
                slateRole: '=?',
                readonly: '<?',
                placeholder: '<?',
                showDropdown: '=?',
                contactObj: '=?'
            },
            controller: directiveController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    function directiveController($scope, $state, $location, $q, getLinkSrv, SlateContact, SlateContacts) {
        /* jshint validthis: true */
        const vm = this;
        let modelPromise;
        let selectedContact = '';

        vm.contacts = new SlateContacts();
        vm.roleStaff = new SlateContacts();

        vm.getSearch = getSearch;
        vm.selectSearch = selectSearch;
        vm.selectStaff = selectStaff;
        vm.clear = clear;

        if (vm.contact) {
            const contact = new SlateContact();
            contact.loadFromServer(vm.contact, true, true).then(() => {
                vm.contactObj = contact;
                selectedContact = contact.id;
            });
        }

        function getDropdown() {
            const filters = {};
            if (vm.slateRole && vm.staff) {
                filters.role = vm.slateRole;
                filters.staff = vm.staff;
            }
            if (vm.company && vm.showDropdown) {
                filters.company = vm.company;
            }
            if (!Object.keys(filters).length) { return false; }
            vm.roleStaff.getList(filters, true);
        }

        $scope.$watch('vm.slateRole', () => { getDropdown(); });
        $scope.$watch('vm.company', () => { getDropdown(); });

        function getSearch(q) {
            let promise;
            if (q && q.length < 1) { return $q.reject([]); }
            promise = vm.contacts.getList({ q, staff: vm.staff, company: vm.company }, true).then((response) => vm.contacts.list);
            return promise;
        }

        function selectSearch($item, $model, $label, $event) {
            vm.searching = false;
            selectedContact = $item.id;
            vm.contact = $item.id;
        }

        function clear() {
            if (vm.readonly) { return true; }
            vm.contact = null;
            vm.contactObj = null;
        }

        function selectStaff(contact) {
            vm.searching = false;
            vm.contactObj = contact;
            selectedContact = contact.id;
            vm.contact = contact.id;
        }

        $scope.$watch('vm.contact', () => {
            if (selectedContact != vm.contact && vm.contact) {
                const contact = new SlateContact();
                contact.loadFromServer(vm.contact, true, true).then(() => {
                    vm.contactObj = contact;
                    selectedContact = contact.id;
                });
            }
        });
    }
})();
