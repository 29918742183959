(function () {
    'use strict';

    angular
        .module('slate.app')
        .run(slateStartup)
        .service('SlateServicesStartup', ServicesStartup);

    function slateStartup($rootScope, $state, $window, $q, $location, $urlRouter, ngToast, SlateTitle, SlateAuth, SlateServicesStartup, SidebarSrv, $cacheFactory) {
        $cacheFactory('slate-contacts');

        $rootScope.$on(
            '$stateChangeStart',
            (event, toState, toParams, fromState, fromParams) => {
                // event.preventDefault();
                SidebarSrv.closeall();

                const fromRootState = fromState.name.split('.')[0];
                const toRootState = toState.name.split('.')[0];

                if (fromRootState && fromRootState !== toRootState) {
                    $window.scroll({ top: 0 });
                }

                if (toState.redirectTo) {
                    event.preventDefault();
                    return $state.go(toState.redirectTo, toParams, { location: 'replace' });
                }

                if (toState.name.indexOf('.') === -1) {
                    SlateTitle.set(toState.slateTitle);
                }

                // If the user is not logged in, and the target route doesn't need auth
                // push to the login page.
                if (!SlateAuth.user && !toState.noAuth) {
                    // if (next === '/login/' || next === '/' || next === '%2F') {
                    console.log(`next url is: ${sessionStorage.getItem('next_url')}`);
                    if (!sessionStorage.getItem('next_url') && !$location.url().startsWith('/login')) {
                        console.log(`setting to ${$location.url()}`);
                        sessionStorage.setItem('next_url', $location.url());
                    }
                    if (!$location.url().startsWith('/login')) {
                        $location.url('/login/');
                    }
                }

                return true;
            }
        );


        $rootScope.$on(
            '$locationChangeSuccess',
            (e) => {
                let authpromise;

                if (SlateAuth.user && $rootScope.startup_complete) return;
                e.preventDefault();

                if (!SlateAuth.user) {
                    authpromise = SlateAuth.loadToken();
                    authpromise.then(() => {
                        SlateServicesStartup.go().then(() => {
                            $rootScope.startup_complete = true;
                            $urlRouter.sync();
                        });
                    }, () => {
                        $urlRouter.sync();
                        return $q.reject();
                    });
                } else {
                    SlateServicesStartup.go().then(() => {
                        $rootScope.startup_complete = true;
                        $urlRouter.sync();
                    });
                }
            }
        );

        if (!$window.localStorage.slateToken) {
            console.log(`next url is: ${sessionStorage.getItem('next_url')}`, $location.url().startsWith('/login'));
            if (!sessionStorage.getItem('next_url') && !$location.url().startsWith('/login')) {
                sessionStorage.setItem('next_url', $location.url());
            }
            if (!$location.url().startsWith('/login')) {
                $location.url('/login/');
            }
        }

        // Configures $urlRouter's listener *after* your custom listener
        $urlRouter.listen();
    }

    function ServicesStartup($rootScope, $q, SlateAuth, SlateRolesSrv, SlateTimeZonesSrv, SlateAccountPrdSrv) {
        const srv = { go };

        return srv;

        function go() {
            $rootScope.availRoles = SlateRolesSrv;
            $rootScope.auth = SlateAuth;
            $rootScope.timezones = SlateTimeZonesSrv;
            $rootScope.acctPrds = SlateAccountPrdSrv;

            srv.promises = [];
            srv.promises.push($rootScope.availRoles.getList());
            srv.promises.push($rootScope.timezones.getList());
            srv.promises.push($rootScope.acctPrds.getList());

            return $q.all(srv.promises);
        }
    }
})();
