(function() {
    "use strict";

    angular
        .module('slate.library.locations')
        .service('SlateZipFinder', SlateService);


    function SlateService($http, $q) {

        var srv = {
            findzip: findzip,
            cache: {}
        };

        return srv;

        function findzip(zip, countrycode) {
            var url = "/zip/" + countrycode + "/" + zip;
            var promise;
            if(srv.cache[zip]) {
                return $q.resolve(srv.cache[zip]);
            }

            promise = $http.get(url).then(function(response) {
                srv.cache[zip] = response.data;
                return response.data;
            }, function() {
                return {};
            });

            return promise;
        }
    }

})();
