(function () {
    "use strict";

    angular
        .module('slate.library.presentations')
        .factory('SlatePresPhotos', ModelList)
        .factory('SlatePresPhoto', ModelDetail);

    var apiUrl = API_URL + 'presentations/presphotos/';

    function ModelList($http, $q, SlatePresPhoto) {

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;
        list.prototype.addBlank = addBlank;
        list.prototype.saveAll = saveAll;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var promise;

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then(function (response) {
                var newlist = [];

                //set this for correct model
                angular.forEach(response.data.payload, function (item) {
                    var itemModel = new SlatePresPhoto();

                    itemModel.loadFromPayload(item);
                    newlist.push(itemModel);
                });
                self.list = newlist;
                self.permissions = response.data.permissions;

                return self;
            });

            return promise;

        }

        function addBlank() {
            /* jshint validthis: true */
            var self = this;
            var newModel = new SlatePresPhoto();
            self.list.push(newModel);
            return newModel;
        }

        function saveAll(defaults) {
            /* jshint validthis: true */
            var self = this;
            var promises = [];

            angular.forEach(self.list, function (item) {
                if (typeof defaults == 'object') {
                    item.loadFromPayload(defaults);
                }

                if (!item.toDelete) {
                    promises.push(item.saveModel());
                } else {
                    promises.push(item.deleteModel());
                }


            });

            return $q.all(promises).then(function () {

                angular.forEach(self.list, function (item) {
                    var index;
                    if (!item.toDelete) {
                        index = self.list.indexOf(item);
                        self.list.splice(index, 1);
                    }
                });
            });
        }
    }





    function ModelDetail($http, $q, Upload, SlateTags) {

        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        // var fields = [
        //     {name: 'id', def: null, readonly: true },
        //     {name: 'company', def: null},
        //     {name: 'company_name', def: null, readonly: true},
        //     {name: 'project', def: null},
        //     {name: 'project_name', def: null, readonly: true},
        //     {name: 'filename', def: null},
        //     {name: 'image_type', def: null},
        //     {name: 'original', def: null, readonly: true},
        //     {name: 'thumb', def: '/img/placeholders/project.png', readonly: true},
        //     {name: 'base_url', def: null, readonly: true},
        //     {name: 'created', def: null, readonly: true},
        //     {name: 'progress', def: 0,readonly: true},
        // ];

        var fields = [
            { name: 'id', def: null, readonly: true },
            { name: 'company', def: null },
            { name: 'company_name', def: null, readonly: true },
            { name: 'project', def: null },
            { name: 'project_name', def: null, readonly: true },
            { name: 'confidential', def: false },
            { name: 'tags', def: [], type: 'json'},
            { name: 'cats', def: [], type: 'json'},
            { name: 'filename', def: null },
            { name: 'image_type', def: null },
            { name: 'full', def: null, readonly: true },
            { name: 'thumb', def: '/img/placeholders/project.png', readonly: true },
            { name: 'base_url', def: null, readonly: true },
            { name: 'created', def: null, readonly: true },
            { name: 'progress', def: 0, readonly: true },
        ];

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function (field) {
                self[field.name] = field.def;
            });

            deferred.resolve(self);

            return deferred.promise;
        }



        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function (field) {
                self[field.name] = payload[field.name];
                if (field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {}
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(type, id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl;

            var promise;
            var filter = {};

            if (!self.id && !id) {
                console.log('Tried to load from server without id');
                return $q.reject(self);
            }

            filter[type] = id;

            promise = $http.get(modelUrl, {
                params: filter
            });

            promise.then(function (response) {
                var promises = [];

                return self.loadFromPayload(response.data).then(function () {
                    self.permissions = response.data.permissions;

                    return $q.when(promises).then(function () {

                        deferred.resolve(self);
                    });

                });
            });

            return deferred.promise;
        }

        function saveModel(file) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            angular.forEach(fields, function (field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });
            if (file) {
                data.file = file;
                promise = Upload.upload({
                    url: modelUrl,
                    data: data,
                    arrayKey: '[]',
                }).then(function (resp) {
                    self.progress = 100;
                    self.loadFromPayload(resp.data.payload);
                    deferred.resolve(self);
                }, function (resp) {

                }, function (evt) {
                    self.progress = parseInt(100.0 * evt.loaded / evt.total);
                    file.progress = parseInt(100.0 * evt.loaded / evt.total);
                });
            } else {
                promise = $http.post(modelUrl, data);
                promise = promise.then(function (response) {
                    self.loadFromPayload(response.data.payload);
                    self.permissions = response.data.permissions;
                });

            }

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function (response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

    }

})();
