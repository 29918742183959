(function () {
    "use strict";

    angular
        .module('slate.presentations')
        .directive('slatePresPhotoEditForm', slatePresPhotoEditForm);

    function slatePresPhotoEditForm() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/presentations/presphoto.form.dir.html',
            scope: {
                obj: '=?defaults',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ListController($scope, $http, SlateSettings, SlateTags, SlatePresCategories, SlateCompanies, SlateProjects) {
        /* jshint validthis: true */
        var vm = this;

        if (!vm.obj) { vm.obj = {}; }

        vm.tags = SlateTags;
        vm.tags.addTags(vm.obj.tags);
        vm.cats = SlatePresCategories;

        vm.getTags = getTags;

        vm.selected_cats = {};

        vm.companies = new SlateCompanies();
        vm.companies.getList();

        vm.projects = new SlateProjects();

        SlateSettings.getConfig('material_categories').then(function(config) {
            vm.categories = config;
            console.log(vm.categories);
        });
        SlateSettings.getConfig('materials').then(function(config) {
            vm.materials = config;
            console.log(vm.materials);
        });

        $scope.$watch('vm.obj.project', function () {
            if (vm.obj.project) {
                var promise;

                promise = $http.get(API_URL + 'projects/project/' + vm.obj.project + '/tags/');
                promise.then(function(response) {
                    var tags = response.data.payload;
                    if(!Array.isArray(vm.obj.tags)) { vm.obj.tags = []; }
                    tags.forEach(function(item) {
                        if(!vm.obj.tags.includes(item)) {
                            console.log(item);
                            vm.tags.list.push({group: 'Project Tags', name: item});
                            vm.obj.tags.push(item);
                        }
                    });
                });
            }
        });


        $scope.$watch(function () { return vm.selected_cats; }, function () {
            var cats = [];
            angular.forEach(vm.selected_cats, function (group, key) {
                angular.forEach(group, function (value, key) {
                    if (value) {
                        cats.push(key);
                    }
                });
            });
            vm.obj.cats = cats;
        }, true);

        vm.cats.promise.then(function () {
            angular.forEach(vm.cats.list, function (cat) {
                if (typeof vm.selected_cats[cat.group] == "undefined") {
                    vm.selected_cats[cat.group] = {};
                }
                if (vm.obj.cats && vm.obj.cats.indexOf(cat.name) !== -1) {
                    vm.selected_cats[cat.group][cat.name] = true;
                } else {
                    vm.selected_cats[cat.group][cat.name] = false;
                }

            });
        });

        function findWithAttr(array, attr, value) {
            for (var i = 0; i < array.length; i += 1) {
                if (array[i][attr] === value) {
                    return i;
                }
            }
            return -1;
        }

        var searchTags = [];
        var searchTagsTerm = "";

        function getTags(search) {
            var newTags = [];
            var found = false;
            
            if(!search) { 
                return vm.tags.list; 
            }
            if(search == searchTagsTerm) { return searchTags; }
            searchTagsTerm = search;
            angular.forEach(vm.tags.list, function(item) {
                if(item.name.search(new RegExp(search, "i")) > -1) { 
                    newTags.push(item);
                }
                if(item.name == search) { found=true; }
            });
            if(search && !found) {
                newTags.unshift({id: 'free-form-'+search, group: 'Entered Text', name: search });
            }
            searchTags = newTags;
            return searchTags;
        }
    }


})();
