(function() {
    "use strict";

    angular
        .module('slate.app')
        .service('SlateTitle', SlateTitle);

        function SlateTitle($rootScope) {
            var srv = {
                set: setTitle,
                subset: setSubTitle,
                title: "",
                sub: "",
                base: "Slate",
                seperator: " - ",
            };

            return srv;

            function setTitle(title) {
                var newTitle = srv.base;
                srv.title = title;
                srv.sub = "";
                if(srv.title) {
                    newTitle = srv.title + srv.seperator + srv.base;
                }
                document.title = newTitle;
            }

            function setSubTitle(title) {
                var newTitle = srv.base;
                srv.sub = title;
                if(srv.sub) {
                    newTitle = srv.title + srv.seperator + srv.sub + srv.seperator + srv.base;
                }
                document.title = newTitle;
            }


        }

})();
