(function () {
    "use strict";

    angular
        .module('slate.salesquotes')
        .directive('slateVendorEstimateEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/estimates/vendorestimates.edit.dir.html',
            scope: {
                estimate: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $window,
        $state,
        $http,
        getLinkSrv,
        SlateAuth,
        SlateCompany,
        SlateLocations,
        SlateProject,
        SlateAssignedRoles,
        SlateRolesSrv,
        SlateVendorEstimateResponses,
        SlateSalesEstimate
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.saveObject = saveObject;
        vm.uploadFiles = uploadFiles;
        vm.isComplete = isComplete;

        vm.generateSalesEstimate = generateSalesEstimate;

        vm.froalaOptions = {
            toolbarButtons: ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL", "|", "insertTable", ],
            zIndex: 10,
        };


        vm.vendor_responses = new SlateVendorEstimateResponses();

        vm.minDate = new Date(new Date().getTime()+(5*24*60*60*1000));
        vm.maxDate = new Date(new Date().getTime()+(90*24*60*60*1000));

        vm.availStatus = ['draft', 'ready', 'quoting', 'complete', 'cancelled', 'exception_hold'];
        vm.baseStatus = vm.estimate.status;

        vm.StatusAvailable = [vm.estimate.status];
        checkAvailStatus();

        function checkAvailStatus() {
            vm.StatusAvailable = [vm.baseStatus];
            if(vm.baseStatus == 'draft') { vm.StatusAvailable.push('ready', 'cancelled', 'exception_hold'); }
            if(vm.baseStatus == 'ready') { vm.StatusAvailable.push('complete', 'exception_hold'); }
            if(vm.baseStatus == 'exception_hold') { vm.StatusAvailable.push('draft'); }
            vm.StatusAvailable = _.cloneDeep(vm.availStatus);
        }

        $scope.$watch('vm.estimate.id', function (old_val, new_val) {
            if (vm.estimate.id && !vm.saving) { updateDependants(); }
        });

        function updateDependants() {
            if(vm.estimate.id) {
                vm.vendor_responses.getList({estimate: vm.estimate.id});
            }
        }

        function isComplete() {
            if(!vm.estimate.name) { return false; }
            if(vm.estimate.status == 'draft') { return true; }
            if(!vm.estimate.quote_due) { return false; }
            if(!vm.estimate.order) { return false; }
            if(!vm.estimate.sales_mgr) { return false; }
            if(!vm.estimate.proj_mgr) { return false; }
            if(!vm.estimate.request_details.length) { return false; }

            return true;
        }

        function saveObject() {
            var deferred = $q.defer();

            if (vm.saving === true) {
                return false;
            }

            vm.saving = true;
            vm.error = false;

            // apply sort order to lineitems so they don't rearrange them selves
            vm.estimate.saveModel().then(function () {
                var promises = [];
                if (vm.fileSrv) {
                    promises.push(vm.fileSrv.uploadFiles('vendorestimate', vm.estimate.id));
                }
                vm.vendor_responses.list.forEach(function(response) {
                    response.estimate = vm.estimate.id;
                    if(response.toDelete) {
                        if(response.status == 'draft') {
                            promises.push(response.deleteModel());
                        }
                    } else {
                        if(!response.id) {
                            promises.push(response.saveModel());
                        }
                    }
                });

                return $q.all(promises).then(function () {
                    vm.baseStatus = vm.estimate.status;
                    checkAvailStatus();
                    updateDependants();
                    $timeout(function () {
                        vm.saving = false;
                        vm.estimateFrom.$setPristine();
                        vm.fileSrv.files = [];
                        if ($state.current.name != 'vendorEstimateEditPage') {
                            $state.transitionTo(
                                'vendorEstimateEditPage',
                                {id: vm.estimate.id},
                                {location: 'replace'}
                            );
                        }
                    }, 300);

                }, function () {
                    vm.saving = false;
                    vm.error = true;
                });

            }, function () {
                vm.saving = false;
                vm.error = true;
            });

            return deferred.promise;
        }

        function uploadFiles() {
            if (vm.saving === true) {
                return false;
            }

            vm.saving = true;
            vm.error = false;

            var promises = [];
            if (vm.fileSrv) {
                promises.push(vm.fileSrv.uploadFiles('vendorestimate', vm.estimate.id));
            }

            return $q.all(promises).then(function () {
                    vm.saving = false;
                    vm.fileSrv.files = [];

            }, function () {
                vm.saving = false;
                vm.error = true;
            });
        }

        function generateSalesEstimate() {
            vm.saveing = true;
            var salesEstimate = new SlateSalesEstimate();
            ['project', 'order', 'sales_mrg', 'proj_mgr', 'name'].forEach(function(key) {
                salesEstimate[key] = _.cloneDeep(vm.estimate[key]);
            });
            salesEstimate.saveModel().then(function() {
                $state.transitionTo(
                    'salesEstimateEditPage',
                    {id: salesEstimate.id}
                );
            });
        }

        function projectSync() {
            var assroles = new SlateAssignedRoles();
            var project = new SlateProject();
            var role_dict = SlateRolesSrv.dict;
            assroles.getList({
                parent_type: 'project',
                parent_fk: vm.estimate.project
            }).then(function () {

                angular.forEach(assroles.list, function (item) {
                    if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Sales Manager' && !vm.estimate.sales_mgr) {
                        vm.estimate.sales_mgr = item.member_fk;
                    }
                    if (item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Project Manager' && !vm.estimate.proj_mgr) {
                        vm.estimate.proj_mgr = item.member_fk;
                    }
                });

            });
            if (!vm.estimate.company) {
                project.loadFromServer(vm.estimate.project).then(function () {
                    if (project.parent_type == 'company') {
                        vm.estimate.company = project.parent_fk;
                    }
                });
            }

        }
        $scope.$watch('vm.estimate.project', function () {
            if (vm.estimate.project) {
                projectSync();
            }
        });

    }

})();
