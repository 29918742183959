(function() {
    "use strict";

    angular
        .module('slate.contacts')
        .controller('SlateContactsDetailsPageController', SlateContactsDetailsPageController);


    function SlateContactsDetailsPageController($scope, $state, $location, SlateTitle, SlateSFLinkage, getLinkSrv, SlateContactEditDialog, contact) {
        /* jshint validthis: true */
        var vm = this;
        vm.contact = contact;
        vm.editContact = editContact;

        SlateTitle.set(vm.contact.full_name);

        vm.sf_linkage = SlateSFLinkage;

        $scope.$watch('vm.contact.id', function (old_val, new_val) {
            if (vm.contact.id && !vm.saving) {
                vm.sf_linkage.parent_type = 'contact';
                vm.sf_linkage.parent_fk = vm.contact.id;
            }
        });

        function editContact() {
            var promise;
            promise = SlateContactEditDialog.edit(contact);
            promise.then(function(newContact) {
                if (newContact.slug != contact.slug) {
                    $location.path(getLinkSrv.getUrl('contact', newContact.id, newContact.slug)).search({});
                } else {
                    $state.reload();
                }
            }, function() {

            });
            return promise;
        }

    }

})();
