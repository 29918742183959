(function () {
    "use strict";

    angular
        .module('slate.navbar')
        .directive('slateNavbarSidebar', dirObject);

    function dirObject() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/navbar/navbar.sidebar.dir.html',
            scope: {

            },
            controller: navbarController,
            controllerAs: 'vm',


        };
    }

    function navbarController($q, $interval, $translate, $location, $http, $state, getLinkSrv, SlateAuth, SlateTitle, SidebarSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.state = $state;
        vm.auth = SlateAuth;
        vm.sidebarsrv = SidebarSrv;


    }


})();
