(function () {
    'use strict';

    angular
        .module('slate.dashboard')
        .controller('SlateDashboardController', SlatePageController);

    function SlatePageController($scope, $timeout, $state, $location, $uibModal, SlateAuth, SlateDashboardEdit, contact) {
        /* jshint validthis: true */
        var vm = this;
        var timeout = null;

        if (!SlateAuth.perms.dashboard_can_see_staff_dashboard) {
            $state.go('contactDetails', { slug: SlateAuth.user.contact_slug }, {
                location: "replace"
            });
        }

        if (SlateAuth.user.homepage === 'vendors') {
            $state.go('vendorList', {}, { location: "replace" });
        }

        vm.contact = contact;
        vm.contact.saving_dashboard = true;

        vm.addWidget = addWidget;
        vm.editWidget = editWidget;
        vm.removeWidget = removeWidget;

        vm.setWallpaper = setWallpaper;

        vm.gridOptions = {
            floating: false,
            pushing: true,
            swapping: true,
            margins: [24, 24],
            outerMargin: false,
            resizable: {
                enabled: true,
                handles: ['s', 'e'],
            },
            draggable: {
                enabled: true, // whether dragging items is supported
                handle: '.widget-header', // optional selector for resize handle
            }
        };

        vm.wallpapers = {
            'squares': 'Squares',
            'beach': 'Beach',
            'rain': 'Rain',
            'galaxy': 'Galaxy',
            'abstract': 'Abstract',
            'moon': "Moon",
            'spooky': "Spooky",
            'hypno': "Hypnotic",
            'argyle': "Argyle",
            'tartan': "Tartan",
            'grizzly': 'Operations Mascot',
            'wales': "Flag of Wales",
            'england': "Flag of England"
        };

        if (typeof vm.contact.settings === 'object' && !vm.contact.settings.wallpaper) {
            vm.contact.settings.wallpaper = 'squares';
        }

        function removeWidget($index) {
            vm.contact.dashboard_config.splice($index, 1);
            vm.contact.saving_dashboard = true;
            vm.contact.locations.list = [];
            vm.contact.contactmethods.list = [];
            vm.contact.saveModel();
        }

        function addWidget() {
            var item = {
            };

            SlateDashboardEdit.edit(item).then(function (results) {
                vm.contact.dashboard_config.push(results);
                vm.contact.saving_dashboard = true;
                vm.contact.locations.list = [];
                vm.contact.contactmethods.list = [];
                vm.contact.saveModel();
            });
        }

        function setWallpaper(papername) {
            vm.contact.settings.wallpaper = papername;
            vm.contact.saving_dashboard = true;
            vm.contact.locations.list = [];
            vm.contact.contactmethods.list = [];
            vm.contact.saveModel();
        }

        function editWidget($index) {
            SlateDashboardEdit.edit(vm.contact.dashboard_config[$index]).then(function (results) {
                angular.copy(results, vm.contact.dashboard_config[$index]);
                vm.contact.saving_dashboard = true;
                vm.contact.locations.list = [];
                vm.contact.contactmethods.list = [];
                vm.contact.saveModel();
            });
        }


        $scope.$watch('vm.contact.dashboard_config', function (items) {
            $timeout.cancel(timeout);
            timeout = $timeout(function () {
                vm.contact.saving_dashboard = true;
                vm.contact.locations.list = [];
                vm.contact.contactmethods.list = [];
                vm.contact.saveModel();
            }, 5000);

        }, true);
    }


})();
