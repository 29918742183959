(function() {
    "use strict";

    angular
        .module('slate.logistics')
        .service('SlateShipmentConfirmQty', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateAuth) {
        var templateUrl = '/partials/logistics/confirmations/confirm_qty.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj, baseStatus, forceShow) {
            var modalInstance;
            if(!forceShow) {
                if(obj.status == baseStatus) { return $q.resolve(); }
                if(!['intransit', 'delivered'].includes(obj.status)) { return $q.resolve(); }
                if(SlateAuth.user && SlateAuth.user.is_admin) { return $q.resolve(); }
            }

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    shipment: function() {
                        return obj;
                    },
                    status: function() {
                        return baseStatus;
                    },
                    forceshow: function() {
                        return forceShow;
                    }
                }
            });


            return modalInstance.result.then(function(results) {
                return results;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($uibModalInstance, $http, shipment, status, forceshow, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;

        vm.auth = SlateAuth;
        vm.shipment = shipment;
        vm.forceShow = forceshow;

        vm.cancel = cancel;
        vm.save = saveObject;
        vm.isComplete = isComplete;

        vm.showBom = showBom;

        vm.deliveryConfirm = '';

        vm.entries = {};

        if(shipment.status == status) { 
            $uibModalInstance.close();
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function saveObject() {
            $uibModalInstance.close();
            vm.saving = false;
            
        }

        function isComplete() {
            if(vm.shipment.goods.some(function(good) {
                if(typeof(vm.entries[good.id]) === 'undefined') { return true; }
                if(vm.entries[good.id] != good.qty) { return true; }
                if(vm.shipment.freight_handler == 'customer' && vm.shipment.origin_type == 'purchaseorder') { return false; }
                if(vm.shipment.status == 'delivered' && vm.shipment.freight_handler != 'customer' && !vm.auth.user.is_admin) {
                    if(good.instock < good.qty) { return true; }
                }
                if(vm.shipment.status == 'delivered' && vm.shipment.freight_handler == 'customer' && vm.shipment.origin_type != 'purchaseorder' && !vm.auth.user.is_admin) {
                    if(good.instock < good.qty) { return true; }
                }
                return false;
            })) { return false; }
            if(vm.shipment.status == 'delivered' && !vm.forceShow && !vm.addressConfirmed) { return false; }
            if(vm.shipment.status == 'delivered' && vm.deliveryConfirm != vm.shipment.delivery_type) { return false; }
            return true;
        }

        vm.showStock = showStock;
        function showStock() { 
            if(vm.forceShow) { return true; }
            if(vm.shipment.status == 'delivered') { return true; }
            if(vm.shipment.origin_type == 'stock') { return true; }
            return false;
        }

        function showBom(good) {
            if(!(good.component_info.finished_good || good.component_info.category == 'Finished Good')) { return false; }
            if(vm.shipment.buildCount && ['intransit', 'customs', 'port', 'delivered'].includes(vm.shipment.status) && vm.shipment.freight_handler != 'customer') { return false; }

            return true;
        }

        vm.getWarehouse = function getWarehouse(good) {
            if(vm.shipment.freight_handler == 'customer') { return good.origin_warehouse; }
            if(vm.shipment.freight_handler == 'vendor') { return good.origin_warehouse; }
            if(vm.shipment.status == 'delivered') { return vm.shipment.warehouse; }
            if(vm.shipment.origin_type == 'stock') { return good.origin_warehouse; }
        };

    }



})();
