(function() {
    "use strict";

    angular
        .module('slate.projects')
        .directive('slatePricingList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/projects/projects.pricing.list.dir.html',
            scope: {
                modelFilters: '=filters',
                projectNomenclature: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $q, $http, $window, SlateAuth, SlatePriceTierEdit, SlatePriceTier, SlatePriceTiers) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;
        vm.updateFromFilter = updateFromFilter;
        vm.newPriceTier = newPriceTier;
        vm.deleteObj = deleteObj;

        
        vm.loading = true;
        vm.loadingMore = false;
        vm.filters = {
            page: $location.search().page || 1,
            count: $location.search().count || 5,
        };

        angular.extend(vm.filters, vm.modelFilters);
        if(vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if(vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }

        vm.priceTiers = new SlatePriceTiers();

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        function updateFromFilter() {
            var modelPromise;

            vm.loading = true;

            angular.forEach(vm.filters, function(value, item) {
                if (vm.filters[item] && !vm.modelFilters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = vm.priceTiers.getList(vm.filters);
            modelPromise.then(function() {
                vm.loading = false;
            });
        }

        function newPriceTier() {
            var priceTier = new SlatePriceTier();
            var promise;

            priceTier.component = vm.modelFilters.component;
            priceTier.prices.push({quantity: 0, price: 0});

            promise = SlatePriceTierEdit.edit(priceTier, priceTier.component);
            promise.then(function(obj) {
                updateFromFilter();
            }, function() {

            });

            return promise;
        }

        function deleteObj(priceTier) {
            if (confirm('Are you sure you want to delete this pricing?')) {
                var apiUrl = API_URL + 'projects/pricing/' + priceTier.id + '/';
                $http.delete(apiUrl).then(function() {
                    updateFromFilter();
                }, function() {
    
                });
            }
        }

    }


})();
