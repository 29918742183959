(function () {
    "use strict";

    angular
        .module('slate.estimates')
        .controller('SlateVendorEstimateNewPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateVendorEstimate, SlateOrder, SlateRolesSrv,
        SlateAssignedRoles,
        SlateProject) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.estimate = new SlateVendorEstimate();
        vm.estimate.quote_due = new Date(new Date().getTime()+(30*24*60*60*1000));

        if($location.search().order && $location.search().order != undefined && $location.search().order != 'undefined') {
            vm.order = new SlateOrder();
            vm.order.loadFromServer($location.search().order).then(function() {
                vm.estimate.order = vm.order.id;
                vm.estimate.sales_mgr = vm.order.sales_mgr;
                vm.estimate.proj_mgr = vm.order.proj_mgr;
                vm.estimate.name = vm.order.name + " Estimate";
                vm.estimate.name = vm.estimate.name.substring(0,68);
                vm.loading = false;
            }, function() {
                vm.loading = false;
            });
        } else if($location.search().project && $location.search().project != undefined && $location.search().project != 'undefined') {
            vm.estimate.project = $location.search().project;
            vm.assroles = new SlateAssignedRoles();
            vm.project = new SlateProject();
            vm.role_dict = SlateRolesSrv.dict;
            vm.assroles.getList({
                parent_type: 'project',
                parent_fk: $location.search().project
            }).then(function () {
                angular.forEach(vm.assroles.list, function (item) {
                    if (item.member_type == 'contact' && vm.role_dict[item.roles[0]].name == 'Sales Manager' && !vm.estimate.sales_mgr) {
                        vm.estimate.sales_mgr = item.member_fk;
                    }
                    if (item.member_type == 'contact' && vm.role_dict[item.roles[0]].name == 'Project Manager' && !vm.estimate.proj_mgr) {
                        vm.estimate.proj_mgr = item.member_fk;
                    }
                });
                vm.project.loadFromServer(vm.estimate.project).then(function () {
                    if (vm.project.parent_type == 'company') {
                        vm.estimate.company = vm.project.parent_fk;
                    }
                    vm.loading = false;
                });
            });
        } else {
            vm.loading = false;
        }

        $scope.$watch('vm.estimate.name', function () {
            if (vm.estimate.name) {
                SlateTitle.set('Edit ' + vm.estimate.name + ' - Vendor Estimates');
            } else {
                SlateTitle.set('Edit Untitled Estimate - Vendor Estimates');
            }
        });

    }

})();
