(function () {
    'use strict';

    angular
        .module('slate.inspections')
        .service('SlateInspectionEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlatePurchaseOrder, SlateInspection) {
        const templateUrl = '/partials/inspections/inspection.edit.dialog.html';

        const srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj, purchaseOrderId) {
            let modalInstance;

            modalInstance = $uibModal.open({
                templateUrl,
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj() {
                        const newInspection = new SlateInspection();
                        if (!obj) {
                            return newInspection;
                        }
                        return newInspection.loadFromPayload(obj);
                    },
                    purchaseOrder() {
                        const purchaseOrder = new SlatePurchaseOrder();
                        return purchaseOrder.loadFromServer(purchaseOrderId);
                    }
                }
            });

            return modalInstance.result.then((results) => obj, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, getLinkSrv, SlateAuth, SlateInspectionLineItem, SlateInspectionResult, SlateFileNodeList, obj, purchaseOrder) {
        /* jshint validthis: true */
        const vm = this;

        vm.obj = obj;
        vm.purchaseOrder = purchaseOrder;
        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;

        vm.selectAllLineItems = selectAllLineItems;
        vm.getProjects = getProjects;
        vm.getComponents = getComponents;
        vm.getVariations = getVariations;
        vm.getFiles = getFiles;
        vm.getArtFiles = getArtFiles;
        vm.getAssemblyFiles = getAssemblyFiles;
        vm.getTechnicalFiles = getTechnicalFiles;
        vm.toggleFile = toggleFile;
        vm.detailsComplete = detailsComplete;
        vm.lineItemsComplete = lineItemsComplete;
        vm.next = next;

        vm.saving = false;
        vm.errorSaving = false;
        vm.isComplete = isComplete;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.step = 0;
        vm.fields = [
            'jansy_pn',
            'name',
            'qc_date',
            'order_qty',
            'qty'
        ];

        vm.minDate = new Date();

        vm.obj.purchase_order = vm.purchaseOrder.id;
        vm.obj.ship_date = vm.purchaseOrder.desired_date;

        vm.lineItems = vm.purchaseOrder.line_items.map((item) => {
            const lineItem = new SlateInspectionLineItem();
            lineItem.po_lineitem = item.id;
            lineItem.po_lineitem_info = item;
            lineItem.orderQty = item.qty;
            lineItem.qty = item.qty;
            lineItem.files = {
                'Assembly Files': [],
                'Technical Files': [],
                'Art Files': []
            };
            return lineItem;
        });

        function getFiles(project, path) {
            const files = new SlateFileNodeList();
            return files.getList({ parent_fk: project, parent_type: 'project', path });
        }

        function getComponents() {
            const seen = {};
            vm.components = vm.obj.line_items.reduce((prevValue, item) => {
                if (!seen[item.po_lineitem_info.parent_info.id]) {
                    seen[item.po_lineitem_info.parent_info.id] = true;
                    prevValue.push(item.po_lineitem_info.parent_info);
                }
                return prevValue;
            }, []);
        }
        // vm.components = getComponents();

        function getProjects() {
            const seen = {};
            vm.projects = vm.obj.line_items.reduce((prevValue, item) => {
                if (!seen[item.po_lineitem_info.project_info.id]) {
                    seen[item.po_lineitem_info.project_info.id] = true;
                    prevValue.push(item.po_lineitem_info.project_info);
                }
                return prevValue;
            }, []);
            // return vm.projects;
        }

        function getVariations() {
            const seen = {};
            vm.variations = vm.obj.line_items.reduce((prevValue, item) => {
                if (!seen[item.po_lineitem_info.component_info.id]) {
                    seen[item.po_lineitem_info.component_info.id] = true;
                    prevValue.push(item.po_lineitem_info.component_info);
                }
                return prevValue;
            }, []);
        }
        function getAssemblyFiles(lineItem) {
            if (!lineItem.assemblyFiles) {
                getFiles(lineItem.po_lineitem_info.project_info.id, 'assemblies').then((files) => {
                    lineItem.assemblyFiles = files.list;
                });
            }
        }

        function getTechnicalFiles(lineItem) {
            if (!lineItem.technicalFiles) {
                getFiles(lineItem.po_lineitem_info.parent_info.id, 'design-files').then((files) => {
                    lineItem.technicalFiles = files.list;
                });
            }
        }

        function getArtFiles(lineItem) {
            if (!lineItem.artFiles) {
                getFiles(lineItem.po_lineitem_info.component_info.id, 'design-files').then((files) => {
                    lineItem.artFiles = files.list;
                });
            }
        }

        function toggleFile(lineItem, file, dirName) {
            const dir = lineItem.files[dirName];
            if (file.selected) {
                dir.push(file.id);
            } else {
                const index = dir.indexOf(file.id);
                dir.splice(index, 1);
            }
        }

        function isComplete() {
            return true;
        }

        function saveObject() {
            if (vm.saving) {
                return false;
            }
            vm.saving = true;
            vm.error = false;

            vm.obj.ship_date = vm.purchaseOrder.desired_date;
            vm.obj.saveModel().then(() => {
                vm.saving = false;
                $uibModalInstance.close(vm.obj);
            }, () => {
                vm.saving = false;
                vm.error = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function selectAllLineItems() {
            vm.lineItems.forEach((item) => {
                item.selected = vm.selectAll;
            });
        }

        function detailsComplete() {
            if (!vm.obj.inspection_type) { return false; }
            return true;
        }

        function lineItemsComplete() {
            if (!vm.lineItems.some((item) => item.selected)) { return false; }
            return true;
        }

        function next() {
            vm.obj.line_items = vm.lineItems.filter((item) => item.selected);
            vm.step++;
        }
    }
})();
