(function() {
    "use strict";

    angular
        .module('slate.invoices')
        .directive('slateInvoiceLineItemEdit', modelDetails);

    function modelDetails() {
        return {
            restrict: 'E',

            templateUrl: '/partials/invoices/invoices.lineitem.edit.dir.html',
            scope: {
                lineitem: '=',
                projects: '='
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ListController($scope, SlateProjects) {
        /* jshint validthis: true */
        var vm = this;

        vm.components = new SlateProjects();
        $scope.$watch('vm.lineitem.jansy_project', function() {
            if (vm.lineitem.jansy_project) {
                vm.components.getList({
                    project: vm.lineitem.jansy_project,
                    recursive: true
                });
            } else {
                vm.components.list = [];
            }
        });

    }

})();
