(function () {
    'use strict';

    angular
        .module('slate.vendorsetuprequests')
        .service('SlateAddVendorSetupRequest', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateAuth) {
        const templateUrl = '/partials/vendorsetuprequests/ticket.add.dialog.html';

        const srv = {
            add: editObj
        };


        return srv;


        function editObj() {
            const modalInstance = $uibModal.open({
                templateUrl,
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {}
            });


            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $uibModalInstance, $http, SlateAuth, SlateVendorSetupRequest) {
        /* jshint validthis: true */
        const vm = this;

        vm.auth = SlateAuth;

        vm.cancel = cancel;
        vm.save = saveObject;
        vm.isComplete = isComplete;

        vm.froalaOptions = {
            toolbarButtons: ['bold',
                'italic',
                'underline',
                '|',
                'align',
                'formatOL',
                'formatUL'],
            placeholderText: 'Include vendor name, contact, phone number, email, and reason for setup',
            zIndex: 50
        };

        vm.ticket = new SlateVendorSetupRequest();

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function saveObject() {
            vm.saving = true;
            vm.error = false;
            vm.ticket.saveModel().then(() => {
                // $uibModalInstance.close(vm.ticket);
                vm.fileSrv.uploadFiles('vendorsetuprequest', vm.ticket.id).then(() => {
                    vm.saving = false;
                });
            }, () => {
                vm.saving = false;
                vm.error = true;
            });
        }

        function isComplete() {
            if (!vm.fileSrv.files.length) { return false; }
            return true;
        }
    }
})();
