(function () {
    "use strict";

    angular
        .module('slate.dashboard')
        .directive('slateWidgetSalesorders', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/dashboard/dashboard.widget.salesorders.html',
            scope: {
                settings: "="
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $interval, SlateSalesOrders, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var interval = null;
        var speed = vm.settings.speed || 60000;
        vm.getLink = getLinkSrv;
        vm.sos = new SlateSalesOrders();

        vm.loading = false;

        vm.fields = ['id', 'name', 'status', 'active_status', 'company', 'company_info', 'order', 'order_info', 'sell_total', 'created_by', 'customer_did_not_sign'];

        function getData() {
            var filters = {
                'fields': vm.fields
            };
            vm.loading = true;

            if (vm.settings.mine) { filters.selfonly = true; }
            if (vm.settings.customer_did_not_sign) { filters.customer_did_not_sign = true; }
            if (vm.settings.status) { filters.status = vm.settings.status; }
            if (vm.settings.active_status) { filters.active_status = vm.settings.active_status; }

            vm.sos.getList(filters).then(function () { vm.loading = false; });
        }

        // getData();

        $scope.$watch('vm.settings', function (items) {
            getData();
        }, true);

        interval = $interval(function () {
            getData();
        }, speed);

        $scope.$on('$destroy', function () {
            if (angular.isDefined(interval)) {
                $interval.cancel(interval);
                interval = undefined;
            }
        });

    }


})();
