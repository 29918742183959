(function() {
    "use strict";

    angular
        .module('slate.app')
        .service('SlateDeleteObjSrv', editDialog);


    var getChildrenUrl = API_URL + 'utils/getchildren/';
    var deleteChildrenUrl = API_URL + 'utils/deletechildren/';

    function editDialog($rootScope, $q, $http, $uibModal) {

        var srv = {
            delete: deleteObj,
        };

        return srv;

        function deleteObj(model, id) {
            var modalInstance;
            var templateUrl = '/partials/slate/slate.delete_obj.dialog.html';

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    model: function () {
                        return model;
                    },
                    id: function () {
                        return id;
                    },
                }
            });

            return modalInstance.result.then(function (results) {
                return results;
            }, function () {
                return $q.reject();
            });
        }
    }

    function editDialogCtrl($scope, $q, $http, $state, $uibModalInstance, SlateAuth, model, id) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;

        vm.auth = SlateAuth;
        
        vm.data = {};

        vm.saving = false;
        vm.errorSaving = false;
        vm.cancel = cancel;
        vm.deleteObj = deleteObj;
        
        $http.get(getChildrenUrl, {
            params: {
                obj_type: model,
                obj_id: id
            }
        }).then(function(response) {
            vm.data = response.data.payload;
            vm.loading = false;
        });


        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function  deleteObj() {
            vm.saving = true;
            vm.errorSaving = false;
            $http.get(deleteChildrenUrl, {
                params: {
                    obj_type: model,
                    obj_id: id
                }
            }).then(function(response) {
                vm.saving = false;
                $uibModalInstance.close();
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });

            
        }

    }



})();
