(function () {
    'use strict';

    angular
        .module('slate.qima')
        .service('QIMABookingEditSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateBooking) {
        const templateUrl = '/partials/qima/booking.qima.dialog.html';

        const srv = {
            edit: editObj
        };


        return srv;


        function editObj(instance) {
            const modalInstance = $uibModal.open({
                templateUrl,
                controller: editDialogCtrl, // editController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    obj() {
                        const booking = new SlateBooking();
                        if (!instance || !instance.id) {
                            return booking;
                        }
                        return booking.loadFromPayload(instance);
                    }
                }
            });

            return modalInstance.result.then((results) => results, () => $q.reject());
        }
    }

    function editDialogCtrl($scope, $http, $q, $uibModalInstance, SlateAuth, SlateInspections, SlateInspectionLineItems, QIMAProductReference, obj) {
        /* jshint validthis: true */
        const vm = this;

        vm.obj = obj;
        vm.auth = SlateAuth;

        vm.saving = false;
        vm.errorSaving = false;
        vm.getSuppliers = getSuppliers;
        vm.isComplete = isComplete;
        vm.sendToQIMA = sendToQIMA;
        vm.updateProductType = updateProductType;
        vm.selectSearch = selectSearch;
        vm.handleChange = handleChange;
        vm.clear = clear;
        vm.cancel = cancel;


        vm.inspectionFields = [
            'jansy_po', 'description', 'status', 'created'
        ];

        vm.serviceTypes = [
            { name: 'Pre-Shipment Inspection', id: '1' }, { name: 'Initial Production Check', id: '2' }, { name: 'During Production Inspection', id: '3' }
        ];

        vm.productTypes = [
            {
                categoryName: 'Bodycare, Fashion & Accessories',
                categoryId: 'bigCat3',
                familyId: 'bigCat3_s1',
                typeId: 'bigCat3_s1_t200'
            },
            {
                categoryName: 'Printing & Packaging',
                categoryId: 'bigCat4',
                familyId: 'bigCat4_s1',
                typeId: 'bigCat4_s1_t200'
            },
            {
                categoryName: 'Homeware, Gardenware',
                categoryId: 'bigCat2',
                familyId: 'bigCat2_s93',
                typeId: 'bigCat2_s93_t200'
            },
            {
                categoryName: 'Textile, Apparel, Footwear & Accessories',
                categoryId: 'bigCat6',
                familyId: 'bigCat6_s1',
                typeId: 'bigCat6_s1_t200'
            }
        ];

        function updateProductType(product) {
            product.category_id = product.productType.categoryId;
            product.family_id = product.productType.familyId;
            product.type_id = product.productType.typeId;
        }

        function isComplete() {
            if (!vm.obj.supplier_code) { return false; }
            return true;
        }

        function selectSearch(item, model, label, event) {
            vm.obj.supplier_code = item.identificationCode;
        }

        function handleChange() {
            if (!vm.supplier) {
                vm.obj.supplier_code = null;
            }
        }

        function clear() {
            vm.obj.supplier_code = null;
            vm.supplier = null;
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function getSuppliers() {
            if (!vm.suppliers) {
                vm.loadingSuppliers = true;
                $http.get(`${API_URL}qima/suppliers/`).then((response) => {
                    vm.suppliers = response.data.payload;
                    vm.loadingSuppliers = false;
                }, () => {
                    vm.loadingSuppliers = false;
                });
            }
        }
        getSuppliers();

        function sendToQIMA() {
            vm.saving = true;
            vm.obj.products.forEach((product) => {
                product.custom_field_1 = product.id;
                product.name = `${product.inspection_lineitem_info.po_lineitem_info.component_info.composite_num}/${product.inspection_lineitem_info.po_lineitem_info.name}`;
            });
            const url = `${API_URL}qima/bookings/${vm.obj.id}/`;
            $http.post(url, vm.obj.getModelData()).then((resp) => {
                vm.saving = false;
                $uibModalInstance.close(resp.data.payload);
            }, () => {
                vm.saving = false;
                vm.error = true;
            });
        }
    }
})();
