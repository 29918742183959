(function () {
    "use strict";

    angular
        .module('slate.invoices')
        .directive('slateInvoiceEmailEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/invoices/invoices.emails.edit.dir.html',
            scope: {
                email: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $state,
        $location,
        getLinkSrv,
        SlateAuth
        
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;
        
        vm.baseEnteredStatus = vm.email.entered;
        vm.baseAPNumber = vm.email.ap_number;

        vm.isComplete = isComplete;
        vm.saveObject = saveObject;

        $scope.$watch('vm.email.ap_number', function(newVal) {
            if (newVal) {
                vm.email.entered = true;
            } else {
                vm.email.entered = false;
            }
        });
        
        function saveObject() {
            if (vm.saving) { return false; }
            if(!vm.auth.user.permissions.payables_can_edit) { return false; }
            
            vm.saving = true;
            vm.saved = false;
            vm.error = false;

            vm.email.saveModel().then(function() {
                vm.saved = true;
                $timeout(function() {
                    vm.saving = false;
                }, 300);
            }, function(e) {
                vm.saving = false;
                vm.error = true;
            });
        }

        function isComplete() {
            if (vm.email.entered && !vm.email.ap_number) { return false; }
            return true;
        }

    }

})();
