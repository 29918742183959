(function () {
    'use strict';

    angular
        .module('slate.library.vendorsetuprequests')
        .factory('SlateVendorSetupRequests', ModelList)
        .factory('SlateVendorSetupRequest', ModelDetail);

    const apiUrl = `${API_URL}vendorsetuprequests/requests/`;

    function ModelList($http, $q, SlateVendorSetupRequest) {
        const list = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        list.pagination = {
            total: 0,
            page: 1,
            count: 25
        };
        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            const self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === 'object') {
                self.filters = filters;
            }

            const promise = $http.get(apiUrl, {
                params: self.filters,
                timeout: self.canceller.promise
            });

            promise.then((response) => {
                const newlist = [];
                // set this for correct model
                angular.forEach(response.data.payload, (item) => {
                    const itemModel = new SlateVendorSetupRequest();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.pagination = {
                    total: parseInt(response.data.pagination.total, 10),
                    page: parseInt(response.data.pagination.page, 10),
                    count: parseInt(response.data.pagination.count, 10)
                };
                self.permissions = response.data.permissions;
                self.canceller = null;

                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }

    function ModelDetail($http, $q, SlatePOLineItem) {
        // instantiate our initial object
        const model = function construct() {
            const self = this;

            self.clear();

            return self;
        };

        // Set to fields for model
        /* beautify preserve:start */
        const fields = [
            { name: 'id', def: null, readonly: true },
            { name: 'name' },
            { name: 'description' },

            { name: 'status', def: 'new' },

            { name: 'submitter' },
            { name: 'submitter_info', readonly: true },
            { name: 'submitter_secondary' },
            { name: 'submitter_secondary_info', readonly: true },
            { name: 'assigned_to' },
            { name: 'assigned_to_info', readonly: true },

            { name: 'url' },
            { name: 'type' },
            { name: 'area' },

            { name: 'audit_status' },
            { name: 'audit_scheduled_date', type: 'dateOnly' },

            { name: 'rfi_received' },
            { name: 'qc_documents_received' },
            { name: 'nda_signed' },

            { name: 'expected_time', type: 'float' },
            { name: 'actual_time', type: 'float' },

            { name: 'priority', def: 'normal' },

            { name: 'created', readonly: true, type: 'date' },
            { name: 'created_by', readonly: true },
            { name: 'updated', readonly: true, type: 'date' },
            { name: 'closed', readonly: true, type: 'date' },
            { name: 'open_time', readonly: true },
            { name: 'deleted', readonly: true, default: false }

        ];
        /* beautify preserve:end */

        /* beautify preserve:start */
        model.prototype.statuses = ['new',
            'open',
            'waiting_response',
            'waiting_vendor',
            'waiting_legal',
            'waiting_other',
            'verifying',
            'setup_slate',
            'setup_accounting',
            'invalid',
            'closed'];
        model.prototype.types = [
            'new',
            'update'
        ];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        return model;

        function clear() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            angular.forEach(fields, (field) => {
                self[field.name] = field.def;
                if (field.type === 'child_list') {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            if (typeof payload !== 'object') {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, (field) => {
                if (!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if (field.type === 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }

                if (field.type === 'dateOnly' && self[field.name] && typeof self[field.name] === 'string') {
                    const parts = self[field.name].split('-');
                    self[field.name] = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
                }

                if (field.type === 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if (field.type === 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch (e) {
                        // do nothing
                    }
                }

                if (field.type === 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach((item, idx, array) => {
                            const obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch (e) {
                        console.log('error loading: ', e);
                    }
                }

                return true;
            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();

            let modelUrl = `${apiUrl + self.id}/`;

            if (id) {
                modelUrl = `${apiUrl + id}/`;
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            const promise = $http.get(modelUrl);

            promise.then((response) => self.loadFromPayload(response.data.payload).then(() => {
                self.permissions = response.data.permissions;
                deferred.resolve(self);
            }));

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            const self = this;
            let modelUrl = apiUrl;

            const promises = [];
            const data = {};

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            }

            angular.forEach(fields, (field) => {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if (data[field.name] && field.type === 'date') {
                        data[field.name] = `${self[field.name].getUTCFullYear()}-${self[field.name].getUTCMonth() + 1}-${self[field.name].getUTCDate()}`;
                    }
                    if (field.type === 'dateOnly' && self[field.name]) {
                        data[field.name] = `${self[field.name].getFullYear()}-${self[field.name].getMonth() + 1}-${self[field.name].getDate()}`;
                    }
                    if (field.type === 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if (field.type === 'child_list' && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach((item) => {
                            if (item.toDelete) {
                                promises.push(item.deleteModel());
                                return false;
                            }
                            data[field.name].push(item.getModelData());

                            return item;
                        });
                    }
                }
            });

            if (data.name) {
                data.name = data.name.substring(0, 72);
            }

            return $q.all(promises).then(() => {
                let promise = $http.post(modelUrl, data);
                promise = promise.then((response) => {
                    self.loadFromPayload(response.data.payload);
                    self.permissions = response.data.permissions;
                });
                return promise;
            });
        }

        function deleteModel() {
            /* jshint validthis: true */
            const self = this;
            const deferred = $q.defer();
            let modelUrl = apiUrl;

            if (self.id) {
                modelUrl = `${apiUrl + self.id}/`;
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            const promise = $http.delete(modelUrl);
            promise.then((response) => {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;
        }
    }
})();
