(function() {
    "use strict";

    angular
        .module('slate.navbar')
        .directive('slateNotificationsNavbar', dirObject);

    function dirObject() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/navbar/notifications.navbar.dir.html',
            scope: {

            },
            controller: navbarController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function navbarController($q, $interval, getLinkSrv, SlateNotifications) {
        /* jshint validthis: true */
        var vm = this;
        var speed = 120000;
        var interval;

        if(DEBUG) {
            speed = speed * 100;
        }
        vm.getLink = getLinkSrv;
        vm.notifications = new SlateNotifications();
        vm.notifications.getList({new_only: true});

        vm.removeNote = removeNote;
        vm.clearAll = clearAll;

        interval = $interval(function() {
            vm.notifications.getList({new_only: true});
        }, speed);

        function removeNote(note) {
            note.read = true;
            note.saveModel().then(function() {
                vm.notifications.getList({new_only: true});
            });
        }

        function clearAll(type, id) {
            var promises = [];

            angular.forEach(vm.notifications.list, function(note) {
                if((!type && !id) || (type && id && note.parent_info.id == id && note.parent_type==type)) {
                    note.read = true;
                    promises.push(note.saveModel());
                }
            });

            $q.all(promises).then(function() {
                vm.notifications.getList({new_only: true});
            });
        }

    }

})();
