(function() {
    "use strict";

    angular
        .module('slate.companies')
        .directive('slateEmployeesList', slateDirectiveList);

    function slateDirectiveList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/companies/employees.list.dir.html',
            scope: {
                modelFilters: '=filters'
            },
            controller: directiveListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function directiveListController($scope, $state, $location, $q, getLinkSrv, SlateEmployees, SlateContact, SlateContactEditDialog) {
        /* jshint validthis: true */
        var vm = this;
        var modelPromise;

        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.addNew = addNew;
        vm.updateFromFilter = updateFromFilter;

        if(!vm.filters) {
            vm.filters = {};
        }

        angular.extend(vm.filters, {
            q: $location.search().q,
            sort_last_name: $location.search().sort_last_name || true
        }, vm.modelFilters, $location.search(), { page: 1, count: 25 });
        if(vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if(vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }
        
        vm.employeesList = new SlateEmployees();

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        
        function updateFromFilter() {
            var modelPromise;

            vm.loading = true;
            
            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = vm.employeesList.getList(vm.filters);
            modelPromise.then(function () {
                vm.loading = false;
            });
        }

        function addNew($event) {
            var newContact = new SlateContact();
            var promise;
            newContact.contactmethods.addBlank({
                method: 'phone',
                is_primary: true
            });
            newContact.contactmethods.addBlank({
                method: 'email',
                is_primary: true
            });
            newContact.timezone = "US/Eastern";
            promise = SlateContactEditDialog.edit(newContact, vm.modelFilters.company);
            promise.then(function() {
                $location.path(getLinkSrv.getUrl('contact', newContact.id, newContact.slug)).search({});
            }, function() {

            });
            return promise;
        }




    }

})();
