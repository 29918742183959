(function() {
    "use strict";

    angular
        .module('slate.rfqs')
        .directive('slateRfqEditResponsesList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/rfqs/rfq.edit.responses.list.dir.html',
            scope: {
                rfq: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateRFQResponses, SlateShortRFQResponse) {
        /* jshint validthis: true */
        var vm = this;

        vm.loading = false;

        vm.addNewLineItem = addNewLineItem;
        vm.deleteLineItem = deleteLineItem;

        vm.response = new SlateShortRFQResponse();

        vm.hasNoPreferred = hasNoPreferred;
        vm.hasNoQualified = hasNoQualified;

        function addNewLineItem() {
            if(!vm.response.vendor) { return false;}
            vm.rfq.responses.push(vm.response);
            vm.response = new SlateShortRFQResponse();
        }
        $scope.$watch('vm.response.vendor', function (newVal, oldVal) {
            if(newVal != oldVal && vm.response.vendor) {
                addNewLineItem();
            }
        });

        function deleteLineItem(lineitem, $idx) {
            lineitem.toDelete = !lineitem.toDelete;
        }

        function hasNoQualified() {
            if(!vm.rfq.responses.length) { return false; }
            if(vm.rfq.responses.some(function(response) {
                return response.vendor_info.vendor_qualified;
            })) { return false; }

            return true;
        }

        function hasNoPreferred() {
            if(!vm.rfq.responses.length) { return false; }
            if(vm.rfq.responses.some(function(response) {
                return response.vendor_info.vendor_preferred;
            })) { return false; }

            return true;
        }
    }


})();
