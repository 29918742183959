(function () {
    "use strict";

    angular
        .module('slate.salesquotes')
        .directive('slateSalesQuotesList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/salesquotes/salesquotes.list.dir.html',
            scope: {
                modelFilters: '=?filters',
                showNew: '=?',
                hideFilters: '=?',
                fields: '=?',
                showTotals: '=?',
                sqList: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateAuth, SlateSalesQuotes, SlateSalesOrder, getLinkSrv, SlateDeleteObjSrv) {
        /* jshint validthis: true */
        var vm = this;

        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.salesquotes = new SlateSalesQuotes();

        vm.shipTypes = ['oceanlcl', 'oceanfcl', 'oceanexp', 'air', 'truck', 'ups', 'fedex', 'dhl', 'other'];
        vm.termsTypes = ['exw', 'fca', 'fob', 'cif', 'cpt', 'cip', 'dat', 'dap', 'ddp', 'ddu', 'other'];

        vm.availStatus = ['draft', 'ready', 'approval', 'quoted', 'accepted', 'rejected'];

        /* beautify preserve:start */
        vm.default_fields = [
            'quote_number',
            'status',
            'name',
            'company',
            'proj_mgr',
            'sales_mgr',
            'sell_total',
            'margin',
            'quote_expires',
        ];
        /* beautify preserve:end */

        /* beautify preserve:start */
        vm.url_filter_fields = [  // these are valid url search parameters
            'q',
            'startDate',
            'endDate',
            'company',
            'sales_mgr',
            'proj_mgr'
        ];
        /* beautify preserve:end */

        vm.date_fields = ['startDate', 'endDate'];
        vm.multiSearchFields = ['shipvia', 'ship_terms', 'status'];

        vm.showFilters = false;

        // define scope functions

        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;

        vm.getSellTotal = getSellTotal;
        vm.getPriceTotal = getPriceTotal;
        vm.getMarginTotal = getMarginTotal;

        vm.duplicateObject = duplicateObject;


        // handle grabing filters on load and making sure they're valid, strings to date objects, etc.
        if (!vm.fields) { vm.fields = vm.default_fields; }
        if (!vm.modelFilters) { vm.modelFilters = {}; }
        vm.default_filters = {};
        if (!vm.hideFilters) {
            vm.default_filters.page = 1;
            vm.default_filters.count = 25;
        }

        var searchParams = $location.search();
        vm.url_filters = {};
        vm.url_filter_fields.forEach(function (item) {
            if (searchParams.hasOwnProperty(item)) {
                vm.url_filters[item] = searchParams[item];
            }
        });
        vm.filters = Object.assign({}, vm.default_filters, vm.modelFilters, vm.url_filters);
        if (vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if (vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }

        vm.date_fields.forEach(function (dateField) {
            if (vm.filters[dateField]) {
                parts = vm.filters[dateField].split('-');
                vm[dateField] = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
            }
            $scope.$watch('vm.' + dateField, function () {
                if (vm[dateField]) {
                    vm.filters[dateField] = vm[dateField].getFullYear() + '-' + (vm[dateField].getMonth() + 1) + '-' + vm[dateField].getDate();
                } else {
                    vm.filters[dateField] = null;
                }
            });
        });

        vm.multiSearchFields.forEach(function (field) {
            if (vm.filters[field] && typeof (vm.filters[field]) === 'string') {
                vm.filters[field] = [vm.filters[field]];
            }

        });

        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            updateFromFilter();
        });

        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function (value, key) {
                if (key == 'page' || key == 'count' || key == 'q') { return false; }
                if (value === undefined || value === null || value === '') { return false; }
                if (vm.filters[key] == vm.modelFilters[key]) { return false; }
                if (typeof vm.filters[key] == 'string' && vm.filters[key].length === 0) { return false; }
                hasData = true;
                vm.showFilters = true;
                return true;

            });
            return hasData;
        }

        function updateFromFilter() {
            var modelPromise;
            var send_data = {};

            vm.loading = true;

            vm.multiSearchFields.forEach(function(fieldname) {
                if (typeof vm.filters[fieldname] === 'string') {
                    vm.filters[fieldname] = [vm.filters[fieldname]];
                }
            });

            angular.forEach(vm.filters, function (value, item) {
                if(vm.modelFilters[item]) { return false; }
                if(value === '') { vm.filters[item] = null; }
                if (vm.filters[item]) {
                    if(vm.filters[item] instanceof Date) {
                        $location.replace().search(item, vm.filters[item].toISOString());
                    } else {
                        $location.replace().search(item, vm.filters[item]);
                    }
                } else {
                    $location.replace().search(item, null);
                }
            });
            angular.extend(
                send_data,
                {'fields': vm.fields},
                vm.filters
            );
            vm.url = $window.location.href;
            return vm.salesquotes.getList(send_data).then(function () {
                vm.loading = false;
            });
        }

        function deleteSO(so) {
            SlateDeleteObjSrv.delete('salesorder', so.id).then(function () {
                vm.updateFromFilter();
            });
        }


        function addNew() {
            var url = "/salesorders/so/new/";
            var params = {
                order: vm.modelFilters.order,
            };
            var queryString;
            queryString = queryString = Object.keys(params).map(function (key) {
                return key + '=' + params[key];
            }).join('&');
            $location.url(url + '?' + queryString);
        }

        function duplicateObject(salesQuote) {
            if(salesQuote.duplicating) { return false; }
            if(!window.confirm('Are you sure you want to void and duplicate this quote?')) { return false; }
            salesQuote.duplicating = true;
            salesQuote.duplicateModel().then(function() {
                    $state.transitionTo(
                        'salesQuoteEditPage',
                        {id: salesQuote.id}
                    );
            });
        }

        function getPriceTotal() {
            var total = Big(0.0);
            vm.salesquotes.list.forEach(function (item) {
                total = total.add(item.price_total);
            });
            return total.toFixed(5);
        }

        function getSellTotal() {
            var total = Big(0.0);
            vm.salesquotes.list.forEach(function (item) {
                total = total.add(item.sell_total);
            });
            return total.toFixed(5);
        }

        function getMarginTotal() {
            var sell = getSellTotal();
            var cost = getPriceTotal();
            var total = Big(0.0);
            if (sell == 0.00000) { return 0.0; }
            total = total.add(sell).minus(cost);
            total = total.div(sell);
            total = total.times(100.0);
            return total.toFixed(2);
        }


    }
})();
