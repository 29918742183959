(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .directive('slateRfqLegacyEdit', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/rfqs/rfqs.legacy_edit.dir.html',
            scope: {
                rfq: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $timeout, $state, $location, $q, getLinkSrv, SlateSettings, SlateAuth, SlateProject, SlateRolesSrv, SlateAssignedRoles, SlateContacts) {
        /* jshint validthis: true */
        var vm = this;

        vm.staff = new SlateContacts();
        vm.staff.getList({ staff: true });

        vm.auth = SlateAuth;
        vm.getLink = getLinkSrv;

        vm.save = save;
        vm.toggleChina = toggleChina;
        vm.canFullEdit = canFullEdit;
        vm.canAssEdit = canAssEdit;
        vm.canSave = canSave;

        vm.review = reviewRFQ;

        vm.toggleField = toggleField;

        vm.quoteDueIsOpen = false;
        vm.quoteDueOpen = quoteDueOpen;
        vm.weekendsDisabled = weekendsDisabled;

        vm.deleted = [];

        SlateSettings.getConfig('material_categories').then(function(config) {
            vm.categories = config;
        });
        SlateSettings.getConfig('materials').then(function(config) {
            vm.material_list = config;
            getMatList();
        });
        vm.materials = [];

        vm.froalaOptions = {
            toolbarButtons : ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL"],
            //zIndex: 501,
        };

        function reviewRFQ(status) {
            if (!SlateAuth.user.permissions.rfq_can_review) { return false; }
            vm.rfq.reviewed = status;
            vm.save();
        }

        function canSave() {
            if (vm.rfq.project && (!vm.rfq.proj_mgr || !vm.rfq.sales_mgr)) {
                projectSync();
            }

            if (!vm.rfq.name) { return false; }
            if (!vm.rfq.company) { return false; }
            if (!vm.rfq.proj_mgr) { return false; }
            if (!vm.rfq.sales_mgr) { return false; }
            if (!vm.rfq.project) { return false; }
            if (vm.rfq.vendors.length === 0 && vm.rfq.source_rep_do_not_assign) { return false; }
            return true;
        }

        function canFullEdit() {
            if (SlateAuth.user.permissions.rfq_edit_all) { return true; }
            if (SlateAuth.user.permissions.rfq_edit_own && vm.rfq.created_by == SlateAuth.user.contact_id) { return true; }
        }

        function canAssEdit() {

            if (vm.rfq.source_rep == SlateAuth.user.contact_id) { return true; }
            return canFullEdit();
        }


        function toggleChina() {
            if (canFullEdit()) { vm.rfq.source_rep_do_not_assign = !vm.rfq.source_rep_do_not_assign; }
        }

        function projectSync() {
            var assroles = new SlateAssignedRoles();
            var project = new SlateProject();
            var role_dict = SlateRolesSrv.dict;
            assroles.getList({
                parent_type: 'project',
                parent_fk: vm.rfq.project
            }).then(function() {

                angular.forEach(assroles.list, function(item) {
                    if(item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Project Manager' && !vm.rfq.proj_mgr) {
                        vm.rfq.proj_mgr = item.member_fk;
                    }
                    if(item.member_type == 'contact' && role_dict[item.roles[0]].name == 'Sales Manager' && !vm.rfq.sales_mgr) {
                        vm.rfq.sales_mgr = item.member_fk;
                    }
                });

            });
            if(!vm.rfq.company) {
                project.loadFromServer(vm.rfq.project).then(function() {
                    if(project.parent_type == 'company') {
                        vm.rfq.company = project.parent_fk;
                    }
                });
            }

        }
        $scope.$watch('vm.rfq.project', function () {
            if (vm.rfq.project) {
                projectSync();
            }
        });

        function componentSync() {
            vm.rfq.component_info = new SlateProject();
            if(vm.rfq.component){
                vm.rfq.component_info.loadFromServer(vm.rfq.component).then(function() {
                    if(vm.rfq.category || vm.rfq.material){

                    }
                    vm.rfq.category= vm.rfq.component_info.category;
                    vm.rfq.material= vm.rfq.component_info.material;
                });
            }
        }

        $scope.$watch('vm.rfq.component', function () {
            if (vm.rfq.component) {
                componentSync();
            }
        });


        function save() {
            return false;
        }

        function oldSave() {
            vm.saving = true;
            var redirect = false;
            if (!vm.rfq.id) { redirect = true; }
            vm.rfq.saveModel().then(function () {
                var promises = [];
                var filePromises = [];
                var compPromise;
                var filePromise;
                angular.forEach(vm.deleted, function (comp) {
                    comp.deleteModel();
                });
                vm.fileSrv.uploadFiles('rfq', vm.rfq.id);

            }, function(e) {
                vm.error = true;
                vm.saving = false;
            });
        }

        function toggleField(field) {
            if (vm.rfq.field_names.indexOf(field) > -1) {
                vm.rfq.field_names.splice(vm.rfq.field_names.indexOf(field), 1);
            } else {
                var fields = [];
                angular.forEach(vm.rfq.avail_fields, function (availField) {
                    if (field == availField || vm.rfq.field_names.indexOf(availField) > -1) {
                        fields.push(availField);
                    }
                });
                vm.rfq.field_names = fields;
            }
        }

        function quoteDueOpen($event) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.quoteDueIsOpen = true;
        }

        function weekendsDisabled(data) {
            var date = data.date,
                mode = data.mode;
            return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
        }

        $scope.$watch('vm.rfq.category', function() {
            getMatList();
        });

        function getMatList() {
            var newlist = [];
            if(!vm.material_list || ! vm.material_list.value) { return []; }
            angular.forEach(vm.material_list.value, function(item) {
                if(item.category == vm.rfq.category) {
                    newlist.push(item);
                }
            });
            vm.materials = newlist;
            return newlist;
        }


        /* beautify preserve:start */
        vm.caps = [
            "Aerosol Can",
            "Barrier Bag",
            "Blow Molding",
            "Bottles",
            "Closure Caps (Disc cap, Flip top cap)",
            "Cosmetic Compact",
            "Cosmetics Components",
            "Cut & Sew",
            "Cylinder Tube",
            "Deodorant Stick",
            "Dispensar (Pump, Sprayer)",
            "Display Shipper",
            "Double Flute Corrugate",
            "Eye Shadow Pot",
            "Filling",
            "Flow Pack Wrapper",
            "Foam",
            "Folding Carton",
            "Folding Cartons",
            "Foundation Stick",
            "Fulfilment",
            "Glass",
            "Hair Spray",
            "Injection Molding",
            "Jars",
            "Lip Pot",
            "Lip Stick Tube",
            "Make up brush",
            "Mascara Tube",
            "Molded Fiber",
            "POP Display",
            "Paper Bag",
            "Paper Board",
            "Paperfoam",
            "Point of Sale",
            "Pressed Powder Compacts",
            "Resealable Bag",
            "Rigid & Set up Box",
            "Single Flute Corrugate",
            "Spray Can",
            "Thermoform",
            "Tin Can",
            "Tins",
            "Tubes",
            "Warehousing",
            "Wood"
        ];
        /* beautify preserve:end */



    }

})();
