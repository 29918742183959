(function () {
    "use strict";

    angular
        .module('slate.salesforecast')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('salesForecastPage', {
                url: '/salesbudgets/',
                template: "<div class=\"content-pane list-page\"><slate-sales-forecast-bulk></slate-sales-forecast-bulk></div>",
                current_app: 'salesforecast',
                slateTitle: 'Sales Budgets',
            })
            // .state('salesQuoteNewPage', {
            //     url: '/salesquotes/quote/new/',
            //     templateUrl: '/partials/salesquotes/salesquote.new.page.html',
            //     controller: 'SlateSalesQuoteNewPageController',
            //     controllerAs: 'vm',
            //     current_app: 'salesquotes',
            //     slateTitle: 'Create New Sales Quote - Sales Quote',
            // })
            // .state('salesQuoteEditPage', {
            //     url: '/salesquotes/quote/:id/edit/',
            //     templateUrl: '/partials/salesquotes/salesquote.edit.page.html',
            //     controller: 'SlateSalesQuoteEditPageController',
            //     controllerAs: 'vm',
            //     current_app: 'salesquotes',
            //     slateTitle: 'Edit Sales Quote - Sales Quote',
            // })
            ;
    }
})();