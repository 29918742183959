(function() {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportBuildFinishedGoods', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.build_finished_goods.dir.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $q, $http, $location, $httpParamSerializer) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'reports/build_finished_goods/';
        
        var modelPromise;
        var canceller = null;

        vm.getDownloadUrl = getDownloadUrl;
        vm.updateFromFilter = updateFromFilter;
        vm.sortBy = sortBy;

        vm.data = [];
        vm.filters = {'status': 'error'};
        vm.loading = true;
        vm.sortOrder = 'build_date';
        vm.reverse = true;
        
        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });
        
        function updateFromFilter() {
            vm.loading = true;

            if (canceller) { canceller.resolve(); }
            canceller = $q.defer();

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
                timeout: canceller.promise
            });
            
            modelPromise.then(function(resp) {
                vm.data = resp.data.payload;
                vm.loading = false;
                canceller = null;
            });
        }

        function sortBy(field) {
            vm.reverse = vm.sortOrder == field ? !vm.reverse : true;
            vm.sortOrder = field;
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }
    }
})();