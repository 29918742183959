(function() {
    "use strict";

    angular
        .module('slate.library.salesquotes')
        .factory('SlateSQLineItems', ModelList)
        .factory('SlateSQLineItem', ModelDetail);

        var apiUrl = API_URL + 'salesquotes/lineitems/';

    function ModelList($http, $q, SlateSQLineItem) {
        // Set to url relative to api root.

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlateSQLineItem();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

    }

    function ModelDetail($http, $q) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };


        var freight_cache = {
            origin_port: null,
            destination_port: null,
            final_destination: null
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', readonly: true },
            {name: 'salesquote'},
            {name: 'name'},
            {name: 'code'},

            {name: 'finished_good'},
            {name: 'finished_good_info', readonly: true},
            {name: 'bom'},

            {name: 'jansy_pn'},
            {name: 'client_pn'},

            {name: 'category'},
            {name: 'material'},

            {name: 'specs'},
            {name: 'notes'},

            {name: 'qty', type: 'float', def: 0},
            {name: "unit_name", def: "ea"},
            {name: 'unitprice', type: 'float', def: 0.00},
            {name: 'sellprice', type: 'float', def: 0.00},
            {name: 'bom_price', type: 'float', def: 0.00, readonly: true},

            {name: 'ready_date', type: 'dateOnly'},
            {name: 'required_date', type: 'dateOnly'},

            {name: 'vendor_pn'},

            {name: 'options', type: 'json', def: {}},
            {name: 'splits', type: 'json', def: []},
            {name: 'fixed_costs', type: 'json', def: []},
            {name: 'selected_vendors', type: 'json', def: {}},

            {name: 'sort_order'},

            {name: 'freightEstimate', type: 'float', def: 0, readonly: true},

            {name: 'created', readonly: true},
            {name: 'updated', readonly: true},
            {name: 'deleted', readonly: true, default: false},

        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        //model.prototype.loadFromServer = loadFromServer;
        model.prototype.getModelData = getModelData;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.getCostTotal = getCostTotal;
        model.prototype.getBOMCostTotal = getBOMCostTotal;
        model.prototype.getSellTotal = getSellTotal;
        model.prototype.getTariffTotal = getTariffTotal;
        model.prototype.getDuty = getDuty;
        model.prototype.getFreightEstimate = getFreightEstimate;
        model.prototype.getOptionSellTotal = getOptionSellTotal;
        model.prototype.getOptionTariffTotal = getOptionTariffTotal;
        model.prototype.getBreak = getBreak;
        model.prototype.selectVendor = selectVendor;
        model.prototype.updateCosts = updateCosts;
        model.prototype.getSellPriceWithTariff = getSellPriceWithTariff;
        model.prototype.getCostWithFreight = getCostWithFreight;
        model.prototype.addRFQOption = addRFQOption;
        model.prototype.addRFQFixedCost = addRFQFixedCost;

        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = _.cloneDeep(field.def);
                if(field.type=="date" && !self[field.name]) {
                    self[field.name] = new Date();
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                if(!payload[field.name]) {
                    return false;
                }

                self[field.name] = _.cloneDeep(payload[field.name]);

                if(field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type == 'dateOnly' && payload[field.name]) {
                    if(typeof payload[field.name] == 'string') {
                        var parts = self[field.name].split('-');
                        self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
                    }
                }

                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }
                if (field.type == 'int') {
                    self[field.name] = parseInt(self[field.name]);
                }


                if(field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch(e) {
                    }
                }

            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];

                return self.loadFromPayload(response.data.payload).then(function() {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            var self = this;
            var data = {};

            angular.forEach(fields, function(field) {
               if ((!field.readonly || field.name == 'id') && typeof(self[field.name]) != 'undefined') {
                    data[field.name] = self[field.name];
                    if(field.type == "date") {
                        data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth()+1) + '-' + self[field.name].getUTCDate();
                    }
                    if(field.type == "dateOnly" && self[field.name]) {
                        data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth()+1) + '-' + self[field.name].getDate();
                    }
                    if(field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            data = getModelData();

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

        function getCostTotal() {
            /* jshint validthis: true */
            var self = this;
            var total = 0;
            var qty;
            var cost;
            var freight;

            try { cost = new Big(self.unitprice); } catch(e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch(e) { qty = new Big(0); }

            total = qty.times(cost);

            return total.toFixed(2);
        }

        function getBOMCostTotal() {
            /* jshint validthis: true */
            var self = this;
            var total = 0;
            var qty;
            var cost;
            var freight;

            try { cost = new Big(self.bom_price); } catch(e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch(e) { qty = new Big(0); }

            total = qty.times(cost);

            return total.toFixed(2);
        }


        function getSellTotal(landed_cost, tariff_type, include_tariff, include_freight, include_duty, tariffs) {
            /* jshint validthis: true */
            var self = this;
            var total = 0;
            var qty = Big(0);
            var cost = Big(0);
            var price_break = self.getBreak();
            try { cost = new Big(self.options.primary[price_break].price); } catch(e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch(e) { qty = new Big(0); }

            total = qty.times(cost);

            if(landed_cost) {
                if(include_tariff) { total = total.plus(self.getTariffTotal(tariff_type, tariffs)); }
                if(include_freight) { total = total.plus(self.freightEstimate); }
                if(include_duty) { total = total.plus(self.getDuty()); }
            }

            return total.toFixed(3);
        }

        function getTariffTotal(tariff_type, tariffs) {
            /* jshint validthis: true */
            var self = this;
            var total = 0;
            var qty = Big(0);
            var cost = Big(0);
            var price_break = self.getBreak();
            if(tariff_type == 'sell_price') {
                try { cost = new Big(self.options.primary[price_break].price); } catch(e) { cost = new Big(0); }
                try { qty = new Big(self.qty); } catch(e) { qty = new Big(0); }

                total = qty.times(cost).times(tariffs);
            } else {
                try { cost = new Big(self.options.primary[price_break].tariff); } catch(e) { cost = new Big(0); }
                try { qty = new Big(self.qty); } catch(e) { qty = new Big(0); }

                total = qty.times(cost);
            }

            return total.valueOf();
        }

        function getFreightEstimate(origin_port, destination_port, final_destination, shipment_method) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var cbms = Big(0.0);
            var weight = Big(0.0);
            var total = Big(0);
            var promises = [];
            var apiUrl = API_URL + 'rates/calc-estimate/';
            var params = {
                origin_port: origin_port,
                destination_port: destination_port,
                final_destination: final_destination,
                weight: 0,
                cbms: 0
            };

            if(self.bomItems && self.bomItems.list) {
                self.bomItems.list.forEach(function(bomItem) {
                    if(bomItem.responses && bomItem.responses.list) {
                        bomItem.responses.list.forEach(function(resp) {

                            if(self.selected_vendors[bomItem.id] != resp.rfq_response.vendor.id) { return false; }
                            var local_params = _.cloneDeep(params);
                            var cartons = Big(0);
                            var carton_qty = Big(resp.carton_qty);
                            if(!carton_qty.eq(0)) {
                                cartons = Big(bomItem.qty_required).times(self.qty).div(resp.carton_qty);
                            }
                            local_params.cbms = resp.calcTotalCBMs(bomItem.qty_required * self.qty).valueOf();
                            local_params.weight = Big(Qty(resp.carton_gross_weight, resp.carton_weight_measure).to('kg').scalar).times(cartons).valueOf();

                            if(resp.rfq_response.vendor.origin_port) { local_params.origin_port = resp.rfq_response.vendor.origin_port; }
                            promises.push($http.get(apiUrl, {params: local_params}).then(function(response) {
                                var result = Big(0.0);
                                if (shipment_method == 'oceanexp') {
                                    total = total.plus(response.data.payload.estimates.sonic);
                                } else if (response.data.payload.estimates.oceanlcl) {
                                    total = total.plus(response.data.payload.estimates.oceanlcl);
                                } else if (response.data.payload.estimates.oceanfcl) {
                                    total = total.plus(response.data.payload.estimates.oceanfcl);
                                }
                            }));
                        });
                    }
                });
            }

            params.cbms = cbms.toFixed(2);
            params.weight = weight.toFixed(2);

            $q.all(promises).then(function() {
                self.freightEstimate = total.valueOf();
                deferred.resolve(total.valueOf());
            });

            return deferred.promise;
        }

        function getOptionSellTotal(option) {
            /* jshint validthis: true */
            var self = this;
            var total = 0;
            var qty = Big(0);
            var cost = Big(0);
            var price_break = self.getBreak();
            try { cost = new Big(self.options[option][price_break].price); } catch(e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch(e) { qty = new Big(0); }

            total = qty.times(cost);

            return total.toFixed(3);
        }

        function getOptionTariffTotal(option, tariffs) {
            /* jshint validthis: true */
            var self = this;
            var total = 0;
            var qty = Big(0);
            var cost = Big(0);
            var price_break = self.getBreak();
            try { cost = new Big(self.options[option][price_break].cost); } catch(e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch(e) { qty = new Big(0); }

            total = qty.times(cost).times(tariffs);

            return total.toFixed(2);
        }

        function getBreak() {
            /* jshint validthis: true */
            var self = this;
            var price_break;
            var big_qty = Big(self.qty);
            self.splits.forEach(function(item) {
                var this_break;
                if(isNaN(parseFloat(item))) { return false; }
                this_break = Big(item);
                if(!price_break) {
                    price_break = item;
                }
                if(this_break.gt(price_break) && this_break.lte(big_qty)) {
                    price_break = item;
                }
            });
            return price_break;
        }

        function selectVendor(bomItem, response, tariffs) {
            /* jshint validthis: true */
            var self = this;

            self.selected_vendors[bomItem.id] = response.rfq_response.vendor.id;

            self.splits.forEach(function(price_break) {
                //self.options.primary[price_break].cost = response.getSplitCost(price_break);
            });
            self.updateCosts(tariffs);
        }

        function updateCosts(tariffs) {
            /* jshint validthis: true */
            var self = this;

            var totalCost = {};
            var totalTariffable = {};
            if(!self.bomItems) { return false; }

            self.splits.forEach(function(price_break) {
                var qty;
                totalCost[price_break] = Big(0);
                totalTariffable[price_break] = Big(0);
                if(isNaN(parseFloat(price_break))) { return false; }
                qty = Big(price_break);
                self.bomItems.list.forEach(function(bomItem) {
                    var response;
                    var cost = Big(0);
                    var tariffCost = Big(0);
                    var qty_req = Big(bomItem.qty_required);
                    var qty_total;
                    var total = Big(0);
                    if(self.selected_vendors[bomItem.id] && bomItem.responses && bomItem.responses.list) {
                        response = bomItem.responses.list.find(function(item) { return item.rfq_response.vendor.id == self.selected_vendors[bomItem.id]; });
                        if(response) {
                            cost = Big(response.getSplitCost(price_break));
                            if(response.rfq_response.vendor.include_tariff){
                                tariffCost = Big(response.getSplitCost(price_break));
                            }
                            response.options.forEach(function(option) {
                                if(self.options[option.name]) {
                                    self.options[option.name][price_break].cost = response.getSplitCost(price_break, option.name);
                                }
                            });
                            response.flatcosts.forEach(function(flatcost) {
                                var lineitem_cost = self.fixed_costs.find(function(item_cost) {
                                    return item_cost.name == flatcost.name;
                                });
                                if(lineitem_cost) {
                                    lineitem_cost.cost = flatcost.price;
                                }
                            });
                        } else {
                            cost = Big(bomItem.component_info.buy_price);
                        }
                    } else {
                        cost = Big(bomItem.component_info.buy_price);
                    }
                    qty_total = qty_req.plus(qty_req.times(Big(bomItem.scrap_factor).div(100)));
                    total = cost.times(qty_total);
                    totalCost[price_break] = totalCost[price_break].add(total);
                    totalTariffable[price_break] = totalTariffable[price_break].add(tariffCost.times(qty_total));
                });
                // total=Sum(F('component__buy_price') * (F('qty_required') + (F('qty_required') * (F('scrap_factor') / Value(100))))
                if(typeof self.options.primary == 'undefined') {
                    self.options.primary = {};
                }
                if(typeof self.options.primary[price_break] == 'undefined') {
                    self.options.primary[price_break] = {};
                }

                self.fixed_costs.forEach(function(fixedCost) {
                    if (fixedCost.amortize) {
                        var cost = Big(fixedCost.cost);
                        totalCost[price_break] = totalCost[price_break].add(cost.div(qty));
                    }
                });
                angular.forEach(self.options, function(option_prices, option) {
                    if (option_prices.amortize) {
                        totalCost[price_break] = totalCost[price_break].add(Big(option_prices[price_break].cost));
                    }
                });
                self.options.primary[price_break].cost = totalCost[price_break].valueOf();
                self.options.primary[price_break].tariff = totalTariffable[price_break].times(tariffs).valueOf();

                if(self.options.primary[price_break].price && self.options.primary[price_break].margin) {
                    if(self.options.primary[price_break].lastUpdate == 'price') {
                        self.options.primary[price_break].margin = Big(self.options.primary[price_break].price).minus(Big(self.options.primary[price_break].cost)).div(Big(self.options.primary[price_break].price)).times(100).toFixed(2);
                    } else {
                        self.options.primary[price_break].price = Big(self.options.primary[price_break].cost).div(Big(1).minus( Big(self.options.primary[price_break].margin).div(100) )).toFixed(3);
                    }
                }
                if(!self.options.primary[price_break].margin && self.options.primary[price_break].price) {
                    self.options.primary[price_break].margin = Big(self.options.primary[price_break].price).minus(Big(self.options.primary[price_break].cost)).div(Big(self.options.primary[price_break].price)).times(100).toFixed(2);
                }
                if(!self.options.primary[price_break].margin && !self.options.primary[price_break].price) {
                    self.options.primary[price_break].margin = 30;
                }
                if(self.options.primary[price_break].margin && (!self.options.primary[price_break].price || Number(self.options.primary[price_break].price) == 0)) {
                    self.options.primary[price_break].price = Big(self.options.primary[price_break].cost).div(Big(1).minus( Big(self.options.primary[price_break].margin).div(100) )).toFixed(3);
                }

            });
        }

        function getSellPriceWithTariff(price_break) {
            /* jshint validthis: true */
            var self = this;

            return Big(self.options.primary[price_break].price).plus(self.options.primary[price_break].tariff).toFixed(3);
        }

        function getCostWithFreight(price_break) {
            /* jshint validthis: true */
            var self = this;

            return Big(self.freightEstimate || 0).div(price_break).plus(self.options.primary[price_break].cost).toFixed(3);
        }

        function addRFQOption(option) {
            /* jshint validthis: true */
            var self = this;

            self.options[option.name] = {};
            self.splits.forEach(function(split) {
                self.options[option.name][split] = {cost: option.getSplitCost(split)};
            });
            return self.options[option.name];

        }

        function addRFQFixedCost(option) {
            /* jshint validthis: true */
            var self = this;

            self.fixed_costs.push({
                name: option.name,
                cost: option.price,
                price: Big(option.price).div(0.7).toFixed(2)
            });

        }

        function getDuty(duty) {
            /* jshint validthis: true */
            var self = this;
            var price_break = self.getBreak();

            return Big(self.options.primary[price_break].cost).times(Big(duty || 0).div(100)).times(self.qty).valueOf();

        }

    }

})();
