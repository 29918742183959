(function() {
    "use strict";

    angular
        .module('slate.sampletracking')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('samplesList', {
                url: '/samples/',
                template: "<div class='content-pane list-page'><slate-samples-list></slate-samples-list></div>",
                current_app: 'samples',
                slateTitle: 'Sample Tracking',
            })

        ;
    }
})();
