(function () {
    "use strict";

    angular
        .module('slate.notifications')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
            .state('salesNotificationsPrefsListPage', {
                url: '/notifications/prefs/',
                template: "<div class=\"content-pane list-page\"><slate-notification-prefs-list></slate-notification-prefs-list></div>",
                current_app: 'notifications',
                slateTitle: 'Notifications Preferences',
            })
            .state('salesNotificationsListPage', {
                url: '/notifications/',
                template: "<div class=\"content-pane list-page\"><slate-notifications-list></slate-notifications-list></div>",
                current_app: 'notifications',
                slateTitle: 'Notifications',
            })
            ;
    }
})();