(function () {
    'use strict';

    angular
        .module('slate.filebrowser')
        .directive('slateEmbeddedUploader', modelList);

    function modelList() {
        return {
            restrict: 'E',
            // replace: 'true',
            templateUrl: '/partials/filebrowser/filebrowser.embeddedupload.dir.html',
            scope: {
                parentType: '=',
                parentId: '=',
                fileSrv: '=',
                path: '=?'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true

        };
    }

    function ListController($rootScope, $scope, $q, SlateFileNode) {
        /* jshint validthis: true */
        const vm = this;

        vm.saving = false;
        vm.errorSaving = false;
        vm.fileSrv = {};
        vm.fileSrv.files = [];
        vm.removeFile = removeFile;
        vm.fileSrv.uploadFiles = uploadFiles;

        if (!vm.path) { vm.path = ''; }

        function removeFile(index) {
            vm.fileSrv.files.splice(index, 1);
        }

        $scope.$watch('vm.filesAdd', () => {
            angular.forEach(vm.filesAdd, (file) => {
                if (vm.fileSrv) {
                    vm.fileSrv.files.push(file);
                }
            });
        });

        function uploadFiles(type, id) {
            if (vm.saving === true) { return false; }
            vm.saving = true;

            const promises = [];
            vm.savedFiles = [];
            const deferred = $q.defer();

            if (type) { vm.parentType = type; }
            if (id) { vm.parentId = id; }
            angular.forEach(vm.fileSrv.files, (file) => {
                const newFile = new SlateFileNode();

                const payload = {
                    parent_type: vm.parentType,
                    parent_fk: vm.parentId,
                    path: vm.path,
                    filename: file.name,
                    description: file.description
                };

                if (vm.fileSrv.path) { payload.path = vm.fileSrv.path; }

                newFile.loadFromPayload(payload);

                const filepromise = newFile.saveModel(file).then(() => {
                    vm.savedFiles.push(newFile);
                });
                promises.push(filepromise);
            });


            $q.all(promises).then(() => {
                vm.fileSrv.files = [];
                $rootScope.$broadcast('uploadComplete', vm.parentType, vm.parentId);
                vm.saving = false;
                deferred.resolve(vm);
            });

            return deferred.promise;
        }
    }
})();
