(function () {
    "use strict";

    angular
        .module('slate.bom')
        .directive('slateBomList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/bom/bom.list.dir.html',
            scope: {
                modelFilters: '=filters'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $window, $q, SlateAuth, SlateDeleteObjSrv, getLinkSrv, SlateProject, SlateBOM, SlateBOMs, SlateBOMItems, SlateBOMEditSrv, SlateRFQ, SlateRFQLineItem, SlateBOMAddCompsSrv) {
        /* jshint validthis: true */
        var vm = this;

        vm.bomList = new SlateBOMs();
        if ($location.search().openbom) { vm.bomList.toggleBom = true; }

        vm.auth = SlateAuth;

        vm.editObj = editObj;
        vm.newObj = newObj;
        vm.deleteBom = deleteBom;

        vm.getLink = getLinkSrv;
        vm.loading = true;
        vm.toggleBom = toggleBom;
        vm.duplicateObject = duplicateObject;

        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;

        vm.getDownloadUrl = getDownloadUrl;

        vm.createRFQ = createRFQ;
        vm.clientOnHold = clientOnHold;

        vm.has_selected_items = has_selected_items;

        vm.filters = {
            page: 1,
            count: 25,
        };

        vm.avail_fields = [
            'item_description',
            'version',
            //'finished_good',
            'client',
            'internal_item_number',
            'client_item_number',
            'weight_claim',
            'upc',
            'updated',
            'created',
            'created_by'
        ];
        vm.fields = vm.avail_fields;

        angular.extend(vm.filters, {
            q: $location.search().q,
            client: $location.search().client,
        }, vm.modelFilters, $location.search());

        if (vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if (vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }

        $scope.$watchCollection(function () { return vm.filters; }, function (value) {
            updateFromFilter();
        });

        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function (value, key) {
                if (key == 'page' || key == 'count' || key == 'order_by' || key == 'q') {
                    return false;
                }
                if (value === undefined || value === null) {
                    return false;
                }
                if (vm.filters[key] == vm.modelFilters[key]) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;

            });
            return hasData;
        }
        function updateFromFilter() {
            var modelPromise;

            vm.loading = true;

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = vm.bomList.getList(vm.filters);
            modelPromise.then(function () {
                if (vm.bomList.toggleBom) {
                    var bom = vm.bomList.list.find(function(bom) { return bom.id == $location.search().openbom; });
                    if (bom) { bom.toggled = true; }
                    vm.bomList.toggleBom = false;
                }
                vm.loading = false;
            });
            vm.url = $window.location.href;
        }

        function editObj(obj) {
            SlateBOMEditSrv.edit(obj).then(function () {
                updateFromFilter();
            });
        }

        function newObj(obj) {
            var newbom = new SlateBOM();


            var component = new SlateProject();
            newbom.finished_good = vm.modelFilters.finished_good;
            component.loadFromServer(vm.modelFilters.finished_good).then(function () {
                newbom.item_description = component.name;
                newbom.internal_item_number = component.internal_item_number;
                newbom.client_item_number = component.client_item_number;

                var project = new SlateProject();
                project.loadFromServer(component.parent_fk).then(function () {
                    if (project.parent_type == 'company') {
                        newbom.client = project.parent_fk;
                        SlateBOMEditSrv.edit(newbom).then(function () {
                            SlateBOMAddCompsSrv.edit(newbom.id).then(function() {
                                updateFromFilter();
                            }, function() { updateFromFilter(); });
                        });

                    }
                });

            });
        }

        function duplicateObject(bom) {
            if (window.confirm("Are you sure you want to copy this BOM?")) {
                bom.duplicate().then(function() {
                    editObj(bom);
                });
            }
        }


        function getDownloadUrl(bom) {
            var url = API_URL + 'boms/bom/' + bom.id + '/';
                return url + '?download=true';
        }


        function deleteBom(bom) {
            if (bom.finalized && !vm.auth.user.permissions.bom_can_edit_finalized_boms) {
                alert('BOM is finalized. Must duplicate or make new BOM.');
                return false;
            }
            SlateDeleteObjSrv.delete('bom', bom.id).then(function() {
                updateFromFilter();
            });
        }

        function toggleBom(bom, $event) {
            if($event.target.tagName.toUpperCase() == "A" || $event.target.parentElement.tagName.toUpperCase() == "A") { return false; }
            vm.bomList.list.forEach(function (item) { if(item.id != bom.id) { item.toggled = false; } });
            bom.toggled = !bom.toggled;
        }

        function has_selected_items() {
            if(!vm.bomItemsList || !vm.bomItemsList.list) { return false; }
            return vm.bomItemsList.list.some(function(item) { return item.selected; });
        }

        function clientOnHold() {
            return vm.bomList.list[0].client_info.on_hold;
        }

        function createRFQ(bom) {
            var rfq = new SlateRFQ();
            var project = new SlateProject();

            if(!has_selected_items()) { return false; }

            vm.creatingRFQ = true;

            rfq.status = 'Draft';
            rfq.name = bom.item_description;
            rfq.component = bom.finished_good;
            rfq.company = bom.client;
            rfq.notes = bom.assembly_instructions;

            return project.loadFromServer(bom.finished_good).then(function() {
                if(project.breadcrumbs && project.breadcrumbs.length > 1) {
                    rfq.project = project.breadcrumbs[1].parent_fk;
                }
            }).then(function() {
                var promises = [];
                vm.bomItemsList.list.forEach(function(bom_item, idx) {
                    var rfq_item = new SlateRFQLineItem();
                    var component = new SlateProject();

                    if(!bom_item.selected) { return false; }

                    rfq_item.sort_order = idx;
                    rfq_item.component = bom_item.component;
                    rfq_item.qty = 0;
                    rfq_item.unit_name = bom_item.unit_name;
                    rfq_item.category = bom_item.category;
                    rfq_item.material = bom_item.material;
                    rfq_item.specs = bom_item.notes;

                    if(!bom_item.component) {
                        rfq.line_items.push(rfq_item);
                    }

                    promises.push(component.loadFromServer(bom_item.component).then(function() {
                        rfq_item.category = component.category;
                        rfq_item.material = component.material;
                        rfq_item.specs = component.brief;
                        rfq.line_items.push(rfq_item);
                        return rfq;
                    }));

                });
                return $q.all(promises).then(function() {
                    return rfq.saveModel().then(function() {
                        $state.transitionTo(
                            'rfqEditPage',
                            {id: rfq.id}
                        );
                    });
                });


            });

        }
    }


})();
