(function () {
    "use strict";

    angular
        .module('slate.purchaseorders')
        .service('SlatePOCartonMarkingSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlatePurchaseOrder) {
        var templateUrl = '/partials/purchaseorders/purchaseorder.cartonmarkingtemplate.edit.sidebar.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj, templateId) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                windowClass: 'modal-sidebar right',
                resolve: {
                    obj: function () {
                        var po = new SlatePurchaseOrder();
                        if (!obj) {
                            return po;
                        }
                        if (typeof obj == 'string') {
                            return po.loadFromServer(obj);
                        }
                        return po.loadFromPayload(obj);
                    },
                    templateId: function() {
                        return templateId;
                    }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, SlateAuth, SlateCartonMarkings, obj, templateId) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;
        vm.auth = SlateAuth;
        vm.template = templateId;
        vm.cartonMarkings = new SlateCartonMarkings();
        
        vm.saving = false;
        vm.loading = true;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.isComplete = isComplete;

        function isComplete() {
            if (!vm.template) { return false; }
            return true;
        }

        function saveObject() {
            $uibModalInstance.close(vm.templateObj);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.cartonMarkings.getList({company: vm.obj.company}).then(function() {
            vm.loading = false;
        }, function() {
            vm.loading = false;
        });

    }

})();
