(function() {
    "use strict";

    angular
        .module('slate.filebrowser')
        .service('SlateFileVerUpload', editDialog);


    function editDialog($rootScope, $q, $uibModal) {
        var templateUrl = '/partials/filebrowser/filebrowser.newver.dialog.html';

        var srv = {
            edit: editObj
        };
        return srv;


        function editObj(obj) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                size: 'lg',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',

                resolve: {
                    obj: function() {
                            return obj;
                    },
                }
            });

            return modalInstance.result.then(function(results) {
                return results;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, obj) {
        /* jshint validthis: true */
        var vm = this;

        vm.obj = obj;

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.file = null;

        function saveObject() {
            vm.saving = true;
            vm.obj.filename = vm.file.name;

            return vm.obj.saveModel(vm.file).then(function() {
                vm.saving = false;
                $uibModalInstance.close(vm.savedFiles);
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function removeFile(index) {
            vm.file = null;
        }

    }



})();
