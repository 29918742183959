(function () {
    'use strict';

    angular
        .module('slate.helpdesk')
        .controller('SlateTicketEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $q, $timeout, $state, $location, SlateTitle, SlateAuth, getLinkSrv, SlateDateChangeSrv, $stateParams, SlateTicket, SlateTicketLog, SlateTicketLogs, SlateProfilePhotoSrv) {
        /* jshint validthis: true */
        const vm = this;
        vm.loading = true;
        vm.auth = SlateAuth;

        vm.save = save;
        vm.postMessage = postMessage;
        vm.isComplete = isComplete;

        vm.ticket = new SlateTicket();
        vm.logs = new SlateTicketLogs();

        vm.froalaOptions = {
            toolbarButtons: ['bold',
                'italic',
                'underline',
                '|',
                'align',
                'formatOL',
                'formatUL'],
            zIndex: 50
        };

        vm.ticket.loadFromServer($stateParams.id).then(() => {
            vm.baseStatus = vm.ticket.status;
            if (vm.ticket.name) {
                SlateTitle.set(`Edit ${vm.ticket.name} - Helpdesk`);
            } else {
                SlateTitle.set('Edit Untitled Ticket - Helpdesk');
            }
            vm.base_expected_time = vm.ticket.expected_time;
            vm.logs.getList({ ticket: vm.ticket.id });
            vm.loading = false;
        }).catch(angular.noop);

        $scope.$watch('vm.ticket.name', () => {
            if (vm.ticket.name) {
                SlateTitle.set(`Edit #${vm.ticket.id} ${vm.ticket.name} - Helpdesk`);
            } else {
                SlateTitle.set('Edit Untitled Ticket - Helpdesk');
            }
        });

        function isComplete() {
            if (vm.ticket.assigned_to && !vm.ticket.expected_time) { return false; }
            if (vm.ticket.submitter !== vm.auth.user.contact_id) {
                if (vm.ticket.status === 'closed' && !vm.ticket.actual_time) { return false; }
            }
            return true;
        }

        function save() {
            if (vm.saving) { return false; }
            if (!isComplete()) { return false; }
            vm.saving = true;

            return vm.ticket.saveModel().then(() => {
                const promises = [];
                const parent_fk = vm.ticket.id;
                const parent_type = 'helpdeskticket';
                promises.push(vm.fileSrv.uploadFiles(parent_type, parent_fk));
                return $q.all(promises).then(() => {
                    $timeout(() => {
                        vm.saving = false;
                        vm.baseStatus = vm.ticket.status;
                        vm.base_expected_time = vm.ticket.expected_time;
                        vm.logs.getList({ ticket: vm.ticket.id });
                    }, 300);
                });
            }, (e) => {
                vm.saving = false;
                vm.error = true;
            });
        }

        function postMessage() {
            const log = new SlateTicketLog();
            log.message = vm.newMessage;
            log.ticket = vm.ticket.id;
            log.type = 'response';
            vm.newMessage = '';
            log.saveModel().then(() => {
                vm.logs.getList({ ticket: vm.ticket.id });
            });
        }
    }
})();
