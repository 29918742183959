(function() {
    "use strict";

    angular
        .module('slate.inspections')
        .controller('SlateInspectionEditPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $state, $location, SlateTitle, getLinkSrv, $stateParams, SlateInspection) {
        /* jshint validthis: true */
        var vm = this;
        vm.loading = true;
        vm.inspection = new SlateInspection();

        vm.inspection.loadFromServer($stateParams.id).then(function () {
            if (vm.inspection.name) {
                SlateTitle.set('Edit ' + vm.inspection.name + ' - Inspection');
            } else {
                SlateTitle.set('Edit Untitled Inspection - Inspections');
            }
            vm.loading = false;
            
        }).catch(angular.noop);

        $scope.$watch('vm.inspection.name', function () {
            if (vm.inspection.name) {
                SlateTitle.set('Edit ' + vm.inspection.name + ' - Logistics');
            } else {
                SlateTitle.set('Edit Untitled Inspection - Inspections');
            }
        });

    }

})();


