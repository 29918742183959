(function () {
    "use strict";

    angular
        .module('slate.reports')
        .directive('slateReportHoursPerUser', modelList);

    function modelList() {
        return {
            restrict: 'E',
            templateUrl: '/partials/reports/reports.report.hours_per_user.dir.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ReportController($scope, $http, $q, $location, $httpParamSerializer, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;
        var apiUrl = API_URL + 'reports/hours_by_user/';
        vm.getLink = getLinkSrv;

        var modelPromise;

        vm.getDownloadUrl = getDownloadUrl;
        vm.updateFromFilter = updateFromFilter;
        vm.getHeight = getHeight;

        vm.data = [];
        vm.filters = { page: 1, count: 5 };

        angular.extend(vm.filters, $location.search());
        if (vm.filters.start_date) { vm.start_date = dateFns.parse(vm.filters.start_date); }
        if (vm.filters.end_date) { vm.end_date = dateFns.parse(vm.filters.end_date); }
        if (vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if (vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }

        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            if (vm.filters.contact && vm.filters.start_date && vm.filters.end_date) {
                updateFromFilter();
            }
        });

        $scope.$watch('vm.start_date', function () {
            if (vm.start_date) {
                vm.filters.start_date = dateFns.format(vm.start_date, 'YYYY-MM-DD');
            } else {
                vm.filters.start_date = null;
            }
        });
        $scope.$watch('vm.end_date', function () {
            if (vm.end_date) {
                vm.filters.end_date = dateFns.format(vm.end_date, 'YYYY-MM-DD');
            } else {
                vm.filters.end_date = null;
            }
        });

        function updateFromFilter() {
            if (vm.canceller) { self.canceller.resolve(); }
            vm.canceller = $q.defer();

            vm.loading = true;

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = $http.get(apiUrl, {
                params: vm.filters,
                timeout: vm.canceller.promise,
            });

            modelPromise.then(function (resp) {
                vm.data = resp.data.payload;
                vm.data.forEach(function (day) {
                    day.date = dateFns.parse(day.date);
                });
                vm.pagination = resp.data.pagination;
                vm.canceller = null;
                vm.loading = false;
            });
        }

        function getHeight(task, day, index) {
            var height;
            if (task.hours_spent > 1) {
                height = 8 + (task.hours_spent * 100) + 'px';
            } else {
                height = '100px';
            }

            return {
                height: height,
            };
        }

        function getDownloadUrl() {
            return apiUrl + '?download=true&' + $httpParamSerializer(vm.filters);
        }
    }
})();