(function () {
    "use strict";

    angular
        .module('slate.inspections')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider
            .state('inspectionListPage', {
                url: '/inspections/',
                template: "<div class=\"content-pane list-page\"><slate-inspection-list></slate-inspection-list></div>",
                current_app: 'inspections',
                slateTitle: 'Inspections',
            })
            .state('inspectionEditPage', {
                url: '/inspections/inspection/:id/edit/',
                templateUrl: '/partials/inspections/inspection.edit.page.html',
                controller: 'SlateInspectionEditPageController',
                controllerAs: 'vm',
                current_app: 'inspections',
                slateTitle: 'Edit Inspection - Inspections',
            })

            ;
    }
})();