(function () {
    'use strict';

    angular
        .module('slate.vendorsetuprequests')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('requestListPage', {
                url: '/vendorsetuprequests/tickets/',
                template: "<div class='content-pane list-page'><slate-vendor-request-list hide-filters='false'></slate-vendor-request-list></div>",
                current_app: 'vendorsetuprequests',
                slateTitle: 'Vendor Setup Requests'
            })
            .state('requestEditPage', {
                url: '/vendorsetuprequests/:id/',
                templateUrl: '/partials/vendorsetuprequests/ticket.edit.page.html',
                controller: 'SlateVendorSetupRequestEditPageController',
                controllerAs: 'vm',
                current_app: 'vendorsetuprequests',
                slateTitle: 'Edit Request - Vendor Setup Requests'
            });
    }
})();
