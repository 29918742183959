(function() {
    "use strict";

    angular
        .module('slate.roles')
        .directive('slateRolesControlPanel', slateDir);

    function slateDir () {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/roles/roles.cp.list.dir.html',
            scope: {
                roles: '=',

            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function ListController($scope) {
        /* jshint validthis: true */
        var vm = this;

    }


})();
