(function () {
    "use strict";

    angular
        .module('slate.bom')
        .service('SlateBOMAddCompsSrv', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateBOM) {
        var templateUrl = '/partials/bom/bomitem.addcomps.sidebar.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(bom) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                windowClass: 'modal-sidebar right',
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    bomId: function () { return bom; }
                }
            });

            return modalInstance.result.then(function (results) {

                return results;
            }, function () {

                return $q.reject();
            });

        }
    }

    function editDialogCtrl($scope, $q, $uibModalInstance, bomId, SlateProject, SlateProjects, SlateBOMItems, SlateBOMItem, SlateBOM) {
        /* jshint validthis: true */
        var vm = this;

        vm.saving = false;
        vm.loading = true;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;
        vm.isComplete = isComplete;

        vm.compUsed = componentUsed;
        vm.adjustAdd = adjustAdd;

        vm.shouldShowComp = shouldShowComp;

        vm.bom = new SlateBOM();
        vm.bomItems = new SlateBOMItems();
        vm.addedComponents = [];
        vm.availComponents = new SlateProjects();
        vm.bom.loadFromServer(bomId).then(function () {
            vm.addedComponents.push(vm.bom.finished_good);
            vm.bomItems.getList({ bom: vm.bom.id }).then(function () {
                var addedComps = [];
                angular.forEach(vm.bomItems.list, function (item) {
                    vm.addedComponents.push(item.component);
                });
                getProject().then(function(proj) {
                    vm.availComponents.getList({project: proj.id, recursive: true, finished_or_variation: true, fields: ['id', 'name', 'variation_name', 'composite_num', 'job_number', 'composite_number', 'variation_number', 'category', 'material', 'supplier', 'scrap_factor', 'unit_name', 'vendor_item_number', 'client_item_number', 'brief']}).then(function() {
                        vm.loading = false;
                    });

                });

            });
        });

        function getProject() {
            if (!vm.bom.finished_good) { return false; }
            var deferred = $q.defer();

            var component = new SlateProject();

            component.loadFromServer(vm.bom.finished_good).then(function () {
                var project = new SlateProject();

                return project.loadFromServer(component.parent_fk).then(function () {
                    var gp = new SlateProject();
                    if (project.parent_type == 'company') {
                        return deferred.resolve(project);
                    }
                    return gp.loadFromServer(project.parent_fk).then(function () {
                        if (gp.parent_type == 'company') {
                            deferred.resolve(gp);
                        }
                    });
                });
            });

            return deferred.promise;
        }

        function componentUsed(compId) {
            return vm.addedComponents.includes(compId);
        }

        function shouldShowComp(comp) {
            if(componentUsed(comp.id)) { return false; }
            if(!vm.filter) { return true; }
            if(comp.composite_num.toLowerCase().indexOf(vm.filter.toLowerCase()) !== -1) { return true; }
            if(comp.name.toLowerCase().indexOf(vm.filter.toLowerCase()) !== -1) { return true; }
            return false;
        }

        function saveObject(skip) {
            if (vm.saving === true) {
                return false;
            }
            vm.saving = true;
            var deferred = $q.defer();
            var promises = [];

            angular.forEach(vm.availComponents.list, function(comp) {
                if(!comp.addToBOM) { return false; }
                var newbomitem = new SlateBOMItem();
                newbomitem.bom = vm.bom.id;
                newbomitem.component = comp.id;
                newbomitem.vendor_item_number = comp.vendor_item_number;
                newbomitem.client_item_number = comp.client_item_number;
                newbomitem.internal_item_number = comp.internal_item_number;
                newbomitem.qty_required = comp.qty_required;
                newbomitem.notes = comp.brief;

                newbomitem.item_description = comp.supplier;
                newbomitem.vendor = comp.vendor;

                newbomitem.category = comp.category;
                newbomitem.material = comp.material;
                newbomitem.unit_name = comp.unit_name;
                newbomitem.scrap_factor = comp.scrap_factor;
                newbomitem.supplier = comp.supplier;
                if(!newbomitem.supplier) {
                    newbomitem.supplier = 'jansy';
                }

                newbomitem.item_description = comp.name;

                if(comp.internal_item_number) {
                    newbomitem.internal_item_number = comp.internal_item_number;
                } else {
                    newbomitem.internal_item_number = comp.composite_num;
                }

                promises.push(newbomitem.saveModel());

            });

            $q.all(promises).then(function () {
                vm.bom.saveModel().then(function() {
                    vm.saving = false;
                    $uibModalInstance.close();
                });
            }, function () {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            vm.saving = false;
            $uibModalInstance.dismiss('cancel');
        }


        function detailsIsComplete() {
            return true;
        }

        function isComplete() {
            var comps = vm.availComponents.list;
            for (var i = 0; i < comps.length; i++) {
                if(comps[i].addToBOM && (!comps[i].qty_required || comps[i].qty_required <= 0)) { return false; }
            }
            return true;
        }

        function adjustAdd(comp) {
            if(comp.qty_required) {
                comp.addToBOM = true;
            } else {
                comp.addToBOM = false;
            }
        }

    }



})();
