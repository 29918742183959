(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .controller('SlateRfqResponseComparisonPageController', SlateDetailsPageController);


    function SlateDetailsPageController($scope, $http, $timeout, $state, $location, SlateTitle, getLinkSrv, $q, $stateParams, SlateAuth, SlateRFQ) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;
        vm.loading = true;

        vm.data = {};
        vm.rfq = new SlateRFQ();

        function getData(id) {
            var promises = [];
            var apiUrl = API_URL + 'rfq/responses/' + id + '/comparison/';
            vm.loading = true;

            promises.push(vm.rfq.loadFromServer(id));
            promises.push($http.get(apiUrl).then(function(response) {
                vm.data = response.data.payload;
                vm.data.line_item_responses.forEach(function(item) {
                    item.splits.forEach(function(split) {
                        var min = null;
                        // item.responses.forEach(function(response) {
                        //     if (!min || response.prices[split] < min.prices[split]) {
                        //         min = response;
                        //     }
                        // });
                    });
                });
            }));
            $q.all(promises).then(function() {
                vm.loading = false;
            });
        }
        getData($stateParams.id);

    }

})();
