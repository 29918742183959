(function () {
    "use strict";

    angular
        .module('slate.salesquotes')
        .directive('slateVendorEstimateResponseView', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/estimates/vendorestimates.view.dir.html',
            scope: {
                estimate: '=',
                response: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController(
        $scope,
        $timeout,
        $q,
        $window,
        $state,
        $http,
        getLinkSrv,
        SlateAuth,
        SlateCompany,
        SlateLocations,
        SlateVendorEstimateResponses
    ) {
        /* jshint validthis: true */
        var vm = this;
        vm.getLink = getLinkSrv;
        vm.auth = SlateAuth;

        vm.uploadFiles = uploadFiles;

        function uploadFiles() {
            vm.uploading = true;
            vm.fileSrv.uploadFiles('vendorestimateresponse', vm.response.id).then(function() {
                vm.uploading = false;
            });
        }
    }

})();
