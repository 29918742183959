(function () {
    "use strict";

    angular
        .module('slate.invoices')
        .config(slateRoutes);

    function slateRoutes($stateProvider) {
        $stateProvider

            .state('invoicesListPage', {
                url: '/invoices/',
                template: "<div class=\"content-pane list-page\"><slate-invoice-list></slate-invoice-list></div>",
                current_app: 'invoices',
                slateTitle: 'Invoice Requests',
            })
            .state('invoiceEmailsListPage', {
                url: '/invoices/email/',
                template: "<div class=\"content-pane list-page\"><slate-invoice-emails-list></slate-invoice-emails-list></div>",
                current_app: 'invoices',
                slateTitle: 'Invoice Emails',
            })
            .state('invoiceEmailEditPage', {
                url: '/invoices/email/:id/edit/',
                templateUrl: '/partials/invoices/invoices.emails.edit.page.html',
                controller: 'SlateInvoiceEmailEditPageController',
                controllerAs: 'vm',
                current_app: 'invoices',
                slateTitle: 'Edit Invoice Email',
            })

            ;
    }
})();