(function() {
    "use strict";

    angular
        .module('slate.library.inspections')
        .factory('SlateInspectionLineItems', ModelList)
        .factory('SlateInspectionLineItem', ModelDetail);

    var apiUrl = API_URL + 'inspections/lineitems/';

    function ModelList($http, $q, SlateInspectionLineItem) {
        // Set to url relative to api root.

        // instantiate our initial object
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];

            if (typeof filters === "object") {
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data, function(item) {
                    var itemModel = new SlateInspectionLineItem();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.permissions = response.data.permissions;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

    }

    function ModelDetail($http, $q, SlateInspectionResult) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', readonly: true },
            {name: 'inspection'},

            {name: 'status'},
            {name: 'inspected', readonly: true},

            {name: 'po_lineitem'},
            {name: 'po_lineitem_info', readonly: true},
            {name: 'parent_info', readonly: true},
            {name: 'project_info', readonly: true},

            {name: 'qc_date', type: 'dateOnly'},
            {name: 'qty', def: 0},
            {name: 'factory_confirmed'},

            {name: 'files', type: 'json', def: {}},
            
            {name: 'inspection_results', type:'child_list', model: SlateInspectionResult},

            {name: 'created', readonly: true},
            {name: 'updated', readonly: true},
        ];
        /* beautify preserve:end */


        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        //model.prototype.loadFromServer = loadFromServer;
        model.prototype.getModelData = getModelData;
        //model.prototype.saveModel = saveModel;
        //model.prototype.deleteModel = deleteModel;
        model.prototype.getSellTotal = getSellTotal;
        return model;

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = field.def;
                if(field.type=="date" && !self[field.name]) {
                    self[field.name] = new Date();
                }
                if(field.type=="child_list") {
                    self[field.name] = [];
                    if (field.name == 'inspection_results') {
                        self[field.name].push(new SlateInspectionResult());
                    }
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                if(!payload[field.name]) {
                    return false;
                }

                self[field.name] = payload[field.name];

                if(field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type == 'dateOnly' && payload[field.name]) {
                    if(typeof payload[field.name] == 'string') {
                        var parts = self[field.name].split('-');
                        self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
                    }
                }
                if(field.type == 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach(function(item, idx, array) {
                            var obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch(e) {
                        console.log('error loading: ', e);
                    }
                }

            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];

                return self.loadFromPayload(response.data.payload).then(function() {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function getModelData() {
            /* jshint validthis: true */
            var self = this;
            var data = {};

            angular.forEach(fields, function(field) {
               if ((!field.readonly || field.name == 'id') && typeof(self[field.name]) != 'undefined') {
                    data[field.name] = self[field.name];
                    if(field.type == "date") {
                        data[field.name] = self[field.name].getUTCFullYear() + '-' + (self[field.name].getUTCMonth()+1) + '-' + self[field.name].getUTCDate();
                    }
                    if(field.type == "dateOnly" && self[field.name]) {
                        data[field.name] = dateFns.format(self[field.name], 'YYYY-MM-DD');
                    }
                    if(field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if(field.type == "child_list" && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach(function(item) {
                            if(item.toDelete) { return false; }
                            data[field.name].push(item.getModelData());
                        });
                    }
                }
            });

            return data;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            data = getModelData();

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

        function getSellTotal() {
            /* jshint validthis: true */
            var self = this;
            var total = 0;
            var qty;
            var cost;
            var freight;

            try { cost = new Big(self.sellprice); } catch(e) { cost = new Big(0); }
            try { qty = new Big(self.qty); } catch(e) { qty = new Big(0); }

            total = qty.times(cost);
            console.log(total.toFixed(5));
            return total.toFixed(5);
        }

    }

})();
