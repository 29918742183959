(function() {
    "use strict";

    angular
        .module('slate.approvals')
        .service('SlateApprovalEdit', editDialog);


    function editDialog($rootScope, $q, $uibModal, SlateContact, SlateCompany) {
        var templateUrl = '/partials/approvals/approvals.edit.dialog.html';

        var srv = {
            edit: editObj
        };


        return srv;


        function editObj(obj, company_fk, root_type, root_fk) {
            var modalInstance;

            modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: editDialogCtrl, //editController,
                controllerAs: 'vm',
                resolve: {
                    obj: function() { return obj; },
                    co_id: function () { return company_fk; },
                    root_fk: function () { return root_fk; },
                    root_type: function () { return root_type; },
                }
            });

            return modalInstance.result.then(function(results) {
                return obj;
            }, function() {
                return $q.reject();
            });

        }
    }

    function editDialogCtrl($q, $uibModalInstance, SlateCompany, SlateApprovalStep, SlateContacts, obj, co_id, root_type, root_fk) {
        /* jshint validthis: true */
        var vm = this;
        var promises = [];

        vm.obj = obj;
        vm.co_id = co_id;

        vm.saving = false;
        vm.errorSaving = false;
        vm.saveObject = saveObject;
        vm.cancel = cancel;

        vm.addStep = addStep;
        vm.removeStep = removeStep;

        vm.company = new SlateCompany();
        vm.contactOptions = [];
        vm.companyContacts = new SlateContacts();
        vm.staffContacts = new SlateContacts();

        if(vm.co_id) {
            promises.push(vm.company.loadFromServer(vm.co_id));
            promises.push(vm.companyContacts.getList({company: vm.co_id}));
        }
        promises.push(vm.staffContacts.getList({staff: true}));


        
        vm.dragOptions = {
        };

        function saveObject() {
            vm.saving = true;
            obj.saveModel(root_type, root_fk).then(function() {
                vm.saving = false;
                $uibModalInstance.close(vm.obj);
            }, function() {
                vm.saving = false;
                vm.errorSaving = true;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function addStep() {
            var step = new SlateApprovalStep();
            vm.obj.steps.list.push(step);
        }

        function removeStep(step) {
            var index;

            if(step.id) {
                step.toDelete = true;
                return step;
            }

            index = vm.obj.steps.list.indexOf(step);
            if(index > -1) {
                vm.obj.steps.list.splice(index, 1);
            }


        }


        $q.all(promises).then(function() {
            var options = [];
            if(vm.allowRoles) {
                angular.forEach(vm.availRoles.assignable, function(role) {
                    options.push({
                        source: 'Groups',
                        name: role.name,
                        member_type: 'role',
                        member_fk: role.id
                    });
                });
            }
            if(vm.companyContacts.list.length) {
                angular.forEach(vm.companyContacts.list, function(contact) {
                    options.push({
                        source: vm.company.name,
                        name: contact.full_name,
                        member_type: 'contact',
                        member_fk: contact.id
                    });
                });
            }
            angular.forEach(vm.staffContacts.list, function(contact) {
                options.push({
                    source: 'staff',
                    name: contact.full_name,
                    member_type: 'contact',
                    member_fk: contact.id
                });

            });
            vm.contactOptions = options;
        });


    }



})();
