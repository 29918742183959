(function() {
    "use strict";

    angular
        .module('slate.projects')
        .directive('slateProjectsList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/projects/projects.list.dir.html',
            scope: {
                modelFilters: '=filters',
                variations: '=?',
                rootOnly: '=?',
                view: '=?',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $q, $window, SlateAuth, SlateTitle, SlateProjects, SlateProject, SlateProjectEdit, getLinkSrv, SlateDeleteObjSrv) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;
        vm.get_url = get_url;
        vm.updateFromFilter = updateFromFilter;
        vm.hasFilterData = hasFilterData;

        vm.loading = true;
        vm.filters = {
            page: 1,
            count: 50,
        };

        vm.fields = ['id', 'job_number', 'component_number', 'variation_number', 'name', 'slug', 'finished_good', 'composite_num', 'parent_info', 'project_type', 'spend', 'status', 'proj_margin', 'created', 'updated'];

        vm.projectTypes = ['Production', 'Design', 'Speculative', 'Pricing', 'Component', 'Primary', 'Secondary', 'Gift Set', 'Smart Stock', 'Turn Key', 'Protective Packaging'];

        angular.extend(vm.filters, {
            //vendor: $location.search().vendor || false,
            q: $location.search().q
        }, vm.modelFilters);
        if(vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if(vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }


        if(vm.filters.q === null) { vm.filters.q = ''; }

        if(!vm.modelFilters || (vm.modelFilters.length == 1 && typeof vm.modelFilters.root_only != 'undefined')) {
            SlateTitle.set('Projects');
        }

        vm.projectsList = new SlateProjects();

        vm.addNew = addNewProject;
        vm.editComponent = editComponent;
        vm.deleteProject = deleteProject;
        vm.updateFromFilter = updateFromFilter;
        vm.canAddProject = canAddProject;

        $scope.$watchCollection(function() {
            return vm.filters;
        }, function(value) {
            updateFromFilter();
        });

        function hasFilterData() {
            var hasData = false;
            angular.forEach(vm.filters, function(value, key) {
                if(key == 'page' || key == 'count' || key == 'q') {
                    return false;
                }
                if(value === undefined || value === null || value === '') {
                    return false;
                }
                if(vm.filters[key] == vm.modelFilters[key]) {
                    return false;
                }
                hasData = true;
                vm.showFilters = true;
                return true;

            });
            return hasData;
        }

        function updateFromFilter() {
            var modelPromise;
            
            angular.forEach(vm.filters, function(value, item) {
                if (vm.filters[item] && !vm.modelFilters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = vm.projectsList.getList(angular.extend({}, vm.filters,{
                //root_only: vm.rootOnly,
                fields: vm.fields,
            }));
            modelPromise.then(function() {
                vm.loading = false;
            });

        }

        function canAddProject() {

            if(vm.modelFilters && vm.modelFilters.project) { return true; }
            if(!vm.auth.perms.projects_can_create) { return false; }
            if(vm.modelFilters && vm.modelFilters.company) { return true; }

            return false;
        }

        function addNewProject($event) {
            var newProject = new SlateProject();
            var promise;
            if (vm.modelFilters.company) {
                newProject.parent_type = 'company';
                newProject.parent_fk = vm.modelFilters.company;
            }
            if (vm.modelFilters.project) {
                newProject.parent_type = 'project';
                newProject.parent_fk = vm.modelFilters.project;
            }

            promise = SlateProjectEdit.edit(newProject, newProject.parent_type, newProject.parent_fk);
            promise.then(function(obj) {
                if(!obj.skip) {
                    $location.path(getLinkSrv.getUrl('project', newProject.id, newProject.slug)).search({});
                } else {
                    updateFromFilter();
                }

            }, function() {

            });
            return promise;
        }

        function editComponent(project) {
            var promise;
            promise = SlateProjectEdit.edit(project);
            promise.then(function(newProject) {
                if (newProject.slug != project.slug) {
                    $location.path(getLinkSrv.getUrl('project', newProject.id, newProject.slug)).search({});
                } else {
                    $state.reload();
                }
            }, function() {

            });
            return promise;
        }

        function deleteProject(project) {
            SlateDeleteObjSrv.delete('project', project.id).then(function() {
                updateFromFilter();
            });
        }

        function get_url() {
            var url =  API_URL + 'projects/';
            if($window.location.search.length ) {
                return url + $window.location.search + '&download=true';
            } else {
                return url + '?download=true';
            }
        }

    }


})();
