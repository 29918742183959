(function() {
    "use strict";

    angular
        .module('slate.salesorders')
        .directive('slateSoReleaseList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/salesorders/releases/releases.list.dir.html',
            scope: {
                salesOrder: '=',
                lineItem: '=',
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $window, $state, $location, $q, $timeout, SlateItemStockDialog, SlateSOLineItemPriceTierEditSrv, SlateSOLineItems, SlateSOLineItem, SlateSOLineItemEditSrv, SlateBOMItems, SlatePriceTier, getLinkSrv, SlateAuth) {
        /* jshint validthis: true */
        var vm = this;
        vm.auth = SlateAuth;


    }


})();
