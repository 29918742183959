(function () {
    "use strict";

    angular
        .module('slate.rfqs')
        .directive('slateGoodsList', modelList);

    function modelList() {
        return {
            restrict: 'E',
            //replace: 'true',
            templateUrl: '/partials/logistics/goods.list.dir.html',
            scope: {
                modelFilters: '=filters'
            },
            controller: ListController,
            controllerAs: 'vm',
            bindToController: true,

        };
    }

    function ListController($scope, $state, $location, $window, $q, SlateGood, SlateGoods, getLinkSrv) {
        /* jshint validthis: true */
        var vm = this;

        vm.getLink = getLinkSrv;
        vm.loading = true;

        vm.updateFromFilter = updateFromFilter;
        vm.getPOs = getPOs;
        vm.poMissingOrigin = poMissingOrigin;

        vm.filters = {
            page: 1,
            count: 25,
        };

        vm.avail_fields = ['name',
                    'client',
                    'vendor',
                    'po_name',
                    'item_num',
                    'lot',
                    'production_date',
                    'qty',
                    'unit',
                    'notes',
                    'updated',
                    'created_by'
        ];
        vm.fields = vm.avail_fields;

        angular.extend(vm.filters, {
            q: $location.search().q,
            startDate: $location.search().startDate,
            endDate: $location.search().endDate,
            client: $location.search().client,
            vendor: $location.search().vendor,
            company: $location.search().company,
            sales_mgr: $location.search().sales_mgr,
            proj_mgr: $location.search().proj_mgr,
        }, vm.modelFilters, $location.search());

        if(vm.filters.page) { vm.filters.page = parseInt(vm.filters.page); }
        if(vm.filters.count) { vm.filters.count = parseInt(vm.filters.count); }
        if(vm.filters.startDate) { vm.start = new Date(vm.filters.startDate); }
        if(vm.filters.endDate) { vm.end = new Date(vm.filters.endDate); }

        console.log('test goods list');

        vm.goodsList = new SlateGoods();

        $scope.$watch('vm.start', function () {
            if (vm.start) {
                vm.filters.startDate = vm.start.toISOString();
            } else {
                delete (vm.filters.startDate);
            }
        });
        $scope.$watch('vm.end', function () {
            if (vm.end) {
                vm.filters.endDate = vm.end.toISOString();
            } else {
                delete (vm.filters.endDate);
            }
        });

        $scope.$watchCollection(function () {
            return vm.filters;
        }, function (value) {
            updateFromFilter();
        });


        function updateFromFilter() {
            var modelPromise;

            vm.loading = true;

            angular.forEach(vm.filters, function (value, item) {
                if (vm.filters[item]) {
                    $location.replace().search(item, vm.filters[item]);
                } else {
                    $location.replace().search(item, null);
                }
            });

            modelPromise = vm.goodsList.getList(vm.filters);
            modelPromise.then(function () {
                vm.loading = false;
            });
            vm.url = $window.location.href;
        }

        function getPOs() {
            console.log('getting POs');
            var po_ids = [];
            vm.goodsList.list.forEach(function(item) {
                if(!po_ids.includes(item.purchaseorder)) {
                    po_ids.push(item.purchaseorder);
                }
            });
            return po_ids;
        }

        function poMissingOrigin(po_id) {
            return vm.goodsList.list.some(function(good) {
                if(good.purchaseorder != po_id) { return false; }
                if(!good.origin_from || !good.origin_warehouse) { return true; }

                return false;
            });
        }

    }


})();
