(function() {
    "use strict";

    angular
        .module('slate.library.salesquotes')
        .factory('SlateSalesQuotes', ModelList)
        .factory('SlateSalesQuote', ModelDetail);

    var apiUrl = API_URL + 'salesquotes/quotes/';

    function ModelList($http, $q, SlateSalesQuote) {
        var list = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        self.pagination = {
            total: 0,
            page: 1,
            count: 25
        };
        list.prototype.clear = clear;
        list.prototype.getList = getList;

        return list;

        function clear() {
            /* jshint validthis: true */
            var self = this;

            self.list = [];
            self.filters = {};
            self.permissions = [];

            return self;
        }

        function getList(filters) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promise;
            var promises = [];
            var avail_fields = new SlateSalesQuote().getFieldsList();
            var update_filter = {};
            var update_fields = [];

            if(self.canceller) { self.canceller.resolve(); }
            self.canceller = $q.defer();

            if (typeof filters === "object") {
                if(filters.fields) {
                    filters.fields.forEach(function(item) {
                        update_fields.push(item);
                        if(avail_fields.includes(item+'_info')) {
                            update_fields.push(item+'_info');
                        }
                    });
                    update_filter.fields = update_fields;
                }
                self.filters = filters;
            }

            promise = $http.get(apiUrl, {
                params: self.filters,
                timeout: self.canceller.promise
            });

            promise.then(function(response) {
                var newlist = [];
                //set this for correct model
                angular.forEach(response.data.payload, function(item) {
                    var itemModel = new SlateSalesQuote();
                    itemModel.loadFromPayload(item);

                    newlist.push(itemModel);
                });

                self.list = newlist;
                self.pagination = {
                    total: parseInt(response.data.pagination.total),
                    page: parseInt(response.data.pagination.page),
                    count: parseInt(response.data.pagination.count),
                };
                self.permissions = response.data.permissions;
                self.canceller = null;

                deferred.resolve(self);
            });

            return deferred.promise;
        }

    }

    function ModelDetail($http, $q, SlateSQLineItem) {
        // instantiate our initial object
        var model = function construct() {
            var self = this;

            self.clear();

            return self;
        };

        //Set to fields for model
        /* beautify preserve:start */
        var fields = [
            {name: 'id', def: null, readonly: true },
            {name: 'name'},
            {name: 'status', def: 'draft'},
            {name: 'quote_expires', type:"dateOnly"},

            {name: 'quote_number', readonly: true},

            {name: 'company'},
            {name: 'company_info', readonly: true},
            {name: 'client_contact'},
            {name: 'client_contact_info', readonly: true},
            {name: 'client_phone'},
            {name: 'project'},
            {name: 'project_info', readonly: true},
            {name: 'order'},
            {name: 'order_info', readonly: true},

            {name: 'proj_mgr'},
            {name: 'proj_mgr_info', readonly: true},
            {name: 'sales_mgr'},
            {name: 'sales_mgr_info', readonly: true},

            {name:'reorder', def: false},
            {name:'tariff_on_sellprice', def: false},
            {name:'landed_cost', def: false},
            {name:'split_shipment', def: false},

            {name:'shipvia'},
            {name:'ship_terms'},
            {name: 'ship_to'},
            {name: 'ship_street'},
            {name: 'ship_state'},
            {name: 'ship_postal'},
            {name: 'ship_phone'},
            {name: 'ship_country', def: 'United States'},
            {name: 'ship_city'},

            {name:'terms', def:[], type:"json"},
            {name:'taxable'},
            {name:'conditions'},

            {name:'desired_date', type:"dateOnly"},

            {name:'freight_cost'},
            {name:'price_total', readonly: true, def: 0.0},
            {name:'sell_total', readonly: true, def: 0.0},
            {name:'margin', readonly: true, def: 0.0},

            {name:'min_margin', type: 'float', def: 0.0},
            {name:'max_sales', type: 'float', def: 0.0},

            {name:'notes'},

            {name:'include_tariff', def: true},
            {name:'include_freight', def: true},
            {name:'include_duty', def: true},

            {name: 'tariffs', def: 0.25},
            {name: 'tariff_type', def: 'buy_price'},
            {name: 'duty', def: 0.0},
            {name: 'freight', def: 0.0},
            {name: 'freight_markup', def: 0.0},
            {name: 'freight_type', def: 'estimate'},

            {name:'origin_port'},
            {name:'destination_port'},
            {name:'final_destination'},
            {name:'container_size'},

            {name: 'line_items', type:'child_list', model: SlateSQLineItem},

            {name: 'created', readonly: true},
            {name: 'created_by', readonly: true},
            {name: 'created_by_info', readonly: true},
            {name: 'updated', readonly: true},
            {name: 'deleted', readonly: true, default: false},
        ];
        /* beautify preserve:end */

        /* beautify preserve:start */
        model.prototype.shipTypes = ['oceanlcl', 'oceanfcl', 'oceanexp', 'air', 'truck', 'ups', 'fedex', 'dhl', 'other'];
        model.prototype.termsTypes = ['exw', 'fca', 'fob', 'cif', 'cpt', 'cip', 'dat', 'dap', 'ddp', 'ddu', 'other'];
        model.prototype.statusOptions = ['draft', 'ready', 'approval', 'quoted', 'accepted', 'rejected'];
        /* beautify preserve:end */

        model.prototype.clear = clear;
        model.prototype.loadFromPayload = loadFromPayload;
        model.prototype.loadFromServer = loadFromServer;
        model.prototype.saveModel = saveModel;
        model.prototype.deleteModel = deleteModel;
        model.prototype.getFieldsList = getFields;
        model.prototype.getSellTotal = getSellTotal;
        model.prototype.getGrandTotal = getGrandTotal;
        model.prototype.getTariff = getTariff;
        model.prototype.getDuty = getDuty;
        model.prototype.getFreightEstimate = getFreightEstimate;
        model.prototype.getFreightCost = getFreightCost;
        model.prototype.duplicateModel = duplicateModel;

        return model;

        function getFields() {
            var fields_list = [];
            fields.forEach(function(field) {
                fields_list.push(field.name);
            });
            return fields_list;
        }

        function clear() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            angular.forEach(fields, function(field) {
                self[field.name] = _.cloneDeep(field.def);

                if(field.type=="date" && !self[field.name]) {
                    //self[field.name] = new Date();
                }
                if(field.type=="child_list") {
                    self[field.name] = [];
                }
            });

            deferred.resolve(self);

            return deferred.promise;
        }


        function loadFromPayload(payload) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            if (typeof payload !== "object") {
                console.log('payload must be an object');
                return self;
            }

            angular.forEach(fields, function(field) {
                if(typeof payload[field.name] == 'undefined') {
                    return false;
                }

                self[field.name] = payload[field.name];

                if(field.type == 'date' && self[field.name]) {
                    self[field.name] = new Date(self[field.name]);
                }
                if (field.type == 'dateOnly' && payload[field.name]) {
                    var parts = self[field.name].split('-');
                    self[field.name] = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]) );
                }


                if (field.type == 'float') {
                    self[field.name] = parseFloat(self[field.name]);
                }

                if(field.type == 'json' && self[field.name]) {
                    try {
                        self[field.name] = JSON.parse(self[field.name]);
                    } catch(e) {
                    }
                }

                if(field.type == 'child_list' && self[field.name]) {
                    try {
                        self[field.name].forEach(function(item, idx, array) {
                            var obj = new field.model();
                            obj.loadFromPayload(item);
                            self[field.name][idx] = obj;
                        });
                    } catch(e) {
                        console.log('error loading: ', e);
                    }
                }


            });

            deferred.resolve(self);

            return deferred.promise;
        }

        function loadFromServer(id) {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();

            var modelUrl = apiUrl + self.id + '/';

            var promise;

            if (id) {
                modelUrl = apiUrl + id + '/';
            }

            if (!self.id && !id) {
                console.log('Tried to load project from server without id');
                return $q.reject(self);
            }

            promise = $http.get(modelUrl);

            promise.then(function(response) {
                var promises = [];

                return self.loadFromPayload(response.data.payload).then(function() {
                    self.permissions = response.data.permissions;
                    deferred.resolve(self);
                });
            });

            return deferred.promise;
        }

        function saveModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            }

            angular.forEach(fields, function(field) {
                if (!field.readonly) {
                    data[field.name] = self[field.name];
                    if(data[field.name] && field.type == "date") {
                        data[field.name] = self[field.name].toISOString();
                    }
                    if(field.type == "dateOnly" && self[field.name]) {
                        data[field.name] = self[field.name].getFullYear() + '-' + (self[field.name].getMonth()+1) + '-' + self[field.name].getDate();
                        //data[field.name] = self[field.name].toISOString();
                    }
                    if(field.type == 'json') {
                        data[field.name] = JSON.stringify(self[field.name]);
                    }
                    if(field.type == "child_list" && Array.isArray(data[field.name])) {
                        data[field.name] = [];
                        self[field.name].forEach(function(item) {
                            if(item.toDelete) { return false; }
                            data[field.name].push(item.getModelData());
                        });
                    }

                }
            });

            promise = $http.post(modelUrl, data);
            promise = promise.then(function(response) {
                var promises = [];
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return $q.all(promises);
            });

            return promise;
        }

        function deleteModel() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var modelUrl = apiUrl;

            var promise;
            var data = {};

            if (self.id) {
                modelUrl = apiUrl + self.id + '/';
            } else {
                deferred.reject(self);
                return deferred.promise;
            }

            promise = $http.delete(modelUrl);
            promise.then(function(response) {
                self.id = null;
                deferred.resolve(self);
            });

            return deferred.promise;

        }

        function duplicateModel() {
            /* jshint validthis: true */
            var self = this;
            var modelUrl = apiUrl;

            var promise;
            if (self.id) {
                modelUrl = apiUrl + self.id + '/duplicate/';
            }

            promise = $http.post(modelUrl);
            promise = promise.then(function(response) {
                self.loadFromPayload(response.data.payload);
                self.permissions = response.data.permissions;

                return self;
            });

            return promise;
        }

        function getTariff() {
            /* jshint validthis: true */
            var self = this;

            var total = Big(0.0);
            self.line_items.forEach(function(item) {
                total = total.plus(item.getTariffTotal(self.tariff_type, self.tariffs));
            });
            return total.toFixed(3);
        }

        function getGrandTotal() {
            /* jshint validthis: true */
            var self = this;
            var total = Big(self.getSellTotal());
            if(!self.landed_cost) {
                if(self.include_tariff) { total = total.plus(self.getTariff()); }
                if(self.include_duty) { total = total.plus(self.getDuty()); }
                if(self.include_freight) { total = total.plus(self.getFreightCost()); }
            }
            return total.toFixed(3);
        }

        function getSellTotal() {
            /* jshint validthis: true */
            var self = this;

            var total = Big(0);
            self.line_items.forEach(function(item) {
                var include_freight = self.include_freight && self.freight_type == 'estimate';
                total = total.plus(item.getSellTotal(self.landed_cost, self.tariff_type, self.include_tariff, include_freight, self.include_duty, self.tariffs));
                item.fixed_costs.forEach(function(cost) {
                    total = total.plus(cost.price);
                });
            });
            return total.toFixed(3);
        }

        function getFreightEstimate() {
            /* jshint validthis: true */
            var self = this;
            var deferred = $q.defer();
            var promises = [];

            var total = Big(0);

            self.line_items.forEach(function(lineItem) {
                promises.push(lineItem.getFreightEstimate(self.origin_port, self.destination_port, self.final_destination, self.shipvia).then(function(estimate) {
                    total = total.plus(estimate);
                }));
            });

            $q.all(promises).then(function() {
                self.freightEstimate = total.toFixed(3);
                deferred.resolve(total.toFixed(3));
            });

            return deferred.promise;
        }

        function getFreightCost() {
            /* jshint validthis: true */
            var self = this;

            switch (self.freight_type) {
                case 'estimate':
                    return self.freightEstimate;                    
                case 'fixed':
                    return self.freight;
                case 'markup_percentage':
                    return Big(self.freight_markup).div(100).times(self.freightEstimate).plus(self.freightEstimate).valueOf();
            }
        }
        
        function getDuty() {
            /* jshint validthis: true */
            var self = this;

            var total = Big(0);

            self.line_items.forEach(function(lineItem) {
                total = total.plus(lineItem.getDuty(self.duty));
            });

            return total.toFixed(3);
        }

    }

})();
